export function timeAgo(date) {
    let _date =  new Date(date)
    const seconds = Math.floor((new Date() - _date) / 1000);
    const intervals = [
      { name: 'year', seconds: 31536000 },
      { name: 'month', seconds: 2592000 },
      { name: 'day', seconds: 86400 },
      { name: 'hour', seconds: 3600 },
      { name: 'minute', seconds: 60 },
      { name: 'second', seconds: 1 }
    ];
  
    for (const interval of intervals) {
      const count = Math.floor(seconds / interval.seconds);
      if (count > 0) {
        return `${count} ${interval.name}${count !== 1 ? 's' : ''} ago`;
      }
    }
  
    return 'Just now';
  }

  export function parseJsonToObject(jsonString) {
    try {
      const jsonObject = JSON.parse(jsonString);
      return jsonObject;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }
  
  // Example usage
//   const dateTime = new Date('2023-08-15T12:30:00'); // Replace with your DateTime
//   const timeDifference = timeAgo(dateTime);
//   console.log(timeDifference); // Output: "1 second ago", "5 minutes ago", etc.
  