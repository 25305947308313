import axios from 'axios';
import { useState } from 'react';
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { authHeader } from '../helpers/authHeader';
import { customConfig } from '../actions/action_constants';
import queryString from 'query-string';
import { errorHandler } from '../helpers/misc';
import fileDownload from 'js-file-download';
import { parseJsonToObject } from '../helpers/util';
const API_URL = customConfig.API_URL_NOTIFICATION;

export const getFetcher = async (url) => {
  return await axios
    .get(`${API_URL}${url}`, { headers: authHeader() })
    .then(response => response.data
    )
    .catch(error => {
      return error
    });
}

const postFetcher = async (url, data) => {
  return axios
    .put(`${API_URL}${url}?ids=${[...data?.args?.ids]}`, {}, { headers: authHeader() })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    });
}

export const useGetNotifications = (query) => {
  let query_ = queryString.stringify(query, { skipEmptyString: true, skipNull: true })
  const { data, error, isLoading, mutate } = useSWR(`api/v1/notifications?${query_}`, getFetcher)

  return {
    data,
    isLoading,
    isError: error,
    mutate
  }
}

export const usePostNotifications = (objToPost) => {
  const { trigger, isMutating, error, data } = useSWRMutation('api/v1/notifications/markasread', postFetcher);
  return { trigger, isMutating, error, data }
}

function useAxiosPost() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const postData = async (payload) => {

    try {
      setIsLoading(true);
      const response = await axios.put(`${API_URL}api/v1/notifications/markasread`, payload, { headers: authHeader() });
      // Assuming that the server returns the created data
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return { data, error, isLoading, postData };
}

export function useDeleteNotification() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const postDelete = async (payload) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(`${API_URL}api/v1/notifications/delete`, { data: payload, headers: authHeader() });
      // Assuming that the server returns the created data
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return { data, error, isLoading, postDelete };
}

export const useDownloadBulkReport = (user) => {
  const [loadingStates, setLoadingStates] = useState({});
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const Download = async ({ eventType, id, metadata}) => {   
    let details = null
    if(metadata){
      details = parseJsonToObject(metadata);
    }
    switch (eventType) {
      case 'UPLOAD_CUSTOMER_USER':
        try {
          setIsLoading(true);
          setLoadingStates((prevLoadingStates) => ({
            ...prevLoadingStates,
            [id]: true,
          }));
          const response = await axios.get(`${customConfig.API_URL}api/customers/users/download?cid=${user?.customer ?? details?.customerId}`, { responseType: 'blob', headers: authHeader() });
          fileDownload(response.data, 'Bulk-User-Upload-Result.xlsx')
          setData(response.data)
          setError(null);
         
        }
        catch (error) {
          setError(errorHandler(error))
        } finally {
          setLoadingStates((prevLoadingStates) => ({
            ...prevLoadingStates,
            [id]: false,
          }));
        }
        break;
      case 'BATCH_TERMINAL_UPLOAD':
          try {
            setIsLoading(true);
            const response = await axios.get(`${customConfig.API_URL}api/terminals/download?vid=${user?.vendor?? details?.vendorId}`, { responseType: 'blob', headers: authHeader() });
            fileDownload(response.data, 'Bulk-Terminal-Upload-Result.xlsx')
            setData(response.data)
            setError(null);
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [id]: true,
            }));
          }
          catch (error) {
            setError(errorHandler(error))
          } finally {
            setIsLoading(false)
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [id]: false,
            }));
          }
        break;
        case 'BATCH_TERMINAL_USER_UPLOAD':
          try {
            setIsLoading(true);
            const response = await axios.get(`${customConfig.API_URL}api/users/terminalusers/download?vid=${user?.vendor?? details.vendorId}`, { responseType: 'blob', headers: authHeader() });
            fileDownload(response.data, 'Bulk-Terminal-Upload-Result.xlsx')
            setData(response.data)
            setError(null);
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [id]: true,
            }));
          }
          catch (error) {
            setError(errorHandler(error))
          } finally {
            setIsLoading(false)
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [id]: false,
            }));
          }
        break;
        case 'BATCH_VOUCHER_UPLOAD':
          try {
            setIsLoading(true);
            const response = await axios.get(`${customConfig.API_URL_SF_VOUCHER}api/v1/vouchers/download?batchId=${details?.batchId?? ''}`, { responseType: 'blob', headers: authHeader() });
            fileDownload(response.data, 'Bulk-Voucher-Upload-Result.xlsx')
            setData(response.data)
            setError(null);
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [id]: true,
            }));
          }
          catch (error) {
            setError(errorHandler(error))
          } finally {
            setIsLoading(false)
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [id]: false,
            }));
          }
        break;
    }
  }

  return { data, error, isLoading, loadingStates, Download };
}


export default useAxiosPost;