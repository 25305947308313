import axios from "axios";
import { customConfig } from "../../../../actions/action_constants";
import { authHeader } from "../../../../helpers/authHeader";
const API_URL = customConfig.API_URL;
// const API_URL_CARD = customConfig.API_URL_CARD;
const API_URL_REPORT = customConfig.API_URL_REPORT;
const API_URL_CARD_AND_TRANS = customConfig.API_URL_CARD_AND_TRANS;

export function fetchAllTrail(page, pageSize, startDate = null, endDate = null, action = '') {
  const dateFilter = (startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}%2023:59:59` : '';

  return function(dispatch) {
    dispatch({ type: "FETCH_TRAIL_START" });
    axios
      .get(API_URL + `api/trails/all?page=${page}&size=${pageSize}&action=${action}${dateFilter}`, {
        headers: authHeader()
      })
      .then(res => {
        dispatch({ type: "FETCH_All_TRAIL_FULLFILLED", payload: res.data });
      })
      .catch(err => {
        dispatch({ type: "FETCH_All_TRAIL_REJECTED", payload: err });
      });
  };
}

export function fetchAllTrailByVendor(vid, page, pageSize, startDate = null, endDate = null, action = '') {
  const dateFilter = (startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}%2023:59:59` : '';

  return function(dispatch) {
    dispatch({ type: "FETCH_ALLVENDOR_TRAIL_START" });
    axios
      .get(API_URL + `api/trails/vendor?vid=${vid}&page=${page}&size=${pageSize}&action=${action}${dateFilter}`, {
        headers: authHeader()
      })
      .then(res => {
        dispatch({
          type: "FETCH_AllVENDOR_TRAIL_FULLFILLED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({ type: "FETCH_AllVENDOR_TRAIL_REJECTED", payload: err });
      });
  };
}

/* export function fetchAllTransactionsSec() {
  return function(dispatch) {
    dispatch({ type: "FETCH_All_TRANSACTION_START" });
    axios
      .get(API_URL_CARD + "api/transaction/all?page=0&size=10000000", {
        headers: authHeader()
      })
      .then(res => {
        dispatch({ type: "FETCH_All_TRANSACTION_FULLFILLED", payload: res });
      })
      .catch(err => {
        dispatch({ type: "FETCH_All_TRANSACTION_REJECTED", payload: err });
      });
  };
} */

export function fetchAllTransactions(startDate, endDate, page, pageSize, status, filterParams) {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const customerNum = options.customerNum || "";
  const location = options.location || "";

  if (vendorNum.length) params+=`&vendorNum=${vendorNum}`;
  if (customerNum.length) params+=`&customerNum=${customerNum}`;
  if (location.length) params+=`&location=${location}`;

  return function(dispatch) {
    dispatch({ type: "FETCH_All_TRANSACTION_START" });
    axios
      .get(
        API_URL_CARD_AND_TRANS +
          `api/v1/transactions?pageNum=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`,
        {
          headers: authHeader()
        }
      )
      .then(res => {
        dispatch({ type: "FETCH_All_TRANSACTION_FULLFILLED", payload: res });
      })
      .catch(err => {
        dispatch({ type: "FETCH_All_TRANSACTION_REJECTED", payload: err });
      });
  };
}

export function downloadAllTransactions(startDate, endDate, status, filterParams, format = 'xlsx') {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const customerNum = options.customerNum || "";
  const location = options.location || "";

  if (vendorNum.length) params += `&vendorNum=${vendorNum}`;
  if (customerNum.length) params += `&customerNum=${customerNum}`;
  if (location.length) params += `&location=${location}`;

  return function(dispatch) {
    dispatch({ type: "DOWNLOAD_ALL_TRANSACTION_START" });
    axios
      .get(
        `${API_URL_CARD_AND_TRANS}api/v1/transactions/download/${format}?startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`,
        { responseType: 'blob', headers: authHeader() }
      )
      .then(res => {
        // console.log(`response-data: ${JSON.stringify(res, null, 2)}`)
        dispatch({ type: "DOWNLOAD_ALL_TRANSACTION_FULLFILLED", payload: res });
      })
      .catch(err => {
        dispatch({ type: "DOWNLOAD_ALL_TRANSACTION_REJECTED", payload: err });
      });
  };
}

export function downloadSettlementReport(startDate, endDate, vendorNum) {
  let param = vendorNum? `&vendorNum=${vendorNum}`: '';
  return function(dispatch) {
    dispatch({ type: "DOWNLOAD_SETTLEMENT_START" });
    axios
      .get(
        `${API_URL_CARD_AND_TRANS}api/v1/settlements/download/xls?startDate=${startDate}&endDate=${endDate}${param}`,
        { responseType: 'blob', headers: authHeader() }
      )
      .then(res => {
        // console.log(`response-data: ${JSON.stringify(res, null, 2)}`)
        dispatch({ type: "DOWNLOAD_SETTLEMENT_FULLFILLED", payload: res });
      })
      .catch(err => {
        dispatch({ type: "DOWNLOAD_SETTLEMENT_REJECTED", payload: err });
      });
  };
}

export function fetchHighestTransactingUser(groupBy, orderBy) {
  return function(dispatch) {
    dispatch({ type: "FETCH_HIGHEST_TRANSACTING_USER_START" });
    axios
      .get(`${API_URL_CARD_AND_TRANS}api/v1/transactions/toplist?groupBy=${groupBy}&orderBy=${orderBy}`, { headers: authHeader() })
      .then (res => undefined/* console.log(`response-got: ${JSON.stringify(res.data, null, 2)}`) */)
      .catch(err => undefined/* console.log(`error-got: ${JSON.stringify((err.response && err.response.data), null, 2)}`) */)
  }
}

export function fetchThreeMonthsTransactionStats(groupBy, orderBy) {
  return function(dispatch) {
    dispatch({ type: "FETCH_THREE_MONTHS_TX_STATS_START" });
    axios
      .get(`${API_URL_CARD_AND_TRANS}api/v1/transactions/monthlytrends?groupBy=${groupBy}&orderBy=${orderBy}`, { headers: authHeader() })
      .then (res => undefined/* console.log(`response-got-2: ${JSON.stringify(res.data, null, 2)}`) */)
      .catch(err => undefined/* console.log(`error-got-2: ${JSON.stringify((err.response && err.response.data), null, 2)}`) */)
  }
}

export function fetchTransactionAmountByFrequency(groupBy, vendorNum = '', customerNum = '') {
  return function(dispatch) {
    dispatch({ type: "FETCH_TRANSACTION_AMOUNT_BY_FREQ_START" });
    const endPoint = `${API_URL_CARD_AND_TRANS}api/v1/transactions/monthlytrends?vendorNum=${vendorNum}&customerNum=${customerNum}`;

    axios
      .all([
        axios.get(`${endPoint}&groupBy=${groupBy}&orderBy=AMOUNT&frequency=DAILY`, { headers: authHeader() }),
        axios.get(`${endPoint}&groupBy=${groupBy}&orderBy=VOLUME&frequency=DAILY`, { headers: authHeader() }),
        axios.get(`${endPoint}&groupBy=${groupBy}&orderBy=AMOUNT&frequency=WEEKLY`, { headers: authHeader() }),
        axios.get(`${endPoint}&groupBy=${groupBy}&orderBy=VOLUME&frequency=WEEKLY`, { headers: authHeader() }),
        axios.get(`${endPoint}&groupBy=${groupBy}&orderBy=VOLUME&frequency=MONTHLY`, { headers: authHeader() }),
        axios.get(`${endPoint}&groupBy=${groupBy}&orderBy=AMOUNT&frequency=MONTHLY`, { headers: authHeader() }),
      ])
      .then(axios.spread((resDa, resDv, resWa, resWv, resMa, resMv) => {
        // console.log('response-d----', JSON.stringify(resDa.data, null, 2));
        // console.log('response-w----', JSON.stringify(resWa.data, null, 2));
        // console.log('response-m----', JSON.stringify(resMa.data, null, 2));
        dispatch({
          type: "FETCH_TRANSACTION_AMOUNT_BY_FREQ_FULFILLED", 
          payload: {
            dailyAmount: (resDa.data.totalAmount || 0), 
            dailyVolume: (resDv.data.totalVolume || 0),
            weeklyAmount: (resWa.data.totalAmount || 0), 
            weeklyVolume: (resWv.data.totalVolume || 0),
            monthlyAmount: (resMa.data.totalAmount || 0),
            monthlyVolume: (resMv.data.totalVolume || 0),
          }
        });
      }), (err) => {
        dispatch({ type: 'FETCH_TRANSACTION_AMOUNT_BY_FREQ_REJECTED' });
      });
      // .catch(err => dispatch({ type: 'FETCH_TRANSACTION_AMOUNT_BY_FREQ_REJECTED' }));
  }
}

export function getTransactionsByvendorForDashboard(vid,page,pageSize,startDate, endDate,status) {
  return function(dispatch) {
    axios
      .get(
        `${
          customConfig.API_URL_CARD_AND_TRANS
        }api/v1/transactions?vendorNum=${vid}&pageNum=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}`,
        { headers: authHeader() }
      )
      .then(res => { 
        dispatch({ type: "GET_ALL_VENDORS_TRANSACTION_FOR_VDASHBOARD", payload: res.data });
      })
      .catch(err => {
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function getTransactionsByvendor(vendorNumber, startDate, endDate, page, pageSize, status, filterParams, setLoader) {
  let params = "";
  let options = filterParams || {};
  const customerNum = options.customerNum || "";
  const location = options.location || "";
  const terminalId = options.terminalId || "";
  if(setLoader) setLoader(true);
  if (customerNum.length) params+=`&customerNum=${customerNum}`;
  if (location.length) params+=`&location=${location}`;
  if(terminalId) params+= `&terminalId=${terminalId}`;

  return function(dispatch) {
    axios
      .get(
        `${
          customConfig.API_URL_CARD_AND_TRANS
        }api/v1/transactions?vendorNum=${vendorNumber}&pageNum=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`,
        { headers: authHeader() }
      )
      .then(res => {
        if(setLoader) setLoader(false);
        dispatch({ type: "GET_ALL_VENDORS_TRANSACTION", payload: res.data });
      })
      .catch(err => {
        if(setLoader) setLoader(false);
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function downloadTransactionsByvendor(vendorNumber, startDate, endDate, status, filterParams, format = 'xlsx') {
  let params = "";
  let options = filterParams || {};
  const customerNum = options.customerNum || "";
  const location = options.location || "";
  const terminalId = options.terminalId || "";
  if (customerNum.length) params+=`&customerNum=${customerNum}`;
  if (location.length) params+=`&location=${location}`;
  if(terminalId) params += `&terminalId=${terminalId}`;

  return function(dispatch) {
    dispatch({ type: "DOWNLOAD_ALL_VENDORS_TRANSACTION_START" });
    axios
      .get(
        `${
          customConfig.API_URL_CARD_AND_TRANS
        }api/v1/transactions/download/${format}?vendorNum=${vendorNumber}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`,
        { responseType: 'blob', headers: authHeader() }
      )
      .then(res => {
        dispatch({ type: "DOWNLOAD_ALL_VENDORS_TRANSACTION_FULFILLED", payload: res.data });
      })
      .catch(err => {
        dispatch({ type: "DOWNLOAD_ALL_VENDORS_TRANSACTION_REJECTED" });
      });
  };
}

export function getAllSettlements(pageNum, pageSize, startDate, endDate, vendorNumber, customerNum) {
  let param = vendorNumber? `&vendorNumber=${vendorNumber}`: '';
  param += customerNum ? `&customerNum=${customerNum}`: '';
    return function(dispatch) {
    dispatch({ type: "FETCH_SETTLEMENTS_START" });
    axios
      .get(API_URL_CARD_AND_TRANS + `api/v1/settlements?pageNum=${pageNum}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}${param}`
       , {
        headers: authHeader()
      })
      // api/settlement/customer?page=0&size=50&customer=CUS000002 `api/v1/settlements?startDate=2020-01-01&endDate=2021-04-11`
      .then(res => {
        dispatch({ type: "FETCH_SETTLEMENTS_FULFILLED", payload: res.data });
      })
      .catch(err => {
        dispatch({ type: "FETCH_SETTLEMENTS_REJECTED", payload: err });
      });
  };
}
