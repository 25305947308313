export function hasPermission(permissions) {
    const user = JSON.parse(localStorage.getItem("user"));
    const userPermissions = (user && user.permissions) || [];
    return permissions.find(permission => userPermissions.indexOf(permission) > -1);
}

export const isInThePast = (date) => {
  return !date || (typeof date === 'string' ? new Date(date) : date) < new Date();
}

export const errorHandler = (err) => {
  let message = "Something is not right";
  if (err.response) {
    if(err.response){
      let errObj = err.response?.data?.status;
      message = errObj?.description || errObj?.message;
      return message
    }
    
    if (err.response.response) {
      let errObj = err.response.response;
      if (errObj.data.status && errObj.data.status.error_description) { //errObj.data && errObj.data.error_description
        message = errObj.data.status.error_description;
      }
      if (errObj.data.status && errObj.data.status.description) {
        message = errObj.data.status.description;
      }
    }
  }
  return message;
}