import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import {BrowserRouter, withRouter, Switch} from 'react-router-dom'
import "./index.css";
import  App from "./App";
import './App.css';
import * as serviceWorker from "./serviceWorker";
// import {Provider} from 'react-redux'
// import store from './store'

ReactDOM.render(<App />, document.getElementById("root"));

// The browser shouldn’t perform a page refresh
if (module.hot) {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
