const vendorUserReducer = (
  state = {
    vendorUsers: [
      { id: '8376321', first_name: 'Akpan', last_name: 'Enoghobong', type: 'Customer', description: 'Head, Acquisition', date_created: '12/11/2019' },
      { id: '8392821', first_name: 'Tony', last_name: 'Ogan', type: 'Scheme Mgr', description: 'Head, Control', date_created: '12/11/2019' },
      { id: '11176321', first_name: 'Bolaji', last_name: 'Bummi', type: 'Customer', description: 'Head, Acquisition', date_created: '12/11/2019' },
      { id: '83763098721', first_name: 'Chisom', last_name: 'Emeagwa', type: 'Scheme Mgr', description: 'Head, Operations', date_created: '12/11/2019' },
    ],
    fetching: false,
    fetched: false,
    error: null,
    bulkCreateModal: false,
    userCreateModal: false,
    bulkUploadModal: false,
    bulkUploadResultModal: false,
    userEditModal: false,
    userDeleteModal: false,
  },
  action,
) => {
  switch (action.type) {
    case 'FETCH_USERS': {
      return { ...state, fetching: true };
    }
    case 'TOGGLE_MODAL': {
      return { ...state, userCreateModal: !state.userCreateModal };
    }
    case 'FETCH_USERS_REJECTED': {
      return { ...state, fetching: false, error: action.payload };
    }
    case 'FETCH_USERS_FULFILLED': {
      return { ...state, fetching: false, fetched: true, users: action.payload };
    }
    case 'ADD_VENDOR_USER': {
      return {
        ...state,
        userCreateModal: false,
        vendorUsers: [...state.vendorUsers, action.payload],
      };
    }
    case 'UPDATE_USERS': {
      const { id } = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex(user => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers,
      };
    }
    case 'DELETE_USERS': {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
  }
  return state;
};

export default vendorUserReducer;
