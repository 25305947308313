import React, { useState, useEffect, useMemo } from "react";
import SubComponentHeader from "../../../lib/Components/SubComponentHeader";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import ToastAlert from "../../../lib/Components/ToastAlert";
import CustomInput from "../../../lib/Components/CustomInput";
import { getUserProperties } from "../../../lib/Constants/UserTypes";
import { Spin, Icon } from "antd";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export default function CreateContract({
  isCreateContract,
  isUpdateContract,
  selectedContract,
  onBackHandler,
  onCreateContract,
  onRequestContractUpdate,
  oContractReducer,
  vendorCustomer,
}) {
  
  const [formData, setFormData] = useState({
    name: "",
    customer: "",
    vendor: "",
    bound: "",
    contractStartDate: "",
    frequency: 1,
    contractCycleType: "DAILY",
    contractCycleTypeDays: 0,
    autoRenew: null,
    description: ""
  });

  const [isCreatingContract, setIsCreatingContract] = useState(false);
  const [isUpdatingContract, setIsUpdatingContract] = useState(false);
  const [contractValidationError, setContractValidationError] = useState(false);
  const [toast, setToast] = useState({ class: "", text: "" });
  const [autoRenewValue, setAutoRenewValue] = useState("")
  const dispatch = useDispatch();

  const loading = useSelector(({ vendorCustomer }) => vendorCustomer.loadingVendorCustomerStatus);

  let contractAmount = useMemo(()=>{
    let data = localStorage.getItem('SAVE_CONTRACT_INFO');
    if(data){ setFormData({ ...formData, bound: data}); return data }
    return ""
  },[])

  const customers = vendorCustomer && vendorCustomer.activeCustomers;
  const customerOption = customers
    ? customers
      .filter(c => isCreateContract || c.contracts?.some(ct => ct.contractStatus === "ACTIVE"))
      .map((value, key) => {
        if (value.contracts?.some(ct => ct.id === selectedContract.id)) {
          formData.customer = value.id
        }

        return (
          <option value={value.id} key={key}>
            {value.name}
          </option>
        );
      })
    : null;


  const handleInput = (attr, event) => {
    // check if "event.detail" is available.
    // "event.detail" is from number component
    if (event.detail && event.target == null) {
      setFormData({ ...formData, [attr]: event.detail.value });
    } else {
      setFormData({ ...formData, [attr]: event.target.value });
    }
  };

  const isContractValidationError = () => {
    const {
      name,
      customer,
      bound,
      contractStartDate,
      contractCycleType,
      contractCycleTypeDays,
    } = formData;
    if (
      !name ||
      (isCreateContract && !customer) ||
      !bound ||
      !contractStartDate ||
      (contractCycleType === "DAYS" && !contractCycleTypeDays) ||
      (Math.sign(bound) === -1) || !autoRenewValue
    ) {
      return true;
    } else {
      return false;
    }
  };

  const resetFormData = () => {
    setFormData({
      name: "",
      customer: "",
      vendor: "",
      bound: "",
      contractStartDate: "",
      frequency: 1,
      contractCycleType: "DAILY",
      contractCycleTypeDays: 0,
      autoRenew: null,
      description: ""
    });
    setAutoRenewValue("");
  };

  const createOrUpdateContract = event => {
    setContractValidationError(false);
    event.preventDefault();
    if (!!isContractValidationError()) {
      setContractValidationError(true);
      setToast({
        class: "",
        text: "All fields must be correctly filled to continue!"
      });
      return;
    }

    if (isCreateContract) {
      setIsCreatingContract(true);
      onCreateContract({...formData, vendor: getUserProperties().vendor});
    }

    if (isUpdateContract) {
      setIsUpdatingContract(true);
      onRequestContractUpdate({...formData, vendor: getUserProperties().vendor}, selectedContract.id);
    }
  };

  useEffect(() => {
    if(Object.keys(selectedContract).length > 0) {
      setFormData({
        name: selectedContract?.name || "",
        customer: selectedContract?.customerSignature || "",
        vendor: selectedContract?.vendorSignature || "",
        bound: selectedContract?.bound || "",
        contractStartDate: moment(selectedContract?.contractStartDate || "").format('YYYY-MM-DD'),
        frequency: selectedContract?.frequency || 1,
        contractCycleType: selectedContract?.contractCycleType || "DAILY",
        contractCycleTypeDays: selectedContract?.contractCycleTypeDays || 0,
        autoRenew: selectedContract?.autoRenew || false,
        description: selectedContract?.description || ""
      });
      setAutoRenewValue(selectedContract?.autoRenew? 'auto-renew': 'one-off');
    }
  }, [isCreateContract, isUpdateContract])

  useEffect(() => {
    if ((!!isCreatingContract || !!isUpdatingContract) && oContractReducer.contractRequestStatus !== 1) {
      setToast({
        class: "",
        text:
          oContractReducer.contractRequestMessage ||
          "An unknown error has occurred!"
      });
      setContractValidationError(true);
      !!isCreatingContract && setIsCreatingContract(false);
      !!isUpdatingContract && setIsUpdatingContract(false);
    }

    if ((!!isCreatingContract || !!isUpdatingContract) && oContractReducer.contractRequestStatus === 1) {
      resetFormData();
      setToast({
        class: "text-white bg-success",
        text:
          oContractReducer.contractRequestMessage ||
          "An unknown error has occured!"
      });
      setContractValidationError(true);
      !!isCreatingContract && setIsCreatingContract(false);
      !!isUpdatingContract && setIsUpdatingContract(false);
    }

    dispatch({
      type: "CREATE_CONTRACT_REQUEST",
      response: { status: 0, message: "" }
    });
  }, [oContractReducer.contractRequestStatus]);

  const handleAutoRenew =(e)=> {
    let renewStatus = false;
    if(e.target.value === 'auto-renew') {
      renewStatus = true;
    }

    if(e.target.value === 'one-off') {
      renewStatus = false;
    }
    setAutoRenewValue(e.target.value);
    setFormData({ ...formData, autoRenew: renewStatus })
  }

  const cancelForm =() => {
    if(contractAmount){
      localStorage.removeItem('SAVE_CONTRACT_INFO')
    }
    resetFormData();
    onBackHandler();
  }

  return (
    <>
      {(isCreateContract || isUpdateContract) && (
        <>
          <SubComponentHeader
            heading={isCreateContract ? `Create new contract` : `Edit Contract`}
            onBackHandler={cancelForm}
          />

          <div className="row mt-2" style={{width: "100%"}}>
            <div className="offset-1 col-10 p-3">
              <div className="col-10 offset-1">
                <div
                  className="row bg-rfid-detailsm form bg-white ml-1 mr-1 pl-4 pr-4"
                  style={{boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)", borderRadius: 4}}
                >
                  <div className="row">
                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Contract Name"
                      value={formData.name}
                      readOnly={isUpdateContract}
                      disabled={isUpdateContract}
                      handleInput={handleInput.bind(this, "name")}
                    />

                  {loading === 0 ? (
                      <div className="col-6 mt-4 pb-3">
                        <Spin
                          indicator={antIcon}
                          tip=""
                        />
                      </div>
                    ) : (
                      <CustomInput
                        parentClass="col-6 mt-4 pb-3"
                        inputClass="pl-2"
                        label="Select Customer"
                        value={formData.customer}
                        data={customerOption}
                        presetData={
                          customerOption === null
                            ? "No customer available"
                            : isCreateContract ? "Select Customer..." : ""
                        }
                        isSelect={true}
                        disabled={(customerOption === null) || isUpdatingContract || isUpdateContract}
                        handleInput={handleInput.bind(this, "customer")}
                      />
                     )}

                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Limit"
                      isNumber={true}
                      value={formData.bound}
                      handleInput={handleInput.bind(this, "bound")}
                    />

                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Start Date"
                      isCompulsory={true}
                      type="date"
                      value={formData.contractStartDate}
                      onBlur={() => {
                        const formattedDate = formData.contractStartDate
                          .split("/")
                          .join("-");
                        setFormData({
                          ...formData,
                          contractStartDate: formattedDate,
                        });
                      }}
                      handleInput={handleInput.bind(this, "contractStartDate")}
                    />

                    <div className="col-6 mt-4 pb-3">
                      <label>
                        Frequency <span className="asterisk">*</span>
                      </label>
                      <select
                        className="contract-select pl-2"
                        name="contract-type"
                        value={formData.contractCycleType}
                        onChange={handleInput.bind(this, "contractCycleType")}
                      >
                        <option value="DAILY">Daily</option>
                        <option value="WEEKLY">Weekly</option>
                        <option value="MONTHLY">Monthly</option>
                        <option value="DAYS">Days</option>
                      </select>
                    </div>

                    <div className="col-6 mt-3 pb-3 pt-2">
                      <div className="row">
                        <div style={{
                          paddingRight: 20,
                          width: '100%',
                          height: 25
                        }}>
                        <span style={{ float: "left",
                            marginLeft: 20
                        }}>
                          Contract Type
                        </span>
                        <span style={{
                          height: 5,
                          bottom: 10
                        }} className="asterisk">*</span>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <div className="form-check pl-0">
                            <label style={{  display: "inline-block", marginTop: '10%' }}>Auto-Renew</label>
                              <input className="form-check-input" type="radio" name='auto-renew' value="auto-renew" id="auto-renew-check"
                                style={{ paddingLeft: 0, width: '70%' }} checked={autoRenewValue === 'auto-renew'}
                                onChange={e => handleAutoRenew(e)} />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <div className="form-check pl-0">
                            <label style={{  display: "inline-block", marginTop: '10%' }}>One-off</label>
                              <input className="form-check-input" type="radio" name='auto-renew' value="one-off" id="one-off-check"
                                style={{ paddingLeft: 0, width: '70%' }} checked={autoRenewValue === 'one-off'}
                                onChange={e => handleAutoRenew(e)} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(formData.contractCycleType === "DAYS") && <CustomInput
                          parentClass="col-6"
                          label="No. Of Days"
                          value={formData.contractCycleTypeDays}
                          handleInput={handleInput.bind(this, "contractCycleTypeDays")}
                        />}
                    <div className="col-12">
                      <span style={{color: "#4B4B4B"}}>
                        All fields marked asterisk (
                        <span className="asterisk">* </span>) are compulsory
                      </span>
                    </div>

                    <ToastAlert
                      showToast={!!contractValidationError}
                      parentClass={"col-12 mt-4 mb-2 pb-3"}
                      childClass={toast.class}
                      toastText={toast.text}
                      onCloseHandler={() => {
                        setContractValidationError(false);
                      }}
                    />
                  </div>
                </div>

                <div className="buttons mt-3 text-right">
                  <div
                    className="mr-4"
                    style={{
                      backgroundColor: "#5F738C",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={cancelForm}
                  >
                    Cancel
                  </div>
                  <div
                    style={{
                      backgroundColor: "#00425F",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={!isCreatingContract || !isUpdatingContract ? createOrUpdateContract : undefined}
                  >
                    {isCreatingContract || isUpdatingContract ? (
                      <img
                        src="/images/ActivityIndicator.gif" alt="activity-indicator"
                        style={{height: 20, width: 20}}
                      />
                    ) : (
                      isCreateContract ? "Create Contract" : "Update Contract"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
