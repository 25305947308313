import { RFID } from "../../Tokens/actions/actionTypes";
const initialState = {
  unusedTags: [],
  unusedTagsSize: 0,
  usedTags: [],
  usedTagsSize: 0,
  isCreatingTags: false,
  isTagsCreated: false,
  rfidBatch: [],
  batchCount: 0,
  tagErrorCode: 0,
};

const RfidReducer = (state = initialState, action) => {
  switch (action.type) {
    case RFID.UNUSED_TAGS:
      const { unusedTags, unusedTagsSize } = action;
      return {
        ...state,
        unusedTagsSize,
        unusedTags
      };
    case RFID.USED_TAGS:
      const { usedTags, usedTagsSize } = action;
      return {
        ...state,
        usedTags,
        usedTagsSize
      };
    case RFID.END_RFID_CREATION:
      const { isCreatingTags } = action;
      return {
        ...state,
        isCreatingTags
      };
    case RFID.TAGS_CREATION_SUCCESS:
      return {
        ...state,
        isTagsCreated: action.isTagsCreated
      };

    case RFID.BATCH_LIST:
      const { rfidBatch, batchCount } = action;
      return {
        ...state,
        rfidBatch,
        batchCount
      };
    case RFID.TAG_CREATION_ERROR:
      const { code } = action;
      return {
        ...state,
        tagErrorCode: code
      };

    default:
      return state;
  }
};
export default RfidReducer;
