import axios from 'axios';
import { 
    ALL_LOCATIONS, 
    ALL_VENDOR_TERMINALS, 
    VENDOR_LOCATION_LOADING_STATUS, 
    VENDOR_TERMINALS_LOADING,
    FETCH_All_TERMINALUSERS_BY_VENDOR_START,
    FETCH_All_TERMINALUSERS_BY_VENDOR_FULLFILLED,
    FETCH_All_TERMINALUSERS_BY_VENDOR_REJECTED
  } from './TerminalMgtConstants';
import { customConfig } from '../../action_constants';
import { authHeader } from '../../../helpers/authHeader';
const API_URL = customConfig.API_URL;

// export const getAllVendorsLocations = locations => dispatch => {
//   dispatch({
//     type: ALL_LOCATIONS,
//     locations,
//   });
// };

export const getAllVendorsLoadingStatus = status => dispatch => {
  dispatch({
    type: VENDOR_LOCATION_LOADING_STATUS,
    status,
  });
};

// Don't forget to pass parameter to send the user vendor ID,
// after integration of authentication process.

export function loadAllVendorLocation(vid) {
  return function(dispatch) {
    axios
      .get(`${API_URL}api/locations/vendors?id=${vid}&page=1&size=10000000`, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: ALL_LOCATIONS, response: { data: res.data.data, status: 1, message: res.data.status.description } });
        }
        if (res.data.status.code !== 0) {
          dispatch({ type: ALL_LOCATIONS, response: { data: [], status: 2, message: res.data.status.description } });
        }
      })
      .catch(error => {
        dispatch({ type: ALL_LOCATIONS, response: { data: [], status: 2, message: error.message } });
      });
  };
}

export function loadAllVendorTerminals() {
  return function(dispatch) {
    axios
      .get(`${API_URL}/terminals/locations?id=2&page=1&size=10000000`, { headers: authHeader() })
      .then(response => {
        if (response.status === 200) {
          const { data } = response.data;
          dispatch(getAllVendorTerminal(data));
          dispatch(getAllVendorTerminalsLoadingStatus(0));
        }
      })
      .catch(error => {
        dispatch(getAllVendorTerminalsLoadingStatus(2));
      });
  };
}

// export function fetchAllTerminalsByLocationID() {
//   const config = {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     data: {},
//   };
//   // 'Access-Control-Allow-Origin': 'http://104.248.149.29:8588',
//   // 'content-type': 'application/x-www-form-urlencoded',
//   return function(dispatch) {
//     axios
//       .get(API_URL + 'api/terminals/all?page=1&size=50', config)
//       .then(res => {
//         dispatch({ type: 'FETCH_All_TERMINALS_FULLFILLED', payload: res.data });
//       })
//       .catch(err => {
//         dispatch({ type: 'FETCH_All_TERMINALS_REJECTED', payload: err });
//       });
//   };
// }

export const getAllVendorTerminal = terminals => dispatch => {
  dispatch({
    type: ALL_VENDOR_TERMINALS,
    terminals,
  });
};

export const getAllVendorTerminalsLoadingStatus = status => dispatch => {
  dispatch({
    type: VENDOR_TERMINALS_LOADING,
    status,
  });
};

export const fetchVendorPosUsers = (vendorId, pageNumber, pageSize) => dispatch => {
  dispatch({ type: FETCH_All_TERMINALUSERS_BY_VENDOR_START });
  axios
    .get(API_URL + `api/users/terminalusersbyvendor?vid=${vendorId}&page=${pageNumber}&size=${pageSize}`, {
      headers: authHeader()
    })
    .then(res => {
      dispatch({
        type: FETCH_All_TERMINALUSERS_BY_VENDOR_FULLFILLED,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({ type: FETCH_All_TERMINALUSERS_BY_VENDOR_REJECTED, payload: err });
    });
};
