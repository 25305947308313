import "../../../src/assets/Styles/Style.css";
import React, { useEffect, useState } from "react";
import { SearchSvg } from "../../assets/Svgs/Svgs";
import { MenuRoutes } from "../Routes/MenuRoutes";
import { NavLink } from "react-router-dom";

const MenuBar = ({ onSearchBtnClick = () => {} }) => {
  const [query, setQuery] = useState("");
  const menuRoutes = MenuRoutes(window.location.pathname).map((value, i) => {
    return (
      <NavLink to={value.route} key={i}>
        <span
          className={
            window.location.pathname.indexOf(value.route.split("/main")[0]) > -1
              ? "active"
              : ""
          }
        >
          {value.name}
        </span>
      </NavLink>
    );
  });

  useEffect(() => setQuery(""), [window.location.pathname]);

  return (
    <div className="menu-bar">
      <div
        className="menu-link"
        style={{
          borderBottomWidth: 0.5,
          borderBottomColor: "#e5e5e5",
          borderBottomStyle: "solid",
        }}
      >
        {menuRoutes}
        {!window.location.pathname.includes("dashboard") && (
          <div className="menu-input-container">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onBlur={(e) => setQuery(e.target.value)}
              onKeyPress={(e) =>
                ((e.keyCode || e.which) === 13 || e.code === "Enter") &&
                onSearchBtnClick &&
                onSearchBtnClick(e)
              } // for enter-key
              placeholder="Search..."
              className="menu-input"
            />
            <i
              className="input-icon"
              style={{ cursor: "pointer" }}
              onClick={(e) => onSearchBtnClick && onSearchBtnClick(e, query)}
            >
              <SearchSvg />
            </i>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuBar;
