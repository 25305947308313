import React from "react";
import {BackSVG} from "../../assets/Svgs/Svgs";
import PropTypes from "prop-types";
export default function SubComponentHeader({ heading, onBackHandler }) {
  return (
    <>
      <div
        className="rfid-menu-link"
        style={{
          fontFamily: "Source Sans Pro",
          marginTop: 0,
          marginBottom: 0,
          marginRight: 0,
          paddingBottom: 6,
          width: "100%",
          borderWidth: 0,
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderBottomColor: "#5F738C"
        }}
      >
        <span style={{ color: "#000000", fontSize: 25 }} className="active">
          {heading}
        </span>
      </div>
      <div
        className="back-btn"
        onClick={onBackHandler}
      >
        <span>
          <BackSVG /> Back
        </span>
      </div>
    </>
  );
}

SubComponentHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  onBackHandler: PropTypes.func.isRequired
}