import { longMonthNames } from "./DateNames";

export const formatDate3 = (rawDate) => {
    const d = new Date(rawDate);
    return `${d.getDate()}, ${longMonthNames[d.getMonth()]} ${d.getFullYear()}`;
}

export const returnTime = (rawDate) => {
    const d = new Date(rawDate);
    return d.toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric' });
}