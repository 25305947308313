import React, { useState, useEffect } from "react";
import "../../../assets/Styles/Dashboard.css";
import { FilterSVG, ExportSVG } from "../../../assets/Svgs/Svgs";
import { CSVLink } from "react-csv";
import Pagination from "../../../lib/Components/Pagination";
import { ACTIVE, EXPIRED, TERMINATED } from "../../../lib/Constants/ContractStates";
import ContractDetails from "./ContractDetails";
import formatAmount from "../../../lib/Functions/formatAmount";
import { shortDayNames, shortMonthNames } from "../../../lib/Functions/DateNames";

const NewContractList = (props) => {
  const { oContractReducer, customerVendor, onHideStatusBar } = props;
  const dataV = customerVendor.allCustomerVendors ? customerVendor.allCustomerVendors?.data || [] : [];
  const data = dataV.length !== 0 && oContractReducer.customerContracts ? oContractReducer.customerContracts : [];
  const count = data ? data.length : 0;
  const [selectedVendor, setSelectedVendor] = useState({});
  const [selectedContract, setSelectedContract] = useState({});
  const [contractArr, setContractArr] = useState([]);
  const pageSize = 10;

  useEffect(() => {
    if (dataV.length !== 0) {// very necessary that vendor-list should have loaded
      contractList(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oContractReducer.customerContracts, dataV])

  function contractList(pageNo = 1) {
    const contractArray = data ? data.slice((pageNo - 1) * pageSize, (pageNo * pageSize)) : [];

    setContractArr(contractArray.map((value, i) => {
      const {
        id, name, bound, contractStartDate, 
        contractCycleType, contractStatus:status
      } = value;
      
      let condition = "", classIdentifier = "";
      if (status === ACTIVE) {
        classIdentifier = "success";
        condition = "Active";
      } else if (status === EXPIRED) {
        classIdentifier = "error";
        condition = "Expired";
      } else if (status === TERMINATED) {
        classIdentifier = "error";
        condition = "Terminated";
      } else {
        classIdentifier = "warning";
        condition = "Inactive";
      } 
      const d = new Date(contractStartDate);
 
      return (
        <tr key={id}>
          <td className="table-description font-weight-bold">{name}</td>
          <td className="table-description font-weight-bold">
            &#8358; {formatAmount(bound * 100)}
            {/* amount in naira already */}
          </td>
          <td className="table-description">
            {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
            {shortMonthNames[d.getMonth()]} {d.getFullYear()}
          </td>
          <td className={`table-status`}>
            <div className={`status-btn ${classIdentifier}`}>{condition}</div>
          </td>
          <td>{contractCycleType || '---'}</td>
          {<td
            className="table-action"
            onClick={() => {
              const linkedVendor = dataV.find(v => v.contracts?.find(c => c.id === id))
              if (!linkedVendor) console.log('---no linked-vendor found');// just for debug
              setSelectedVendor(linkedVendor ?? {});
              setSelectedContract(value);
              onHideStatusBar(!!linkedVendor);
            }}
          >
            View
          </td>}
        </tr>
      );
    }));
  }

  return (
    <div className="disbursed-cards-container row">
      {!selectedVendor.id && (
        <>
          <div
            className="menu-link"
            style={{ marginBottom: 0, paddingBottom: 4 }}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid",
              }}
            >
              All Contracts
            </span>
          </div>
          {!!data && (
            <CSVLink
              data={data}
              filename={"Contracts.csv"}
              className=""
              target="_blank"
            >
              <div
                className="contract-export-btn"
                style={{ float: "right", right: 0 }}
              >
                <span className="text-white">
                  Export CSV <ExportSVG />
                </span>
              </div>
            </CSVLink>
          )}

          <table className="table table-hover">
            <thead>
              <tr>
                <td>Name</td>
                {/* <td>Contract Type <FilterSVG /></td> */}
                <td>Bound</td>
                <td>Start Date <FilterSVG /></td>
                <td>Status <FilterSVG /></td>
                <td>Frequency <FilterSVG /></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {!!count && contractArr}
              {!count && (
                <tr>
                  <td colSpan={6} className="text-center">
                    No contract to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            count={count}
            pageSize={pageSize}
            fetchMethod={(pageNo, pageSize) => {
              contractList(pageNo);
            }}
          />
        </>
      )}
      {selectedVendor.id && (
        <ContractDetails {...props} 
        selectedContract={selectedContract}
        selectedVendor={selectedVendor} 
        backFunction = {() => {
          setSelectedVendor({})
          props.onHideStatusBar(false);
        }} />
      )}
    </div>
  );
};

export default NewContractList;
