import React, { useEffect, useState/* , useEffect */ } from "react";
import "../../../../assets/Styles/Dashboard.css";
import { FilterSVG, ExportSVG, /* CompletedMarkSVG, LoadingSVG, DownloadSVG, */ MarkSVG, MissSVG } from "../../../../assets/Svgs/Svgs";
import Modal from "react-bootstrap/Modal";
import { longMonthNames, /* shortMonthNames, */ shortDayNames, shortMonthNames } from "../../../../lib/Functions/DateNames";
import formatAmount from "../../../../lib/Functions/formatAmount";
import { Link } from "react-router-dom";
import Pagination from "../../../../lib/Components/Pagination";
import { Spin } from "antd";

const SettlementList = (props) => {
  const { oTrailReducer : { oSettlements: { content =[], count }, fetchingSettlement, downloadingSettlement }, onDownloadTransactions, onPageChange } = props;
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const {cardName, cardNumber, location, time, fee, amount, vendor, reference, status} = selectedTransaction;
  const d = new Date(time);
  const transactionDate = `${shortDayNames[d.getDay()]}, ${longMonthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
 const pageSize = 10;
  const TransactionList = content && content.map((value) => {
    const {id, location, cardNumber, vendor, amount, time, fee, customer, product, volume} = value;
    const d = new Date(time);
    const date = d.getDate();
    let suffix = (date >= 4 && date <= 20) || (date >= 24 && date <= 30) ? "th" : ["st", "nd", "rd"][date % 10 - 1];
    const dayIndex = ((d.getDay() - 1) < 0 ? (7 - Math.abs(d.getDay() - 1)) : (d.getDay() - 1));
 
    return (
      <tr key={id}>
        <td className="table-description">Purchased of {product} from {location}
        <small className="d-block text-primary font-weight-bold">
              {shortDayNames[dayIndex]}, {d.getDate()}{suffix}{" "}
              {shortMonthNames[d.getMonth()]}, {d.getFullYear()}
            </small>
        </td>
        <td className="table-pin">{customer}</td>
        <td className="table-description font-weight-bold">{cardNumber}</td>
        <td className="table-pin">{vendor}</td>
        <td className="table-description">₦ {formatAmount(amount * 100)}</td>
        <td className="table-description">{product}</td>
        <td className="table-description">{volume}</td>
        <td className="table-description">₦ {formatAmount(fee * 100)}</td>
        <td className="table-action" onClick={() => {
          setSelectedTransaction(value);
          setShowTransactionModal(true);
        }}>View</td>
      </tr>
    )
  });

  return (
    <>
      <div className="disbursed-cards-container row">
        <div className="menu-link" style={{marginBottom: 0, paddingBottom: 4}}>
          <span
            style={{
              color: "#000000",
              borderBottomColor: "#00425F",
              borderBottomWidth: 3,
              borderBottomStyle: "solid",
            }}
          >
            Settlement Report
          </span>
        </div>
        {content.length > 0 && (
          // <CSVLink
          //   data={content}
          //   filename={"Settlement Report.csv"}
          //   className=""
          //   target="_blank"
          // >
          //   <div
          //     className="disbursed-cards-export-btn"
          //     style={{float: "right"}}
          //   >
          //     <span className="text-white">
          //       Export CSV <ExportSVG />
          //     </span>
          //   </div>
            <div
              className="disbursed-cards-export-btn text-white"
              style={{float: "right"}}
            >
              {downloadingSettlement ? 'Loading...': <Link
                to="#"
                onClick={() => {
                  onDownloadTransactions()
                }}
                className="text-white"
              >Export XLS <ExportSVG /></Link>}
            </div>
          // </CSVLink>
        )}
        <Spin wrapperClassName='spinner-wrapper' spinning={fetchingSettlement} tip="Loading...">
          <table className="table table-hover">
          <thead>
            <tr>
              <td>Description</td>
              <td>Customer</td>
              <td>Card Pan</td>
              <td>
                Vendor <FilterSVG />
              </td>
              <td>Amount</td>
              <td>
                Product
              </td>
              <td>Volume/QTY</td>
              <td>
                Fee
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {!!TransactionList && TransactionList}
            {TransactionList < 1 && (
              <tr>
                <td colSpan={6} className="text-center">
                  No Settlement to show
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </Spin>
        <Pagination pageSize={pageSize}
          count={count}
          fetchMethod={(pageNo, pageSize) => {
            onPageChange(pageNo, pageSize)
          }} />
        {/* {totalPages >= 1 && <div className="pagination-container row">
        <div className={`col-2 offset-3 prev text-center ${pageNo > 1 ? "visible": "invisible"}`} onClick={() => {
          setPageNo(pageNo - 1);
        }}>
          Prev
        </div>
        <div className="col-2 text-center">
          <span className="align-middle">{`Page ${pageNo} of ${totalPages}`}</span>
        </div>
        <div className={`col-2 next text-center ${pageNo === totalPages ? "invisible": "visible"}`} onClick={() => {
          setPageNo(pageNo + 1);
        }}>
          Next
        </div>
      </div>} */}
      </div>

      <Modal
        show={showTransactionModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="transaction-modal"
      >
        <Modal.Body>
          <div className="pl-5 pr-5 pt-5 pb-5 text-center">
            <div
              style={{
                borderBottomColor: "#5F738C",
                borderBottomWidth: 0.5,
                borderBottomStyle: "solid",
                textAlign: "left",
              }}
            >
              <h6 className="mt-5 text-left d-inline">Transaction Details</h6>
              <span
                style={{float: "right", cursor: "pointer"}}
                onClick={() => {
                  setShowTransactionModal(false);
                }}
              >
                {" "}
                X{" "}
              </span>
            </div>
            <div className="text-center mt-4">
              {status === "Successful" ? <MarkSVG /> : <MissSVG />}
            </div>
            <div className="text-center mt-3 mb-1 font-weight-bold quickteller-black">
              Your transaction was{" "}
              {status === "Successful" ? "successful" : "unsuccessful"}
            </div>
            <span className="quickteller-black f-14">
              {location} Purchase for {cardName} {cardNumber}{" "}
            </span>
            {
              <>
                <div className="col-12 text-left bg-white mb-3 mt-4">
                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Description
                      </span>
                      <span className="d-block qt-gray mt-3">
                        Smart Fuel Purchase for {cardName}
                      </span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Date
                      </span>
                      <span className="d-block qt-gray mt-3">
                        {transactionDate}
                      </span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                      <div className="row">
                        <div className="col-6">
                        <span className="d-block font-weight-bold qt-dark-blue">
                         Amount
                        </span>
                      <span className="d-block qt-gray mt-3">
                        ₦ {formatAmount(amount * 100)}
                      </span>
                        </div>
                        {status === "Successful" && <div className="col-6">
                        <span className="d-block font-weight-bold qt-dark-blue">
                         Transaction Fee
                        </span>
                      <span className="d-block qt-gray mt-3">
                        ₦ {formatAmount(fee * 100)}
                      </span>
                        </div>}
                      </div>
                      
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Card Pan
                      </span>
                      <span className="d-block qt-gray mt-3">{cardNumber}</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Customer Name
                      </span>
                      <span className="d-block qt-gray mt-3">{cardName}</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Location
                      </span>
                      <span className="d-block qt-gray mt-3">{location}</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Vendor
                      </span>
                      <span className="d-block qt-gray mt-3">{vendor}</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Request Reference
                      </span>
                      <span className="d-block qt-gray mt-3">{reference}</span>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SettlementList;