const oCustomerReducer = (
  state = {
    allCustomers: [],
    customerVendorTerminals: [],
    oneCustomer: {},
    fetching: false,
    fetched: false,
    error: null,
    bulkCreateModal: false,
    userCreateModal: false,
    bulkUploadModal: false,
    bulkUploadResultModal: false,
    userEditModal: false,
    userDeleteModal: false,
    createCustomerRequestStatus: 0,
    createCustomerRequestMessage: "",
    isCreatingCustomer: false,
    fetchingStatus: 0,
    editingStatus: 0,
    isUpdatingCustomer: false,
    customerCreationErrorMessage: ""
  },
  action
) => {
  switch (action.type) {// eslint-disable-line default-case
    case "FETCH_USERS": {
      return { ...state, fetching: true };
    }

    case "TOGGLE_MODAL": {
      return { ...state, userCreateModal: !state.userCreateModal };
    }

    case "RESET_ADD_OWNER_CUSTOMER": {
      return {
        ...state,
        isCreatingCustomer: false,
        fetchingStatus: 0,
        customerCreationErrorMessage: ""
      };
    }

    case "ADD_CUSTOMERS_START": {
      return {
        ...state,
        isCreatingCustomer: true,
        fetchingStatus: 0
      };
    }

    case "OCREATE_CUSTOMER_REQUEST": {
      return {
        ...state,
        isCreatingCustomer: false,
        fetchingStatus: action.response.status
        // createCustomerRequestMessage: action.response.message
      };
    }
     case 'FETCH_CUSTOMER_TERMINALS_START': {
      return { ...state, fetching: true };
    }
    case 'FETCH_CUSTOMER_TERMINALS_FAILED': {
      return { ...state, fetching: false, error: action.payload };
    }
    case 'FETCH_CUSTOMER_TERMINALS_SUCCESS': {
      return { ...state, fetching: false, fetched: true, customerVendorTerminals: action.payload };
    }
    case 'FETCH_CUSTOMER_TERMINALS_CLEAR': {
      return {...state, customerVendorTerminals: []}
    }
    case "OCREATE_CUSTOMER_REJECTED": {
      return {
        ...state,
        isCreatingCustomer: false,
        fetchingStatus: action.response.status,
        customerCreationErrorMessage: action.response.message
      };
    }

    case "ADD_CUSTOMER_FINISED": {
      return {
        ...state,
        creatingCustomer: false,
        customerCreationErrorMessage: "",
        // fetchingStatus: 0
      };
    }

    case "RESET_CUSTOMER_UPDATE": {
      return {
        ...state,
        fetchingStatus: 0,
        isUpdatingCustomer: false
      };
    }

    case "UPDATE_CUSTOMER_REQUEST": {
      const {response, /* id, customer */} = action;
      return {
        ...state,
        isCreatingCustomer: false,
        fetchingStatus: response.status,
        // createCustomerRequestMessage: action.response.message
      };
    }

    case "UPDATE_CUSTOMERS_REJECTED": {
      return {
        ...state,
        isCreatingCustomer: false,
        fetchingStatus: action.response.status,
        customerCreationErrorMessage: action.response.message
      };
    }

    case "": {
      // const { id } = action.response.data;
      // const customerToUpdate = state.allCustomers.data.findIndex(customer => customer.id === id);
      // state.allCustomers.data[customerToUpdate] = action.response.data;
      return {
        ...state,
        createCustomerRequestStatus: action.response.status,
        createCustomerRequestMessage: action.response.message
      };
    }

    case 'TOGG_CUSTOMER_STATUS_START': {
      return {...state, isUpdatingCustomer: true, editingStatus: 0}
    }

    case 'TOGG_CUSTOMER_STATUS_FULLFILLED': {
      const { response, id, mode } = action;
      const newCustomers = [...state.allCustomers.data];
      const customerToUpdate = newCustomers.findIndex(customer => customer.id === id);
      newCustomers[customerToUpdate].status = mode;
      return {
        ...state,
        editingStatus: response.editingStatus,
        isUpdatingCustomer: false,
        allCustomers: {...state.allCustomers, data: newCustomers}
      }
    }

    case 'TOGG_CUSTOMER_STATUS_REJECTED': { 
      return {...state, isUpdatingCustomer: false, editingStatus: action.response.editingStatus}
    }

    case 'RESET_USER_UPDATE': {
      return {
        ...state,
        editingStatus: 0,
        isUpdatingCustomer: false
      }
    }

    case "RESET_CUSTOMER_CREATE_REQ_PARAMS": {
      return {
        ...state,
        createCustomerRequestStatus: action.response.fetchingStatus,
        createCustomerRequestMessage: action.response.message
      };
    }

    case "FETCH_USERS_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }

    case "FETCH_All_CUSTOMERS_START": {
      return { ...state, fetching: true, fetched: false };
    }

    case "FETCH_All_CUSTOMERS_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        allCustomers: action.payload
      };
    }

    case "FETCH_ONE_CUSTOMER_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        oneCustomer: action.payload
      };
    }

    case "ADD_CUSTOMER_USER": {
      return {
        ...state,
        userCreateModal: false,
        allCustomers: [...state.allCustomers, action.payload]
      };
    }

    case "UPDATE_USERS": {
      const { id } = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex(user => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers
      };
    }

    case "DELETE_USERS": {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload)
      };
    }
  }
  return state;
};

export default oCustomerReducer;
