const ownerUserReducer = (
  state = {
    users: {data: []},
    ownerUsers: [],
    allSysUsers: [],
    fetching: false,
    fetched: false,
    error: null,
    bulkCreateModal: false,
    userCreateModal: false,
    bulkUploadModal: false,
    bulkUploadResultModal: false,
    userEditModal: false,
    userDeleteModal: false,
    isCreatingUser: false,
    fetchingStatus: 0,
    editingStatus: 0,
    isUpdatingUser: false,
    userCreationErrorMessage: "",
    availableModules: [],
    assignedModules: [],
    isEditModuleMode: false,
    feeProcessing: false,
    feeStatus: 0,
  },
  action,
) => {
  switch (action.type) {
    case 'FETCH_USERS': {
      return { ...state, fetching: true };
    }

    case 'FETCH_MODULES_FULFILLED': {
        return { ...state, availableModules: action.payload }
    }

    case 'FETCH_USER_MODULES_FULFILLED': {
        return { ...state, assignedModules: action.response.data }
    }

    case 'EDIT_ASSIGNED_MODULE_START': {
        return { ...state, isEditModuleMode: true, }
    }

    case 'EDIT_ASSIGNED_MODULE_FULFILLED': {
        return {
            ...state, 
            isEditModuleMode: false,
            assignedModules: 
                action.isAssignMode
                ? [...state.assignedModules, action.response.data]
                : state.assignedModules.filter(m => (m.name !== action.response.data.name))
        }
    }

    case 'EDIT_ASSIGNED_MODULE_FAILED': {
        return {
            ...state, 
            isEditModuleMode: false,
            moduleAssignedEditErrorMessage: action.response.message
        }
    }

    case 'TOGGLE_MODAL': {
      return { ...state, userCreateModal: !state.userCreateModal };
    }

    case 'FETCH_USERS_REJECTED': {
      return { ...state, fetching: false, error: action.payload };
    }

    case 'FETCH_USERS_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, users: action.payload };
    }

    case 'ADD_OWNER_USER': {
      return {
        ...state,
        userCreateModal: false,
        ownerUsers: [...state.ownerUsers, action.payload],
      };
    }

    case 'RESET_ADD_OWNER_USER': {
      return {
        ...state,
        isCreatingUser: false,
        fetchingStatus: 0,
        userCreationErrorMessage: ""
      }
    }

    case 'ADD_OWNER_USER_START': {
      return {...state, isCreatingUser: true, fetchingStatus: 0}
    }

    case 'ADD_OWNER_USER_SUCCESS': {
      return { 
        ...state,
        isCreatingUser: false,
        // ownerUsers: [...state.ownerUsers, action.payload],
        fetchingStatus: action.response.fetchingStatus
      };
    }

    case 'ADD_OWNER_USER_FAILED': {
      return {
        ...state,
        isCreatingUser: false,
        fetchingStatus: action.response.fetchingStatus,
        userCreationErrorMessage: action.response.message,
      }
    }

    case 'START_USERS_STATUS': {
      return {...state, isUpdatingUser: true, editingStatus: 0}
    }

    case 'UPDATE_USERS_STATUS_FULFILLED': {
      const { response, id, mode } = action;
      const newUsers = [...state.users.data];
      const userToUpdate = newUsers.findIndex(user => user.id === id);
      newUsers[userToUpdate].status = mode;
      return {
        ...state,
        editingStatus: response.editingStatus,
        isUpdatingUser: false,
        users: {...state.users, data: newUsers}
      }
    }

    case 'UPDATE_USERS_STATUS_FAILED': { 
      return {...state, isUpdatingUser: false, editingStatus: action.response.editingStatus}
    }

    case 'RESET_USER_UPDATE': {
      return {
        ...state,
        editingStatus: 0,
        isUpdatingUser: false
      }
    }

    case 'UPDATE_USERS': {
      const { id } = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex(user => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers,
      };
    }

    case 'DELETE_USERS': {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }

    case 'FETCH_SYS_USERS_START': {
      return { ...state, fetching: true, fetched: true };
    }

    case 'FETCH_SYS_USERS_FULFILLED': {
      return { ...state, fetching: false, fetched: true, allSysUsers: action.payload };
    }

    case 'FETCH_SYS_USERS_REJECTED': {
      return { ...state, fetching: false, error: action };
    }

    case 'RESET_PASSWORD_START': {
      return { ...state, fetching: true, fetched: true };
    }

    case 'RESET_PASSWORD_REJECTED': {
      return { ...state, fetching: false, error: action};
    }
    case 'VENDOR_CONFIG_START': {
      return { ...state, feeProcessing: true };
    }

    case 'VENDOR_CONFIG_SUCCESS' : {
      return { ...state, feeProcessing: false, fee: action.payload, feeStatus: 1 };
    }

    case 'VENDOR_CONFIG_FAILED' : {
      return { ...state, feeProcessing: false, feeConfig: action.payload, description: action.response.message, feeStatus: 2 };
    }

    case 'GET_VENDOR_CONFIG_START': {
      return { ...state, feeLoading: true };
    }

    case 'GET_VENDOR_CONFIG_SUCCESS' : {
      return { ...state, feeLoading: false, feeConfig: action.response.data, getFee: 1, feeStatus: 0 };
    }

    case 'GET_VENDOR_CONFIG_FAILED' : {
      return { ...state, feeLoading: false, feeConfig: action.payload, description: action.response.message, feeStatus: 2 };
    }
    case 'VENDOR_CONFIG_RESET' : {
      return { ...state, feeLoading: false, feeProcessing : false, feeStatus: 0}
    }

    default:
      return state;
  }
};

export default ownerUserReducer;
