import React from "react";
import {
  /* BrowserRouter as Router, Route,  */ Redirect,
} from "react-router-dom";
import CScheme from "../../modules/customerModule/Tokens/Scheme/Scheme";
import CVouchers from "../../modules/customerModule/Tokens/Voucher/Voucher";
import CCards from "../../modules/customerModule/Tokens/Card/Cards";
import CTrails from "../../modules/customerModule/AuditTrail/AuditTrail";
import CDashboard from "../../modules/customerModule/Dashboard/Dashboard";
import CTransactions from "../../modules/customerModule/Reports/Transactions/Transactions";
import CContracts from "../../modules/customerModule/Contracts/Contracts";
import CVendors from "../../modules/customerModule/Contracts/Vendors";
import CSettlements from "../../modules/customerModule/Reports/Settlements/Settlements";
import CUsers from "../../modules/customerModule/Accounts/Users/Users";
import AuditTrail from "../../modules/ownerModule/AuditTrail/AuditTrail";
import Terminals from "../../modules/ownerModule/Terminals/Terminals/Terminals";
import Cards from "../../modules/ownerModule/Tokens/Card/Cards";
import Tokens from "../../modules/ownerModule/Tokens/Rfid/Rfids";
import Dashboard from "../../modules/ownerModule/Common/Dashboard/Dashboard";
import Transactions from "../../modules/ownerModule/Reports/Transactions/Transactions";
import Settlements from "../../modules/ownerModule/Reports/Settlements/Settlements";
import Contracts from "../../modules/ownerModule/Contracts/Contracts";
import Users from "../../modules/ownerModule/Accounts/Users/Users";
import Vendors from "../../modules/ownerModule/Accounts/Vendors/Vendors";
import Customers from "../../modules/ownerModule/Accounts/Customers/Customers";
import PosUsers from "../../modules/ownerModule/Terminals/Pos/PosUsers";
import ChangePassword from "../../modules/Auth/ChangePassword";
import VDashboard from "../../modules/vendorModule/Dashboard/Dashboard";
import VContracts from "../../modules/vendorModule/Contracts/Contracts";
import VUsers from "../../modules/vendorModule/Accounts/Users/Users";
import VTerminals from "../../modules/vendorModule/Terminals/Terminals/Terminals";
import VPOSUser from "../../modules/vendorModule/Terminals/Pos/PosUsers";
import VCustomers from "../../modules/vendorModule/Accounts/Customers/Customers";
import VProducts from "../../modules/vendorModule/Products/Products";
import VTrail from "../../modules/vendorModule/AuditTrail/AuditTrail";
import VTransactions from "../../modules/vendorModule/Reports/Transactions/Transactions";
import VSettlements from "../../modules/vendorModule/Reports/Settlements/Settlements";
import SDashboard from "../../modules/vendorModule/Dashboard/SDashboard";
import StationPosUsers from "../../modules/vendorModule/Terminals/Pos/StationManager/StationPosUser";
import StationTransactions from "../../modules/vendorModule/Reports/Transactions/StationManager/Transactions";
import Products from "../../modules/vendorModule/Products/Products";
import PaymentsPage from "../../modules/shared/Payments";
import Invoices from "../../modules/shared/Payments/Invoice";
import Drivers from "../../modules/customerModule/Reports/Drivers/Driver";
import Fees from "../../modules/shared/Payments/Fees/Fees";
import SmsFee from "../../modules/customerModule/SmsFee/SmsFee";
import SettlementContracts from "../../modules/customerModule/Reports/SettlementContracts/Contract";

export const ownerRoutes = [
  {
    path: "/",
    exact: true,
    main: () => {
      const userTypeFromStorage = JSON.parse(localStorage.getItem("user")).type;

      if (
        userTypeFromStorage === "CUSTOMER_USER" ||
        userTypeFromStorage === "CUSTOMER_ADMIN"
      ) {
        return <Redirect to="/customer-admin/dashboard" />;
      } else if (
        [
          "VENDOR_USER",
          "VENDOR_ADMIN",
          "VENDOR_SUPER_ADMIN",
          "LOGISTIC",
          "PROCUREMENT",
        ].includes(userTypeFromStorage)
      ) {
        return <Redirect to="/vendor-admin/dashboard" />;
      } else if (
        userTypeFromStorage === "OWNER_USER" ||
        userTypeFromStorage === "SUPER_ADMIN"
      ) {
        return <Redirect to="/owner-admin/dashboard" />;
      }
    },
  },
  {
    path: "/owner-admin",
    exact: true,
    main: () => <Redirect to="/owner-admin/dashboard" />,
  },
  {
    path: "/owner-admin/users",
    exact: true,
    main: () => <Redirect to="/owner-admin/users/main" />,
  },
  {
    path: "/owner-admin/terminals/",
    exact: true,
    main: () => <Redirect to="/owner-admin/terminals/main" />,
  },
  {
    path: "/owner-admin/cards/",
    exact: true,
    main: () => <Redirect to="/owner-admin/cards/main" />,
  },
  {
    path: "/owner-admin/contracts/",
    exact: true,
    main: () => <Redirect to="/owner-admin/contracts/main" />,
  },
  {
    path: "/owner-admin/reports/",
    exact: true,
    main: () => <Redirect to="/owner-admin/reports/main" />,
  },
  {
    path: "/owner-admin/settlements/",
    exact: true,
    main: () => <Redirect to="/owner-admin/settlements/main" />,
  },
  {
    path: "/owner-admin/vendors/",
    exact: true,
    main: () => <Redirect to="/owner-admin/vendors/main" />,
  },
  {
    path: "/owner-admin/customers/",
    exact: true,
    main: () => <Redirect to="/owner-admin/customers/main" />,
  },
  {
    path: "/owner-admin/dashboard",
    exact: false,
    main: () => <Dashboard />,
  },
  {
    path: "/owner-admin/rfid/main",
    exact: false,
    main: () => <Tokens />,
  },
  {
    path: "/owner-admin/cards/main",
    exact: false,
    main: () => <Cards />,
  },
  {
    path: "/owner-admin/terminals/main",
    exact: false,
    main: () => <Terminals />,
  },
  {
    path: "/owner-admin/users/main",
    exact: false,
    main: () => <Users />,
  },
  {
    path: "/owner-admin/contracts/main",
    exact: false,
    main: () => <Contracts />,
  },
  {
    path: "/owner-admin/vendors/main",
    exact: false,
    main: () => <Vendors />,
  },

  {
    path: "/owner-admin/vendors/vendor-details/:id",
    exact: false,
    main: () => <Vendors />,
  },
  {
    path: "/owner-admin/trail",
    exact: false,
    main: () => <AuditTrail />,
  },
  {
    path: "/owner-admin/sms-fee",
    exact: false,
    main: () => <SmsFee />,
  },
  {
    path: "/owner-admin/posusers/main",
    exact: false,
    main: () => <PosUsers />,
  },
  {
    path: "/owner-admin/reports/main",
    exact: false,
    main: () => <Transactions />,
  },
  {
    path: "/owner-admin/settlements/main",
    exact: false,
    main: () => <Settlements />,
  },
  {
    path: "/owner-admin/drivers/main",
    exact: false,
    main: () => <Drivers />,
  },
  {
    path: "/owner-admin/report/settlement-contracts/main",
    exact: false,
    main: () => <SettlementContracts />,
  },
  {
    path: "/owner-admin/customers/main",
    exact: false,
    main: () => <Customers />,
  },
  {
    path: "/owner-admin/change-password/",
    exact: true,
    main: () => <Redirect to="/owner-admin/change-password/main" />,
  },
  {
    path: "/owner-admin/change-password/main",
    exact: true,
    main: () => <ChangePassword />,
  },
  {
    path: "/owner-admin/payments",
    exact: false,
    main: () => <PaymentsPage />,
  },
];

export const vendorRoutes = [
  {
    path: "/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/dashboard" />,
  },
  {
    path: "/vendor-admin",
    exact: true,
    main: () => <Redirect to="/vendor-admin/dashboard" />,
  },
  {
    path: "/vendor-admin/dashboard",
    exact: false,
    main: () => <VDashboard />,
  },
  {
    path: "/vendor-admin/change-password/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/change-password/main" />,
  },
  {
    path: "/vendor-admin/change-password/main",
    exact: false,
    main: () => <ChangePassword />,
  },
  {
    path: "/vendor-admin/users/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/users/main" />,
  },
  {
    path: "/vendor-admin/users/main",
    exact: false,
    main: () => <VUsers />,
  },
  {
    path: "/vendor-admin/customers/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/customers/main" />,
  },
  {
    path: "/vendor-admin/customers/main",
    exact: false,
    main: () => <VCustomers />,
  },
  {
    path: "/vendor-admin/contracts/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/contracts/main" />,
  },
  {
    path: "/vendor-admin/contracts/main",
    exact: false,
    main: () => <VContracts />,
  },
  {
    path: "/vendor-admin/terminals/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/terminals/main" />,
  },
  {
    path: "/vendor-admin/terminals/main",
    exact: false,
    main: () => <VTerminals />,
  },
  {
    path: "/vendor-admin/products/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/products/main" />,
  },
  {
    path: "/vendor-admin/products/main",
    exact: false,
    main: () => <VProducts />,
  },
  {
    path: "/vendor-admin/trail/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/trail/main" />,
  },
  {
    path: "/vendor-admin/trail/main",
    exact: false,
    main: () => <VTrail />,
  },
  {
    path: "/vendor-admin/reports/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/reports/main" />,
  },
  {
    path: "/vendor-admin/reports/main",
    exact: false,
    main: () => <VTransactions />,
  },
  {
    path: "/vendor-admin/settlements/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/settlements/main" />,
  },
  {
    path: "/vendor-admin/settlements/main",
    exact: false,
    main: () => <VSettlements />,
  },
  {
    path: "/vendor-admin/posusers/",
    exact: true,
    main: () => <Redirect to="/vendor-admin/posusers/main" />,
  },
  {
    path: "/vendor-admin/posusers/main",
    exact: false,
    main: () => <VPOSUser />,
  },
  //Station manager routes
  {
    path: "/vendor-admin/station/dashboard",
    exact: false,
    main: () => <SDashboard />,
  },
  {
    path: "/vendor-admin/station/terminals",
    exact: false,
    main: () => <StationPosUsers />,
  },
  {
    path: "/vendor-admin/station/reports/",
    exact: true,
    main: () => <StationTransactions />,
  },
  {
    path: "/vendor-admin/station/products",
    exact: true,
    main: () => <Products />,
  },
  {
    path: "/vendor-admin/payments",
    exact: true,
    main: () => <PaymentsPage />,
  },
  //  {
  //    path: "/vendor-admin/products/",
  //    exact: true,
  //    main: () => <Redirect to="/vendor-admin/products/main" />,
  //  },
  //  {
  //    path: "/vendor-admin/customers/",
  //    exact: true,
  //    main: () => <Redirect to="/vendor-admin/customers/main" />,
  //  },
  //  {
  //    path: "/vendor-admin/contracts/",
  //    exact: true,
  //    main: () => <Redirect to="/vendor-admin/contracts/main" />,
  //  },
  //  {
  //    path: "/vendor-admin/change-password/",
  //    exact: true,
  //    main: () => <Redirect to="/vendor-admin/change-password/main" />,
  //  },
  //  {
  //    path: "/vendor-admin/change-password/main",
  //    exact: true,
  //    main: () => <ChangePassword />,
  //  },
  // {
  //   path: '/vendor-admin/customers/contracts',
  //   exact: false,
  //   main: () => <VCreateCustomer />,
  // },
];

export const customerRoutes = [
  {
    path: "/",
    exact: true,
    main: () => <Redirect to="/customer-admin/dashboard" />,
  },
  {
    path: "/customer-admin/dashboard",
    exact: false,
    main: () => <CDashboard />,
  },
  {
    path: "/customer-admin/users",
    exact: true,
    main: () => <Redirect to="/customer-admin/users/main" />,
  },
  {
    path: "/customer-admin/users/main",
    exact: false,
    main: () => <CUsers />,
  },
  {
    path: "/customer-admin/contracts/",
    exact: true,
    main: () => <Redirect to="/customer-admin/contracts/main" />,
  },
  {
    path: "/customer-admin/contracts/main",
    exact: false,
    main: () => <CContracts />,
  },
  {
    path: "/customer-admin/vendors/",
    exact: true,
    main: () => <Redirect to="/customer-admin/vendors/main" />,
  },
  {
    path: "/customer-admin/vendors/main",
    exact: false,
    main: () => <CVendors />,
  },
  {
    path: "/customer-admin/trail/",
    exact: true,
    main: () => <Redirect to="/customer-admin/trail/main" />,
  },
  {
    path: "/customer-admin/trail/main",
    exact: false,
    main: () => <CTrails />,
  },
  {
    path: "/customer-admin/reports/",
    exact: true,
    main: () => <Redirect to="/customer-admin/reports/main" />,
  },
  {
    path: "/customer-admin/drivers/main",
    exact: false,
    main: () => <Drivers />,
  },
  {
    path: "/customer-admin/report/settlement-contracts/main",
    exact: false,
    main: () => <SettlementContracts />,
  },
  {
    path: "/customer-admin/reports/main",
    exact: false,
    main: () => <CTransactions />,
  },
  {
    path: "/customer-admin/settlements/",
    exact: true,
    main: () => <Redirect to="/customer-admin/settlements/main" />,
  },
  {
    path: "/customer-admin/settlements/main",
    exact: false,
    main: () => <CSettlements />,
  },
  {
    path: "/customer-admin/scheme/",
    exact: true,
    main: () => <Redirect to="/customer-admin/scheme/main" />,
  },
  {
    path: "/customer-admin/scheme/main",
    exact: false,
    main: () => <CScheme />,
  },
  {
    path: "/customer-admin/vouchers/main",
    exact: false,
    main: () => <CVouchers />,
  },
  {
    path: "/customer-admin/cards/",
    exact: true,
    main: () => <Redirect to="/customer-admin/cards/main" />,
  },
  {
    path: "/customer-admin/cards/main",
    exact: false,
    main: () => <CCards />,
  },
  {
    path: "/customer-admin/payments",
    exact: true,
    main: () => <Redirect to="/customer-admin/payments/main" />,
  },
  {
    path: "/customer-admin/payments/main",
    exact: false,
    main: () => <PaymentsPage />,
  },
  {
    path: "/customer-admin/invoices",
    exact: true,
    main: () => <Redirect to="/customer-admin/invoices/main" />,
  },
  {
    path: "/customer-admin/invoices/main",
    exact: false,
    main: () => <Invoices />,
  },
  {
    path: "/customer-admin/fees/main",
    exact: false,
    main: () => <Fees />,
  },
  {
    path: "/admin/",
    exact: true,
    main: () => <Redirect to="/customer-admin/dashboard" />,
  },
  {
    path: "/admin/vendor/",
    exact: true,
    main: () => <Redirect to="/customer-admin/vendor/main" />,
  },
  {
    path: "/admin/schemes/",
    exact: true,
    main: () => <Redirect to="/customer-admin/schemes/main" />,
  },
  {
    path: "/admin/reports/",
    exact: true,
    main: () => <Redirect to="/customer-admin/reports/main" />,
  },
  {
    path: "/customer-admin/change-password/",
    exact: true,
    main: () => <Redirect to="/customer-admin/change-password/main" />,
  },
  {
    path: "/customer-admin/change-password/main",
    exact: false,
    main: () => <ChangePassword />,
  },
];

export const schemeRoutes = [
  // {
  //   path: '/',
  //   exact: true,
  //   // main: () => <Redirect to="/admin/dashboard" />,
  //   main: () => <Redirect to="/scheme-admin/schemes" />,
  // },
  {
    // path: '/admin/',
    path: "/scheme-admin/",
    exact: true,
    // main: () => <Redirect to="/admin/dashboard" />,
    main: () => <Redirect to="/scheme-admin/schemes" />,
  },

  // {
  //   path: '/admin/vendor/',
  //   exact: true,
  //   main: () => <Redirect to="/admin/vendor/main" />,
  // },
  {
    path: "/scheme-admin/schemes",
    exact: true,
    main: () => <Redirect to="/scheme-admin/schemes/main" />,
  },
  // {
  //   path: '/admin/reports/',
  //   exact: true,
  //   main: () => <Redirect to="/admin/reports/main" />,
  // },
  // {
  //   path: '/admin/dashboard',
  //   exact: false,
  //   main: () => <CDashboard />,
  // },
  // {
  //   path: '/admin/reports/main',
  //   exact: false,
  //   main: () => <Report />,
  // },
  // {
  //   path: '/admin/vendor/main',
  //   exact: false,
  //   main: () => <Vendorlist />,
  // },
  // {
  //   path: '/admin/vendor/vendor-details/:id',
  //   exact: false,
  //   main: () => <Vendordetails />,
  // },
  // {
  //   path: '/admin/vendor/:id/vendor-products/',
  //   exact: false,
  //   main: () => <Vendorproducts />,
  // },

  // {
  //   path: '/admin/schemes/archived-cards',
  //   exact: false,
  //   main: () => <Archivecardlist />,
  // },

  // {
  //   path: '/admin/schemes/all/card/bulk-pin-suspend',
  //   exact: false,
  //   main: () => <Cardbulkpinsuspend />,
  // },
  // {
  //   path: '/admin/users',
  //   exact: false,
  //   main: () => <Userlist />,
  // },
  // {
  //   path: '/admin/trail',
  //   exact: false,
  //   main: () => <Trail />,
  // },
  // {
  //   path: '/admin/reports/transaction',
  //   exact: false,
  //   main: () => <Reporttransaction />,
  // },
  // {
  //   path: '/admin/reports/settlement',
  //   exact: true,
  //   main: () => <Reportsettlement />,
  // },
];
