import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import { DatePicker, Select } from "antd";
import ReportStatusBar from '../StatusBar';
import { downloadSettlementReport, getAllSettlements } from "../../redux/action/OwnerTrailActions";
import SettlementList from "./SettlementList";
import moment from "moment";
import fileDownload from "js-file-download";

function Settlements () {
  const dispatch = useDispatch();
  const startDate = moment(new Date(moment().subtract(365, 'days').format('LLLL')));
  const endDate = moment(new Date(moment().subtract(0, 'days').format('LLLL')));
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [vendorNum, setVendorNum] = useState("");
  const [ customerNum, setCustomerNum ] = useState("");
  const props = useSelector(state => state);
  const { allVendors } = props.oVendorReducer;
  const { allCustomers } = props.oCustomerReducer;

  useEffect(() => {
    if (props.oTrailReducer.oTransactionsBlob) {
      fileDownload(props.oTrailReducer.oTransactionsBlob, 'Settlement-Report.xlsx');
      dispatch({ type: 'DOWNLOAD_ALL_TRANSACTION_RESET' });
    }
  }, [props.oTrailReducer.oTransactionsBlob]);

  useEffect(()=> {
      // dispatch(fetchAllTransactions(startDate, endDate, 1, 20, 2, {vendorNum: null, customerNum: null, location: null}));
      dispatch(getAllSettlements(1, 10, dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'), vendorNum ));
  }, [])

  const handleCalendarChange = ([startDate0 = startDate, endDate0 = endDate]) => {
    setDateRange([moment(startDate0), moment(endDate0)]);
  };

  const handleSearch =()=>{
    dispatch(getAllSettlements(1, 10, dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'), vendorNum, customerNum ));
  }

  const handleDownload =() =>{
    dispatch(downloadSettlementReport(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'), vendorNum, customerNum))
  }

  return (
    <div className="dashboard-container">
      <ReportStatusBar {...props} isVisible={true} type='settlement' />
      <div className="row">
        <div className="col-md-5 text-right pr-0 mt-md-1 mt-2">
          <Select 
            defaultValue={vendorNum} style={{ minWidth: 135 }} 
            className="mr-3"
            placeholder="Choose Vendor"
            
            onChange={value => setVendorNum(value)}>
            <Select.Option value="">All Vendors</Select.Option>
            {(allVendors.data || []).filter(v => v.name).map((v, i) => (
              <Select.Option value={v.number} key={i}>{v.name}</Select.Option>
            ))}
          </Select>
          <Select 
            defaultValue={customerNum} 
            className="mr-3"
            placeholder="Choose Customer"
            // allowClear 
            // showSearch
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={value => setCustomerNum(value)}>
            <Select.Option value="">All Customers</Select.Option>
            {(allCustomers.data || []).map((c, i) => (
              <Select.Option value={c.number} key={i}>{c.name}</Select.Option>
            ))}
          </Select>
        </div>
       
        <div className="col-md-4 text-right pr-0 mt-md-1 mt-2">
          <DatePicker.RangePicker
            defaultValue={dateRange}
            onCalendarChange={handleCalendarChange}
            format={"DD-MMMM-YYYY"}
            allowClear={false}
          />
        </div>
        <div className="col-md text-right pr-0 mt-md-1 mt-2">
          <button className="btn btn-primary" onClick={handleSearch}>Search</button>
        </div>
      </div>
      <SettlementList {...props} onDownloadTransactions={handleDownload}
        onPageChange={(pageNo, pageSize) => {
          dispatch(getAllSettlements(
            pageNo,
            pageSize,
            dateRange[0].format('YYYY-MM-DD'), 
            dateRange[1].format('YYYY-MM-DD'), 
            vendorNum, customerNum,
          ))
        }}
      />
    </div>
  )
}

export default Settlements;