import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../../assets/Styles/Dashboard.css";
import ReportStatusBar from '../../StatusBar';
import { DatePicker, Radio } from "antd";
import moment from "moment";
import StatusTransactionList from "./TransactionList";
import fileDownload from "js-file-download";
import { downloadTransactionsByvendor,  getTransactionsByvendor } from "../../../../ownerModule/redux/action/OwnerTrailActions";
import { fetchAllLocationsByVendor } from "../../../../ownerModule/redux/action/OwnerVendorActions";
import { loadAllVendorCustomer } from "../../../redux/actions/CustomerActions";
import { getUserProperties } from "../../../../../lib/Constants/UserTypes";
import { fetchTerminalDetail } from "../../../../customerModule/redux/action/VendorAction";

function StationTransactions () {
  const dispatch = useDispatch();

  const startDate = moment(new Date(moment().subtract(365, 'days').format('LLLL')))
  const endDate = moment(new Date(moment().subtract(0, 'days').format('LLLL')));

  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [status, setStatus] = useState(5);
  const [ loading, setLoading ] = useState(null);

  const props = useSelector(state => state);
  const { terminalDetail } = props.oVendorReducer;
  const { data } = terminalDetail;
  const { vendor = 0, vendorNumber = '' } = getUserProperties() || {};

  useEffect(() => {
    if (props.vendorCustomer.getAllTransactionbyVendorIdBlob) {
      fileDownload(props.vendorCustomer.getAllTransactionbyVendorIdBlob, 'Transaction-Report.xlsx');
      dispatch({ type: 'DOWNLOAD_ALL_VENDORS_TRANSACTION_RESET' });
    }
  }, [props.vendorCustomer.getAllTransactionbyVendorIdBlob]);

  useEffect(()=>{
    if(!terminalDetail.data) {
      dispatch(fetchTerminalDetail())
    }
  }, [])

  useEffect(()=> {
    if(terminalDetail.data) {
      dispatch(
        getTransactionsByvendor(vendorNumber ,dateRange[0].format('YYYY-MM-DD'),
        dateRange[1].format('YYYY-MM-DD'), 1, 10, status,
        {
          terminalId : data.terminalId,
        },
        (status) => {
          setLoading(status);
        }
      ));
    } 
  }, [dateRange, status, terminalDetail.data]);
  
  useEffect(() => {
    batch(() => {
      dispatch(loadAllVendorCustomer(vendor, 1, 10000000));
      dispatch(fetchAllLocationsByVendor(vendor))
    });
  }, []);

  const handleCalendarChange = ([startDate0 = startDate, endDate0 = endDate]) => {
    setDateRange([moment(startDate0), moment(endDate0)]);
  };

  return (
    <div className="dashboard-container">
      <ReportStatusBar {...props} isVisible={true} />
      
      <div className="row">
        <div className="col-md-5 text-right pr-0 mt-md-1 mt-2">
          <span className="filter-tags-desc">Filters:</span>
        </div>
        <div className="col-md-3 text-right pr-0 mt-md-1 mt-2">
          <Radio.Group defaultValue={status} buttonStyle="solid" onChange={(e) => setStatus(e.target.value)}>
            <Radio.Button value={5}>ALL</Radio.Button>
            <Radio.Button value={1}>SUCCESSFUL</Radio.Button>
            <Radio.Button value={0}>FAILED</Radio.Button>
          </Radio.Group>
        </div>
        <div className="col-md-4 text-right pr-0 mt-md-1 mt-2">
          <DatePicker.RangePicker
            defaultValue={dateRange}
            onCalendarChange={handleCalendarChange}
            format={"DD-MMMM-YYYY"}
            allowClear={false}
          />
        </div>
      </div>

      <StatusTransactionList
        {...props}
        downloading={props.vendorCustomer.downloading}
        onDownloadTransactions={() => {
          dispatch(downloadTransactionsByvendor(
            vendorNumber,
            dateRange[0].format('YYYY-MM-DD'), 
            dateRange[1].format('YYYY-MM-DD'), 
            status,
            { terminalId: data.terminalId },
            'xlsx'))
        }}
        onFetchTransactions={(pageNo, pageSize) =>
          dispatch(
            getTransactionsByvendor(
              vendorNumber,
              dateRange[0].format('YYYY-MM-DD'), 
              dateRange[1].format('YYYY-MM-DD'), 
              pageNo, 
              pageSize, 
              status, 
              { terminalId: data.terminalId },
              (status) => {
                setLoading(status);
              }
            )
          )
        }
        loading={loading}
      />
    </div>
  );
}

export default StationTransactions;