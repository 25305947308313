import React/* , {useEffect} */ from "react";
import {useDispatch, useSelector/* , batch */} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import StatusBar from '../StatusBar';
import TerminalList from "./TerminalList";
import { useState } from "react";
import {
  submitTerminalForm,
  fetchAllTerminalsByVendor,
  submitTerminalUpdateForm,
} from "../../../ownerModule/redux/action/OwnerVendorActions";
import { getUserProperties } from "../../../../lib/Constants/UserTypes";

function Terminals () {
  const dispatch = useDispatch();
  const props = useSelector(state => state);
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);

  const createTerminal = async (data, callback) => {
    let body = {
      merchantId: data.merchantId,
      terminalId: data.terminalId,
      dealerCode: data.dealerCode,
      name: data.terminalName,
      state: data.state,
      address: data.address
    };
    await dispatch(submitTerminalForm(getUserProperties().vendor, body));
    callback(true);
  }

  const editTerminal = async (data, callback) => {
    let body = {
      merchantId: data.merchantId,
      terminalId: data.terminalId,
      dealerCode: data.dealerCode,
      name: data.terminalName,
      address: data.address,
      state: data.state
    };
    await dispatch(submitTerminalUpdateForm(body));
    callback(true);
  }
  
  return (
    <div className="dashboard-container">
      {isStatusBarVisible && <StatusBar {...props} isVisible={true} />}
      <TerminalList {...props} onFetchTerminals={(pageNo, size, state) => {
        dispatch(
          fetchAllTerminalsByVendor(getUserProperties().vendor, pageNo, size, state)
        );
      }} onHideStatusBar={status => {
        setIsStatusBarVisible(!status);
      }}
      onCreateTerminal={createTerminal}
      onEditTerminal={editTerminal}
      />
    </div>
  )
}

export default Terminals;