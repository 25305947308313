import React, { useEffect, useState } from 'react';
import { DatePicker, Select, Input } from "antd";
import moment from 'moment';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getAllVendors } from '../../ownerModule/Accounts/Vendors/vendors.actions';
import { getUserProperties } from '../../../lib/Constants/UserTypes';
import { loadAllVendorCustomer } from '../../vendorModule/redux/actions/CustomerActions';
import { fetchAllCustomers } from '../../ownerModule/Accounts/Customers/customers.actions';
import { loadAllCustomerVendors } from '../../customerModule/redux/action/VendorAction';

const PaymentsFilter = ({ onUpdateSearchTerms }) => {


    const startDate = moment(new Date(moment().subtract(365, 'days').format('LLLL')));
    const endDate = moment(new Date(moment().subtract(0, 'days').format('LLLL')));
    const [dateRange, setDateRange] = useState([startDate, endDate]);
    const [vid, setVendorNum] = useState("");
    const [cid, setCustomerNum] = useState("");
    const [status, setStatus] = useState("");
    const [paymentRef, setPaymentRef] = useState("")
    const { type, userLevel, vendor, customer } = getUserProperties();
    const dispatch = useDispatch();

    useEffect(() => {
        batch(() => {
            if(type === 'OWNER_USER'){
                dispatch(getAllVendors(1, 1000000000));
                dispatch(fetchAllCustomers(1, 100000000))
            }
            
            if(type === 'VENDOR_USER'){
            dispatch(loadAllVendorCustomer(vendor, 1, 100000000))
            }

            if(type === 'CUSTOMER_USER'){
                dispatch(loadAllCustomerVendors(customer))
            }
        })
    }, [])

    const handleCalendarChange = ([startDate0 = startDate, endDate0 = endDate]) => {
        setDateRange([moment(startDate0), moment(endDate0)]);
    };

    const handleSearch = () => {
        let obj = {
            to: dateRange[1].format('YYYY-MM-DD'),
            from: dateRange[0].format('YYYY-MM-DD'),
            vid,
            cid,
            status,
            reference: paymentRef
        }
        onUpdateSearchTerms(obj);
    }

    const props = useSelector(state => state);
    const { allVendors } = props.oVendorReducer;
    const { allCustomers } = props.oCustomerReducer;
    const { activeCustomers } = props?.vendorCustomer;
    const { allCustomerVendors } = props.customerVendor;

    return (
        <div className='filter-container'>
            <div style={{ marginBottom: 28 }} className='d-flex justify-content-between'>
                <span>
                    {(type === "CUSTOMER_USER" || type === "OWNER_USER") &&
                        <Select
                            // defaultValue={customerNum} 
                            className="mr-1 filter-select"
                            placeholder="Choose vendor"
                            // allowClear 
                            showSearch
                            // filterOption={(input, option) =>
                            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                            onChange={value => setVendorNum(value)}>
                            <Select.Option value="">All Vendors</Select.Option>
                            {(allVendors.data || allCustomerVendors?.data || []).filter(v => v.status === "ACTIVE").map((v, i) => (
                                <Select.Option value={v.id} key={i}>{v.name}</Select.Option>
                            ))}
                        </Select>}
                    {(type === 'VENDOR_USER' || type === "OWNER_USER") &&
                        <Select
                            // defaultValue={customerNum} 
                            className="mr-3 filter-select"
                            placeholder="Choose Customer"
                            // allowClear 
                            showSearch
                            // filterOption={(input, option) =>
                            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                            onChange={value => setCustomerNum(value)}
                        >
                            <Select.Option value="">All Customers</Select.Option>
                            {(allCustomers.data || activeCustomers || []).filter(c => c.status === "ACTIVE").map((c, i) => (
                                <Select.Option value={c.id} key={i}>{c.name}</Select.Option>
                            ))}
                        </Select>
                    }
                </span>
                <span>
                    <Select
                        className="mr-1 filter-select"
                        placeholder="Choose Status"
                        onChange={value => setStatus(value)}>
                        <Select.Option value="">All Status</Select.Option>
                        <Select.Option value="PENDING">PENDING</Select.Option>
                        <Select.Option value="SUCCESS">SUCCESS</Select.Option>
                        <Select.Option value="FAILED">FAILED</Select.Option>
                    </Select>
                </span>
                <span>
                    <DatePicker.RangePicker
                        style={{
                            maxWidth: 290
                        }}
                        className='filter-select'
                        defaultValue={dateRange}
                        onCalendarChange={handleCalendarChange}
                        format={"DD-MMMM-YYYY"}
                        allowClear={false}
                    />
                </span>
            </div>
            <div className='d-flex justify-content-between'>
                <h2 className='payments-header'>All Payments</h2>
                <span>
                    <Input
                        onChange={(e) => { setPaymentRef(e.target.value) }}
                        placeholder="Search Payment Reference"
                        style={{ width: 302,
                            marginRight: 10
                        }}
                    //  prefix={< className="site-form-item-icon" />}

                    />
                    <button className='add-btn' onClick={handleSearch}>Search</button>
                </span>
            </div>
        </div>
    )
}
export default PaymentsFilter;