import React from "react";
import PropTypes from "prop-types"

export default function ToastAlert({showToast, parentClass='', childClass='', toastText, onCloseHandler}) {
  return (
    <div className={parentClass}>
      {!!showToast && (
        <div className={`error-container error-text ${childClass}`}>
          <span
            className="error-close"
            onClick={onCloseHandler}
          >
            x
          </span>
          <span className={` ${childClass}`}>{toastText}</span>
        </div>
      )}
    </div>
  );
}

ToastAlert.propTypes = {
    showToast: PropTypes.bool.isRequired,
    parentClass: PropTypes.string.isRequired,
    onCloseHandler: PropTypes.func.isRequired,
    toastText: PropTypes.string.isRequired,
    childClass: PropTypes.string.isRequired
}
