import React/* , {useEffect} */ from "react";
// import {useDispatch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import { TransactionSVG, AnalyticsSVG, SalesSVG } from "../../../../assets/Svgs/Svgs";
import { Bar, Line } from "react-chartjs-2";
import { longMonthNames } from "../../../../lib/Functions/DateNames";
import formatAmount from "../../../../lib/Functions/formatAmount";

const Analytics =  (props) => {
  const {oTrailReducer, currentDate} = props;
  const {
    oTransactions: { content, /* , count */ },
    transactionFrequencies: {
      dailyAmount = 0,
      dailyVolume = 0,
      weeklyAmount = 0,
      weeklyVolume = 0,
      monthlyAmount = 0,
      monthlyVolume = 0,
    },
  } = oTrailReducer;

  let checkArray = [], checkArrayC = [];
  let dataArray = [], dataArrayC = [];

  !!content && content.forEach((val) => {
    // console.log(`val-array: ${JSON.stringify(val,null,2)}`);
    const customer = val.customer || val.customerNumber;
    const vendor = val.vendor || val.vendorNumber;

    if(!checkArray.includes(vendor)){
      checkArray.push(vendor);
      dataArray.push({vendor, num: 0});
    }

    if(!checkArrayC.includes(customer)){
      checkArrayC.push(customer);
      dataArrayC.push({customer, num: 0});
    }

    dataArray.forEach((item, i) => {
      if(item.vendor === vendor) {
        dataArray[i].num += 1;
      }
    })

    dataArrayC.forEach((item, i) => {
      if(item.customer === customer) {
        dataArrayC[i].num += 1;
      }
    })
  });

  dataArray.sort((a, b) => (a.num - b.num)).splice(3);
  dataArrayC.sort((a, b) => (a.num - b.num)).splice(3);

  checkArray = dataArray.map((val) => (val.vendor || val.vendorNumber));
  checkArrayC = dataArrayC.map((val) => (val.customer || val.customerNumber));

  let numArray = dataArray.map(val => val.num);
  let numArrayC = dataArrayC.map(val => val.num);

  const today = new Date(currentDate);
  const currentMonth = today.getMonth() % 12;
  const lastThreeMonths = {
    'names': [
      longMonthNames[((currentMonth - 2) < 0 ? (12 - Math.abs(currentMonth - 2)) : (currentMonth - 2))],
      longMonthNames[((currentMonth - 1) < 0 ? (12 - Math.abs(currentMonth - 1)) : (currentMonth - 1))],
      longMonthNames[currentMonth],
    ],
    'numbers': [(currentMonth - 2), (currentMonth - 1), currentMonth]
  };

  return (
    <div className="charts-container row">
      <div className="col-4">
        <span className="text-nowrap">
          <span className="title d-inline mr-3">Performance Analytics - January</span><AnalyticsSVG />
        </span>
        <span className="sub-title">The table shows the top 3 highest transacting and value of vendors and corporate customers for the month. </span>
      </div>
      <div className="col-4"> 
        <span className="text-nowrap"><span className="title d-inline mr-3">3 Months Transaction Trends</span><TransactionSVG color={"#00B8DE"} /></span>
        <span className="sub-title">The graph represents a 3 months transaction trends and value of transaction.</span>
      </div>
      <div className="col-4">
        <span className="text-nowrap"><span className="title d-inline mr-3">Sales and Revenue Inflows - January</span><SalesSVG /></span>
        <span className="sub-title">Daily, Weekly and Monthly transaction inflow.</span><br/>    
      </div>
      <div className="row">
        <div className="col-4 bg-white pt-3 pb-3 mr-0"> 
          <Bar
            data = {{
              labels: [...checkArray, ...checkArrayC],
              datasets: [
                {
                  label: 'Vendors / Customers',
                  data: [...numArray, ...numArrayC],
                  backgroundColor: '#025ec3',
                  borderColor: '#025ec3',
                  borderWidth: 1,
                  hoverBackgroundColor: '#025ec3',
                  hoverBorderColor: '#025ec3',
                  // hoverBorderColor: '#0999c3',
                }
              ],
            }}
            width = {6}
            height = {5}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    offset: true,
                    // ticks: {
                    //   beginAtZero: true,
                    // },
                  },
                ],
              },
            }}
          />
        </div>
        <div className="col-4 bg-white pt-3 pb-3 ml-0"> 
          {/* <img src="/images/new/trendsChart.png" alt="trends-chart" style={{width: "100%", height: 240, objectFit: "fill"}} /> */}
          <Line
            data={{
              labels: lastThreeMonths['names'],
              datasets: [
                {
                  label: 'Value of Transactions',
                  data: [12, 19, 3],
                  fill: false,
                  backgroundColor: 'rgb(255, 255, 255)',
                  borderColor: 'rgba(7, 80, 168, 0.8)',
                  // borderColor: 'rgba(7, 80, 168, 0.3)',
                  // backgroundColor: 'rgb(208, 2, 27)',
                  // borderColor: 'rgba(208, 2, 27, 0.2)',
                  pointStyle: 'circle',
                },
              ],
            }}
            width={6}
            height={5}
            options={{
              spanGaps: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                    // stacked: true,
                  },
                ],
                xAxes: [
                  {
                    offset: true, 
                    ticks: {
                      beginAtZero: true,
                    }
                  }
                ]
              },
            }}
          />
        </div>
        <div className="col-4 bg-white pt-4 pb-3 mr-0"> 
        <div className="revenue-box col-12">
          <div className="row table-title text-white">
            <div className="col-4">FREQUENCY</div>
            <div className="col-4">VOLUME</div>
            <div className="col-4">VALUE</div>
          </div>
          <div className="revenue-box-row row">
            <div className="col-4">DAILY</div>
            <div className="col-4">{ parseFloat(dailyVolume).toFixed(2)/* 500 */ }</div>
            <div className="col-4">{ formatAmount(dailyAmount)/* 500,000 */}</div>
          </div>
          <div className="revenue-box-row row">
            <div className="col-4">WEEKLY</div>
            <div className="col-4">{ parseFloat(weeklyVolume).toFixed(2)/* 1000 */}</div>
            <div className="col-4">{ formatAmount(weeklyAmount)/* 1,000,000 */}</div>
          </div>
          <div className="revenue-box-row row">
            <div className="col-4">MONTHLY</div>
            <div className="col-4">{ parseFloat(monthlyVolume).toFixed(2)/* 4000 */}</div>
            <div className="col-4">{ formatAmount(monthlyAmount)/* 2,500,000 */}</div>
          </div>
          <div className="revenue-box-footer row">
            This data is updated real time to reflect revenue and business performance.
          </div>
        </div>
        </div>
      </div>
    </div>
  )
};

export default Analytics;