import {
  ALL_ACTIVE_VENDOR_CUSTOMERS,
  ALL_ACTIVE_VENDOR_CUSTOMERS_LOADING_STATUS,
  SET_SELECTED_CUSTOMER,
  FETCH_VENDOR_LOCATIONS_FULLFILLED,
  FETCH_CUSTOMER_LOCATIONS_FULLFILLED
} from '../../modules/vendorModule/redux/actions/CustomerConstants';

const initialState = {
  getAllTransactionbyVendorId:[],
  getAllTransactionbyVendorIdBlob: null,
  activeCustomers: [],
  allCustomers: [],
  activeCustomerLoadingStatus: 0,
  selectedCustomer: {},
  contracts: [],
  vendorTransactions: [],
  vendorLocations: [],
  terminalTransactions: [],
  locationTransactions: [],
  productTransactions: [],
  customerAgreedVendors: [],
  customerContracts: [],
  customerTransactions: [],
  vendorSettlements: [],
  customerSettlements: [],
  oneTransaction: {},
  loadingVendorCustomerMessage: '',
  loadingVendorCustomerStatus: 0,
  contrProdChanged: '',
  addCustomerToVendorMessage: '',
  addCustomerToVendorStatus: 0,
  addCustomerToVendorMessageFailed:'',
  customerLocations: [],
  count: 0,
  editingStatus: 0,
  isUpdatingCustomer: false, 
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_ACTIVE_VENDOR_CUSTOMERS:
      return Object.assign({}, state, {
        activeCustomers: action.customers,
      });
    case "FETCH_ALL_CUSTOMERS":
      return Object.assign({}, state, {
        allCustomers: action.customers,
      });
    case "FETCH_ALL_VENDOR_CUSTOMER":
      return Object.assign({}, state, {
        activeCustomers: action.response.data,
        count: action.response.count,
        loadingVendorCustomerMessage: action.response.message,
        loadingVendorCustomerStatus: action.response.status,
        addCustomerToVendorStatus: 0,
      });
    case "START_ADD_CUSTOMER_TO_VENDOR":
      return {
        ...state,
        isUpdatingCustomerToVendor: true,
        addCustomerToVendorFailedStatus: 0,
        addCustomerToVendorStatus: 0,
      };
    case "ADD_CUSTOMER_TO_VENDOR":
      return Object.assign({}, state, {
        addCustomerToVendorMessage: action.response.message,
        isUpdatingCustomerToVendor: false,
        addCustomerToVendorStatus: action.response.status,
      });

    case "ADD_CUSTOMER_TO_VENDOR_FAILED":
      return {
        ...state,
        addCustomerToVendorFailedMessage: action.response.description,
        isUpdatingCustomerToVendor: false,
        addCustomerToVendorFailedStatus: action.response.status,
      };

    case "RESET_ADD_CUSTOMER_TO_VENDOR":
      return {
        ...state,
        addCustomerToVendorFailedMessage: "",
        isUpdatingCustomerToVendor: false,
        addCustomerToVendorFailedStatus: 0,
        addCustomerToVendorStatus: 0,
      };

    case ALL_ACTIVE_VENDOR_CUSTOMERS_LOADING_STATUS:
      return Object.assign({}, state, {
        activeCustomerLoadingStatus: action.status,
      });
    case SET_SELECTED_CUSTOMER:
      return Object.assign({}, state, {
        selectedCustomer: action.customer,
      });

    case "FETCH_All_TRANSACTIONSBY_VENDORS_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_TRANSACTIONSBY_VENDORS_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        vendorTransactions: action.payload,
      };
    }
    case "FETCH_All_TRANSACTIONSBY_VENDORS_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_All_TRANSACTIONSBY_TERMINAL_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_TRANSACTIONSBY_TERMINAL_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        terminalTransactions: action.payload,
      };
    }
    case "FETCH_All_TRANSACTIONSBY_TERMINAL_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_All_TRANSACTIONSBY_LOCATION_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_TRANSACTIONSBY_LOCATION_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        locationTransactions: action.payload,
      };
    }
    case "FETCH_All_TRANSACTIONSBY_LOCATION_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_All_TRANSACTIONSBY_PRODUCT_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_TRANSACTIONSBY_PRODUCT_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        productTransactions: action.payload,
      };
    }
    case "FETCH_All_TRANSACTIONSBY_PRODUCT_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_All_VENDORSSIGNING_WITH_CUSTOMERS_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_VENDORSSIGNING_WITH_CUSTOMERS_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        customerAgreedVendors: action.payload,
      };
    }
    case "FETCH_All_VENDORSSIGNING_WITH_CUSTOMERS_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_All_CUSTOMERS_CONTRACT_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_CUSTOMERS_CONTRACT_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        customerContracts: action.payload,
      };
    }
    case "FETCH_All_CUSTOMERS_CONTRACT_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_All_TRANSACTIONBY_CUSTOMERS_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_TRANSACTIONBY_CUSTOMERS_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        customerTransactions: action.payload,
      };
    }
    case "FETCH_All_TRANSACTIONBY_CUSTOMERS_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_All_TRANSACTIONBY_ID_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_TRANSACTIONBY_ID_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        oneTransaction: action.payload,
      };
    }
    case "FETCH_All_TRANSACTIONBY_ID_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_All_SETTLEMENTSBY_VENDOR_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_SETTLEMENTSBY_VENDOR_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        vendorSettlements: action.payload,
      };
    }
    case "FETCH_All_SETTLEMENTSBY_VENDOR_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_All_SETTLEMENTSBY_CUSTOMER_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_SETTLEMENTSBY_CUSTOMER_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        customerSettlements: action.payload,
      };
    }
    case "FETCH_All_SETTLEMENTSBY_CUSTOMER_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_SETTLEMENT_INVOICE_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_SETTLEMENT_INVOICE_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        settlementInvoice: action.payload,
      };
    }
    case "FETCH_SETTLEMENT_INVOICE_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_CUSTOMER_SETTLEMENT_STATUS_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_CUSTOMER_SETTLEMENT_STATUS_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        customerSettlementStatus: action.payload,
      };
    }
    case "FETCH_CUSTOMER_SETTLEMENT_STATUS_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_CUSTOMER_CONTRACTWITH_VENDOR_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_CUSTOMER_CONTRACTWITH_VENDOR_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        customerVendorContract: action.payload,
      };
    }
    case "FETCH_CUSTOMER_CONTRACTWITH_VENDOR_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "CHANGE_CONTRACT_PRODUCT_PRICE_START": {
      return {...state, fetching: true, fetched: false};
    }
    case "CHANGE_CONTRACT_PRODUCT_PRICE_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        contrProdChanged: action.payload,
      };
    }
    case "CHANGE_CONTRACT_PRODUCT_PRICE_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "GET_ALL_VENDORS_TRANSACTION": {
      return {
        ...state,
        getAllTransactionbyVendorId: action.payload,
      };
    }
    case "DOWNLOAD_ALL_VENDORS_TRANSACTION_START": {
      return {
        ...state, downloading: true, getAllTransactionbyVendorIdBlob: null,
      };
    }
    case "DOWNLOAD_ALL_VENDORS_TRANSACTION_FULFILLED": {
      return {
        ...state, downloading: false, getAllTransactionbyVendorIdBlob: action.payload,
      };
    }
    case "DOWNLOAD_ALL_VENDORS_TRANSACTION_REJECTED": {
      return {
        ...state, downloading: false, getAllTransactionbyVendorIdBlob: null,
      };
    }
    case 'DOWNLOAD_ALL_VENDORS_TRANSACTION_RESET': {
      return { ...state, getAllTransactionbyVendorIdBlob: null };
    }
    case "GET_ALL_VENDORS_TRANSACTION_FOR_VDASHBOARD": {
      return {
        ...state,
        getAllTransactionbyVendorId: action.payload,
      };
    }

    case FETCH_VENDOR_LOCATIONS_FULLFILLED: {
      return {
        ...state,
        vendorLocations: action.payload,
      };
    }

    case FETCH_CUSTOMER_LOCATIONS_FULLFILLED: {
      return {
        ...state,
        customerLocations: action.payload,
      };
    }

    case "TOG_CUSTOMER_STATUS_START": {
      return {...state, isUpdatingCustomer: true, editingStatus: 0};
    }
    case "TOG_CUSTOMER_STATUS_FULLFILLED": {
      const {response, id, mode} = action;
      const newCustomers = [...state.activeCustomers];
      const customerToUpdate = newCustomers.findIndex(
        (customer) => customer.id === id
      );
      newCustomers[customerToUpdate].status = mode;
      return {
        ...state,
        editingStatus: response.editingStatus,
        isUpdatingCustomer: false,
        activeCustomers: newCustomers,
      };
    }
    case "TOG_CUSTOMER_STATUS_REJECTED": {
      return {
        ...state,
        isUpdatingCustomer: false,
        editingStatus: action.response.editingStatus,
      };
    }
    case "RESET_USER_UPDATE": {
      return {
        ...state,
        editingStatus: 0,
        isUpdatingCustomer: false,
      };
    }

    default:
      return state;
  }
};
