import React from "react";
import "../../../../assets/Styles/Dashboard.css";
import { TransactionSVG } from "../../../../assets/Svgs/Svgs";
import { shortMonthNames } from "../../../../lib/Functions/DateNames";
import { NavLink } from "react-router-dom";

const StatusBar = (props) => {
  const { customerUser, oTrailReducer, oVendorReducer } = props;
  const d = new Date();
  const vendorCount = oVendorReducer.allVendors.data
    ? oVendorReducer.allVendors.count
    : "0";

  return (
    <div className="status-container row">
      <div className="compartment compartment-one col col-5">
        <span className="title d-inline mr-3">Transaction Tracking</span>
        <TransactionSVG color={"#06CC3E"} />
        <span className="sub-title">
          Summary of your transactions updated daily.
        </span>
        <span className="count">{0}</span>
        <span className="count-description">{`Transactions from ${vendorCount}  Vendor(s)`}</span>
        <span className="count-time">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
        <NavLink to="/owner-admin/reports">
          <div className="blue-bg-btn">View Report</div>
        </NavLink>
        <div className="dashboard-short-border-line"></div>
      </div>
      <div className="compartment compartment-two col col-4">
        <div className="row">
          <div className="text-center col-6">
            <p className="title">All Users</p>
            <span className="sub-title"></span>
            <span className="count">{customerUser.users.length}</span>
          </div>

          <div className="text-center col-6">
            <p className="title">User Actions</p>
            <span className="sub-title"></span>
            <span className="count">{oTrailReducer.oTrail.count || "0"}</span>
            <br />
            <NavLink to="/owner-admin/trail">
              <div className="white-bg-btn">View Trail</div>
            </NavLink>
          </div>
        </div>

        <div className="dashboard-short-border-line"></div>
      </div>

      <div className="compartment text-center col-3">
        <div className="row">
          <div className="text-center col-6">
            <p className="title">Cards</p>
            <span className="sub-title"></span>
            <span className="count">{0}</span>
          </div>

          <div className="text-center col-6">
            <p className="title">Vendors</p>
            <span className="sub-title"></span>
            <span className="count">{vendorCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusBar;
