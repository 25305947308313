import React, {useState/* , useEffect */} from "react";
import {useSelector} from "react-redux";
import StatusBar from "../StatusBar";
import CustomersList from "./CustomersList";

const Customers = () => {
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);
  const props = useSelector((state) => state);

  return (
    <div
      className={`${
        isStatusBarVisible ? "page-container" : "profile-page-container"
      }`}
    >
      <StatusBar isVisible={isStatusBarVisible} {...props} />
      <CustomersList
        {...props}
        onHideStatusBar={(status) => setIsStatusBarVisible(!status)}
      />
      {/* <RegisteredTags {...props} onHideStatusBar={(status) => setIsStatusBarVisible(!status)} /> */}
    </div>
  );
};

export default Customers;
