import React, { useState/* , useEffect */ } from "react";
import "../../../../assets/Styles/Dashboard.css";
import {
  FilterSVG,
  ExportSVG,
  // CompletedMarkSVG,
  // LoadingSVG,
  // DownloadSVG,
  // MarkSVG,
  // MissSVG,
  // CloseSVG
} from "../../../../assets/Svgs/Svgs";
import { CSVLink } from "react-csv";
// import Modal from "react-bootstrap/Modal";
import {
  // longMonthNames,
  shortMonthNames,
  shortDayNames
} from "../../../../lib/Functions/DateNames";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import { useRef } from "react";
// import ReactToPrint from "react-to-print";
import Pagination from "../../../../lib/Components/Pagination";
import formatAmount from "../../../../lib/Functions/formatAmount";

const TransactionReport = props => {
  const { onFetchTransactions } = props;
  const { content, count } = props.transactions;
  const [selectedTransaction, setSelectedTransaction] = useState({});// eslint-disable-line no-unused-vars
  const [showTransactionModal, setShowTransactionModal] = useState(false);// eslint-disable-line no-unused-vars
  // const componentRef = useRef();
  // const pageSize = 10;
  /* const {
    cardName,
    cardNumber,
    location,
    time,
    amount,
    vendor,
    reference,
    status
  } = selectedTransaction; */
  // const d = new Date(time);
  /* const transactionDate = `${shortDayNames[d.getDay()]}, ${
    longMonthNames[d.getMonth()]
  } ${d.getDate()}, ${d.getFullYear()}`; */
  // const [pageNo, setPageNo] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);

  const TransactionList =
    (content &&
    content.map(value => {
      const { id, location, cardNumber, vendor, amount, time } = value;
      const d = new Date(time);
      let classIdentifier = "",
        condition = "";
      if (value.status === "Successful") {
        classIdentifier = "success";
        condition = "Successful";
      } else {
        classIdentifier = "error";
        condition = "Failed";
      }
      return (
        <tr key={id}>
          <td className="table-description">
            Purchased of fuel from {location}
          </td>
          <td className="table-description font-weight-bold">{cardNumber}</td>
          <td className="table-pin">{vendor}</td>
          <td className="table-description">₦ {formatAmount(amount)}</td>
          <td className="table-description">
            {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
            {shortMonthNames[d.getMonth()]} {d.getFullYear()}
          </td>
          <td className="table-status">
            <div className={`status-btn ${classIdentifier}`}>{condition}</div>
          </td>
          <td
            className="table-action"
            onClick={() => {
              setSelectedTransaction(value);
              setShowTransactionModal(true);
            }}
          >
            View
          </td>
        </tr>
      );
    }));
  return (
    <>
        <div className="disbursed-cards-container row">
          <div
            className="menu-link"
            style={{ marginBottom: 0, paddingBottom: 4 }}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid"
              }}
            >
              Customer Transaction Report
            </span>
          </div>
          {!!content && (
            <CSVLink
              data={content}
              filename={"Transaction Report.csv"}
              className=""
              target="_blank"
            >
              <div
                className="disbursed-cards-export-btn"
                style={{ float: "right" }}
              >
                <span className="text-white">
                  Export CSV <ExportSVG />
                </span>
              </div>
            </CSVLink>
          )}
          <table className="table table-hover">
            <thead>
              <tr>
                <td>Description</td>
                <td>Card Pan</td>
                <td>
                  Vendor <FilterSVG />
                </td>
                <td>Amount</td>
                <td>
                  Date <FilterSVG />
                </td>
                <td>
                  Status <FilterSVG />
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {/* {!!TransactionList && TransactionList} */}
              {(TransactionList && TransactionList.length > 0) || (
                <tr>
                  <td colSpan={7} className="text-center">
                    No Transaction to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            count={count}
            pageSize={30}
            fetchMethod={(pageNo, pageSize) => {
              onFetchTransactions(pageNo, pageSize);
            }}
          />
    
        </div>

    </>
  );
};

export default TransactionReport;
