import React from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import { shortMonthNames } from "../../../lib/Functions/DateNames";
import formatAmount from "../../../lib/Functions/formatAmount";

const ContractsStatusBar = (props) => {
  
  const d = new Date();
  const {oContractReducer} = props;
  const {allContracts = { data: [], count: 0}} =  oContractReducer;
    let data = allContracts.data;
    let active =0, pending =0, inactive = 0, ended = 0, total = allContracts.count;

    const totalBound = data && data.reduce((total, amount) => total + amount.bound, 0);
    data && data.forEach(dataArr=>{
      switch(dataArr.contractStatus){
        case 'ACTIVE':
        active++
        break;
        case 'PENDING':
        pending++
        break;
        case 'INACTIVE':
        inactive++
        break;
        case 'ENDED':
        case 'TERMINATED':
        ended++
        break;
        
        default:
      }
    })


  return (
    <div className="status-container row">
      <div className="compartment compartment-one col col-2">
        <span className="title d-block mt-3 mb-3 mr-3">Contracts</span>
        <span className="sub-title">
          Keep track of vendors and customers contracts and also create new
          contracts.
        </span>
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
      </div>

      <div className="compartment report-compartment-two col-3">
        <div
          className="pl-3 pr-3 pt-1 pb-1"
          style={{display: "table", height: "100%", width: "100%"}}
        >
          <div
            style={{
              border: "none",
              boxShadow: "none",
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            <span className="sub-title mb-3 text-dark font-weight-bold">
              Total Value of Contracts
            </span>
            <span className="count mt-2 font-weight-bold">
              ₦ {formatAmount(totalBound * 100 || 0)}
            </span>
            {/* amount in naira already */}
          </div>
        </div>
      </div>

      <div className="compartment compartment-two col-7">
        <div className="row token-status-box">
          <div
            className="col col-12"
            style={{
              position: "relative",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 18,
            }}
          >
            <div className="row mb-3">
              <span className="title">Contracts</span>
            </div>
            <div className="row status-sub-title">
              <div className="col-3 pl-0">TOTAL</div>
              <div className="col-2 pl-0">ACTIVE</div>
              <div className="col-2 pl-0">INACTIVE </div>
              <div className="col-2 pl-0">PENDING</div>
              <div className="col-2 pl-0">ENDED</div>
            </div>
            <div className="row status-values">
              <div className="col-3 pl-0">{total || "0"}</div>
              <div className="col-2 pl-0">{active || "0"}</div>
              <div className="col-2 pl-0">{inactive || "0"}</div>
              <div className="col-2 pl-0">{pending || "0"}</div>
              <div className="col-2 pl-0">{ended || "0"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractsStatusBar;