import React from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import {shortMonthNames} from "../../../lib/Functions/DateNames";

const StatusBar = (props) => {
  const {customerCards, cardQuantity, myVouchersStats} = props;
  const {activeCards, inactiveCards} = cardQuantity;
  const {unUsedVouchers, usedVouchers} = myVouchersStats || {};
  const schemes = customerCards.allSchemesByCus || [];

  let activeSchemes = 0, inactiveSchemes = 0;
  schemes.forEach((val) => {
    if (val.status === 1) {
      return activeSchemes++;
    }
    inactiveSchemes++;
  });

  const d = new Date();

  return (
    <div
      className={`status-container row ${
        props.isVisible ? "d-block" : "d-none"
      }`}
    >
      <div className="compartment compartment-one col-3">
        <span className="title d-block mb-2">Tokens / Vouchers Insights</span>
        <span className="sub-title">
          Analytics of Cards / Vouchers and RFID serials generated and assigned and
          suspended.
        </span>
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
      </div>
      <div className="compartment compartment-two col-9 ">
        <div className="row token-status-box">
          <div
            className="col col-6"
            style={{
              position: "relative",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 16,
              width: "50%",
            }}
          >
            <div className="row">
              <span className="title">Cards</span>
            </div>
            <div className="row status-sub-title">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ASSIGNED</div>
              <div className="col-4 pl-0">SUSPENDED</div>
            </div>
            <div className="row status-values">
              <div className="col-4 pl-0">{activeCards + inactiveCards}</div>
              <div className="col-4 pl-0">{activeCards}</div>
              <div className="col-4 pl-0">{inactiveCards}</div>
            </div>
            <div className="status-bar-short-borders" style={{top: 23}}></div>
          </div>
          <div
            className="col col-6"
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 16,
              width: "50%",
            }}
          >
            {myVouchersStats ? (<>
              <div className="row">
                <span className="title">Vouchers</span>
              </div>
              <div className="row status-sub-title">
                <div className="col-4 pl-0">TOTAL</div>
                <div className="col-4 pl-0">UNUSED</div>
                <div className="col-4 pl-0">USED</div>
              </div>
              <div className="row status-values">
                <div className="col-4 pl-0">{(unUsedVouchers + usedVouchers)}</div>
                <div className="col-4 pl-0">{unUsedVouchers}</div>
                <div className="col-4 pl-0">{usedVouchers}</div>
              </div>
            </>) : (<>
              <div className="row">
                <span className="title">Schemes</span>
              </div>
              <div className="row status-sub-title">
                <div className="col-4 pl-0">TOTAL</div>
                <div className="col-4 pl-0">ACTIVE</div>
                <div className="col-4 pl-0">UNASSIGNED</div>
              </div>
              <div className="row status-values">
                <div className="col-4 pl-0">{schemes.length}</div>
                <div className="col-4 pl-0">{activeSchemes}</div>
                <div className="col-4 pl-0">{inactiveSchemes}</div>
              </div>
            </>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusBar;
