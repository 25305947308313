import React, { useState } from "react";
import "./toggleStyle.css"; // Import your CSS file for styling

const ToggleButton = ({ onToggle, isToggled, toggleLoadingStatus }) => {
  const toggleHandler = () => {
    onToggle && onToggle();
  };

  return (
    <div className="toggle-container">
      <span className="toggle-label">
        Toggle {isToggled ? "off" : "on"} SMS notification:{" "}
      </span>
      <label
        className={`toggle-switch ${toggleLoadingStatus ? "loading" : ""}`}
      >
        {toggleLoadingStatus ? (
          <div className="spinner"></div>
        ) : (
          <>
            <input
              type="checkbox"
              checked={isToggled}
              onChange={toggleHandler}
              disabled={toggleLoadingStatus}
            />
            <span className="toggle-slider round"></span>
          </>
        )}
      </label>
    </div>
  );
};

export default ToggleButton;
