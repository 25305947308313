import axios from 'axios';
import { 
  ALL_ACTIVE_VENDOR_CUSTOMERS, 
  ALL_ACTIVE_VENDOR_CUSTOMERS_LOADING_STATUS, 
  SET_SELECTED_CUSTOMER,
  FETCH_VENDOR_LOCATIONS_FULLFILLED,
  FETCH_VENDOR_LOCATIONS_REJECTED,
  FETCH_CUSTOMER_LOCATIONS_FULLFILLED,
  FETCH_CUSTOMER_LOCATIONS_REJECTED } from './CustomerConstants';
import { customConfig } from '../../../../actions/action_constants';
import { vendorService, customerService, settlementService } from '../../../../services/authService';
import { authHeader } from '../../../../helpers/authHeader';

const API_URL = customConfig.API_URL;

export const getAllVendorsUpdate = customers => dispatch => {
  dispatch({
    type: ALL_ACTIVE_VENDOR_CUSTOMERS,
    customers,
  });
};
export const getAllCustomer = customers => dispatch => {
  dispatch({
    type: 'FETCH_ALL_CUSTOMERS',
    customers,
  });
};

export const getAllVendorsLoadingStatus = status => dispatch => {
  dispatch({
    type: ALL_ACTIVE_VENDOR_CUSTOMERS_LOADING_STATUS,
    status,
  });
};

export const updateSelectedCustomer = customer => dispatch => {
  dispatch({
    type: SET_SELECTED_CUSTOMER,
    customer,
  });
};

export function loadAllVendorCustomer(vid, page, size, q = '') {
  return function(dispatch) {
    axios
      .get(`${API_URL}api/vendors/customers?vid=${vid}&page=${page}&size=${size}&q=${q}`, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'FETCH_ALL_VENDOR_CUSTOMER', response: { data: res.data.data, count: res.data.count, status: 1, message: res.data.status.description } });
        } else {
          dispatch({ type: 'FETCH_ALL_VENDOR_CUSTOMER', response: { data: [], count: 0, status: 2, message: res.data.status.description } });
        }
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_ALL_VENDOR_CUSTOMER',
          response: {
            data: [],
            count: 0,
            message: error.response.data ? error.response.data.status?.description || error.response.data.status?.message : 'Network Error',
            status: 2,
          },
        });
      });
  };
}

export function changeContractLimitRequest(contractId, limit) {
  return function(dispatch) {
    dispatch({ type: 'CHANGE_CONTRACT_LIMIT_REQUEST_START' });
    axios
      .put(`${API_URL}api/contracts/update/limit?contractId=${contractId}&limit=${limit}`, {}, { headers: authHeader() })
      .then(res => {
        const isError = (res.status !== 200);
        const message = isError ? 'Contract-limit change-request failed' : 'Contract-limit change-request was successful';
        dispatch({
          type: `CHANGE_CONTRACT_LIMIT_REQUEST_${isError ? 'REJECTED' : 'FULLFILLED'}`, 
          payload: { data: (isError ? null : res.data.data), message }
        });
      })
      .catch(err => {
        dispatch({
          type: 'CHANGE_CONTRACT_LIMIT_REQUEST_REJECTED', 
          payload: { message: (err.response && err.response.data?.status?.description) || 'Contract-limit change-request failed' }
        });
      });
  };
}

export function changeProductPriceRequest(cid, pid, rate) {
  return function(dispatch) {
    dispatch({ type: 'CHANGE_CONTRACT_PRODUCT_PRICE_REQUEST_START' });
    axios
      .put(API_URL + 'api/contracts/updateproduct?pid=' + pid + '&cid=' + cid + '&discount=' + rate, {}, { headers: authHeader() })
      .then(res => {
        const isError = (res.status !== 200);
        const message = isError ? 'Product-rate change-request failed' : 'Product-rate change-request was successful';
        dispatch({
          type: `CHANGE_CONTRACT_PRODUCT_PRICE_REQUEST_${isError ? 'REJECTED' : 'FULLFILLED'}`, 
          payload: { data: (isError ? null : res.data.data), message }
        });
      })
      .catch(err => {
        dispatch({
          type: 'CHANGE_CONTRACT_PRODUCT_PRICE_REQUEST_REJECTED', 
          payload: { message: (err.response && err.response.data?.status?.description) || 'Product-rate change-request failed' }
        });
      });
  };
}

export function toggleContract(contractId, isEnable) {
  return function (dispatch) {
    dispatch({ type: 'TOGGLE_CONTRACT_START' });
    axios
      .put(`${API_URL}api/contract/${(isEnable ? 'enable' : 'terminate')}?contractId=${contractId}`, {}, { headers: authHeader() })
      .then(res => dispatch({ type: 'TOGGLE_CONTRACT_FULFILLED', payload: res.data }))
      .catch(err => dispatch({ type: 'TOGGLE_CONTRACT_REJECTED', payload: err }));
  };
}

export function loadAllCustomer() {
  return function(dispatch) {
    axios
      .get(`${API_URL}api/customers/all?page=1&size=10000000`, { headers: authHeader() })
      .then(response => {
        if (response.status === 200) {
          const { data } = response.data;
          dispatch(getAllCustomer(data));
          dispatch(getAllVendorsLoadingStatus(1));
        }
      })
      .catch(error => {
        dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function updateCustomerToVendorList(vendId, cusId) {
  return function(dispatch) {
    dispatch({type:'START_ADD_CUSTOMER_TO_VENDOR'})
    axios
      .put(`${API_URL}api/vendors/serve?vid=${vendId}&cid=${cusId}`, {}, { headers: authHeader() })
      .then(res => {
        
        if (res.status === 200) {
          if (res.data.status.code === 0) {
            dispatch({ type: 'ADD_CUSTOMER_TO_VENDOR', response: { status: 1, message: res.data.status.description } });
          } 
          else{
            dispatch({ type: 'ADD_CUSTOMER_TO_VENDOR_FAILED', response: { data: [], status: 2, message: res.data.status.description } });
          }
        }
      })
      .catch(err => {    
            dispatch({type:'ADD_CUSTOMER_TO_VENDOR_FAILED',response:{description:err.response.data.status.description, code:err.response.data.status.code, status: 2}});   
      });
  };
}

export function resetUpdateCustomerToVendorList() {
  return dispatch => dispatch({type: "RESET_ADD_CUSTOMER_TO_VENDOR"})
}

export function getTransactionsByVendor(vid, filterParams) {
  return dispatch => {
    dispatch(request());

    vendorService.getTransactionsByVendor(vid, filterParams).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_TRANSACTIONSBY_VENDORS_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_TRANSACTIONSBY_VENDORS_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_TRANSACTIONSBY_VENDORS_REJECTED', error }; 
  }
}

export function getTransactionsByTerminal(tid) {
  return dispatch => {
    dispatch(request());

    vendorService.getTransactionsByTerminal(tid).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_TRANSACTIONSBY_TERMINAL_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_TRANSACTIONSBY_TERMINAL_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_TRANSACTIONSBY_TERMINAL_REJECTED', error };
  }
}

export function getTransactionsByLocation(lid) {
  return dispatch => {
    dispatch(request());

    vendorService.getTransactionsByLocation(lid).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_TRANSACTIONSBY_LOCATION_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_TRANSACTIONSBY_LOCATION_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_TRANSACTIONSBY_LOCATION_REJECTED', error };
  }
}

export function getTransactionsByProduct(pid) {
  return dispatch => {
    dispatch(request());

    vendorService.getTransactionsByProduct(pid).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_TRANSACTIONSBY_PRODUCT_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_TRANSACTIONSBY_PRODUCT_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_TRANSACTIONSBY_PRODUCT_REJECTED', error };
  }
}

export function getVendorsThatAgreeToSignWithCustomer(cid) {
  return dispatch => {
    dispatch(request());

    customerService.getVendorsThatAgreeToSignWithCustomer(cid).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_VENDORSSIGNING_WITH_CUSTOMERS_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_VENDORSSIGNING_WITH_CUSTOMERS_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_VENDORSSIGNING_WITH_CUSTOMERS_REJECTED', error };
  }
}

export function getAllCustomerContracts(cid) {
  return dispatch => {
    dispatch(request());

    customerService.getAllCustomerContracts(cid).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_CUSTOMERS_CONTRACT_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_CUSTOMERS_CONTRACT_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_CUSTOMERS_CONTRACT_REJECTED', error };
  }
}

export function getTransactionsByCustomer(cid,startDate,endDate,page,pageSize,status, filterParams) {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const location = options.location || "";

  if (vendorNum.length) params+=`&vendorNum=${vendorNum}`;
  if (location.length) params+=`&location=${location}`;

  return function(dispatch) {
    axios
      .get(
        `${
          customConfig.API_URL_CARD_AND_TRANS
        }api/v1/transactions?customerNum=${cid}&pageNum=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`,
        { headers: authHeader() },
      )
      .then(res => {
        if (res.status === 200) {
          if (res.status === 200) {
            dispatch({ type: 'GET_ALL_CUSTOMERS_TRANSACTION', response: { data: res.data } });
          } else {
            dispatch({ type: 'GET_ALL_CUSTOMERS_TRANSACTION', response: { data: {} } });
          }
        }
      })
      .catch(err => {
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function getTransactionsByCustomerForCDasboard(cid,page,pageSize,startDate,endDate,status,filterParams) {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const location = options.location || "";
  // setLoader(true);
  if (vendorNum.length) params+=`&vendorNum=${vendorNum}`;
  if (location.length) params+=`&location=${location}`;

  return function(dispatch) {
    axios
      .get(
        `${
          customConfig.API_URL_CARD_AND_TRANS
        }api/v1/transactions?customerNum=${cid}&pageNum=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`,
        { headers: authHeader() },
      )
      .then(res => {
        if (res.status === 200) {
          dispatch({ type: 'GET_ALL_CUSTOMERS_TRANSACTION_CDASHBOARD', response: { data: res.data } });
        } else {
          dispatch({ type: 'GET_ALL_CUSTOMERS_TRANSACTION_CDASHBOARD', response: { data: {} } });
        }
      })
      .catch(err => {
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function getTransactionsById(id) {
  return dispatch => {
    dispatch(request());

    customerService.getTransactionsById(id).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_TRANSACTIONBY_ID_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_TRANSACTIONBY_ID_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_TRANSACTIONBY_ID_REJECTED', error };
  }
}

export function getSettlementsByVendor(vid) {
  return dispatch => {
    dispatch(request());

    settlementService.getSettlementsByVendor(vid).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_SETTLEMENTSBY_VENDOR_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_SETTLEMENTSBY_VENDOR_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_SETTLEMENTSBY_VENDOR_REJECTED', error };
  }
}

export function getSettlementsByCustomer(cid) {
  return dispatch => {
    dispatch(request());

    settlementService.getSettlementsByCustomer(cid).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_SETTLEMENTSBY_CUSTOMER_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_SETTLEMENTSBY_CUSTOMER_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_SETTLEMENTSBY_CUSTOMER_REJECTED', error };
  }
}

export function getSettlementInvoice(id) {
  return dispatch => {
    dispatch(request());

    settlementService.getSettlementInvoice(id).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_SETTLEMENT_INVOICE_START' };
  }
  function success(payload) {
    return { type: 'FETCH_SETTLEMENT_INVOICE_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_SETTLEMENT_INVOICE_REJECTED', error };
  }
}

export function getCustomerSettlementStatus(id) {
  return dispatch => {
    dispatch(request());

    settlementService.getCustomerSettlementStatus(id).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_CUSTOMER_SETTLEMENT_STATUS_START' };
  }
  function success(payload) {
    return { type: 'FETCH_CUSTOMER_SETTLEMENT_STATUS_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_CUSTOMER_SETTLEMENT_STATUS_REJECTED', error };
  }
}

export function getCustomersContractWithAVendor(cid, vid) {
  return dispatch => {
    dispatch(request());

    customerService.getCustomersContractWithAVendor(cid, vid).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_CUSTOMER_CONTRACTWITH_VENDOR_START' };
  }
  function success(payload) {
    return { type: 'FETCH_CUSTOMER_CONTRACTWITH_VENDOR_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_CUSTOMER_CONTRACTWITH_VENDOR_REJECTED', error };
  }
}

export function fetchVendorLocations(vendorNum) {

  return function(dispatch) {
    axios
      .get(customConfig.API_URL_CARD_AND_TRANS + `api/v1/transactions/locations?vendorNum=${vendorNum}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: FETCH_VENDOR_LOCATIONS_FULLFILLED, payload: res.data });
      })
      .catch(err => {
        dispatch({ type: FETCH_VENDOR_LOCATIONS_REJECTED, payload: err });
      });
  };
}

export function fetchCustomerLocations(customerNum) {

  return function(dispatch) {
    axios
      .get(customConfig.API_URL_CARD_AND_TRANS + `api/v1/transactions/locations?customerNum=${customerNum}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: FETCH_CUSTOMER_LOCATIONS_FULLFILLED, payload: res.data });
      })
      .catch(err => {
        dispatch({ type: FETCH_CUSTOMER_LOCATIONS_REJECTED, payload: err });
      });
  };
}

// export const customerService = {
// ,
// getSchemeById,
// getSchemeBySchemeMgrId,

// };

