import axios from "axios";
import { customConfig } from "../../../../actions/action_constants";
import { authHeader } from "../../../../helpers/authHeader";
const API_URL = customConfig.API_URL;
const API_URL_CARD_AND_TRANS = customConfig.API_URL_CARD_AND_TRANS;
const API_URL_REPORT = customConfig.API_URL_REPORT;


export function fetchAllTrailByCustomer(cid, page, pageSize, startDate = null, endDate = null, action = '') {
  const dateFilter = (startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}%2023:59:59` : '';

  return function(dispatch) {
    dispatch({ type: "FETCH_AllCUSTOMER_TRAIL_START" });
    axios
      .get(
        API_URL + `api/trails/customer?cid=${cid}&page=${page}&size=${pageSize}&action=${action}${dateFilter}`,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: "FETCH_AllCUSTOMER_TRAIL_FULLFILLED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({ type: "FETCH_AllCUSTOMER_TRAIL_REJECTED", payload: err });
      });
  };
}

export function fetchAllTransactions(startDate, endDate, page, pageSize,status, filterParams) {
  /* let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const customerNum = options.customerNum || "";
  const location = options.location || "";
  if (vendorNum.length) params+=`&vendorNum=${vendorNum}`;
  if (customerNum.length) params+=`&customerNum=${customerNum}`;
  if (location.length) params+=`&location=${location}`; */

  return function(dispatch) {
    dispatch({ type: "FETCH_All_TRANSACTION_START" });
    axios
      .get(
        API_URL_CARD_AND_TRANS +
          `api/v1/transactions?pageNum=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}%2023:59:59`,
        {
          headers: authHeader()
        }
      )
      .then(res => {
        dispatch({ type: "FETCH_All_TRANSACTION_FULLFILLED", payload: res });
      })
      .catch(err => {
        dispatch({ type: "FETCH_All_TRANSACTION_REJECTED", payload: err });
      });
  };
}

export function getAllSettlements() {
  return function(dispatch) {
    dispatch({ type: "FETCH_SETTLEMENTS_START" });
    axios
      .get(API_URL_REPORT + "api/settlement/find/all", {
        headers: authHeader()
      })
      // api/settlement/customer?page=0&size=50&customer=CUS000002
      .then(res => {
        dispatch({ type: "FETCH_SETTLEMENTS_FULFILLED", payload: res.data });
      })
      .catch(err => {
        dispatch({ type: "FETCH_SETTLEMENTS_REJECTED", payload: err });
      });
  };
}