import axios from 'axios';
// import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import { customConfig } from '../../action_constants';
import { authHeader } from '../../../helpers/authHeader';
const {
  API_URL, API_URL_CARD, API_URL_CARD_ACC, 
  API_URL_CORPORATE, API_URL_SF_VOUCHER, API_URL_CARD_AND_TRANS
} = customConfig;
const version = 'v1';

export function fetchAllCustomerSchemes(cus_id) {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/customers/all?page=1&size=50', { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_All_CUSTOMER_SCHEMES_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_All_CUSTOMER_SCHEME_REJECTED', payload: err });
      });
  };
}

export function fetchAllCustomerContracts(cus_id, page, size) {
  return function(dispatch) {
    axios
      .get(API_URL + `api/contracts/customers?cid=${cus_id}&page=${page}&size=${size}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_ALL_CUSTOMER_CONTRACTS_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_ALL_CUSTOMER_CONTRACTS_REJECTED', payload: err });
      });
  };
}

export function fetchAllCustomerContractsWithProducts(cus_id) {
  return function(dispatch) {
    axios
      .get(API_URL + `api/contracts/customerswithproducts?cid=${cus_id}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_ALL_CUSTOMER_CONTRACTS_WITH_PRODUCTS_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_ALL_CUSTOMER_CONTRACTS_WITH_PRODUCTS_FULLFILLED', payload: err });
      });
  };
}

export function fetchAllCustomerVendorContracts(customerId, vendorId) {
  return function(dispatch) {
    axios
      .get(API_URL + `api/contracts/ccv?cid=${customerId}&vid=${vendorId}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_ALL_CUSTOMER_VENDOR_CONTRACTS_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_ALL_CUSTOMER_VENDOR_CONTRACTS_REJECTED', payload: err });
      });
  };
}

export function fetchAllCardsByScheme(customerId, accountNum) {
  return function(dispatch) {
    axios
      .get(
        API_URL_CARD_ACC +
          `api/v1/cards/assigned?customerId=${customerId}&accountNum=${accountNum}`,
        {headers: authHeader()}
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "FETCH_CARDS_BY_SCHEME_FULLFILLED",
            response: {
              data: res.data.content,
              message: "Cards loaded successfully",
              status: 1,
            },
          });
        }
        if (res.status !== 200) {
          dispatch({
            type: "FETCH_CARDS_BY_SCHEME_FULLFILLED",
            response: {data: [], message: "Loading cards fail", status: 2},
          });
        }
      })
      .catch((err) => {
        dispatch({type: "FETCH_CARDS_BY_SCHEME_REJECTED", payload: err});
      });
  };
}

export function createNewScheme(body, onCreateScheme, onCreateHandler) {
  return function(dispatch) {
    axios
      .post(API_URL_CARD_ACC + `api/${version}/schemes/create`, body, { headers: authHeader() })
      .then(res => {
        onCreateScheme(false);
        onCreateHandler(true);
        if (res.status === 200) {
          dispatch({ type: 'ADD_SCHEME_SUCCESSFUL', payload: { message: 'Scheme created successfully.', status: 1 } });
        }
        if (res.status !== 200) {
          dispatch({ type: 'ADD_SCHEME_REJECTED', payload: { message: 'Scheme creation failed.', status: 2 } });
        }
      })
      .catch(err => {
        onCreateScheme(false);
        onCreateHandler(false, err.response.data);
        if (err.response.data) {
          dispatch({ type: 'ADD_SCHEME_REJECTED', payload: { message: err.response.data.description, status: 2 } });
        }
        dispatch({ type: 'ADD_SCHEME_REJECTED', payload: { message: err.response.data.description, status: 2 } });
      });
  };
}

export function updateSchemeLimit(accountNumber, contractId, cycle, limit, onUpdateSchemeLimit) {
  return function(dispatch) {
    axios
      .post(API_URL_CARD_ACC + `api/${version}/schemes/setLimit`, {accountNumber, contractId, cycle, limit}, { headers: authHeader() })
      .then(res => {
        // console.log(`response-got: ${JSON.stringify(res, null, 2)}`);
        const isError = (res.status !== 200 || parseInt(res.data.code) >= 300);// actual-code NOT-CERTAIN as at implementation
        const message = isError ? 'Scheme-limit update failed.' : 'Scheme-limit updated successfully.';

        onUpdateSchemeLimit({ status: false, isError, message });
        dispatch({ type: `UPDATE_SCHEME_LIMIT_${(isError ? 'REJECTED' : 'SUCCESSFUL')}`, payload: { message, status: (isError ? 2 : 1) } });
      })
      .catch(err => {
        // console.log(`error-got: ${JSON.stringify(err, null, 2)}`);
        const message = (err.response && err.response.data?.description) || 'Scheme-limit update failed...';

        onUpdateSchemeLimit({ status: false, isError: true, message });
        dispatch({ type: 'UPDATE_SCHEME_LIMIT_REJECTED', payload: { message, status: 2 } });
      });
  };
}

export function updateCreateSchemeModal(status) {
  return {
    type: 'ADD_SCHEME_SUCCESSFUL',
    payload: { message: '', status },
  };
}

export function updateScheme(id, body) {
  return function(dispatch) {
    axios
      .put(API_URL + 'api/schemes?sid=' + id, body, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'ADD_SCHEME_FULLFILLED', payload: res });
        dispatch({ type: 'ADD_SCHEME_SUCCESSFUL', payload: res.data.status });
      })
      .catch(err => {
        dispatch({ type: 'ADD_SCHEME_REJECTED', payload: err });
      });
  };
}

export function addSchemeSuccessful(payload) {
  return {
    type: 'ADD_SCHEME_SUCCESSFUL',
    payload,
  };
}

export function editScheme(id) {
  return {
    type: 'TOGGLE_MODAL',
    payload: id,
  };
}

export function assignNewCardToUser(body) {
  return {
    type: 'ADD_CARD_FULLFILLED',
    payload: body,
  };
}

export function fetchCardsByCustomer(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CARDS_BYCUSTOMER_START' });
    axios
      .get(API_URL_CARD + 'api/card/fetch/customer?customer=' + id + '&page=1&size=50', { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_CARDS_BYCUSTOMER_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_CARDS_BYCUSTOMER_REJECTED', payload: err });
      });
  };

}

export function fetchAllCards() {
  return function(dispatch) {
    dispatch({ type: 'FETCH_ALL_CARDS_START' });
    axios
      .get(API_URL_CARD + 'api/card/find?page=0&size=50', { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_ALL_CARDS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_ALL_CARDS__REJECTED', payload: err });
      });
  };
}

export function fetchOneCard(pan) {
  return function(dispatch) {
    axios
      .post(API_URL_CARD_ACC + 'api/v1/cards/single/detail', { pan }, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_ONE_CARD_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_ONE_CARD_REJECTED', payload: err });
      });
  };
}

export function fetchOneCardBalance(pan) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CARD_BALANCE_START' });
    axios
      .post(`${API_URL_CARD_ACC}api/v1/cards/balance`, { pan }, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_CARD_BALANCE_FULFILLED', payload: res.data });
      })
      .catch(err => {
        const errorObj = err.response ? err.response.data : {};
        dispatch({ type: 'FETCH_CARD_BALANCE_REJECTED', payload: errorObj.description || errorObj.errors });
      });
  };
}

export function fetchOneCardStatistics(pan) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CARD_STATISTICS_START' });
    axios
      .get(`${API_URL_CARD_AND_TRANS}api/v1/cards/stats?pan=${pan}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_CARD_STATISTICS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_CARD_STATISTICS_REJECTED', payload: err });
      });
  };
}

export function fetchOneCardTransactions(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CARD_TRANSACTION_START' });
    axios
      .get(API_URL_CARD + 'api/transaction/card?card=' + id + '&page=0&size=50', { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_CARD_TRANSACTION_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_CARD_TRANSACTION_REJECTED', payload: err });
      });
  };
}

export function toggleCardStatus(id, action) {
  return function(dispatch) {
    dispatch({ type: 'TOGGLE_CARD_STATUS_START' });
    axios
      .put(API_URL_CARD + 'api/card/' + action + '?id=' + id, {}, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'TOGGLE_CARD_STATUS_FULFILLED', payload: { data: res, message: res.data.status.description, status: 1 } });
        } else {
          dispatch({ type: 'TOGGLE_CARD_STATUS_REJECTED', payload: { data: res, message: res.data.status.description, status: 2 } });
        }
      })
      .catch(err => {
        dispatch({ type: 'TOGGLE_CARD_STATUS_REJECTED', payload: { message: err.response.data.status.error, status: 2 } });
      });
  };
}

export function toggleActivateAccountStatus(accountNumber) {
  return function(dispatch) {
    dispatch({ type: 'TOGGLE_ACTIVATE_ACC_STATUS_START' });
    axios
      .put(API_URL_CARD_ACC+ 'api/v1/schemes/activate',{accountNumber}, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'TOGGLE_ACTIVATE_ACC_STATUS_FULFILLED', payload: { data: res, message: res.data.description, status: 1 } });
      })
      .catch(err => {
        const message = err
          && err.response
          && err.response.data
          && err.response.data.description;
        dispatch({ type: 'TOGGLE_ACTIVATE_ACC_STATUS_REJECTED', payload: { message, status: 2 } });
      });
  };
}

export function toggleDeactivateAccountStatus(accountNumber) { 
  return function(dispatch) {
    dispatch({ type: 'TOGGLE_DISABLE_ACC_STATUS_START' });
    axios
      .put(API_URL_CARD_ACC + 'api/v1/schemes/disable',{accountNumber}, { headers: authHeader() })
      .then(res => {
          dispatch({ type: 'TOGGLE_DISABLE_ACC_STATUS_FULFILLED', payload: { data: res, message: res && res.data.description, status: 1 } });
       })
      .catch(err => {
        const message = err
          && err.response
          && err.response.data
          && err.response.data.description;
        dispatch({ type: 'TOGGLE_DISABLE_ACC_STATUS_REJECTED', payload: { message, status: 2 } });
      });
  };
}

export function toggleSchemeCardStatus(pan, expiryDate, accountNumber, isToBlock) {
  return function(dispatch) {
    dispatch({ type: 'TOGGLE_SCHEME_CARD_STATUS_START' });

    axios
      .post(
        `${API_URL_CARD_ACC}api/${version}/cards/${isToBlock ? 'block' : 'unblock'}`, 
        { pan, expiryDate, accountNumber }, 
        { headers: authHeader() }
      )
      .then(res => {
        const isError = (res.status !== 200 || (res.data.code !== '00'));
        // const message = isError 
        //   ? `Card ${isToBlock ? '' : 'un'}blocking failed.` 
        //   : `Card ${isToBlock ? '' : 'un'}blocked successfully.`;

        dispatch({
          type: `TOGGLE_SCHEME_CARD_STATUS_${(isError ? 'REJECTED' : 'FULFILLED')}`, 
          payload: { message: res.data.description, },
        });
      })
      .catch(err => {
        let message = (err.response 
          && err.response.data
          && (err.response.data.description || err.response.data.message))
          || `Card ${isToBlock ? '' : 'un'}blocking failed.`;

        dispatch({
          type: 'TOGGLE_SCHEME_CARD_STATUS_REJECTED',
          payload: { message }
        });
      });
  }
}

export function resetAccountStatus() { 
  return function(dispatch) {
    dispatch({ type: 'RESET_ACC_STATUS_ACTION' });
  };
}

export function updateCardCreateRequestParams(data) {
  return {
    type: 'UPDATE_CARD_CREATE_REQUEST_PARAMS',
    response: { status: data, message: '' },
  };
}

export function updateRequestParams(data) {
  return {
    type: 'UPDATE_CARD_REQUEST_PARAMS',
    response: { status: data, message: '' },
  };
}

export function uploadSingleCard(body) {
  return function(dispatch) {
    axios
      .post(API_URL_CARD + 'api/blankcard/create', body, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({
            type: 'UPLOAD_BLANK_CARDS_FULLFILLED',
            response: { payload: res.data, data: res.data.data, message: res.data.status.description, status: 1 },
          });
        }
        if (res.data.status.code !== 0) {
          dispatch({ type: 'UPLOAD_BLANK_CARDS_REJECTED', response: { message: res.data.status.description, status: 2 } });
        }
      })
      .catch(err => {
        dispatch({ type: 'UPLOAD_BLANK_CARDS_REJECTED', response: { message: err.response.data.status.description, status: 2 } });
      });
  };
}

export function assignCard(body, setAssigning, setIsSuccessful) {
  return function(dispatch) {
    axios
      .post(API_URL_CARD_ACC + 'api/v1/cards/assign', body, { headers: authHeader() })
      .then(res => {
        setAssigning(false);
        if (res.status === 200) {
          setIsSuccessful(true);
          dispatch({ type: 'ASSIGN_CARDS_FULFILLED', response: { status: 1, message: res.data.description } });
        } else {
          setIsSuccessful(false, "An error occurred while assigning card");
          dispatch({ type: 'ASSIGN_CARDS_REJECTED', response: { status: 2, message: res.data.description } });
        }
      })
      .catch(err => {
        setAssigning(false);
        setIsSuccessful(false, 'An error occurred while assigning card');
        dispatch({
          type: 'ASSIGN_CARDS_REJECTED',
          response: {
            status: 2,
            message: err.response && err.response.data ? err.response.data.description : 'An error occured',
          },
        });
      });
  };
}

export function reAssignCard(body,  setAssigning, setIsSuccessful) {
  return function(dispatch) {
    axios
      .put(API_URL_CARD_ACC + 'api/v1/cards/reassign', body, { headers: authHeader() })
      .then(res => {
        setAssigning(false);
        if (res.status === 200) {
          setIsSuccessful(true);
          dispatch({ type: 'ASSIGN_CARDS_FULFILLED', response: { status: 1, message: res.data.description } });
        } else {
          setIsSuccessful(false, "An error occurred while assigning card");
          dispatch({ type: 'ASSIGN_CARDS_REJECTED', response: { status: 2, message: res.data.description } });
        }
      })
      .catch(err => {
        setAssigning(false);
        setIsSuccessful(false, 'An error occurred while assigning card');
        dispatch({
          type: 'ASSIGN_CARDS_REJECTED',
          response: {
            status: 2,
            message: err.response && err.response.data ? err.response.data.description : 'An error occured',
          },
        });
      });
}
}

export function topupCard(body, setTopingUp, setIsSuccessful) {
  return function(dispatch) {
    axios
      .post(API_URL_CARD_ACC + 'api/v1/cards/setLimit', body, { headers: authHeader() })
      .then(res => {
        setTopingUp(false);
        if (res.status === 200) {
          setIsSuccessful(true);
          dispatch({ type: 'ASSIGN_CARDS_FULFILLED', response: { status: 1, message: res.data.description } });
        } else {
          setIsSuccessful(false, "An error occurred while topi upng card");
          dispatch({ type: 'ASSIGN_CARDS_REJECTED', response: { status: 2, message: res.data.description } });
        }
      })
      .catch(err => {
        setTopingUp(false);
        setIsSuccessful(false, 'An error occurred while toping up card');
        dispatch({
          type: 'ASSIGN_CARDS_REJECTED',
          response: {
            status: 2,
            message: err.response && err.response.data ? err.response.data.description : 'An error occured',
          },
        });
      });
}
}

export function assignVoucher(userNumber, body, setAssigning, setIsSuccessful) {
  return function(dispatch) {
    axios
      .post(`${API_URL_SF_VOUCHER}api/v1/vouchers/${userNumber}`, body, { headers: authHeader() })
      .then(res => {
        setAssigning(false);
        if (res.status === 201) {
          setIsSuccessful(true);
          dispatch({ type: 'ASSIGN_VOUCHERS_FULFILLED', response: { status: 1, message: res.data.description } });
        } else {
          setIsSuccessful(false, res.data.description || "An error occurred while generating/assigning voucher");
          dispatch({ type: 'ASSIGN_VOUCHERS_REJECTED', response: { status: 2, message: res.data.description } });
        }
      })
      .catch(err => {
        const errorMessage = err.response && err.response.data ? (
          err.response.data.description || 'An error occurred while generating/assigning voucher') : 'An error occurred';

        setAssigning(false);
        setIsSuccessful(false, errorMessage);
        dispatch({ type: 'ASSIGN_VOUCHERS_REJECTED', response: { status: 2, message: errorMessage }});
      });
  };
}

export function fundCustomerWallet(vendorId, customerId, amount, setFunding, setIsSuccessful) {
  return function(dispatch) {
    axios
      .post(`${API_URL}api/customer/fund?vid=${vendorId}&cid=${customerId}&amount=${amount}`, {}, { headers: authHeader() })
      .then(res => {
        setFunding(false);
        if (res.status === 200) {
          setIsSuccessful(true);
          dispatch({ type: 'FUND_WALLET_FULFILLED', response: { status: 1, message: res.data.description } });
        } else {
          setIsSuccessful(false, res.data.description || "An error occurred while funding the wallet");
          dispatch({ type: 'FUND_WALLET_REJECTED', response: { status: 2, message: res.data.description } });
        }
      })
      .catch(err => {
        const errorMessage = err.response && err.response.data ? (
          err.response.data.description || 'An error occurred while funding the wallet') : 'An error occurred';

        setFunding(false);
        setIsSuccessful(false, errorMessage);
        dispatch({ type: 'FUND_WALLET_REJECTED', response: { status: 2, message: errorMessage }});
      });
  };
}

export function assignTag(body, onFetched) {
  return function (dispatch) {
    axios
      .post(API_URL_CORPORATE + "api/v1/assign/card", body, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status === 200) {
          onFetched(true);
          dispatch({
            type: "ASSIGN_TAG_FULFILLED",
            response: {status: 1, message: res.data.description},
          });
        } else {
          onFetched(false, 'Tag could not be assigned to card');
          dispatch({
            type: "ASSIGN_TAG_REJECTED",
            response: {status: 2, message: res.data.description},
          });
        }
      })
      .catch((err) => {
        onFetched(false, err.response?.data?.description);
        dispatch({
          type: "ASSIGN_TAG_REJECTED",
          response: {
            status: 2,
            message:
              err.response && err.response.data
                ? err.response.data.description
                : "An error occured",
          },
        });
      });
  };
}

export function getCardRfid(pan, onFetched) {
  return function () {
    axios
      .post(API_URL_CORPORATE + "api/v1/assign/list", pan, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status === 200) {
          onFetched(res.data.tags);
        } else {
          onFetched("Error!");
        }
      })
      .catch((err) => {
        onFetched("Error!");
      });
  };
}

export function getBlankCardsByCus(cid, pageNumber, pageSize) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_BLANKCARDS_BYCUSTOMER_START' });
    axios
      .get(API_URL_CARD + `api/blankcard/customer?customer=${cid}&status=NOT_ASSIGNED&page=${pageNumber}&size=${pageSize}`, { headers: authHeader() })
      .then(res => {
        
        dispatch({ type: 'FETCH_BLANKCARDS_BYCUSTOMER_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_BLANKCARDS_BYCUSTOMER_REJECTED', payload: err });
      });
  };
}

export function getSchemesByCus(cid) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_SCHEMES_BYCUSTOMER_START' });
    axios
      .get(API_URL_CARD_ACC + "api/v1/schemes?customerId=" + cid, {
        headers: authHeader(),
      })
      .then((res) => {
        dispatch({
          type: "FETCH_SCHEMES_BYCUSTOMER_FULFILLED",
          payload: res.data.content,
        });
      })
      .catch((err) => {
        dispatch({type: "FETCH_SCHEMES_BYCUSTOMER_REJECTED", payload: err});
      });
  };
}

export function getAllVouchersByCustomer(customerNumber, page = 1, size = 10) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_GENERATED_VOUCHERS_START' });
    axios
      .get(`${API_URL_SF_VOUCHER}api/v1/vouchers/customer/${customerNumber}?page=${page}&size=${size}`, {
        headers: authHeader(),
      })
      .then((res) => {
        dispatch({
          type: "FETCH_GENERATED_VOUCHERS_FULFILLED",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({type: "FETCH_GENERATED_VOUCHERS_REJECTED", payload: err});
      });
  };
}

export function getAllVouchersByCustomerForStats(customerNumber) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_GENERATED_VOUCHERS_LIST_START' });
    axios
      .get(`${API_URL_SF_VOUCHER}api/v1/vouchers/customer/${customerNumber}/listing`, {
        headers: authHeader(),
      })
      .then((res) => {
        dispatch({
          type: "FETCH_GENERATED_VOUCHERS_LIST_FULFILLED",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({type: "FETCH_GENERATED_VOUCHERS_LIST_REJECTED", payload: err});
      });
  };
}

export function getSchemesByCustomerAndContract(customerNumber, contractNumber) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_SCHEMES_BY_CUSTOMER_AND_CONTRACT_START' });
    axios
      .get(
        API_URL_CARD_ACC +
          `api/v1/schemes?customerId=${customerNumber}&contractId=${contractNumber}`,
        {headers: authHeader()}
      )
      .then((res) => {
        if (res.data.content)
          dispatch({
            type: "FETCH_SCHEMES_BY_CUSTOMER_AND_CONTRACT_FULFILLED",
            payload: res.data.content,
          });
      })
      .catch((err) => {
        dispatch({
          type: "FFETCH_SCHEMES_BY_CUSTOMER_AND_CONTRACT_REJECTED",
          payload: err,
        });
      });
  };
}

export function getAllSchemes() {
  return function(dispatch) {
    dispatch({ type: 'FETCH_SCHEMES_START' });
    axios
      .get(API_URL + 'api/schemes/all?page=1&size=50', { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_SCHEMES_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_SCHEMES_REJECTED', payload: err });
      });
  };
}

export function getCustomerSuperUser(cid) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CUSTOMER_SUPERADMIN_START' });
    axios
      .get(API_URL + 'api/customers/superadmin?cid=' + cid, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_CUSTOMER_SUPERADMIN_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_CUSTOMER_SUPERADMIN_REJECTED', payload: err });
      });
  };
}

export function getCustomerUsers(cid) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CUSTOMER_USERS_START' });
    axios
      .get(API_URL + 'api/customers/users?cid=' + cid + '&page=1&size=100', { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_CUSTOMER_USERS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_CUSTOMER_USERS_REJECTED', payload: err });
      });
  };
}

export function getAllAssignedCard() {
  return function(dispatch) {
    axios
      .get(API_URL_CARD_ACC + 'api/v1/cards/assigned', { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_ALL_ASSIGNED_CARD', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_ALL_ASSIGNED_CARD_FAILED', payload: err });
      });
  };
}

export function clearError () {
  return function(dispatch) {
    dispatch({ type: 'CLEAR_ERRORS'})
  }
}

