import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CloseSVG } from '../../../assets/Svgs/Svgs';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Bell } from '../../../assets/Svgs/Bell.svg';
import { ReactComponent as NotifyIcon } from '../../../assets/Svgs/NotificationIcon.svg';
import { ReactComponent as XIcon } from '../../../assets/Svgs/X.svg';
import { ReactComponent as EmptyInboxIcon } from '../../../assets/Svgs/emptyInbox.svg';
import { Dropdown, Menu, Checkbox, Tabs, Button } from 'antd';
import { timeAgo } from '../../../helpers/util';
import useAxiosPost, { useDeleteNotification, useDownloadBulkReport, useDownloadBulkUserReport, useGetNotifications, usePostNotifications } from '../../../services/notificationService';
import { getUserProperties } from '../../../lib/Constants/UserTypes';
import { customConfig } from '../../../actions/action_constants';

const API_URL = customConfig.API_URL
const { TabPane } = Tabs;

const Notification = () => {
    const user = getUserProperties();
    const [isNotifyOpen, setIsNotifyOpen] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const { data, mutate } = useGetNotifications({ owner: user?.userEmail, page: 1, pageSize: 100 });
    const { error, loadingStates, Download } = useDownloadBulkReport(user);
    const { postData, error: postError, data: updateData, isLoading: marking } = useAxiosPost();
    const { postDelete, isLoading, data: delData, error: delError } = useDeleteNotification();
    const [openedNotifications, setOpenedNotifications] = useState([])
    const [unOpenedNotifications, setUnOpenedNotifications] = useState(() => {
        return data?.data?.filter((item) => item.status === 'SENT')
    })

    const [selectedItemIds, setSelectedItemIds] = useState([]);

    const notifications = useMemo(() => {
        return data?.data ?? []
    }, [data])

    useEffect(() => {
        return () => {
            handlemarkAsRead();
        //  setSelectedItemIds([])
        setIsDelete(false);
        }
    }, [])

    useEffect(() => {
        if (delData) {
             setSelectedItemIds([]);
             mutate();
            setShowConfirmation(false);
            setIsDelete(false)
        }
    }, [delData])

    const resetNotification = () => {
        handlemarkAsRead();
         setSelectedItemIds([])
        setIsDelete(false);
    }

    useEffect(() => {
        setUnOpenedNotifications(() => {
            return notifications?.filter((item) => item.status === 'SENT')
        })
    }, [notifications])

    const unOpenedCount = useMemo(() => {
        return unOpenedNotifications?.filter(item => item.status === 'SENT')?.length;
    }, [openedNotifications, unOpenedNotifications])

    const handleMouseEnter = (e) => {
        if (e.status !== 'SENT') {
            return;
        }
        let index = openedNotifications.findIndex(x => x === e.id);
        if (index === -1) {
            setOpenedNotifications([...openedNotifications, e.id])
            let _unOPened = unOpenedNotifications;
            let id = _unOPened.findIndex(x => x.id === e.id)
            _unOPened[id].status = 'READ';
            setUnOpenedNotifications(_unOPened);
        }
    }

    const handlemarkAsRead = async () => {
        if (openedNotifications.length >= 1) {
            await postData(openedNotifications);
            mutate()
        }
    }

    const handleMarkAllAsRead = async () => {
        if (unOpenedNotifications?.length >= 1) {
            setUnOpenedNotifications(() => unOpenedNotifications.map(item => item.status = 'READ'))
            await postData(unOpenedNotifications.map(item => item.id));
            mutate();
        }
    }
    const handleCheck = (id) => {
        setSelectedItemIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((itemId) => itemId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });
    };

    const handleDelete = () => {
        if (selectedItemIds.length === 0 && isDelete) {
            setIsDelete(false);
            return;
        }
        if (selectedItemIds.length > 0) {
            setShowConfirmation(true);
            return;
        }
        setIsDelete(true);
    }

    const dropDownOverlay = (
        //  <!-- Dropdown menu -->
        <Menu className='notify-menu' style={{ paddingTop: 40, paddingBottom: 40, borderRadius: 8, width: 400, top: 20, }}>
            <div className="notify-header">
                <h4 className='notify-h1'>Notifications</h4>
                <button style={{ marginTop: '-5px !important' }} onClick={() => setIsNotifyOpen(false)} className="read-btn m-0 p-0" type='button'>
                    <XIcon style={{ height: 18 }} className='m-0 p-0' />
                </button>
            </div>

            <Tabs onChange={resetNotification} size='small'>
                <TabPane style={{ maxHeight: 250, overflow: 'scroll' }} tab={`Unread (${unOpenedCount})`} key="1">
                    {unOpenedNotifications?.length >= 1 ?
                        <section style={{ marginTop: 24 }}>
                            {unOpenedNotifications?.map((notify, index) => {
                                return <Menu.Item onClick={() => { }} onItemHover={() => handleMouseEnter(notify)} key={`index-${index}`}><div className={`notify-item${index === unOpenedNotifications?.length - 1 ? ' last' : ""}`}>
                                    <div style={{ width: '100%' }} className="d-flex align-items-center">
                                    {isDelete === true && <Checkbox onChange={() => handleCheck(notify?.id)} style={{ alignSelf: 'flex-start', marginRight: 16 }} />}
                                        <div className='d-flex' style={{ width: '95%' }}>
                                            <NotifyIcon style={{ display: 'inline-block' }} className='mr-0' />
                                            <div className="pl-3" style={{ display: 'inline-block', maxWidth: '90%', }}>
                                                {/* <!-- Notification Content --> */}
                                                <div className="notify-text">
                                                    {notify?.message}
                                                </div>
                                                <span className='notify-action'>
                                                    perform more action {NotifcationNavigator(notify, loadingStates, Download)}
                                                </span>
                                                <span className="notify-time">
                                                    {timeAgo(notify?.createdAt)}
                                                </span>
                                            </div>
                                        </div>
                                        {notify?.status === "SENT" && <div style={{ background: '#0275D8', height: 6, width: 6, maxHeight: 6, borderRadius: 3 }}>
                                        </div>}
                                    </div>
                                </div>
                                </Menu.Item>
                            })}

                        </section> :
                        <div style={{ marginTop: 54 }} className='text-center'>
                            <EmptyInboxIcon />
                            <h3 className='notify-h3'>No Notifications</h3>
                            <h3 className='notify-p'>No unread notifications. You’re all caught up!</h3>
                        </div>
                    }
                </TabPane>
                <TabPane tab="All" key="2" style={{ maxHeight: 250, overflow: 'scroll' }}>
                    {notifications?.length >= 1 ?
                        <section style={{ marginTop: 24 }}>
                            {notifications?.map((notify, index) => {
                                return <Menu.Item onClick={() => { }} onItemHover={() => handleMouseEnter(notify)} className={`notify-item${index === notifications?.length - 1 ? ' last' : ""}`} key={`index-${index}`}>
                                    <div style={{ width: '100%' }} className="d-flex align-items-center">
                                        {isDelete === true && <Checkbox onChange={() => handleCheck(notify?.id)} style={{ alignSelf: 'flex-start', marginRight: 16 }} />}
                                        <div className='d-flex' style={{ width: '95%' }}>
                                            <NotifyIcon style={{ display: 'inline-block' }} className='mr-0' />
                                            <div className="pl-3" style={{ display: 'inline-block', maxWidth: '90%', }}>
                                                {/* <!-- Notification Content --> */}
                                                <div className="notify-text">
                                                    {notify?.message}
                                                </div>
                                                <span className='notify-action'>
                                                    perform more action {NotifcationNavigator(notify, loadingStates, Download)}
                                                </span>
                                                <span className="notify-time">
                                                    {timeAgo(notify?.createdAt)}
                                                </span>
                                            </div>
                                        </div>
                                        {notify?.status === "SENT" && <div style={{ background: '#0275D8', height: 6, width: 6, maxHeight: 6, borderRadius: 3 }}>
                                        </div>}
                                    </div>
                                </Menu.Item>
                            })}
                        </section> :
                        <div style={{ marginTop: 54 }} className='text-center'>
                            <EmptyInboxIcon />
                            <h3 className='notify-h3'>No Notifications</h3>
                            <h3 className='notify-p'>No unread notifications. You’re all caught up!</h3>
                        </div>
                    }
                </TabPane>
            </Tabs>
            <div className='notification-footer'>
                <Button onClick={handleMarkAllAsRead} className='notify-button'>
                    {marking? 'Loading': 'Mark all as read'}
                </Button>
                <Button onClick={handleDelete} className='notify-delete'>
                    Delete {selectedItemIds?.length > 0 ? `(${selectedItemIds.length})` : ''} Notification
                </Button>
            </div>
        </Menu>
    )

    const handleCloseConfirmation = () => {
        if (isLoading) {
            return;
        }
        setShowConfirmation(false);
        setIsDelete(false);
        setSelectedItemIds([])
    }

    const handleDeleteSelected = ()=>{
        postDelete(selectedItemIds);
    }

    return (
        // <!-- Notifications -->
        <>
            <div className="notify-menu-ctn dropdown">
                <Dropdown visible={isNotifyOpen} overlay={dropDownOverlay} trigger={['click']} placement="bottomLeft" onVisibleChange={handlemarkAsRead}>
                    <button
                        type="button"
                        className="p-0 m-0 notification-button text-gray-500 rounded-lg d-flex align-items-center justify-content-center"
                        onClick={() => setIsNotifyOpen(!isNotifyOpen)}
                    >
                        {unOpenedCount > 0 && <span className="notification-badge">{unOpenedCount}</span>}
                        <Bell className='m-0' />
                    </button>
                </Dropdown>
            </div>
            <Modal scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal" style={{ maxWidth: 375 }} show={showConfirmation}>
                {/* <ModalHeader toggle={()=>{}}></ModalHeader> */}
                <Modal.Body style={{
                    padding: 0
                }}>
                    <div className='payment-modal-container'>
                        <div className='delete-confirm-h'><h5 className='payment-modal-h mb-3'>Delete Notifications</h5><span onClick={handleCloseConfirmation}><CloseSVG style={{ height: 15, marginTop: '-10px !important' }} className="modal-close-icon" /></span></div>
                        <div>
                            <p>Are you sure you want to delete these notifications?</p>
                            <div className='container-internal'>
                                <Button disabled={isLoading} onClick={handleCloseConfirmation} style={{ color: '#353F50', width: 144 }} className='notify-delete'>
                                    Cancel
                                </Button>
                                <Button disabled={isLoading} onClick={handleDeleteSelected} style={{ background: '#DC4437', width: 144 }} className='notify-button-red'>
                                    {isLoading ? 'Deleting...' : 'Yes, Delete'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default React.memo(Notification);

const NotifcationNavigator = (notify, loadingStates, action) => {

    switch (notify?.eventType) {
        case "UPDATE_CONTRACT_PRODUCT_RATE":
            return <Link to="/vendor-admin/products/main">here</Link>;
        case "ADD_CONTRACT":
            return <Link to="/owner-admin/contracts/main">here</Link>;
        case "UPDATE_CONTRACT_LIMIT":
        case "UPDATE_CONTRACT":
        case "ADD_CONTRACT_REQUEST":
        case "UPDATE_CONTRACT_REQUEST":
        case "UPDATE_CONTRACT_LIMIT_REQUEST":
        case "ADD_CONTRACT_PRODUCT_RATE_REQUEST":
            return <Link to="/vendor-admin/contracts/main">here</Link>;

        case "UPDATE_CONTRACT_PRODUCT_RATE_REQUEST":
            return <Link to="/vendor-admin/products/main">here</Link>;
        case "ADD_FEE_REQUEST":
        case "UPDATE_FEE_REQUEST":
            return <Link to="/owner-admin/vendors/main">here</Link>;
        case "ADD_PRODUCT_REQUEST":
        case "UPDATE_PRODUCT_REQUEST":
            return <Link to="/vendor-admin/products/main">here</Link>;
        // case "BATCH_TERMINAL_UPLOAD":
        //     return <Link to="/owner-admin/terminals/main">here</Link>;
        //case "BATCH_TERMINAL_USER_UPLOAD":
        case "ADD_TERMINAL_USER_REQUEST":
            return <Link to="/owner-admin/posusers/main">here</Link>;
        case "BATCH_TERMINAL_USER_UPLOAD":
        case "BATCH_TERMINAL_UPLOAD":
        case "UPLOAD_CUSTOMER_USER":
        case "BATCH_VOUCHER_UPLOAD":
            return <Button style={{
                padding: 0,
                margin: 0,
                fontSize: 10,
            }} type="link" onClick={() => action(notify)}>{loadingStates[notify.id] ? 'downloading report...' : 'here'}</Button>
        default:
            return "";
    }
}

