import axios from 'axios';
// import { GET_ALL_VENDORS } from './OwnerVendorMgtConstants';
import { customConfig } from '../../action_constants';
import { authHeader } from '../../../helpers/authHeader';
// import { history } from '../../../helpers/history';
// import { userService } from '../../../services/authService';
// import { history } from '../../../helpers/history';

const API_URL = customConfig.API_URL;

// export function submitOwnerUserForm(body) {
//     return {
//       type: 'ADD_OWNER_USER',
//       payload: body,
//     };
//   }

// export function submitOwnerUserForm(body) {
//   return function(dispatch) {
//     axios
//       .post(API_URL + 'api/users', body)
//       .then(res => {
//         dispatch({ type: 'ADD_OWNER_USER', payload: res.data });
//       })
//       .catch(err => {
//       });
//   };
// }

// this has been deprecated as authentication now happens @ ../../../modules/Auth/Actions/auth_actions
// export function login(username, password) {
//   return dispatch => {
//     dispatch(request({ username }));

//     userService.login(username, password).then(
//       user => {
//         if (user) {
//           switch (user.data.type) {
//             case 'OWNER_USER':
//               history.push(
//                 user.data.isNew
//                   ? "/owner-admin/change-password/"
//                   : "/owner-admin/dashboard"
//               );
//               window.location.reload();
//               break;
//             case 'VENDOR_USER':
//               history.push(
//                 user.data.isNew
//                   ? "/vendor-admin/change-password/"
//                   : "/vendor-admin/dashboard"
//               );
//               window.location.reload();
//               break;
//             case 'CUSTOMER_USER':
//               history.push(
//                 user.data.isNew
//                   ? "/customer-admin/change-password/"
//                   : "/customer-admin/dashboard"
//               );
//               window.location.reload();
//               break;

//             default:
//               history.push('/login');
//               break;
//           }
//         }
//       },
//       error => {
//         dispatch(failure(error.toString()));
//         dispatch(alertError(error.toString()));
//       },
//     );
//   };

//   function request(user) {
//     return { type: 'USERS_LOGIN_REQUEST', user }; 
//   }
//   /* function success(user) {
//     return { type: 'USERS_LOGIN_SUCCESS', user };
//   } */
//   function failure(error) {
//     return { type: 'USERS_LOGIN_FAILURE', error };
//   }

//   // function alertSuccess(message) {
//   //   return { type: 'ALERT_SUCCESS', message };
//   // }

//   function alertError(message) {
//     return { type: 'alert-danger', message };
//   }

//   // function alertClear() {
//   //   return { type: 'ALERT_CLEAR' };
//   // }
// }

// export function logout() {
//   userService.logout();
//   return { type: 'USERS_LOGOUT' };
// }

export function toggleUserStatus(userId, mode) {
  return function(dispatch) {
    dispatch({type:'START_USERS_STATUS'})
    axios
      .put(
        API_URL + 'api/users/toggle?id=' + userId,
        {},
        {
          headers: authHeader(),
        },
      )
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'UPDATE_USERS_STATUS_FULFILLED', id: userId, mode: mode, response: { data: res.data.data, message: res.data.status.description, editingStatus: 1 } });
        } else if (res.status.code !== 0) {
          dispatch({ type: 'UPDATE_USERS_STATUS_FAILED', response: { message: res.data.status.description, editingStatus: 2 } });
        }
      })
      .catch(err => {
        dispatch({ type: 'UPDATE_USERS_STATUS_FAILED', response: { message: err.response.data.status.description, editingStatus: 2 } });
      });
  };
}

export function submitUserForm(data) {
  return function(dispatch) {
    dispatch({ type: 'ADD_OWNER_USER_START' });
    axios
      .post(API_URL + 'api/users', data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'ADD_OWNER_USER_SUCCESS', response: { data: res.data.data, message: res.data.status.description, fetchingStatus: 1 } });
        } else {
          dispatch({ type: 'ADD_OWNER_USER_FAILED', response: { message: res.data.status.description, fetchingStatus: 2 } });
        }
      })
      .catch(err => {
        const msg = 
          err.response && 
          err.response.data && 
          err.response.data.status &&
          err.response.data.status.description;
        
        dispatch({
          type: 'ADD_OWNER_USER_FAILED',
          response: {
            message: msg || 'User not added successfully',
            fetchingStatus: 2,
          },
        });
      });
  };
}

export function resetUserCreation() {
  return (dispatch) => {
    dispatch({type: 'RESET_ADD_OWNER_USER'})
  }
}

export function resetUserUpdate() {
  return dispatch => {
    dispatch({type: 'RESET_USER_UPDATE'});
  }
}

export function fetchUserModules(email) {
    return function (dispatch){
        axios.get(`${API_URL}api/modules/email?userEmail=${email}`, { headers: authHeader()})
        .then(res => {
            if (res.data.status.code === 0) {
                dispatch({ type: 'FETCH_USER_MODULES_FULFILLED', response: { data: res.data.data } });
            } else {
                // dispatch({ type: 'FETCH_USER_MODULES_FAILED', isAssignMode: true, response: { message: res.data.status.description } });
            }
        })
        .catch(err => {})
    }
}

export function assignUserModule(userId, userModule) {
    return function (dispatch) {
        dispatch({ type: "EDIT_ASSIGNED_MODULE_START"});
        axios
            .post(`${API_URL}api/module/assign?userId=${userId}&moduleId=${userModule.id}`, null, { headers: authHeader()})
            .then(res => {
                if (res.data.status.code === 0) {
                    dispatch({ type: 'EDIT_ASSIGNED_MODULE_FULFILLED', isAssignMode: true, response: { data: userModule/* , message: res.data.status.description */ } });
                } else {
                    dispatch({ type: 'EDIT_ASSIGNED_MODULE_FAILED', isAssignMode: true, response: { message: res.data.status.description } });
                }
            })
            .catch(err => {
                const msg =  err.response && err.response.data && err.response.data.status && err.response.data.status.description;
                dispatch({ type: 'EDIT_ASSIGNED_MODULE_FAILED', isAssignMode: true, response: { message: msg || 'Module assignment failed' } });
            })
    };
}

export function removeUserModule(userId, userModule) {
    return function (dispatch) {
        dispatch({ type: "EDIT_ASSIGNED_MODULE_START"});
        axios
            .delete(`${API_URL}api/module/delete?userId=${userId}&moduleId=${userModule.id}`, { headers: authHeader()})
            .then(res => {
                if (res.data.status.code === 0) {
                    dispatch({ type: 'EDIT_ASSIGNED_MODULE_FULFILLED', isAssignMode: false, response: { data: userModule/* , message: res.data.status.description */ } });
                } else {
                    dispatch({ type: 'EDIT_ASSIGNED_MODULE_FAILED', isAssignMode: false, response: { message: res.data.status.description } });
                }
            })
            .catch(err => {
                const msg = err.response && err.response.data && err.response.data.status && err.response.data.status.description;
                dispatch({ type: 'EDIT_ASSIGNED_MODULE_FAILED', isAssignMode: false, response: { message: msg || 'Module removal failed' } });
            })
    }
}

export function updateUserForm(data, id) {
  return function(dispatch) {
    dispatch({ type: 'ADD_OWNER_USER_START' });
    axios
      .put(API_URL + 'api/users?id=' + id, data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'ADD_OWNER_USER_SUCCESS', response: { data: res.data.data, message: res.data.status.description, fetchingStatus: 1 } });
        } else {
          dispatch({ type: 'ADD_OWNER_USER_FAILED', response: { message: res.data.status.description, fetchingStatus: 2 } });
        }
      })
      .catch(err => {
        const msg = 
          err.response && 
          err.response.data && 
          err.response.data.status &&
          err.response.data.status.description;
        dispatch({ 
          type: 'ADD_OWNER_USER_FAILED',
          response: { message: msg || 'User not Updated', fetchingStatus: 2 },
        });
      });
  };
}

// export function resetPassword(data) {
//   return function(dispatch) {
//     axios
//       .post()
//       .then(res => {
//         if (res.data.status.code !== 0) {
//           dispatch({ type: 'OCREATE_USER_REQUEST', response: { status: 2, message: res.data.status.description } });
//         } else {
//           dispatch({ type: 'OCREATE_USER_REQUEST', response: { status: 1, message: res.data.status.description } });
//         }
//       })
//       .catch(err => {
//         dispatch({ type: 'ADD_VENDOR_REJECTED', payload: err });
//       });
//   };
// }

export function resetPassword(data, handleAction, handleResult) {
  return function(dispatch) {
    dispatch({ type: 'RESET_PASSWORD_START' });
    axios
      .put(API_URL + 'api/users/reset-password', data)
      .then(res => {
        handleAction(false);
        if (typeof res.data.data === 'string') {
          handleResult(true);
          dispatch({ type: 'RESET_PASSWORD_FULLFILLED', payload: res.data });
        }
        
        if (typeof res.data.data === 'object') {
          handleResult(false, 'An error occurred! Try again.');
          dispatch({ type: 'RESET_PASSWORD_REJECTED', payload: 'An error occurred' });
        }
      })
      .catch(err => {
        handleAction(false);
        const message = err.response && err.response.data ? (err.response.data.status.description || err.response.data.data) : null;
        handleResult(false, message || "An error occurred! Try again.");
        dispatch({
          type: "RESET_PASSWORD_REJECTED",
          payload: message,
        });
      });
  };
}
