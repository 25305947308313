import React from "react";
import NumberFormat from 'react-number-format';

export default function CustomInput({
                 parentClass,
                 inputClass = "contract-select",
                 label,
                 type = "text",
                 handleInput,
                 isSelect = false,
                 value,
                 data = [],
                 presetData = "",
                 labelClass = "",
                 onBlurHandler = () => {},
                 disabled = false,
                 readOnly = false,
                 children,
                 isCompulsory = true,
                 maxLength,
                 placeHolder,
                 isTextArea = false,
                 numberOfLines,
                 isNumber=false,
               }) {
                 return (
                   <div className={parentClass}>
                     <label className={labelClass}>
                       {label}{" "}
                       {isCompulsory && <span className="asterisk">*</span>}
                     </label>
                     {isSelect && (
                       <select
                         className={inputClass}
                         value={value}
                         onChange={handleInput}
                         disabled={disabled}
                       >
                         {!!presetData && (
                           <option value="">{presetData}</option>
                         )}

                         {data}
                       </select>
                     )}
                     {!isSelect && isTextArea && (
                       <textarea
                         className={inputClass}
                         value={value}
                         type={type}
                         onChange={handleInput}
                         onBlur={onBlurHandler}
                         disabled={disabled}
                         contentEditable={disabled}
                         maxLength={maxLength}
                         rows={numberOfLines}
                         placeholder={placeHolder}
                         style={{ height: 80 }}
                       ></textarea>
                     )}
                     {!isSelect && !isTextArea && !isNumber && (
                       <input
                         className={inputClass}
                         value={value}
                         type={type}
                         onChange={handleInput}
                         onBlur={onBlurHandler}
                         disabled={disabled}
                         readOnly={readOnly}
                         maxLength={maxLength}
                         placeholder={placeHolder}
                       />
                     )}
                     {isNumber && (
                       <NumberFormat
                         className={inputClass}
                         thousandSeparator={true}
                         value={value}
                         type={"text"}
                         // create custom event cos NumberFormat sends object
                         // but " handleInput" accepts a js event
                         onValueChange={(values) => {
                           const cEvent = new CustomEvent(null, {
                             bubbles: true,
                             detail: { value: values.value },
                           });
                           handleInput(cEvent); // send the custom event to change handler
                         }}
                         onBlur={onBlurHandler}
                         disabled={disabled}
                         maxLength={maxLength}
                         placeholder={placeHolder}
                       />
                     )}
                     {children}
                   </div>
                 );
               }
