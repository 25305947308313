import React, { useState, useMemo } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import fileDownload from "js-file-download";
import { DatePicker, Radio, Select, Spin } from "antd";
import moment from "moment";
import {
  downloadContractByCustomer,
  getContractByCustomerId,
  getSettlementContractReportByCustomerForCDasboard,
} from "../../redux/action/CustomerActions";
import {
  loadAllCustomerVendors,
  fetchAllLocations,
} from "../../redux/action/OwnerVendorActions";
import {
  getUserProperties /* , getUser */,
} from "../../../../lib/Constants/UserTypes";
import { useEffect } from "react";
import SettlementContractList from "./ContractList";
import "../../Accounts/Users/Styles/Users.css";
// import ContractList from "./";
// import SettlementContractList from "../ContractList";

function SettlementContracts() {
  const dispatch = useDispatch();

  const startDate = moment(
    new Date(moment().subtract(1825, "days").format("LLLL"))
  );
  const endDate = moment(new Date(moment().subtract(0, "days").format("LLLL")));

  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [vendorNum, setVendorNum] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState(5);

  const props = useSelector((state) => state);
  const { allCustomerVendors } = props.customerVendor;
  const { allLocations } = props.oVendorReducer;
  const { cContract, fetching } = props.oTrailReducer;

  const { customer = 0, customerNumber = "" } = getUserProperties() || {};

  useEffect(() => {
    if (props.oTrailReducer.cDashoardTransactionsBlob) {
      fileDownload(
        props.oTrailReducer.cDashoardTransactionsBlob,
        "Settlement-Contract-Report.xlsx"
      );
      dispatch({ type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_RESET" });
    }
  }, [props.oTrailReducer.cDashoardTransactionsBlob]);

  useEffect(() => {
    batch(() => {
      dispatch(loadAllCustomerVendors(customer));
      dispatch(fetchAllLocations());
      dispatch(getContractByCustomerId(customer));
    });
  }, []);

  const handleCalendarChange = ([
    startDate0 = startDate,
    endDate0 = endDate,
  ]) => {
    setDateRange([moment(startDate0), moment(endDate0)]);
  };

  const activeContracts = useMemo(() => {
    if (cContract) {
      return cContract?.data?.filter(
        (contract) => contract.contractStatus === "ACTIVE"
      );
    }
    return;
  }, [cContract]);

  return (
    <div className="dashboard-container">
      {/* <div className="row text-center">
        {fetching && (
          <Spin
            wrapperClassName="spinner-wrapper"
            spinning={fetching}
            tip="Loading..."
          ></Spin>
        )}
      </div> */}

      {activeContracts && activeContracts?.[0]?.contractNumber && (
        <SettlementContractList
          {...props}
          downloading={props.oTrailReducer.downloading}
          dateRange={dateRange}
          status={status}
          vendorNum={vendorNum}
          location={location}
          onDownloadTransactions={() => {
            dispatch(
              downloadContractByCustomer(
                activeContracts?.[0]?.contractNumber,
                dateRange[0].format("YYYY-MM-DD"),
                dateRange[1].format("YYYY-MM-DD"),
                status,
                { vendorNum, location }
              )
            );
          }}
          onFetchTransactions={(pageNo, pageSize) =>
            activeContracts &&
            activeContracts?.[0]?.contractNumber &&
            dispatch(
              getSettlementContractReportByCustomerForCDasboard(
                activeContracts?.[0]?.contractNumber
              )
            )
          }
          activeContractsNumber={activeContracts?.[0]?.contractNumber}
          customerNumber={customerNumber}
        />
      )}

      {!activeContracts?.[0]?.contractNumber && (
        <SettlementContractList
          {...props}
          downloading={props.oTrailReducer.downloading}
          dateRange={dateRange}
          status={status}
          vendorNum={vendorNum}
          location={location}
          onDownloadTransactions={() => {
            dispatch(
              downloadContractByCustomer(
                activeContracts?.[0]?.contractNumber,
                dateRange[0].format("YYYY-MM-DD"),
                dateRange[1].format("YYYY-MM-DD"),
                status,
                { vendorNum, location }
              )
            );
          }}
          onFetchTransactions={(pageNo, pageSize) =>
            activeContracts &&
            activeContracts?.[0]?.contractNumber &&
            dispatch(
              getSettlementContractReportByCustomerForCDasboard(
                activeContracts?.[0]?.contractNumber
              )
            )
          }
          activeContractsNumber={activeContracts?.[0]?.contractNumber}
          customerNumber={customerNumber}
        />
      )}
    </div>
  );
}

export default SettlementContracts;
