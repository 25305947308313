import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import CardStatusBar from "./CardStatusBar";
import {
  getUserProperties,
} from "../../../../lib/Constants/UserTypes";
import CardList from "./CardList";
import {
  getSchemesByCus, 
  fetchAllCardsByScheme, 
  toggleSchemeCardStatus,
  fetchAllCustomerContractsWithProducts
} from "../../../../actions/CustomerActions/CardMgtActions/CardAction";

function Cards() {
  const dispatch = useDispatch();
  const [showStatusBar, setshowStatusBar] = useState(true);
  const [isSchemeLimitUpdate, setIsSchemeLimitUpdate] = useState(false);
  const [state, setState] = useState({
    selectedScheme: "",
  });

  const {customer, customerNumber, userLevel, number: userNumber} = getUserProperties() || {};

  useEffect(() => {
    const selectedScheme = JSON.parse(localStorage.getItem("selectedScheme"));
    setState({
      ...state,
      selectedScheme,
    });
    batch(() => {
      dispatch(getSchemesByCus(customerNumber));
      dispatch(fetchAllCardsByScheme(selectedScheme.customerId, selectedScheme.accountNumber));
      dispatch(fetchAllCustomerContractsWithProducts(customer));
    });
  }, [isSchemeLimitUpdate]);// eslint-disable-line react-hooks/exhaustive-deps

  const props = useSelector((state) => state);
  const {customerCards} = props;
  const cards = customerCards.allCardsBySchemes || [];
  const contracts = (customerCards.customerContractsWithProducts || []).filter(cc => (
    (!['TERMINATED', 'INACTIVE'].includes(cc.contract.contractStatus)) &&
    (cc.contract.contractNumber === state.selectedScheme.contractId)
  ));

  return (
    <div className="dashboard-container">
      {showStatusBar && 
        <CardStatusBar 
          {...props} 
          selectedScheme={state.selectedScheme} 
          onSchemeLimitUpdate={(status, selectedScheme) => {
            if (status) {
              localStorage.setItem("selectedScheme", JSON.stringify(selectedScheme));
              setState({...state, selectedScheme})
            }

            setIsSchemeLimitUpdate(status)
          }} 
          userLevel={userLevel}
        />}

      <CardList
        {...props}
        cards={cards.content}
        isStatusBarVisible={showStatusBar}
        userNumber={userNumber}
        customerNumber={customerNumber}
        myCards={cards}
        selectedScheme={state.selectedScheme}
        selectedSchemeContracts={contracts}
        onHideStatusBar={(status) => {
          setshowStatusBar(!status);
        }}
        isTogglingSchemeCard={customerCards.isTogglingSchemeCard}
        toggleSchemeCardResponse={customerCards.toggleSchemeCardResponse || {}}
        onToggleSchemeCard={(pan, expiryDate, accountNumber, isToBlock) => {
          dispatch(toggleSchemeCardStatus(pan, expiryDate, accountNumber, isToBlock))
        }}
      />
    </div>
  );
}

export default Cards;
