import React from "react";
import { Spin } from "antd";

const BgLoader = ({ message }) => {
  return (
    <section
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        width: "100%"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#ffffff",
          height: "100%"
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            // color: "dodgerblue",
            fontWeight: "bold",
            color: "#00425F"
          }}
        >
          <Spin /* size="large" */ style={{marginRight: 10}} />
          { message || 'Loading all assets...'}
        </span>
      </div>
    </section>
  );
};

export default BgLoader;
