import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  BackSVG,
  UploadSVG,
  AvatarSVG,
  LoadingSVG,
  UserCompleteMarkSVG
} from "../../../../assets/Svgs/Svgs";
import EditForm from "./EditVendor";
import FileBase64 from "react-file-base64";
import { resetVendorCreation } from "./vendors.actions";
import { useDispatch } from "react-redux";

const CreateVendor = props => {
  const [isCreatingVendor, setIsCreatingVendor] = useState(false);
  const [showCreateSuccessModal, setShowCreateSuccessModal] = useState(false);
  const [profilePix, setProfilePix] = useState("");
  const [vendorCreationError, setVendorCreationError] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = () => { 
    setIsCreatingVendor(false);
    setShowCreateSuccessModal(false);
  };

  useEffect(() => {
    setIsCreatingVendor(props.oVendorReducer.isCreatingVendor)
  }, [props.oVendorReducer.isCreatingVendor])

  useEffect(() => {
    if(props.oVendorReducer.fetchingStatus === 1){
      setShowCreateSuccessModal(true);
    }
    else if(props.oVendorReducer.fetchingStatus === 2){
      setVendorCreationError(true);
    }
  }, [props.oVendorReducer.fetchingStatus])


  useEffect(() => {
    setIsCreatingVendor(false);
    setShowCreateSuccessModal(false);
    dispatch(resetVendorCreation());
}, [])

  return (
    <>
      <>
        <Modal
          isOpen={isCreatingVendor || showCreateSuccessModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="user-modal"
        >
          <ModalBody>
            {isCreatingVendor && (
              <>
                <div className="text-center mt-5 header">Creating vendor....</div>
                <div className="text-center mt-5 mb-5">
                  <LoadingSVG />
                </div>
              </>
            )}
            {showCreateSuccessModal && (
              <>
                <div className="text-center mt-5 mb-4">
                  <span
                    className="d-block font-weight-bold"
                    style={{
                      fontSize: "20px",
                      color: "#4B4B4B",
                      margin: "1rem"
                    }}
                  >
                    Thank you! You are all set!
                  </span>
                  <UserCompleteMarkSVG />
                  <span
                    className="d-block quickteller-blue font-weight-bold"
                    style={{
                      fontSize: "1rem",
                      color: "#00425F",
                      margin: "1.25rem"
                    }}
                  >
                    Your new vendor profile has been successfully created
                  </span>
                </div>
                <div className="row modal-action-buttons">
                  <div className="col-6">
                    <button className="back" onClick={props.onCreatingDone}>Back</button>
                  </div>
                  <div className="col-6">
                    <button className="create" onClick={()=>{setResetForm(true); toggleModal(); setProfilePix("")}}>Create new</button>
                  </div>
                </div>
                <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
              </>
            )}
          </ModalBody>
        </Modal>
      </>
      <div className="underlined-tab">
        <span className="tab-title">Create new Vendor</span>
        <div className="action-buttons">
          <button className="export-btn" onClick={props.backFunction}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className="row customer-details">
        <div className="col-3 profile-picture-and-actions">
          
            {!profilePix ? (
              <div className="avatar"><AvatarSVG /></div>
            ) : (
              <img
                src={profilePix.base64} alt="profile-face"
                width="100px"
                height="100px"
                style={{ borderRadius: 50 }}
              />
            )}
          <div className="upload">
            <i>
              <UploadSVG />
            </i>{" "}
            <FileBase64 multiple={false} onDone={file => setProfilePix(file)} />
          </div>
          <button
            className="button remove-btn"
            onClick={() => {
              setProfilePix("");
              
            }}
          >
            Remove
          </button>
        </div>
        <div className="col-8 edit-form"> 
          <EditForm {...props} create={true} resetForm={resetForm} profilePix={profilePix} vendorCreationError={vendorCreationError} vendorCreationErrorHandler = {status => setVendorCreationError(status)} />
        </div>
      </div>
    </>
  );
};

export default CreateVendor;
