import React, {useEffect} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import ReportStatusBar from '../StatusBar';
import { getAllSettlements } from "../../redux/action/CustomerTrailActions";
import SettlementList from "./SettlementList";
// import moment from "moment";

function Settlements () {
  const dispatch = useDispatch();
  // const startDate = moment(new Date(moment().subtract(7, 'days').format('LLLL'))).format('YYYY-MM-DD')
  // const endDate = moment(new Date(moment().subtract(0, 'days').format('LLLL'))).format('YYYY-MM-DD')
  useEffect(()=> {
    batch(()=>{
      dispatch(getAllSettlements());
    })
    
  }, []);

  const props = useSelector(state => state);

  return (
    <div className="dashboard-container">
      <ReportStatusBar {...props} isVisible={true} />
      <SettlementList {...props} />
    </div>
  )
}

export default Settlements;