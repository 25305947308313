export const OWNER_USER = "OWNER_USER";
export const CUSTOMER_USER = "CUSTOMER_USER";
export const CUSTOMER_ADMIN = "CUSTOMER_ADMIN";
export const VENDOR_USER = "VENDOR_USER";
export const VENDOR_ADMIN = "VENDOR_ADMIN";
export const VENDOR_SUPER_ADMIN = "VENDOR_SUPER_ADMIN";
export const LOGISTIC = "LOGISTIC";
export const PROCUREMENT = "PROCUREMENT";
export const SC_MANAGER = "SC_MANAGER";
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const STATION_MANAGER = 'STATION_MANAGER';

export const getUserType = () => {
    return JSON.parse(localStorage.getItem('user'))?.type;
}

export const getUserProperties = () => {
    return JSON.parse(localStorage.getItem('user'));
}

export const getUser = (userType) => {
    return (userType === OWNER_USER || userType === SUPER_ADMIN) ? 'Owner' :
        (userType === CUSTOMER_USER || userType === CUSTOMER_ADMIN) ? 'Customer' : 'Vendor';
}

export const getUserLevel = () => getUserProperties()?.userLevel === SUPER_ADMIN ? 'Super Admin' : getUserProperties()?.userLevel === ADMIN ? 'Admin' : 'User';