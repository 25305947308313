export const PAGINATE_INIT = 'PAGINATE_INIT';
export const PAGINATE_NEXT = 'PAGINATE_NEXT';
export const PAGINATION_SIZE_CHANGE = 'PAGINATION_SIZE_CHANGE';


export const paginateInit = () => (
    { type: PAGINATE_INIT }
);

export const paginateNext = page => (
    { type: PAGINATE_NEXT, payload: page }
);

export const paginationSizeChange = size => (
    { type: PAGINATION_SIZE_CHANGE, payload: size }
)
