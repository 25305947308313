import React, {useMemo, useState} from "react";
import "../../../../assets/Styles/Dashboard.css";
import {
  CreateSVG,
  ExportSVG,
  FilterSVG,
  MarkSVG,
  MissSVG,
  CloseSVG,
} from "../../../../assets/Svgs/Svgs";
import {CSVLink} from "react-csv";
import toSentenceCase from "../../../../lib/Functions/toSentenceCase";
import Pagination from "../../../../lib/Components/Pagination";
import CreatePosUser from "./CreatePosUser";
import Modal from "react-bootstrap/Modal";
import BulkTerminalUserUpload from "../../../shared/BulkTerminalUser";

const PosUsersList = (props) => {
  const [isCreatePos, setIsCreatePos] = useState(false);
  const [isEditPos, setIsEditPos] = useState(false);

  const [isPosUserSelected, setIsPosUserSelected] = useState(false);
  const [selectedPosUser, setSelectedPosUser] = useState({});
  const [showBulkTerminalUserModal, setshowBulkTerminalUserModal] = useState(false);
  const {vendorTerminal, onHideStatusBar, onFetchPosUsers} = props;

  const allPosUsers = vendorTerminal.allPOSUsers;
  let totalCount = allPosUsers.count;
  let posData = allPosUsers ? allPosUsers.data : [];
  const pageSize = 10;

  const posList = posData && posData.map((value, i) => {
    const {firstName, lastName, id, status, phone, userEmail, terminalName} = value;
    return (
      <tr key={i}>
        <td className="table-description font-weight-bold">
          {id.userId}
        </td>
        <td className="table-description font-weight-bold">
          {id.usercode}
        </td>
        <td className="table-description font-weight-bold">
          {firstName + " " + lastName}
        </td>
        <td className="table-description">{phone}</td>
        <td className="table-description">{userEmail}</td>
        <td className="table-description">{terminalName || "----"}</td>
        <td className={"table-status"}>
          <div
            className={`status-btn ${
              status === "ACTIVE" ? "success" : "error"
            }`}
          >
            {toSentenceCase(status)}
          </div>
        </td>
        <td
          className="table-action"
          onClick={() => {
            setIsPosUserSelected(true);
            setSelectedPosUser(value);
          }}
        >
          View
        </td>
      </tr>
    );
  });

  const dataToExport = useMemo(() => {
    return posData && posData.map(({ id, firstName, lastName, phone, address, userLevel, status,
      image, userEmail, userType, createdAt, updatedAt, terminalId, terminalName }) => {
      return {
        userId: id.userId, userCode: id.usercode, firstName, lastName, phone : `"${phone}"`,
        address: `${address.street}, ${address.city}, ${address.state}, ${address.country}`,
        userLevel, status, image, userEmail, userType, createdAt, updatedAt, terminalId, terminalName
      }
    })
  }, [posData])
  const csvHeader = [
    { label: "User ID", key: "userId" },
    { label: "User Code", key: "userCode" },
    { label: "Firstname", key: "firstName" },
    { label: "Lastname", key: "lastName" },
    { label: "Phone number", key: "phone"},
    { label: "Address", key: "address"},
    { label: "User's Level", key: "userLevel"},
    { label: "Status", key: "status"},
    { label: "Email", key: "userEmail"},
    { label: "User type", key: "userType"},
    { label: "Terminal ID", key: "terminalId"},
    { label: "Terminal Name", key: "terminalName"},
    { label: "Created At", key: "createdAt"},
    { label: "Updated At", key: "updatedAt"},
  ]

  return (
    <div className="disbursed-cards-container row">
      {!isCreatePos && !isEditPos && (
        <>
        <div className="col-12 m-0 p-0">
          <div
            className="rfid-menu-link"
            style={{marginBottom: 0, marginRight: 20}}
          >
            <span style={{color: "#000000"}} className="active">
              All POS Users
            </span>
          </div>
           <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 10,
           }}>
              <button
                className="create-new-tags-btn mr-2"
                style={{ position: 'relative', border: 'none'}}
                onClick={()=> setshowBulkTerminalUserModal(true)}
              >
                <span className="align-middle">
                  <CreateSVG /> Add POS users
                </span>
              </button>
              <button
                className="create-new-tags-btn mr-2"
                style={{ position: 'relative', border: 'none'}}
                onClick={() => {
                  onHideStatusBar(true);
                  setIsCreatePos(true);
                }}
              >
                <span className="align-middle">
                  <CreateSVG /> Add POS user
                </span>
              </button>
                <button style={{ position: 'relative', border: 'none', right: 'unset'}}
                       className="terminals-export-btn mr-2">
                  <CSVLink
                    data={dataToExport || []}
                    headers={csvHeader}
                    filename={"POS Users.csv"}
                    className=""
                    target="_blank"
                  >
                    <span className="text-white">
                      Export CSV <ExportSVG />
                    </span>
                  </CSVLink>
                </button>
            </div>
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <td>User ID</td>
                  <td>User Code</td>
                  <td>Name</td>
                  <td>Phone</td>
                  <td>Email</td>
                  <td>Terminal Name</td>
                  <td>
                    Status <FilterSVG />
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {posList || (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No POS User available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          {
            <Pagination
              count={totalCount}
              pageSize={pageSize}
              fetchMethod={(pageNo, pageSize) => {
                onFetchPosUsers(pageNo, pageSize);
              }}
            />
          }
        </>
      )}

      <CreatePosUser
        {...props}
        isCreatePos={isCreatePos}
        isEditPos={isEditPos}
        selectedPosUser={selectedPosUser}
        onBackHandler={() => {
          setIsCreatePos(false);
          setIsEditPos(false);
          setIsPosUserSelected(false);
          onHideStatusBar(false);
        }}
      />

      <Modal
        show={isPosUserSelected}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="transaction-modal"
      >
        <Modal.Body style={{backgroundColor: "#E8EFF9"}}>
          <div className="pl-4 pr-3 pt-5 pb-5">
            <div style={{display: "flex"}}>
              <h6
                className="mt-2 ml-2 pb-3 text-left font-weight-bold quickteller-black"
                style={{
                  borderBottomColor: "#5F738C",
                  borderBottomWidth: 0.5,
                  borderBottomStyle: "solid",
                  textAlign: "left",
                  flex: 1,
                }}
              >
                POS User
              </h6>
              <span
                style={{
                  cursor: "pointer",
                  position: "relative",
                  bottom: 5,
                  width: 60,
                  textAlign: "center",
                }}
                onClick={() => {
                  setSelectedPosUser({});
                  setIsPosUserSelected(false);
                  onHideStatusBar(false);
                }}
              >
                <CloseSVG />
              </span>
            </div>
            {
              <>
                <div className="col-12 text-left mb-3 mt-1">
                  <span className="title d-inline-block mb-4 pb-2">
                    USER ID: {selectedPosUser.id?.usercode}-
                    {selectedPosUser.id?.userId}
                  </span>
                  <div className="mr-2 p-3 rounded">
                    <div className="row mb-5">
                      <div className="col-12">
                        <div className="row" style={{width: "100%"}}>
                          <div className="row ml-0 contract-modal-heading">
                            <div className="col-3">Name</div>
                            <div className="col-3">Phone Number</div>
                            <div className="col-3">Email</div>
                            <div className="col-3">Status</div>
                          </div>
                          <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                            <div className="col-3">
                              {toSentenceCase(
                                `${selectedPosUser.firstName} ${selectedPosUser.lastName}` ||
                                  "Unavailable"
                              )}
                            </div>
                            <div className="col-3">
                              {toSentenceCase(
                                selectedPosUser.phone || "Unavailable"
                              )}
                            </div>
                            <div className="col-3">
                              {toSentenceCase(
                                selectedPosUser?.userEmail || "Unavailable"
                              )}
                            </div>
                            <div className="col-3">
                              {toSentenceCase(selectedPosUser?.status)}{" "}
                              {selectedPosUser?.status === "ACTIVE" ? (
                                <MarkSVG size={14} />
                              ) : (
                                <MissSVG size={14} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-12">
                        <div className="row" style={{width: "100%"}}>
                          <div className="row ml-0 contract-modal-heading">
                            <div className="col-3">Street</div>
                            <div className="col-3">City</div>
                            <div className="col-3">State</div>
                            <div className="col-3">Country</div>
                          </div>
                          <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                            <div className="col-3">
                              {toSentenceCase(
                                `${selectedPosUser.address?.street}` ||
                                  "Unavailable"
                              )}
                            </div>
                            <div className="col-3">
                              {toSentenceCase(
                                selectedPosUser.address?.city || "Unavailable"
                              )}
                            </div>
                            <div className="col-3">
                              {toSentenceCase(
                                selectedPosUser.address?.state || "Unavailable"
                              )}
                            </div>
                            <div className="col-3">
                              {toSentenceCase(
                                selectedPosUser.address?.country
                              ) || "Unavailable"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="row" style={{width: "100%"}}>
                          <div className="row ml-0 contract-modal-heading">
                            <div className="col-3">Terminal Name</div>
                          </div>
                          <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                            <div className="col-3">
                              {toSentenceCase(
                                `${selectedPosUser.terminalName || "----"}`                                  
                              )}
                            </div>
                          </div>

                          <div className="row ml-0 font-weight-bold mt-4 contract-modal-value">
                            <div className="col-4">
                              <div
                                style={{
                                  backgroundColor: "#00425F",
                                  color: "#fff",
                                  display: "inline-block",
                                  borderRadius: 4,
                                  padding: "5px 30px",
                                  cursor: "pointer",
                                  marginTop: 30,
                                }}
                                onClick={() => {
                                  onHideStatusBar(true);
                                  setIsEditPos(true);
                                  setIsPosUserSelected(false)
                                }}
                              >
                                Edit POS User
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </Modal.Body>
      </Modal>
      {showBulkTerminalUserModal && <BulkTerminalUserUpload closeBulkUploadModal={()=>setshowBulkTerminalUserModal(false)} showBulkModal={showBulkTerminalUserModal}
        uploadComplete={()=>{
          setshowBulkTerminalUserModal(false);
          onFetchPosUsers(1, pageSize);
        }}
      />}
    </div>
  );
};

export default PosUsersList;
