import React, {useEffect} from "react";
import "../../../assets/Styles/Dashboard.css";
import { shortMonthNames } from "../../../lib/Functions/DateNames";
import './AccountStatusBar.css';
import { useDispatch, batch } from 'react-redux';
import { fetchUser } from './Users/users.actions';
import { fetchAllCustomers } from './Customers/customers.actions';
import { getAllVendors } from './Vendors/vendors.actions';

const StatusBar = (props) => {
  const dispatch = useDispatch();
  const {ownerUsers, oCustomerReducer, oVendorReducer} = props;
  const allCustomers = oCustomerReducer.allCustomers;
  const allUsers = ownerUsers.users;
  const allVendors = oVendorReducer.allVendors;
  const d = new Date();

  useEffect(() => {
    batch(() => {
        dispatch(fetchUser(1, 10));
        dispatch(fetchAllCustomers(1, 10));
        dispatch(getAllVendors(1, 10));
    });
}, []);

  return (
    <div className={`status-container row ${props.isVisible? "d-block": "d-none"}`}>
      <div className="compartment compartment-one col-3">
        <span className="title">Accounts Insights</span>
        <span className="sub-title">Summary of all users profile, both vendors and customers.</span>
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${shortMonthNames[d.getMonth()]} ${d.getFullYear()} `}</span>
      </div>
      <div className="compartment compartment-two col-9 ">
        <div className="row status-box">
          <div className="col col-3" style={{position: "relative", paddingLeft: 40, paddingRight: 40, paddingTop: 16, paddingBottom: 16, width: "25%"}}>
              <div className="row"><span className="title">Users</span></div>
              <div className="row status-sub-title">
                <div className="col-6 pl-0">TOTAL</div>
                <div className="col-6 pl-0">ACTIVE</div>
              </div>
              <div className="short-borders"></div>
              <div className="row status-values">
                <div className="col-6 pl-0">{allUsers.count || "0"}</div>
                <div className="col-6 pl-0">{"0"}</div>
              </div>
              <div className="short-borders">
          </div>
        </div>
          <div className="col col-4" style={{position: "relative", paddingLeft: 40, paddingRight: 40, paddingTop: 16, paddingBottom: 16, width: "35%"}}>
            <div className="row"><span className="title">Customers</span></div>
            <div className="row status-sub-title">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ACTIVE</div>
              <div className="col-4 pl-0">SUSPENDED</div>
            </div>
            <div className="row status-values">
              <div className="col-4 pl-0">{allCustomers.count || "0"}</div>
              <div className="col-4 pl-0">{allCustomers.activeCount || "0"}</div>
              <div className="col-4 pl-0">{allCustomers.suspendedCount || "0"}</div>
              <div className="short-borders"> </div>
            </div>
          </div>
          <div className="col col-5" style={{paddingLeft: 40, paddingRight: 40, paddingTop: 16, paddingBottom: 16, width: "35%"}}>
            <div className="row"><span className="title">Vendors</span></div>
            <div className="row status-sub-title">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ASSIGNED</div>
              <div className="col-4 pl-0">SUSPENDED</div>
            </div>
            <div className="row status-values">
              <div className="col-4 pl-0">{allVendors.count || "0"}</div>
              <div className="col-4 pl-0">{"0"}</div>
              <div className="col-4 pl-0">{"0"}</div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  )
};

export default StatusBar;