import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { CloseSVG } from "../../../../assets/Svgs/Svgs";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import { useSelector, useDispatch, batch } from "react-redux";
import { getAllUsers } from "../../redux/action/customerUserActions";
import { getUserProperties } from "../../../../lib/Constants/UserTypes";
import { loadAllCustomerVendors } from "../../redux/action/OwnerVendorActions";

export default ({ onHide, onFundWallet, isFundWalletError, hideError }) => {
  const [state, setState] = useState({
    vendorId: "",
    customerId: "",
    amount: "",
  });
  const [fundWalletErrorMessage, setFundWalletErrorMessage] = useState("");
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [computeError, setComputeError] = useState('');

  const props = useSelector(state => state);
  const users = props.customerUser.users;
  const myVendors = props.customerVendor.allCustomerVendors ? props.customerVendor.allCustomerVendors?.data || [] : [];
  const dispatch = useDispatch();

  function onHandleInput(field, e) {
    const isOk = (field === 'amount') ? computePurchasableQty(e.target.value) : true;

    if (isOk) {
      setState({ ...state, [field]: e.target.value });
    }
  }

  const computePurchasableQty = (value) => {
    setComputeError('');

    if (value && Number.isNaN(parseFloat(value))) {
      setComputeError('Only numbers!');
      return false;
    }

    return true;
  }

  useEffect(() => {
    batch(() => {
      dispatch(getAllUsers(getUserProperties().type));
      dispatch(loadAllCustomerVendors(
        props.authentication.user.customerNumber?.replace('CUS', '') ??
        props.authentication.user.number?.replace('USER', '')
      ))
    });
  }, []);// eslint-disable-line

  useEffect(() => {
    let customerUsers = users.length
      ? users.map((e) => {// eslint-disable-line
        if (e.status === "ACTIVE") {
          return (
            <option value={e.id} key={e.id}>
              {e.firstName} {e.lastName}
            </option>
          );
        }
      })
      : [];
    setCustomers(customerUsers);
  }, [users]);

  useEffect(() => {
    let customerVendors = myVendors.length
      ? myVendors.map((e, i) => {// eslint-disable-line
        if (e.status === "ACTIVE") {
          return (
            <option value={e.id} key={e.id}>
              {e.name}
            </option>
          );
        }
      })
      : [];
    setVendors(customerVendors);
  }, [myVendors]);

  const { vendorId, customerId, amount } = state;

  function isValidateError() {
    let errorMsg = "";
    if (amount <= 0) {
      errorMsg = "Amount must be greater than 0 naira";
    } else if (!vendorId) {
      errorMsg = "Please select a vendor";
    } else if (!customerId) {
      errorMsg = "Please select a driver";
    }
    setFundWalletErrorMessage(errorMsg);
    return errorMsg;
  }

  return (
    <Modal
      isOpen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      className="user-modal"
    >
      <ModalBody>
        <div className="card-management-row ml-3 mr-3">
          <div
            className="mb-4"
            style={{
              textAlign: "left",
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10,
              borderBottom: "0.5px solid #5F738C",
            }}
          >
            <h6 className="mt-5 qt-black font-weight-bold text-left d-inline">
              Fund Driver Wallet
            </h6>
            <span
              style={{ float: "right", cursor: "pointer" }}
              onClick={() => {
                onHide();
                hideError('');
              }}
            >
              <CloseSVG />
            </span>
          </div>
          <div className="row">
            <div className="form-group col-12">
              <label>Select Vendor</label>
              <select
                className="form-control my-md-3x"
                value={state.vendorId}
                onChange={(event) => {
                  setState({ ...state, vendorId: event.target.value });
                }}
                name="vendor"
              >
                <option>Select Vendor</option>
                {vendors}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12">
              <label>Select Driver</label>
              <select
                className="form-control my-md-3x"
                value={state.customerId}
                onChange={(event) => {
                  setState({ ...state, customerId: event.target.value });
                }}
                name="driver"
              >
                <option>Select Driver</option>
                {customers}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-8">
              <label>
                Amount
                {computeError &&
                  <span
                    className="text-warning ml-4"
                    style={{
                      fontWeight: 'normal',
                      fontSize: '12px'
                    }}>
                    {computeError}
                  </span>
                }
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text font-weight-bold">&#8358;</span>
                </div>
                <input
                  type="text"
                  value={amount}
                  className="form-control mt-0"
                  onChange={onHandleInput.bind(this, "amount")}
                />
              </div>
            </div>
            <div className="form-group col-4">
              <label>&nbsp;</label>
              <div className="assign-btn col-4x">
                <button
                  onClick={() => {
                    if (!isValidateError()) {
                      onFundWallet(state.vendorId, state.customerId, state.amount);
                    }
                  }}
                  className="ml-0"
                >
                  Fund
                </button>
              </div>
            </div>
          </div>
          {(!!fundWalletErrorMessage || !!isFundWalletError) && (
            <div className="row mt-2 mb-4">
              <ToastAlert
                parentClass="col-12"
                toastText={fundWalletErrorMessage || isFundWalletError}
                showToast={!!fundWalletErrorMessage || !!isFundWalletError}
                childClass=""
                onCloseHandler={() => {
                  !!fundWalletErrorMessage && setFundWalletErrorMessage("");
                  !!isFundWalletError && hideError("");
                }}
              />
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
