import axios from "axios";
import { customConfig } from "../../../../actions/action_constants";
import { authHeader } from "../../../../helpers/authHeader";

export function getTransactionsByCustomer(
  cid,
  startDate,
  endDate,
  page,
  pageSize,
  status,
  filterParams
) {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const location = options.location || "";

  if (vendorNum.length) params += `&vendorNum=${vendorNum}`;
  if (location.length) params += `&location=${location}`;

  return function (dispatch) {
    axios
      .get(
        `${customConfig.API_URL_CARD_AND_TRANS}api/v1/transactions?customerNum=${cid}&pageNum=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`,
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.status === 200) {
            dispatch({
              type: "GET_ALL_CUSTOMERS_TRANSACTION",
              response: { data: res.data },
            });
          } else {
            dispatch({
              type: "GET_ALL_CUSTOMERS_TRANSACTION",
              response: { data: {} },
            });
          }
        }
      })
      .catch((err) => {
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function getTransactionsByCustomerForCDasboard(
  cid,
  page,
  pageSize,
  startDate,
  endDate,
  status,
  filterParams
) {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const location = options.location || "";
  // setLoader(true);
  if (vendorNum.length) params += `&vendorNum=${vendorNum}`;
  if (location.length) params += `&location=${location}`;

  return function (dispatch) {
    axios
      .get(
        `${customConfig.API_URL_CARD_AND_TRANS}api/v1/transactions?customerNum=${cid}&pageNum=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`,
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GET_ALL_CUSTOMERS_TRANSACTION_CDASHBOARD",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "GET_ALL_CUSTOMERS_TRANSACTION_CDASHBOARD",
            response: { data: {} },
          });
        }
      })
      .catch((err) => {
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function getDriverReportByCustomerForCDasboard(
  cid,
  page,
  pageSize,
  startDate,
  endDate,
  status,
  filterParams
) {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const location = options.location || "";
  // setLoader(true);
  if (vendorNum.length) params += `&vendorNum=${vendorNum}`;
  if (location.length) params += `&location=${location}`;

  return function (dispatch) {
    axios
      .get(
        `${customConfig.API_URL_CARD_AND_TRANS}api/v1/transactions/driver-transactions?customerNum=${cid}&pageNum=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`,
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.status === 200) {
          // console.log("res",res.data)
          dispatch({
            type: "GET_ALL_CUSTOMERS_DRIVER_REPORT_CDASHBOARD",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "GET_ALL_CUSTOMERS_DRIVER_REPORT_CDASHBOARD",
            response: { data: {} },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function downloadTransactionsByCustomer(
  customerNum,
  startDate,
  endDate,
  status,
  filterParams,
  format = "xlsx"
) {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const location = options.location || "";
  // setLoader(true);
  if (vendorNum.length) params += `&vendorNum=${vendorNum}`;
  if (location.length) params += `&location=${location}`;
  let url = `${customConfig.API_URL_CARD_AND_TRANS}api/v1/transactions/download/${format}?customerNum=${customerNum}&startDate=${startDate}&endDate=${endDate}%2023:59:59&status=${status}${params}`;
  return function (dispatch) {
    dispatch({ type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_START" });
    axios
      .get(url, { responseType: "blob", headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_FULFILLED",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
            response: { data: null },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
          response: { data: null },
        });
      });
  };
}

export function downloadDriverReportByCustomer(
  customerNum,
  startDate,
  endDate,
  filterParams,
  format = "xlsx"
) {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const location = options.location || "";
  // setLoader(true);
  if (vendorNum.length) params += `&vendorNum=${vendorNum}`;
  if (location.length) params += `&location=${location}`;
  let url = `${customConfig.API_URL_CARD_AND_TRANS}api/v1/transactions/download/driver/xlsx?customerNum=${customerNum}&startDate=${startDate}&endDate=${endDate}`;
  return function (dispatch) {
    dispatch({ type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_START" });
    axios
      .get(url, { responseType: "blob", headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_FULFILLED",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
            response: { data: null },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
          response: { data: null },
        });
      });
  };
}

export function downloadSampleByCustomer(customerNum) {
  let url = `${customConfig.API_URL_SF_VOUCHER}api/v1/vouchers/sample/download?customerId=${customerNum}`;
  return function (dispatch) {
    dispatch({ type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_START" });
    axios
      .get(url, { responseType: "blob", headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_FULFILLED",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
            response: { data: null },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
          response: { data: null },
        });
      });
  };
}

export function downloadSMSFeeByCustomer(customerNum, startDate, endDate) {
  let url = `${customConfig.baseUrl}/smartfuel-userms/api/sms/invoice?customerNumber=${customerNum}&startDateTime=${startDate}&endDateTime=${endDate}`;
  return function (dispatch) {
    dispatch({ type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_START" });
    axios
      .get(url, { responseType: "blob", headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_FULFILLED",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
            response: { data: null },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
          response: { data: null },
        });
      });
  };
}

export function getSmsFeeListADasboard(page, pageSize, query) {
  let url = query
    ? `${customConfig.baseUrl}/smartfuel-userms/api/customers/search?q=${query}`
    : `${customConfig.baseUrl}/smartfuel-userms/api/customers/all?page=${page}&size=${pageSize}`;

  return function (dispatch) {
    dispatch({
      type: "FETCH_AllCUSTOMER_SMSFEE_START",
    });
    axios
      .get(url, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          // console.log("res",res.data)
          dispatch({
            type: "FETCH_AllCUSTOMER_SMSFEE_FULLFILLED",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "FETCH_AllCUSTOMER_SMSFEE_REJECTED",
            response: { data: {} },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function getSettlementContractReportByCustomerForCDasboard(contractNum) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_AllCUSTOMER_SETTLEMENTCONTRACT_START",
    });
    axios
      .get(
        `${customConfig.baseUrl}/smartfuel-userms/api/contract/transactions?contractNumber=${contractNum}`,
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.status === 200) {
          // console.log("res",res.data)
          dispatch({
            type: "FETCH_AllCUSTOMER_SETTLEMENTCONTRACT_FULLFILLED",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "FETCH_AllCUSTOMER_SETTLEMENTCONTRACT_REJECTED",
            response: { data: {} },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function getContractByCustomerId(cid) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_AllCUSTOMER_CONTRACT_START",
    });
    axios
      .get(
        `${customConfig.baseUrl}/smartfuel-userms/api/contracts/customers?cid=${cid}&page=1&size=10`,
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.status === 200) {
          // console.log("res",res.data)
          dispatch({
            type: "FETCH_AllCUSTOMER_CONTRACT_FULLFILLED",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "FETCH_AllCUSTOMER_CONTRACT_REJECTED",
            response: { data: {} },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function getSmsNotificationByCustomerForCDasboard(
  cid,
  page,
  pageSize,
  startDate,
  endDate,
  status,
  filterParams
) {
  let params = "";
  let options = filterParams || {};
  const vendorNum = options.vendorNum || "";
  const location = options.location || "";
  // setLoader(true);
  if (vendorNum.length) params += `&vendorNum=${vendorNum}`;
  if (location.length) params += `&location=${location}`;

  return function (dispatch) {
    dispatch({
      type: "FETCH_SMS_NOTIFICATION_START",
    });
    axios
      .get(
        `${customConfig.API_URL_SF_VOUCHER}api/v1/vouchers/customer/notifications?customerNumber=${cid}&startDate=${startDate}&endDate=${endDate}`,
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "FETCH_SMS_NOTIFICATION_FULLFILLED",
            response: { data: res.data?.data },
          });
        } else {
          dispatch({
            type: "FETCH_SMS_NOTIFICATION_REJECTED",
            response: { data: {} },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // dispatch(getAllVendorsLoadingStatus(2));
      });
  };
}

export function downloadContractByCustomer(customerNum, startDate, endDate) {
  let url = `${customConfig.baseUrl}/smartfuel-userms/api/download/transactions?contractNumber=${customerNum}`;
  return function (dispatch) {
    dispatch({ type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_START" });
    axios
      .get(url, { responseType: "blob", headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_FULFILLED",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
            response: { data: null },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
          response: { data: null },
        });
      });
  };
}

export function toggleSMSFeeByCustomer(customerNum, toggled) {
  let url = `${customConfig.baseUrl}/smartfuel-userms/api/${customerNum}/toggleSmsSubscription?enableSmsSubscription=${toggled}`;
  return function (dispatch) {
    dispatch({ type: "TOGGLE_SMSFEE_START" });
    axios
      .put(url, {}, { headers: authHeader() })
      .then((res) => {
        console.log(res?.data?.status?.message, res.status);
        if (res.status === 200) {
          dispatch({
            type: "TOGGLE_SMSFEE_FULFILLED",
            response: { data: res?.data?.status?.message },
          });
        } else {
          dispatch({
            type: "TOGGLE_SMSFEE_REJECTED",
            response: { data: null },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED",
          response: { data: null },
        });
      });
  };
}

export function getToggleSmsStatus(customerNum) {
  let url = `${customConfig.baseUrl}/smartfuel-userms/api/customers/bynumber?cno=${customerNum}`;
  return function (dispatch) {
    dispatch({ type: "FETCH_TOGGLE_SMSFEE_STATUS_START" });
    axios
      .get(url, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "FETCH_TOGGLE_SMSFEE_STATUS_FULFILLED",
            response: { data: res.data },
          });
        } else {
          dispatch({
            type: "FETCH_TOGGLE_SMSFEE_STATUS_REJECTED",
            response: { data: null },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "FETCH_TOGGLE_SMSFEE_STATUS_REJECTED",
          response: { data: null },
        });
      });
  };
}
