import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../src/assets/Styles/Dashboard.css";
import ContractsStatusBar from "./StatusBar";
import ContractList from "./ContractList";
import {
  // viewAllContracts,
  createContract,
  updateContract,
  viewVendorContracts,
} from "../../ownerModule/redux/action/OwnerContractsActions";
import {
  // fetchAllVendors,
  // loadAllCustomerVendors_id,
} from "../../ownerModule/redux/action/OwnerVendorActions";
import {
  changeContractLimitRequest,
  changeProductPriceRequest, loadAllVendorCustomer, toggleContract,
} from "../redux/actions/CustomerActions";
import {getUserProperties} from "../../../lib/Constants/UserTypes";
import {
  approveContractLimitChange, 
  approveAddContractRequest, 
  approveProductRateChange, 
  fetchPendingRequests, 
  rejectRequest,
  approveAddContractProductRateRequest
} from "../../../actions/maker-checker-request-actions";

function Contracts() {
  const dispatch = useDispatch();
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);
  const pageSize = 10;

  useEffect(() => {
    batch(() => {
      dispatch(loadAllVendorCustomer(getUserProperties().vendor, 1, 1_000_000_000));
      dispatch(viewVendorContracts(getUserProperties().vendor, 1, pageSize));
      dispatch(fetchPendingRequests());
    });
  }, []);
  const props = useSelector((state) => state);

  return (
    <div className="dashboard-container">
      {isStatusBarVisible && <ContractsStatusBar {...props} />}

      <ContractList
        {...props}
        pageSize={pageSize}
        onHideStatusBar={(status) => {
          setIsStatusBarVisible(!status);
        }}
        onFetchContracts={(pageNo, pageSize) => {
          dispatch(
            viewVendorContracts(getUserProperties().vendor, pageNo, pageSize)
          );
        }}
        onFetchVendorCustomers={(vendorId) =>
          dispatch(loadAllVendorCustomer(vendorId, 1, 1_000_000_000))
        }
        onCreateContract={(data) => dispatch(createContract(data))}
        isAdmin={['SUPER_ADMIN', 'ADMIN'].includes(getUserProperties().userLevel)}
        onToggleContract={(contractId, isEnable) => dispatch(toggleContract(contractId, isEnable))}
        onRequestProductRateChange={(contractId, productId, rate) => {
          dispatch(changeProductPriceRequest(contractId, productId, rate));
        }}
        onRequestContractUpdate={(data, id) => dispatch(updateContract(data, id))}
        onRequestContractLimitChange={(contractId, limit) => {
          dispatch(changeContractLimitRequest(contractId, limit));
        }}
        currentUserId={getUserProperties().id}
        onFetchPendingRequest={() => dispatch(fetchPendingRequests())}
        onApproveAddContractRequest={requestId => dispatch(approveAddContractRequest(requestId))}
        onApproveAddContractProductRateRequest={requestId => dispatch(approveAddContractProductRateRequest(requestId))}
        onApproveProductRateChange={requestId => dispatch(approveProductRateChange(requestId))}
        onApproveContractLimitChange={requestId => dispatch(approveContractLimitChange(requestId))}
        onRejectRequest={requestId => dispatch(rejectRequest(requestId))}
      />
    </div>
  );
}

export default Contracts;
