import React, {useState} from "react";
import {useSelector} from "react-redux";
import StatusBar from '../StatusBar';
import UsersList from "./UsersList";

const Users = () => {
    const props = useSelector(state => state);
    const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);

    return (
        <div className={`${isStatusBarVisible? "page-container":"profile-page-container"}`}>
            <StatusBar isVisible={isStatusBarVisible}
                {...props} 
            />
            <UsersList {...props} onHideStatusBar={(status) => setIsStatusBarVisible(!status)}/>
        </div>
    )
}

export default Users;
