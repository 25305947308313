import React from 'react';
import { Redirect } from 'react-router-dom';

const Authorization = allowedRoles => WrappedComponent => {
  return class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);

      // In this case the user is hardcoded, but it could be loaded from anywhere.
      // Redux, MobX, RxJS, Backbone...
      this.state = {
        user: '',
      };
    }

    componentDidMount() {
      let loggedInUser = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {},
      user = {
        type: loggedInUser.type ? loggedInUser.type : '',
        role: loggedInUser.roles ? loggedInUser.roles : '',
        userLevel: loggedInUser.userLevel ? loggedInUser.userLevel : '',
      };

      this.setState(
        {
          user,
        },
        () => {
          
        },
      );
    }

    render() {
      const { type } = this.state.user;

      if (allowedRoles.includes(type)) {
        return <WrappedComponent {...this.props} />;
      } else {
        if (type) {
          if (type === 'CUSTOMER_USER' || type === 'CUSTOMER_ADMIN') {
            // return <Redirect to="/admin/change-password/main" />
            return <Redirect to="/customer-admin/dashboard" />;
          } else if (['VENDOR_USER', 'VENDOR_ADMIN', 'VENDOR_SUPER_ADMIN', 'LOGISTIC', 'PROCUREMENT'].includes(type)) {
            return <Redirect to="/vendor-admin/dashboard" />;
          } else if (type === 'OWNER_USER' || type === 'SUPER_ADMIN') {
            return <Redirect to="/owner-admin/dashboard" />;
          } else {
            return <Redirect to="/login" />;
          }
        } else {
          return null;
        }
      }
    }
  };
};

export const Owner = Authorization(['OWNER_USER', 'SUPER_ADMIN']);
export const Customer = Authorization(['CUSTOMER_USER', 'CUSTOMER_ADMIN']);
export const Vendor = Authorization(['VENDOR_USER', 'VENDOR_ADMIN', 'VENDOR_SUPER_ADMIN', 'LOGISTIC', 'PROCUREMENT']);
export const Scheme_mgr = Authorization(['SCMANAGER_USER']);

export const OwnerAdmin = Authorization(['OWNER_USER', 'SUPER_ADMIN', '']);
export const OwnerUser = Authorization(['OWNER_USER', 'SUPER_ADMIN']);
export const CustomerAdmin = Authorization(['CUSTOMER_USER', 'CUSTOMER_ADMIN']);
export const CustomerUser = Authorization(['CUSTOMER_USER', 'CUSTOMER_ADMIN']);
export const VendorAdmin = Authorization(['VENDOR_USER', 'VENDOR_ADMIN']);
export const VendorUser = Authorization(['VENDOR_USER', 'VENDOR_ADMIN']);
