const customerVoucherReducer = (
  state = {
    activeVouchers: [
      {
        id: 98273633242134,
        serial: 2938763129837,
        date_used: '',
        date_created: '12/11/2019',
        amount: 123220,
        product: 'PMS',
        holder: 'Abel',
        quantity: 2,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'active',
      },
      {
        id: 9827363234334,
        serial: 2938232119837,
        date_used: '',
        date_created: '01/11/2019',
        amount: 100220,
        product: 'AGO',
        holder: 'Fami',
        quantity: 3,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'active',
      },
      {
        id: 912121214334,
        serial: 984329119837,
        date_used: '',
        date_created: '01/09/2019',
        amount: 90220,
        product: 'AGO',
        holder: 'Asi',
        quantity: 3,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'active',
      },
      {
        id: 2121214334,
        serial: 99872119837,
        date_used: '',
        date_created: '01/12/2019',
        amount: 190220,
        product: 'PMS',
        holder: 'Asi',
        quantity: 3,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'active',
      },
    ],
    usedVouchers: [
      {
        id: 98273633242134,
        serial: 2938763129837,
        date_used: '22/11/2019',
        date_created: '12/11/2019',
        amount: 123220,
        product: 'PMS',
        holder: 'Abel',
        quantity: 2,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'used',
      },
      {
        id: 9827363234334,
        serial: 2938232119837,
        date_used: '21/11/2019',
        date_created: '01/11/2019',
        amount: 100220,
        product: 'AGO',
        holder: 'Fami',
        quantity: 3,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'used',
      },
      {
        id: 912121214334,
        serial: 984329119837,
        date_used: '12/11/2019',
        date_created: '01/09/2019',
        amount: 90220,
        product: 'AGO',
        holder: 'Asi',
        quantity: 3,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'used',
      },
      {
        id: 2121214334,
        serial: 99872119837,
        date_used: '22/12/2019',
        date_created: '01/12/2019',
        amount: 190220,
        product: 'PMS',
        holder: 'Asi',
        quantity: 3,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'used',
      },
    ],
    expiredVouchers: [
      {
        id: 98273633242134,
        serial: 2938763129837,
        date_used: '',
        date_created: '12/11/2019',
        amount: 123220,
        product: 'PMS',
        holder: 'Abel',
        quantity: 2,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'expired',
      },
      {
        id: 9827363234334,
        serial: 2938232119837,
        date_used: '',
        date_created: '01/11/2019',
        amount: 100220,
        product: 'AGO',
        holder: 'Fami',
        quantity: 3,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'expired',
      },
      {
        id: 912121214334,
        serial: 984329119837,
        date_used: '',
        date_created: '01/09/2019',
        amount: 90220,
        product: 'AGO',
        holder: 'Asi',
        quantity: 3,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'expired',
      },
      {
        id: 2121214334,
        serial: 99872119837,
        date_used: '',
        date_created: '01/12/2019',
        amount: 190220,
        product: 'PMS',
        holder: 'Asi',
        quantity: 3,
        expiry_date: '21/12/2019',
        customer: 'oando',
        status: 'expired',
      },
    ],
    fetching: false,
    fetched: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case 'FETCH_USERS': {
      return { ...state, fetching: true };
    }
    case 'TOGGLE_MODAL': {
      return { ...state, userCreateModal: !state.userCreateModal };
    }
    case 'FETCH_USERS_REJECTED': {
      return { ...state, fetching: false, error: action.payload };
    }
    case 'FETCH_USERS_FULFILLED': {
      return { ...state, fetching: false, fetched: true, users: action.payload };
    }
    case 'CREATE_VOUCHER': {
      return {
        ...state,
        activeVouchers: [...state.activeVouchers, action.payload],
      };
    }
    case 'UPDATE_USERS': {
      const { id } = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex(user => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers,
      };
    }
    case 'DELETE_USERS': {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
  }
  return state;
};

export default customerVoucherReducer;
