import React, {useState, useEffect} from "react";
import SubComponentHeader from "../../../lib/Components/SubComponentHeader";
import {useDispatch} from "react-redux";
import ToastAlert from "../../../lib/Components/ToastAlert";
import CustomInput from "../../../lib/Components/CustomInput";

export default function CreateProduct({
  isCreateProduct,
  isEditProduct,
  onBackHandler,
  onCreateProduct,
  onEditProduct,
  oVendorReducer,
  selectedProduct,
}) {
  const [formData, setFormData] = useState({
    name: "",
    unit: "",
    rate: "",
    other: "",
    description: "",
  });

  const [isCreatingProduct, setisCreatingProduct] = useState(false);
  const [isEditingProduct, setisEditingProduct] = useState(false);
  const [productValidationError, setproductValidationError] = useState(false);
  const [toast, setToast] = useState({class: "", text: ""});
  const dispatch = useDispatch();
  const setProducts = ["AGO", "DPK", "PMS"];

  const handleInput = (attr, event) => {
    setFormData({...formData, [attr]: event.target.value});
  };

  const isproductValidationError = () => {
    const {name, unit, rate, other} = formData;
    if ((!name && !other) || !unit || !rate || Math.sign(rate) === -1) {
      return true;
    } else {
      return false;
    }
  };

  const resetFormData = () => {
    setFormData({
      name: "",
      unit: "",
      rate: "",
      other: "",
      description: "",
    });
  };

  const createProduct = (event) => {
    setproductValidationError(false);
    event.preventDefault();
    if (!!isproductValidationError()) {
      setproductValidationError(true);
      setToast({
        class: "",
        text: "All compulsory fields must be correctly filled to continue!",
      });
      return;
    }
    const formObj = {...formData};
    if (!formData.name) {
      formObj.name = formData.other;
    }
    delete formObj.other;
    if (isCreateProduct) {
      onCreateProduct(formObj);
      setisCreatingProduct(true);
    } else {
      setisEditingProduct(true);
      onEditProduct(selectedProduct.id, formObj);
    }
  };

  useEffect(() => {
    const {name, rate, unit} = selectedProduct;
    const isAmongSetProduct = setProducts.includes(name);
    setFormData({
      name: isAmongSetProduct ? name : "",
      rate,
      unit,
      other: isAmongSetProduct ? "" : name,
    });
  }, [isEditProduct]);

  useEffect(() => {
    if (
      !!isCreatingProduct &&
      oVendorReducer.createProductRequestStatus !== 1
    ) {
      setToast({
        class: "",
        text:
          oVendorReducer.createProductRequestMessage ||
          "An unknown error has occurred!",
      });
      setproductValidationError(true);
      setisCreatingProduct(false);
    }
    if (
      !!isCreatingProduct &&
      oVendorReducer.createProductRequestStatus === 1
    ) {
      resetFormData();
      setToast({
        class: "text-white bg-success",
        text: oVendorReducer.createProductRequestMessage,
      });
      setproductValidationError(true);
      setisCreatingProduct(false);
      setReload();
    }
    dispatch({
      type: "CREATE_PRODUCT_REQUEST",
      response: {status: 0, message: ""},
    });
  }, [oVendorReducer.createProductRequestStatus]);

  useEffect(() => {
    if (!!isEditingProduct && oVendorReducer.editProductRequestStatus !== 1) {
      setToast({
        class: "",
        text:
          oVendorReducer.editProductRequestMessage ||
          "An unknown error has occurred!",
      });
      setproductValidationError(true);
      setisEditingProduct(false);
    }
    if (!!isEditingProduct && oVendorReducer.editProductRequestStatus === 1) {
      setToast({
        class: "text-white bg-success",
        text: oVendorReducer.editProductRequestMessage,
      });
      setproductValidationError(true);
      setisEditingProduct(false);
      
      setReload();
    }
    dispatch({
      type: "EDIT_PRODUCT_REQUEST",
      response: {status: 0, message: ""},
    });
  }, [oVendorReducer.editProductRequestStatus]);

  const setReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  return (
    <>
      {(isCreateProduct || isEditProduct) && (
        <>
          <SubComponentHeader
            heading={isCreateProduct ? "Create new product" : "Edit product"}
            onBackHandler={() => {
              setproductValidationError(false);
              onBackHandler();
            }}
          />

          <div className="row mt-2" style={{width: "100%"}}>
            <div className="offset-1 col-10 p-3">
              <div className="col-10 offset-1">
                <div
                  className="row bg-rfid-detailsm form bg-white ml-1 mr-1 pl-4 pr-4"
                  style={{
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)",
                    borderRadius: 4,
                  }}
                >
                  <div className="row">
                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Product Name"
                      isCompulsory={true}
                      value={formData.name}
                      isSelect={true}
                      presetData="Select a product..."
                      data={setProducts.map((item, i) => (
                        <option value={item} key={i}>
                          {item}
                        </option>
                      ))}
                      handleInput={(e) => {
                        setFormData({
                          ...formData,
                          other: "",
                          name: e.target.value,
                        });
                      }}
                    />
                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Other product (if not among the list)"
                      value={formData.other}
                      isCompulsory={false}
                      handleInput={(e) => {
                        setFormData({
                          ...formData,
                          name: "",
                          other: e.target.value,
                        });
                      }}
                    />
                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Unit Measurement"
                      isCompulsory={true}
                      value={formData.unit}
                      isSelect={true}
                      presetData="Select a unit..."
                      data={["Kilogramme", "Litre", "Pounds", "tonnes"].map(
                        (item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        )
                      )}
                      handleInput={handleInput.bind(this, "unit")}
                    />

                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Rate"
                      type="number"
                      isCompulsory={true}
                      value={formData.rate}
                      handleInput={(e) => {
                        setFormData({
                          ...formData,
                          rate: parseFloat(e.target.value),
                        });
                      }}
                    />

                    <div className="col-12">
                      <span style={{color: "#4B4B4B"}}>
                        All fields marked asterisk (
                        <span className="asterisk">* </span>) are compulsory
                      </span>
                    </div>

                    <ToastAlert
                      showToast={!!productValidationError}
                      parentClass={"col-12 mt-4 pb-3"}
                      childClass={toast.class}
                      toastText={toast.text}
                      onCloseHandler={() => {
                        setproductValidationError(false);
                      }}
                    />
                  </div>
                </div>

                <div className="buttons mt-3 text-right">
                  {isCreateProduct && (
                    <div
                      className="mr-4"
                      style={{
                        backgroundColor: "#5F738C",
                        color: "#fff",
                        display: "inline-block",
                        borderRadius: 4,
                        padding: "5px 30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setproductValidationError(false);
                        onBackHandler();
                      }}
                    >
                      Cancel
                    </div>
                  )}
                  <div
                    style={{
                      backgroundColor: "#00425F",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={
                      !isCreatingProduct && isCreateProduct
                        ? createProduct
                        : !isEditingProduct && isEditProduct
                        ? createProduct
                        : undefined
                    }
                  >
                    {isCreatingProduct || isEditingProduct ? (
                      <img
                        src="/images/ActivityIndicator.gif" alt="activity-indicator"
                        style={{height: 20, width: 20}}
                      />
                    ) : isCreateProduct ? (
                      "Create Product"
                    ) : (
                      "Save Changes"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </>
      )}
    </>
  );
}
