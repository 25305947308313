import React, { useState, useEffect } from "react";
// import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { /* Ellipse, */ CloseSVG/* , SearchSvg */ } from "../../../../assets/Svgs/Svgs";
/* import {
  updateCustomerToVendorList,
  resetUpdateCustomerToVendorList
} from "../../../vendorModule/redux/actions/CustomerActions"; */
import { useDispatch } from "react-redux";
// import formatAmount from "../../../../lib/Functions/formatAmount";
// import { submitUserForm } from "../../../../actions/OwnerActions/OwnerCustomerMgtActions/OwnerCustomerActions";
import { submitProductForm, resetCreateProduct } from "../../redux/action/OwnerVendorActions";
import AutoCloseModal from "../../../../lib/Functions/AutoCloseModal";

const CreateProduct = ({
  onCloseHandler,
  vendorId,
  oVendorReducer
}) => {
  
  const dispatch = useDispatch();
  const [formatError, setFormatError] = useState("");
  const [errorType, setErrorType] = useState("error");
  const [name, setName] = useState("");
  const [rate, setRate] = useState("");
  const [unit, setUnit] = useState("");


  useEffect(() => {
    if (oVendorReducer.createProductRequestStatus === 1) {
      setFormatError(oVendorReducer.createProductRequestMessage);
      setErrorType("success");
      AutoCloseModal(resetCreateProduct, true, dispatch)
    } else if (oVendorReducer.createProductRequestStatus === 2) {
      setErrorType("error");
      setFormatError(oVendorReducer.createProductRequestMessage);
    }
  }, [oVendorReducer.createProductRequestStatus]);

  useEffect(() => {
    dispatch(resetCreateProduct());
  }, [])

  const handleInput = (target, e) => {
    if (target === "rate") {
      setRate(e.target.value);
    }
    if (target === "name") {
      setName(e.target.value);
    }
    if (target === "unit") {
      setUnit(e.target.value);
    }
  };

  const validateInputs = () => {

    let isError = false;
    if (name.length < 3) {
      setFormatError("Name of products must be at least 3 characters!");
      isError = true;
    }
    else if (rate === "" || isNaN(rate) || rate < 0) {
      setFormatError("Enter a valid rate to continue!");
      isError = true;
    }
    else if (unit === "" || !isNaN(unit)) {
      setFormatError("Enter a valid number of units to continue!");
      isError = true;
    }
    return isError;
  }

  const submitForm = () => {
    if(validateInputs()) return;
    const data = {name, rate, unit};
    dispatch(
      submitProductForm(vendorId, data)
    );
  }


  return (
    <>
      <div className="card-management-row">
        <div
          className="mb-3"
          style={{
            textAlign: "left",
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 10,
            borderBottom: "0.5px solid #5F738C"
          }}
        >
          <h6 className="mt-5 qt-black font-weight-bold text-left d-inline">
            Add a new product
          </h6>
          <span
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {onCloseHandler(); dispatch(resetCreateProduct());}}
          >
            <CloseSVG />
          </span>
        </div>
      </div>
      <div className="col-8 offset-2 mb-5">
        <div className="password">
          <label>Name</label>
          <div className="form-group">
            <input
              value={name}
              className="form-control"
              onChange={e => {
                handleInput("name", e)
              }}
              placeholder="Enter product name"
            />
            {/* <i className="input-icon" style={{    right: "0.5rem", position: "absolute", top: "1.55rem"}}>
                <SearchSvg color="#000" />
              </i> */}
          </div>
        </div>
        <div className="password mt-3">
          <label>Unit</label>
          <div className="form-group">
            <input
              value={unit}
              className="form-control"
              onChange={e => {
                handleInput("unit", e)
              }}
              placeholder="Choose a unit"
            />
          </div>
        </div>
        <div>
        <div className="password mt-3">
          <label>Rate</label>
          <div className="form-group">
            <input
              value={rate}
              className="form-control"
              onChange={e => {
                handleInput("rate", e);
              }}
              placeholder="Enter your rate"
            />
          </div>
        </div>
        <div>
          {!!formatError && (
            <div className="col-12 pb-3 pl-0 pr-0">
              <div className={`${errorType}-container ${errorType}-text `}>
                <span
                  className="error-close"
                  onClick={() => setFormatError("")}
                >
                  x
                </span>
                <span className={``}>{formatError}</span>
              </div>
            </div>
          )}
        </div>
        <div
          className="create-btn text-right mb-5"
          style={{ position: "relative", bottom: 0, flight: "right" }}
        >
          <button
            onClick={() => {
              submitForm()
            }}
            style={{ padding: "0.5rem 2rem 0.5rem 2rem", marginLeft: 0}}
          >
            {oVendorReducer.startCreateProductRequest ? (
              <img
                src="/images/ActivityIndicator.gif" alt="activity-indicator"
                style={{ height: 20, width: 20, marginTop: 0 }}
              />
            ) : (
              "Add Product"
            )}
          </button>
        </div>
      </div>
      </div>
    </>
  );
};

export default CreateProduct;
