import React, { Component } from "react";
import "../Admin.css";
import "../../../../src/assets/Styles/SideMenu.css";
import {
  DashboardSvg,
  AccountSvg,
  ContractSvg,
  // TokenSvg,
  AuditSvg,
  ReportSvg,
  CustomerSVG,
  TerminalSvg,
  //ProductSvg,
  ProductSvg2,
} from "../../../assets/Svgs/Svgs";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import { getAllUsers } from "../../ownerModule/Accounts/Users/users.actions";
import { fetchAllTerminalsByVendor, getAllProductsByVendor } from "../../ownerModule/redux/action/OwnerVendorActions";
import { loadAllVendorCustomer } from "../../vendorModule/redux/actions/CustomerActions";
import { getUserProperties } from "../../../lib/Constants/UserTypes";
import { viewVendorContracts } from "../../ownerModule/redux/action/OwnerContractsActions";

class VMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // let is logged in be taken from fakeAuth
      isloggedIn: false,
      customerMenu: false,
      vendorMenu: false,
      ownerMenu: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  }

  handleSearchBtnClick(e) {
    const pathName = window.location.pathname;

    if (pathName === "/vendor-admin/users/main") {
      this.props.getAllUsersFn(e.target.value)
    }

    if (pathName === "/vendor-admin/customers/main") {
      this.props.loadAllVendorCustomerFn(e.target.value)
    }

    if (pathName === "/vendor-admin/terminals/main") {
      this.props.fetchAllTerminalsByVendorFn(e.target.value)
    }

    if (pathName === "/vendor-admin/contracts/main") {
      this.props.viewVendorContractsFn(e.target.value)
    }

    if (pathName === "/vendor-admin/products/main") {
      this.props.getAllProductsByVendorFn(e.target.value)
    }
  }

  render() {
    const pathName = window.location.pathname;

    return (
      <div>
        <Navbar onSearchBtnClick={this.handleSearchBtnClick.bind(this)} />

        <div className="side-menu-container">
          <ul className="">
            <NavLink to={!this.state.user.isNew ? "/vendor-admin" : "#"}>
              <li
                className={`side-menu-list ${(pathName === "/vendor-admin/dashboard" ||
                    pathName === "/vendor-admin/change-password/main") &&
                  "active"
                  }`}
              >
                <DashboardSvg />
                <span>Dashboard</span>
              </li>
            </NavLink>

            {!this.state.user.isNew && this.state.user.userLevel !== 'STATION_MANAGER' && (
              <>
                <NavLink to="/vendor-admin/users">
                  <li
                    className={`side-menu-list ${(pathName.indexOf("/vendor-admin/users/main") > -1 ||
                        pathName.indexOf("/vendor-admin/customers/") > -1 ||
                        pathName.indexOf("/vendor-admin/vendors/") > -1) &&
                      "active"
                      }`}
                  >
                    {pathName === "/vendor-admin/customers/main" ? (
                      <CustomerSVG />
                    ) : (
                      <AccountSvg />
                    )}
                    <span>Accounts</span>
                  </li>
                </NavLink>

                {/* <NavLink to="/vendor-admin/scheme/main">
                  <li
                    className={`side-menu-list ${
                      (pathName === "/vendor-admin/cards/main" ||
                        pathName === "/vendor-admin/scheme/main") &&
                      "active"
                    }`}
                  >
                    <TokenSvg />
                    <span>Tokens</span>
                  </li>
                </NavLink> */}

                <NavLink to="/vendor-admin/contracts">
                  <li
                    className={`side-menu-list ${pathName === "/vendor-admin/contracts/main" && "active"
                      }`}
                  >
                    <ContractSvg />
                    <span>Contracts</span>
                  </li>
                </NavLink>
                <NavLink to="/vendor-admin/payments">
                  <li
                    className={`side-menu-list ${(pathName === "/vendor-admin/payments") && "active"
                      }`}
                  >
                    <ContractSvg />
                    <span>Payments</span>
                  </li>
                </NavLink>

                {/* {(hasRole(this.state.user, ['SUPER_ADMIN', 'ADMIN']) || isAllowed(this.state.user, ['VIEW_SETTLEMENT', 'VIEW_TRANSACTION'])) && ( */}
                <NavLink to="/vendor-admin/products">
                  <li
                    className={`side-menu-list ${pathName === "/vendor-admin/products/main" && "active"
                      }`}
                  >
                    <ProductSvg2 />
                    <span>Products</span>
                  </li>
                </NavLink>
                <NavLink to="/vendor-admin/reports">
                  <li
                    className={`side-menu-list ${(pathName === "/vendor-admin/reports/main" ||
                        pathName === "/vendor-admin/settlements/main") &&
                      "active"
                      }`}
                  >
                    <ReportSvg />
                    <span>Reports</span>
                  </li>
                </NavLink>
                <NavLink to="/vendor-admin/terminals">
                  <li
                    className={`side-menu-list ${(pathName.indexOf("/vendor-admin/posusers/main") > -1 ||
                        pathName.indexOf("/vendor-admin/terminals/main") >
                        -1) &&
                      "active"
                      }`}
                  >
                    <TerminalSvg />
                    <span>Terminals</span>
                  </li>
                </NavLink>

                {/* {/* {(hasRole(this.state.user, ['SUPER_ADMIN', 'ADMIN']) || isAllowed(this.state.user, ['VIEW_AUDIT'])) && ( */}
                <NavLink to="/vendor-admin/trail">
                  <li
                    className={`side-menu-list ${pathName.indexOf("/vendor-admin/trail") > -1 && "active"
                      }`}
                  >
                    <AuditSvg />
                    <span>Audit Trail</span>
                  </li>
                </NavLink>
                {/* )} */}
              </>
            )}

            {!this.state.user.isNew && this.state.user.userLevel === 'STATION_MANAGER' && (
              <>
                <NavLink to="/vendor-admin/station/products">
                  <li
                    className={`side-menu-list ${pathName === "/vendor-admin/station/products" && "active"
                      }`}
                  >
                    <ProductSvg2 />
                    <span>Products</span>
                  </li>
                </NavLink>
                <NavLink to="/vendor-admin/station/reports">
                  <li
                    className={`side-menu-list ${(pathName === "/vendor-admin/station/reports") &&
                      "active"
                      }`}
                  >
                    <ReportSvg />
                    <span>Reports</span>
                  </li>
                </NavLink>
                <NavLink to="/vendor-admin/station/terminals">
                  <li
                    className={`side-menu-list ${(pathName === "/vendor-admin/station/terminals") &&
                      "active"
                      }`}
                  >
                    <TerminalSvg />
                    <span>Terminals</span>
                  </li>
                </NavLink>
              </>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllUsersFn: query => dispatch(getAllUsers(getUserProperties().type, query)),
    loadAllVendorCustomerFn: query => dispatch(loadAllVendorCustomer(getUserProperties().vendor, 1, 10, query)),
    fetchAllTerminalsByVendorFn: query => dispatch(fetchAllTerminalsByVendor(getUserProperties().vendor, 1, 10, '', query)),
    viewVendorContractsFn: query => dispatch(viewVendorContracts(getUserProperties().vendor, 1, 10, query)),
    getAllProductsByVendorFn: query => dispatch(getAllProductsByVendor(getUserProperties().vendor, 1, 10, query)),
  };
};

export default connect(null, mapDispatchToProps)(VMenu);
