import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Form, Dropdown } from 'react-bootstrap';
import { Skeleton, Alert, Input } from "antd";

import { batch, useDispatch, useSelector } from 'react-redux';
import { getUserProperties } from '../../../lib/Constants/UserTypes';
import { ReactComponent as MenuIcon } from '../../../assets/Svgs/menu_icon.svg';

import { addStateProductPrice, fetchState, fetchStateProductPrice, uploadBulkStatePrice } from '../../customerModule/redux/action/VendorAction';
import CustomToggle from '../../../lib/Components/CustomToggle';
import formatAmount from '../../../lib/Functions/formatAmount';
import { ReactComponent as GreenCheckIcon } from '../../../assets/Svgs/GreenCheck.svg'
import StatePriceFile from '../../../assets/upload_files/bulk-state-product-price.xlsx';
import { formatDate3 } from '../../../lib/Functions/formatDate';


export const StatePriceComponent = ({product, defaultRate, vendorCode, backFunction}) => {
    const { vendorNumber, type } = getUserProperties();
    const [showAddModal, setShowAddModal] = useState(false);
    const [rate, setRate] = useState(defaultRate);
    const [state, set_State] = useState("")
    const [pageInfo, setPageInfo] = useState({ page: 1, pageSize: 10 });
    const [errMsg,  setErrorMessage ] = useState("");
    const [showBulkModal, setShowBulkModal] = useState(false);
    const [bulkFile, setBulkFile ] = useState();
    const [fileError, setFileError] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [filter, setFilter] = useState('');

    const props = useSelector(s=> s.oVendorReducer);

    const dispatch = useDispatch();
    useEffect(()=>{
        batch(() => { 
            dispatch(fetchState({ page: 1, pageSize: 38}));
            dispatch(fetchStateProductPrice({vendorNumber: vendorCode ?? vendorNumber, produtName: product }))
        })
    },[])

    useEffect(()=>{
      if(props?.statePrice){
        if(props.statePrice?.status?.httpCode === 200){
            dispatch(fetchStateProductPrice({vendorNumber, produtName: product }))
        }
      }
    },[props?.statePrice])

    useEffect(()=>{
        if(props?.stateUpload){
          if(props.stateUpload?.status?.httpCode === 200){
              dispatch(fetchStateProductPrice({vendorNumber, produtName: product }))
          }
        }
      },[props?.statePrice])

   const filteredData = useMemo(()=>{
       if(filter === '') { return props?.statePrices?.data }
       else { return props?.statePrices?.data?.filter(x=>x.state.name.toLowerCase()?.includes(filter?.toLowerCase()))}
   },[ props?.statePrices?.data, filter])

   const handleSubmitForm=(e)=>{
    e.preventDefault();
    if(!state){
        setErrorMessage('You have to set for a state');
        return;
    }
    dispatch(addStateProductPrice({product, state, rate  }))
   }

    const handleUploadFile = (e) => {
        e.preventDefault();
        if (bulkFile) {
            if (bulkFile.size > 4 * 1024 * 1024) {
                setFileError('File size exceeds 4MB.');
                return;
            }
            dispatch(uploadBulkStatePrice(bulkFile));
        } else {
            setFileError('Please select a file.');
            return;
        }
    };
  
   const handleFileChange = (e) =>{
    setFileError('');
     if(e.target.files[0]){
        setBulkFile(e.target.files[0])
     }
   }

   const uploadBulkModal = ()=>{
    if(showBulkModal){
        dispatch({ type: "RESET_UPLOAD_PRICE"});
    }
    setShowBulkModal(!showBulkModal);
   }

    const showAddStatePriceModal = (e, item) => {
        if(showAddModal){  dispatch({ type: 'RESET_STATE_PRICE'})}
        setShowAddModal(!showAddModal)
        if (item) {
            setIsEdit(true)
            setRate(item.rate);
            set_State(item.state.name);
        } else {
            setRate('');
            set_State('');
            setIsEdit(false);
        }
    }

    const handleSelectedState = (e)=>{
        set_State(e.target.value);
    }

    const handleAmountChange = (e) => {
        const { value } = e.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setRate(value);
        }
    }

    const convertToNumber = () => {
        const numberValue = rate? rate.toString().replace(/,/g, '') : '';
        setRate(numberValue);
    };

    const formatAsMoney = () => {
        if (rate === '' || !/^\d*\.?\d*$/.test(rate)) {
            return;
        }
        const formattedValue = parseFloat(rate).toLocaleString();
        setRate(formattedValue);
    };

    return (
        <>
            <div className='sect-container'>
                <div className='d-flex justify-content-between align-items-center mb-5'>
                    <h2 className='payments-header'>State Prices</h2>
                    <Input
                        onChange={(e) => { setFilter(e.target.value) }}
                        placeholder="Filter by State"
                        style={{ width: 302,
                            marginRight: 10
                        }}
                    //  prefix={< className="site-form-item-icon" />}

                    />
                    {type === "VENDOR_USER" && 
                    <span>
                        <button onClick={showAddStatePriceModal} style={{
                            backgroundColor: "#00425F",
                            color: "#fff",
                            display: "inline-block",
                            borderRadius: 4,
                            padding: "5px 30px",
                            marginTop: 10,
                            border: 'none',
                        }}>
                            Add new(1)
                        </button>
                        <button onClick={uploadBulkModal} style={{
                            backgroundColor: "#00425F",
                            color: "#fff",
                            display: "inline-block",
                            borderRadius: 4,
                            padding: "5px 30px",
                            marginTop: 10,
                            marginLeft: 10,
                            border: 'none',
                        }}>
                            Add multiple
                        </button>
                    </span>}
                    {type !== "VENDOR_USER" &&
                        <button onClick={backFunction} style={{
                            backgroundColor: "#00425F",
                            color: "#fff",
                            display: "inline-block",
                            borderRadius: 4,
                            padding: "5px 30px",
                            marginTop: 10,
                            marginLeft: 10,
                            border: 'none',
                        }}>
                            Close
                        </button>
                    }
                </div>
                <div className="table-responsive payment-table-container">
                    <table className="table table-borderless">
                        <thead className='chart-table-head'>
                            <tr>
                                {/* <th scope="col">Product</th> */}
                                <th scope="col">State</th>
                                <th scope="col">Rate</th>
                                <th scope="col">Former Rate</th>
                                <th scope="col">Date of Update</th>
                                {type === "VENDOR_USER" &&  <th scope="col"></th>}
                            </tr>
                        </thead>
                        <tbody>
                          {props.fetching? <Skeleton avatar={false} paragraph={{ rows: 3 }} />:(props && props?.statePrices?
                            filteredData?.map((item, index)=>{
                                return (<tr key={`in-${index}`}>
                                        <td>{item.state.name}</td>
                                        <td>N{formatAmount(item.rate * 100)}</td>
                                        <td>{item.oldRate? `N${formatAmount(item.oldRate * 100)}`: ''}</td>
                                        <td>{formatDate3(item?.updatedAt)}</td>
                                        {type === "VENDOR_USER" &&<td><Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                                <MenuIcon />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={(e)=>showAddStatePriceModal(e, item)}><>
                                                   Edit
                                                </>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown></td>}
                                </tr>)
                            }) :
                            <tr >
                                <td style={{ textAlign: 'center'}} colSpan={3}><h1>No data to display</h1></td>
                            </tr>
                          )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* single upload modal */}
            <Modal style={{
            }} scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal" show={showAddModal}>
                <Modal.Header closeButton style={{
                }} onHide={() => showAddStatePriceModal()}>
                    <Modal.Title>
                    {!isEdit? "Add new state price" : "Edit new state price"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                }}>
                    <div className='invoice-modal-detail'>
                   {props?.statePrice?.status?.httpCode === 200 && <div className='d-flex flex-column justify-content-center align-items-center p-3'>
                        <GreenCheckIcon />
                        <span className='payment-modal-h mb-3'>{isEdit? "Rate updated Successfuly" : "Rate Added Successfuly"}</span>
                        <button onClick={showAddStatePriceModal} className='payment-button invoice-detail-button mb-3'>
                        Done
                    </button>
                    </div>}
                        {!props?.statePrice && <form onSubmit={handleSubmitForm}>
                            <Form.Label className='invoice-label mb-1'>Select State</Form.Label>
                             <select style={{ padding:10}} required className='mb-3' value={state} onChange={handleSelectedState} placeholder="Select state">
                             <option>select state...</option>
                              {props?.states?.data?.map((item, index)=>{
                                return(<option key={index} value={item.name}>{item.name}</option>)
                              })}
                             </select>
                            <Form.Label className='invoice-label mb-1'>Rate</Form.Label>
                            <input required className='mb-3' style={{width: '100%',  height: 40, padding: 10}} onBlur={formatAsMoney} onFocus={convertToNumber} onChange={handleAmountChange}  value={rate}/>

                        {errMsg && <Alert className='mb-1' closable message={errMsg} onClose={()=>setErrorMessage('')} type='error' />}

                        <div className='button-border'><button type='button' disabled={props.creating} onClick={showAddStatePriceModal} className='invoice-cancel-button'>
                            Cancel
                        </button>
                            <button disabled={props.creating} type='submit' className='invoice-form-button'>{props.creating ? (isEdit? "updating...":'adding...') : 'Submit'}</button>
                        </div>
                    </form>}

                    </div>
                </Modal.Body>
            </Modal>
            {/* Bulk upload Modal */}
            <Modal style={{
            }}  scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal" show={showBulkModal}>
                <Modal.Header closeButton style={{
                }} onHide={uploadBulkModal}>
                    <Modal.Title>
                      Add multiple state price
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                }}>
                    <div className='invoice-modal-detail'>
                   {props?.stateUpload?.status?.httpCode === 200 && <div className='d-flex flex-column justify-content-center align-items-center p-3'>
                        <GreenCheckIcon />
                        <span className='payment-modal-h mb-3'>Rate Added Successfuly</span>
                        <button onClick={uploadBulkModal} className='payment-button invoice-detail-button mb-3'>
                        Done
                    </button>
                    </div>}
                        {!props?.stateUpload && <form onSubmit={handleUploadFile}>
                            {/* <Form.Label className='invoice-label mb-1'>Select State</Form.Label> */}
                            <a style={{ display: 'inline-block'}} className='mb-3' href={StatePriceFile} download="State-Price-Bulk-file" target='_blank' rel="noopener noreferrer">Click here to download sample upload file</a>
                             
                            <Form.Label className='invoice-label mb-1'>Upload file</Form.Label>
                            <input required type="file" className='mb-3' style={{width: '100%',  height: 40, padding: 10}} onChange={handleFileChange} />

                        {props.error && <Alert className='mb-1' closable message={props.error|| fileError} onClose={()=>setFileError('')} type='error' />}

                        <div className='button-border'><button type='button' disabled={props.uploading} onClick={uploadBulkModal} className='invoice-cancel-button'>
                            Cancel
                        </button>
                            <button disabled={props.uploading} type='submit' className='invoice-form-button'>{props.uploading ? 'uploading...' : 'Upload'}</button>
                        </div>
                    </form>}

                    </div>
                </Modal.Body>
            </Modal>
        </>);
}