import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import {
  approveRequest,
  fetchPendingRequests,
  rejectRequest,
} from "../../../actions/maker-checker-request-actions";
import { getUserProperties } from "../../../lib/Constants/UserTypes";
import toSentenceCase from "../../../lib/Functions/toSentenceCase";
import ToastAlert from "../../../lib/Components/ToastAlert";

// reurable aprroval component
export const PendingRequest = ({ approvalType = "VendorFee" }) => {
  const { userLevel, id: currentUserId } = getUserProperties();
  const isAdmin = ["SUPER_ADMIN", "ADMIN"].includes(userLevel);
  const [decisionSubmitResponse, setDecisionSubmitResponse] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const dispatch = useDispatch();
  const props = useSelector((state) => state);
  const { makerCheckerRequests = {} } = props;
  useEffect(() => {
    setDecisionSubmitResponse({});
    dispatch(fetchPendingRequests());
  }, []);
  let pendingRequestsToBeTreated = useMemo(() => {
    switch (approvalType) {
      case "VendorFee":
        return (
          (makerCheckerRequests.pendingRequests &&
            makerCheckerRequests.pendingRequests.filter(
              (pr) =>
                pr.requestedby !== currentUserId &&
                ["UPDATE_FEE_CONFIGURATION"].includes(pr.requestType)
            )) ||
          []
        );
      case "Contract":
        return (
          (makerCheckerRequests.pendingRequests &&
            makerCheckerRequests.pendingRequests.filter(
              (pr) =>
                pr.requestedby !== currentUserId &&
                [
                  "ADD_CONTRACT",
                  "UPDATE_CONTRACT",
                  "ADD_CONTRACT_PRODUCT_RATE",
                ].includes(pr.requestType)
            )) ||
          []
        );
      case "SmsFee":
        return (
          (makerCheckerRequests.pendingRequests &&
            makerCheckerRequests.pendingRequests.filter(
              (pr) =>
                pr.requestedby !== currentUserId &&
                ["SET_SMS_FEE"].includes(pr.requestType)
            )) ||
          []
        );
      default:
        break;
    }
  }, [makerCheckerRequests]);

  useEffect(() => {
    setDecisionSubmitResponse(makerCheckerRequests.decisionSubmitResponse);
    if (makerCheckerRequests.decisionSubmitResponse.message) {
      setSelectedIndex("");
      dispatch(fetchPendingRequests());
    }
  }, [makerCheckerRequests.decisionSubmitResponse]);

  useEffect(() => {
    if (!makerCheckerRequests.isSubmittingDecision) {
      if (pendingRequestsToBeTreated[selectedIndex]) {
        pendingRequestsToBeTreated[selectedIndex].isApprove = false;
        pendingRequestsToBeTreated[selectedIndex].isReject = false;
      }
    }
  }, [makerCheckerRequests.isSubmittingDecision]);

  const handleApproval = (id, key) => {
    dispatch(approveRequest(id));
    setSelectedIndex(key);
    pendingRequestsToBeTreated[key].isApprove = true;
  };

  const handleRejection = (id, key) => {
    dispatch(rejectRequest(id));
    setSelectedIndex(key);
    pendingRequestsToBeTreated[key].isReject = true;
  };

  return (
    <Fragment>
      {!makerCheckerRequests.isSubmittingDecision && (
        <div className="row">
          <div className="col-12">
            <ToastAlert
              showToast={!!decisionSubmitResponse.message}
              toastText={decisionSubmitResponse.message || ""}
              parentClass={`mt-2 d-block clear-both bg-${
                decisionSubmitResponse.isError ? "warning" : "success"
              }`}
              childClass="text-white"
              onCloseHandler={() =>
                dispatch({ type: "REQUEST_DECISION_SUBMIT_RESET" })
              }
            />
          </div>
        </div>
      )}

      {isAdmin ? (
        pendingRequestsToBeTreated.length >= 1 && (
          <table className="table table-hover table-sm mb-3">
            <thead>
              <tr>
                <td className="p-1 bg-secondary text-warning" colSpan="3">
                  Pending Requests
                </td>
              </tr>
            </thead>
            <tbody>
              {pendingRequestsToBeTreated.map((r, key) => {
                const requestDataObj = JSON.parse(r.requestData);
                return (
                  <tr key={key}>
                    <td className="p-1">
                      <span className="font-weight-bold">
                        {r.requestType.replaceAll("_", "-")}
                      </span>{" "}
                      &mdash;&nbsp;
                      <span className="font-weight-bold">
                        {requestDataObj.name}
                      </span>
                      &mdash;
                      {r.requestInfo}
                    </td>
                    <td className="pb-0 pt-1 text-right">
                      <Popover
                        content={
                          <div>
                            {Object.keys(requestDataObj).map((k, i) => {
                              if (
                                [
                                  "id",
                                  "number",
                                  "image",
                                  "appid",
                                  "appmodules",
                                  "createdat",
                                  "updatedat",
                                  "created_at",
                                  "updated_at",
                                ].includes(k.toLowerCase()) ||
                                !requestDataObj[k] ||
                                Array.isArray(requestDataObj[k])
                              )
                                return null;

                              if (typeof requestDataObj[k] === "object") {
                                const requestDataSubObj = requestDataObj[k];
                                return (
                                  <ul key={i}>
                                    {Object.keys(requestDataSubObj).map(
                                      (k2, i2) => {
                                        if (
                                          [
                                            "id",
                                            "number",
                                            "image",
                                            "appid",
                                            "appmodules",
                                            "createdat",
                                            "updatedat",
                                            "created_at",
                                            "updated_at",
                                          ].includes(k2.toLowerCase()) ||
                                          !requestDataSubObj[k2] ||
                                          Array.isArray(requestDataSubObj[k2])
                                        )
                                          return null;

                                        return (
                                          <li key={i2}>
                                            <span className="font-weight-bold mr-2">
                                              {toSentenceCase(k2)}:
                                            </span>
                                            {JSON.stringify(
                                              requestDataSubObj[k2],
                                              null,
                                              2
                                            )
                                              .replaceAll('"', "")
                                              .replaceAll("}", "")
                                              .replaceAll("{", "")
                                              .replaceAll(":", "-")}
                                            {/* {JSON.stringify(requestDataSubObj[k2], null, 2).replaceAll(/(\"\}/g, '').replaceAll('{', '')} */}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                );
                              }

                              return (
                                <p key={i} className="pt-0 pb-0">
                                  <span className="font-weight-bold mr-2">
                                    {toSentenceCase(k)}:
                                  </span>
                                  {JSON.stringify(requestDataObj[k], null, 2)}
                                </p>
                              );
                            })}
                          </div>
                        }
                        title="Details"
                        trigger="click"
                      >
                        <i
                          className="material-icons text-primary"
                          title="Details"
                          style={{ cursor: "pointer" }}
                        >
                          info
                        </i>
                      </Popover>
                    </td>
                    <td className="pb-0 pt-1 pr-1 pl-1 text-right">
                      <div className="btn-group btn-group-sm" role="group">
                        <button
                          type="button"
                          className="btn pb-0"
                          style={{ background: "#00425f", color: "white" }}
                          disabled={makerCheckerRequests.isSubmittingDecision}
                          onClick={() => handleApproval(r.id, key)}
                        >
                          {makerCheckerRequests.isSubmittingDecision &&
                          r.isApprove ? (
                            "Loading..."
                          ) : (
                            <i
                              className="material-icons text-white"
                              style={{ fontSize: "18px" }}
                            >
                              done
                            </i>
                          )}
                        </button>
                        <button
                          type="button"
                          title="Decline"
                          className="btn btn-danger pb-0"
                          disabled={makerCheckerRequests.isSubmittingDecision}
                          onClick={() => handleRejection(r.id, key)}
                        >
                          {makerCheckerRequests.isSubmittingDecision &&
                          r.isReject ? (
                            "Loading..."
                          ) : (
                            <i
                              className="material-icons text-white"
                              style={{ fontSize: "18px" }}
                            >
                              close
                            </i>
                          )}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )
      ) : (
        <></>
      )}
    </Fragment>
  );
};
export default PendingRequest;
