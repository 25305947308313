const oTrailReducer = (
  state = {
    cTransactions: {},
    cDashoardTransactions: {},
    cDashoardTransactionsBlob: null,
    oTrail: [],
    vTrail: [],
    vtrails: [],
    cTrail: [],
    oTransactions: [],
    oTransactionsBlob: null,
    oHighestTransactingVendors: [],
    oHighestTransactingCustomers: [],
    transactionFrequencies: {},
    oSettlements: [],
    fetching: false,
    fetched: false,
    error: null,
    ctrails: [],
    cDriver: [],
    cSmsFee: [],
    sContractreport: [],
    cContract: [],
    smsNotification: [],
    toggleResponse: null,
    toggleStatus: false,
  },
  action
) => {
  switch (action.type) {
    case "FETCH_TRAIL_START": {
      return { ...state, fetching: true };
    }
    case "FETCH_All_TRAIL_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_All_TRAIL_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        oTrail: action.payload,
      };
    }
    case "GET_ALL_CUSTOMERS_TRANSACTION": {
      return { ...state, cTransactions: action.response.data };
    }
    case "GET_ALL_CUSTOMERS_TRANSACTION_CDASHBOARD": {
      return { ...state, cDashoardTransactions: action.response.data };
    }
    case "GET_ALL_CUSTOMERS_DRIVER_REPORT_CDASHBOARD": {
      return { ...state, cDriver: action.response.data };
    }
    case "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_START": {
      return { ...state, downloading: true, cDashoardTransactionsBlob: null };
    }
    case "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_FULFILLED": {
      return {
        ...state,
        downloading: false,
        cDashoardTransactionsBlob: action.response.data,
      };
    }
    case "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_REJECTED": {
      return {
        ...state,
        downloading: false,
        cDashoardTransactionsBlob: action.response.data,
      };
    }
    case "DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_RESET": {
      return { ...state, downloading: false, cDashoardTransactionsBlob: null };
    }

    case "FETCH_All_TRANSACTION_START": {
      return { ...state, fetching: true };
    }
    case "FETCH_All_TRANSACTION_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_All_TRANSACTION_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        oTransactions: action.payload.data,
      };
    }

    case "DOWNLOAD_ALL_TRANSACTION_START": {
      return {
        ...state,
        downloading: true,
        downloaded: false,
        oTransactionsBlob: null,
      };
    }
    case "DOWNLOAD_ALL_TRANSACTION_REJECTED": {
      return {
        ...state,
        downloading: false,
        downloaded: false,
        oTransactionsBlob: null,
        error: action.payload,
      };
    }
    case "DOWNLOAD_ALL_TRANSACTION_FULLFILLED": {
      return {
        ...state,
        downloading: false,
        downloaded: true,
        oTransactionsBlob: action.payload.data,
      };
    }

    case "DOWNLOAD_SETTLEMENT_START": {
      return {
        ...state,
        downloadingSettlement: true,
        downloaded: false,
        oTransactionsBlob: null,
      };
    }
    case "DOWNLOAD_SETTLEMENT_REJECTED": {
      return {
        ...state,
        downloadingSettlement: false,
        downloaded: false,
        oTransactionsBlob: null,
        error: action.payload,
      };
    }
    case "DOWNLOAD_SETTLEMENT_FULLFILLED": {
      return {
        ...state,
        downloadingSettlement: false,
        downloaded: true,
        oTransactionsBlob: action.payload.data,
      };
    }

    case "DOWNLOAD_ALL_TRANSACTION_RESET": {
      return { ...state, downloaded: false, oTransactionsBlob: null };
    }

    case "FETCH_SETTLEMENTS_START": {
      return { ...state, fetchingSettlement: true };
    }
    case "FETCH_SETTLEMENTS_REJECTED": {
      return { ...state, fetchingSettlement: false, error: action.payload };
    }
    case "FETCH_SETTLEMENTS_FULFILLED": {
      return {
        ...state,
        fetchingSettlement: false,
        fetched: true,
        oSettlements: action.payload,
      };
    }

    case "TOGGLE_SMSFEE_START": {
      return { ...state };
    }
    case "TOGGLE_SMSFEE_REJECTED": {
      return { ...state, error: action.payload };
    }
    case "TOGGLE_SMSFEE_RESET": {
      return {
        ...state,
        error: action.payload,
        toggleResponse: action.response?.data,
      };
    }
    case "TOGGLE_SMSFEE_FULFILLED": {
      return {
        ...state,
        toggleResponse: action.response?.data,
      };
    }

    case "FETCH_TOGGLE_SMSFEE_STATUS_START": {
      return { ...state, fetchingStatus: true };
    }
    case "FETCH_TOGGLE_SMSFEE_STATUS_REJECTED": {
      return { ...state, error: action.payload, fetchingStatus: false };
    }

    case "FETCH_TOGGLE_SMSFEE_STATUS_FULFILLED": {
      return {
        ...state,
        fetchingStatus: false,
        toggleStatus: action.response?.data?.data?.subscribedForSms,
      };
    }

    case "FETCH_HIGHEST_TRANSACTING_USER_START":
      return {
        ...state,
      };

    case "FETCH_THREE_MONTHS_TX_STATS_START":
      return {
        ...state,
      };

    case "FETCH_TRANSACTION_AMOUNT_BY_FREQ_START":
      return { ...state, isFetchingFrequencies: true };

    case "FETCH_TRANSACTION_AMOUNT_BY_FREQ_FULFILLED":
      return {
        ...state,
        isFetchingFrequencies: false,
        transactionFrequencies: action.payload,
      };

    case "FETCH_TRANSACTION_AMOUNT_BY_FREQ_REJECTED":
      return { ...state, isFetchingFrequencies: false };

    case "FETCH_AllCUSTOMER_TRAIL_START": {
      return { ...state, fetching: true };
    }
    case "FETCH_AllCUSTOMER_TRAIL_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_AllCUSTOMER_TRAIL_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ctrails: action.payload,
      };
    }

    case "FETCH_AllCUSTOMER_SMSFEE_START": {
      return { ...state, fetching: true };
    }
    case "FETCH_AllCUSTOMER_SMSFEE_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_AllCUSTOMER_SMSFEE_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        cSmsFee: action.response.data,
      };
    }

    case "FETCH_AllCUSTOMER_SETTLEMENTCONTRACT_START": {
      return { ...state, fetching: true };
    }
    case "FETCH_AllCUSTOMER_SETTLEMENTCONTRACT_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_AllCUSTOMER_SETTLEMENTCONTRACT_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        sContractreport: action.response.data,
      };
    }

    case "FETCH_AllCUSTOMER_CONTRACT_START": {
      return { ...state, fetching: true };
    }
    case "FETCH_AllCUSTOMER_CONTRACT_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_AllCUSTOMER_CONTRACT_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        cContract: action.response.data,
      };
    }

    case "FETCH_SMS_NOTIFICATION_START": {
      return { ...state, fetching: true };
    }
    case "FETCH_SMS_NOTIFICATION_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_SMS_NOTIFICATION_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        smsNotification: action.response.data,
      };
    }

    case "FETCH_ALLVENDOR_TRAIL_START": {
      return { ...state, fetching: true };
    }
    case "FETCH_AllVENDOR_TRAIL_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_AllVENDOR_TRAIL_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        vtrails: action.payload,
      };
    }

    default:
      return state;
  }
};

export default oTrailReducer;
