const oTrailReducer = (
  state = {
    fetching: false,
    fetched: false,
    error: null,
    ctrails: [],
  },
  action
) => {
  switch (action.type) {

    case "FETCH_AllCUSTOMER_TRAIL_START": {
      return { ...state, fetching: true };
    }
    case "FETCH_AllCUSTOMER_TRAIL_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_AllCUSTOMER_TRAIL_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ctrails: action.payload,
      };
    }

    default:
      return state;
  }
};

export default oTrailReducer;
