import React, { useEffect, useState } from 'react';
import { Modal, Dropdown, ModalHeader } from 'react-bootstrap';
import { CloseSVG } from '../../../assets/Svgs/Svgs';
import { ReactComponent as CopyIcon } from '../../../assets/Svgs/CopyIcon.svg';
import { ReactComponent as WhiteSpinner} from '../../../assets/Svgs/WhiteSpinnerGap.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RequeryTransaction } from '../../customerModule/redux/action/paymentsAction';
import { formatDate3 } from '../../../lib/Functions/formatDate';
import PaymentStatus from './paymentUtil';

const RequeryComponent = ({toggleModal, showModal, transactionDetail}) => {
    const { account } = useSelector(s => s.oPaymentsReducer);
    const { loading, success, data, error } = account;
    const [ status, setStatus ] = useState(false);
   const dispatch = useDispatch();
   const requeryDetails = () =>{
        dispatch(RequeryTransaction(transactionDetail.paymentReference))
   }
   
   useEffect(()=>{
    setStatus(success)
   },[success])

   function copy(text){
    navigator.clipboard.writeText(text)
  }
    return (
        <Modal scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal" show={showModal}>
            {/* <ModalHeader toggle={()=>{}}></ModalHeader> */}
            <Modal.Body style={{
                padding: 0
            }}>
                <div className='payment-modal-container'>
                    <div className='payment-modal-header'> <span onClick={toggleModal}><CloseSVG className="modal-close-icon" /></span></div>
                    <div>
                    <h5 className='payment-modal-h mb-3'>Details</h5>
                            <div className='acct-section mb-4'>
                                <div className='account-row mb-2'>
                                    <span className='payments-sub'>Payment Ref</span>
                                    <span className='payments-sub dark'>{transactionDetail.paymentReference ? transactionDetail.paymentReference : ''}</span>
                                </div>
                                <div className='account-row mb-2'>
                                    <span className='payments-sub'>Bank Name</span>
                                    <span className='payments-sub dark'>{transactionDetail.bankName}</span>
                                </div>
                                <div className='account-row mb-2'>
                                    <span className='payments-sub'>Account Number</span>
                                    <div style={{ position: 'relative' }}><span className='payments-sub blue mr-4'>{transactionDetail.accountNumber}</span> <CopyIcon onClick={()=>copy('123456')} className='copy-icon' /></div>
                                </div>
                                <div className='account-row mb-2'>
                                    <span className='payments-sub'>Date</span>
                                    <span className='payments-sub dark'>{transactionDetail.transactionDate ? formatDate3(transactionDetail.transactionDate) : 'N/A'}</span>
                                </div>
                                {success &&<div className='account-row mb-2'>
                                    <span className='payments-sub'>Queried transaction Status</span>
                                    <span className='payments-sub dark'><PaymentStatus status={data?.data?.status|| ''} /> </span>
                                </div>}
                            </div>
                           
                            <p className='mb-4 text-center acct-terms'>
                                *The details above are static and specific to this vendor. Use them for all future payments*
                            </p>
                            <span style={{ display: 'block', width: '100%', marginTop: 20, marginBottom:20, fontWeight: 400 }}>
                                <button disabled={loading} onClick={requeryDetails} className='payment-button'>
                                    {!loading? 'Confirm Transaction Status' : <>Confirming Transaction Status <WhiteSpinner/></>}
                                </button>
                            </span>
                        </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default RequeryComponent