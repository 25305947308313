const oProductReducer = (
  state = {
    allProducts: [],
    oneProduct: {},
    fetching: false,
    fetched: false,
    error: null,
    userEditModal: false,
    userDeleteModal: false,
  },
  action,
) => {
  switch (action.type) {
    case 'FETCH_All_PRODUCTS_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, allProducts: action.payload };
    }
    case 'FETCH_ONE_PRODUCT_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, oneProduct: action.payload };
    }
    case 'ADD_PRODUCT_USER': {
      return {
        ...state,
        userCreateModal: false,
        allProducts: [...state.allProducts, action.payload],
      };
    }

    case 'UPDATE_USERS': {
      const { id } = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex(user => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers,
      };
    }
    case 'DELETE_USERS': {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
  }
  return state;
};

export default oProductReducer;
