import React, {useState, useEffect} from "react";
import {Modal, ModalBody} from "reactstrap";
import {
  BackSVG,
  ActiveMarkSVG,
  CancelSVG,
  AvatarSVG,
  DisabledMark,
  LoadingSVG,
  UserCompleteMarkSVG,
} from "../../../../assets/Svgs/Svgs";
import CustomerSettings from "./CustomerSettings";
import EditForm from "./EditCustomer";
import {formatDate3} from "../../../../lib/Functions/formatDate";
import formatAmount from "../../../../lib/Functions/formatAmount";
import {
  resetCustomerUpdate,
  // toggleCustomerStatus,
  toggleVendorCustomerStatus,
} from "../../../../actions/OwnerActions/OwnerCustomerMgtActions/OwnerCustomerActions";
import {getSchemesByCus} from "../../../../actions/CustomerActions/CardMgtActions/CardAction";
import {useDispatch} from "react-redux";
import ContractManagement from "./ContractManagement";
import {getUserProperties} from "../../../../lib/Constants/UserTypes";

const CustomerProfile = (props) => {
  const dispatch = useDispatch();
  const [showSettings, setShowSettings] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const {selectedCustomer} = props;
  const {
    name,
    contact,
    rcNumber,
    address,
    createdAt,
    sector,
    status,
    image,
  } = selectedCustomer;
  const [saveEditForm, setSaveEditForm] = useState(false);
  const [schemeStats, setSchemeStats] = useState({
    availableBalance: 0,
    used: 0,
    totalCredit: 0,
  });
  const [profilePix, setProfilePix] = useState(image || "");// eslint-disable-line no-unused-vars
  const [customerCreationError, setCustomerCreationError] = useState(false);
  const toggleSettings = () => setShowSettings(!showSettings);

  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  const [showCreateSuccessModal, setShowCreateSuccessModal] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const customer = selectedCustomer;

  const toggleModal = () => {
    setIsCreatingCustomer(false);
    setShowCreateSuccessModal(false);
  };

  useEffect(() => {
    setIsCreatingCustomer(props.oCustomerReducer.isCreatingCustomer);
  }, [props.oCustomerReducer.isCreatingCustomer]);

  useEffect(() => {
    if (props.oCustomerReducer.fetchingStatus === 1) {
      setShowCreateSuccessModal(true);
    } else if (props.oCustomerReducer.fetchingStatus === 2) {
      setCustomerCreationError(true);
    }
  }, [props.oCustomerReducer.fetchingStatus]);

  let availableBalance = 0;
  useEffect(() => {
    const contracts = selectedCustomer.contracts.filter((item) =>
      item.vendorSignature.includes(getUserProperties().userEmail)
    );
    contracts.forEach((item) => (availableBalance += item.bound), 0);
    setSchemeStats({...schemeStats, availableBalance});
    setIsCreatingCustomer(false);
    setShowCreateSuccessModal(false);
    dispatch(resetCustomerUpdate());
    dispatch(getSchemesByCus(customer.number));
  }, []);

  return (
    <>
      <Modal
        isOpen={isCreatingCustomer || showCreateSuccessModal}
        toggle={toggleModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="user-modal"
      >
        <ModalBody>
          {isCreatingCustomer && (
            <>
              <div className="text-center mt-5 header">
                Editing customer....
              </div>
              <div className="text-center mt-5 mb-5">
                <LoadingSVG />
              </div>
            </>
          )}
          {showCreateSuccessModal && (
            <>
              <div className="text-center mt-5 mb-4">
                <span
                  className="d-block font-weight-bold"
                  style={{
                    fontSize: "20px",
                    color: "#4B4B4B",
                    margin: "1rem",
                  }}
                >
                  Thank you! You are all set!
                </span>
                <UserCompleteMarkSVG />
                <span
                  className="d-block quickteller-blue font-weight-bold"
                  style={{
                    fontSize: "1rem",
                    color: "#00425F",
                    margin: "1.25rem",
                  }}
                >
                  Your customer profile has been successfully edited
                </span>
              </div>
              <div className="row modal-action-buttons">
                <div className="col-12 text-center">
                  <button
                    className="back"
                    onClick={() => {
                      props.onEditingDone();
                    }}
                    style={{width: 120}}
                  >
                    Back
                  </button>
                </div>
              </div>
              <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
            </>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showSettings}
        backdrop="static"
        toggle={toggleSettings}
        className="settings-modal"
      >
        <div className="settings-modal-header">
          <p className="title">
            Customer Settings
            <i onClick={toggleSettings}>
              <CancelSVG />
            </i>
          </p>
          <p className="subtitle">
            You can monitor customer activities enable/disable features
          </p>
        </div>
        <ModalBody>
          <CustomerSettings />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showTransactionDetails}
        // show={showContractDetails}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
        dialogClassName="rfid-modal"
      >
        <ModalBody>
          <ContractManagement
            contracts={selectedCustomer.contracts}
            onCloseHandler={() => setShowTransactionDetails(false)}
          />
        </ModalBody>
      </Modal>

      <div className="underlined-tab">
        <span className="tab-title">Customer Profile</span>
        <div className="action-buttons">
          <button className="export-btn" onClick={props.backFunction}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className="row customer-details">
        <div className="col-3 profile-picture-and-actions">
          <div className="avatar">
            {!!profilePix ? (
              <img
                alt="Profile Face"
                src={profilePix.base64 || profilePix}
                style={{width: 100, height: 100, borderRadius: 50}}
              />
            ) : (
              <AvatarSVG />
            )}
          </div>
          {/* {showEditForm && (
            <>
              <div className="upload">
                <i>
                  <UploadSVG />
                </i>{" "}
                <FileBase64
                  multiple={false}
                  onDone={file => {
                    if (file.type === "image/jpeg" || file.type === "image/png")
                      setProfilePix(file);
                  }}
                />
              </div>
              <button
                className="button remove-btn"
                onClick={() => setProfilePix("")}
              >
                Remove
              </button>
            </>
          )} */}
          {!showEditForm && (
            <button
              className="button edit-profile-btn"
              onClick={() => setShowEditForm(true)}
            >
              Edit Profile
            </button>
          )}
          {!showEditForm && (
            <button
              className={`button ${
                customer.status === "ACTIVE" ? "suspend-btn" : "activate-btn"
              }`}
              onClick={() => {
                dispatch(
                  toggleVendorCustomerStatus(
                    customer.id,
                    customer.status === "ACTIVE" ? "DISABLED" : "ACTIVE"
                  )
                );
              }}
            >
              {props.vendorCustomer.isUpdatingCustomer ? (
                <img
                  src="/images/ActivityIndicator.gif" alt="activity-indicator"
                  style={{height: 20, width: 20, marginTop: 0}}
                />
              ) : (
                `${
                  customer.status !== "ACTIVE" ? "Activate" : "Disable"
                } Account`
              )}
            </button>
          )}
          {showEditForm && (
            <button
              className="button save-btn"
              onClick={() => setSaveEditForm(true)}
            >
              Save changes
            </button>
          )}
        </div>

        {!showEditForm && (
          <div className="col-9">
            <>
              <div className="row user-info">
                <div className="row user-info-title mt-4">
                  <div className="col-3">Business Name</div>
                  <div className="col-3">Email Address</div>
                  <div className="col-3">RC Number</div>
                  <div className="col-3">Date Created</div>
                </div>
                <div className="row user-info-details">
                  <div className="col-3">{name || ""}</div>
                  <div className="col-3">{contact.email || ""}</div>
                  <div className="col-3">{rcNumber || ""}</div>
                  <div className="col-3">{formatDate3(createdAt)}</div>
                </div>
              </div>
              <div className="row user-extras mt-3">
                <div className="row user-extras-title">
                  <div className="col-3">Phone</div>
                  <div className="col-3">Status</div>
                  <div className="col-3">Sector</div>
                </div>
                <div className="row user-extras-detail">
                  <div className="col-3">{contact.phoneNumber || ""}</div>
                  <div className="col-3">
                    {status === "ACTIVE" ? "Active " : `Disabled `}
                    {status === "ACTIVE" ? <ActiveMarkSVG /> : <DisabledMark />}
                  </div>
                  <div className="col-3">{sector}</div>
                </div>
              </div>
              <div className="row user-address mt-3">
                <div className="row user-address-title">
                  <div className="col-3">Street</div>
                  <div className="col-3">City</div>
                  <div className="col-3">State</div>
                  <div className="col-3">Country</div>
                </div>
                <div className="row user-address-details">
                  <div className="col-3">{address.street}</div>
                  <div className="col-3">{address.city}</div>
                  <div className="col-3">{address.state}</div>
                  <div className="col-3">{address.country}</div>
                </div>
              </div>
              <div className="row user-overview mt-3">
                <span className="overview-title">Overview</span>
                <div className="scheme-stats" style={{width: "100%"}}>
                  <div className="row title">
                    <div className="col-4">Available Balance</div>
                    <div className="col-4">Used</div>
                    <div className="col-4">Total Credit</div>
                  </div>
                  <div className="row numbers">
                    <div className="col-4">
                      ₦ {formatAmount(schemeStats.availableBalance * 100)}
                      <div className="scheme-stats-border"></div>
                    </div>
                    <div className="col-4">
                      ₦ {formatAmount(schemeStats.used * 100)}
                      <div className="scheme-stats-border"></div>
                    </div>
                    <div className="col-4">
                      ₦ {formatAmount(schemeStats.availableBalance * 100)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
        {showEditForm && (
          <EditForm
            {...props}
            create={false}
            saveEditForm={saveEditForm}
            onSaveEditFormHandler={(status) => setSaveEditForm(status)}
            customerToBeEdited={customer}
            resetForm={false}
            profilePix={{base64: profilePix.base64 || profilePix}}
            customerCreationError={customerCreationError}
            customerCreationErrorHandler={(status) =>
              setCustomerCreationError(status)
            }
          />
        )}
      </div>
    </>
  );
};

export default CustomerProfile;
