import React, { useState, useEffect } from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import { DatePicker, Radio, Button } from "antd";
import { FilterSVG, ExportSVG} from "../../../assets/Svgs/Svgs";
import { CSVLink } from "react-csv";
import { shortMonthNames, shortDayNames } from "../../../lib/Functions/DateNames";
import /* Skeleton, */ {SkeletonTheme} from "react-loading-skeleton";
import Pagination from "../../../lib/Components/Pagination";

const AuditTrailList = (props) => {
  const [action, setAction] = useState('');
  const {oTrailReducer, dateFilterRange, onCalendarChange, defaultDateFilterRange, resetCalendar} = props;
  const {data, count/* , status */} = oTrailReducer.oTrail;
  const pageNo = 1;
  const pageSize = 10;

  useEffect(() => {
    props.onFetchTrails(pageNo, pageSize, action);
  }, [action, dateFilterRange])

  const TrailList = data && data.map((value) => {
    const {id, description, useremail, action, createdAt} = value;
    const d = new Date(createdAt);

    const hours = d.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    return (
      <tr key={id}>
        <td className="table-description font-weight-bold">{useremail}</td>
        <td className="table-description">{description}</td>
        <td className="table-description">{action}</td>
        <td className="table-description">
          {shortDayNames[d.getDay()-1]} {d.getDate()}, {shortMonthNames[d.getMonth()]} {d.getFullYear()}
          {' @ '}{(hours % 12) || 12}:{`0${d.getMinutes()}`.slice(-2)}{ampm}{/* // the hour '0' should be '12' */}
        </td>
      </tr>
    )
  });

  return (
    <>
    <div className="row">
      <div className="col-7 text-right pr-0 mt-1">
        <span className="filter-tags-desc">Filters:</span>
        <Radio.Group defaultValue="" buttonStyle="solid" onChange={(e) => setAction(e.target.value)}>
          <Radio.Button value="">ALL</Radio.Button>
          <Radio.Button value="CREATE">CREATE</Radio.Button>
          <Radio.Button value="UPDATE">UPDATE</Radio.Button>
          <Radio.Button value="DELETE">DELETE</Radio.Button>
          <Radio.Button value="APPROVE">APPROVE</Radio.Button>
          <Radio.Button value="LOGIN">LOGIN</Radio.Button>
          <Radio.Button value="DISABLE">DISABLE</Radio.Button>
        </Radio.Group>
      </div>
      <div className="col-md-5 text-right pr-0 mt-md-1 mt-2">
        <DatePicker.RangePicker
          defaultValue={defaultDateFilterRange}
          value={dateFilterRange}
          onCalendarChange={onCalendarChange}
          format={"DD-MMMM-YYYY"}
          allowClear={false}
        />
        <Button tooltip="Reset to default date range" style={{ marginTop: 8, marginLeft: 3}} onClick={resetCalendar} type='primary' size='small' > reset date </Button>
      </div>
    </div>

    <SkeletonTheme color="#fff" highlightColor="#f4f4f4">

    <div className="disbursed-cards-container row">
      <div className="menu-link" style={{marginBottom: 0, paddingBottom: 4}}>
        <span style={{color: "#000000", borderBottomColor: "#00425F", borderBottomWidth: 3, borderBottomStyle: "solid"}}>Audit Trail</span>
      </div>  
      {!!data && <CSVLink data={data} filename={'Audit Trail.csv'} className="" target="_blank">
        <div className="disbursed-cards-export-btn" style={{float: "right"}}>
          <span className="text-white">Export CSV <ExportSVG /></span>
        </div> 
      </CSVLink>}
      <table className="table table-hover">
          <thead>
            <tr>
              <td>Email Address</td>
              <td>Description</td>
              <td>Actions  <FilterSVG /></td>
              <td>Date / Time <FilterSVG /></td>
            </tr>
          </thead>
          <tbody>
            {TrailList || <tr><td colSpan={4} className="text-center">No Audit Trail to show</td></tr>}
          </tbody>
      </table>
      <Pagination count={count} pageSize={pageSize} fetchMethod={(pageNo, pageSize) => {
        props.onFetchTrails(pageNo, pageSize, action);
      }} />
    </div>
    </SkeletonTheme>
    </>
  )
};

export default AuditTrailList;