import React, { useState /* , useEffect */ } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import "../../../assets/Styles/Dashboard.css";
import moment from "moment";
import { getSmsFeeListADasboard } from "../redux/action/CustomerActions";
import {
  loadAllCustomerVendors,
  fetchAllLocations,
} from "../redux/action/OwnerVendorActions";
import {
  getUserProperties /* , getUser */,
} from "../../../lib/Constants/UserTypes";
import { useEffect } from "react";
import SmsFeeList from "./SmsFeeList";

function SmsFee() {
  const dispatch = useDispatch();
  const [vendorNum, setVendorNum] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState(5);

  const props = useSelector((state) => state);
  const { allCustomerVendors } = props.customerVendor;
  const { allLocations } = props.oVendorReducer;
  const { customer = 0, customerNumber = "" } = getUserProperties() || {};

  useEffect(() => {
    batch(() => {
      dispatch(loadAllCustomerVendors(customer));
      dispatch(fetchAllLocations());
    });
  }, []);

  return (
    <div className="dashboard-container">
      <SmsFeeList
        {...props}
        downloading={props.oTrailReducer.downloading}
        status={status}
        vendorNum={vendorNum}
        location={location}
        onFetchTransactions={(pageNo, pageSize) =>
          dispatch(getSmsFeeListADasboard(pageNo, pageSize))
        }
        customerNumber={customerNumber}
      />
    </div>
  );
}

export default SmsFee;
