import React from "react";
import Menu from "./modules/admin/shared/Menu";
import Notfound from "./modules/admin/shared/Notfound";
import { Provider } from "react-redux";
import store from "./store";
import {
  BrowserRouter as Router,
  Route,
  /* Redirect, */ Switch,
} from "react-router-dom";
import { PrivateRoute } from "../src/modules/admin/auth/PrivateRoute";
import LoginPage from "../src/modules/Auth/Login";
import {
  Owner,
  Vendor,
  Customer /* , Scheme_mgr */,
} from "./modules/admin/auth/Authorize";
import {
  ownerRoutes,
  customerRoutes,
  vendorRoutes /* ,schemeRoutes, commonRoutes */,
} from "./lib/Routes/routeArray";
import Timeout from "./helpers/timeOut";

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Timeout />
            <Menu />
            <Switch>
              {/* <div className="ml-md-5 pl-md-3"> */}
              {ownerRoutes.map((route, index) => (
                <PrivateRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={Owner(route.main)}
                />
              ))}
              {vendorRoutes.map((route, index) => (
                <PrivateRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={Vendor(route.main)}
                />
              ))}
              {customerRoutes.map((route, index) => (
                <PrivateRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={Customer(route.main)}
                />
                // <PrivateRoute key={index} exact={route.exact} path={route.path} component={route.main} />
              ))}
              {/* {schemeRoutes.map((route, index) => (
                <PrivateRoute key={index} exact={route.exact} path={route.path} component={Scheme_mgr(route.main)} />
                // <PrivateRoute key={index} exact={route.exact} path={route.path} component={route.main} />
              ))} */}

              <Route exact path="/login" component={LoginPage} />
              {/* <Route exact path="/forgot-password" component={LoginPage} /> */}
              <Route component={Notfound} />
              {/* </div> */}
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
