import React, {useState, useEffect} from "react";
import {FilterSVG, ExportSVG, CreateSVG} from "../../../../assets/Svgs/Svgs";
import {
  shortMonthNames,
  shortDayNames,
} from "../../../../lib/Functions/DateNames";
import AccountDetails from "./UserProfile";
import CreateUser from "./CreateUser";
import "../Styles/Users.css";
import {CSVLink} from "react-csv";
import Pagination from "../../../../lib/Components/Pagination";
import {permissions} from "../../../../lib/Constants/Permissions";
import {getUserProperties, USER} from "../../../../lib/Constants/UserTypes";
import { fetchAllTerminalsByVendor } from "../../../ownerModule/redux/action/OwnerVendorActions";
import { useDispatch } from "react-redux";

const UsersList = (props) => {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState({});
  const {customerUser} = props;
  const {users} = customerUser;
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [userToDisplay, setUserToDisplay] = useState([]);
  const pageSize = 10;
 
  useEffect(()=> {
    dispatch(
      fetchAllTerminalsByVendor(getUserProperties().vendor, 1, 1000)
    );
  }, [])

  useEffect(() => {
    displayUsers(1, (user) => {
      setSelectedUser(user);
      props.onHideStatusBar(true);
      setShowAccountDetails(true);
    });
  }, [users]);

  const displayUsers = (pageNo = 1, callback) => {
    const userList = users
      .slice((pageNo - 1) * pageSize, pageNo * pageSize)
      .map((value, index) => {
        const {name, createdAt, status, userEmail, userLevel} = value;
        const d = new Date(createdAt);
        return (
          <tr key={index}>
            <td className="font-weight-bold">{name} </td>
            <td className="font-weight-bold">{userEmail}</td>
            <td className="font-weight-bold">{userLevel}</td>
            <td className="table-date">
              {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
              {shortMonthNames[d.getMonth()]} {d.getFullYear()}
            </td>
            {status.toUpperCase() === "ACTIVE" ? (
              <td className="table-status">
                <div className="status-btn success">{"Active"}</div>
              </td>
            ) : (
              <td className="table-status">
                <div className="status-btn error">{"Suspended"}</div>
              </td>
            )}
            {getUserProperties().permissions.indexOf(permissions.VIEW_USER) >
              1 && (
              <td className="table-action" onClick={() => callback(value)}>
                View
              </td>
            )}
          </tr>
        );
      });
    setUserToDisplay(userList);
  };

  return (
    <>
      {!showAccountDetails && !showCreateUser && (
        <div className="charts-container row">
          <div className="table-menu-link">
            <span>All Users</span>

            <div className="action-buttons">
              {!!users && (
                <div className="export-btn d-inline-block">
                  <CSVLink
                    data={
                      users.map((item) => {
                        const {image, ...otherData} = item;
                        return otherData;
                      }) || []
                    }
                    filename={"List of Users.csv"}
                    className=" text-white"
                    target="_blank"
                  >
                    Export CSV{" "}
                    <i>
                      <ExportSVG />
                    </i>
                  </CSVLink>
                </div>
              )}
              {getUserProperties().userLevel !== USER && (
                <button
                  className="add-btn"
                  onClick={() => {
                    props.onHideStatusBar(true);
                    setShowCreateUser(true);
                  }}
                >
                  <i>
                    <CreateSVG />
                  </i>
                  Add new user
                </button>
              )}
            </div>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <td className="table-title">Name</td>
                <td className="table-title">Email</td>
                <td className="table-title">
                  Users Level
                  <i className="filter-bars">
                    <FilterSVG />
                  </i>
                </td>
                <td className="table-title">
                  Date Created
                  <i className="filter-bars">
                    <FilterSVG />
                  </i>
                </td>
                <td className="table-title">
                  Status
                  <i className="filter-bars">
                    <FilterSVG />
                  </i>
                </td>
                {getUserProperties().permissions.indexOf(
                  permissions.VIEW_USER
                ) > 1 && <td className="table-title"></td>}
              </tr>
            </thead>
            <tbody>
              {users.length > 0 ? userToDisplay :(
                  <tr>
                    <td colSpan={7} className="text-center">
                      {users.description || customerUser.fetchingUserStatus === 0 ? "Loading..." : "No Users available"}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <Pagination
            pageSize={pageSize}
            count={users.length}
            fetchMethod={(value, pageSize) => {
              displayUsers(value, (user) => {
                setSelectedUser(user);
                props.onHideStatusBar(true);
                setShowAccountDetails(true);
              });
            }}
          />
        </div>
      )}

      {showAccountDetails && (
        <AccountDetails
          user={selectedUser}
          {...props}
          backFunction={() => {
            setShowAccountDetails(false);
            props.onHideStatusBar(false);
          }}
          onEditingDone={() => {
            setShowAccountDetails(false);
            props.onHideStatusBar(false);
            window.location.reload();
          }}
        />
      )}

      {showCreateUser && (
        <CreateUser
          {...props}
          backFunction={() => {
            setShowCreateUser(false);
            props.onHideStatusBar(false);
          }}
          onCreatingDone={() => {
            window.location.reload();
          }}
        />
      )}
    </>
  );
};

export default UsersList;
