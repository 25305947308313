const oCardReducer = (
    state = {
      allBlankCards: [],
      allBlankCardsCustomer: [],
    //   allCustomerCards: [],
    //   allBlankCards: [],
    //   allCusBlankCards: [],
    //   allSchemes: [],
    //   allSchemesByCus: [],
    //   oneCard: {},
    //   oneCardTransactions: [],
    //   fetching: false,
    //   fetched: false,
    //   error: null,
    //   bulkCreateModal: false,
    //   userCreateModal: false,
    //   bulkUploadModal: false,
    //   bulkUploadResultModal: false,
    //   userEditModal: false,
    //   userDeleteModal: false,
    //   addSchemeSuccessful: false,
    //   schemeMessage: '',
    },
    action,
  ) => {
    switch (action.type) {
      case 'FETCH_ALL_BLANKCARDS_START': {
        return { ...state, fetching: true };
      }

      case 'FETCH_ALL_BLANKCARDS_FULLFILLED': {
        return { ...state, fetching: false, fetched: true, allBlankCards: action.payload };
      }

      case 'FETCH_ALL_BLANKCARDS_FULLFILLED_CUS': {
        return { ...state, allBlankCardsCustomer: action.payload };
      }

      case 'FETCH_ALL_BLANKCARDS_REJECTED': {
        return { ...state, fetching: false, fetched: true, error: action.payload  };
      }
  
    //   case 'FETCH_ONE_CARD_START': {
    //     return { ...state, fetching: true };
    //   }
  
    //   case 'FETCH_ONE_CARD_FULFILLED': {
    //     return { ...state, fetching: false, fetched: true, oneCard: action.payload };
    //   }
  
    //   case 'FETCH_ONE_CARD_REJECTED': {
    //     return { ...state, fetching: false, fetched: true, error: action.payload  };
    //   }
  
  
    //   case 'FETCH_CARD_TRANSACTION_START': {
    //     return { ...state, fetching: true };
    //   }
  
    //   case 'FETCH_CARD_TRANSACTION_FULFILLED': {
    //     return { ...state, fetching: false, fetched: true, oneCardTransactions: action.payload };
    //   }
  
    //   case 'FETCH_CARD_TRANSACTION_REJECTED': {
    //     return { ...state, fetching: false, fetched: true, error: action.payload  };
    //   }
  
  
  
    //   case 'FETCH_BLANKCARDS_BYCUSTOMER_START': {
    //     return { ...state, fetching: true };
    //   }
  
    //   case 'FETCH_BLANKCARDS_BYCUSTOMER_FULLFILLED': {
    //     return { ...state, fetching: false, fetched: true, allCusBlankCards: action.payload };
    //   }
    //   case 'FETCH_BLANKCARDS_BYCUSTOMER_REJECTED': {
    //     return { ...state, fetching: false, fetched: true, error: action.payload  };
    //   }



    //   case 'FETCH_CARDS_BYCUSTOMER_START': {
    //     return { ...state, fetching: true };
    //   }
  
    //   case 'FETCH_CARDS_BYCUSTOMER_FULLFILLED': {
    //     return { ...state, fetching: false, fetched: true, allCustomerCards: action.payload };
    //   }
    //   case 'FETCH_CARDS_BYCUSTOMER_REJECTED': {
    //     return { ...state, fetching: false, fetched: true, error: action.payload  };
    //   }
  
       
  
      case 'FETCH_BLANKCARDS_START': {
        return { ...state, fetching: true };
      }
  
      case 'FETCH_BLANKCARDS_FULFILLED': {
        return { ...state, fetching: false, fetched: true, allBlankCards: action.payload };
      }

      case 'FETCH_BLANKCARDS_REJECTED': {
        return { ...state, fetching: false, fetched: true, error: action.payload  };
      }

      default:
        return state;
    }
  };
  
  export default oCardReducer;
  