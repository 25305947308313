import React, {useEffect, useState} from "react";
import {
  BackSVG,
  ActiveMarkSVG,
  DisabledMark,
  CardSvg,
  CardTypeSvg,
  RfidAssignSvg,
} from "../../../../assets/Svgs/Svgs";
import formatAmount from "../../../../lib/Functions/formatAmount";
import {useDispatch} from "react-redux";
import {
  // fetchOneCardTransactions,
  fetchOneCard,
} from "../../../../actions/CustomerActions/CardMgtActions/CardAction";
// import {set} from "date-fns";
import ToastAlert from "../../../../lib/Components/ToastAlert";
export default ({backFunction, selectedCard, customerCards}) => {
  const {
    name,
    userId,
    pan,
    limit,
    cycle,
    customerId,
    accountName,
    accountNumber,
    rfid,
  } = selectedCard;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOneCard(pan));
    // dispatch(fetchOneCardTransactions(7));
  }, []);
  const [cardAssignStage, setcardAssignStage] = useState(1);
  const [rfidTagNo, setrfidTagNo] = useState("");
  const [assignTagError, setassignTagError] = useState("");
  useEffect(() => {
    if (!!rfid) {
      setcardAssignStage(4);
    }
  }, [rfid]);
  return (
    <>
      <div className="underlined-tab">
        <span className="tab-title">Card Details</span>
        <div className="action-buttons">
          <button className="export-btn" onClick={backFunction}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className="row account-details">
        <div className="col-3 profile-picture-and-actions mt-4">
          <div>
            <CardSvg />
          </div>
          <div className="mt-5">
            <CardTypeSvg />
          </div>
        </div>

        <>
          <div className="col-9">
            <div className="row user-info">
              <div className="row user-info-title">
                <div className="col-3">Name</div>
                <div className="col-3">Users ID</div>
                <div className="col-3">Card Pan</div>
                <div className="col-3">RFID</div>
              </div>
              <div className="row user-info-details mt-3">
                <div className="col-3">{name}</div>
                <div className="col-3">{userId.replace("USER", "")}</div>
                <div className="col-3">{pan}</div>
                <div className="col-3">Nil</div>
              </div>
            </div>
            <div className="row user-extras">
              <div className="row user-extras-title">
                <div className="col-3">Date Created</div>
                <div className="col-3">Status</div>
                <div className="col-3">Limit</div>
                <div className="col-3">Frequency</div>
              </div>
              <div className="row user-extras-detail mt-3">
                <div className="col-3">{"Unavailable"}</div>
                <div className="col-3">
                  {!!customerId ? `Active ` : "Suspended"}{" "}
                  {!!customerId ? <ActiveMarkSVG /> : <DisabledMark />}
                </div>
                <div className="col-3">₦ {formatAmount(limit * 100)}</div>
                <div className="col-3">
                  {cycle[0]}
                  {cycle.split("").slice(1).join("").toLowerCase()}
                </div>
              </div>
            </div>
            <div className="row user-address">
              <div className="row user-address-title">
                <div className="col-3">Account</div>
                <div className="col-3">Scheme</div>
              </div>
              <div className="row user-address-details mt-3">
                <div className="col-3">{accountNumber}</div>
                <div className="col-3">{accountName}</div>
              </div>
            </div>
            <div className="row user-address mt-3">
              <div className="row col-12">
                <span className="overview-title" style={{marginBottom: 20}}>
                  Account Overview
                </span>
              </div>
              <div
                className="row col-12 ml-1"
                style={{backgroundColor: "#F1F4FA"}}
              >
                <div className="col-3 text-center mt-4">
                  <div className="credit-details">
                    <div
                      className="credit-title mb-2"
                      style={{position: "relative"}}
                    >
                      AVAILABLE BALANCE
                    </div>
                    <div
                      className="amount"
                      style={{
                        position: "relative",
                        width: 130,
                        margin: "10px auto",
                      }}
                    >
                      ₦ {formatAmount(limit * 100)}
                    </div>
                  </div>
                </div>
                <div className="col-9 pt-4">
                  <div className="customer-stats">
                    <div className="col-10 stats">
                      <div className="row">
                        <div className="col-4">LITRES USED</div>
                        <div className="col-4">TRANSACTION</div>
                        <div className="col-4">VALUE</div>
                      </div>
                      <div className="row numbers">
                        <div className="col-4">{"0"}</div>
                        <div className="col-4">{"0"}</div>
                        <div className="col-4">{"0"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {cardAssignStage === 1 && (
              <div className="row mt-4">
                <button
                  className="add-btn"
                  onClick={() => {
                    setcardAssignStage(2);
                  }}
                >
                  Assign tag to card
                </button>
              </div>
            )}
            {cardAssignStage === 2 && (
              <>
                <div className="row mt-4 user-address-details">
                  <span>Assign tag to card</span>
                </div>
                <div className="row d-block mt-4">
                  <label>RFID Tag</label>
                  <input
                    type="text"
                    value={rfidTagNo}
                    placeholder="Enter Tag Number"
                    className="form-control"
                    style={{maxWidth: 400}}
                    onChange={(e) => {
                      setrfidTagNo(e.target.value);
                    }}
                    maxLength={19}
                  />
                </div>
                {!!assignTagError && <div className="row">
                  <ToastAlert
                    showToast={!!assignTagError}
                    toastText={assignTagError}
                    parentClass="mt-2 d-block clear-both"
                    onCloseHandler={() => setassignTagError("")}
                  />
                </div>}
                <div className="row mt-4">
                  <button
                    className="add-btn"
                    onClick={() => {
                      setassignTagError("");
                      if (rfidTagNo.length < 16)
                        return setassignTagError(
                          "RFID tag must be at least 16 digits long"
                        );
                      else if (isNaN(rfidTagNo))
                        return setassignTagError(
                          "RFID tag must be made up of numeric digits"
                        );
                      setcardAssignStage(3);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </>
            )}
            {cardAssignStage === 3 && (
              <>
                <div className="row mt-4">
                  <RfidAssignSvg />
                </div>
                <div className="row mt-4">
                  <button
                    className="add-btn"
                    onClick={() => {
                      setcardAssignStage(4);
                    }}
                  >
                    Refresh Page
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};
