import React, { Component } from "react";
import "../Admin.css";
import "../../../../src/assets/Styles/SideMenu.css";
import {
  DashboardSvg,
  AccountSvg,
  ContractSvg,
  TokenSvg,
  AuditSvg,
  ReportSvg,
  TerminalSvg,
  CustomerSVG,
  SmsFeeSvg,
} from "../../../../src/assets/Svgs/Svgs";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Navbar from "../../admin/shared/Navbar";
import { fetchAllCustomers } from "../../ownerModule/Accounts/Customers/customers.actions";
import { fetchUser } from "../../ownerModule/Accounts/Users/users.actions";
import { fetchTags } from "../../ownerModule/Tokens/actions/methods";
import { getAllVendors } from "../../ownerModule/Accounts/Vendors/vendors.actions";
import {
  fetchAllPOSUsers,
  fetchAllTerminals,
} from "../../ownerModule/redux/action/OwnerVendorActions";
import { getSmsFeeListADasboard } from "../../customerModule/redux/action/CustomerActions";
// import fakeAuth from '../../../App'
// import {AdminUsers, hasRole, isAllowed} from "../auth/NavAuthorize";

class OMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // let is logged in be taken from fakeAuth
      isloggedIn: false,
      customerMenu: false,
      vendorMenu: false,
      ownerMenu: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  }

  handleSearchBtnClick(e, query) {
    const pathName = window.location.pathname;
    if (query) {
      if (pathName === "/owner-admin/users/main") {
        this.props.fetchUserFn(query);
      }

      if (pathName === "/owner-admin/sms-fee") {
        this.props.fetchSmsFee(query);
      }
      if (pathName === "/owner-admin/customers/main") {
        this.props.fetchCustomersFn(query);
      }

      if (pathName === "/owner-admin/vendors/main") {
        this.props.getAllVendorsFn(query);
      }

      if (pathName === "/owner-admin/terminals/main") {
        this.props.fetchAllTerminalsFn(query);
      }

      if (pathName === "/owner-admin/posusers/main") {
        this.props.fetchAllPOSUsersFn(query);
      }

      if (pathName === "/owner-admin/rfid/main") {
        this.props.fetchTagsFn(query);
      }
    } else {
      if (pathName === "/owner-admin/users/main") {
        this.props.fetchUserFn(e.target.value);
      }

      if (pathName === "/owner-admin/sms-fee") {
        this.props.fetchSmsFee(e.target.value);
      }
      if (pathName === "/owner-admin/customers/main") {
        this.props.fetchCustomersFn(e.target.value);
      }

      if (pathName === "/owner-admin/vendors/main") {
        this.props.getAllVendorsFn(e.target.value);
      }

      if (pathName === "/owner-admin/terminals/main") {
        this.props.fetchAllTerminalsFn(e.target.value);
      }

      if (pathName === "/owner-admin/posusers/main") {
        this.props.fetchAllPOSUsersFn(e.target.value);
      }

      if (pathName === "/owner-admin/rfid/main") {
        this.props.fetchTagsFn(e.target.value);
      }
    }
  }

  render() {
    const pathName = window.location.pathname;

    return (
      <div>
        <Navbar onSearchBtnClick={this.handleSearchBtnClick.bind(this)} />

        <div className="side-menu-container">
          <ul className="">
            <NavLink
              to={!this.state.user.isNew ? "/owner-admin/dashboard" : "#"}
            >
              <li
                className={`side-menu-list ${
                  (pathName === "/owner-admin/dashboard" ||
                    pathName === "/owner-admin/change-password/main") &&
                  "active"
                }`}
              >
                <DashboardSvg />
                <span>Dashboard</span>
              </li>
            </NavLink>

            {!this.state.user.isNew && (
              <>
                <NavLink to="/owner-admin/users">
                  <li
                    className={`side-menu-list ${
                      (pathName.indexOf("/owner-admin/users/main") > -1 ||
                        pathName.indexOf("/owner-admin/customers/") > -1 ||
                        pathName.indexOf("/owner-admin/vendors/") > -1) &&
                      "active"
                    }`}
                  >
                    {pathName === "/owner-admin/customers/main" ? (
                      <CustomerSVG />
                    ) : (
                      <AccountSvg />
                    )}
                    <span>Accounts</span>
                  </li>
                </NavLink>

                <NavLink to="/owner-admin/rfid/main">
                  <li
                    className={`side-menu-list ${
                      (pathName === "/owner-admin/cards/main" ||
                        pathName === "/owner-admin/rfid/main") &&
                      "active"
                    }`}
                  >
                    <TokenSvg />
                    <span>Tokens</span>
                  </li>
                </NavLink>

                <NavLink to="/owner-admin/contracts">
                  <li
                    className={`side-menu-list ${
                      pathName === "/owner-admin/contracts/main" && "active"
                    }`}
                  >
                    <ContractSvg />
                    <span>Contracts</span>
                  </li>
                </NavLink>
                <NavLink to="/owner-admin/payments">
                  <li
                    className={`side-menu-list ${
                      pathName === "/owner-admin/payments" && "active"
                    }`}
                  >
                    <ContractSvg />
                    <span>Payments</span>
                  </li>
                </NavLink>

                {/* {(hasRole(this.state.user, ['SUPER_ADMIN', 'ADMIN']) || isAllowed(this.state.user, ['VIEW_SETTLEMENT', 'VIEW_TRANSACTION'])) && ( */}
                <NavLink to="/owner-admin/reports">
                  <li
                    className={`side-menu-list ${
                      (pathName === "/owner-admin/reports/main" ||
                        pathName === "/owner-admin/settlements/main") &&
                      "active"
                    }`}
                  >
                    <ReportSvg />
                    <span>Reports</span>
                  </li>
                </NavLink>
                {/* )} */}

                <NavLink to="/owner-admin/terminals">
                  <li
                    className={`side-menu-list ${
                      (pathName.indexOf("/owner-admin/posusers/main") > -1 ||
                        pathName.indexOf("/owner-admin/terminals/main") > -1) &&
                      "active"
                    }`}
                  >
                    <TerminalSvg />
                    <span>Terminals</span>
                  </li>
                </NavLink>

                {/* {/* {(hasRole(this.state.user, ['SUPER_ADMIN', 'ADMIN']) || isAllowed(this.state.user, ['VIEW_AUDIT'])) && ( */}
                <NavLink to="/owner-admin/trail">
                  <li
                    className={`side-menu-list ${
                      pathName.indexOf("/owner-admin/trail") > -1 && "active"
                    }`}
                  >
                    <AuditSvg />
                    <span>Audit Trail</span>
                  </li>
                </NavLink>
                {/* )} */}

                <NavLink to="/owner-admin/sms-fee">
                  <li
                    className={`side-menu-list ${
                      pathName.indexOf("/owner-admin/sms-fee") > -1 && "active"
                    }`}
                  >
                    <SmsFeeSvg />
                    <span>Sms Fee</span>
                  </li>
                </NavLink>
              </>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserFn: (query) => dispatch(fetchUser(1, 10, query)),
    fetchCustomersFn: (query) => dispatch(fetchAllCustomers(1, 10, query)),
    getAllVendorsFn: (query) => dispatch(getAllVendors(1, 10, query)),
    fetchAllTerminalsFn: (query) =>
      dispatch(fetchAllTerminals(1, 10, "", "", query)),
    fetchAllPOSUsersFn: (query) => dispatch(fetchAllPOSUsers(query)),
    fetchTagsFn: (query) => dispatch(fetchTags(1, 1, query)),
    fetchSmsFee: (query) => dispatch(getSmsFeeListADasboard(1, 10, query)),
  };
};

// export default OMenu;
export default connect(null, mapDispatchToProps)(OMenu);
