import React, { useState, useEffect } from "react";
import SubComponentHeader from "../../../../lib/Components/SubComponentHeader";
import { useDispatch } from "react-redux";
import FormWarning from "../../../admin/shared/FormWarning";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import CustomInput from "../../../../lib/Components/CustomInput";
import { countries } from "../../../../lib/Dropdowns/countries";
import validator from "validator";

export default function CreatePosUser({
  isCreatePos,
  onBackHandler,
  oVendorReducer,
  onCreatePos
}) {
  const dispatch = useDispatch();
  const [posValidationError, setPosValidationError] = useState(false);
  const [toast, setToast] = useState({ class: "", text: "" });
  const [isCreatingPos, setIsCreatingPos] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    usersCode: "",
    usersId: "",
    email: "",
    street: "",
    city: "",
    state: "",
    country: "",
    vendor: ""
  });

  const invalidPosForm = () => {
    const {
      firstName,
      lastName,
      phone,
      usersCode,
      usersId,
      email,
      street,
      city,
      state,
      country,
      vendor
    } = formData;
    if (
      !firstName ||
      !lastName ||
      !validator.isMobilePhone(phone) ||
      !usersCode ||
      !usersId ||
      !validator.isEmail(email) ||
      !street ||
      !city ||
      !state ||
      !country ||
      !vendor
    ) {
      return true;
    } else {
      return false;
    }
  };

  const ven = oVendorReducer.allVendors;
  const venRow = ven.data ? ven.data : ven;
  const vendorData = venRow.data ? venRow.data : venRow;

  const vendorOption = vendorData.map((value, key) => {
    return (
      <option value={value.id} key={key}>
        {value.name}
      </option>
    );
  });

  useEffect(() => {
    if (!!isCreatingPos && oVendorReducer.createPOSUserRequestStatus !== 1) {
      setToast({
        class: "",
        text:
          oVendorReducer.createPOSUserRequestMessage ||
          "Fields incorrectly filled!"
      });
      setPosValidationError(true);
      setIsCreatingPos(false);
    }
    if (!!isCreatingPos && oVendorReducer.createPOSUserRequestStatus === 1) {
      setToast({
        class: "success-container success-text",
        text: oVendorReducer.createPOSUserRequestMessage
      });
      resetFormData();
      setIsCreatingPos(false);
      setPosValidationError(true);
    }

    setTimeout(function(){
      dispatch({
        type: "CREATE_POS_USERS_REQUEST",
        response: { status: 0, message: "" }
      });
    }, 3000)
    
  }, [oVendorReducer.createPOSUserRequestStatus]);

  const resetFormData = () => {
    setFormData({
      firstName: "",
      lastName: "",
      phone: "",
      usersCode: "",
      usersId: "",
      email: "",
      street: "",
      city: "",
      state: "",
      country: "",
      vendor: ""
    });
  };

  const createPos = event => {
    event.preventDefault();
    if (!!invalidPosForm()) {
      setPosValidationError(true);
      setToast({
        class: "",
        text: "Fill all fields correctly to continue!"
      });
      return;
    }

    onCreatePos(formData, bool => {
      setIsCreatingPos(bool);
    });
  };

  const handleInput = (attr, event) => {
    setFormData({ ...formData, [attr]: event.target.value });
  };

  return (
    <>
      {isCreatePos && (
        <>
          <SubComponentHeader
            heading="Create new POS user"
            onBackHandler={onBackHandler}
          />

          <div className="row mt-2" style={{ width: "100%" }}>
            <div className="offset-1 col-10 p-3">
              <div className="col-12">
                <div
                  className="row bg-rfid-detailsm form bg-white ml-1 mr-1 pb-5"
                  style={{
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)",
                    borderRadius: 4
                  }}
                >
                  <div className="row ml-2 mr-2">
                    

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="First Name"
                      labelClass="mt-3"
                      value={formData.firstName}
                      handleInput={handleInput.bind(this, "firstName")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Last Name"
                      labelClass="mt-3"
                      value={formData.lastName}
                      handleInput={handleInput.bind(this, "lastName")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Phone Number"
                      labelClass="mt-3"
                      value={formData.phone}
                      handleInput={handleInput.bind(this, "phone")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Users Code"
                      labelClass="mt-3"
                      value={formData.usersCode}
                      handleInput={handleInput.bind(this, "usersCode")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Users ID"
                      labelClass="mt-3"
                      value={formData.usersId}
                      handleInput={handleInput.bind(this, "usersId")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Email"
                      labelClass="mt-3"
                      value={formData.email}
                      handleInput={handleInput.bind(this, "email")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Street"
                      labelClass="mt-3"
                      value={formData.street}
                      handleInput={handleInput.bind(this, "street")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="City"
                      labelClass="mt-3"
                      value={formData.city}
                      handleInput={handleInput.bind(this, "city")}
                    />


                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="State"
                      labelClass="mt-3"
                      value={formData.state}
                      handleInput={handleInput.bind(this, "state")}
                    />

                    <CustomInput
                      presetData="Select Country..."
                      data={countries}
                      labelClass="mt-3"
                      parentClass="col-4 mt-4"
                      label="Select Country"
                      value={formData.country}
                      isSelect={true}
                      handleInput={handleInput.bind(this, "country")}
                    />

                    <CustomInput
                      presetData="Select Vendor"
                      data={vendorOption}
                      labelClass="mt-3"
                      parentClass="col-4 mt-4"
                      label="Select Vendor"
                      value={formData.vendor}
                      isSelect={true}
                      handleInput={handleInput.bind(this, "vendor")}
                    />
                  </div>

                  <div className="row">
                    <div className="row ml-3 mt-4 mr-2">
                      <FormWarning
                        inline={true}
                        message="Please note that all fields are compulsory"
                      />
                    </div>
                  </div>

                  <ToastAlert
                    showToast={!!posValidationError}
                    parentClass={"col-12 pb-3"}
                    childClass={toast.class}
                    toastText={toast.text}
                    onCloseHandler={() => {
                      setPosValidationError(false);
                    }}
                  />
                </div>

                <div className="buttons mt-3 text-right">
                  <div
                    className="mr-4"
                    style={{
                      backgroundColor: "#5F738C",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer"
                    }}
                    onClick={onBackHandler}
                  >
                    Cancel
                  </div>
                  <div
                    style={{
                      backgroundColor: "#00425F",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer"
                    }}
                    onClick={!isCreatingPos ? createPos : undefined}
                  >
                    {isCreatingPos ? (
                      <img
                        src="/images/ActivityIndicator.gif" alt="activity-indicator"
                        style={{ height: 20, width: 20 }}
                      />
                    ) : (
                      "Create POS user"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
