import React, { useState, useEffect } from "react";
import "../../../../assets/Styles/Dashboard.css";
import {
  shortMonthNames,
  shortDayNames,
  longMonthNames
} from "../../../../lib/Functions/DateNames";
import { formatDate3 } from "../../../../lib/Functions/formatDate";
import { useDispatch } from "react-redux";
import {
  ActiveMarkSVG,
  CreateSVG,
  ExportSVG,
  CloseSVG
} from "../../../../assets/Svgs/Svgs";
import Modal from "react-bootstrap/Modal";
import {
  setTagCreationStatusAction,
} from "../actions/actions";
import { CSVLink } from "react-csv";
import Pagination from "../../../../lib/Components/Pagination";
import CreateRfid from "./CreateRfid";

const displayTags = (tagsArray, callback) => {
  return tagsArray.map((value, index) => {
    const { rfid, batchType, isUsed, createdAt, firstName, lastName } = value;
    const d = new Date(createdAt);
    return (
      <tr key={index}>
        <td className="table-pin">{rfid}</td>
        <td className="table-description font-weight-bold">
          {batchType.toUpperCase()[0] + batchType.substring(1)}
        </td>
        <td className="table-description">
          {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
          {shortMonthNames[d.getMonth()]} {d.getFullYear()}
        </td>
        <td className={`table-description ${!isUsed ? "text-danger" : ""}`}>
          {isUsed ? "Assigned" : "Unassigned"}
        </td>
        <td className="table-date">
          {firstName ? `${firstName} ${lastName}` : `Unavailable`}
        </td>
        <td className="table-status">
          <div className="status-btn success">Active</div>
        </td>
        <td
          className="table-action"
          onClick={() => {
            callback(value);
          }}
        >
          View
        </td>
      </tr>
    );
  });
};

const displayBatch = batchArray => {
  return batchArray.content.map((value, index) => {
    const { fileName, createdAt, batchSize, link, type } = value;
    return (
      <tr key={index}>
        <td className="table-pin font-weight-bold">{fileName}</td>
        <td className="table-description font-weight-bold">
          {type || "Unavailable"}
        </td>
        <td className="table-description font-weight-bold">{batchSize}</td>
        <td className="table-description font-weight-bold">
          {formatDate3(createdAt)}
        </td>
        <td className="table-action font-weight-bold">
          {!!link ? (
            <a style={{ color: "#007bff" }} href={link || "#"}>
              Download
            </a>
          ) : (
            <span style={{ color: "#dc3545" }}>Unavailable</span>
          )}
        </td>
      </tr>
    );
  });
};

const RFIDList = props => {
  const [tab, setTab] = useState(0);
  const [showRfidDetails, setShowRfidDetails] = useState(false);
  const [showTagsCreation, setShowTagsCreation] = useState(false);
  const { rfidReducer, onFetchRfidTags, onFetchRfidBatch } = props;
  const {
    usedTags,
    unusedTags,
    unusedTagsSize,
    usedTagsSize,
    // isCreatingTags,
    // isTagsCreated
  } = rfidReducer;
  const [selectedRfid, setSelectedRfid] = useState({});
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    rfid,
    createdAt,
    batchType,
    isUsed
  } = selectedRfid;
  const d = new Date(createdAt);
  const rfidCreatedDate = `${d.getDate()}, ${
    longMonthNames[d.getMonth()]
  } ${d.getFullYear()}`;
  // const pageSize = 10;

  useEffect(() => {
    dispatch(setTagCreationStatusAction(false));
  }, []);

  return (
    <div className="disbursed-cards-container row">
      {!showTagsCreation && (
        <>
          <div
            onClick={() => setTab(0)}
            className="rfid-menu-link"
            style={{ marginBottom: 0, marginRight: 20 }}
          >
            <span
              style={{ color: "#000000" }}
              className={tab === 0 ? "active" : ""}
            >
              Assigned Serials
            </span>
          </div>
          <div
            onClick={() => setTab(1)}
            className="rfid-menu-link"
            style={{ marginBottom: 0, marginRight: 20 }}
          >
            <span
              style={{ color: "#000000" }}
              className={tab === 1 ? "active" : ""}
            >
              Unassigned Serials
            </span>
          </div>
          <div
            onClick={() => setTab(2)}
            className="rfid-menu-link"
            style={{ marginBottom: 0 }}
          >
            <span
              style={{ color: "#000000" }}
              className={tab === 2 ? "active" : ""}
            >
              Batch Downloads
            </span>
          </div>
          {
            <>
              <div
                className="create-new-tags-btn"
                onClick={() => {
                  props.onHideStatusBar(true);
                  setShowTagsCreation(true);
                }}
              >
                <span className="align-middle">
                  <CreateSVG /> Create New Tags
                </span>
              </div>
              {(tab === 0 || tab === 1) && unusedTagsSize > 0 && (
                <div className="rfid-export-btn">
                  <CSVLink
                    data={tab === 0 ? usedTags : unusedTags}
                    filename={
                      tab === 0
                        ? "Smart Fuel Assgined Tags.csv"
                        : "Smart Fuel Unassigned Tags.csv"
                    }
                    className=""
                    target="_blank"
                  >
                    <span className="text-white">
                      Export CSV <ExportSVG />
                    </span>
                  </CSVLink>
                </div>
              )}{" "}
            </>
          }
          {tab === 0 && (
            <>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <td>RFID</td>
                    <td>Account Type</td>
                    <td>Date Created</td>
                    <td>Tag Status</td>
                    <td>Assigned To</td>
                    <td>Activity</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {usedTagsSize > 0 &&
                    displayTags(usedTags, selectedTag => {
                      props.onHideStatusBar(false);
                      setSelectedRfid(selectedTag);
                      setShowRfidDetails(true);
                    })}
                  {usedTagsSize === 0 && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No RFID tag available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                count={usedTagsSize}
                pageSize={10}
                fetchMethod={pageNo => {
                  onFetchRfidTags(pageNo, 1);
                }}
              />
            </>
          )}
          {tab === 1 && (
            <>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <td>RFID</td>
                    <td>Account Type</td>
                    <td>Date Created</td>
                    <td>Tag Status</td>
                    <td>Assigned To</td>
                    <td>Activity</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {unusedTagsSize > 0 &&
                    displayTags(unusedTags, selectedTag => {
                      props.onHideStatusBar(false);
                      setSelectedRfid(selectedTag);
                      setShowRfidDetails(true);
                    })}
                  {unusedTagsSize === 0 && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No RFID tag available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                count={unusedTagsSize}
                pageSize={10}
                fetchMethod={pageNo => {
                  onFetchRfidTags(1, pageNo);
                }}
              />
            </>
          )}

          {tab === 2 && (
            <>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Type</td>
                    <td>Batch Size</td>
                    <td>Date Created</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {rfidReducer.rfidBatch.count > 0 &&
                    displayBatch(rfidReducer.rfidBatch)}
                  {rfidReducer.rfidBatch === 0 && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No batch to view
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                count={rfidReducer.rfidBatch.count}
                pageSize={10}
                fetchMethod={(pageNo, pageSize) => {
                  onFetchRfidBatch(pageNo, pageSize);
                }}
              />
            </>
          )}
        </>
      )}

      {
        <Modal
          show={showRfidDetails}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="transaction-modal"
        >
          <Modal.Body>
            <div className="pl-5 pr-5 pt-3 pb-5 text-center">
              <div className="text-right">
                <span
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => {
                    setShowRfidDetails(false);
                    props.onHideStatusBar(false);
                  }}
                >
                  <CloseSVG />
                </span>
              </div>

              <div className="row" style={{ clear: "both" }}>
                <div className="col-12 bg-rfid-details mt-3 p-3">
                  <div className="col-10 offset-1">
                    <div className="row text-center">
                      <div className="col-3">Name</div>
                      <div className="col-3">RFID Serial</div>
                      <div className="col-3">Account Type</div>
                      <div className="col-3">Assigned to</div>
                    </div>
                    <div className="row text-center font-weight-bold mt-3">
                      <div className="col-3">{`${firstName || ""} ${lastName ||
                        "Unavailable"}`}</div>
                      <div className="col-3">{rfid}</div>
                      <div className="col-3">Owner User</div>
                      <div className="col-3">Owner User</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 bg-rfid-details p-3">
                  <div className="col-10 offset-1">
                    <div className="row text-center">
                      <div className="col-3">Date Created</div>
                      <div className="col-3">Tag Category</div>
                      <div className="col-3">Tag Status</div>
                      <div className="col-3">Activity</div>
                    </div>
                    <div className="row text-center font-weight-bold mt-3">
                      <div className="col-3">{rfidCreatedDate}</div>
                      <div className="col-3">
                        {!!batchType &&
                          batchType[0].toUpperCase() + batchType.substring(1)}
                      </div>
                      <div className="col-3">
                        {isUsed ? "Assigned" : "Unassigned"}
                      </div>
                      <div className="col-3">
                        Active <ActiveMarkSVG />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
      <CreateRfid
        {...props}
        onBackHandler={() => { 
          setShowTagsCreation(false);
          props.onHideStatusBar(false);
        }}
        showTagsCreation={showTagsCreation}
        setTabHandler={tabIndex => setTab(tabIndex)}
        showTagsCreationHandler={status => setShowTagsCreation(status)}
      />
    </div>
  );
};

export default RFIDList;
