import React, {useEffect} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import StatusBar from './StatusBar';
import Analytics from './Analytics';
import { getAllUsers } from "../../redux/action/customerUserActions";
import { OWNER_USER} from "../../../../lib/Constants/UserTypes";
import {
  fetchAllTrail,
  fetchAllTransactions,
  fetchHighestTransactingUser,
  fetchThreeMonthsTransactionStats,
  fetchTransactionAmountByFrequency
} from "../../redux/action/OwnerTrailActions";
import { fetchAllVendors } from "../../redux/action/OwnerVendorActions";
import TransactionReport from "../../Reports/Transactions/TransactionList";
import moment from "moment";

function Dashboard () {
  const dispatch = useDispatch();
  const props = useSelector(state => state);
  const startDate = moment(new Date(moment().subtract(365, 'days').format('LLLL'))).format('YYYY-MM-DD')
  const endDate = moment(new Date(moment().subtract(0, 'days').format('LLLL'))).format('YYYY-MM-DD')

  useEffect(()=> {
    batch(()=>{
      dispatch(getAllUsers(OWNER_USER));
      dispatch(fetchAllTrail(1, 10000000));
      dispatch(fetchAllVendors(1, 1000000));
      dispatch(fetchAllTransactions(startDate, endDate, 1, 10, 2, {vendorNum: null, customerNum: null, location: null}));
      dispatch(fetchHighestTransactingUser('VENDOR', 'AMOUNT'));
      dispatch(fetchThreeMonthsTransactionStats('VENDOR', 'AMOUNT'));
      dispatch(fetchTransactionAmountByFrequency('VENDOR'));
    })
    
  }, [])
  
  return (
    <div className="dashboard-container">
      <StatusBar {...props} /> 
      <Analytics {...props} currentDate={endDate} /> 
      <TransactionReport {...props} title="Last 10 Transactions" />
    </div>
  )
}

export default Dashboard;