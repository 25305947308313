import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  BackSVG,
  ActiveMarkSVG,
  AvatarSVG,
  DisabledMark,
  UserCompleteMarkSVG,
  LoadingSVG,
} from "../../../../assets/Svgs/Svgs";
import PermissionBadge from "../../subcomponents/PermissionBadge/PermissionBadge";
import {
  shortMonthNames,
  shortDayNames,
} from "../../../../lib/Functions/DateNames";
import EditForm from "./EditUser";
import { useDispatch } from "react-redux";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import { toggleUserStatus } from "../../../customerModule/redux/action/users.actions";
import { resetUserUpdate } from "../../../../actions/OwnerActions/OwnerUserMgtActions/OwnerUserActions";

const AccountDetails = (props) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const { user } = props;
  const { permissions } = user;
  const d = new Date(user.createdAt);
  const dispatch = useDispatch();

  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [showCreateSuccessModal, setShowCreateSuccessModal] = useState(false);
  const [profilePix] = useState(user.image || "");
  const [userCreationError, setUserCreationError] = useState(false);
  const [userUpdateError, setUserUpdateError] = useState("");
  const [saveEditForm, setSaveEditForm] = useState(false);

  useEffect(() => {
    setIsCreatingUser(props.customerUser.isCreatingUser);
  }, [props.customerUser.isCreatingUser]);

  useEffect(() => {
    if (props.customerUser.fetchingStatus === 1) {
      setShowCreateSuccessModal(true);
    } else if (props.customerUser.fetchingStatus === 2) {
      setUserCreationError(true);
    }
  }, [props.customerUser.fetchingStatus]);

  useEffect(() => {
    setIsCreatingUser(false);
    setShowCreateSuccessModal(false);
    dispatch(resetUserUpdate());
  }, []);

  useEffect(() => {
    if(props.customerUser.editingStatus === 2){
      setUserUpdateError('User status could not be updated!')
    }
  }, [props.customerUser.editingStatus])

  return (
    <>
      <>
        <Modal
          isOpen={isCreatingUser || showCreateSuccessModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="user-modal"
        >
          <ModalBody>
            {isCreatingUser && (
              <>
                <div className="text-center mt-5 header">Editing user....</div>
                <div className="text-center mt-5 mb-5">
                  <LoadingSVG />
                </div>
              </>
            )}
            {showCreateSuccessModal && (
              <>
                <div className="text-center mt-5 mb-4">
                  <span
                    className="d-block font-weight-bold"
                    style={{
                      fontSize: "20px",
                      color: "#4B4B4B",
                      margin: "1rem",
                    }}
                  >
                    Thank you! You are all set!
                  </span>
                  <UserCompleteMarkSVG />
                  <span
                    className="d-block quickteller-blue font-weight-bold"
                    style={{
                      fontSize: "1rem",
                      color: "#00425F",
                      margin: "1.25rem",
                    }}
                  >
                    Your new user profile has been successfully edited
                  </span>
                </div>
                <div className="row modal-action-buttons">
                  <div className="col-12 text-center">
                    <button
                      className="back"
                      onClick={() => {
                        props.onEditingDone();
                      }}
                      style={{ width: 120 }}
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
              </>
            )}
          </ModalBody>
        </Modal>
      </>

      <div className="underlined-tab">
        <span className="tab-title">Account details</span>
        <div className="action-buttons">
          <button className="export-btn" onClick={props.backFunction}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>

      <div className="row account-details">
        <div className="col-3 profile-picture-and-actions">
          <div className="avatar">
            {!!profilePix ? (
              <img
                alt="Profile"
                src={profilePix.base64 || profilePix}
                style={{ width: 100, height: 100, borderRadius: 50 }}
              />
            ) : (
              <AvatarSVG />
            )}
          </div>
          {showEditForm && (
            <button
              className="button save-btn"
              onClick={() => setSaveEditForm(true)}
            >
              Save changes
            </button>
          )}

          {!showEditForm && (
            <>
              <button
                className="button edit-profile-btn"
                onClick={() => setShowEditForm(true)}
              >
                Edit Profile
              </button>
              <button
                className={`button ${
                  user.status === "ACTIVE" ? "suspend-btn" : "activate-btn"
                }`}
                onClick={() => {
                  if(user.userLevel === "SUPER_ADMIN"){
                    setUserUpdateError("You cannot update the status of a user you did not create!");
                    return;
                  }
                  dispatch(
                    toggleUserStatus(
                      user.id,
                      user.status === "ACTIVE" ? "SUSPENDED" : "ACTIVE"
                    )
                  );
                }}
              >
                {props.customerUser.isUpdatingUser ? (
                  <img
                    alt="Profile"
                    src="/images/ActivityIndicator.gif"
                    style={{ height: 20, width: 20, marginTop: 0 }}
                  />
                ) : (
                  `${user.status !== "ACTIVE" ? "Activate" : "Suspend"} Account`
                )}
              </button>
              <ToastAlert
                showToast={!!userUpdateError}
                parentClass={"col-12 pb-3 pl-0 pr-0 text-left"}
                childClass={""}
                toastText={userUpdateError}
                onCloseHandler={() => {
                  setUserUpdateError('');
                  dispatch({type: 'RESET_CUSER_UPDATE'})
                }}
              />
            </>
          )}
        </div>

        {!showEditForm && (
          <>
            <div className="col-9">
              <div className="row user-info">
                <div className="row user-info-title">
                  <div className="col-2">First Name</div>
                  <div className="col-2">Last Name</div>
                  <div className="col-4">Email</div>
                  <div className="col-2">User Level</div>
                  <div className="col-2">User Type</div>
                </div>
                <div className="row user-info-details">
                  <div className="col-2" title={user.firstName}>
                    {user.firstName}
                  </div>
                  <div className="col-2" title={user.lastName}>
                    {user.lastName}
                  </div>
                  <div
                    className="col-4"
                    title={user.userEmail}
                    style={{ overflowWrap: "break-word" }}
                  >
                    {user.userEmail}
                  </div>
                  <div className="col-2" title={user.userLevel}>
                    {user.userLevel.split("_").join(" ")[0].toUpperCase()}
                    {user.userLevel.split("_").join(" ").slice(1).toLowerCase()}
                  </div>
                  <div className="col-2" title={user.type}>
                    {user.type.split("_").join(" ")[0].toUpperCase()}
                    {user.type.split("_").join(" ").slice(1).toLowerCase()}
                  </div>
                </div>
              </div>
              <div className="row user-extras">
                <div className="row user-extras-title">
                  <div className="col-2">Date Created</div>
                  <div className="col-2">Phone</div>
                  <div className="col-4">Status</div>
                  <div className="col-3">Limit (Scheme managers only)</div>
                </div>
                <div className="row user-extras-detail">
                  <div className="col-2">
                    {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
                    {shortMonthNames[d.getMonth()]} {d.getFullYear()}
                  </div>
                  <div className="col-2">{user.contact.phoneNumber || ""}</div>
                  {user.status.toUpperCase() === "ACTIVE" ? (
                    <div className="col-4">
                      Active <ActiveMarkSVG />
                    </div>
                  ) : (
                    <div className="col-3">
                      Suspended <DisabledMark />
                    </div>
                  )}
                </div>
              </div>
              <div className="row user-address">
                <div className="row user-address-title">
                  <div className="col-2">Street</div>
                  <div className="col-2">City</div>
                  <div className="col-4">State</div>
                  <div className="col-2">Country</div>
                </div>
                <div className="row user-address-details">
                  <div className="col-2">
                    {(user.address && user.address.street) || "Unavailable"}
                  </div>
                  <div className="col-2">
                    {(user.address && user.address.city) || "Unavailable"}
                  </div>
                  <div className="col-4">
                    {(user.address && user.address.state) || "Unavailable"}
                  </div>
                  <div className="col-2">
                    {(user.address && user.address.country) || "Unavailable"}
                  </div>
                </div>
              </div>
              <div className="row user-overview">
                <span className="overview-title">Permissions</span>
                {!permissions && "No Permissions assigned to user"}
                {permissions &&
                  permissions.map((permission, index) => (
                    <PermissionBadge key={index} permission={permission.name} />
                  ))}
              </div>
            </div>
          </>
        )}

        {showEditForm && (
          <div className="col-8 edit-form">
            <EditForm
              {...props}
              create={false}
              saveEditForm={saveEditForm}
              onSaveEditFormHandler={(status) => setSaveEditForm(status)}
              userToBeEdited={user}
              resetForm={false}
              profilePix={{ base64: profilePix.base64 || user.image }}
              userCreationError={userCreationError}
              userCreationErrorHandler={(status) =>
                setUserCreationError(status)
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AccountDetails;
