import React, { useEffect, useMemo, useRef } from 'react';
import { Modal, Dropdown, ModalHeader } from 'react-bootstrap';
import { ReactComponent as BlueDownloadIcon } from '../../../assets/Svgs/blue_download.svg'
import { CloseSVG } from '../../../assets/Svgs/Svgs';
import ReactToPrint from "react-to-print";
import formatAmount from '../../../lib/Functions/formatAmount';
import PaymentStatus from './paymentUtil';


const ReceiptComponent = ({ toggleModal, paymentDetail, showReceipt }) => {
    const receiptRef = useRef();
    return (<Modal style={{
    }} scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal receipt-modal" show={showReceipt}>
        <Modal.Body ref={receiptRef} style={{
             padding: 50,
            // background: '#EBF8FE'
        }}> <div>
            <div className='payment-modal-header justify-content-between mb-5 mr-5'>
                <div>
                    <h5 style={{ color: '#212429' }} className='payment-modal-h'>Receipt</h5>
                    <label style={{ color: '#7F91A8' }} className='payments-sub'>
                        View payment receipt
                    </label>
                </div>
                <span onClick={toggleModal}><CloseSVG className="modal-close-icon" />
                </span>
            </div>

            {paymentDetail && <div  className='invoice-modal-detail row'>
                <div className='col-3 mb-45'>
                    <div>
                        <label className='receipt-col-head'>Customer Name</label>
                        <p className='payments-sub dark bold-600'>{paymentDetail.customerName}</p>
                    </div>
                </div>
                <div className='col-3 mb-45'>
                    <div>
                        <label className='receipt-col-head'>Vendor name</label>
                        <p className='payments-sub dark bold-600'>{paymentDetail.vendorName}</p>
                    </div>
                </div>
                <div className='col-3 mb-45'>
                    <div>
                        <label className='receipt-col-head'>Invoiced Amount</label>
                        <p className='payments-sub dark bold-600'>N{formatAmount(paymentDetail.invoice.amount * 100)}</p>
                    </div>
                </div>
                <div className='col-3 mb-45'>
                    <div>
                        <label className='receipt-col-head'>Paid Amount</label>
                        <p className='payments-sub dark bold-600'>{paymentDetail.invoice.remittanceAmount ? `N${formatAmount(paymentDetail.invoice.remittanceAmount * 100)}` : "N/A"}</p>
                    </div>
                </div>
                <div className='col-3'>
                    <div>
                        <label className='receipt-col-head'>Invoice ID</label>
                        <p className='payments-sub dark bold-600'>{paymentDetail.invoice.number}</p>
                    </div>
                </div>
                <div className='col-3 mb-45'>
                    <div>
                        <label className='receipt-col-head'>Payment Type</label>
                        <p className='payments-sub dark bold-600'>{paymentDetail.paymentType}</p>
                    </div>
                </div>
                <div className='col-3 mb-45'>
                    <div>
                        <label className='receipt-col-head'>Payment Date</label>
                        <p className='payments-sub dark bold-600'>{paymentDetail.invoice.transactionDate ? paymentDetail.invoice.transactionDate : ""}</p>
                    </div>
                </div>
                <div className='col-3'>
                    <div>
                        <label className='receipt-col-head'>Payment Status</label>
                        <p><PaymentStatus status={paymentDetail.invoice.status} /></p>
                    </div>
                </div>


                <div className='row w-100 justify-content-between m-0'>
                    <div className='col-4 mb-45'>
                        <div>
                            <label className='receipt-col-head'>Payment Ref</label>
                            <p className='payments-sub dark bold-600'>{paymentDetail.invoice.paymentReference}</p>
                        </div>
                    </div>

                    <div className='col-4 mb-45'>
                        <div>
                            <label className='receipt-col-head'>Bank Name</label>
                            <p className='payments-sub dark bold-600'>{paymentDetail.bankName}</p>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div>
                            <ReactToPrint
                                trigger={() => (
                                    <div style={{ cursor:'pointer' }} className='d-flex align-items-center mt-3' ><BlueDownloadIcon className='mb-2 mr-2' /> <label className='download-link'>Download</label></div>
                                )}
                                content={() => receiptRef.current}
                            />
                        </div>
                    </div>
                </div>
            </div>}
            </div>
        </Modal.Body>
    </Modal>);
}

export default ReceiptComponent;