import React, { Component } from 'react';
import '../Admin.css';
import { Route } from 'react-router-dom';
import { Owner, Customer, Vendor } from '../auth/Authorize';
import OMenu from './OMenu';
import CMenu from './CMenu';
import VMenu from './VMenu';

class Menu extends Component {
  constructor() {
    super();
    this.state = {
      // let is logged in be taken from fakeAuth
      isloggedIn: false,
      // show: true,
      toggleMenuBar: true,
    };
  }

  toggleMenu = val => {
    this.setState({
      toggleMenuBar: val,
    });
  };

  UNSAFE_componentWillMount() {}

  render() {
    return (
      <div>
        <div className="">
          <Route path="/owner-admin/" exact={false} component={Owner(OMenu)} />
          <Route
            path="/customer-admin/"
            exact={false}
            component={Customer(CMenu)}
          />
          <Route
            path="/vendor-admin/"
            exact={false}
            component={Vendor(VMenu)}
          />
          {/* <Route path="/owner-admin/" exact={false} component={Owner(OwnerMenu)} /> */}
        </div>
        <div
          className={
            this.state.toggleMenuBar === true
              ? "FullMenu d-block d-sm-block d-md-none"
              : "d-block d-sm-block d-md-none"
          }
        >
          <Route path="/owner-admin/" exact={false} component={Owner(OMenu)} />
          <Route
            path="/customer-admin/"
            exact={false}
            component={Customer(CMenu)}
          />
          <Route
            path="/vendor-admin/"
            exact={false}
            component={Vendor(VMenu)}
          />
        </div>
      </div>
    );
  }
}

export default Menu;
