const customerTransactReducer = (
  state = {
    customerTransact: [
      {
        id: '83721',
        channel: 'Card',
        transationNos: 'Romiro@kobo360.com',
        vendor: 'Oando',
        amount: 'N12,000',
        product: 'PMS',
        rate: 'N124',
        transationTime: '12/12/2019, 12:42pm',
        terminalNos: '1209831872',
        terminalUser: 'Jon Doe',
        holder: 'Success Tony',
        vendorId: 'VEN98732',
        customer: 'Kobo360',
        channelId: '987326813',
        location: 'MaryLand, Lagos',
        status: 'Success',
      },
      {
        id: '8378821',
        channel: 'Card',
        transationNos: 'Romiro@kobo360.com',
        vendor: 'Oando',
        amount: 'N12,000',
        product: 'PMS',
        rate: 'N124',
        transationTime: '12/12/2019, 12:42pm',
        terminalNos: '1209831872',
        terminalUser: 'Jon Doe',
        holder: 'Success Tony',
        vendorId: 'VEN98732',
        customer: 'Kobo360',
        channelId: '987326813',
        location: 'MaryLand, Lagos',
        status: 'Success',
      },
      {
        id: '837654121',
        channel: 'Card',
        transationNos: 'Romiro@kobo360.com',
        vendor: 'Oando',
        amount: 'N12,000',
        product: 'PMS',
        rate: 'N124',
        transationTime: '12/12/2019, 12:42pm',
        terminalNos: '1209831872',
        terminalUser: 'Jon Doe',
        holder: 'Success Tony',
        vendorId: 'VEN98732',
        customer: 'Kobo360',
        channelId: '987326813',
        location: 'MaryLand, Lagos',
        status: 'Success',
      },
      {
        id: '837211121',
        channel: 'Card',
        transationNos: 'Romiro@kobo360.com',
        vendor: 'Oando',
        amount: 'N12,000',
        product: 'PMS',
        rate: 'N124',
        transationTime: '12/12/2019, 12:42pm',
        terminalNos: '1209831872',
        terminalUser: 'Jon Doe',
        holder: 'Success Tony',
        vendorId: 'VEN98732',
        customer: 'Kobo360',
        channelId: '987326813',
        location: 'MaryLand, Lagos',
        status: 'Success',
      },
      {
        id: '837200001',
        channel: 'Card',
        transationNos: 'Romiro@kobo360.com',
        vendor: 'Oando',
        amount: 'N12,000',
        product: 'PMS',
        rate: 'N124',
        transationTime: '12/12/2019, 12:42pm',
        terminalNos: '1209831872',
        terminalUser: 'Jon Doe',
        holder: 'Success Tony',
        vendorId: 'VEN98732',
        customer: 'Kobo360',
        channelId: '987326813',
        location: 'MaryLand, Lagos',
        status: 'Success',
      },
      {
        id: '8374320021',
        channel: 'Card',
        transationNos: 'Romiro@kobo360.com',
        vendor: 'Oando',
        amount: 'N12,000',
        product: 'PMS',
        rate: 'N124',
        transationTime: '12/12/2019, 12:42pm',
        terminalNos: '1209831872',
        terminalUser: 'Jon Doe',
        holder: 'Success Tony',
        vendorId: 'VEN98732',
        customer: 'Kobo360',
        channelId: '987326813',
        location: 'MaryLand, Lagos',
        status: 'Success',
      },
      {
        id: '8322222721',
        channel: 'Card',
        transationNos: 'Romiro@kobo360.com',
        vendor: 'Oando',
        amount: 'N12,000',
        product: 'PMS',
        rate: 'N124',
        transationTime: '12/12/2019, 12:42pm',
        terminalNos: '1209831872',
        terminalUser: 'Jon Doe',
        holder: 'Success Tony',
        vendorId: 'VEN98732',
        customer: 'Kobo360',
        channelId: '987326813',
        location: 'MaryLand, Lagos',
        status: 'Success',
      },
      {
        id: '8339966721',
        channel: 'Card',
        transationNos: 'Romiro@kobo360.com',
        vendor: 'Oando',
        amount: 'N12,000',
        product: 'PMS',
        rate: 'N124',
        transationTime: '12/12/2019, 12:42pm',
        terminalNos: '1209831872',
        terminalUser: 'Jon Doe',
        holder: 'Success Tony',
        vendorId: 'VEN98732',
        customer: 'Kobo360',
        channelId: '987326813',
        location: 'MaryLand, Lagos',
        status: 'Success',
      },
    ],
    fetching: false,
    fetched: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case 'FETCH_USERS': {
      return { ...state, fetching: true };
    }
  }
  return state;
};

export default customerTransactReducer;
