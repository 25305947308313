import React, {useState, useEffect} from "react";
import {Modal, ModalBody} from "reactstrap";
import {
  BackSVG,
  UploadSVG,
  CancelSVG,
  AvatarSVG,
  LoadingSVG,
  UserCompleteMarkSVG,
} from "../../../../assets/Svgs/Svgs";
import CustomerSettings from "./CustomerSettings";
import EditForm from "./EditCustomer";
import {useDispatch} from "react-redux";
import FileBase64 from "react-file-base64";
import {resetCustomerCreation} from "../../../../actions/OwnerActions/OwnerCustomerMgtActions/OwnerCustomerActions";

const CreateCustomer = (props) => {
  const [showSettings, setShowSettings] = useState(false);

  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  const [showCreateSuccessModal, setShowCreateSuccessModal] = useState(false);
  const [profilePix, setProfilePix] = useState("");
  const [customerCreationError, setCustomerCreationError] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setIsCreatingCustomer(false);
    setShowCreateSuccessModal(false);
  };

  useEffect(() => {
    setIsCreatingCustomer(props.oCustomerReducer.isCreatingCustomer);
  }, [props.oCustomerReducer.isCreatingCustomer]);

  useEffect(() => {
    if (props.oCustomerReducer.fetchingStatus === 1) {
      setShowCreateSuccessModal(true);
    } else if (props.oCustomerReducer.fetchingStatus === 2) {
      setCustomerCreationError(true);
    }
  }, [props.oCustomerReducer.fetchingStatus]);

  useEffect(() => {
    setIsCreatingCustomer(false);
    setShowCreateSuccessModal(false);
    dispatch(resetCustomerCreation());
  }, []);

  const toggleSettings = () => setShowSettings(!showSettings);

  return (
    <>
      <>
        <Modal
          isOpen={showSettings}
          backdrop="static"
          toggle={toggleSettings}
          className="settings-modal"
        >
          <div className="settings-modal-header">
            <p className="title">
              Customer Settings
              <i onClick={toggleSettings}>
                <CancelSVG />
              </i>
            </p>
            <p className="subtitle">
              You can monitor customer activities enable/disable features
            </p>
          </div>
          <ModalBody>
            <CustomerSettings />
          </ModalBody>
        </Modal>
      </>

      <>
        <Modal
          isOpen={isCreatingCustomer || showCreateSuccessModal}
          toggle={toggleModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="user-modal"
        >
          <ModalBody>
            {isCreatingCustomer && (
              <>
                <div className="text-center mt-5 header">
                  Creating customer....
                </div>
                <div className="text-center mt-5 mb-5">
                  <LoadingSVG />
                </div>
              </>
            )}
            {showCreateSuccessModal && (
              <>
                <div className="text-center mt-5 mb-4">
                  <span
                    className="d-block font-weight-bold"
                    style={{
                      fontSize: "20px",
                      color: "#4B4B4B",
                      margin: "1rem",
                    }}
                  >
                    Thank you! You are all set!
                  </span>
                  <UserCompleteMarkSVG />
                  <span
                    className="d-block quickteller-blue font-weight-bold"
                    style={{
                      fontSize: "1rem",
                      color: "#00425F",
                      margin: "1.25rem",
                    }}
                  >
                    Your new customer profile has been successfully created
                  </span>
                </div>
                <div className="row modal-action-buttons">
                  <div className="col-6">
                    <button className="back" onClick={props.onCreatingDone}>
                      Back
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="create"
                      onClick={() => {
                        setResetForm(true);
                        toggleModal();
                        setProfilePix("");
                      }}
                    >
                      Create new
                    </button>
                  </div>
                </div>
                <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
              </>
            )}
          </ModalBody>
        </Modal>
      </>
      <div className="underlined-tab">
        <span className="tab-title">Create new customer</span>
        <div className="action-buttons">
          <button className="export-btn" onClick={props.backFunction}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className="row customer-details">
        <div className="col-3 profile-picture-and-actions">
          {!profilePix ? (
            <div className="avatar">
              <AvatarSVG />
            </div>
          ) : (
            <img
              src={profilePix.base64} alt="profile"
              width="100px"
              height="100px"
              style={{borderRadius: 50}}
            />
          )}
          <div className="upload">
            <i>
              <UploadSVG />
            </i>{" "}
            <FileBase64
              multiple={false}
              onDone={(file) => setProfilePix(file)}
            />
          </div>
          <button
            className="button remove-btn"
            onClick={() => {
              setProfilePix("");
            }}
          >
            Remove
          </button>
        </div>
        <EditForm
          {...props}
          create={true}
          resetForm={resetForm}
          profilePix={profilePix}
          customerCreationError={customerCreationError}
          customerCreationErrorHandler={(status) =>
            setCustomerCreationError(status)
          }
        />
      </div>
    </>
  );
};

export default CreateCustomer;
