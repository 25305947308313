import React, { useState, useEffect } from "react";
import FormWarning from "../../../admin/shared/FormWarning";
import PermissionBadge from "../../subcomponents/PermissionBadge/PermissionBadge";
import { SearchSvg } from "../../../../assets/Svgs/Svgs";
import { countries } from "../../../../lib/Dropdowns/countries";
// import formatAmount from "../../../../lib/Functions/formatAmount";
import { useDispatch, batch } from "react-redux";
import {
  ownerPermissions, customerPermissions, vendorPermissions
} from "../../../admin/shared/Permission";
import Checkbox from "./Checkbox";
import {
  submitUserForm,
  resetUserCreation,
  updateUserForm,
  resetUserUpdate
} from "../../../../actions/OwnerActions/OwnerUserMgtActions/OwnerUserActions";
import validator from "validator";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import CustomInput from "../../../../lib/Components/CustomInput";

const EditForm = ({
  create,
  authentication,
  profilePix,
  userCreationError,
  userCreationErrorHandler,
  ownerUsers,
  resetForm,
  userToBeEdited,
  saveEditForm,
  onSaveEditFormHandler
}) => {
  const condition = userToBeEdited && userToBeEdited.address;
  const formObj = {
    firstName: (userToBeEdited && userToBeEdited.firstName) || "",
    lastName: (userToBeEdited && userToBeEdited.lastName) || "",
    userEmail: (userToBeEdited && userToBeEdited.userEmail) || "",
    secret: (userToBeEdited && userToBeEdited.secret) || "",
    type: (userToBeEdited && userToBeEdited.type) || "",
    userLevel: (userToBeEdited && userToBeEdited.userLevel) || "",
    owner: null,
    image: (userToBeEdited && userToBeEdited.image) || "",
    contact: {
      email: (userToBeEdited && userToBeEdited.userEmail) || "",
      phoneNumber: (userToBeEdited && userToBeEdited.contact.phoneNumber) || ""
    },
    address: {
      street: (condition && userToBeEdited.address.street) || "",
      city: (condition && userToBeEdited.address.city) || "",
      state: (condition && userToBeEdited.address.state) || "",
      country: (condition && userToBeEdited.address.country) || ""
    },
    permissions:
      (userToBeEdited && userToBeEdited.permissions.map(val => val.name)) || []
  };

  const { owner, customer, vendor, type } = !!userToBeEdited ? userToBeEdited : authentication.user;
  if (type === "OWNER_USER") formObj.owner = owner;
  else if (type === "CUSTOMER_USER") formObj.customer = customer;
  else if (type === "VENDOR_USER") formObj.vendor = vendor;
  formObj.type = type;
  // formObj.owner = authentication.user.id;
  
  const applicablePermissions = (type === "OWNER_USER") ? ownerPermissions :
    ((type === "CUSTOMER_USER") ? customerPermissions : ((type === "VENDOR_USER") ? vendorPermissions : []));

  const [formData, setFormData] = useState({...formObj});
  const [formatError, setFormatError] = useState("");
  const [showPermissionList, setShowPersmissionList] = useState(false);

  useEffect(() => {
    setFormData({ ...formData, image: profilePix.base64 });
  }, [profilePix]);

  useEffect(() => {
    if (userCreationError === true) {
      setFormatError(ownerUsers.userCreationErrorMessage);
      userCreationErrorHandler(false);
    }
  }, [userCreationError]);

  useEffect(() => {
    batch(() => {
      dispatch(resetUserCreation());
      dispatch(resetUserUpdate());
    });
  }, []);

  useEffect(() => {
    formObj.image = "";
    if (resetForm) setFormData(formObj);
  }, [resetForm]);

  useEffect(() => {
    if (saveEditForm) {
      handleCreateUser();
      onSaveEditFormHandler(false);
    }
  }, [saveEditForm]);

  const availablePermissions = applicablePermissions.map((val, i) => {
    return (
      <div className="permission-dropdown-item" key={i}>
        <span>
          <Checkbox
            val={val}
            name={i}
            selectedPermissions={formData.permissions}
            onSelectPermission={({ target }) => {
              if (target.checked) {
                if (!formData.permissions.includes(val)) {
                  let arr = [...formData.permissions];
                  arr.push(val);
                  setFormData({ ...formData, permissions: arr });
                }
              } else if (!target.checked) {
                setFormData({
                  ...formData,
                  permissions: formData.permissions.filter(permission => {
                    return permission !== val;
                  })
                });
              }
            }}
          />{" "}
          {val}
        </span>
      </div>
    );
  });

  const getSelectedPermissions = () => {
    return formData.permissions.map((val, i) => (
      <PermissionBadge
        edit={true}
        key={i}
        permission={val}
        onDeleteHandler={permission =>
          setFormData({
            ...formData,
            permissions: formData.permissions.filter(val => val !== permission)
          })
        }
      />
    ));
  };

  const dispatch = useDispatch();

  const validationRules = () => {
    return {
      firstName: val =>
        val.length < 3 ? `First name must be longer than 2 letters!` : "",
      lastName: field =>
        field.length < 3 ? `Last name must be longer than 2 letters!` : "",
      secret: val =>
        val.length < 6 ? `Password length  must be longer than 5!` : "",
      userEmail: val =>
        !validator.isEmail(val) ? "Valid email address is required!" : "",
      phoneNumber: val =>
        !validator.isMobilePhone(val) ? "Valid phone number is required!" : "",
      image: val =>
        val.type !== "image/jpeg" || val.type !== "image/png"
          ? "Invalid image format!"
          : "",
      userLevel: val => (!val.length ? "User level is required!" : ""),
      street: val => (val.length < 3 ? "A valid street name is required!" : ""),
      city: val => (val.length < 3 ? "A valid city name is required!" : ""),
      state: val => (val.length < 3 ? "A valid state name is required!" : ""),
      country: val =>
        val.length < 3 ? "A valid country name is required!" : "",
      permissionLevel: val =>
        !val.length ? "Permission level is required!" : ""
    };
  };

  const isValid = () => {
    let response = true;
    let formatError = "";
    const firstnameErr = validationRules().firstName(formData.firstName);
    const lastnameErr = validationRules().lastName(formData.lastName);
    const emailErr = validationRules().userEmail(formData.userEmail);
    const phoneErr = validationRules().phoneNumber(
      formData.contact.phoneNumber
    );
    const userLevelErr = validationRules().userLevel(formData.userLevel);
    const permissionLevelErr = validationRules().permissionLevel(
      formData.permissions
    );
    const streetErr = validationRules().street(formData.address.street);
    const cityErr = validationRules().city(formData.address.city);
    const stateErr = validationRules().state(formData.address.state);
    const countryErr = validationRules().country(formData.address.country);

    if (!!firstnameErr) {
      formatError = firstnameErr;
      response = false;
    } else if (lastnameErr) {
      formatError = lastnameErr;
      response = false;
    } else if (emailErr) {
      formatError = emailErr;
      response = false;
    } else if (phoneErr) {
      formatError = phoneErr;
      response = false;
    } else if (userLevelErr) {
      formatError = userLevelErr;
      response = false;
    } else if (permissionLevelErr) {
      formatError = permissionLevelErr;
      response = false;
    } else if (streetErr) {
      formatError = streetErr;
      response = false;
    } else if (cityErr) {
      formatError = cityErr;
      response = false;
    } else if (stateErr) {
      formatError = stateErr;
      response = false;
    } else if (countryErr) {
      formatError = countryErr;
      response = false;
    }

    setFormatError(formatError);
    return response;
  };

  const handleInput = (target, e) => {
    if (target === "userEmail") {
      setFormData({
        ...formData,
        userEmail: e.target.value,
        secret: e.target.value,
        contact: { ...formData.contact, email: e.target.value }
      });
      return;
    }

    if (target === "phone") {
      setFormData({
        ...formData,
        contact: { ...formData.contact, phoneNumber: e.target.value }
      });
      return;
    }

    if (target === "userLevel") {
      setFormData({
        ...formData,
        userLevel: e.target.value
      });
      return;
    }

    if (
      target === "street" ||
      target === "city" ||
      target === "state" ||
      target === "country"
    ) {
      setFormData({
        ...formData,
        address: { ...formData.address, [target]: e.target.value }
      });
      return;
    }

    setFormData({ ...formData, [target]: e.target.value });
  };

  const handleCreateUser = () => {
    setFormData({
      ...formData,
      name: formData.firstName + " " + formData.lastName,
      // owner: 2
    });
    if (!isValid()) return;

    // eslint-disable-next-line no-lone-blocks
    {
      !!create
        ? dispatch(submitUserForm(formData))
        : dispatch(updateUserForm(formData, userToBeEdited.id));
    }
  };


  return (
    <>
      <div className="row">
        <div className="form-group col-3">
          <label>First Name</label>
          <input
            type="text"
            value={formData.firstName}
            onChange={handleInput.bind(this, "firstName")}
            className="form-control"
          />
        </div>
        <div className="form-group col-3">
          <label>Last Name</label>
          <input
            type="text"
            value={formData.lastName}
            onChange={handleInput.bind(this, "lastName")}
            className="form-control"
          />
        </div>
        <div className="form-group col-3">
          <label>Phone Number</label>
          <input
            type="phone"
            value={formData.contact.phoneNumber}
            onChange={handleInput.bind(this, "phone")}
            className="form-control"
          />
        </div>
        <CustomInput
          presetData="User Level"
          data={[
            {name: "Admin", value: "ADMIN"},
            {name: "User", value: "USER"},
          ].map((val, i) => {
            return (
              <option value={val.value} key={i}>
                {val.name}
              </option>
            );
          })}
          parentClass="form-group col-3"
          label="User Level"
          value={formData.userLevel}
          isSelect={true}
          handleInput={handleInput.bind(this, "userLevel")}
        />
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label>Email</label>
          <input
            type="email"
            value={formData.userEmail}
            onChange={
              (create && handleInput.bind(this, "userEmail")) || undefined
            }
            className="form-control"
            disabled={!create}
          />
        </div>

        <div className="form-group col-3">
          <label>Street</label>
          <input
            type="text"
            value={formData.address.street}
            onChange={handleInput.bind(this, "street")}
            className="form-control"
          />
        </div>

        <div className="form-group col-3">
          <label>City</label>
          <input
            type="text"
            value={formData.address.city}
            onChange={handleInput.bind(this, "city")}
            className="form-control"
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-3">
          <label>State</label>
          <input
            type="text"
            value={formData.address.state}
            onChange={handleInput.bind(this, "state")}
            className="form-control"
          />
        </div>
        <CustomInput
          presetData="Select Country..."
          data={countries}
          parentClass="form-group col-3"
          label="Country"
          value={formData.address.country}
          isSelect={true}
          handleInput={handleInput.bind(this, "country")}
        />
      </div>

      <div className="row edit-permissions permissions">
        <span className="overview-title">
          Permissions
          <FormWarning
            inline={true}
            message=" Permission determines the rights of a users"
          />
        </span>
        <div style={{width: "100%"}}>
          {!showPermissionList && (
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search a permission"
                onFocus={() => {
                  setShowPersmissionList(true);
                }}
                className="menu-input"
              />
              <i className="input-icon">
                <SearchSvg />
              </i>
            </div>
          )}
          {showPermissionList && (
            <div className="permission-dropdown">
              {
                <div className="permission-dropdown-title">
                  Search a permission{" "}
                  <span
                    style={{float: "right", cursor: "pointer"}}
                    onClick={setShowPersmissionList.bind(this, false)}
                  >
                    Done
                  </span>
                </div>
              }
              {availablePermissions}
            </div>
          )}
        </div>
        {!showPermissionList && getSelectedPermissions()}
      </div>

      <ToastAlert
        showToast={!!formatError}
        parentClass={"col-12 pb-3 pl-0"}
        childClass={""}
        toastText={formatError}
        onCloseHandler={() => {
          setFormatError("");
        }}
      />

      {create && (
        <div className="user-create-btn">
          <button onClick={handleCreateUser}>Create User</button>
        </div>
      )}
    </>
  );
};

export default EditForm;
