import React, {useState, useEffect} from "react";
import {Modal, ModalBody} from "reactstrap";
import {
  BackSVG,
  ActiveMarkSVG,
  WarningIcon,
  Payment,
  GrayMark,
  CancelSVG,
  AvatarSVG,
  DisabledMark,
  LoadingSVG,
  UserCompleteMarkSVG,
} from "../../../../assets/Svgs/Svgs";
import CustomerSettings from "./CustomerSettings";
import EditForm from "./EditCustomer";
import {formatDate3} from "../../../../lib/Functions/formatDate";
import formatAmount from "../../../../lib/Functions/formatAmount";
import {
  resetCustomerUpdate,
  toggleCustomerStatus,
} from "../../../../actions/OwnerActions/OwnerCustomerMgtActions/OwnerCustomerActions";
import {
  getSchemesByCus,
  toggleDeactivateAccountStatus,
  toggleActivateAccountStatus,
  resetAccountStatus,
} from "../../../../actions/CustomerActions/CardMgtActions/CardAction";
import {useDispatch} from "react-redux";
import {getTransactionsByCustomer} from "../../../vendorModule/redux/actions/CustomerActions";
import moment from "moment";
import TransactionReport from "./TransactionReport";
import ContractManagement from "./ContractManagement";

const CustomerProfile = (props) => {
  const dispatch = useDispatch();
  const [showAccounts, setShowAccounts] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const {selectedCustomer} = props;
  const {
    name,
    contact,
    rcNumber,
    address,
    createdAt,
    number,
    status,
    contracts,
    image,
  } = selectedCustomer;
  const [saveEditForm, setSaveEditForm] = useState(false);
  const [profilePix, setProfilePix] = useState(image || "");// eslint-disable-line no-unused-vars
  const [customerCreationError, setCustomerCreationError] = useState(false);
  const toggleSettings = () => setShowSettings(!showSettings);

  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  const [showCreateSuccessModal, setShowCreateSuccessModal] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [
    showCustomerTransactionReport,
    setShowCustomerTransactionReport,
  ] = useState(false);

  const customer = selectedCustomer,
    contractAvailability = customer.contracts ? customer.contracts.length : "";
  let boundArr = [],
    inactiveArr = [],
    // vendor = selectedCustomer,
    /* contractNo = vendor
      ? vendor.contracts.forEach((e) => {
          boundArr.push(e.bound);
        })
      : [],
    contractsArr = vendor ? vendor.contracts : [],
    inactives = vendor
      ? vendor.contracts.forEach((e) => {
          if (e.contractStatus === "INACTIVE") {
            inactiveArr.push(e.contractStatus);
          }
        })
      : [], */
    // contractAvailability = vendor ? vendor.contracts.length : '',
    sum = boundArr.reduce((a, b) => a + b, 0);

  const toggleModal = () => {
    setIsCreatingCustomer(false);
    setShowCreateSuccessModal(false);
  };

  useEffect(() => {
    setIsCreatingCustomer(props.oCustomerReducer.isCreatingCustomer);
  }, [props.oCustomerReducer.isCreatingCustomer]);

  useEffect(() => {
    if (props.oCustomerReducer.fetchingStatus === 1) {
      setShowCreateSuccessModal(true);
    } else if (props.oCustomerReducer.fetchingStatus === 2) {
      setCustomerCreationError(true);
    }
  }, [props.oCustomerReducer.fetchingStatus]);

  useEffect(() => {
    setIsCreatingCustomer(false);
    setShowCreateSuccessModal(false);
    dispatch(resetCustomerUpdate());
    dispatch(getSchemesByCus(customer.number));
    getCustomerTransaction();
  }, []);

  useState(() => {}, [props.customerCards.data]);

  !!props.customerCards.errorMessage &&
    setTimeout(() => {
      dispatch(resetAccountStatus());
    }, 5000);

  function getCustomerTransaction(pageNo = 1, pageSize = 30) {
    const startDate = new Date(moment().subtract(30, "days").format("LLLL")),
      endDate = new Date(),
      start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    const filterParams = {vendorNum: null, location: null};
    dispatch(
      getTransactionsByCustomer(
        customer.number,
        start,
        end,
        pageNo,
        pageSize,
        2,
        filterParams
      )
    );
  }

  const allAccounts =
    props.customerCards.allSchemesByCus &&
    props.customerCards.allSchemesByCus.map((val, i) => {
      const {title, accountNumber, limit, status} = val;
      return (
        <tr key={i}>
          <td>{title}</td>
          <td>{accountNumber}</td>
          <td>₦ {formatAmount(limit)}</td>
          {/* <td>Active <i><ActiveMarkSVG /></i></td> */}
          <td>
            {status === 1 ? "Active " : "Inactive "}
            <i>{status === 1 ? <ActiveMarkSVG /> : <DisabledMark />}</i>
          </td>
          {/* <td className="deactivate">Deactivate</td> */}
          <td
            className={status === 1 ? "Deactivate" : "Activate"}
            onClick={() => {
              status === 1
                ? dispatch(toggleDeactivateAccountStatus(accountNumber))
                : dispatch(toggleActivateAccountStatus(accountNumber));
            }}
          >
            {props.customerCards.isSubmitting ? (
              <img
                src="/images/ActivityIndicator.gif" alt="activity-indicator"
                style={{height: 20, width: 20, marginTop: 0}}
              />
            ) : status === 1 ? (
              "Deactivate"
            ) : (
              "Activate"
            )}
          </td>
        </tr>
      );
    });

  return (
    <>
      <>
        <Modal
          isOpen={isCreatingCustomer || showCreateSuccessModal}
          toggle={toggleModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="user-modal"
        >
          <ModalBody>
            {isCreatingCustomer && (
              <>
                <div className="text-center mt-5 header">
                  Editing customer....
                </div>
                <div className="text-center mt-5 mb-5">
                  <LoadingSVG />
                </div>
              </>
            )}
            {showCreateSuccessModal && (
              <>
                <div className="text-center mt-5 mb-4">
                  <span
                    className="d-block font-weight-bold"
                    style={{
                      fontSize: "20px",
                      color: "#4B4B4B",
                      margin: "1rem",
                    }}
                  >
                    Thank you! You are all set!
                  </span>
                  <UserCompleteMarkSVG />
                  <span
                    className="d-block quickteller-blue font-weight-bold"
                    style={{
                      fontSize: "1rem",
                      color: "#00425F",
                      margin: "1.25rem",
                    }}
                  >
                    Your customer profile has been successfully edited
                  </span>
                </div>
                <div className="row modal-action-buttons">
                  <div className="col-12 text-center">
                    <button
                      className="back"
                      onClick={() => {
                        props.onEditingDone();
                      }}
                      style={{width: 120}}
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
              </>
            )}
          </ModalBody>
        </Modal>
      </>

      <>
        <Modal
          isOpen={showSettings}
          backdrop="static"
          toggle={toggleSettings}
          className="settings-modal"
        >
          <div className="settings-modal-header">
            <p className="title">
              Customer Settings
              <i onClick={toggleSettings}>
                <CancelSVG />
              </i>
            </p>
            <p className="subtitle">
              You can monitor customer activities enable/disable features
            </p>
          </div>
          <ModalBody>
            <CustomerSettings />
          </ModalBody>
        </Modal>
      </>

      <>
        <Modal
          isOpen={showTransactionDetails}
          // show={showContractDetails}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          dialogClassName="rfid-modal"
        >
          <ModalBody>
            <ContractManagement
              contracts={selectedCustomer.contracts}
              onCloseHandler={() => setShowTransactionDetails(false)}
            />
          </ModalBody>
        </Modal>
      </>

      <div className="underlined-tab">
        <span className="tab-title">Customer Profile</span>
        <div className="action-buttons">
          <button className="export-btn" onClick={props.backFunction}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className="row customer-details">
        <div className="col-3 profile-picture-and-actions">
          <div className="avatar">
            {!!profilePix ? (
              <img
                alt="Profile Face"
                src={profilePix.base64 || profilePix}
                style={{width: 100, height: 100, borderRadius: 50}}
              />
            ) : (
              <AvatarSVG />
            )}
          </div>
          {/* {showEditForm && (
            <>
              <div className="upload">
                <i>
                  <UploadSVG />
                </i>{" "}
                <FileBase64
                  multiple={false}
                  onDone={file => {
                    if (file.type === "image/jpeg" || file.type === "image/png")
                      setProfilePix(file);
                  }}
                />
              </div>
              <button
                className="button remove-btn"
                onClick={() => setProfilePix("")}
              >
                Remove
              </button>
            </>
          )} */}
          {!showEditForm && (
            <button
              className="button edit-profile-btn"
              onClick={() => setShowEditForm(true)}
            >
              Edit Profile
            </button>
          )}
          {!showEditForm && (
            <button
              className={`button ${
                customer.status === "ACTIVE" ? "suspend-btn" : "activate-btn"
              }`}
              onClick={() => {
                dispatch(
                  toggleCustomerStatus(
                    customer.id,
                    customer.status === "ACTIVE" ? "DISABLED" : "ACTIVE"
                  )
                );
              }}
            >
              {props.oCustomerReducer.isUpdatingCustomer ? (
                <img
                  src="/images/ActivityIndicator.gif" alt="activity-indicator"
                  style={{height: 20, width: 20, marginTop: 0}}
                />
              ) : (
                `${
                  customer.status !== "ACTIVE" ? "Activate" : "Suspend"
                } Account`
              )}
            </button>
          )}
          {showEditForm && (
            <button
              className="button save-btn"
              onClick={() => setSaveEditForm(true)}
            >
              Save changes
            </button>
          )}
          {!showEditForm && (
            <button className="button registered-tags-btn">
              Registered Tags
            </button>
          )}
          {!showEditForm && (
            <button
              className="button settings-btn"
              onClick={() => setShowSettings(!showSettings)}
            >
              Settings
            </button>
          )}
        </div>

        {!showEditForm && (
          <div className="col-9">
            {!showCustomerTransactionReport && (
              <>
                <div className="row user-info">
                  <div className="row user-info-title">
                    <div className="col-3">Name</div>
                    <div className="col-3">Email</div>
                    <div className="col-3">Phone</div>
                    <div className="col-3">RC</div>
                  </div>
                  <div className="row user-info-details">
                    <div className="col-3">{name || ""}</div>
                    <div className="col-3">{contact.email || ""}</div>
                    <div className="col-3">{contact.phoneNumber || ""}</div>
                    <div className="col-3">{rcNumber || ""}</div>
                  </div>
                </div>
                <div className="row user-extras">
                  <div className="row user-extras-title">
                    <div className="col-3">Date Created</div>
                    <div className="col-3">Custumer Number</div>
                    <div className="col-3">Status</div>
                  </div>
                  <div className="row user-extras-detail">
                    <div className="col-3">{formatDate3(createdAt)}</div>
                    <div className="col-3">{number}</div>
                    <div className="col-3">
                      {status === "ACTIVE" ? "Active " : `Disabled `}
                      {status === "ACTIVE" ? (
                        <ActiveMarkSVG />
                      ) : (
                        <DisabledMark />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row user-address">
                  <div className="row user-address-title">
                    <div className="col-3">Street</div>
                    <div className="col-3">City</div>
                    <div className="col-3">State</div>
                    <div className="col-3">Country</div>
                  </div>
                  <div className="row user-address-details">
                    <div className="col-3">{address.street}</div>
                    <div className="col-3">{address.city}</div>
                    <div className="col-3">{address.state}</div>
                    <div className="col-3">{address.country}</div>
                  </div>
                </div>
                <div className="row user-overview">
                  <span className="overview-title">Customer Overview</span>
                  <div className="inner">
                    <div className="row customer-stats">
                      <div className="col-3 credit-details">
                        <div className="row credit-title">CREDIT OBTAINED</div>
                        <div className="row amount">₦ {formatAmount(sum)}</div>
                      </div>
                      <div className="col-9 stats">
                        <div className="row">
                          <div className="col-3">CONTRACTS</div>
                          <div className="col-3">VENDORS</div>
                          <div className="col-6">INACTIVE CONTRACTS</div>
                        </div>
                        <div className="row numbers">
                          <div className="col-3">{contracts.length}</div>
                          <div style={{ left: '27%'}} className="customer-profile-short-border-1"></div>
                          <div className="col-3">{contractAvailability}</div>
                          <div className="short-borders-2"></div>
                          <div className="col-6">{inactiveArr.length}</div>
                        </div>
                      </div>
                    </div>
                    <div className="row contract-details">
                      <div className="warning">
                        <div className="message" style={{width: "60%"}}>
                          <WarningIcon />
                          Contract ends soon.
                        </div>
                        <span
                          className="btn-view-contract"
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            setShowTransactionDetails(true);
                          }}
                        >
                          View contract details
                        </span>
                      </div>
                    </div>
                    <div className="payment-icon">
                      <i>
                        <Payment />
                      </i>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row reporting">
              <span className="reporting-title">Reporting</span>
              <div className="inner">
                <div className="row">
                  <div className="settlement-report col-8">
                    View transaction and settlement reports at a glance
                  </div>
                  <div className="settlement-report-link col-4">
                    <a href="#a">
                      <i>
                        <GrayMark />
                      </i>
                      View Settlement Report
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="settlement-report col-8">
                    {!showAccounts && (
                      <span
                        onClick={() => {
                          setShowAccounts(true);
                          setShowCustomerTransactionReport(false);
                        }}
                      >
                        View Accounts
                      </span>
                    )}
                  </div>
                  <div className="settlement-report-link col-4">
                    <a
                      style={{cursor: "pointer"}} href="#b"
                      onClick={() => {
                        setShowCustomerTransactionReport(
                          !showCustomerTransactionReport
                        );
                        setShowAccounts(false);
                      }}
                    >
                      <i>
                        <GrayMark />
                      </i>
                      {showCustomerTransactionReport ? "Hide" : "View"}{" "}
                      Transaction Report
                    </a>
                  </div>
                </div>
              </div>
              {showCustomerTransactionReport && (
                <div className="col-12">
                  <TransactionReport
                    transactions={
                      props.oTrailReducer.cTransactions ||
                      []
                    }
                    onFetchTransactions={(pageNo, pageSize) => {
                      getCustomerTransaction(pageNo, pageSize);
                    }}
                  />
                </div>
              )}
              {showAccounts && (
                <div className="inner saved-accounts">
                  <div className="row">
                    <div className="header">
                      <a href="#cancel">
                        Saved Accounts{" "}
                        <i onClick={() => setShowAccounts(false)}>
                          <CancelSVG />
                        </i>
                      </a>
                    </div>
                  </div>
                  <div className="accounts-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <td className="accounts-table-title">Title</td>
                          <td className="accounts-table-title">
                            Account Number
                          </td>
                          <td className="accounts-table-title">Limit</td>
                          <td className="accounts-table-title">Status</td>
                          <td className="accounts-table-title"></td>
                        </tr>
                      </thead>
                      <tbody className="accounts-table-body">
                        {allAccounts.length > 0 ? (
                          allAccounts
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No account to be displayed
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            {!!props.customerCards.errorMessage && (
              <div
                className="col-12 pb-3 pl-0"
                style={{marginLeft: -15, paddingRight: 0}}
              >
                <div className={`error-container error-text `}>
                  <span
                    className="error-close"
                    onClick={() => dispatch(resetAccountStatus())}
                  >
                    x
                  </span>
                  <span className={``}>{props.customerCards.errorMessage}</span>
                </div>
              </div>
            )}
          </div>
        )}
        {showEditForm && (
          <div className="col-8 edit-form">
            <EditForm
              {...props}
              create={false}
              saveEditForm={saveEditForm}
              onSaveEditFormHandler={(status) => setSaveEditForm(status)}
              customerToBeEdited={customer}
              resetForm={false}
              profilePix={{base64: profilePix.base64 || profilePix}}
              customerCreationError={customerCreationError}
              customerCreationErrorHandler={(status) =>
                setCustomerCreationError(status)
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerProfile;
