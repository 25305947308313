import React, { useEffect, useMemo, useState } from 'react';
import NewAccountIcon from '../../../../assets/Svgs/NewAccountIcon';
import { ReactComponent as CopyIcon } from '../../../../assets/Svgs/CopyIcon.svg';
import { ReactComponent as MenuIcon } from '../../../../assets/Svgs/menu_icon.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/Svgs/download_icon.svg';
import { ReactComponent as ViewIcon } from '../../../../assets/Svgs/view_icon.svg';
import { ReactComponent as EmptyTableIcon } from '../../../../assets/Svgs/EmptyTableIcon.svg';
import { Modal, Dropdown } from 'react-bootstrap';
import { CloseSVG } from '../../../../assets/Svgs/Svgs';
import { ReactComponent as UserIcon } from '../../../../assets/Svgs/User.svg';
import Spinner from 'react-bootstrap/Spinner';
import { batch, useDispatch, useSelector } from 'react-redux';
import { createAccountNUmber, getAccountNumber, getInvoices } from '../../../customerModule/redux/action/paymentsAction';
import { Skeleton } from 'antd';
import { getUserProperties } from '../../../../lib/Constants/UserTypes';
import GenerateInvoice from './GenerateInvoice';
import { loadAllCustomerVendors } from '../../../customerModule/redux/action/VendorAction';
import { getCustomerContracts } from '../../../ownerModule/redux/action/OwnerContractsActions';
import InvoiceComponent from './InvoiceComponent';
import CustomToggle from '../../../../lib/Components/CustomToggle';
import ToastAlert from '../../../../lib/Components/ToastAlert';
// import { Alert, Pagination } from 'reactstrap';
import PaymentToolTip from '../../../../lib/Components/PaymentToolTip';
import formatAmount from '../../../../lib/Functions/formatAmount';
import { formatDate3 } from '../../../../lib/Functions/formatDate';
import Pagination2 from '../../../../lib/Components/Pagination2';
import Pagination from '../../../../lib/Components/Pagination';
import PaymentStatus from '../paymentUtil';


const Invoices = () => {
    const [showModal, setIsShowModal] = useState(false);
    const [showAcctfields, setShowAcctfields] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showInvoiceDetailModal, setShowInvoiceDetailModal] = useState(false);
    const [errorMessage, setError] = useState('');
    const [selectedInvoice, setSelectedInvoice] = useState('');
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10)

    const [vendorInfo, setVendorInfo] = useState({ vendorName: "" });

    const dispatch = useDispatch();
    const { customerVendor, oPaymentsReducer, oContractReducer } = useSelector(s => s);

    const { account, invoice } = oPaymentsReducer
    const { loading, error, success, creating, created, data: accountData = { count: 0, data: [] }, createData } = account;
    const { loading: invoiceLoading, success: invoiceLoaded, data: invoiceData } = invoice;
    ;
    const vendorList = customerVendor.allCustomerVendors ? customerVendor.allCustomerVendors?.data || [] : [];
    const contractList = oContractReducer.customerContracts ? oContractReducer.customerContracts : [];
    //  vendorList.length !== 0 && 
    const { customerNumber, customer, type, userLevel } = getUserProperties() || {};
    useEffect(() => {
        if (vendorList.length >= 1) {
            setVendorInfo({
                vendorName: vendorList[0].name,
                vendorNumber: vendorList[0].number,
            })
        }
    }, [vendorList])

    const invoiceList = useMemo(() => {
        if (invoiceData && invoiceData.data) {
            return invoiceData.data;
        }
    }, [invoiceData])

    useEffect(() => {
        if (created) dispatch(getAccountNumber());
    }, [created])

    let accountInfo = useMemo(() => {
        if (accountData.count > 0) {
            return accountData.data[0].account;
        }
    }, [accountData])

    useEffect(() => {
        if (createData && createData.data) {
            setSelectedInvoice(createData.data)
        }
    }, [createData])


    useEffect(() => {
        batch(() => {
            dispatch(loadAllCustomerVendors(customer));
            dispatch(getCustomerContracts(customer))
            dispatch(getAccountNumber());
            dispatch(getInvoices({ page, size }))
        })
    }, [])

    useEffect(()=>{
        dispatch(getInvoices({ page, size }));
    },[size])

    const handleGenerateAccountModal = () => {
        setIsShowModal(!showModal);
    }
    const handleShowInvoiceModal = () => {
        setShowInvoiceModal(!showInvoiceModal);
    }

    const handleInvoiceDetailModal = (item) => {
        setShowInvoiceDetailModal(!showInvoiceDetailModal)
        if (selectedInvoice) { setSelectedInvoice(null); return }
        setSelectedInvoice(item)
    }

    const handleInvoiceFetch = (page, size) => {
        setPage(page);
        dispatch(getInvoices({ page, size }))
    }

    const handleSizeUpdate = (e) =>{
      setSize(e)
      dispatch(getInvoices({ page, e }))
    }

    const submitGenerateAcount = () => {
        if (vendorInfo.vendorNumber) {
            dispatch(createAccountNUmber({ vendorNumber: vendorInfo.vendorNumber, customerNumber }))
            setError('')
        }
        else { setError('Waiting for vendor details') }
    }

    function copy(text) {
        navigator.clipboard.writeText(text)
    }
    return (<div className='container-2'>
        <div className='payment-box row'>
            <div className='col-4 pl-0'>
                <div className='box-upsection'>
                    <div>
                        <h2 className='payments-header'>Invoice</h2>
                        <p className='payments-sub'>
                            Make payments to your vendors directly on the platform and monitor the status of your payments
                        </p>
                    </div>
                    {/* <span className='payment-label'>Last updated 8, May 2023</span> */}
                </div>
            </div>
            {loading ? <div className='col-4 pt-0 pb-0'><Skeleton avatar={false} paragraph={{ rows: 3 }} /></div> :
                (!loading && accountData.count && <div className='col-4 pt-0 pb-0'>
                    <div className='account-box'>
                        <div className='account-row'>
                            <span className='payments-sub'>Vendor ID</span>
                            <span className='payments-sub dark'>{vendorInfo.vendorNumber}</span>
                        </div>
                        <div className='account-row'>
                            <span className='payments-sub'>Bank Name</span>
                            <span className='payments-sub dark'>{accountInfo.bankName}</span>
                        </div>
                        <div className='account-row'>
                            <span className='payments-sub'>Account Number</span>
                            <div style={{ position: 'relative' }}><span className='payments-sub blue mr-4'>{accountInfo.accountNumber}</span> <CopyIcon onClick={() => copy(accountInfo.accountNumber)} className='copy-icon' /></div>
                        </div>
                    </div>
                </div>)}
            {loading ? <div className='col-4 pr-0'> <Skeleton avatar={false} paragraph={{ rows: 3 }} /> </div> :
                (!accountData.count && !loading &&
                    <div className='col-4 pr-0'>
                        <PaymentToolTip title={'Click to generate account number specific to your vendor'}>
                            <div className='generate-btn' onClick={handleGenerateAccountModal}>
                                <NewAccountIcon />
                                <label>Generate account number</label>
                            </div>
                        </PaymentToolTip>
                    </div>
                )}
            <Modal scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal" show={showModal}>
                {/* <ModalHeader toggle={()=>{}}></ModalHeader> */}
                <Modal.Body style={{
                    padding: 0
                }}>
                    <div className='payment-modal-container'>
                        {!creating && <div className='payment-modal-header'> <span onClick={handleGenerateAccountModal}><CloseSVG className="modal-close-icon" /></span></div>}
                        <>
                            {(!creating && !created) && <div>
                                <h5 className='payment-modal-h'>Generate Account Number</h5>
                                <label className='payment-sub sub-14'>
                                    Generate a static account number to make payments to the vendors you’re mapped to.
                                </label>
                                <p className='acct-name-container'>
                                    <UserIcon /> <label className='payment-sub sub-14'>Vendor Name - {vendorInfo.vendorName ? vendorInfo.vendorName : 'fetching vendor info'}</label>
                                </p>

                                <span style={{ display: 'block', width: '100%', marginBottom: 20 }}>
                                    <button onClick={submitGenerateAcount} className='payment-button'>
                                        Generate Account Number
                                    </button>
                                </span>
                                <button onClick={handleGenerateAccountModal} className='white-button'>
                                    Skip For Now
                                </button>
                            </div>}
                        </>
                        {/* Spinner */}
                        {creating && <div className='payment-spinner-container'>
                            <Spinner style={{
                                width: 45,
                                height: 45
                            }} animation='border' variant="success" role="status">
                            </Spinner>
                            <p className='modal-spinner-text'> Generating account number...</p>
                        </div>}
                        {/* Success Account generated */}
                        {creating ? <Skeleton avatar={false} paragraph={{ rows: 3 }} /> : (!creating && accountData.data.length > 0 && <div>
                            <h5 className='payment-modal-h mb-3'>Vendor Account Details</h5>
                            <div className='acct-section mb-4'>
                                <div className='account-row mb-2'>
                                    <span className='payments-sub'>Vendor ID</span>
                                    <span className='payments-sub dark'>{vendorInfo.vendorNumber}</span>
                                </div>
                                <div className='account-row mb-2'>
                                    <span className='payments-sub'>Bank Name</span>
                                    <span className='payments-sub dark'>{accountData.data.bankName}</span>
                                </div>
                                <div className='account-row mb-2'>
                                    <span className='payments-sub'>Account Number</span>
                                    <div style={{ position: 'relative' }}><span className='payments-sub blue mr-4'>{accountData.data.accountNumber}</span> <CopyIcon onClick={() => copy(accountData.data[0].accountNumber)} className='copy-icon' /></div>
                                </div>
                            </div>
                            <p className='mb-4 text-center acct-terms'>
                                *The details above are static and specific to this vendor. Use them for all future payments*
                            </p>
                            <span style={{ display: 'block', width: '100%', marginTop: 20 }}>
                                <button onClick={handleGenerateAccountModal} className='payment-button'>
                                    Ok
                                </button>
                            </span>
                        </div>)}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
        <>
            <div className='d-flex justify-content-between align-items-center mt-5 mb-5'>
                <h2 className='payments-header'>Invoices</h2>
                <PaymentToolTip title="Click here to generate payment invoice">
                    <button onClick={handleShowInvoiceModal} className='payment-button max'>
                        Generate Payment Invoice
                    </button>
                </PaymentToolTip>
            </div>
            {/* Invoice Table */}

            {invoiceLoading && <Skeleton avatar={false} paragraph={{ rows: 8 }} />}
            {(!invoiceLoading && invoiceLoaded && invoiceList) ?
                <>
                    <div className="table-responsive payment-table-container">
                        <table className="table table-borderless">
                            <thead className='chart-table-head'>
                                <tr>
                                    <th scope="col">Invoice ID</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Invoiced Amount (N)</th>
                                    <th scope="col">Generated Date</th>
                                    <th scope="col">Payment Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoiceList.content.map((item, index) => {
                                        return (<tr key={`in-${index}`}>
                                            <td>{item.invoice.number ? item.invoice.number : ""}</td>
                                            <td>{item.paymentType ? item.paymentType : ""}</td>
                                            <td>N{item.invoice.amount ? formatAmount(item.invoice.amount * 100) : ''}</td>
                                            <td>{formatDate3(item.createdAt)}</td>
                                            <td>{item.invoice.transactionDate ? item.invoice.transactionDate : 'N/A'}</td>
                                            <td><PaymentStatus status={item.invoice.status}/>
                                            </td>
                                            <td><Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                                    <MenuIcon />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleInvoiceDetailModal(item)}><>
                                                        <ViewIcon /> View
                                                    </></Dropdown.Item>
                                                    {/* <Dropdown.Divider />
                                                <Dropdown.Item href="#"><><DownloadIcon /> Download</></Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown></td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                   
                </>
                : <> {!invoiceLoading && <div className='empty-table-container'>
                    <EmptyTableIcon />
                    <h3 className='empty-table-caption'>No Invoices Generated</h3>
                    <span className='text-center empty-table-text'>
                        You have not generated any invoice yet.<br /> Make transactions to get started!
                    </span>
                </div>}</>
            }
            {invoiceList && <div className='mt-5 mb-2'>
                <Pagination2 total={invoiceData?.count} current={page} pageSize={size} onChange={handleInvoiceFetch} onSizeChange={handleSizeUpdate} />
            </div>} 

            <GenerateInvoice
                vendorInfo={vendorInfo}
                toggleModal={handleShowInvoiceModal}
                contractList={contractList || []}
                showModal={showInvoiceModal}
                accountDetails={accountInfo || {}}
            />
            {selectedInvoice && <InvoiceComponent
                invoiceDetails={selectedInvoice}
                toggleModal={handleInvoiceDetailModal}
                showInvoice={showInvoiceDetailModal}
            />}

        </>
    </div>);
}
export default Invoices;

