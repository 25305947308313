import React, {useState} from "react";
import "../../../../assets/Styles/Dashboard.css";
import { useDispatch } from "react-redux";
import {ExportSVG} from "../../../../assets/Svgs/Svgs";
import {CSVLink} from "react-csv";
import {shortMonthNames, shortDayNames} from "../../../../lib/Functions/DateNames";
import formatAmount from "../../../../lib/Functions/formatAmount";
import Pagination from "../../../../lib/Components/Pagination";
import ProductDetails from "../ProductDetails";
import StationCreateProduct from "./StationCreateProduct";

const StationProductList = (props) => {
  const {
    oVendorReducer, 
    onFetchProducts, 
    pageSize,
  } = props;
  const {data=[], count} = oVendorReducer.vendorProduct || {};
  const [showProductDetails, setshowProductDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isEditProduct, setIsEditProduct] = useState(false);


  const ProductLists = data && data.map((value) => {
      const {id, name, number, rate, stationRate, createdAt} = value;
      const d = new Date(createdAt);

      return (
        <tr key={id}>
          <td className="table-description font-weight-bold">{name}</td>
          <td className="table-description font-weight-bold">{number}</td>
          <td className="table-description font-weight-bold">
            ₦ {formatAmount(rate * 100)}
            {/* amount in naira already */}
          </td>
          <td className="table-description font-weight-bold">
           {stationRate ? `₦ ${formatAmount(stationRate * 100)}` : 'N/A' }
          </td>
          <td className="table-description">
            {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
            {shortMonthNames[d.getMonth()]} {d.getFullYear()}
          </td>
          <td
            className="table-action"
            onClick={() => {
              setshowProductDetails(true);
              setSelectedProduct(value);
              props.onHideStatusBar(true);
            }}
          >
            View
          </td>
        </tr>
      );
    });

 
  
  return (
    <div className="disbursed-cards-container row">
      {!isEditProduct && !showProductDetails  && (
        <>
          <div
            className="menu-link"
            style={{marginBottom: 0, paddingBottom: 4}}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid",
              }}
            >
              All Products
            </span>
          </div>
          {!!data && (
            <CSVLink
              data={data}
              filename={"Products.csv"}
              className=""
              target="_blank"
            >
              <div
                className="contract-export-btn"
                style={{float: "right", right: 370}}
              >
                <span className="text-white">
                  Export CSV <ExportSVG />
                </span>
              </div>
            </CSVLink>
          )}
          <table className="table table-hover">
            <thead>
              <tr>
                <td>Name</td>
                <td>Product ID</td>
                <td>Price</td>
                <td>Station Price</td>
                <td>Date Created</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {!!data.length && ProductLists}
              {!data.length && (
                <tr>
                  <td colSpan={7} className="text-center">
                    No product to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            count={count}
            pageSize={pageSize}
            fetchMethod={(pageNo, pageSize) => {
              onFetchProducts(pageNo, pageSize);
            }}
          />
        </>
      )}

      {showProductDetails && (
        <ProductDetails
          {...props}
          selectedProduct={selectedProduct}
          editProduct={() => {
            setshowProductDetails(false);
            setIsEditProduct(true);
            // setIsCreateProduct(false);
          }}
          backFunction={() => {
            setSelectedProduct({});
            setshowProductDetails(false);
            props.onHideStatusBar(false);
          }}
        />
      )}

       <StationCreateProduct
        {...props}
        selectedProduct={selectedProduct}
        isEditProduct={isEditProduct}
        onBackHandler={() => {
          // setIsCreateProduct(false);
          setIsEditProduct(false);
          setshowProductDetails(false);
          props.onHideStatusBar(false);
        }}
      />
    </div>
  );
};

export default StationProductList;
