import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch } from "react-redux";
import { Select, Button } from 'antd';
import { FilterSVG, BackSVG } from "../../../assets/Svgs/Svgs";
import { StateList } from '../../../services/states';
import Pagination from "../../../lib/Components/Pagination";
import { fetchCustomerTerminal } from '../redux/action/OwnerVendorActions';
import toSentenceCase from '../../../lib/Functions/toSentenceCase';


export const LocationList = (props) => {
  const dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState("")
  const {  selectedVendor, oCustomerReducer } = props;
  const allTerminals = oCustomerReducer.customerVendorTerminals;
  let totalCount = allTerminals.count || 0;
  let pageSize = 20;
  let terminalData = allTerminals ? allTerminals.data : [];
  let terminalList = terminalData && terminalData.map((value, i) => {
    const {name, vendorName, address, state, status} = value;
    return (
      <tr key={i}>
        <td className="table-description font-weight-bold">{vendorName}</td>
        <td className="table-description font-weight-bold">{name}</td>
        <td className="table-description font-weight-bold">{address}</td>
        <td className="table-description">{state}</td>
        <td className={"table-status"}>
          <div
            className={`status-btn ${
              status === "ACTIVE" ? "success" : "error"
            }`}
          >
            {toSentenceCase(status)}
          </div>
        </td>
      </tr>
    );
  });
  useEffect(() => {
    dispatch(fetchCustomerTerminal(selectedVendor.id, 1, pageSize))
  }, []);


  return (
    <Fragment>
      <div className="col-12 m-0 p-0" style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <div
          className="menu-link"
          style={{ marginBottom: 0, paddingBottom: 4 }}
        >
          <span
            style={{
              color: "#000000",
              borderBottomColor: "#00425F",
              borderBottomWidth: 3,
              borderBottomStyle: "solid",
            }}
          >
            Vendor's Location
          </span>
        </div>
        <div>
          <span className="filter-tags-desc">Filters:</span>
          <Select
            style={{ width: 250 }}
            defaultValue={selectedState}
            className="mr-3"
            placeholder="Choose State"
            allowClear 
            showSearch
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={value => setSelectedState(value)}>
            <Select.Option value="">Select State</Select.Option>
            {StateList.map((value, key) => (
              <Select.Option value={value} key={key}>{value}</Select.Option>
            ))}
          </Select>
          <Button type="primary"
                  onClick={()=> dispatch(fetchCustomerTerminal(selectedVendor.id, 1, pageSize, selectedState))}>
             Search
          </Button>
        </div>
        <div style={{
          // float: 'right'
        }}>
          <button className="export-btn" style={{ marginRight: 0 }} onClick={() => {
            dispatch({ type: "FETCH_CUSTOMER_TERMINALS_CLEAR" });
            props.backFunction();
          }}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <td>Vendor Name</td>
            <td>Terminal Name</td>
            <td>Address</td>
            <td>State</td>
            <td>
              Status <FilterSVG />
            </td>
          </tr>
        </thead>
        <tbody>
          {terminalList || (
            <tr>
              <td colSpan={12} className="text-center">
                No Terminal available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        count={totalCount}
        pageSize={pageSize}
        fetchMethod={(pageNo, pageSize) => {
          // onFetchTerminals(pageNo, pageSize);
          dispatch(fetchCustomerTerminal(selectedVendor.id, pageNo, pageSize, selectedState));
        }}
      />
    </Fragment>)
}

export default LocationList;