import axios from 'axios';
import { customConfig } from '../../action_constants';
import { authHeader } from '../../../helpers/authHeader';

const API_URL = customConfig.API_URL;
export const GET_ALL_VENDORS = 'GET_ALL_VENDORS';

export function ConfigureVendorFee(data) {
    return function(dispatch) {
       dispatch({ type: 'VENDOR_CONFIG_START' });
      axios
        .post(API_URL + 'api/fees', data, { headers: authHeader() })
        .then(res => {
          if (res.data.status.code === 0) {
            dispatch({ type: 'VENDOR_CONFIG_SUCCESS', response: { status: 1, message: res.data.status.description } });
          } else {
            dispatch({ type: 'VENDOR_CONFIG_FAILED', response: { status: 2, message: res.data.status.description } });
          }
        //  dispatch({type:'ADD_CUSTOMER_FINISED'});
        })
        .catch(err => {
          dispatch({
            type: 'VENDOR_CONFIG_FAILED',
            // response: { status: 2, message: err.response.data.status.description || err.response.data.status.message || err.response.data.status.errors },
            response: {
              status: 2,
              message: err.response?.data?.status?.description || err.response?.data?.status?.message || err.response?.data?.status?.errors || 'Error occured',
            },
          });
        });
        // dispatch({type:'VENDOR_CONFIG_FAILED'});
    };
  }

  export function EditVendorFee(data) {
    return function(dispatch) {
       dispatch({ type: 'VENDOR_CONFIG_START' });
      axios
        .put(API_URL + 'api/fees', data, { headers: authHeader() })
        .then(res => {
          if (res.data.status.code === 0) {
            dispatch({ type: 'VENDOR_CONFIG_SUCCESS', response: { status: 1, message: res.data.status.description } });
          } else {
            dispatch({ type: 'VENDOR_CONFIG_FAILED', response: { status: 2, message: res.data.status.description } });
          }
        //  dispatch({type:'ADD_CUSTOMER_FINISED'});
        })
        .catch(err => {
          dispatch({
            type: 'VENDOR_CONFIG_FAILED',
            // response: { status: 2, message: err.response.data.status.description || err.response.data.status.message || err.response.data.status.errors },
            response: {
              status: 2,
              message: err.response?.data?.status?.description || err.response?.data?.status?.message || err.response?.data?.status?.errors || 'Error occured',
            },
          });
        });
        // dispatch({type:'VENDOR_CONFIG_FAILED'});
    };
  }

  export function getVendorFee(vendorNum) {
    return function(dispatch) {
       dispatch({ type: 'GET_VENDOR_CONFIG_START' });
      axios
        .get(API_URL + `api/fees/${vendorNum}`, { headers: authHeader() })
        .then(res => {
          if (res.data.status.code === 0) {
            dispatch({ type: 'GET_VENDOR_CONFIG_SUCCESS', response: { status: 1, message: res.data.status.description , data: res.data.data} });
          } else {
            dispatch({ type: 'GET_VENDOR_CONFIG_FAILED', response: { status: 2, message: res.data.status.description } });
          }
        //  dispatch({type:'ADD_CUSTOMER_FINISED'});
        })
        .catch(err => {
          dispatch({
            type: 'GET_VENDOR_CONFIG_FAILED',
            // response: { status: 2, message: err.response.data.status.description || err.response.data.status.message || err.response.data.status.errors },
            response: {
              status: 2,
              message: err.response?.data?.status?.description || err.response?.data?.status?.message || err.response?.data?.status?.errors || 'Error occured',
            },
          });
        });
        // dispatch({type:'VENDOR_CONFIG_FAILED'});
    };
  }