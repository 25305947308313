import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import "../../../../src/assets/Styles/Dashboard.css";
import ContractsStatusBar from "./StatusBar";
import {
  getAllProductsByVendor, fetchOneVendor, getAllProductsByStationManager,
} from "../../ownerModule/redux/action/OwnerVendorActions";
import { getUserProperties, STATION_MANAGER } from "../../../lib/Constants/UserTypes";
import { AddUpdateStationPrice, createVendorProduct, editVendorProduct } from "../../../actions/VendorActions/ProductActions/ProductAction";
import ProductList from "./ProductList";
import {
  approveProductRequest,
  fetchPendingRequests,
  rejectRequest
} from "../../../actions/maker-checker-request-actions";
import StationProductList from "./StationManager/StationProductList";

function Products() {
  const dispatch = useDispatch();
  const { vendor, userLevel, id } = getUserProperties();
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);
  const pageSize = 10;

  useEffect(() => {
    batch(() => {
      if(userLevel === STATION_MANAGER) {
       dispatch(getAllProductsByStationManager(1, pageSize));
      } else {
        dispatch(getAllProductsByVendor(vendor, 1, pageSize));
        dispatch(fetchOneVendor(vendor));
       }
    });
  }, []);


  const props = useSelector((state) => state);

  return (
    <div className="dashboard-container">
      {isStatusBarVisible && <ContractsStatusBar {...props} />}

      {userLevel !== STATION_MANAGER && <ProductList
        {...props}
        onHideStatusBar={(status) => {
          setIsStatusBarVisible(!status);
        }}
        onFetchProducts={(pageNo, pageSize) => {
          dispatch(
            getAllProductsByVendor(vendor, pageNo, pageSize)
          );
        }}
        // onFetchVendorCustomers={(vendorId) =>
        //   dispatch(loadAllVendorCustomer(vendorId, 1, 100000))
        // }
        pageSize={pageSize}
        onCreateProduct={(data) => dispatch(createVendorProduct(vendor, data))}
        onEditProduct={(productId, data) => dispatch(editVendorProduct(productId, data))}
        currentUserId={id}
        isAdmin={['SUPER_ADMIN', 'ADMIN'].includes(userLevel)}
        onFetchPendingRequest={() => dispatch(fetchPendingRequests())}
        onApproveProductRequest={requestId => dispatch(approveProductRequest(requestId))}
        onRejectRequest={(requestId) => dispatch(rejectRequest(requestId))}
      />}
      {userLevel === STATION_MANAGER &&
        <StationProductList
          {...props}
          onHideStatusBar={(status) => {
            setIsStatusBarVisible(!status);
          }}
          onFetchProducts={(pageNo, pageSize) => {
            dispatch(getAllProductsByStationManager(pageNo, pageSize));
          }}
          pageSize={pageSize}
          onEditProduct={(productId, data) => dispatch(AddUpdateStationPrice(productId, data))}
         currentUserId={id}
        />}
    </div>
  );
}

export default Products;
