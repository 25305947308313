import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../../../../src/assets/Styles/Dashboard.css";
import formatAmount from "../../../../lib/Functions/formatAmount";
import { BackSVG } from "../../../../assets/Svgs/Svgs";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { updateSchemeLimit } from "../../../../actions/CustomerActions/CardMgtActions/CardAction";

const StatusBar = (props) => {
  const { selectedScheme, onSchemeLimitUpdate, userLevel } = props;
  const {title, accountNumber, cycle, limit, contractId} = selectedScheme;
  const dispatch = useDispatch();

  const isSuperAdmin = userLevel === 'SUPER_ADMIN';
  const [newLimit, setNewLimit] = useState(0);
  const [isELM, setIsELM] = useState(false);// ELM = edit-limit-mode
  const [isUpdating, setIsUpdating] = useState(false);
  const [feedback, setFeedback] = useState({ status: false, isError: false, message: '' });
  const hasSuccessMessage = (!feedback.isError && feedback.message);

  const handleSubmitLimitUpdate = () => {
    setIsUpdating(true);

    dispatch(updateSchemeLimit(accountNumber, contractId, cycle, newLimit, (feedback) => {
      onSchemeLimitUpdate(!feedback.isError, {...selectedScheme, limit: newLimit});
      terminateEditMode(!feedback.isError);
      setFeedback(feedback);
    }));
  };

  const terminateEditMode = (isOk) => {
    if (isOk) setIsELM(prevState => !prevState);
    setIsUpdating(prevState => !prevState);
  };

  useEffect(() => {
    setNewLimit(limit)
  }, [limit]);

  const spinnerLayout = (<span className="spinner-border spinner-border-sm text-light" role="status"><span className="sr-only">Loading...</span></span>);

  return (
    <div className={`status-container row d-block mt-5`}>
      <div
        className="back-btn"
        onClick={() => {
          window.location.href = '/customer-admin/scheme/main';
        }}
        style={{ top: 24, right: 24}}
      >
        <span>
          <BackSVG /> Back
        </span>
      </div>
      <div className="compartment compartment-two col-12 ">
        <div
          className="row token-status-box pl-5 pr-5"
          style={{border: "none"}}
        >
          <div
            className="col col-12"
            style={{
              position: "relative",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 16,
              width: "50%",
            }}
          >
            <div className="row card-status-sub-title">
              <div className="col-3 pl-0">Scheme Name</div>
              <div className="col-3 pl-0">Account Number</div>
              <div className="col-3 pl-0">Cycle</div>
              <div className="col-3 pl-0">Limit</div>
            </div>
            <div className="row status-values">
              <div className="col-3 pl-0">{title}</div>
              <div className="col-3 pl-0">{accountNumber}</div>
              <div className="col-3 pl-0">{cycle}</div>

              {!isELM && <div className="col-3 pl-0">
                ₦ {formatAmount(limit * 100)} 
                {/* {isSuperAdmin && <a href="#edit" onClick={() => {
                  setIsELM(true);
                  setFeedback({ status: false, isError: false, message: '' });
                }} title={`${isELM ? 'Close': 'Edit'}`}>
                  <i className="material-icons" style={{color: "#D0021B", fontSize:'16px', marginLeft: '5px'}}>
                    {isELM ? 'close' : 'edit'}
                  </i>
                </a>} */}
                {(hasSuccessMessage) && <small style={{fontSize: '11px'}} className="text-success d-block">{feedback.message}</small>}
              </div>}
              {(isSuperAdmin && isELM) && <div className="col-3 pl-0" style={{marginBottom: '-35px'}}>
                <InputGroup size="sm">
                    <FormControl
                        placeholder="Set New Limit"
                        aria-label="Set New Limit"
                        aria-describedby="basic-addon2"
                        defaultValue={limit}
                        onChange={(e) => setNewLimit(e.target.value)}
                        className="mt-0"
                        disabled={isUpdating}
                    />
                    <InputGroup.Append>
                        <Button variant="danger" style={{paddingBottom: 0}} onClick={handleSubmitLimitUpdate} disabled={isUpdating}>
                            {isUpdating ? spinnerLayout : <i className="material-icons" style={{fontSize: '18px'}}>done</i>}
                        </Button>
                        <Button style={{background: '#00425F', borderColor: '#00425F', paddingBottom: 0}} onClick={() => setIsELM(false)} disabled={isUpdating}>
                            <i className="material-icons" style={{fontSize: '18px'}}>close</i>
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
                <small style={{fontSize: '11px'}} className="text-danger">{ feedback.message || '**Must be less than contract-limit**' }</small>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusBar;