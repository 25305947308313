const oPaymentsReducer = (
  state = {
    account: {
      loading: false,
      creating: false,
      success: false,
      created: false,
      error: false,
    },
    invoice: {
      loading: false,
      creating: false,
      success: false,
      created: false,
      error: false,
    },
    banks: {
      loading: false,
      success: false,
      error: false,
    },
    settlement: {
      creating: false,
      success: false,
      error: false
    }
  },
  action,
) => {
  const { account, invoice, banks, settlement } = state;
  switch (action.type) {
    case 'GET_ACCOUNT_NUMBER':
      return { ...state, account: { ...account, loading: true, error: false } };
    case 'GET_ACCOUNT_NUMBER_FULLFILLED':
      return { ...state, account: { ...account, loading: false, success: true, data: action.payload } };

    case 'CREATE_ACCOUNT_NUMBER':
      return { ...state, account: { ...account, creating: true, error: false } };
    case 'CREATE_ACCOUNT_NUMBER_FULLFILLED':
      return { ...state, account: { ...account, creating: false, created: true, data: action.payload } };
    case 'CREATE_ACCOUNT_NUMBER_REJECTED':
      return { ...state, account: { ...account, loading: false, data: action.payload } }

    case 'START_REQUERY':
      return { ...state, account: { ...account, loading: true, error: false } };
    case 'COMPLETE_REQUERY':
      return { ...state, account: { ...account, loading: false, success: true, data: action.payload } };

    case 'GET_INVOICE':
      return { ...state, invoice: { ...invoice, loading: true, error: false } };
    case 'GET_INVOICE_COMPLETED':
      return { ...state, invoice: { ...invoice, loading: false, success: true, data: action.payload } };

    case 'CREATE_INVOICE':
      return { ...state, invoice: { ...invoice, creating: true, error: false } };
    case 'CREATE_INVOICE_COMPLETED':
      return { ...state, invoice: { ...invoice, creating: false, created: true, createData: action.payload } };


    case 'ACCOUNT_RESET': 
    return { ...state, account: { ...account, creating: false, created: false, data: null, success: false } };
    case 'ERROR':
      return { ...state, invoice: { ...invoice, loading: false, creating: false, error: true, err: action.payload }, 
      account: { ...account, loading: false, creating: false, error: true, err: action.payload },
      // banks: { ...banks, loading: false, success: false, error: true, err: action.payload},
      settlement: { ...settlement, creating: false, error: false, success: false, err: action.payload} }

    case 'START_FETCH_BANKS':
      return {...state, banks: { loading: true, error: false, success: false } }
    case 'COMPLETE_FETCH_BANKS':
      return {...state, banks: { loading: false, error: false, success: true, data: action.payload } }

    case 'COMPLETE_GET_SETTLEMENT': 
    return {...state, settlement: {success: true, data: action.payload}}
    case 'START_ADD_SETTLEMENT':
      return {...state, settlement: { creating: true, error: false, success: false} }
    case 'COMPLETE_ADD_SETTLEMENT':
      return {...state, settlement: { creating: false, error: false, success: true, data: action.payload} }
    case 'SETTLEMENT_ERROR':
      return {...state, settlement: { creating: false, error: true, success: false, data: action.payload}}
      case 'SETTLEMENT_RESET':
        return {...state, settlement: { creating: false, error: false, success: false, data: null}}
    default:
      return state;
  }
}

export default oPaymentsReducer;