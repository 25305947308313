import axios from 'axios';
import { authHeader } from '../../../../../src/helpers/authHeader';
import {unusedTags, usedTags, createTags, batchDownloads} from '../../../../../src/actions/action_constants'
import { setUnusedTags, setUsedTags, TagCreationAction, setTagCreationStatusAction, setRFIDBatch, setTagCreationError } from './actions';
import { batch } from 'react-redux';

export function fetchTags(usedPage, ununsedPage, q = '') {
  return function(dispatch) {
    dispatch({ type: 'FETCH_ALL_BLANKCARDS_START' });
    axios
      .get(`${unusedTags}?page=${ununsedPage}&rfidTag=${q}`, { headers: authHeader() })
      .then(res => {
        const response = res.data;
        const allUnusedTagsCount = response.total;
        dispatch(setUnusedTags(allUnusedTagsCount, response.data));
        return axios.get(`${usedTags}?page=${usedPage}&rfidTag=${q}`, {headers: authHeader()});
      })
      .then(res => {
        const response = res.data;
        const allUsedTagsCount = response.total;
        dispatch(setUsedTags(allUsedTagsCount, response.data));
      })
      .catch(err => {
      });
  };
}


export function fetchBatchDownloads(pageNum, pageSize) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_RFID_BATCH' });
    axios
      .get(`${batchDownloads}?pageNum=${pageNum}&pageSize=${pageSize}`, { headers: authHeader() })
      .then(res => {
        const { count, data } = res;
        dispatch(setRFIDBatch(count, data));
      });
  };
}



export function createRfidTags(identifier, noOfTags) {
  const batchType = identifier === "501" ? "individual": "corporate";
  return dispatch => {
    axios
    .get(`${createTags}?batchSize=${noOfTags}&batchType=${batchType}&batchPrefix=${identifier}`, { headers: authHeader() })
    .then(res => {
      const response = res.data;
      if(response.code === "00"){
        batch(() => {
          dispatch(setTagCreationStatusAction(true));
          dispatch(TagCreationAction(false));
        })
      }
    })
    .catch(err => {
      dispatch(TagCreationAction(false));
      dispatch(setTagCreationError(2));
    })
  }
}

