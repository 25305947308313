import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import StatusBar from '../StatusBar';
import { getAllUsers } from "../../redux/action/customerUserActions";
import { OWNER_USER } from "../../../../lib/Constants/UserTypes";
import { fetchAllTrail } from "../../redux/action/OwnerTrailActions";
import { fetchAllVendors } from "../../redux/action/OwnerVendorActions";
import RFIDList from "./RFIDList";
import { fetchAllBlankcards } from "../../redux/action/OwnerCardActions";
import { fetchTags, fetchBatchDownloads } from "../actions/methods";

function Rfids () {
  const dispatch = useDispatch();
  const [isStatusVisible, setIsStatusVisible] = useState(true);

  function fetchRfidTags (usedPage, unusedPage) {
    dispatch(fetchTags(usedPage, unusedPage));
  }

  function fetchRfidBatchDownloads (pageNum, pageSize) {
    dispatch(fetchBatchDownloads(pageNum, pageSize));
  }

  useEffect(()=> {
    batch(()=>{
      dispatch(getAllUsers(OWNER_USER));
      dispatch(fetchAllTrail(1, 10000000));
      dispatch(fetchAllVendors(1, 1000));
      dispatch(fetchAllBlankcards());
      fetchRfidTags(1, 1);
      fetchRfidBatchDownloads(1, 10);
      dispatch(fetchBatchDownloads(1, 10));
    })
    
  }, [])


  const props = useSelector(state => state);
  
  return (
    <div className="dashboard-container">
      <StatusBar {...props} isVisible={isStatusVisible} />
      <RFIDList {...props} onHideStatusBar={(status) => {setIsStatusVisible(!status)}} onFetchRfidTags={(fetchRfidTags)} onFetchRfidBatch = {fetchRfidBatchDownloads}  />
    </div>
  )
}

export default Rfids;