import axios from 'axios';
import { customConfig } from '../../../../actions/action_constants';
import { authHeader } from '../../../../helpers/authHeader';
const API_URL = customConfig.API_URL;
// const API_URL = 'http://104.248.149.29:8588/';

export function createContract(data) {
  return function(dispatch) {
    dispatch({type:'START_CONTRACT_REQUEST'})
    axios
      .post(API_URL + 'api/contracts', data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code !== 0) {
          dispatch({ type: 'CREATE_CONTRACT_REQUEST', response: { status: 2, message: res.data.status.description } });
        } else {
          dispatch({ type: 'CREATE_CONTRACT_REQUEST', response: { status: 1, message: 'New contract created successfully.' } });
        }
      })
      .catch(err => {
        const errorMessage = err.response ? err.response.data.status.description :  "An error occurred while creating contract"
        dispatch({ type: 'CREATE_CONTRACT_REQUEST', response: { status: 2, message: errorMessage} });
      });
  };
}

export function updateContract(data, id) {
  return function(dispatch) {
    axios
      .put(API_URL + 'api/contracts?cid=' + id, data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code !== 0) {
          dispatch({ type: 'CREATE_CONTRACT_REQUEST', response: { status: 2, message: res.data.status.description } });
        } else {
          dispatch({ type: 'CREATE_CONTRACT_REQUEST', response: { status: 1, message: res.data.status.description } });
        }
      })
      .catch(err => {
        const errorMessage = err.response ? err.response.data.status.description :  "An error occurred while updating contract"
        dispatch({ type: 'CREATE_CONTRACT_REQUEST', response: { status: 2, message: errorMessage } });
      });
  };
}

export function updateModal(data) {
  return {
    type: 'UPDATE_CONTRACT_REQUEST_PARAMS',
    response: { status: data, message: '' },
  };
}

export function viewAllContracts(page,pageSize) {
  return function(dispatch) {
    axios
      .get(API_URL + `api/contracts/all?page=${page}&size=${pageSize}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_All_CONTRACTS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        // dispatch({ type: 'FETCH_All_CUSTOMERS_REJECTED', payload: err });
      });
  };
}

export function viewVendorContracts(vid, page, pageSize, q= '') {
  return function(dispatch) {
    dispatch({ type: 'FETCH_VENDOR_CONTRACTS_STARTED'});
    axios
      .get(API_URL + `api/contracts/vendors?vid=${vid}&page=${page}&size=${pageSize}&q=${q}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_VENDOR_CONTRACTS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_VENDOR_CONTRACTS_REJECTED', payload: err });
      });
  };
}

export function getContract(contractId) {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/contracts?id=' + contractId, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'FETCH_CONTRACT_FULFILLED', response: { data: res.data.data, status: 1, message: res.data.status.description } });
        }
        if (res.data.status.code !== 0) {
          dispatch({ type: 'FETCH_CONTRACT_FULFILLED', response: { data: {}, status: 2, message: res.data.status.description } });
        }
      })
      .catch(err => {
        // dispatch({ type: 'FETCH_All_CUSTOMERS_REJECTED', payload: err });
      });
  };
}

export function getContractsById(id) {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/contracts/?id=' + id, { headers: authHeader() })
      // .post('http://8a81848e.ngrok.io/api/contracts', data)
      .then(res => {
        dispatch({ type: 'FETCH_All_CONTRACTS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        // dispatch({ type: 'FETCH_All_CUSTOMERS_REJECTED', payload: err });
      });
  };
}

export function getVendorContracts(vid) {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/contracts/vendors?page=1&size=10000000', { headers: authHeader() })
      // .post('http://8a81848e.ngrok.io/api/contracts', data)
      .then(res => {
        dispatch({ type: 'FETCH_All_CONTRACTS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        // dispatch({ type: 'FETCH_All_CUSTOMERS_REJECTED', payload: err });
      });
  };
}

export function getCustomerContracts(cid, page = 1, size = 10000000) {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/contracts/customers?cid=' + cid + '&page='+ page +'&size='+ size, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({
            type: 'FETCH_All_CUSTOMER_CONTRACTS_FULFILLED',
            response: { data: res.data.data, loadingStatus: 1, message: res.data.status.descripton },
          });
        }
        if (res.data.status.code !== 0) {
          dispatch({
            type: 'FETCH_All_CUSTOMER_CONTRACTS_FULFILLED',
            response: { data: [], loadingStatus: 2, message: res.data.status.descripton },
          });
        }
      })
      .catch(err => {
        dispatch({ type: 'FETCH_All_CUSTOMER_CONTRACTS_FAILED', payload: err });
      });
  };
}

export function addProductToContracts(data) {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/contracts/vendors?page=1&size=10000000', { headers: authHeader() })
      // .post('http://8a81848e.ngrok.io/api/contracts', data)
      .then(res => {
        dispatch({ type: 'FETCH_All_CONTRACTS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        // dispatch({ type: 'FETCH_All_CUSTOMERS_REJECTED', payload: err });
      });
  };
}
