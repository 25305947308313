import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { errorHandler } from '../../helpers/misc';
export const ErrorToast = () => {
    const [showToast, setShowToast] = useState(true)
    const props = useSelector(s => s.oPaymentsReducer);
    const { account } = props;

    useEffect(() => {
        setShowToast(account.error)
    }, [account.error])


    return (<Toast style={{
        position: 'absolute',
        top: 10,
        right: 10,
    }} show={showToast} onClose={() => setShowToast(false)} autohide={true} delay={3000}>
        <Toast.Header className='error-toast-header'></Toast.Header>
        <Toast.Body className='error-toast-body'>
            <p>{account.error && errorHandler(account.error)}</p>
        </Toast.Body>
    </Toast>)
}