import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AutoCloseModal from "../../../lib/Functions/AutoCloseModal";
import {
  resetAddProd,
  submitAddProductForm,
} from "../../ownerModule/redux/action/OwnerVendorActions";
import SubComponentHeader from "../../../lib/Components/SubComponentHeader";
import CustomInput from "../../../lib/Components/CustomInput";
import { Ellipse } from "../../../assets/Svgs/Svgs";


const AddProduct = ({
  onCloseHandler,
  vendorId,
  oVendorReducer,
  contracts,
  products,
}) => {
  const dispatch = useDispatch();
  const [formatError, setFormatError] = useState("");
  const [errorType, setErrorType] = useState("error");
  const [cid, setCid] = useState("");
  const [discount, setDiscount] = useState("");
  const [product, setProduct] = useState("");
  const [ chargeType, setChargeType] = useState("");

  const contractOptions = contracts && contracts.filter(x=>x.contractStatus === 'ACTIVE').map((val, i) => {
    return (
      <option key={i} value={val.id}>
        {val.name}
      </option>
    );
  });

  const productOptions = products && products.map((val, i) => {
    return (
      <option key={i} value={val.id}>
        {val.name}
      </option>
    );
  });

  useEffect(() => {
    if (oVendorReducer.addProdToVenRequestStatus === 1) {
      setFormatError(oVendorReducer.addProdToVenRequestMessage);
      setErrorType("success");
      AutoCloseModal(resetAddProd, true, dispatch);
    } else if (oVendorReducer.addProdToVenRequestStatus === 2) {
      setErrorType("error");
      setFormatError(oVendorReducer.addProdToVenRequestMessage);
    }
  }, [oVendorReducer.addProdToVenRequestStatus]);

  useEffect(() => {
    dispatch(resetAddProd());
  }, []);

  const handleInput = (target, e) => {
    if (target === "discount") {
      setDiscount(e.target.value);
    }
    if (target === "name") {
      setCid(e.target.value);
    }
    if (target === "product") {
      setProduct(e.target.value);
    }
  };

  const validateInputs = () => {
    let isError = false;
    if (cid.length === "") {
      setFormatError("Select a contract");
      isError = true;
    } else if (discount === "" || isNaN(discount) || discount < 0 || discount > 100) {
      setFormatError("Enter a valid discount to continue!");
      isError = true;
    } else if (product === "") {
      setFormatError("Select a product");
      isError = true;
    }
    return isError;
  };

  const submitForm = () => {
    if (validateInputs()) return;
    dispatch(submitAddProductForm(parseInt(product), parseInt(cid), parseFloat(discount)));
  };

  return (
    <>
      <SubComponentHeader
        heading={"Add product to a contract"}
        onBackHandler={onCloseHandler}
      />
      <div className="row mt-2" style={{ width: "100%" }}>
        <div className="offset-1 col-10 p-3">
          <div className="col-10 offset-1">
            <div
              className="row bg-rfid-detailsm form bg-white ml-1 mr-1 pl-4 pr-4"
              style={{
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)",
                borderRadius: 4,
              }}
            >
              <div className="row" style={{ width: "100%" }}>
                <CustomInput
                  parentClass="col-6 mt-4 pb-3"
                  label="Contract Name"
                  isCompulsory={true}
                  value={cid}
                  isSelect={true}
                  presetData="Select a contract..."
                  data={contractOptions}
                  handleInput={(e) => {
                    handleInput("name", e);
                  }}
                />
                <CustomInput
                  parentClass="col-6 mt-4 pb-3"
                  label="Product Name"
                  isCompulsory={true}
                  value={product}
                  isSelect={true}
                  presetData="Select a product..."
                  data={productOptions}
                  handleInput={(e) => {
                    handleInput("product", e);
                  }}
                />
                {/* <CustomInput
                  parentClass="col-6 mt-4 pb-3"
                  label="Rate"
                  type="number"
                  isCompulsory={true}
                  value={rate}
                  placeholder="Enter your rate"
                  handleInput={(e) => {
                    handleInput("rate", e);
                  }}
                  
                /> */}
                {/* <div className="card-management-row col-12">
                  <div className="title"> Transaction Fee type</div>
                  <div className="radios row m-0">
                    <div className="col-6">
                    <div
                      onClick={() => setChargeType('PERCENT')}
                      className={`choice-btn ${chargeType === "PERCENT" ? 'enable-btn' : 'inactive-btn'} first`}>
                      Percentage Fee <Ellipse color={chargeType === "PERCENT" ? "#11B01A" : ''} />
                    </div>
                    </div>
                    <div className="col-6">
                    <div 
                     onClick={() => setChargeType('FLATRATE')}
                      className={`choice-btn ${chargeType === "FLATRATE" ? 'enable-btn' : 'inactive-btn'} first`}>
                      Flat fee <Ellipse color={chargeType === "FLATRATE" ? "#11B01A" : ''} />
                    </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="password">
          <div className="title">Cap - Fee</div> */}
          <CustomInput
                  parentClass="col-6 mt-4 pb-3"
                  label="% discount"
                  type="number"
                  isCompulsory={true}
                  value={discount}
                  placeholder="Enter your discount"
                  handleInput={(e) => {
                    handleInput("discount", e);
                  }}
                />
  
        {/* </div> */}
        {/* <div className="password">
          <div className="title">Fee</div>
          <div className="form-group">
            <input 
            // value={charge} 
            // onChange={(e) => setCharge(e.target.value)} 
            type="number" className="form-control" />
          </div>
        </div> */}
                <div className="mt-4">
                  {!!formatError && (
                    <div className="col-12 pb-3 pl-0 pr-0 mt-4">
                      <div
                        className={`${errorType}-container ${errorType}-text `}
                      >
                        <span
                          className="error-close"
                          onClick={() => setFormatError("")}
                        >
                          x
                        </span>
                        <span className={``}>{formatError}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="col-12 mb-5 mt-3"
                  style={{
                    position: "relative",
                    bottom: 0,
                  }}
                >
                  <button
                    onClick={() => {
                      submitForm();
                    }}
                    style={{
                      padding: "0.5rem 2rem 0.5rem 2rem",
                      marginLeft: 0,
                      backgroundColor: "#00425f",
                      border: "none",
                      color: "#fff",
                    }}
                  >
                    {oVendorReducer.startAddProductToVendorRequest ? (
                      <img alt="activity-indicator"
                        src="/images/ActivityIndicator.gif"
                        style={{ height: 20, width: 20, marginTop: 0 }}
                      />
                    ) : (
                      "Add Product"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
