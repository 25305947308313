import React from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import { shortMonthNames } from "../../../lib/Functions/DateNames";
// import { useDispatch } from "react-redux";

const StatusBar = (props) => {
  
  const {rfidReducer, oCardReducer} = props
  // const dispatch = useDispatch();
  const unusedCards = oCardReducer.allBlankCards;
  const {unusedTagsSize, usedTagsSize} = rfidReducer;
  const d = new Date();

  return (
    <div className={`status-container row ${props.isVisible? "d-block": "d-none"}`}>
      <div className="compartment compartment-one col-3">
        <span className="title d-block mb-2">Tokens Insights</span>
        <span className="sub-title">Analytics of Cards and RFID serials generated and assigned and suspended.</span>
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${shortMonthNames[d.getMonth()]} ${d.getFullYear()} `}</span>
      </div>
      <div className="compartment compartment-two col-9 ">
        <div className="row token-status-box">
          <div className="col col-6" style={{position: "relative", paddingLeft: 40, paddingRight: 40, paddingTop: 16, paddingBottom: 16, width: "50%"}}>
            <div className="row"><span className="title">Cards</span></div>
            <div className="row status-sub-title">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ASSIGNED</div>
              <div className="col-4 pl-0">SUSPENDED</div>
            </div>
            <div className="row status-values">
              <div className="col-4 pl-0">{unusedCards.count || "0"}</div>
              <div className="col-4 pl-0">{unusedCards.count || "0"}</div>
              <div className="col-4 pl-0">{"0"}</div>
            </div>
            <div className="status-bar-short-borders" style={{top: 23}}></div>
          </div>
          <div className="col col-6" style={{paddingLeft: 40, paddingRight: 40, paddingTop: 16, paddingBottom: 16, width: "50%"}}>
            <div className="row"><span className="title">RFID Serials</span></div>
            <div className="row status-sub-title">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ASSIGNED</div>
              <div className="col-4 pl-0">UNASSIGNED</div>
            </div>
            <div className="row status-values">
              <div className="col-4 pl-0">{unusedTagsSize + usedTagsSize}</div>
              <div className="col-4 pl-0">{usedTagsSize}</div>
              <div className="col-4 pl-0">{unusedTagsSize}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default StatusBar;