import React, { useState } from "react";
import "../../../../assets/Styles/Dashboard.css";
import Pagination from "../../../../lib/Components/Pagination";
import formatAmount from "../../../../lib/Functions/formatAmount";
import { formatDate3, returnTime } from "../../../../lib/Functions/formatDate";
import { CreateSVG } from "../../../../assets/Svgs/Svgs";
import BulkVoucherUpload from "../../../shared/BulkVoucher";

const VoucherList = (props) => {
  const { myVouchers, myVouchersCount, isStatusBarVisible, pageSize, onFetchPage, } = props;
 const [ showBulkVoucherModal, setshowBulkVoucherModal] = useState(false);
  // const [pageNo, setPageNo] = useState(1);
  // const start = (pageNo - 1) * pageSize;
  // const end = start + pageSize;

  const vouchersList = myVouchers && myVouchers/* .slice(start, end) */.map((value) => {
    const {
      status, referenceNumber: voucherNumber, name, beneficiaryNumber, amount, generatedBy, createdAt,
    } = value;

    return (
      <tr key={Math.random()}>
        <td className="table-description">{voucherNumber}</td>
        <td className="table-description font-weight-bold">{name || beneficiaryNumber}</td>
        <td className="table-status">
          <div className={`status-btn ${status === 'UNUSED' ? 'success' : (status === 'USED'? 'error': 'warning')}`}>{status}</div>
        </td>
        <td className="table-description">₦ {formatAmount(amount * 100)}</td>
        <td className="table-description">
          {generatedBy}
        </td>
        <td className="table-description">
          {formatDate3(createdAt)} <br/>
          {returnTime(createdAt)}
        </td>
        {/* amount in naira already */}
      </tr>
    );
  });

  return (
    <>
      {isStatusBarVisible && (
        <div className="disbursed-cards-container row">
          <div
            className="menu-link"
            style={{ marginBottom: 0, paddingBottom: 4 }}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid",
              }}
            >
              Generated Vouchers
            </span>
          </div>
          <div className="action-buttons" style={{bottom: "inherit"}}>
          <button
                  className="add-btn"
                  onClick={() => {
                    setshowBulkVoucherModal(true)
                  }}
                >
                  <i>
                    <CreateSVG />
                  </i>
                  Add new vouchers
                </button>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <td>Voucher No.</td>
                <td>Assignee</td>
                <td>Status</td>
                <td>Amount / Limit</td>
                <td>Generated By</td>
                <td>Date</td>
              </tr>
            </thead>
            <tbody>
              {vouchersList.length > 0 ? (
                vouchersList
              ) : (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No voucher to show
                  </td>
                  </tr>
                )}
            </tbody>
          </table>

          <Pagination
            count={myVouchersCount}
            pageSize={pageSize}
            fetchMethod={(pageNo) => {
              // setPageNo(pageNo);
              onFetchPage(pageNo);
            }}
          />
        </div>
      )}
      {showBulkVoucherModal && <BulkVoucherUpload showBulkModal={showBulkVoucherModal} closeBulkUploadModal={()=>setshowBulkVoucherModal(false)} uploadComplete={()=>{
        setshowBulkVoucherModal(false);
         onFetchPage(1);
      }} />}
    </>
  );
};

export default VoucherList;
