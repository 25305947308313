import {RFID} from "./actionTypes";

export const setUnusedTags = (size, content) => {
  return {
    type: RFID.UNUSED_TAGS,
    unusedTags: content,
    unusedTagsSize: size,
  }
}

export const setUsedTags = (size, content) => {
  return {
    type: RFID.USED_TAGS,
    usedTags: content,
    usedTagsSize: size,
  }
}

export const TagCreationAction = (isCreatingTags) => {
  return {
    type: RFID.END_RFID_CREATION, isCreatingTags
  };
}

export const setTagCreationError = (code) => {
  return {type: RFID.TAG_CREATION_ERROR, code}
}

export const setTagCreationStatusAction = (status) => {
  return {
    type: RFID.TAGS_CREATION_SUCCESS, isTagsCreated: status
  }
}


export const setRFIDBatch = (count, content) => {
  return {
    type: RFID.BATCH_LIST,
    rfidBatch: content,
    batchCount: count,
  }
}