import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProperties } from '../../../../lib/Constants/UserTypes';
import { createInvoice } from '../../../customerModule/redux/action/paymentsAction';
import { Alert } from 'antd';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { toggleUserCreateModal } from '../../../ownerModule/redux/action/customerUserActions';
import InvoiceComponent from './InvoiceComponent';
// import moment from 'moment';
// import { formatDate3 } from '../../../../lib/Functions/formatDate';

const GenerateInvoice = (props) => {
    const { vendorInfo, toggleModal, contractList, showModal, accountDetails } = props;
    const [key, setKey] = useState('PREPAID');
    const [showInvoice, setShowInvoice] = useState(false);
    const { invoice: { creating, created, data, createData } } = useSelector(state => state.oPaymentsReducer);

 useEffect(()=>{
   if(created){
    toggleModal();
    setShowInvoice(!showInvoice);
   }
 },[created])
 

    return (
    <>
    <Modal style={{
        width: 720,
        background: 'white',
    }} scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal invoice-modal" show={showModal}>
        <Modal.Body style={{
            padding: 0,
            maxHeight: '60vh', overflowY: 'auto'
        }}>
            <div className='payment-modal-container'>
                <h5 className='payment-modal-h'>Generate Payment Invoice</h5>
                <label className='payments-sub'>
                    Create a prepaid or postpaid invoice
                </label>
                <Tabs tabclassname="invoice-tab" defaultActiveKey="PREPAID"
                    id="uncontrolled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}>
                    <Tab eventKey="PREPAID" title="Prepaid">
                        <div className='form-container'>
                           <InvoiceForm accountDetails={accountDetails}
                                        contractType='PREPAID'
                                        vendorInfo={vendorInfo}
                                        toggleModal={toggleModal} />
                        </div>
                    </Tab>
                    <Tab eventKey="POSTPAID" title="Postpaid">
                        <div className='form-container'>
                        <InvoiceForm accountDetails={accountDetails} 
                                        contractList={contractList} 
                                         contractType='POSTPAID' 
                                         vendorInfo={vendorInfo}
                                         toggleModal={toggleModal} />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Modal.Body>
    </Modal>
    {createData?.data && <InvoiceComponent invoiceDetails={{...createData?.data}} showInvoice={showInvoice} toggleModal={()=>setShowInvoice(!showInvoice)} />}
    </>)
}

export default GenerateInvoice;

export const InvoiceForm = ({accountDetails, vendorInfo, contractType, toggleModal, contractList  })=>{

    const [amount, setAmount] = useState('');
    const [selectedContract, setSelectedContract] = useState("");
    const [contractNumber, setContractNumber] = useState("");
    const [duration, setDuration] = useState();
    const [err, setErrorMessage] = useState("");
    const [dateGenerated, setDateGenerated] = useState(new Date().toLocaleDateString())
    const { name, customerNumber } = getUserProperties() || {};
    const dispatch = useDispatch();

    const { invoice: { creating, created, data } } = useSelector(state => state.oPaymentsReducer);
  
    const handleAmountChange = (e) => {
        const { value } = e.target;
        if (/^-?\d*$/.test(value)) {
            setAmount(value);
        }
    }

    const handleSelectedContract = (e) => {
        setContractNumber(e.target.value)
        setSelectedContract(contractList.find(x => x.contractNumber === e.target.value))
        setDuration(contractList.find(x => x.contractNumber === e.target.value).contractStopDate);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (accountDetails && !accountDetails.accountNumber) {
            setErrorMessage("No bank account retrieved");
            return;
        }

        if (vendorInfo && !vendorInfo.vendorNumber) {
            setErrorMessage("Loading Vendor information...");
            return;
        }
   
        let data = {
                "vendorNumber": vendorInfo.vendorNumber,
                "customerNumber": customerNumber,
                "amount": amount.replace(/,/g, ''),
                "paymentType": contractType,
            }
        
        if (contractType == 'POSTPAID') {
            if(!selectedContract){
                setErrorMessage("Select a contract before proceeding");
                  return;
            }
            data = {
                ...data,
                "contractName": selectedContract.name,
                "contractNumber": selectedContract.contractNumber,
                "contractDuration": duration,
            }
        }
        dispatch(createInvoice(data));
    }

    const convertToNumber = () => {
        const numberValue = amount.replace(/,/g, '');
        setAmount(numberValue);
    };

    const formatAsMoney = () => {
        if (amount === '' || !/^-?\d*$/.test(amount)) {
            return;
        }
        const formattedValue = parseInt(amount).toLocaleString();
        setAmount(formattedValue);
    };
   return( 
   <form className='incoice-form' onSubmit={handleSubmit}>
    <Row>
        <Col>
            <Form.Label className='invoice-label mb-0'>Customer Name*</Form.Label>
            <Form.Control className='mb-4 invoice-input' disabled defaultValue={name} readOnly as={"input"} placeholder="" />
        </Col>
        <Col>
            <Form.Label className='invoice-label mb-0'>Vendor Name*</Form.Label>
            <Form.Control defaultValue={vendorInfo?.vendorName} className='mb-4 invoice-input' disabled readOnly as={'input'} placeholder="" />
        </Col>
    </Row>
    {contractType === 'POSTPAID' && <Row>
        <Col>
            <Form.Label className='invoice-label mb-0'>Contract Name*</Form.Label>
            <Form.Control onChange={handleSelectedContract} required value={contractNumber} className='mb-4 invoice-input' defaultValue="Choose..." as='select' type='number' placeholder="" >
                <option value="">Select contract...</option>
                {contractList && contractList.filter(x=>x.contractStatus==='ACTIVE').map((contract, index) => {
                    return (<option value={contract.contractNumber} key={index}>{contract.name}</option>)
                })}
            </Form.Control>
        </Col>
        <Col>
            <Form.Label required className='invoice-label mb-0'>Contract Duration*</Form.Label>
            <Form.Control value={duration}  readOnly as={"input"} disabled className='mb-4 invoice-input' placeholder="" />
        </Col>
    </Row>
    }
    <Row>
        <Col>
            <Form.Label className='invoice-label mb-1'>Account Number*</Form.Label>
            <Form.Control defaultValue={accountDetails?.accountNumber} className='mb-4 invoice-input' disabled readOnly as={'input'} type='number' placeholder="" />
        </Col>
        <Col>
            <Form.Label className='invoice-label mb-0'>Amount*</Form.Label>
            <Form.Control onBlur={formatAsMoney} onFocus={convertToNumber} onChange={handleAmountChange} type='text' className='mb-4 invoice-input' as={'input'} value={amount} placeholder="" required />
            {/* <input type='text' required="true" value={amount}  onChange={handleAmountChange} className='mb-4 invoice-input' /> */}
        </Col>
    </Row>
    <Row>
        <Col>
            <Form.Label className='invoice-label mb-0'>Bank Name*</Form.Label>
            <Form.Control defaultValue={accountDetails?.bankName} className='mb-4 invoice-input' disabled readOnly as={'input'} placeholder="" />
        </Col>
        <Col>
            <Form.Label className='invoice-label mb-0'>Date Generated*</Form.Label>
            <Form.Control className='mb-4 invoice-input' disabled defaultValue={dateGenerated} readOnly as={'input'} placeholder="" />
        </Col>
    </Row>
    {err && <Alert className='mb-1' closable message={err} onClose={() => setErrorMessage('')} type='error' />}

    <div className='button-border'><button type='button' disabled={creating}  onClick={toggleModal} className='invoice-cancel-button'>
        Cancel
    </button>
        <button disabled={creating} type='submit' className='invoice-form-button'>{creating? 'Generating...' : 'Submit Invoice'}</button>
    </div>
</form>)
}
