const initialState = {
  fetchingData: false,
  isSubmittingDecision: false,
  pendingRequests: [],
  requestType: [],
  pendingRequestsFetchMessage: {},
  decisionSubmitResponse: {},
  approveRequestMessage: {},
  requestRequestMessage: {},
};

export default function authorizeRequestReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_PENDING_REQUESTS_START':
      return {
        ...state,
        fetchingData: true,
      };

    case 'FETCH_PENDING_REQUESTS_FULFILLED':
      return {
        ...state,
        fetchingData: false,
        pendingRequests: action.payload.data,
        pendingRequestsFetchMessage: { isError: false, message: action.payload.message },
      };

    case 'FETCH_PENDING_REQUESTS_REJECTED':
      return {
        ...state,
        fetchingData: false,
        pendingRequestsFetchMessage: { isError: true, message: action.payload.message },
      };

    case 'REQUEST_DECISION_SUBMIT_START':
      return {
        ...state,
        isSubmittingDecision: true,
        decisionSubmitResponse: {}
      };

    case 'REQUEST_DECISION_SUBMIT_FULFILLED':
      return {
        ...state,
        isSubmittingDecision: false,
        decisionSubmitResponse: { isError: false, message: action.payload.message }
      };

    case 'REQUEST_DECISION_SUBMIT_REJECTED':
      return {
        ...state,
        isSubmittingDecision: false,
        decisionSubmitResponse: { isError: true, message: action.payload.message }
      };

    case 'REQUEST_DECISION_SUBMIT_RESET':
      return {
        ...state,
        decisionSubmitResponse: {}
      };

    default:
      return state
  };
};
