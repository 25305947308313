import React, {Fragment, useState, useEffect } from "react";
import SubComponentHeader from "../../../../lib/Components/SubComponentHeader";
import FormWarning from "../../../admin/shared/FormWarning";
import ActionModal from "../../../../lib/Components/ActionModal";
import { useDispatch } from "react-redux";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import CustomInput from "../../../../lib/Components/CustomInput";
import { StateList } from "../../../../services/states";

export default function CreateTerminal({
  onBackHandler,
  isCreateTerminal,
  oVendorReducer,
  onCreateTerminal,
  isTerminalEdit,
  terminalToEdit,
  onEditTerminal
}) {
  const dispatch = useDispatch();

  const [toast, setToast] = useState({ class: "", text: "" });
  const [isCreatingTerminal, setIsCreatingTerminal] = useState(false);
  const [isEditingTerminal, setIsEditingTerminal] = useState(false);
  const [terminalValidationError, setTerminalValidationError] = useState(false);
 const [ updateTerminalRequestStatus, setUpdateTerminalRequestStatus] = useState(0);
  const [formData, setFormData] = useState({
    terminalName: "",
    terminalId: "",
    vendor: "",
    merchantId: "",
    dealerCode: "",
    state: "",
    address: "",
  });
  const [
    showTerminalCreationCompletion,
    setShowTerminalCreationCompletion
  ] = useState(false);
  const ven = oVendorReducer.allVendors;
  const venRow = ven.data ? ven.data : ven;
  const vendorData = venRow.data ? venRow.data : venRow;

  useEffect(() => {
    if (
      !!isCreatingTerminal &&
      oVendorReducer.createTerminalRequestStatus !== 1
    ) {
      setToast({
        class: "",
        text: oVendorReducer.createTerminalRequestMessage
      });
      setTerminalValidationError(true);
      setIsCreatingTerminal(false);
    }
    if (
      !!isCreatingTerminal &&
      oVendorReducer.createTerminalRequestStatus === 1
    ) {
      resetFormData();
      setIsCreatingTerminal(false);
      setShowTerminalCreationCompletion(true);
    }
    dispatch({
      type: "CREATE_TERMINAL_REQUEST",
      response: {
        status: 0,
        message: ""
      }
    });
  }, [oVendorReducer.createTerminalRequestStatus]);

  useEffect(() =>{
    if(isTerminalEdit) {
      setFormData({...terminalToEdit, terminalName: terminalToEdit.name});
    }
  }, [isTerminalEdit, terminalToEdit]);

  useEffect(()=> {
    const { updateTerminalRequestStatus, updateTerminalRequestMessage } = oVendorReducer;
    setUpdateTerminalRequestStatus(updateTerminalRequestStatus);
 }, [oVendorReducer])

  const resetFormData = () => {
    setFormData({
      terminalId: "",
      terminalName: "",
      vendor: "",
      merchantId: "",
      dealerCode: "",
      state: "",
      address: "",
    });
  };

  const cancelForm = () => {
    resetFormData();
    onBackHandler();
  }

  const vendorOption = vendorData && vendorData.map((value, key) => {
    return (
      <option value={value.id} key={key}>
        {value.name}
      </option>
    );
  });

  const stateOption = StateList && StateList.map((value, key) => {
    return (<option value={value} key={`${key}_${value}`}>
      {value}
    </option>);
  });

  const invalidTerminalForm = () => {
    const { terminalName, terminalId, vendor, merchantId, dealerCode, state, address } = formData;
    if (!terminalName || !vendor || !terminalId || !merchantId || !state || !address) {
      return true;
    } else if(!dealerCode || dealerCode.length < 5 || dealerCode.length > 8) {
      return "Dealer Code must be minimum of 5 - 8 numeric characters long";
    } else {
      return false;
    }
  };

  const createTerminal = event => {
    event.preventDefault();
    const error = invalidTerminalForm();
    if (!!error) {
      setTerminalValidationError(true);
      setToast({
        class: "",
        text: ((typeof error === 'string') ? error : "All fields are required to create a terminal!")
      });
      return;
    }

    onCreateTerminal(formData, bool => {
      setIsCreatingTerminal(bool);
    });
  };

  const editTerminal = event =>{
    event.preventDefault();
    const { state, address } = formData;
    if(!state || !address) {
      setToast({
        class: "",
        text: "State and Address fields are required to edit!"
      });
      return;
    }

    onEditTerminal(formData, bool => {
      setIsEditingTerminal(bool);
    })
  }

  const handleInput = (attr, event) => {
    setFormData({ ...formData, [attr]: event.target.value });
  };

  return (
    <>
      {(isCreateTerminal || isTerminalEdit) && (
        <>
          <SubComponentHeader
            heading={isTerminalEdit ? "Edit Terminal" : "Create Terminal"}
            onBackHandler={onBackHandler}
          />

          <div className="row mt-2" style={{width: "100%"}}>
            <div className="offset-1 col-10 p-3">
              <div className="col-10 offset-1">
                <div
                  className="row bg-rfid-details pb-5 form bg-white ml-1 mr-1"
                  style={{
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)",
                    borderRadius: 4,
                  }}
                >
                  <div className="row ml-1 mt-4 mr-2">
                    <FormWarning
                      inline={true}
                      message={isTerminalEdit ? "Kindly note that only State and Address fields are editable" 
                                              : "Please note that all fields are compulsory"}
                    />
                  </div>

                  <div className="row ml-2 mr-2" style={{width: "100%"}}>
                    <CustomInput
                      disabled={isTerminalEdit}
                      parentClass="col-6 mt-2 mb-3"
                      label="Terminal Name"
                      value={formData.terminalName}
                      handleInput={handleInput.bind(this, "terminalName")}
                    />

                    <CustomInput
                      parentClass="col-6 mt-2 mb-3"
                      label="Terminal ID"
                      value={formData.terminalId}
                      handleInput={handleInput.bind(this, "terminalId")}
                      disabled={isTerminalEdit}
                    />

                    <CustomInput
                      parentClass="col-6 mt-2 mb-3"
                      label="Merchant ID"
                      value={formData.merchantId}
                      handleInput={handleInput.bind(this, "merchantId")}
                      disabled={isTerminalEdit}
                    />

                    <CustomInput
                      presetData="Select Vendor"
                      data={vendorOption}
                      parentClass="col-6 mt-2"
                      label="Select Vendor"
                      value={formData.vendor}
                      isSelect={true}
                      handleInput={handleInput.bind(this, "vendor")}
                      disabled={isTerminalEdit}
                    />

                    <CustomInput
                      parentClass="col-6 mt-2 mb-3"
                      label="Dealer Code"
                      value={formData.dealerCode}
                      type="number"
                      maxLength={8}
                      handleInput={handleInput.bind(this, "dealerCode")}
                      disabled={isTerminalEdit}
                    />
                  </div>

                  <CustomInput
                      presetData="Select State"
                      data={stateOption}
                      parentClass="col-6 mt-2 mb-3"
                      label="State"
                      value={formData.state}
                      isSelect={true}
                      handleInput={handleInput.bind(this, "state")}
                    />

                    <CustomInput
                      parentClass="col-6 mt-2 mb-3"
                      label="Address"
                      value={formData.address}
                      handleInput={handleInput.bind(this, "address")}
                    />

                  <ToastAlert
                    showToast={!!terminalValidationError}
                    parentClass={"col-12 pb-3"}
                    childClass={toast.class}
                    toastText={toast.text}
                    onCloseHandler={() => {
                      setTerminalValidationError(false);
                    }}
                  />
                </div>

                <div className="buttons mt-3 text-right">
                  <div
                    className="mr-4"
                    style={{
                      backgroundColor: "#5F738C",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={!isTerminalEdit? cancelForm : onBackHandler}
                  >
                    Cancel
                  </div>
                  <div
                    style={{
                      backgroundColor: "#00425F",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={!isTerminalEdit ? createTerminal : editTerminal}
                  >
                    {isTerminalEdit? 'Save Changes' : 'Create Terminal'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <ActionModal
        isShow={isCreatingTerminal || showTerminalCreationCompletion || isEditingTerminal}
        isCompleted={!isCreatingTerminal }
        actionTitle={isEditingTerminal ? "Updating Terminal" : "Creating Terminal"}
        actionText={isEditingTerminal ? "Hold on a sec while we update your terminal..."
                                    : "Hold on a sec while we generate your terminal..."}
        isCompletedTitle="You are all set!"
        isCompletedText={isTerminalEdit ? "Your new terminal has been successfully updated"
                                        : "Your new terminal has been successfully created" }
        updateCompletedStatus={()=>{}}
      >
        <Fragment>        
          {showTerminalCreationCompletion ? (
          <div className="text-center mb-5">
            <div
              className="dark-blue-btn d-inline-block mr-3"
              style={{float: "none", backgroundColor: "#5F738C"}}
              onClick={() => {
                resetFormData();
                setShowTerminalCreationCompletion(false);
                setTerminalValidationError(false);
              }}
            >
              <span className="align-middle text-white">Create New</span>
            </div>
            <div
              className="dark-blue-btn d-inline-block ml-3"
              style={{float: "none"}}
              onClick={() => {
                onBackHandler();
                setShowTerminalCreationCompletion(false);
                setTerminalValidationError(false);
              }}
            >
              <span className="align-middle text-white">Back to terminals</span>
            </div>
          </div>
        ) : null}
        {isTerminalEdit && updateTerminalRequestStatus === 1 && <div className="text-center mb-5">
          <div
              className="dark-blue-btn d-inline-block ml-3"
              style={{float: "none"}}
              onClick={() => {
                onBackHandler("back");
              }}
            >
              <span className="align-middle text-white">Back to terminals</span>
            </div>
            </div>}
        </Fragment>
      </ActionModal>
    </>
  );
}
