import axios from 'axios';
import { ALL_VENDOR_PRODUCTS, ALL_VENDOR_PRODUCTS_LOADING } from './ProductConstants';
import { customConfig } from '../../action_constants';
import { authHeader } from '../../../helpers/authHeader';
const API_URL = customConfig.API_URL;

export const getAllVendorProducts = products => dispatch => {
  dispatch({
    type: ALL_VENDOR_PRODUCTS,
    products,
  });
};

export const allVendorsProductLoading = status => dispatch => {
  dispatch({
    type: ALL_VENDOR_PRODUCTS_LOADING,
    status,
  });
};

// Don't forget to pass parameter to send the user vendor ID,
// after integration of authentication process.

// export function loadAllVendorProducts() {
//   return function(dispatch) {
//     axios
//       .get(`${API_URL}api/products/vendors?id=2&page=1&size=50`)
//       .then(response => {
//         if (response.status === 200) {
//           const { data } = response.data;
//           dispatch(getAllVendorProducts(data));
//           dispatch(allVendorsProductLoading(1));
//         }
//       })
//       .catch(error => {
//         dispatch(allVendorsProductLoading(2));
//       });
//   };
// }

export function loadAllVendorProducts() {
  return function(dispatch) {
    axios
      .get(`${API_URL}api/products/vendors?id=2&page=1&size=10000000`, { headers: authHeader() })
      .then(response => {
        if (response.status === 200) {
          const { data } = response.data;
          dispatch(getAllVendorProducts(data));
          dispatch(allVendorsProductLoading(1));
        }
      })
      .catch(error => {
        dispatch(allVendorsProductLoading(2));
      });
  };
}

export function loadAllCustomerVendors(cid) {
  const config = { // eslint-disable-line no-unused-vars
    headers: {
      'Access-Control-Allow-Origin': 'http://104.248.149.29:8588',
      // 'Content-Type': 'application/json',
      'content-type': 'application/x-www-form-urlencoded',
    },
  };
  return function(dispatch) {
    dispatch({ type: 'GET_VENDORS_START' });
    axios
      .get(`${API_URL}api/customers/vendors?cid=${cid}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'GET_VENDORS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'GET_VENDORS_REJECTED', payload: err });
      });
  };
}

export function createVendorProduct(vid, data) {
  return function(dispatch) {
    axios
      .post(`${API_URL}api/products/vendors?id=${vid}`, data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'CREATE_PRODUCT_REQUEST', response: { status: 1, message: res.data.status.description } });
        } else {
          dispatch({
            type: 'CREATE_PRODUCT_REQUEST',
            response: { status: 2, message: res.data.status.message ? res.data.status.message : res.data.status.errors },
          });
        }
      })
      .catch(err => {
        dispatch({
          type: "CREATE_PRODUCT_REQUEST_FAIL",
          response: {
            message:
              err?.response?.data?.status?.message ||
              err?.response?.data?.status?.errors,
            status: 2,
          },
        });
      });
  };
}

export function editVendorProduct(pid, data) {
  return function(dispatch) {
    dispatch({type:'START_EDIT_PRODUCT_REQUEST'})
    axios
      .put(`${API_URL}api/products/?id=${pid}`, data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'EDIT_PRODUCT_REQUEST', response: { status: 1, message: res.data.status.description } });
        } else {
          dispatch({
            type: 'EDIT_PRODUCT_REQUEST',
            response: { status: 2, message: res.data.status.message ? res.data.status.message : res.data.status.errors },
          });
        }
      })
      .catch(err => {
        dispatch({ type: 'EDIT_PRODUCT_REQUEST_FAIL', response: { message: err.response.data.status.errors, status: 2 } });
      });
  };
}

export function AddUpdateStationPrice(productId, price) {
  return function(dispatch) {
    dispatch({type:'START_EDIT_PRODUCT_REQUEST'})
    axios
      .post(`${API_URL}api/products/stations/rate?id=${productId}&stationRate=${price}`, null, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'EDIT_PRODUCT_REQUEST', response: { status: 1, message: res.data.status.description } });
        } else {
          dispatch({
            type: 'EDIT_PRODUCT_REQUEST',
            response: { status: 2, message: res.data.status.message ? res.data.status.message : res.data.status.errors },
          });
        }
      })
      .catch(err => {
        dispatch({ type: 'EDIT_PRODUCT_REQUEST_FAIL', response: { message: err.response.data.status.errors, status: 2 } });
      });
  };
}

export function loadProductById(pid) {
  return function(dispatch) {
    axios
      .get(`${API_URL}/api/products?id=${pid}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_PRODUCT_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_PRODUCT_REJECTED', payload: err });
      });
  };
}
