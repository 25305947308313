import React from 'react';
import {
    BackSVG,
    TerminalSvg2,
    MarkSVG,
    MissSVG
  } from "../../../../assets/Svgs/Svgs";
import toSentenceCase from '../../../../lib/Functions/toSentenceCase';

export const TerminalDetails =({selectedTerminal, showTerminalEdit, handleBack }) => {
    const { name, vendorName, merchantId, terminalId, state, dealerCode, address, status, managerName } = selectedTerminal
    return (
        <>
          <div className="underlined-tab" /* style={{marginRight: 30}} */>
            <span className="tab-title">Terminal Details</span>
            <div className="action-buttons">
              <button className="export-btn" style={{marginRight: 100}} onClick={() => {
                  showTerminalEdit()
              }}>
                Edit Terminal
              </button>
            </div>
            <div className="action-buttons">
              <button className="export-btn" style={{marginRight: 0, backgroundColor: '#00425F' }} onClick={() => {
                  handleBack()
              }}>
                <BackSVG />
                Back
              </button>
            </div>

          </div>
        <>
          <div className="row mt-2" style={{ width: "100%" }}>
            <div className="col-10 offset-1 mt-2 p-3">
              <div className="row bg-rfid-details mr-2 p-5 rounded">
                <div className="col-1">
                  <TerminalSvg2 />
                </div>
                <div className="col-11">
                  <span
                    className="title d-inline-block mb-4 pb-2"
                    style={{ borderBottom: "1px solid #c4c4c4" }}
                  >
                    Terminal Name: {name}
                  </span>
                  <div className="row" style={{ width: "100%" }}>
                    <div className="row ml-0 contract-modal-heading">
                      <div className="col-3">Vendor Name</div>
                      <div className="col-3">Merchant ID</div>
                      <div className="col-3">Dealer Code</div>
                      <div className="col-3">Status</div>
                    </div>
                    <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                      <div className="col-3">
                        {vendorName}
                      </div>
                      <div className="col-3">
                        {merchantId}
                      </div>
                      <div className="col-3">
                        {dealerCode}
                      </div>
                      <div className="col-3">
                        {toSentenceCase(status)}{" "}
                        {status === "ACTIVE" ? (
                          <MarkSVG size={14} />
                        ) : (
                          <MissSVG size={14} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row bg-rfid-details mr-2 mt-3 p-5 rounded">
                <div className="col-1"></div>
                <div className="col-11">
                  <div className="row" style={{ width: "100%" }}>
                    <div className="row ml-0 contract-modal-heading">
                      <div className="col-3">Terminal ID</div>
                      <div className="col-3">Address</div>
                      <div className="col-3">State</div>
                      <div className="col-3">Station Manager</div>
                    </div>
                    <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                      <div className="col-3">
                        {terminalId}
                      </div>
                      <div className="col-3">
                        {address}
                      </div>
                      <div className="col-3">
                        {state}
                      </div>
                      <div className="col-3">
                        {managerName || "----"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        </>
      );
}