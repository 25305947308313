import {
  CUSTOMER_ADMIN,
  CUSTOMER_USER,
  VENDOR_ADMIN,
  /* OWNER_USER, */ VENDOR_USER,
  SUPER_ADMIN,
  ADMIN,
  LOGISTIC,
  PROCUREMENT,
  VENDOR_SUPER_ADMIN,
} from "../Constants/UserTypes";

const user = localStorage.getItem("user");
const userType = user ? JSON.parse(user).type : null;
const userLevel = user ? JSON.parse(user).userLevel : null;
const type =
  userType &&
  [
    VENDOR_USER,
    VENDOR_ADMIN,
    VENDOR_SUPER_ADMIN,
    LOGISTIC,
    PROCUREMENT,
  ].includes(userType)
    ? "vendor"
    : userType && (userType === CUSTOMER_USER || userType === CUSTOMER_ADMIN)
    ? "customer"
    : "owner";

export const MenuRoutes = (route) => {
  let routes = [];

  if (isRouteContains(`/${type}-admin/dashboard`)) {
    routes = [
      {
        id: 0,
        name: "Dashboard",
        route: `/${type}-admin/dashboard`,
        active: true,
      },
    ];
  }

  if (isRouteContains(`/${type}-admin/station/dashboard`)) {
    routes = [
      {
        id: 0,
        name: "Dashboard",
        route: `/${type}-admin/station/dashboard`,
        active: true,
      },
    ];
  }

  if (isRouteContains(`/${type}-admin/change-password/main`)) {
    routes = [
      {
        id: 0,
        name: "User Management",
        route: `/${type}-admin/change-password/main`,
        active: true,
      },
    ];
  }

  if (
    isRouteContains(`/owner-admin/cards/main`) ||
    isRouteContains(`/owner-admin/rfid/main`)
  ) {
    routes = [
      {
        id: 0,
        name: "RFID",
        route: `/${type}-admin/rfid/main`,
      },
      {
        id: 1,
        name: "Cards",
        route: `/${type}-admin/cards/main`,
      },
    ];
  }

  if (
    isRouteContains(`/${type}-admin/scheme/main`) ||
    isRouteContains(`/${type}-admin/vouchers/main`)
  ) {
    routes = [
      {
        id: 0,
        name: "Tokens",
        route: `/${type}-admin/scheme/main`,
      },
    ];

    if (
      (userType === CUSTOMER_USER || userType === CUSTOMER_ADMIN) &&
      (userLevel === SUPER_ADMIN || userLevel === ADMIN)
    ) {
      routes.push({
        id: 1,
        name: "Vouchers",
        route: `/${type}-admin/vouchers/main`,
      });
    }
  }

  if (isRouteContains(`/customer-admin/cards/main`)) {
    routes = [
      {
        id: 0,
        name: "Card Schemes",
        route: `/${type}-admin/cards/main`,
      },
    ];
  }

  if (isRouteContains(`/${type}-admin/trail`)) {
    routes = [
      {
        id: 0,
        name: "Audit Trail",
        route: `/${type}-admin/trail`,
        active: true,
      },
    ];
  }

  if (isRouteContains(`/${type}-admin/sms-fee`)) {
    routes = [
      {
        id: 0,
        name: "Payments",
        route: `/${type}-admin/sms-fee`,
        active: true,
      },
    ];
  }

  if (
    isRouteContains(`/${type}-admin/reports/main`) ||
    isRouteContains(`/${type}-admin/settlements/main`) ||
    isRouteContains(`/${type}-admin/drivers/main`) ||
    isRouteContains(`/${type}-admin/report/settlement-contracts/main`)
  ) {
    routes = [
      {
        id: 0,
        name: "Transactions",
        route: `/${type}-admin/reports/main`,
        active: true,
      },
      {
        id: 1,
        name: "Settlements",
        route: `/${type}-admin/settlements/main`,
      },
      {
        id: 2,
        name: "Drivers",
        route: `/${type}-admin/drivers/main`,
      },
      {
        id: 3,
        name: "Settlements Contracts",
        route: `/${type}-admin/report/settlement-contracts/main`,
      },
    ];
  }

  if (
    isRouteContains(`/${type}-admin/contracts/main`) ||
    (isRouteContains(`/${type}-admin/vendors/main`) && type === "customer")
  ) {
    routes = [
      {
        id: 0,
        name: "Contracts",
        route: `/${type}-admin/contracts/main`,
        active: true,
      },
    ];
    if (type === "customer") {
      routes.push({
        id: 1,
        name: "Vendors",
        route: `/${type}-admin/vendors/main`,
        active: true,
      });
    }
  }

  if (isRouteContains("/vendor-admin/products/main")) {
    routes = [
      {
        id: 0,
        name: "Products",
        route: `/vendor-admin/products/main`,
        active: true,
      },
    ];
  }

  if (
    isRouteContains(`/${type}-admin/terminals/main`) ||
    isRouteContains(`/${type}-admin/posusers/main`)
  ) {
    routes = [
      {
        id: 0,
        name: "Terminals",
        route: `/${type}-admin/terminals/main`,
        active: true,
      },
      {
        id: 1,
        name: "POS User",
        route: `/${type}-admin/posusers/main`,
      },
    ];
  }

  if (
    isRouteContains(`/${type}-admin/payments/main`) ||
    isRouteContains(`/${type}-admin/invoice/main`) ||
    isRouteContains(`/${type}-admin/fees/main`)
  ) {
    routes = [
      {
        id: 0,
        name: "Payments",
        route: `/${type}-admin/payments/main`,
        active: true,
      },
    ];
    if (type === "customer") {
      routes.push(
        {
          id: 1,
          name: "Invoices",
          route: `/${type}-admin/invoices/main`,
          active: true,
        },
        {
          id: 2,
          name: "Fees",
          route: `/${type}-admin/fees/main`,
          active: true,
        }
      );
    }
  }

  if (
    isRouteContains(`/${type}-admin/users/main`) ||
    isRouteContains(`/${type}-admin/customers/main`) ||
    (isRouteContains(`/${type}-admin/vendors/main`) && type !== "customer")
  ) {
    const customerOnlyObj = [
      {
        id: 0,
        name: "Users",
        route: `/${type}-admin/users/main`,
        active: true,
      },
    ];

    const vendorOnlyObj = [
      ...customerOnlyObj,
      { id: 1, name: "Customers", route: `/${type}-admin/customers/main` },
    ];

    const ownerOnlyObj = [
      ...vendorOnlyObj,
      { id: 2, name: "Vendors", route: `/${type}-admin/vendors/main` },
    ];

    routes =
      userType === CUSTOMER_USER || userType === CUSTOMER_ADMIN
        ? customerOnlyObj
        : [
            VENDOR_USER,
            VENDOR_ADMIN,
            VENDOR_SUPER_ADMIN,
            LOGISTIC,
            PROCUREMENT,
          ].includes(userType)
        ? vendorOnlyObj
        : ownerOnlyObj;
  }
  // Station Manager routes
  if (isRouteContains(`/${type}-admin/station/terminals`)) {
    routes = [
      {
        id: 0,
        name: "POS Users",
        route: `/${type}-admin/station/terminals`,
        active: true,
      },
    ];
  }

  if (isRouteContains(`/${type}-admin/station/products`)) {
    routes = [
      {
        id: 0,
        name: "Products",
        route: `/${type}-admin/station/products`,
        active: true,
      },
    ];
  }
  if (isRouteContains(`/${type}-admin/station/reports`)) {
    routes = [
      {
        id: 0,
        name: "Reports",
        route: `/${type}-admin/station/reports`,
        active: true,
      },
    ];
  }
  // Station manager route ends
  function isRouteContains(myRoute) {
    const newRoute = myRoute.split("/main")[0];
    return route.indexOf(newRoute) > -1 ? true : false;
  }

  return routes;
};
