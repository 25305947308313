import React, { useState } from "react";
import {
  FilterSVG,
  ExportSVG,
  CreateSVG
} from "../../../../../src/assets/Svgs/Svgs";
import {
  shortMonthNames,
  shortDayNames,
  // longMonthNames
} from "../../../../lib/Functions/DateNames";
import "./Customers.css";
import { fetchAllCustomers } from "./customers.actions";
import { useDispatch } from "react-redux";
import CustomerProfile from "./CustomerProfile";
import CreateCustomer from "./CreateCustomer";
import { CSVLink } from "react-csv";
import Pagination from "../../../../lib/Components/Pagination";
// import { fetchAllVendors } from "../Vendors/vendors.actions";

const displayCustomers = (customers, callback) => {
  return customers.map((value, index) => {
    const { name, number, createdAt, status, contact } = value;
    const d = new Date(createdAt);
    return (
      <tr key={index}>
        <td className="font-weight-bold">{name}</td>
        <td className="font-weight-bold">{contact.email}</td>
        <td className="font-weight-bold">{number}</td>
        <td>
          {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
          {shortMonthNames[d.getMonth()]} {d.getFullYear()}
        </td>
        {status.toUpperCase() === "ACTIVE" ? (
          <td className="table-status">
            <div className="status-btn success">{"Active"}</div>
          </td>
        ) : (
          <td className="table-status">
            <div className="status-btn error">{"Suspended"}</div>
          </td>
        )}
        <td className="table-action" onClick={() => callback(value)}>
          View
        </td>
      </tr>
    );
  });
};

const CustomersList = props => {
  const { oCustomerReducer } = props;
  const { allCustomers} = oCustomerReducer;
  const dispatch = useDispatch();
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);
  /* const { createdAt } = selectedCustomer;
  const d = new Date(createdAt);
  const customerCreatedDate = `${d.getDate()}, ${
    longMonthNames[d.getMonth()]
  } ${d.getFullYear()}`; */
  const pageSize = 10;

  return (
    <>
      {!showCustomerDetails && !showCreateCustomer && (
        <>
          <div className="charts-container row">
            <div className="table-menu-link">
              <span>Customers</span>
              <div className="action-buttons">
                {!!allCustomers && (
                  <div className="export-btn d-inline-block">
                    <CSVLink
                      data={allCustomers.data || []}
                      filename={"List of Customers.csv"}
                      className=" text-white"
                      target="_blank"
                    >
                      Export CSV{" "}
                      <i>
                        <ExportSVG />
                      </i>
                    </CSVLink>
                  </div>
                )}
                <button
                  className="add-btn"
                  onClick={() => {
                    props.onHideStatusBar(true);
                    setShowCreateCustomer(true);
                  }}
                >
                  <i>
                    <CreateSVG />
                  </i>
                  Create new customer
                </button>
              </div>
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <td className="table-title">Name</td>
                  <td className="table-title">Email</td>
                  <td className="table-title">Customer ID</td>
                  <td className="table-title">
                    Date Created
                    <i className="filter-bars">
                      <FilterSVG />
                    </i>
                  </td>
                  <td className="table-title">
                    Status
                    <i className="filter-bars">
                      <FilterSVG />
                    </i>
                  </td>
                  <td className="table-title"></td>
                </tr>
              </thead>
              <tbody>
                {allCustomers.count > 0 &&
                  displayCustomers(allCustomers.data, selectedCustomer => {
                    setSelectedCustomer(selectedCustomer);
                    props.onHideStatusBar(true);
                    setShowCustomerDetails(true);
                  })}
                {allCustomers.count < 0 ||
                  (!allCustomers.count && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        {"No customer available"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Pagination count={allCustomers.count} pageSize={pageSize} fetchMethod={(page, pageSize) => dispatch(fetchAllCustomers(page, pageSize))} />
          </div>
        </>
      )}
      {showCustomerDetails && (
        <CustomerProfile
          {...props}
          selectedCustomer={selectedCustomer}
          backFunction={() => {
            setShowCustomerDetails(false);
            props.onHideStatusBar(false);
          }}
          onEditingDone = { () => {
            setShowCustomerDetails(false);
            props.onHideStatusBar(false);
            window.location.reload();
          }}
        />
      )}
      {showCreateCustomer && (
        <CreateCustomer
          {...props}
          backFunction={() => {
            setShowCreateCustomer(false);
            props.onHideStatusBar(false);
          }}
          onCreatingDone={() => window.location.reload()}
        />
        
      )}
    </>
  );
};

export default CustomersList;
