const customerTrailReducer = (
  state = {
    customerTrail: [
      { id: '8376321', action: 'Create', description: 'Create 20 cards, bulk', user: 'Romiro@kobo360.com', status: 'Success', datetime: '12/12/2019, 12:42pm' },
      { id: '8392821', action: 'Create', description: 'Create 10 cards, bulk', user: 'miro@kobo360.com', status: 'Success', datetime: '12/12/2019, 12:42pm' },
      { id: '11176321', action: 'Delete', description: 'Deleted 20 cards, bulk', user: 'Amiro@kobo360.com', status: 'Failed', datetime: '12/12/2019, 12:42pm' },
      {
        id: '83763098721',
        action: 'Create',
        description: 'Create 20 cards, bulk',
        user: 'Romiro@kobo360.com',
        status: 'Success',
        datetime: '12/12/2019, 12:42pm',
      },
      { id: '83098721', action: 'Create', description: 'Create 10 cards, bulk', user: 'omira@kobo360.com', status: 'Success', datetime: '12/12/2019, 12:42pm' },
      {
        id: '83763021',
        action: 'Update',
        description: 'Updated 20 cards, bulk',
        user: 'aaamo@kobo360.com',
        status: 'Success',
        datetime: '12/12/2019, 12:42pm',
      },
      { id: '8376309', action: 'Create', description: 'Create 20 cards, bulk', user: 'Romiro@kobo360.com', status: 'Success', datetime: '12/12/2019, 12:42pm' },
      {
        id: '763098721',
        action: 'Create',
        description: 'Create 20 cards, bulk',
        user: 'Romiro@kobo360.com',
        status: 'Success',
        datetime: '12/12/2019, 12:42pm',
      },
    ],
    fetching: false,
    fetched: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case 'FETCH_USERS': {
      return { ...state, fetching: true };
    }
    case 'TOGGLE_MODAL': {
      return { ...state, userCreateModal: !state.userCreateModal };
    }
    case 'FETCH_USERS_REJECTED': {
      return { ...state, fetching: false, error: action.payload };
    }
    case 'FETCH_USERS_FULFILLED': {
      return { ...state, fetching: false, fetched: true, users: action.payload };
    }
    case 'UPDATE_USERS': {
      const { id } = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex(user => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers,
      };
    }
    case 'DELETE_USERS': {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    default: {
      return state;
    }
  }
};

export default customerTrailReducer;
