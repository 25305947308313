import React, {useState, useEffect} from "react";
import {/* useDispatch, */ useSelector/* , batch */} from "react-redux";
import StatusBar from '../StatusBar';
import VendorsList from "./VendorsList";
import { useParams } from "react-router";

const Vendors = () => {
    // const dispatch = useDispatch();
    const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);
    const params = useParams();
    const [vendorId, setVendorId] = useState(params.id && !isNaN(params.id) ? params.id: null)
    const props = useSelector(state => state);

    useEffect(() => {
        vendorId ? setIsStatusBarVisible(false): setIsStatusBarVisible(true);
    }, [])

    return (
        <div className={`${isStatusBarVisible?"page-container":"profile-page-container"}`}>
            <StatusBar isVisible={isStatusBarVisible}
                {...props}
            />
            <VendorsList {...props} setVendor={vendorId} unsetVendor={(id) => setVendorId(id)} onHideStatusBar={(status) => setIsStatusBarVisible(!status)} />
        </div>
    )
}

export default Vendors;
