import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../../../src/assets/Styles/Dashboard.css";
import AuditStatusBar from './AuditStatusBar';
import { fetchAllTrailByCustomer } from "../redux/action/CustomerTrailActions";
import AuditTrailList from "./AuditTrailList";
import moment from "moment";
import { getUserProperties } from "../../../lib/Constants/UserTypes";

function AuditTrail () {
  const dispatch = useDispatch();
  const props = useSelector(state => state);

  const startDate = moment(new Date(moment().subtract(365, 'days').format('LLLL')));
  const endDate = moment(new Date(moment().subtract(0, 'days').format('LLLL')));
  const defaultDateRange =[startDate, endDate];
  const [dateRange, setDateRange] = useState([startDate, endDate]);

  const handleCalendarChange = ([startDate0, endDate0]) => {
    setDateRange([moment(startDate0), moment(endDate0)]);
  };
  const resetCalendar =()=>{
    setDateRange([startDate, endDate]);
  }

  return (
    <div className="dashboard-container">
      <AuditStatusBar {...props} isVisible={true} />
      <AuditTrailList 
        {...props} 
        dateFilterRange={dateRange}
        resetCalendar={resetCalendar}
        defaultDateFilterRange={defaultDateRange}
        onCalendarChange = {handleCalendarChange}
        onFetchTrails = {(pageNo, pageSize, action) => {
          dispatch(fetchAllTrailByCustomer(
            getUserProperties().customer, 
            pageNo, 
            pageSize, 
            dateRange[0].format('YYYY-MM-DD'), 
            dateRange[1].format('YYYY-MM-DD'), 
            action
          ));
        }}
      />
    </div>
  )
}

export default AuditTrail;