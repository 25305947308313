import React, { useState/* , useEffect */} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import ReportStatusBar from "../StatusBar";
import TransactionList from "./TransactionList";
import fileDownload from "js-file-download";
import { DatePicker, Radio, Select } from "antd";
import moment from "moment";
import {
  downloadTransactionsByCustomer,
  // getTransactionsByCustomer,
  getTransactionsByCustomerForCDasboard,
} from "../../redux/action/CustomerActions";
import { loadAllCustomerVendors, fetchAllLocations } from "../../redux/action/OwnerVendorActions";
import {getUserProperties/* , getUser */} from "../../../../lib/Constants/UserTypes";
import { useEffect } from "react";

function Transactions() {
  const dispatch = useDispatch();

  const startDate = moment(new Date(moment().subtract(365, "days").format("LLLL")));
  const endDate = moment(new Date(moment().subtract(0, "days").format("LLLL")));

  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [vendorNum, setVendorNum] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState(5);

  const props = useSelector((state) => state);
  const { allCustomerVendors } = props.customerVendor;
  const { allLocations } = props.oVendorReducer;
  const { customer = 0, customerNumber = '' } = getUserProperties() || {};

  useEffect(() => {
    if (props.oTrailReducer.cDashoardTransactionsBlob) {
      fileDownload(props.oTrailReducer.cDashoardTransactionsBlob, 'Transaction-Report.xlsx');
      dispatch({ type: 'DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_RESET' });
    }
  },[props.oTrailReducer.cDashoardTransactionsBlob]);

  useEffect(()=> {
    batch(()=>{
      dispatch(loadAllCustomerVendors(customer));
      dispatch(fetchAllLocations());
    })
  }, []);

  const handleCalendarChange = ([startDate0 = startDate, endDate0 = endDate]) => {
    setDateRange([moment(startDate0), moment(endDate0)]);
  };

  return (
    <div className="dashboard-container">
      <ReportStatusBar {...props} isVisible={true} customerNumber={customerNumber} />
      
      <div className="row">
        <div className="col-md-5 text-right pr-0 mt-md-1 mt-2">
          <span className="filter-tags-desc">Filters:</span>
          <Select 
            defaultValue={vendorNum}
            className="mr-3"
            placeholder="Choose Vendor"
            // showSearch
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={value => setVendorNum(value)}>
            <Select.Option value="">All Vendors</Select.Option>
            {(allCustomerVendors.data || []).map((v, i) => (
              <Select.Option value={v.number} key={i}>{v.name}</Select.Option>
            ))}
          </Select>
          <Select 
            defaultValue={location}
            placeholder="Choose Location"
            // allowClear 
            // showSearch
            // filterOption={(input, option) =>allLocations
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={value => setLocation(value)}>
            <Select.Option value="">All Locations</Select.Option>
            {(allLocations.data || []).map((l, i) => (
              <Select.Option value={l.number} key={i}>{l.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="col-md-3 text-right pr-0 mt-md-1 mt-2">
          <Radio.Group defaultValue={status} buttonStyle="solid" onChange={(e) => setStatus(e.target.value)}>
            <Radio.Button value={5}>ALL</Radio.Button>
            <Radio.Button value={1}>SUCCESSFUL</Radio.Button>
            <Radio.Button value={0}>FAILED</Radio.Button>
          </Radio.Group>
        </div>
        <div className="col-md-4 text-right pr-0 mt-md-1 mt-2">
          <DatePicker.RangePicker
            defaultValue={dateRange}
            onCalendarChange={handleCalendarChange}
            format={"DD-MMMM-YYYY"}
            allowClear={false}
          />
        </div>
      </div>

      <TransactionList
        {...props}
        downloading={props.oTrailReducer.downloading}
        dateRange={dateRange}
        status={status}
        vendorNum={vendorNum}
        location={location}
        onDownloadTransactions={() => {
          dispatch(downloadTransactionsByCustomer(
            customerNumber,
            dateRange[0].format('YYYY-MM-DD'),
            dateRange[1].format('YYYY-MM-DD'),
            status,
            { vendorNum, location }
          ))
        }}
        onFetchTransactions={(pageNo, pageSize) =>
          dispatch(
            getTransactionsByCustomerForCDasboard(
              customerNumber,
              pageNo,
              pageSize,
              dateRange[0].format('YYYY-MM-DD'),
              dateRange[1].format('YYYY-MM-DD'),
              status,
              { vendorNum, location }
            )
          )
        }
        customerNumber={customerNumber}
      />
    </div>
  );
}

export default Transactions;
