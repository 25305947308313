import React from 'react';
const NewAccountIcon = () => <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#EBF8FE"/>
<path d="M28 32C30.7614 32 33 29.7614 33 27C33 24.2386 30.7614 22 28 22C25.2386 22 23 24.2386 23 27C23 29.7614 25.2386 32 28 32Z" stroke="#0275D8" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M19.9749 36.9218C20.7281 35.4408 21.8765 34.1971 23.2929 33.3284C24.7093 32.4598 26.3384 32 28 32C29.6615 32 31.2906 32.4598 32.707 33.3284C34.1234 34.1971 35.2718 35.4407 36.0251 36.9217" stroke="#0275D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M34 19H40" stroke="#0275D8" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M37 16V22" stroke="#0275D8" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M39.8516 26.1072C40.2488 28.6079 39.844 31.1702 38.6953 33.4267C37.5465 35.6831 35.7128 37.5179 33.4569 38.6679C31.2011 39.818 28.6391 40.2242 26.1382 39.8284C23.6373 39.4325 21.3259 38.255 19.5355 36.4646C17.745 34.6741 16.5675 32.3628 16.1717 29.8618C15.7759 27.3609 16.1821 24.7989 17.3321 22.5431C18.4822 20.2873 20.3169 18.4535 22.5734 17.3048C24.8299 16.156 27.3921 15.7512 29.8928 16.1485" stroke="#0275D8" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
</svg>

export default NewAccountIcon;