import React, { useEffect } from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import { shortMonthNames } from "../../../lib/Functions/DateNames";
import { useDispatch, batch} from "react-redux";
import { fetchAllTerminalsByVendor } from "../../ownerModule/redux/action/OwnerVendorActions";
import { getUserProperties } from "../../../lib/Constants/UserTypes";
import { fetchVendorPosUsers } from "../../../actions/VendorActions/TerminalActions/TerminalAction";

const StatusBar = (props) => {
  const dispatch = useDispatch();
  const venId = getUserProperties().vendor;

  useEffect(() => {
    batch(() => {
      dispatch(fetchAllTerminalsByVendor(venId, 1, 10));
      dispatch(fetchVendorPosUsers(venId, 1, 10));
    });
  }, []);

  const {oVendorReducer, vendorTerminal} = props;
  const {allTerminalsByVenId} = oVendorReducer;
  const posUsers = vendorTerminal.allPOSUsers;

  let activeTerminals = 0, inactiveTerminals = 0, activePosUser = 0, inactivePosUser = 0;
  allTerminalsByVenId && allTerminalsByVenId.data && allTerminalsByVenId.data.forEach(value => {
    if(value.status === "ACTIVE"){
      activeTerminals += 1;
    } else inactiveTerminals += 1;
  })

  posUsers && posUsers.data && posUsers.data.forEach(value => {
    if(value.status === "ACTIVE") {
      activePosUser += 1;
    } else inactivePosUser += 1;
  });
  const d = new Date();

  return (
    <div
      className={`status-container row ${
        props.isVisible ? "d-block" : "d-none"
      }`}
    >
      <div className="compartment compartment-one col-3">
        <span className="title d-block mb-2">Terminals</span>
        <span className="sub-title">
          Registered terminals and POS accounts.
        </span>
        <br />
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
      </div>
      <div className="compartment compartment-two col-9 ">
        <div className="row token-status-box">
          <div
            className="col col-6"
            style={{
              position: "relative",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 16,
              width: "50%",
            }}
          >
            <div className="row">
              <span className="title">Terminals</span>
            </div>
            <div className="row status-sub-title">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ACTIVE</div>
              <div className="col-4 pl-0">INACTIVE</div>
            </div>
            <div className="row status-values">
              <div className="col-4 pl-0">
                {(allTerminalsByVenId && allTerminalsByVenId.count) || 0}
              </div>
              <div className="col-4 pl-0">{activeTerminals}</div>
              <div className="col-4 pl-0">{inactiveTerminals}</div>
            </div>
            <div className="terminals-short-borders" style={{top: 23}}></div>
          </div>
          <div
            className="col col-6"
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 16,
              width: "50%",
            }}
          >
            <div className="row">
              <span className="title">POS Users</span>
            </div>
            <div className="row status-sub-title">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ACTIVE</div>
              <div className="col-4 pl-0">INACTIVE</div>
            </div>
            <div className="row status-values">
              <div className="col-4 pl-0">
                {(posUsers && posUsers.count) || 0}
              </div>
              <div className="col-4 pl-0">{activePosUser}</div>
              <div className="col-4 pl-0">{inactivePosUser}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusBar;