const customerSettleReducer = (
  state = {
    customerSettle: [
      {
        id: '8376321',
        customerId: 'Create',
        customerName: 'Romiro@kobo360.com',
        vendorId: '8737737131',
        vendorName: 'Oando',
        cardCount: '121',
        voucherCount: '109',
        cardSubTotal: 'N723737',
        voucherSubTotal: 'N872666',
        total: 'N8726351',
        startDate: '12/12/2019, 12:42pm',
        endDate: '12/12/2019, 12:42pm',
        gracePeriodEnd: '12/12/2019, 12:42pm',
        status: 'status',
      },
      {
        id: '837634221',
        customerId: 'Create',
        customerName: 'Romiro@kobo360.com',
        vendorId: '8737737131',
        vendorName: 'Oando',
        cardCount: '121',
        voucherCount: '109',
        cardSubTotal: 'N723737',
        voucherSubTotal: 'N872666',
        total: 'N8726351',
        startDate: '12/12/2019, 12:42pm',
        endDate: '12/12/2019, 12:42pm',
        gracePeriodEnd: '12/12/2019, 12:42pm',
        status: 'status',
      },
      {
        id: '8376321',
        customerId: 'Create',
        customerName: 'Romiro@kobo360.com',
        vendorId: '8737737131',
        vendorName: 'Oando',
        cardCount: '121',
        voucherCount: '109',
        cardSubTotal: 'N723737',
        voucherSubTotal: 'N872666',
        total: 'N8726351',
        startDate: '12/12/2019, 12:42pm',
        endDate: '12/12/2019, 12:42pm',
        gracePeriodEnd: '12/12/2019, 12:42pm',
        status: 'status',
      },
      {
        id: '83763098721',
        customerId: 'Create',
        customerName: 'Romiro@kobo360.com',
        vendorId: '8737737131',
        vendorName: 'Oando',
        cardCount: '121',
        voucherCount: '109',
        cardSubTotal: 'N723737',
        voucherSubTotal: 'N872666',
        total: 'N8726351',
        startDate: '12/12/2019, 12:42pm',
        endDate: '12/12/2019, 12:42pm',
        gracePeriodEnd: '12/12/2019, 12:42pm',
        status: 'status',
      },
      {
        id: '8309800721',
        customerId: 'Create',
        customerName: 'Romiro@kobo360.com',
        vendorId: '8737737131',
        vendorName: 'Oando',
        cardCount: '121',
        voucherCount: '109',
        cardSubTotal: 'N723737',
        voucherSubTotal: 'N872666',
        total: 'N8726351',
        startDate: '12/12/2019, 12:42pm',
        endDate: '12/12/2019, 12:42pm',
        gracePeriodEnd: '12/12/2019, 12:42pm',
        status: 'status',
      },
      {
        id: '1183763021',
        customerId: 'Create',
        customerName: 'Romiro@kobo360.com',
        vendorId: '8737737131',
        vendorName: 'Oando',
        cardCount: '121',
        voucherCount: '109',
        cardSubTotal: 'N723737',
        voucherSubTotal: 'N872666',
        total: 'N8726351',
        startDate: '12/12/2019, 12:42pm',
        endDate: '12/12/2019, 12:42pm',
        gracePeriodEnd: '12/12/2019, 12:42pm',
        status: 'status',
      },
      {
        id: '854211376309',
        customerId: 'Create',
        customerName: 'Romiro@kobo360.com',
        vendorId: '8737737131',
        vendorName: 'Oando',
        cardCount: '121',
        voucherCount: '109',
        cardSubTotal: 'N723737',
        voucherSubTotal: 'N872666',
        total: 'N8726351',
        startDate: '12/12/2019, 12:42pm',
        endDate: '12/12/2019, 12:42pm',
        gracePeriodEnd: '12/12/2019, 12:42pm',
        status: 'status',
      },
      {
        id: '30981221721',
        customerId: 'Create',
        customerName: 'Romiro@kobo360.com',
        vendorId: '8737737131',
        vendorName: 'Oando',
        cardCount: '121',
        voucherCount: '109',
        cardSubTotal: 'N723737',
        voucherSubTotal: 'N872666',
        total: 'N8726351',
        startDate: '12/12/2019, 12:42pm',
        endDate: '12/12/2019, 12:42pm',
        gracePeriodEnd: '12/12/2019, 12:42pm',
        status: 'status',
      },
    ],
    fetching: false,
    fetched: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case 'FETCH_USERS': {
      return { ...state, fetching: true };
    }
    default:
      return {...state};
  }
};

export default customerSettleReducer;
