import React from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import { shortMonthNames } from "../../../lib/Functions/DateNames";

const AuditStatusBar = (props) => {
  const {oTrailReducer} = props;
  const d = new Date();
  const {count, data} = oTrailReducer.oTrail;
  let trailArr = oTrailReducer.oTrail === undefined ? [] :data && data.map((trailData,index)=>({...trailData,serialNum:index+1}));
    
    var groupedTrails = trailArr && trailArr.reduce((obj, item)=>{
      obj[item.useremail] = obj[item.useremail] || [];
      obj[item.useremail].push(item);
      return obj;
    }, {});
    let totalUsers = [];
    let userActionCount = trailArr && Object.keys(groupedTrails).length;
    let login = 0, create = 0, update = 0, deletes = 0, disable=0;
    
    trailArr && trailArr.forEach(trailData => {
      !totalUsers.includes(trailData.useremail) && totalUsers.push(trailData.useremail);
        switch(trailData.action){
          case 'LOGIN':
            login++
            break;
          case 'CREATE':
            create++
            break;
          case 'UPDATE':
            update++
            break;
          case 'DELETE':
            deletes++
            break;
          case 'DISABLE':
            disable++
            break;
          default:
        }
    });


  return (
    <div className="status-container row">
      <div className="compartment compartment-one col col-2">
        <span className="title d-block mt-3 mb-3 mr-3">Audit Trail</span>
        <span className="sub-title d-block mb-2">Users actions and activities performed</span>
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${shortMonthNames[d.getMonth()]} ${d.getFullYear()} `}</span>
      </div>
      
      <div className="compartment report-compartment-two col-3">
        <div className="p-3">
            <span className="sub-title mb-0 text-dark font-weight-bold">Total Number of Users</span>
            <span className="count qt-black font-weight-bold">{totalUsers.length}</span>
            <span className="sub-title mt-0 mb-0 text-dark font-weight-bold">Users who performed actions</span>
            <span className="count mt-1 qt-black font-weight-bold">{userActionCount || 0}</span>
        </div>
      </div>

      <div className="compartment compartment-two col-7">
        <div className="row token-status-box">
          <div className="col col-12" style={{position: "relative", paddingLeft: 40, paddingRight: 40, paddingTop: 16, paddingBottom: 18,}}>
            <div className="row mb-4"><span className="title">Summary Actions</span></div>
            <div className="row status-sub-title">
              <div className="col-2 pl-0">TOTAL{/*  ACTIONS */}</div>
              <div className="col-2 pl-0 text-center">CREATE </div>
              <div className="col-2 pl-0 text-center">UPDATE</div>
              <div className="col-2 pl-0 text-center">DELETE</div>
              <div className="col-2 pl-0 text-center">LOGIN</div>
              <div className="col-2 pl-0 text-center">DISABLE</div>
            </div>
            <div className="row status-values">
              <div className="col-2 pl-0">{count || 0}</div>
              <div className="col-2 pl-0 text-center">{create || 0}</div>
              <div className="col-2 pl-0 text-center">{update || 0}</div>
              <div className="col-2 pl-0 text-center">{deletes || 0}</div>
              <div className="col-2 pl-0 text-center">{login || 0}</div>
              <div className="col-2 pl-0 text-center">{disable || 0}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AuditStatusBar;