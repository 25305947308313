import React from 'react';

const PaymentStatus =({status}) =>{
    const returnStatus =(status) =>{
        switch(status){
            case 'SUCCESS':
                return( <span className='d-flex status-badge d-flex align-items-center'> <span className='badge-dot'></span> Success</span>);
            case 'PENDING' :
                return (<span className='d-flex status-badge pending d-flex align-items-center'> <span className='badge-dot'></span> Pending</span>);
        default:
            return(<></>)
        }
    }
    return(<>{returnStatus(status)}</>)
}

export default PaymentStatus;