import React, { useState, useEffect } from "react";
import {Modal, ModalBody } from "reactstrap";
import {
  BackSVG,
  ActiveMarkSVG,
  Payment,
  GrayMark,
  CancelSVG,
  AvatarSVG,
  DisabledMark,
  UserCompleteMarkSVG,
  LoadingSVG,
  PurseSvg
} from "../../../../assets/Svgs/Svgs";
import {
  shortMonthNames,
  shortDayNames,
} from "../../../../lib/Functions/DateNames";
import EditForm from "./EditVendor";
import { useDispatch } from "react-redux";
import {
  toggleVendorStatus,
  resetVendorUpdate,
  resetStatusFetch
} from "../../redux/action/OwnerVendorActions";
import formatAmount from "../../../../lib/Functions/formatAmount";
import {
  loadAllVendorCustomer,
  getTransactionsByVendor,
  loadAllCustomer
} from "../../../vendorModule/redux/actions/CustomerActions";
import TransactionReport from "./TransactionReport";
import moment from "moment";
import ContractManagement from "./ContractManagement";
import AddCustomer from "./AddCustomer";
import CreateProduct from "./CreateProduct";
import AddProduct from "./AddProduct";
import { history } from "../../../../helpers/history";
import VendorSettings from "./VendorSettings";
import SettlementAccount from "./SettlementAccount";
import { StatePriceComponent } from "../../../shared/StatePrice/StatePrice";

const AccountDetails = props => {
  const [showCustomerList, setShowCustomerList] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const { selectedVendor } = props;
  const {
    // id,
    name,
    contact,
    rcNumber,
    address,
    // description,
    createdAt,
    number,
    status,
    contracts,
    // image,
    products
  } = selectedVendor;
  const d = new Date(createdAt);
  const dispatch = useDispatch();

  const [showCreateSuccessModal, setShowCreateSuccessModal] = useState(false);
  const [profilePix, setProfilePix] = useState("");// eslint-disable-line no-unused-vars
  const [vendorCreationError, setVendorCreationError] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  // const [resetForm, setResetForm] = useState(false);
  const [saveEditForm, setSaveEditForm] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [accountStatus, setAccountStatus] = useState(status);
  const {/* isCreatingVendor, */ fetchingStatus, isUpdatingVendor, editingAccountStatus, isTogglingStatus/* , allVendors */} = props.oVendorReducer;
  const [
    showVendorTransactionReport,
    setShowVendorTransactionReport
  ] = useState(false);

  const [showSettlementModal, setShowSettlementModal] = useState();
  const [selectedProduct, setSelectedProduct ] = useState();

  // const toggleModal = () => {
  //   setShowCreateSuccessModal(false);
  //   dispatch(resetVendorUpdate());
  // };
  useEffect(() => {
    if (fetchingStatus === 1) {
      setAccountStatus(accountStatus === "ACTIVE"? "DISABLED": "ACTIVE");
      setShowCreateSuccessModal(true);
      dispatch(resetVendorUpdate());
    } else if (fetchingStatus === 2) {
      setVendorCreationError(true);
    }
  }, [fetchingStatus]);

  useEffect(() => {
    if (editingAccountStatus === 1) {
      setAccountStatus(accountStatus === "ACTIVE" ? "DISABLED": "ACTIVE");
    } else if (editingAccountStatus === 2) {
    }
  }, [editingAccountStatus]);

  useEffect(() => {
    setShowCreateSuccessModal(false);
    dispatch(resetVendorUpdate());
    dispatch(resetStatusFetch());
    fetchVendorCustomers(selectedVendor.id);
    //fetch all customers
    dispatch(loadAllCustomer());
    //vendor Transaction Report
    getVendorTransactions();
  }, []);

  function fetchVendorCustomers(id, page = 1, size = 30) {
    dispatch(loadAllVendorCustomer(id, page, size));
  }

  function getVendorTransactions(pageNo = 1, pageSize = 30) {
    // const { startDate, endDate, page, pageSize, status, customerNumber, vendorNum, location } = this.state;
    const startDate = new Date(
        moment()
          .subtract(30, "days")
          .format("LLLL")
      ),
      endDate = new Date(),
      start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    const filterParams = {
      vendorNum: null,
      location: null,
      startDate: start,
      endDate: end
    };
    dispatch(getTransactionsByVendor(selectedVendor.number, filterParams));
  }

  const handleSettlementModal = () =>{
    setShowSettlementModal(!showSettlementModal);
  }

  //when done fetching customers of a vendor
  const { activeCustomers } = props.vendorCustomer;
  const customerList =
    activeCustomers &&
    activeCustomers.map((val, i) => {
      const { status, name, sector, contracts } = val;
      return (
        <tr key={i}>
          <td>{name || ""}</td>
          <td>{sector || ""}</td>
          <td>{contracts.length}</td>
          {/* <td>Active <i><ActiveMarkSVG /></i></td> */}
          <td>
            {status === "ACTIVE" ? "Active " : "Inactive "}
            <i>{status === "ACTIVE" ? <ActiveMarkSVG /> : <DisabledMark />}</i>
          </td>
          {/* <td className="deactivate">Deactivate</td> */}
          {/* <td className="view">View</td> */}
        </tr>
      );
    });

  /* const contractAvailability = selectedVendor.contracts
    ? selectedVendor.contracts.length
    : ""; */
  let boundArr = [],
    inactiveArr = [],
    vendor = selectedVendor,
    /* contractNo = vendor
      ? vendor.contracts.forEach(e => {
          boundArr.push(e.bound);
        })
      : [],
    contractsArr = vendor ? vendor.contracts : [],
    inactives = vendor
      ? vendor.contracts.forEach(e => {
          if (e.contractStatus === "INACTIVE") {
            inactiveArr.push(e.contractStatus);
          }
        })
      : [], */
    // contractAvailability = vendor ? vendor.contracts.length : '',
    sum = boundArr.reduce((a, b) => a + b, 0);

  return (
    <>
       <>
       <>
        <Modal
          isOpen={showSettings}
          backdrop="static"
          toggle={()=>setShowSettings(!showSettings)}
          className="settings-modal"
        >
          <div className="settings-modal-header">
            <p className="title">
               Vendor Settings
              <i onClick={()=>setShowSettings(!showSettings)}>
                <CancelSVG />
              </i>
            </p>
            <p className="subtitle">
              You can setup vendor activities enable/disable features
            </p>
          </div>
          <ModalBody>
            <VendorSettings vendorId={selectedVendor.number} onEditingDone={()=>setShowSettings(!showSettings)} />
            {/* <SettlementAccount /> */}
          </ModalBody>
        </Modal>
      </>
       </>
      <>
        <Modal
          isOpen={isUpdatingVendor || showCreateSuccessModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="user-modal"
        >
          <ModalBody>
            {isUpdatingVendor && (
              <>
                <div className="text-center mt-5 header">
                  Editing vendor....
                </div>
                <div className="text-center mt-5 mb-5">
                  <LoadingSVG />
                </div>
              </>
            )}
            {showCreateSuccessModal && (
              <>
                <div className="text-center mt-5 mb-4">
                  <span
                    className="d-block font-weight-bold"
                    style={{
                      fontSize: "20px",
                      color: "#4B4B4B",
                      margin: "1rem"
                    }}
                  >
                    Thank you! You are all set!
                  </span>
                  <UserCompleteMarkSVG />
                  <span
                    className="d-block quickteller-blue font-weight-bold"
                    style={{
                      fontSize: "1rem",
                      color: "#00425F",
                      margin: "1.25rem"
                    }}
                  >
                    Your vendor profile has been successfully edited
                  </span>
                </div>
                <div className="row modal-action-buttons">
                  <div className="col-12 text-center">
                    <button
                      className="back"
                      onClick={() => {
                        props.onEditingDone();
                      }}
                      style={{ width: 120 }}
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
              </>
            )}
          </ModalBody>
        </Modal>
      </>
      <>
        <Modal
          isOpen={showTransactionDetails}
          // show={showContractDetails}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          className="rfid-modal"
        >
          <ModalBody>
            <ContractManagement
              contracts={selectedVendor.contracts}
              onCloseHandler={() => setShowTransactionDetails(false)}
              onAddProductHandler={() => {
                setShowTransactionDetails(false);
                setShowAddProduct(true);
              }}
            />
          </ModalBody>
        </Modal>
      </>
      
      <>
        <>
          <Modal
            isOpen={showAddCustomer}
            // show={showContractDetails}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            dialogClassName="rfid-modal"
          >
            <ModalBody>
              <AddCustomer
                {...props}
                allCustomers={
                  props.vendorCustomer && props.vendorCustomer.allCustomers
                }
                vendorId={selectedVendor.id}
                onCloseHandler={() => setShowAddCustomer(false)}
              />
            </ModalBody>
          </Modal>
        </>
        <>
          <Modal
            isOpen={showCreateProduct}
            // show={showContractDetails}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            dialogClassName="rfid-modal"
          >
            <ModalBody>
              <CreateProduct
                {...props}
                allCustomers={
                  props.vendorCustomer && props.vendorCustomer.allCustomers
                }
                vendorId={selectedVendor.id}
                onCloseHandler={() => setShowCreateProduct(false)}
              />
            </ModalBody>
          </Modal>
        </>

        <>
        <Modal
          isOpen={showAddProduct}
          // show={showContractDetails}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          dialogClassName="rfid-modal"
        >
          <ModalBody>
            <AddProduct
              {...props}
              contracts={selectedVendor.contracts}
              products = {selectedVendor.products}
              allCustomers={
                props.vendorCustomer && props.vendorCustomer.allCustomers
              }
              vendorId={selectedVendor.id}
              onCloseHandler={() => setShowAddProduct(false)}
            />
          </ModalBody>
        </Modal>
      </>
      </>
      <div className="underlined-tab">
        <span className="tab-title">Vendor Profile</span>
        <div className="action-buttons">
          <button className="export-btn" onClick={() => {
            showEditForm ? props.onQuitEditing(): props.backFunction();
            setShowEditForm(false);
            history.go(-1);
          }}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className="row account-details">
        <div className="col-3 profile-picture-and-actions">
          <div className="avatar">
            {selectedVendor.image ? (
              <img
                alt="Profile Face"
                src={profilePix.base64 || selectedVendor.image}
                style={{ width: 100, height: 100, borderRadius: 50 }}
              />
            ) : (
              <AvatarSVG />
            )}
          </div>
          {/* {showEditForm && (
            <div className="upload">
              <i>
                <UploadSVG />
              </i>{" "}
              <FileBase64
                multiple={false}
                onDone={file => {
                  if (file.type === "image/jpeg" || file.type === "image/png")
                    setProfilePix(file);
                }}
              />
            </div>
          )} */}
          {/* <button className="button remove-btn">Remove</button> */}
          {showEditForm && (
            <button
              className="button save-btn"
              onClick={() => setSaveEditForm(true)}
            >
              Save changes
            </button>
          )}
          {!showEditForm && (
            <button
              className="button edit-profile-btn"
              onClick={() => setShowEditForm(true)}
            >
              Edit Profile
            </button>
          )}
          {!showEditForm && (
            <button
              className={`button ${
                accountStatus === "ACTIVE"
                  ? "suspend-btn"
                  : "activate-btn"
              }`}
              onClick={() => {
                dispatch(
                  toggleVendorStatus(
                    selectedVendor.id,
                    accountStatus === "ACTIVE" ? "DISABLED" : "ACTIVE"
                  )
                );
              }}
            >
              {isTogglingStatus ? (
                <img
                  src="/images/ActivityIndicator.gif" alt="activity-indicator"
                  style={{ height: 20, width: 20, marginTop: 0 }}
                />
              ) : (
                `${
                  accountStatus === "ACTIVE" ? "Suspend" : "Activate"
                } Account`
              )}
            </button>
          )}
          {!showEditForm && (
            <button
              className="button settings-btn"
              onClick={() => setShowSettings(!showSettings)}
            >
              Settings
            </button>
          )}
          <button
              className="button settings-btn"
              onClick={handleSettlementModal}
            >
              Add Settlement Account
            </button>
        </div>
        {!showEditForm && (
          <>
            <div className="col-9">
              {!showVendorTransactionReport && (
                <>
                  <div className="row user-info">
                    <div className="row user-info-title">
                      <div className="col-3">Vendor Name</div>
                      <div className="col-3">RC Number</div>
                      <div className="col-3">Email</div>
                      <div className="col-3">Phone Number</div>
                    </div>
                    <div className="row user-info-details">
                      <div className="col-3" title={name}>
                        {name}
                      </div>
                      <div className="col-3" title={rcNumber}>
                        {rcNumber}
                      </div>
                      <div className="col-3" style={{overflowWrap: "break-word"}} title={contact.email}>
                        {contact.email}
                      </div>
                      <div className="col-3" title={contact.phoneNumber}>
                        {contact.phoneNumber}
                      </div>
                    </div>
                  </div>
                  <div className="row user-extras">
                    <div className="row user-extras-title">
                      <div className="col-3">Date Created</div>
                      <div className="col-3">Status</div>
                      <div className="col-3">Building Number</div>
                      <div className="col-3">Street</div>
                    </div>
                    <div className="row user-extras-detail">
                      <div className="col-3">
                        {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
                        {shortMonthNames[d.getMonth()]} {d.getFullYear()}
                      </div>
                      {accountStatus === "ACTIVE" ? (
                        <div className="col-3">
                          Active <ActiveMarkSVG />
                        </div>
                      ) : (
                        <div className="col-3">
                          Inactive <DisabledMark />
                        </div>
                      )}
                      <div className="col-3">
                        {address.buildingNumber || "Unavailable"}
                      </div>
                      <div className="col-3">{address.street}</div>
                    </div>
                  </div>
                  <div className="row user-address">
                    <div className="row user-address-title">
                      <div className="col-3">City</div>
                      <div className="col-3">State</div>
                      <div className="col-3">Country</div>
                      <div className="col-3"></div>
                    </div>
                    <div className="row user-address-details">
                      <div className="col-3">
                        {(address && address.city) || "Unavailable"}
                      </div>
                      <div className="col-3">
                        {(address && address.state) || "Unavailable"}
                      </div>
                      <div className="col-3">
                        {(address && address.country) || "Unavailable"}
                      </div>
                      <div className="col-3"></div>
                    </div>
                  </div>
                  <div className="row vendor-overview">
                    <span className="overview-title">Account Overview</span>
                    <div className="inner">
                      <div className="row customer-stats">
                        <div className="col-3 credit-details">
                          <div
                            className="row credit-title"
                            style={{ fontSize: "0.8725rem", whiteSpace:'break-spaces' }}
                          >
                            AVAILABLE BALANCE
                          </div>
                          <div className="row amount">
                            ₦ {formatAmount(sum)}
                          </div>
                        </div>
                        <div className="col-9 stats">
                          <div className="row">
                            <div
                              className="col-4"
                              style={{ fontSize: "0.8725rem" }}
                            >
                              ALL CONTRACTS
                            </div>
                            <div
                              className="col-3"
                              style={{ fontSize: "0.8725rem" }}
                            >
                              INACTIVE
                            </div>
                            <div
                              className="col-4"
                              style={{ fontSize: "0.8725rem" }}
                            >
                              MY PRODUCTS
                            </div>
                          </div>
                          <div className="row numbers">
                            <div className="col-4">
                              {contracts.length}
                              <div
                                className="short-borders"
                                style={{ top: -23, background: "#fff" }}
                              ></div>
                            </div>

                            <div className="col-3">
                              {inactiveArr.length}
                              <div
                                className="short-borders"
                                style={{ top: -23, background: "#fff" }}
                              ></div>
                            </div>

                            <div className="col-4">{products.length}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row contract-details"
                        style={{ background: "#D4E1F4" }}
                      >
                        <div className="warning">
                          <div className="message" style={{ width: "60%" }}>
                            All awarded contract and allocated limits
                          </div>
                          <span
                            className="btn-view-contract"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowTransactionDetails(true);
                            }}
                          >
                            View contract details
                          </span>
                        </div>
                      </div>
                      <div className="payment-icon">
                        <i>
                          <Payment />
                        </i>
                      </div>
                    </div>
                  </div>
                 {selectedProduct && <div style={{ marginLeft: -15}}><StatePriceComponent product={selectedProduct.name} vendorCode={number} backFunction={()=>setSelectedProduct('')}  /></div>}
                 {!selectedProduct && <div className="row pl-5 reporting">
                    <span className="reporting-title ml-5 mb-3 d-block">
                      Products
                    </span>
                    <span className="reporting-title ml-5 mb-4 font-weight-normal">
                      Registered product offerings from vendor
                    </span>
                    <div className="col-5 reporting-title">
                      {vendor ? (
                        vendor.products.length ? (
                          vendor.products.map((p, index) => {
                            return (
                              <div key={index} className="mb-3 font-weight-bold">
                                <span
                                  style={{
                                    background: "#0172CB",
                                    borderRadius: 2,
                                    padding: "5px 10px",
                                    color: "#fff",
                                    marginRight: 10,
                                    textDecoration: "none"
                                  }}
                                >
                                  {index + 1}
                                </span>{" "}
                                <a onClick={()=>setSelectedProduct(p)}>{p.name}</a>
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <span style={{ fontSize: "16px " }}>No product added</span>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-5">
                      <div
                        className="btn-view-contract d-block mb-2 text-center"
                        style={{
                          cursor: "pointer",
                          float: "right",
                          clear: "both",
                          height: 30,
                          width: 201,
                          lineHeight: "30px",
                          padding: "0 10px"
                        }}
                        onClick={() => {
                          setShowAddCustomer(true);
                        }}
                      >
                        Add a customer to vendor
                      </div>
                      <div
                        className="btn-view-contract d-block text-center"
                        style={{
                          cursor: "pointer",
                          float: "right",
                          clear: "both",
                          height: 30,
                          width: 201,
                          border: "1px solid #5F738C",
                          color: "#5F738C",
                          lineHeight: "30px",
                          padding: "0 20px",
                          background: "transparent"
                        }}
                        onClick={() => {
                          setShowCreateProduct(true);
                        }}
                      >
                        Create new product
                      </div>
                    </div>
                  </div>}
                </>
              )}
              <div className="row reporting">
                <span className="reporting-title">Reporting</span>
                <div className="col-1">
                  <i>
                    <PurseSvg />
                  </i>
                </div>
                <div className="inner col-11">
                  <div className="row">
                    <div className="settlement-report col-8">
                      Vendor settlement and transaction report at a glance.
                    </div>
                    <div className="settlement-report-link col-4">
                      <a href="#report">
                        <i>
                          <GrayMark />
                        </i>
                        View Settlement Report
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="settlement-report col-8">
                      {!showCustomerList && (
                        <span
                          onClick={() => {
                            setShowCustomerList(true);
                            setShowVendorTransactionReport(false);
                          }}
                        >
                          View Customer List
                        </span>
                      )}
                    </div>
                    <div className="settlement-report-link col-4">
                      <a href="#a"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowVendorTransactionReport(
                            !showVendorTransactionReport
                          );
                          setShowCustomerList(false);
                        }}
                      >
                        <i>
                          <GrayMark />
                        </i>
                        {showVendorTransactionReport ? "Hide" : "View"}{" "}
                        Transaction Report
                      </a>
                    </div>
                  </div>
                </div>
                {showVendorTransactionReport && (
                  <div className="col-12">
                    <TransactionReport
                      transactions={
                        props.vendorCustomer.vendorTransactions  ||
                        []
                      }
                      onFetchTransactions={(pageNo, pageSize) => {
                        getVendorTransactions(pageNo, pageSize);
                      }}
                    />
                  </div>
                )}
                {showCustomerList && (
                  <div className="inner saved-accounts">
                    <div className="row">
                      <div className="header">
                        <a href="#b">
                          Customer List{" "}
                          <i onClick={() => setShowCustomerList(false)}>
                            <CancelSVG />
                          </i>
                        </a>
                      </div>
                    </div>
                    <div className="accounts-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <td className="accounts-table-title">Name</td>
                            <td className="accounts-table-title">Business</td>
                            <td className="accounts-table-title">Contracts</td>
                            <td className="accounts-table-title">Status</td>
                            <td className="accounts-table-title"></td>
                          </tr>
                        </thead>
                        <tbody className="accounts-table-body">
                          {customerList.length > 0 ? (
                            customerList
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center">
                                No cutomer to display
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {showEditForm && (
          <div className="col-8 edit-form">
            <EditForm
              {...props}
              create={false}
              saveEditForm={saveEditForm} 
              onSaveEditFormHandler={status => setSaveEditForm(status)}
              vendorToBeEdited={selectedVendor}
              resetForm={false}
              profilePix={{ base64: profilePix.base64 || profilePix }}
              vendorCreationError={vendorCreationError}
              vendorCreationErrorHandler={status =>
                setVendorCreationError(status)
              }
            />
          </div>
        )}
      </div>
      {showSettlementModal && <SettlementAccount  toggleModal={handleSettlementModal} vendor={selectedVendor} showModal={showSettlementModal} />}
    </>
  );
};

export default AccountDetails;
