import React, { useState } from "react";
import {
  BackSVG,
  AvatarSVG,
  ProductSvg,
  AccountSvg,
} from "../../../assets/Svgs/Svgs";
import { Button } from 'antd';
import formatAmount from "../../../lib/Functions/formatAmount";
import { batch, useDispatch } from "react-redux";
import { fetchOneVendorCustomerContract } from "../redux/action/OwnerVendorActions";
import { removeCustomerVendorContract } from "../redux/action/VendorAction";
import { StatePriceComponent } from "../../shared/StatePrice/StatePrice";


const VendorDetails = (props) => {
  const [selectedProduct, setSelectedProduct ] = useState();
  const dispatch = useDispatch();
  const { selectedVendor, authentication, oVendorReducer, showLocation } = props;
  const {
    number,
    image,
    description,
    products,
    locations
  } = selectedVendor;

  const contractData = oVendorReducer.oneVendorContract ? oVendorReducer.oneVendorContract.data : {};
  const sum = contractData ? contractData.bound * 100 : 0;

  // const walletBalance = oVendorReducer.walletBalanceInContract ? oVendorReducer.walletBalanceInContract.data : {};
  // const availableBalance = walletBalance ? walletBalance.availableBalance : 0;

  useState(() => {
    batch(() => {
      dispatch(fetchOneVendorCustomerContract(selectedVendor.id, authentication.user.customer));
      // dispatch(fetchWalletBalanceInVendorContract(selectedVendor.id))
    });
  }, [])

  useState(() => {
    dispatch(removeCustomerVendorContract());
  }, [])

  return (
    <>
      <div className="underlined-tab" /* style={{marginRight: 30}} */>
        <span className="tab-title">Vendor Details</span>
        <div className="action-buttons">
          <button className="export-btn" style={{ marginRight: 0 }} onClick={() => {
            props.backFunction()
          }}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className=" customer-details" style={{ width: "100%" }}>
        <div className="row pl-3" style={{ marginRight: "-30px" }}>
          <div className="col-12">
            <>
              <div className="row user-info">
                <div className="row user-info-title">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5 text-center mt-4">
                        {!!image ? (
                          <img
                            alt="Profile"
                            src={image}
                            style={{ width: 100 }}
                          />
                        ) : (
                          <AvatarSVG style={{ marginRight: 0 }} />
                        )}
                        <div className="credit-details mt-4">
                          <div
                            className="credit-title mb-2"
                            style={{ position: "relative" }}
                          >
                            AVAILABLE BALANCE
                          </div>
                          <div
                            className="amount"
                            style={{
                              position: "relative",
                              width: 130,
                              margin: "0 auto",
                            }}
                          >
                            ₦ {formatAmount(sum)}
                          </div>
                          {/* <div
                          className="credit-title mb-2 mt-2"
                          style={{ position: "relative" }}
                        >
                          WALLET BALANCE
                        </div>
                        <div
                          className="amount"
                          style={{
                            position: "relative",
                            width: 130,
                            margin: "0 auto",
                          }}
                        >
                          ₦ {formatAmount(availableBalance)}
                        </div> */}
                        </div>
                      </div>
                      <div className="col-7 pt-4">
                        <div style={{ float: "left", marginTop: 10 }}><AccountSvg height={25} width={25} /></div>
                        <div className="d-inline-block">
                          <div className=" user-info-title">
                            <div>Vendor ID</div>
                          </div>
                          <div className=" user-info-details">
                            <div>{number}</div>
                          </div>
                        </div>
                        <div className="mt-5 customer-stats">
                          <div className="col-10 stats">
                            <div className="row">
                              <div className="col-6">PRODUCTS</div>
                              <div className="col-6">LOCATION</div>
                            </div>
                            <div className="row numbers">
                              <div className="col-6">{products.length}</div>
                              <div className="col-6">{locations.length}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="vendor-overview-border"></div>
                    <div className="user-info-title mt-4">
                      <div className="vendor-overview-title mb-2">Vendor Overview</div>
                      <div className="vendor-overview-body">
                        {description || "Not available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row user-overview" style={{padding: '20px 80px'}}>
              <span className="overview-title" style={{marginBottom: 20}}>Contract Overview</span>
              <div className="inner pr-5 pb-5" style={{ paddingLeft: "200px", borderRadius: 6 }}>
                <div className="mb-3"><UserTransactionSVG /> <span style={{color: "#000", fontSize: 18, fontWeight: 900}}>Product: {oVendorReducer.oneVendorContract.data ? oVendorReducer.oneVendorContract.data.name : ""}</span></div>
                {oVendorReducer.oneVendorContract.data ? <><div className="row user-address-title">
                  <div className="col-3">Contract Limit</div>
                  <div className="col-3">Frequency</div>
                  <div className="col-3">Vendor Signature</div>
                  <div className="col-3">Customer Signature</div>
                </div>
                <div className="row user-address-details mt-2">
                  <div className="col-3">₦ {formatAmount(sum || 0)}</div>
                  <div className="col-3">
                    {(oVendorReducer.oneVendorContract.data.contractCycleType === "DAYS") && 
                      (oVendorReducer.oneVendorContract.data.contractCycleTypeDays || "")}
                    {oVendorReducer.oneVendorContract.data.contractCycleType || "0"}
                  </div>
                  <div className="col-3">{oVendorReducer.oneVendorContract.data.vendorSignature || "Nil"}</div>
                  <div className="col-3">{oVendorReducer.oneVendorContract.data.customerSignature || "Nil"}</div>
                </div>
                
                <div className="row user-address-title mt-4">
                  <div className="col-3">Start Date</div>
                  <div className="col-3">Auto-Renew?</div>
                  <div className="col-3">Status</div>
                </div>
                <div className="row user-address-details mt-2">
                  <div className="col-3">{formatDate3(oVendorReducer.oneVendorContract.data.contractStartDate)}</div> 
                  <div className="col-3">{oVendorReducer.oneVendorContract.data.autoRenew ? "Yes" : "No"}</div> 
                  <div className="col-3">
                    {oVendorReducer.oneVendorContract.data.contractStatus === "ACTIVE" ? "Active " :  (
                      <div onClick={() => {
                        dispatch(customerSignVendorsContract(oVendorReducer.oneVendorContract.data.id))
                      }} style={{cursor: "pointer", color: '#0172CB'}}>
                        {oVendorReducer.isSigningContract ? <img
                        alt="Profile"
                        src="/images/ActivityIndicator.gif"
                        style={{ height: 20, width: 20, marginTop: 0 }}
                      /> : 'Activate Contract'}
                      </div>
                    )}{oVendorReducer.oneVendorContract.data.contractStatus === "ACTIVE" && (<ActiveMarkSVG />)}
                  </div>
                </div>
                </>: <div className="ml-5">Unavailable</div>}

                <Divider />
                  <div className="mb-3">
                    <span
                      style={{
                        color: "#000",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Product Rates
                    </span>
                  </div>
                  {oVendorReducer.oneVendorContract.data ? (
                    <>
                      <div className="row user-address-title">
                        <Table
                          style={{ width: "100%" }}
                          columns={columns}
                          dataSource={
                            oVendorReducer.oneVendorContract?.data
                              ?.productContractRates
                          }
                          size="small"
                        />
                      </div>
                    </>
                  ) : (
                    <div className="ml-5">Unavailable</div>
                  )}
              </div>     
            </div> */}
              {!selectedProduct && <div className="row user-info">
                <div className="row user-info-title mt-4 mb-4">
                  <div className="col-2 text-right">
                    <ProductSvg />
                  </div>
                  <div className="col-10">
                    <div className="product-title">Products</div>
                    <span>Click on product name to view state prices</span>
                    <div className="row">
                      {
                        selectedVendor.products.map((item, i) => {
                          return <div key={i} className="col-6 mb-4">
                            <div className="user-info-title mt-2">
                              <div className="product-item mt-3">
                                <a onClick={()=>setSelectedProduct(item)}>{item.name}</a>
                              </div>
                              <div className="product-amount mt-2">
                                ₦ {formatAmount(item.rate * 100)} <span>Per liter</span>
                              </div>
                            </div>
                            {i % 2 === 0 && <div style={{ width: 1, height: 60, background: "rgba(95, 115, 140, 0.47)", position: "absolute", right: 120, top: 30 }}></div>}
                          </div>
                        })
                      }
                      {selectedVendor.products.length === 0 && <span className="mt-3 ml-3">No product available</span>}
                    </div>
                  </div>
                </div>
              </div>}
              {selectedProduct && <div className="row"><StatePriceComponent product={selectedProduct.name} vendorCode={number} backFunction={()=>setSelectedProduct('')}  /></div>}
              <div className="row">
                <div className="col-6 user-info">
                  <h4>Vendor Locations</h4>
                  <Button type="link" onClick={showLocation}>View existing outlets and locations</Button>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
