import {
  ALL_LOCATIONS,
  ALL_VENDOR_TERMINALS,
  VENDOR_LOCATION_LOADING_STATUS,
  VENDOR_TERMINALS_LOADING,
  FETCH_All_TERMINALUSERS_BY_VENDOR_FULLFILLED,
} from '../../actions/VendorActions/TerminalActions/TerminalMgtConstants';

const initialState = {
  allLocationLoadingStatus: 0,
  allLocations: [],
  fetchVendorLocationStatus: 0,
  fetchVendorLocationMsg: '',
  allTerminals: [],
  allPOSUsers: [],
  locationTerminals: [
    {
      id: 1,
      name: 'Terminal 1',
      number: 'TERM000001',
      macAddress: 'Dummy content. Must come from terminal hardware3063326.453047397',
      createdAt: [2018, 11, 25, 18, 28, 47],
      updatedAt: [2018, 11, 25, 18, 28, 47],
    },
    {
      id: 2,
      name: 'Terminal 1',
      number: 'TERM000002',
      macAddress: 'Dummy content. Must come from terminal hardware2488202.765133438',
      createdAt: [2018, 11, 25, 19, 6, 19],
      updatedAt: [2018, 11, 25, 19, 6, 19],
    },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_LOCATIONS:
      return Object.assign({}, state, {
        allLocations: action.response.data,
        fetchVendorLocationStatus: action.response.status,
        fetchVendorLocationMsg: action.response.message,
      });
    case VENDOR_LOCATION_LOADING_STATUS:
      return Object.assign({}, state, {
        allLocationLoadingStatus: action.status,
      });
    case ALL_VENDOR_TERMINALS:
      return Object.assign({}, state, {
        allTerminals: action.terminals,
      });
    case VENDOR_TERMINALS_LOADING:
      return Object.assign({}, state, {
        allLocationLoadingStatus: action.status,
      });
      case FETCH_All_TERMINALUSERS_BY_VENDOR_FULLFILLED:
        return {
          ...state,
          allPOSUsers: action.payload
        }
    default:
      return state;
  }
};
