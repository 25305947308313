//Live Enviroment
// export const customConfig = {
//   API_URL: 'https://smartfuel-userms-uat.k8.isw.la/smartfuel-userms/',
//   API_URL_CARD: 'https://smartcard-cms-uat.k8.isw.la/smartcard-cms/',
//   API_URL_REPORT: 'https://smartfuel-reportms-uat.k8.isw.la/smartfuel-reportms/',
//   API_URL_VOUCHER: 'https://smartfuel-vms-uat.k8.isw.la/smartcard-vms/',
//   API_URL_CARD_AND_TRANS: 'https://qa.interswitchng.com/smartfuel-trx/',
//   ownerEmail: 'smartcard-complaint@interswitchgroup.com',
//   API_URL_CARD_ACC: 'https://kobo360-cms-mock-uat.k8.isw.la/kobo360-cms/',

import environment from "../env";

// };

const env = environment;

const devObj = {
  merchantCode: "MX6072",
  baseUrl: "https://smartfuel-userms-ag.k8.isw.la",
  API_URL: "https://smartfuel-userms-ag.k8.isw.la/smartfuel-userms/",
  API_URL_CARD: "https://smartcard-cms.k8.isw.la/smartcard-cms/",
  API_URL_VOUCHER: "https://smartcard-vms.k8.isw.la/smartcard-vms/",
  API_URL_REPORT: "https://smartfuel-reportms.k8.isw.la/smartfuel-reportms/",
  API_URL_CARD_AND_TRANS: "https://smartfuel-trx.k8.isw.la/smartfuel-trx/",
  API_URL_CARD_ACC: "https://smartfuel-cms.k8.isw.la/smartfuel-cms/",
  API_URL_SF_VOUCHER:
    "https://smartfuel-voucher-service.k8.isw.la/smartfuel-vs/",
  API_URL_CORPORATE: "https://rfid-corporate-service.k8.isw.la/corporate-rfid/",
  API_URL_NOTIFICATION:
    "https://smartfuel-notification-service.k8.isw.la/smartfuel-notification/",
  FRONT_SSO_URL: "https://uber4diesel-frontend-isw-ag.k8.isw.la",
};

const prodObj = {
  merchantCode: "MX6072",
  baseUrl: "https://smartfuel.interswitchng.com",
  API_URL: "https://smartfuel.interswitchng.com/smartfuel-userms/",
  API_URL_CARD: "https://quicktellerfuel.interswitchgroup.com/smartcard-cms/",
  API_URL_VOUCHER:
    "https://quicktellerfuel.interswitchgroup.com/smartcard-vms/",
  API_URL_REPORT:
    "https://quicktellerfuel.interswitchgroup.com/smartfuel-reportms/",
  API_URL_CARD_AND_TRANS:
    "https://quicktellerfuel.interswitchgroup.com/smartfuel-trx/",
  API_URL_CARD_ACC:
    "https://quicktellerfuel.interswitchgroup.com/smartfuel-cms/",
  API_URL_SF_VOUCHER:
    "https://quicktellerfuel.interswitchgroup.com/smartfuel-vs/",
  API_URL_CORPORATE:
    "https://quicktellerfuel.interswitchgroup.com/corporate-rfid/",
  API_URL_NOTIFICATION:
    "http://smartfuel-notification-service-prod.k2.isw.la/smartfuel-notification/",
  FRONT_SSO_URL: "https://smartfuel.interswitchng.com",
};

export const customConfig = env === "dev" ? devObj : prodObj;

export const rfidBaseURL =
  env === "dev"
    ? "https://rfid-report-service.k8.isw.la/"
    : "https://quicktellerfuel.interswitchgroup.com/";

export const GenService =
  env === "dev"
    ? "https://rfid-gen-service.k8.isw.la/rfid-gen/api/v1/"
    : "https://quicktellerfuel.interswitchgroup.com/rfid-gen/api/v1/";

export const unusedTags = `${rfidBaseURL}rfid-report/api/v1/tags/unused`;
export const usedTags = `${rfidBaseURL}rfid-report/api/v1/tags/used`;
export const createTags = `${GenService}batch/generate`;
export const batchDownloads = `${GenService}batch/list`;
export const forgotPassword =
  env === "dev"
    ? "https://passport-v2.k8.isw.la/passport/accounts/recovery"
    : "https://passport.interswitchng.com/passport/accounts/recovery";

// //Production
// export const customConfig = {
//   API_URL: 'https://quicktellerfuel.interswitchgroup.com/smartfuel-userms/',
//   API_URL_CARD: 'https://quicktellerfuel.interswitchgroup.com/smartcard-cms/',
//   API_URL_REPORT: 'https://quicktellerfuel.interswitchgroup.com/smartfuel-reportms/',
//   API_URL_VOUCHER: 'https://quicktellerfuel.interswitchgroup.com/smartcard-vms/',
//   API_URL_CARD_AND_TRANS: 'https://quicktellerfuel.interswitchgroup.com/smartfuel-trx/',
//   ownerEmail: 'smartfuelkobo360@interswitchgroup.com',
//   API_URL_CARD_ACC: 'https://quicktellerfuel.interswitchgroup.com/kobo360-cms/',
// };

//dev
// export const customConfig = {
//   API_URL: 'https://qa.interswitchng.com/smartfuel-userms/',
//   API_URL_CARD: 'https://qa.interswitchng.com/smartcard-cms/',
//   API_URL_REPORT: 'https://qa.interswitchng.com/smartfuel-reportms/',
//   API_URL_VOUCHER: 'https://qa.interswitchng.com/smartcard-vms/',
//   API_URL_CARD_AND_TRANS: 'https://qa.interswitchng.com/smartfuel-trx/',
//   ownerEmail: 'smartfuelkobo360@interswitchgroup.com',
//   API_URL_CARD_ACC: 'https://qa.interswitchng.com/kobo360-cms/',
// };
