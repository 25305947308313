const customerCardReducer = (
  state = {
    allCards: [],
    allCardsBySchemes: [],
    allCustomerCards: [],
    allCusBlankCards: [],
    allSchemes: [],
    allSchemesByCus: [],
    allGeneratedVouchers: [],
    allGeneratedVouchersList: [],
    customerSuperAdmin: {},
    customerUsers: [],
    oneCard: {},
    oneCardStats: {},
    fetchingStats: false,
    oneCardTransactions: [],
    fetching: false,
    fetched: false,
    error: null,
    oneCardBalance: {},
    fetchingBal: false,
    errorBal: null,
    bulkCreateModal: false,
    userCreateModal: false,
    bulkUploadModal: false,
    bulkUploadResultModal: false,
    userEditModal: false,
    userDeleteModal: false,
    addSchemeStatus: 0,
    addSchemeMessage: '',
    addSchemeSuccessful: false,
    updateSchemeLimitStatus: 0,
    updateSchemeLimitMessage: '',
    updateSchemeLimitSuccessful: false,
    schemeMessage: '',
    fetchingCardsMessage: '',
    fetchingCardsStatus: 0,
    cusCardStatus: '',
    createCardStatus: 0,
    assignCardStatus: 0,
    createCardMessage: '',
    assignCardMessage: '',
    toggleCardStatusReqStatus: 0,
    toggleCardStatusReqMessge: '',
    customerContracts: [],
    customerContractsWithProducts: [],
    customerVendorContracts: {},
    customerVendorSchemes: [],
    fetchallAssignedCard:[],
    accActivateResponseData:'',
    accActivateResponseMessage:'',
    accActivateResponseStatus:0,
    accDeactivateResponseData:'',
    accDeactivateResponseMessage:'',
    accDeactivateResponseStatus:0

  },
  action,
) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'ADD_SCHEME_SUCCESSFUL': {
      return { ...state, addSchemeSuccessful: true, addSchemeStatus: action.payload.status, addSchemeMessage: action.payload.message };
    }

    case 'ADD_SCHEME_REJECTED': {
      return { ...state, addSchemeSuccessful: true, addSchemeStatus: action.payload.status, addSchemeMessage: action.payload.message };
    }

    case 'UPDATE_SCHEME_LIMIT_SUCCESSFUL': {
      return { ...state, updateSchemeLimitSuccessful: true, updateSchemeLimitStatus: action.payload.status, updateSchemeLimitMessage: action.payload.message };
    }

    case 'UPDATE_SCHEME_LIMIT_REJECTED': {
      return { ...state, updateSchemeLimitSuccessful: false, updateSchemeLimitStatus: action.payload.status, updateSchemeLimitMessage: action.payload.message };
    }

    case 'FETCH_ALL_CARDS_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_All_CARDS_FULFILLED': {
      return { ...state, fetching: false, fetched: true, allCards: action.payload };
    }

    case 'FETCH_All_CARDS_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'FETCH_CUSTOMER_SUPERADMIN_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_CUSTOMER_SUPERADMIN_FULFILLED': {
      return { ...state, fetching: false, fetched: true, customerSuperAdmin: action.payload };
    }

    case 'FETCH_CUSTOMER_SUPERADMIN_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'ASSIGN_CARDS_REJECTED': {
      return { ...state, createCardStatus: action.response.status, createCardMessage: action.response.message };
    }

    case 'ASSIGN_CARDS_FULFILLED': {
      return { ...state, createCardStatus: action.response.status, createCardMessage: action.response.message };
    }

    case 'ASSIGN_VOUCHERS_FULFILLED': {
      return { ...state, createCardStatus: action.response.status, createCardMessage: action.response.message };
    }

    case 'ASSIGN_VOUCHERS_REJECTED': {
      return { ...state, createCardStatus: action.response.status, createCardMessage: action.response.message };
    }

    case 'FUND_WALLET_FULFILLED': {
      return { ...state, fundWalletStatus: action.response.status, fundWalletMessage: action.response.message };
    }

    case 'FUND_WALLET_REJECTED': {
      return { ...state, fundWalletStatus: action.response.status, fundWalletMessage: action.response.message };
    }

    case 'ASSIGN_TAG_REJECTED': {
      return { ...state, assignCardStatus: action.response.status, assignCardMessage: action.response.message };
    }

    case 'ASSIGN_TAG_FULFILLED': {
      return { ...state, assignCardStatus: action.response.status, assignCardMessage: action.response.message };
    }

    case 'FETCH_CUSTOMER_USERS_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_CUSTOMER_USERS_FULFILLED': {
      return { ...state, fetching: false, fetched: true, customerUsers: action.payload };
    }

    case 'FETCH_CUSTOMER_USERS_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'FETCH_ONE_CARD_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_ONE_CARD_FULFILLED': {
      return { ...state, fetching: false, fetched: true, oneCard: action.payload };
    }

    case 'FETCH_ONE_CARD_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'FETCH_CARD_STATISTICS_START': {
      return { ...state, fetchingStats: true, oneCardStats: {} };
    }

    case 'FETCH_CARD_STATISTICS_FULFILLED': {
      return { ...state, fetchingStats: false, oneCardStats: action.payload };
    }

    case 'FETCH_CARD_STATISTICS_REJECTED': {
      return { ...state, fetchingStats: false, error: action.payload };
    }

    case 'FETCH_CARD_BALANCE_START': {
      return { ...state, fetchingBal: true, oneCardBalance: {}, errorBal: null };
    }

    case 'FETCH_CARD_BALANCE_FULFILLED': {
      return { ...state, fetchingBal: false, oneCardBalance: action.payload };
    }

    case 'FETCH_CARD_BALANCE_REJECTED': {
      return { ...state, fetchingBal: false, errorBal: action.payload };
    }

    case 'FETCH_CARD_TRANSACTION_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_CARD_TRANSACTION_FULFILLED': {
      return { ...state, fetching: false, fetched: true, oneCardTransactions: action.payload };
    }

    case 'FETCH_CARD_TRANSACTION_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'FETCH_BLANKCARDS_BYCUSTOMER_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_BLANKCARDS_BYCUSTOMER_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, allCusBlankCards: action.payload };
    }

    case 'FETCH_BLANKCARDS_BYCUSTOMER_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'TOGGLE_CARD_STATUS_START': {
      return { ...state, fetching: true };
    }

    case 'TOGGLE_CARD_STATUS_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        cusCardStatus: action.payload.data,
        toggleCardStatusReqStatus: action.payload.status,
        toggleCardStatusReqMessge: action.payload.message,
      };
    }

    case 'TOGGLE_CARD_STATUS_REJECTED': {
      return { ...state,
               toggleCardStatusReqStatus: action.payload.status,
               toggleCardStatusReqMessge: action.payload.message };
    }

    case'TOGGLE_ACTIVATE_ACC_STATUS_START' :
    return {
      ...state,
      toggleActivateAccStart:true,
      errorMessage: null,
      successMessage: null,
      isSubmitting: true
    }

    case 'TOGGLE_ACTIVATE_ACC_STATUS_FULFILLED' :
    return {
      ...state,
      toggleActivateAccStart:false,
      accActivateResponseData:action.payload.data,
      accActivateResponseMessage:action.payload.message,
      accActivateResponseStatus:action.payload.status,
      successMessage: action.payload.message,
      isSubmitting: false
    }

    case 'RESET_ACC_STATUS_ACTION' :
    return {
      ...state,
      toggleActivateAccStart:false,
      errorMessage: null,
      successMessage: null,
      isSubmitting: false
    }

    case 'TOGGLE_ACTIVATE_ACC_STATUS_REJECTED' :
    return {
      ...state,
      toggleActivateAccStart:false,
      accActivateResponseData:action.payload.data,
      accActivateResponseMessage:action.payload.message,
      accActivateResponseStatus:action.payload.status,
      errorMessage: action.payload.message,
      isSubmitting: false
    }

    case 'TOGGLE_DISABLE_ACC_STATUS_START' :
    return {
      ...state,
      toggleActivateAccStart:true,
      successMessage: null,
      errorMessage: null,
      isSubmitting: true
    }

    case 'TOGGLE_DISABLE_ACC_STATUS_FULFILLED' :
    return {
      ...state,
      toggleActivateAccStart:false,
      accDeactivateResponseMessage:action.payload.message,
      accDeactivateResponseStatus:action.payload.status,
      successMessage: action.payload.message,
      isSubmitting: false
    }

    case 'TOGGLE_DISABLE_ACC_STATUS_REJECTED' :
    return {
      ...state,
      toggleActivateAccStart:false,
      accDeactivateResponseData:action.payload.data,
      accDeactivateResponseMessage:action.payload.message,
      accDeactivateResponseStatus:action.payload.status,
      errorMessage: action.payload.message,
      isSubmitting: false
    }

    case 'FETCH_SCHEMES_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_SCHEMES_FULFILLED': {
      return { ...state, fetching: false, fetched: true, allSchemes: action.payload };
    }

    case 'FETCH_SCHEMES_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'FETCH_SCHEMES_BYCUSTOMER_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_SCHEMES_BYCUSTOMER_FULFILLED': {
      return { ...state, fetching: false, fetched: true, allSchemesByCus: action.payload };
    }

    case 'FETCH_SCHEMES_BYCUSTOMER_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'FETCH_GENERATED_VOUCHERS_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_GENERATED_VOUCHERS_FULFILLED': {
      return { ...state, fetching: false, fetched: true, allGeneratedVouchers: action.payload };
    }

    case 'FETCH_GENERATED_VOUCHERS_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'FETCH_GENERATED_VOUCHERS_LIST_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_GENERATED_VOUCHERS_LIST_FULFILLED': {
      return { ...state, fetching: false, fetched: true, allGeneratedVouchersList: action.payload };
    }

    case 'FETCH_GENERATED_VOUCHERS_LIST_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'FETCH_CARDS_BYCUSTOMER_START': {
      return { ...state, fetching: true };
    }

    case 'UPDATE_CARD_REQUEST_PARAMS': {
      return { ...state, fetchingCardsStatus: action.response.status, fetchingCardsMessage: action.response.message };
    }

    case 'UPLOAD_BLANK_CARDS_FULLFILLED': {
      return { ...state, fetchingCardsStatus: action.response.status, fetchingCardsMessage: action.response.message };
    }

    case 'UPLOAD_BLANK_CARDS_REJECTED': {
      return { ...state, fetchingCardsStatus: action.response.status, fetchingCardsMessage: action.response.message };
    }

    case 'UPDATE_CARD_CREATE_REQUEST_PARAMS': {
      return { ...state, createCardStatus: action.response.status, createCardMessage: action.response.message };
    }
    case 'FETCH_CARDS_BYCUSTOMER_FULLFILLED': {
      return {
        ...state,
        fetching: false,
        fetchingCardsStatus: action.response.status,
        fetchingCardsMessage: action.response.message,
        fetched: true,
        allCustomerCards: action.response.payload,
      };
    }
    case 'FETCH_ALL_CUSTOMER_CONTRACTS_FULLFILLED': {
      return { ...state, customerContracts: action.payload.data };
    }
    case 'FETCH_ALL_CUSTOMER_CONTRACTS_WITH_PRODUCTS_FULLFILLED': {
      return { ...state, customerContractsWithProducts: action.payload.data };
    }

    case 'FETCH_ALL_CUSTOMER_VENDOR_CONTRACTS_FULLFILLED': {
      return { ...state, customerVendorContracts: action.payload.data };
    }
    case 'FETCH_ALL_CUSTOMER_VENDOR_CONTRACTS_REJECTED': {
      return { ...state, customerVendorContracts: {} };
    }
    case 'FETCH_SCHEMES_BY_CUSTOMER_AND_CONTRACT_FULFILLED': {
      return { ...state, customerVendorSchemes: action.payload }
    }

    case 'FETCH_SCHEMES_BY_CUSTOMER_AND_CONTRACT_rEJECTED': {
      return { ...state, customerVendorSchemes: [] }
    }
    // case 'FETCH_ALL_CUSTOMER_CONTRACTS_REJECTED': {
    //   return { ...state, customerContracts: action.payload.data };
    // }
    case 'FETCH_CARDS_BY_SCHEME_FULLFILLED': {
      return { ...state, fetchingCardsStatus: action.response.status, fetchingCardsMessage: action.response.message, allCardsBySchemes: action.response.data };
    }

    case 'TOGGLE_SCHEME_CARD_STATUS_START': {
      return { ...state, isTogglingSchemeCard: true };
    }

    case 'TOGGLE_SCHEME_CARD_STATUS_FULFILLED': {
      return {
        ...state, 
        isTogglingSchemeCard: false, 
        toggleSchemeCardResponse: { isError: false, message: action.payload.message },
      };
    }

    case 'TOGGLE_SCHEME_CARD_STATUS_REJECTED': {
      return {
        ...state, 
        isTogglingSchemeCard: false,
        toggleSchemeCardResponse: { isError: true, message: action.payload.message },
      };
    }

    case 'TOGGLE_SCHEME_CARD_STATUS_RESET':
      return { ...state, toggleSchemeCardResponse: {} };

    case 'FETCH_CARDS_BYCUSTOMER_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.payload };
    }

    case 'FETCH_CARDS_BYSCHEME_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, allCards: action.payload };
    }
    case 'FETCH_All_CUSTOMER_SCHEMES_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, allSchemes: action.payload };
    }
    case 'FETCH_ONE_CARD_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, oneCard: action.payload };
    }

    case 'FETCH_ALL_ASSIGNED_CARD':{
      return{
        ...state,
        fetchallAssignedCard:action.payload
      }
    }
  

    case 'ADD_SCHEME_FULLFILLED': {
      return {
        ...state,
        // userCreateModal: false,
        allCards: [...state.allCards, action.payload],
      };
    }

    case 'UPDATE_USERS': {
      const { id } = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex(user => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers,
      };
    }
    case 'DELETE_USERS': {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }

    case 'CLEAR_ERRORS': {
      return {
        ...state,
        errorMessage: null,
        successMessage: null
      }
    }
  }
  return state;
};

export default customerCardReducer;
