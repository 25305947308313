import axios from "axios";
import queryString from 'query-string';
import {customConfig} from "../../../../actions/action_constants";
import {authHeader} from "../../../../helpers/authHeader";
import { errorHandler } from "../../../../helpers/misc";
const API_URL = customConfig.API_URL;
export const getAccountNumber = ()=>{
    return function(dispatch) {
        dispatch({ type: "GET_ACCOUNT_NUMBER" });
        axios
          .get(
            API_URL + `api/payments/accounts`,
            { headers: authHeader() }
          )
          .then(res => {
            dispatch({
              type: "GET_ACCOUNT_NUMBER_FULLFILLED",
              payload: res.data
            });
          })
          .catch(err => {
            dispatch({ type: "ERROR", payload: err });
          });
      };
}

export function createAccountNUmber(body) {
    return function(dispatch) {
      dispatch({ type: "CREATE_ACCOUNT_NUMBER" });
      axios
        .post(
          API_URL + `api/payments/accounts`,
          body,
          { headers: authHeader() }
        )
        .then(res => {
          dispatch({
            type: "CREATE_ACCOUNT_NUMBER_FULLFILLED",
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({ type: "ERROR", payload: err });
        });
    };
  }

  export function createInvoice(body){
    return function(dispatch) {
      dispatch({ type: "CREATE_INVOICE" });
      axios
        .post(
          API_URL + `api/payments/invoices`,
          body,
          { headers: authHeader() }
        )
        .then(res => {
          dispatch({
            type: "CREATE_INVOICE_COMPLETED",
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({ type: "ERROR", payload: err });
        });
    }
  }
    // query
  export function getInvoices(queryObj){
    const query = queryString.stringify(queryObj, { skipEmptyString: true, skipNull: true })
    return function(dispatch) {
      dispatch({ type: "GET_INVOICE" });
      axios
        .get(API_URL + `api/payments/invoices?${query}`,
          { headers: authHeader() })
        .then(res => {
          dispatch({
            type: "GET_INVOICE_COMPLETED",
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({ type: "ERROR", payload: err });
        });
    }
  }

  export function RequeryTransaction(trxRef){
    return function(dispatch) {
      dispatch({ type: "START_REQUERY" });
      axios
        .get(
          API_URL + `api/payments/requery?trxRef=${trxRef}`,
          { headers: authHeader() }
        )
        .then(res => {
          dispatch({
            type: "COMPLETE_REQUERY",
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({ type: "ERROR", payload: err });
        });
    }
  }

  export function getBanks(){
    return function(dispatch) {
      dispatch({ type: "START_FETCH_BANKS" });
      axios
        .get(
          API_URL + `api/payments/settlement/banks`,
          { headers: authHeader() }
        )
        .then(res => {
          dispatch({
            type: "COMPLETE_FETCH_BANKS",
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({ type: "ERROR", payload: err });
        });
    }
  }

  export function addSettlementAccount(body){
    return function(dispatch){
      dispatch({ type: "START_ADD_SETTLEMENT"});
      axios
        .post(
          API_URL + `api/payments/settlements/accounts`,
          body,
          { headers: authHeader() }
        )
        .then(res => {
          dispatch({
            type: "COMPLETE_ADD_SETTLEMENT",
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({ type: "SETTLEMENT_ERROR", payload: errorHandler(err) });
        });
    }
  }

  export function GetSettlementAccount(vid){
    return function(dispatch){
      dispatch({ type: "START_GET_SETTLEMENT"});
      axios
        .get(
          API_URL + `api/payments/settlements/accounts?vid=${vid}`,
          { headers: authHeader() }
        )
        .then(res => {
          dispatch({
            type: "COMPLETE_GET_SETTLEMENT",
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({ type: "ERROR", payload: err });
        });
    }
  }
  