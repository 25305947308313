import React, {useState, useEffect} from "react";
import SubComponentHeader from "../../../../lib/Components/SubComponentHeader";
import {useDispatch} from "react-redux";
import FormWarning from "../../../admin/shared/FormWarning";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import CustomInput from "../../../../lib/Components/CustomInput";
import {countries} from "../../../../lib/Dropdowns/countries";
import validator from "validator";
import {getUserProperties} from "../../../../lib/Constants/UserTypes";
import { fetchAllTerminalsByVendor } from "../../../ownerModule/redux/action/OwnerVendorActions";

export default function CreatePosUser({
  isCreatePos,
  isEditPos,
  onBackHandler,
  oVendorReducer,
  onCreatePos,
  onEditPos,
  selectedPosUser,
}) {
  const dispatch = useDispatch();
  const [posValidationError, setPosValidationError] = useState(false);
  const [toast, setToast] = useState({class: "", text: ""});
  const [isCreatingPos, setIsCreatingPos] = useState(false);
  const [isEditingPos, setIsEditingPos] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    usersCode: "",
    usersId: "",
    email: "",
    street: "",
    city: "",
    state: "",
    country: "",
    terminalIdMerchantId: "",
    vendor: getUserProperties().vendor,
  });
  const [isStationManager, setIsStationManager] = useState(false);
  const invalidPosForm = () => {
    const {
      firstName,
      lastName,
      phone,
      usersCode,
      usersId,
      email,
      street,
      city,
      state,
      country,
      terminalIdMerchantId,
    } = formData;
    if (!firstName) {
      return "Invalid first name";
    } else if (!lastName) {
      return "Invalid last name";
    } else if (!validator.isMobilePhone(phone)) {
      return "Invalid phone number";
    } else if (!usersCode || usersCode.length !== 4 || isNaN(usersCode)) {
      return "User code must be four (4) numeric digits";
    } else if (!usersId || usersId.length !== 4 || isNaN(usersId)) {
      return "User ID must be four (4) numeric digits";
    } else if (!validator.isEmail(email)) {
      return "Email is not valid";
    } else if (!street) {
      return "Enter a valid street name";
    } else if (!city) {
      return "Enter a valid city name";
    } else if (!state) {
      return "Enter a valid state name";
    } else if (!country) {
      return "Enter a valid country name";
    } else if(!terminalIdMerchantId || terminalIdMerchantId === undefined) { 
      return "Select a Terminal"
    } 
    else
    return false;
    
  };

  useEffect(() => {
    const {
      firstName,
      lastName,
      address,
      phone,
      id,
      userEmail,
      terminalName,
      terminalId
    } = selectedPosUser;
    if(!selectedPosUser) { 
      return;
    } 
    if(!terminalData || terminalData.length < 1) {
      return; 
    }
    let terminalNameFind = terminalData?.filter(x => x.terminalId === selectedPosUser.terminalId);
   
    setFormData({
      ...formData,
      firstName,
      lastName,
      phone,
      usersCode: id?.usercode + "",
      usersId: id?.userId + "",
      email: userEmail,
      street: address?.street,
      city: address?.city,
      state: address?.state,
      country: address?.country,
      terminalName,
      terminalId,
      terminalIdMerchantId: (terminalNameFind && terminalNameFind.length > 0) ? `${terminalNameFind[0].terminalId}:${terminalNameFind[0].merchantId}` : '',
    });
  }, [selectedPosUser]);

  useEffect(() => {
    if (!!isCreatingPos && oVendorReducer.createPOSUserRequestStatus !== 1) {
      setToast({
        class: "",
        text:
          oVendorReducer.createPOSUserRequestMessage ||
          "Fields incorrectly filled!",
      });
      setPosValidationError(true);
      setIsCreatingPos(false);
    }
    if (!!isCreatingPos && oVendorReducer.createPOSUserRequestStatus === 1) {
      setToast({
        class: "success-container success-text",
        text: oVendorReducer.createPOSUserRequestMessage,
      });
      resetFormData();
      setIsCreatingPos(false);
      setPosValidationError(true);
    }

    setTimeout(function () {
      dispatch({
        type: "CREATE_POS_USERS_REQUEST",
        response: {status: 0, message: ""},
      });
    }, 3000);
  }, [ oVendorReducer.createPOSUserRequestStatus ]);
  

    useEffect(() => {
      if (!!isEditingPos && oVendorReducer.editPOSUserRequestStatus !== 1) {
        setToast({
          class: "",
          text:
            oVendorReducer.editPOSUserRequestMessage ||
            "Fields incorrectly filled!",
        });
        setPosValidationError(true);
        setIsEditingPos(false);
      }
      if (!!isEditingPos && oVendorReducer.editPOSUserRequestStatus === 1) {
        setToast({
          class: "success-container success-text",
          text: oVendorReducer.editPOSUserRequestMessage,
        });
        setIsEditingPos(false);
        setPosValidationError(true);
        setTimeout(function () {
          window.location.reload()
        }, 1000);
      }

      setTimeout(function () {
        dispatch({
          type: "EDIT_POS_USERS_REQUEST",
          response: {status: 0, message: ""},
        });
      }, 3000);
    }, [oVendorReducer.editPOSUserRequestStatus]);

    useEffect(()=> {
      dispatch(
        fetchAllTerminalsByVendor(getUserProperties().vendor, 1, 1000)
      );
    }, []);

    useEffect(()=> {
      if(getUserProperties().userLevel === 'STATION_MANAGER') {
        setIsStationManager(true);
        if(oVendorReducer.terminalDetail && oVendorReducer.terminalDetail.data){
          let terminal = oVendorReducer.terminalDetail.data;
          setFormData({...formData, terminalName: terminal.name, terminalIdMerchantId: `${terminal.terminalId}:${terminal.merchantId}`})
        }
      }
    }, [oVendorReducer.terminalDetail, formData.terminalName])

  const resetFormData = () => {
    setFormData({
      firstName: "",
      lastName: "",
      phone: "",
      usersCode: "",
      usersId: "",
      email: "",
      street: "",
      city: "",
      state: "",
      country: "",
      terminalIdMerchantId: "",
    });
  };

  const submitPosForm = (event) => {
    event.preventDefault();
    const error = invalidPosForm();
    if (!!error) {
      setPosValidationError(true);
      setToast({
        class: "",
        text: error,
      });
      return;
    }
    isCreatePos && onCreatePos(formData, (bool) => {
       setIsCreatingPos(bool);
    });
    isEditPos && onEditPos(formData, (bool) => {
      setIsEditingPos(bool);
    })
  };

  const handleInput = (attr, event) => {
      setFormData({ ...formData, [attr]: event.target.value });
  };

  const allTerminals = oVendorReducer.allTerminalsByVenId;
  let terminalData = allTerminals ? allTerminals.data : [];

  const terminalList = terminalData && terminalData.filter(x => !x.managerName).map((value, i) => {
      const {name, terminalId, merchantId} = value;
      return (
        <option value={`${terminalId}:${merchantId}`} key={i}>
          {`${name} - ${terminalId}`} 
        </option>
      );
    });

  return (
    <>
      {(isCreatePos || isEditPos) && (
        <>
          <SubComponentHeader
            heading={isCreatePos ? "Create new POS user" : "Edit POS User"}
            onBackHandler={()=> { resetFormData(); onBackHandler();} }
          />

          <div className="row mt-2" style={{width: "100%"}}>
            <div className="offset-1 col-10 p-3">
              <div className="col-12">
                <div
                  className="row bg-rfid-detailsm form bg-white ml-1 mr-1 pb-5"
                  style={{
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)",
                    borderRadius: 4,
                  }}
                >
                  <div className="row">
                    <div className="row ml-3 mt-4 mr-2">
                      <FormWarning
                        inline={true}
                        message="Please note that all fields are compulsory"
                      />
                    </div>
                  </div>

                  <div className="row ml-2 mr-2">
                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="First Name"
                      labelClass="mt-3"
                      value={formData.firstName}
                      handleInput={handleInput.bind(this, "firstName")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Last Name"
                      labelClass="mt-3"
                      value={formData.lastName}
                      handleInput={handleInput.bind(this, "lastName")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Phone Number"
                      labelClass="mt-3"
                      value={formData.phone}
                      handleInput={handleInput.bind(this, "phone")}
                    />

                      <CustomInput
                        parentClass="col-4 mt-4"
                        label="Users Code"
                        labelClass="mt-3"
                        value={formData.usersCode}
                        type="number"
                        maxLength={4}
                        handleInput={handleInput.bind(this, "usersCode")}
                      />

                      <CustomInput
                        parentClass="col-4 mt-4"
                        label="Users ID"
                        labelClass="mt-3"
                        type="number"
                        maxLength={4}
                        value={formData.usersId}
                        handleInput={handleInput.bind(this, "usersId")}
                      />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Email"
                      labelClass="mt-3"
                      value={formData.email}
                      handleInput={handleInput.bind(this, "email")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="Street"
                      labelClass="mt-3"
                      value={formData.street}
                      handleInput={handleInput.bind(this, "street")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="City"
                      labelClass="mt-3"
                      value={formData.city}
                      handleInput={handleInput.bind(this, "city")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-4"
                      label="State"
                      labelClass="mt-3"
                      value={formData.state}
                      handleInput={handleInput.bind(this, "state")}
                    />

                    <CustomInput
                      presetData="Select Country..."
                      data={countries}
                      labelClass="mt-3"
                      parentClass="col-4 mt-4"
                      label="Select Country"
                      value={formData.country}
                      isSelect={true}
                      handleInput={handleInput.bind(this, "country")}
                    />

                    {!isStationManager? <CustomInput
                      presetData= {isCreatePos? "Select Terminal...": formData.terminalName}
                      data={terminalList}
                      labelClass="mt-3"
                      parentClass="col-4 mt-4"
                      label="Select Terminal"
                      value={formData.terminalIdMerchantId}
                      isSelect={true}
                      handleInput={handleInput.bind(this, "terminalIdMerchantId")}
                    /> :
                    <CustomInput
                       labelClass="mt-3"
                       parentClass="col-4 mt-4"
                       label="Terminal Name"
                       disabled={true}
                       value={formData.terminalName ? formData.terminalName : 'unavailable'}
                    />
                    }
                  </div>

                  <ToastAlert
                    showToast={!!posValidationError}
                    parentClass={"col-12 pb-3"}
                    childClass={toast.class}
                    toastText={toast.text}
                    onCloseHandler={() => {
                      setPosValidationError(false);
                    }}
                  />
                </div>

                <div className="buttons mt-3 text-right">
                  <div
                    className="mr-4"
                    style={{
                      backgroundColor: "#5F738C",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={()=> { resetFormData(); onBackHandler()} }
                  >
                    Cancel
                  </div>
                  <div
                    style={{
                      backgroundColor: "#00425F",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={
                      !isCreatingPos && !isEditingPos
                        ? submitPosForm
                        : undefined
                    }
                  >
                    {isCreatingPos || isEditingPos ? (
                      <img
                        src="/images/ActivityIndicator.gif" alt="activity-indicator"
                        style={{height: 20, width: 20}}
                      />
                    ) : isCreatePos ? (
                      "Create POS User"
                    ) : (
                      "Edit POS User"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
