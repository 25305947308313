import React from "react";
import { Bar, Line } from "react-chartjs-2";
import "../../../assets/Styles/Dashboard.css";
import {
  TransactionSVG,
  AnalyticsSVG,
  SalesSVG,
} from "../../../assets/Svgs/Svgs";
import { longMonthNames } from "../../../lib/Functions/DateNames";
import formatAmount from "../../../lib/Functions/formatAmount";

const Analytics = (props) => {
  const { oTrailReducer, currentDate } = props;
  const {
    transactionFrequencies: {
      dailyAmount = 0,
      // dailyVolume = 0,
      weeklyAmount = 0,
      // weeklyVolume = 0,
      monthlyAmount = 0,
      // monthlyVolume = 0,
    },
  } = oTrailReducer;
  // let checkArray = [];
  // let dataArray = [];
  // !!content && content.map((val) => {
  //     if (!checkArray.includes(val.vendor)) {
  //       checkArray.push(val.vendor);
  //       dataArray.push({ vendor: val.vendor, num: 0 });
  //     }

  //     dataArray.map((item, i) => {
  //       if (item.vendor === val.vendor) {
  //         dataArray[i].num += 1;
  //       }
  //       return;
  //     });
  //   });
  // dataArray.sort((a, b) => {
  //   return a.num - b.num;
  // });

  // dataArray.splice(3);

  // checkArray = dataArray.map((val) => {
  //   return val.vendor;
  // });
  const currentMonth = longMonthNames[new Date().getMonth()];

  const today = new Date(currentDate);
  const currentMonthx = today.getMonth() % 12;
  const lastThreeMonths = {
    'names': [
      longMonthNames[((currentMonthx - 2) < 0 ? (12 - Math.abs(currentMonthx - 2)) : (currentMonthx - 2))],
      longMonthNames[((currentMonthx - 1) < 0 ? (12 - Math.abs(currentMonthx - 1)) : (currentMonthx - 1))],
      longMonthNames[currentMonthx],
    ],
    'numbers': [currentMonthx - 2, currentMonthx - 1, currentMonthx]
  };

  return (
    <div className="charts-container row">
      <div className="col-4">
        <span className="text-nowrap">
          <span className="title d-inline mr-3">
            Top Customers - {currentMonth}
          </span>
          <AnalyticsSVG />
        </span>
        <span className="sub-title">
          The table shows the top 3 transacting customers and the amount used.
        </span>
      </div>
      <div className="col-4">
        <span className="text-nowrap">
          <span className="title d-inline mr-3">
            3 Months Customers Transations
          </span>
          <TransactionSVG color={"#00B8DE"} />
        </span>
        <span className="sub-title">
          The graph represents a 3 months customers transactions cycle.
        </span>
      </div>
      <div className="col-4">
        <span className="text-nowrap">
          <span className="title d-inline mr-3">
            Customers Transactions - {currentMonth}
          </span>
          <SalesSVG />
        </span>
        <span className="sub-title">
          Daily, Weekly and Monthly transaction inflows.
        </span>
        <br />
      </div>
      <div className="row">
        <div className="col-4 bg-white pt-3 pb-3 mr-0">
          {<Bar
            data={{
              labels: ['Sorox', 'SeNormal', 'TunguVent Plc.'],
              datasets: [
                {
                  label: "Customer",
                  backgroundColor: "#025ec3",
                  borderColor: "#025ec3",
                  borderWidth: 1,
                  hoverBackgroundColor: "#025ec3",
                  hoverBorderColor: "#025ec3",
                  data: [200,320, 440],
                },
              ],
            }}
            width={6}
            height={5}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    offset: true,
                  }
                ],
              }
            }}
          />}
          {/* <img
            src="/images/new/driveAnalytics.png" alt="driver-analytics"
            style={{width: "100%", height: 240, objectFit: "fill"}}
          /> */}
        </div>
        <div className="col-4 bg-white pt-3 pb-3 ml-0">
          {/* <img
            src="/images/new/trendsChart.png" alt="trends-chart"
            style={{width: "100%", height: 240, objectFit: "fill"}}
          /> */}
          <Line
            data={{
              labels: lastThreeMonths['names'],
              datasets: [
                {
                  label: 'Value of Transactions',
                  data: [12, 19, 3],
                  fill: false,
                  backgroundColor: 'rgb(255, 255, 255)',
                  borderColor: 'rgba(7, 80, 168, 0.8)',
                  // borderColor: 'rgba(7, 80, 168, 0.3)',
                  // backgroundColor: 'rgb(208, 2, 27)',
                  // borderColor: 'rgba(208, 2, 27, 0.2)',
                  pointStyle: 'circle',
                },
              ],
            }}
            width={6}
            height={5}
            options={{
              spanGaps: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                    // stacked: true,
                  },
                ],
                xAxes: [
                  {
                    offset: true, 
                    ticks: {
                      beginAtZero: true,
                    }
                  }
                ]
              },
            }}
          />
        </div>
        <div className="col-4 bg-white pt-4 pb-3 mr-0">
          <div className="revenue-box col-12">
            <div className="row table-title text-white">
              <div className="col-6">FREQUENCY</div>
              <div className="col-6">TRANSACTION AMOUNT</div>
            </div>
            <div className="revenue-box-row row">
              <div className="col-6">DAILY</div>
              <div className="col-6">{ formatAmount(dailyAmount)/* 500,000 */}</div>
            </div>
            <div className="revenue-box-row row">
              <div className="col-6">WEEKLY</div>
              <div className="col-6">{ formatAmount(weeklyAmount)/* 1,000,000 */}</div>
            </div>
            <div className="revenue-box-row row">
              <div className="col-6">MONTHLY</div>
              <div className="col-6">{ formatAmount(monthlyAmount)/* 2,500,000 */}</div>
            </div>
            <div className="revenue-box-footer row">
              This data is updated real time to reflect revenue and performance
              metrics.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
