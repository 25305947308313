import axios from 'axios';
import React, { useState } from 'react';
import queryString from 'query-string';
import { /* GET_ALL_VENDORS,  */SELECTED_VENDOR, SELECTED_VENDOR_LOCATIONS } from './VendorMgtConstants';
import { customConfig } from '../../../../actions/action_constants';
import { customerService } from '../../../../services/authService';
import { authHeader } from '../../../../helpers/authHeader';
import { errorHandler } from '../../../../helpers/misc';
const API_URL = customConfig.API_URL;
const API_URL_VOUCHER =  customConfig.API_URL_SF_VOUCHER

// export const getAllVendors = vendors => dispatch => { 
//   dispatch({
//     type: GET_ALL_VENDORS,
//     vendors: '[]',
//   });
// };

export const SelectedVendor = vendor => dispatch => {
  dispatch({
    type: SELECTED_VENDOR,
    vendor,
  });
};

export const SelectedVendorLocations = locations => dispatch => {
  dispatch({
    type: SELECTED_VENDOR_LOCATIONS,
    locations,
  });
};


export function loadAllCustomerVendors(cid) {
  return function(dispatch) {
    dispatch({ type: 'GET_CUSTOMER_VENDORS_START' });
    axios
      .get(`${API_URL}api/customers/vendors?cid=${cid}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'GET_CUSTOMER_VENDORS_FULFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'GET_CUSTOMER_VENDORS_REJECTED', payload: err });
      });
  };
}

export function getAllPosUsers(managerId, page = 1, pageSize = 10) {
  return function(dispatch) {
    dispatch({ type: 'GET_POSUSER_START'});
    axios
    .get(`${API_URL}api/users/stationposuser?manager=${managerId}&page=${page}&size=${pageSize}`, { headers: authHeader() })
    .then(res => {
      dispatch({ type: 'GET_POSUSER_SUCCESS', payload: res.data})
    })
    .catch(err => {
      dispatch({ type: 'GET_POSUSER_ERR', payload: err});
    })
  };
}

export function fetchTerminalDetail() {
  return function(dispatch) {
    dispatch({ type: 'GET_TERMINAL_DETAILS'});
    axios
    .get(`${API_URL}api/terminals`, { headers: authHeader() })
    .then(res => {
      dispatch({ type: 'GET_TERMINAL_DETAILS_SUCCESS', payload: res.data})
    })
    .catch(err => {
      dispatch({ type: 'GET_TERMINAL_DETAILS_ERR', payload: err});
    })
  };
}

export function getVendorsThatAgreeToSignWithCustomer(id) {
  return dispatch => {
    dispatch(request());

    customerService.getVendorsThatAgreeToSignWithCustomer(id).then(payload => dispatch(success(payload)), error => dispatch(failure(error.toString())));
  };

  function request() {
    return { type: 'FETCH_All_CUSTOMER_AGREED_VENDORS_START' };
  }
  function success(payload) {
    return { type: 'FETCH_All_CUSTOMER_AGREED_VENDORS_FULLFILLED', payload };
  }
  function failure(error) {
    return { type: 'FETCH_All_CUSTOMER_AGREED_VENDORS_REJECTED', error };
  }
}

export function customerSignVendorsContract(cid, accountNUmber = '') {
  return function(dispatch) {
    dispatch({ type: 'SIGN_VENDORCONTRACT_START' });
    axios
      .put(`${API_URL}api/contracts/csign?cid=${cid}&accountNumber=${accountNUmber}`, {}, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'SIGN_VENDORCONTRACT_FULFILLED', payload: res.data, contractId: cid });
      })
      .catch(err => {
        const errObj = err.response.data;
        dispatch({ type: 'SIGN_VENDORCONTRACT_REJECTED', payload: errObj.status?.description || errObj.status?.message });
      });
  };
}

export function removeCustomerVendorContract () {
  return dispatch => {
    dispatch ({type: 'REMOVE_ONE_CONTRACT'})
  }
}

export function fetchStateProductPrice ({vendorNumber, produtName}) {
  return function(dispatch) {
    dispatch({ type: "FETCH_STATE_PRICE" });
    axios
      .get(
        API_URL + `api/stateproductprice/byvendorandproduct?vendorNumber=${vendorNumber}&productName=${produtName}`,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: "FETCH_STATE_PRICE_COMPLETED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({ type: "FETCH_STATE_PRICE_ERROR", payload: errorHandler(err) });
      });
  }
}

export function fetchState ({page, pageSize}) {
  return function(dispatch) {
    dispatch({ type: "FETCH_STATES" });
    axios
      .get(
        API_URL + `api/states/all?page=${page}&size=${pageSize}`,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: "FETCH_STATES_COMPLETED",
          payload: res.data
        });
      })
      .catch(err => {
    dispatch({ type: "FETCH_STATES_ERROR", payload: errorHandler(err) });
      });
  }
}
  // query = { amount, cid, stateId}
export function calculateProductVolume (query){
  let query_ = queryString.stringify(query,{ skipEmptyString: true, skipNull: true})
  return function(dispatch) {
    dispatch({ type: "FETCH_STATEVOLUME" });
    axios
      .get(
        API_URL + `api/contracts/productquantities?${query_}`,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: "FETCH_STATEVOLUME_FULLFILLED",
          payload: res.data
        });
      })
      .catch(err => {
    dispatch({ type: "ERROR", payload: errorHandler(err) });
      });
  }
}

export function addStateProductPrice (body) {
  return function(dispatch) {
    dispatch({ type: "CREATE_STATE_PRICE" });
    axios
      .post(
        API_URL + `api/stateproductprice`,
        body,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: "CREATE_STATE_PRICE_COMPLETED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({ type: "ERROR", payload: err });
        dispatch({ type: "CREATE_STATE_PRICE_ERROR",  payload: errorHandler(err)})
      });
  }
}

export function uploadBulkStatePrice (file) {
  let body = new FormData();
  body.append('file', file);
  return function(dispatch) {
    dispatch({ type: "UPLOAD_STATE_PRICE" });
    axios
      .post(
        API_URL + `api/stateproductprice/upload`,
        body,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: "UPLOAD_STATE_PRICE_COMPLETED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({ type: "UPLOAD_STATE_PRICE_ERROR", payload: errorHandler(err) });
      });
  }
}

export function useCreateMultipleUser(cid) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const postData = async (payload) => {
    setError(null);
    let body = new FormData();
    body.append('cid', cid);
    body.append('file', payload);
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL}api/customers/users/upload`, body, { headers: authHeader() });
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };
  return { data, error, isLoading, postData };
}

export function useCreateMultipleTerminals() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const postData = async (payload, vid) => {
    setError(null);
    let body = new FormData();
    body.append('vid', vid);
    body.append('file', payload);
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL}api/terminals/upload`, body, { headers: authHeader() });
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };
  return { data, error, isLoading, postData };
}

export function useCreateMultipleTerminalUsers() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const postData = async (payload, vid) => {
    setError(null);
    let body = new FormData();
    body.append('vid', vid);
    body.append('file', payload);
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL}api/users/terminalusers/upload`, body, { headers: authHeader() });
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };
  return { data, error, isLoading, postData };
}

export function useCreateMultipleVoucher() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const postData = async (payload, cus, userId) => {
    setError(null);
    let body = new FormData();
    body.append('userNumber', userId);
    body.append('file', payload);
    body.append('customerNumber', cus);
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL_VOUCHER}api/v1/vouchers/upload`, body, { headers: authHeader() });
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };
  return { data, error, isLoading, postData };
}
