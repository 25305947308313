import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import "../../../../src/assets/Styles/Dashboard.css";
import {
  loadAllCustomerVendors
} from "../redux/action/OwnerVendorActions";
import ContractsStatusBar from "./StatusBar";
import ContractList from "./ContractList";
import {
  getCustomerContracts
} from "../../ownerModule/redux/action/OwnerContractsActions";
import { getUserProperties } from "../../../lib/Constants/UserTypes";
import { getSchemesByCus } from "../../../actions/CustomerActions/CardMgtActions/CardAction";

function Contracts () {
  const dispatch = useDispatch();
  const userProperties = getUserProperties();
  const customerNumber = userProperties.customerNumber;
  const props = useSelector(state => state)
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);

  useEffect(() => {
    batch(() => {
      dispatch(loadAllCustomerVendors(
        props.authentication.user.customerNumber?.replace('CUS', '') ??
        props.authentication.user.number?.replace('USER', '')
      ))
      dispatch(getCustomerContracts(props.authentication.user.customer, 1, 10));
      dispatch(getSchemesByCus(customerNumber));
    });
  }, []);

  return (
    <div className="dashboard-container">
      {isStatusBarVisible && <ContractsStatusBar {...props} />}

      <ContractList
        {...props}
        onHideStatusBar={status => {
          setIsStatusBarVisible(!status);
        }}
        backFunction = {() => {
          setIsStatusBarVisible(true)
        }}
      />
    </div>
  );
}

export default Contracts;
