import React/* , {useEffect} */ from "react";
import {useDispatch, useSelector/* , batch */} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import StatusBar from '../StatusBar';
import PosUsersList from "./PosUsersList";
import { useState } from "react";
import {
  singlePOSUserEditForm,
  singlePOSUserCreateForm,
} from "../../../ownerModule/redux/action/OwnerVendorActions";
import { fetchVendorPosUsers } from "../../../../actions/VendorActions/TerminalActions/TerminalAction";
import { getUserProperties } from "../../../../lib/Constants/UserTypes";

function PosUsers () {
  const dispatch = useDispatch();
  const [ isStatusBarVisible, setIsStatusBarVisible ] = useState(true);
  const venId = getUserProperties().vendor;

  const createPos = async (data, callback) => {
    const posUser = data;
    let dt = data.terminalIdMerchantId.split(":");
      let body = { 
        firstName: posUser.firstName,
        lastName: posUser.lastName,
        userCode: posUser.usersCode,
        userId: posUser.usersId,
        userEmail: posUser.email,
        phone: posUser.phone,
        vendor: posUser.vendor,
        merchantId: dt[1],
        terminalId: dt[0],
        address: {
          street: posUser.street,
          city: posUser.city,
          state: posUser.state,
          country: posUser.country,
        }
      }
     await dispatch(singlePOSUserCreateForm(body));
    callback(true);
  }

  const editPos = async (data, callback) => {
    const posUser = data;
    let dt = data.terminalIdMerchantId.split(":");
      let body = {
        firstName: posUser.firstName,
        lastName: posUser.lastName,
        userCode: posUser.usersCode,
        userId: posUser.usersId,
        userEmail: posUser.email,
        phone: posUser.phone,
        vendor: posUser.vendor,
        merchantId: dt[1],
        terminalId: dt[0],
        address: {
          street: posUser.street,
          city: posUser.city,
          state: posUser.state,
          country: posUser.country,
        }
      }
     await dispatch(singlePOSUserEditForm(body));
    callback(true);
  }

  const props = useSelector(state => state);
  
  return (
    <div className="dashboard-container">
      <StatusBar {...props} isVisible={isStatusBarVisible} />
      <PosUsersList {...props} onFetchPosUsers={(pageNo, size) => {
        dispatch(fetchVendorPosUsers(venId, pageNo, size));
      }}
      onHideStatusBar={status => {
        setIsStatusBarVisible(!status);
      }}
        onCreatePos={createPos}
        onEditPos={editPos}
       />
    </div>
  )
}

export default PosUsers;