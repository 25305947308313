import React, { useEffect } from "react";
import "../../../../../assets/Styles/Dashboard.css";
import { shortMonthNames } from "../../../../../lib/Functions/DateNames";
import { useDispatch, batch} from "react-redux";
import { getUserProperties } from "../../../../../lib/Constants/UserTypes";
import { getAllPosUsers } from "../../../../customerModule/redux/action/VendorAction";

const StationStatusBar = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(getAllPosUsers(getUserProperties().id))
  }, []);

  const {oVendorReducer} = props;
  const posUsers = oVendorReducer.allPosUser;
  
  let activePosUser = 0, inactivePosUser = 0;

  posUsers && posUsers.data && posUsers.data.forEach(value => {
    if(value.status === "ACTIVE") {
      activePosUser += 1;
    } else inactivePosUser += 1;
  });
  const d = new Date();

  return (
    <div
      className={`status-container row ${
        props.isVisible ? "d-block" : "d-none"
      }`}
    >
      <div className="compartment compartment-one col-3">
        <span className="title d-block mb-2">Terminals</span>
        <span className="sub-title">
          POS accounts.
        </span>
        <br />
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
      </div>
      <div className="compartment compartment-two col-9 ">
        <div className="row token-status-box">
          <div
            className="col col-12"
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 16,
              width: "50%",
            }}
          >
            <div className="row">
              <span className="title">POS Users</span>
            </div>
            <div className="row status-sub-title">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ACTIVE</div>
              <div className="col-4 pl-0">INACTIVE</div>
            </div>
            <div className="row status-values">
              <div className="col-4 pl-0">
                {(posUsers && posUsers.count) || 0}
              </div>
              <div className="col-4 pl-0">{activePosUser}</div>
              <div className="col-4 pl-0">{inactivePosUser}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationStatusBar;