import React, { useState } from "react";

import Toast from "react-bootstrap/Toast";
// import { ToastContainer } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function AutohideExample({ show, setShow, message, isCreatingUser }) {
  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      //   className="position-relative"
      //   style={{ position: "fixed" }}
    >
      {/* <ToastContainer position="top-start" className="p-3"> */}
      <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
        <Toast.Body
          className={
            !isCreatingUser ? "text-white bg-danger" : "text-white bg-success "
          }
        >
          {message}
        </Toast.Body>
      </Toast>
      {/* </ToastContainer> */}
    </div>
  );
}

export default AutohideExample;
