import React, { useEffect, useState } from "react";
import {
  FilterSVG,
  ExportSVG,
  CreateSVG,
  // LoadingSVG
} from "../../../../assets/Svgs/Svgs";
import {
  shortMonthNames,
  shortDayNames,
  // longMonthNames
} from "../../../../lib/Functions/DateNames";
import { useDispatch } from "react-redux";
import AccountDetails from "./UserProfile";
import CreateUser from "./CreateUser";
import { fetchModules, fetchUser/* , selectedUserObject */ } from "./users.actions";
import "./Users.css";
import { CSVLink } from "react-csv";
import Pagination from "../../../../lib/Components/Pagination";
import PendingRequest from "../../../vendorModule/Contracts/Pendingrequest";
import { Spin } from "antd";

const displayUsers = (users, callback) => {
  return users.map((value, index) => {
    const { name, createdAt, status, userEmail, userLevel } = value;
    const d = new Date(createdAt);
    return (
      <tr key={index}>
        <td className="font-weight-bold">{name} </td>
        <td className="font-weight-bold">{userEmail}</td>
        <td className="font-weight-bold">{userLevel?.replace('_', '-')}</td>
        <td className="table-date">
          {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
          {shortMonthNames[d.getMonth()]} {d.getFullYear()}
        </td>
        {status.toUpperCase() === "ACTIVE" ? (
          <td className="table-status">
            <div className="status-btn success">{"Active"}</div>
          </td>
        ) : (
          <td className="table-status">
            <div className="status-btn error">{"Suspended"}</div>
          </td>
        )}
        <td className="table-action" onClick={() => callback(value)}>
          View
        </td>
      </tr>
    );
  });
};

const UsersList = props => {
  const [selectedUser, setSelectedUser] = useState({});
  const { ownerUsers } = props;
  const { users, availableModules, fetching } = ownerUsers;
  // const [usedPageNo, setusedPageNo] = useState(1);
  const dispatch = useDispatch();
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  /* const { name, contact, createdAt, status } = selectedUser;
  const d = new Date(createdAt);
  const userCreatedDate = `${d.getDate()}, ${
    longMonthNames[d.getMonth()]
  } ${d.getFullYear()}`; */
  const pageSize = 10;

  useEffect(() => {
    dispatch(fetchModules()); // effect
  }, [])

  return (
    <>
      {!showAccountDetails && !showCreateUser && (
        <div className="charts-container row">
          <div className="table-menu-link">
            <span>Users</span>

            <div className="action-buttons">
              {!!users && <div className="export-btn d-inline-block">
                <CSVLink
                  data={users.data || []}
                  filename={"List of Users.csv"}
                  className=" text-white"
                  target="_blank"
                >
                  Export CSV{" "}
                  <i>
                    <ExportSVG />
                  </i>
                </CSVLink>
              </div>}
              <button
                className="add-btn"
                onClick={() => {
                  props.onHideStatusBar(true);
                  setShowCreateUser(true);
                }}
              >
                <i>
                  <CreateSVG />
                </i>
                Add new user
              </button>
            </div>
          </div>
          <PendingRequest />
          <Spin wrapperClassName='spinner-wrapper' spinning={fetching} tip="Loading...">
          <table className="table table-hover">
            <thead>
              <tr>
                <td className="table-title">Name</td>
                <td className="table-title">Email</td>
                <td className="table-title">
                  Users Level
                  <i className="filter-bars">
                    <FilterSVG />
                  </i>
                </td>
                <td className="table-title">
                  Date Created
                  <i className="filter-bars">
                    <FilterSVG />
                  </i>
                </td>
                <td className="table-title">
                  Status
                  <i className="filter-bars">
                    <FilterSVG />
                  </i>
                </td>
                <td className="table-title"></td>
              </tr>
            </thead>
            <tbody>
              {users.count > 0 &&
                displayUsers(users.data, user => {
                  setSelectedUser(user);
                  props.onHideStatusBar(true);
                  setShowAccountDetails(true);
                })}
              {users.count < 0 ||
                (!users.count && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      {users.description || "No Users available"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          </Spin>
          <Pagination pageSize={pageSize} count={users.count} fetchMethod={(value, pageSize) => {
              dispatch(fetchUser(value, pageSize));
          }} />
        </div>
      )}

      {showAccountDetails && (
        <AccountDetails
          user={selectedUser}
          modules={availableModules || []}
          {...props}
          backFunction={() => {
            setShowAccountDetails(false);
            props.onHideStatusBar(false);
          }}
          onEditingDone = { () => {
            setShowAccountDetails(false);
            props.onHideStatusBar(false);
            window.location.reload();
          }}
        />
      )}

      {showCreateUser && (
        <CreateUser
          {...props}
          backFunction={() => {
            setShowCreateUser(false);
            props.onHideStatusBar(false);
          }}
          onCreatingDone = {()=> {
            window.location.reload();
          }}
        />
      )}
    </>
  );
};

export default UsersList;
