import React, { useEffect } from "react";
import "../../../../assets/Styles/Dashboard.css";
import { shortMonthNames } from "../../../../lib/Functions/DateNames";
import "./Styles/AccountStatusBar.css";
import { useDispatch, batch } from "react-redux";
import { getAllUsers } from "../../redux/action/customerUserActions";
import { getUserProperties } from "../../../../lib/Constants/UserTypes";

const StatusBar = (props) => {
  const dispatch = useDispatch();
  const { customerUser } = props;
  const allUsers = customerUser?.users;
  const allUsersCount = allUsers && allUsers.length;
  const d = new Date();

  const activeUsers = allUsers ? allUsers.filter(val => val.status === 'ACTIVE'): [];
  const activeUsersCount = activeUsers.length || 0;
  const inActiveUsersCount = allUsersCount - activeUsersCount || 0;

  useEffect(() => {
    batch(() => {
      dispatch(getAllUsers(getUserProperties().type));
    });
  }, []);

  return (
    <div
      className={`status-container row ${
        props.isVisible ? "d-block" : "d-none"
      }`}
    >
      <div className="compartment compartment-one col-3">
        <span className="title">Accounts Insights</span>
        <span className="sub-title pt-4">
         Summary of all users profile, active and disabled accounts
        </span>
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
      </div>
      <div className="compartment compartment-two col-9 ">
        <div className="row status-box">
          <div
            className="col offset-2 col"
            style={{
              position: "relative",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 16,
              width: "25%",
            }}
          >
            <div className="row">
              <span className="title">Users</span>
            </div>
            <div className="row status-sub-title">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ACTIVE</div>
              <div className="col-4 pl-0">SUSPENDED</div>
            </div>
            <div className="row status-values">
              <div className="col-4 pl-0">{allUsersCount}</div>
            <div className="short-borders" style={{left: 170}}></div>
              <div className="col-4 pl-0">{activeUsersCount}</div>
            {/* <div className="short-borders" style={{left: 440}}></div> */}
              <div className="col-4 pl-0">{inActiveUsersCount}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusBar;
