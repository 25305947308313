import React, { Component } from "react";
import "../Admin.css";
import "../../../../src/assets/Styles/SideMenu.css";
import {
  DashboardSvg,
  AccountSvg,
  ContractSvg,
  TokenSvg,
  AuditSvg,
  ReportSvg,
  CustomerSVG
} from "../../../assets/Svgs/Svgs";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import { getAllUsers } from "../../customerModule/redux/action/customerUserActions";
import { getUserProperties } from "../../../lib/Constants/UserTypes";
import { loadAllCustomerVendors } from "../../customerModule/redux/action/OwnerVendorActions";
// import fakeAuth from '../../../App'

class CMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // let is logged in be taken from fakeAuth
      isloggedIn: false,
      customerMenu: false,
      vendorMenu: false,
      ownerMenu: false,
      user: JSON.parse(localStorage.getItem("user"))
    };
  }

  handleSearchBtnClick(e) {
    const pathName = window.location.pathname;

    if (pathName === "/customer-admin/users/main") {
      this.props.getAllUsersFn(e.target.value)
    }

    if (pathName === "/customer-admin/contracts/main") {
      this.props.loadAllCustomerVendorsFn(e.target.value)
    }
  }

  render() {
    const pathName = window.location.pathname;

    return (
      <div>
        <Navbar onSearchBtnClick={this.handleSearchBtnClick.bind(this)} />

        <div className="side-menu-container">
          <ul className="">
            <NavLink to={!this.state.user.isNew ? "/customer-admin/dashboard": "#"}>
              <li
                className={`side-menu-list ${
                  (pathName === "/customer-admin/dashboard" || pathName === "/customer-admin/change-password/main") && "active"
                }`}
              >
                <DashboardSvg />
                <span>Dashboard</span>
              </li>
            </NavLink>

            {!this.state.user.isNew && <><NavLink to="/customer-admin/users">
              <li
                className={`side-menu-list ${
                  (pathName.indexOf("/customer-admin/users/main") > -1 ||
                    pathName.indexOf("/customer-admin/customers/") > -1/*  ||
                    pathName.indexOf("/customer-admin/vendors/") > -1 */) &&
                  "active"
                }`}
              >
                {pathName === "/customer-admin/customers/main" ? (
                  <CustomerSVG />
                ) : (
                  <AccountSvg />
                )}
                <span>Accounts</span>
              </li>
            </NavLink>

            <NavLink to="/customer-admin/scheme/main">
              <li
                className={`side-menu-list ${
                  (pathName === "/customer-admin/cards/main" ||
                  pathName === "/customer-admin/vouchers/main" ||
                  pathName === "/customer-admin/scheme/main") &&
                  "active"
                }`}
              >
                <TokenSvg />
                <span>Tokens</span>
              </li>
            </NavLink>

            <NavLink to="/customer-admin/contracts">
              <li
                className={`side-menu-list ${
                  (pathName === "/customer-admin/contracts/main" ||
                  pathName.indexOf("/customer-admin/vendors/") > -1) && "active"
                }`}
              >
                <ContractSvg />
                <span>Contracts</span>
              </li>
            </NavLink>
            <NavLink to="/customer-admin/payments">
              <li
                className={`side-menu-list ${
                  (pathName === "/customer-admin/payments/main" || pathName === '/customer-admin/invoices/main') && "active"
                }`}
              >
                <ContractSvg />
                <span>Payments</span>
              </li>
            </NavLink>

            {/* {(hasRole(this.state.user, ['SUPER_ADMIN', 'ADMIN']) || isAllowed(this.state.user, ['VIEW_SETTLEMENT', 'VIEW_TRANSACTION'])) && ( */}
            <NavLink to="/customer-admin/reports">
              <li
                className={`side-menu-list ${
                  (pathName === "/customer-admin/reports/main" ||
                    pathName === "/customer-admin/settlements/main") &&
                  "active"
                }`}
              >
                <ReportSvg />
                <span>Reports</span>
              </li>
            </NavLink>
            {/* )} */}

            {/* {/* {(hasRole(this.state.user, ['SUPER_ADMIN', 'ADMIN']) || isAllowed(this.state.user, ['VIEW_AUDIT'])) && ( */}
            <NavLink to="/customer-admin/trail">
              <li
                className={`side-menu-list ${
                  pathName.indexOf("/customer-admin/trail") > -1 && "active"
                }`}
              >
                <AuditSvg />
                <span>Audit Trail</span>
              </li>
            </NavLink>
            {/* )} */}

            </>}
          </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllUsersFn: query => dispatch(getAllUsers(getUserProperties().type, query)),
    loadAllCustomerVendorsFn: query => dispatch(loadAllCustomerVendors(getUserProperties().customerNumber.replace('CUS', ''), query)),
  }
};

export default connect(null, mapDispatchToProps)(CMenu);
