import React, { useState, useEffect } from "react";
import FormWarning from "../../../admin/shared/FormWarning";
import { countries } from "../../../../lib/Dropdowns/countries";
import { useDispatch } from "react-redux";
import validator from "validator";
import { SECTORS } from "../../../admin/shared/assets";
import {
  submitVendorForm,
  submitVendorEdit,
  resetVendorUpdate
} from "../../redux/action/OwnerVendorActions";
import { resetVendorCreation } from "./vendors.actions";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import CustomInput from "../../../../lib/Components/CustomInput";

const EditForm = ({
  create,
  authentication,
  profilePix,
  vendorCreationError,
  vendorCreationErrorHandler,
  oVendorReducer,
  resetForm,
  vendorToBeEdited,
  saveEditForm,
  onSaveEditFormHandler
}) => {
  const condition = vendorToBeEdited && vendorToBeEdited.address;
  const formObj = {
    name: (vendorToBeEdited && vendorToBeEdited.name) || "",
    image: (vendorToBeEdited && vendorToBeEdited.image) || "",
    contact: {
      email: (vendorToBeEdited && vendorToBeEdited.contact.email) || "",
      phoneNumber:
        (vendorToBeEdited && vendorToBeEdited.contact.phoneNumber) || ""
    },
    address: {
      street: (condition && vendorToBeEdited.address.street) || "",
      city: (condition && vendorToBeEdited.address.city) || "",
      state: (condition && vendorToBeEdited.address.state) || "",
      country: (condition && vendorToBeEdited.address.country) || ""
    },
    sector: (vendorToBeEdited && vendorToBeEdited.sector) || "",
    rcNumber: (vendorToBeEdited && vendorToBeEdited.rcNumber) || "",
    description: (vendorToBeEdited && vendorToBeEdited.description) || ""
  };
  const { owner, customer, vendor, type } = authentication.user;
  if (type === "OWNER_USER") formObj.owner = owner;
  else if (type === "CUSTOMER_USER") formObj.customer = customer;
  else if (type === "VENDOR_USER") formObj.vendor = vendor;
  formObj.type = type;
  const [formData, setFormData] = useState(formObj);
  const [formatError, setFormatError] = useState("");

  useEffect(() => {
    setFormData({ ...formData, image: profilePix.base64 });
  }, [profilePix]);

  useEffect(() => {
    if (vendorCreationError === true) {
      setFormatError(oVendorReducer.vendorCreationErrorMessage);
      vendorCreationErrorHandler(false);
    }
  }, [vendorCreationError]);

  useEffect(() => {
    dispatch(resetVendorCreation());
    dispatch(resetVendorUpdate());
  }, []);

  useEffect(() => {
    formObj.image = "";
    if (resetForm) setFormData(formObj);
  }, [resetForm]);

  useEffect(() => {
    if (saveEditForm) {
      handleCreateVendor();
      onSaveEditFormHandler(false);
    }
  }, [saveEditForm]);

  const dispatch = useDispatch();

  const validationRules = () => {
    return {
      name: val => (val.length < 3 ? `A valid company name is required!` : ""),
      email: val =>
        !validator.isEmail(val) ? "Valid email address is required!" : "",
      phoneNumber: val =>
        !validator.isMobilePhone(val) ? "Valid phone number is required!" : "",
      image: val =>
        val.type !== "image/jpeg" || val.type !== "image/png"
          ? "Invalid image format!"
          : "",
      rcNumber: val =>
        val.length !== 8 ? "A valid rcNumber is required!" : "",
      street: val => (val.length < 3 ? "A valid street name is required!" : ""),
      city: val => (val.length < 3 ? "A valid city name is required!" : ""),
      state: val => (val.length < 3 ? "A valid state name is required!" : ""),
      sector: val => (val.length < 3 ? "A valid sector is required!" : ""),
      country: val =>
        val.length < 3 ? "A valid country name is required!" : ""
    };
  };

  const isValid = () => {
    let response = true;
    let formatError = "";
    const nameErr = validationRules().name(formData.name);
    const emailErr = validationRules().email(formData.contact.email);
    const phoneErr = validationRules().phoneNumber(
      formData.contact.phoneNumber
    );
    const sector = validationRules().sector(formData.sector);
    const rcNumber = validationRules().rcNumber(formData.rcNumber);
    const streetErr = validationRules().street(formData.address.street);
    const cityErr = validationRules().city(formData.address.city);
    const stateErr = validationRules().state(formData.address.state);
    const countryErr = validationRules().country(formData.address.country);

    if (!!nameErr) {
      formatError = nameErr;
      response = false;
    } else if (emailErr) {
      formatError = emailErr;
      response = false;
    } else if (phoneErr) {
      formatError = phoneErr;
      response = false;
    } else if (sector) {
      formatError = sector;
      response = false;
    } else if (rcNumber) {
      formatError = rcNumber;
      response = false;
    } else if (streetErr) {
      formatError = streetErr;
      response = false;
    } else if (cityErr) {
      formatError = cityErr;
      response = false;
    } else if (stateErr) {
      formatError = stateErr;
      response = false;
    } else if (countryErr) {
      formatError = countryErr;
      response = false;
    }

    setFormatError(formatError);
    return response;
  };

  const handleInput = (target, e) => {
    if (target === "email") {
      setFormData({
        ...formData,
        contact: { ...formData.contact, email: e.target.value }
      });
      return;
    }
    if (target === "phoneNumber") {
      setFormData({
        ...formData,
        contact: { ...formData.contact, phoneNumber: e.target.value }
      });
      return;
    }
    if (
      target === "street" ||
      target === "city" ||
      target === "state" ||
      target === "country"
    ) {
      setFormData({
        ...formData,
        address: { ...formData.address, [target]: e.target.value }
      });
      return;
    }

    setFormData({ ...formData, [target]: e.target.value });
  };

  const handleCreateVendor = () => {
    if (!isValid()) return;

    !!create
      ? dispatch(submitVendorForm(formData))
      : dispatch(submitVendorEdit(formData, vendorToBeEdited.id));
  };

  const sectors = SECTORS.map((e, index) => <option key={index}>{e}</option>);

  return (
    <>
      <div className="row">
        <CustomInput
          parentClass="form-group col-3"
          label="Name"
          value={formData.name}
          handleInput={handleInput.bind(this, "name")}
        />
        <CustomInput
          parentClass="form-group col-3"
          label="Email"
          value={formData.contact.email}
          handleInput={(create && handleInput.bind(this, "email")) || undefined}
          disabled={!create}
        >
          <FormWarning message="Ensure you enter the correct email" />
        </CustomInput>

        <CustomInput
          parentClass="form-group col-3"
          label="Phone Number"
          value={formData.contact.phoneNumber}
          handleInput={
            (create && handleInput.bind(this, "phoneNumber")) || undefined
          }
          disabled={!create}
        />
        <CustomInput
          parentClass="form-group col-3"
          label="Registration Number"
          value={formData.rcNumber}
          handleInput={handleInput.bind(this, "rcNumber")}
        />
      </div>
      <div className="row">
        <CustomInput
          presetData="Select Sector..."
          data={sectors}
          parentClass="form-group col-3"
          label="Sector"
          value={formData.sector}
          isSelect={true}
          handleInput={handleInput.bind(this, "sector")}
        />
        <CustomInput
          parentClass="form-group col-3"
          label="Street"
          value={formData.address.street}
          handleInput={handleInput.bind(this, "street")}
        />
        <CustomInput
          parentClass="form-group col-3"
          label="City"
          value={formData.address.city}
          handleInput={handleInput.bind(this, "city")}
        />
        <CustomInput
          parentClass="form-group col-3"
          label="State"
          value={formData.address.state}
          handleInput={handleInput.bind(this, "state")}
        />
      </div>
      <div className="row mt-4">
        <CustomInput
          presetData="Select Country..."
          data={countries}
          parentClass="form-group col-3"
          label="Country"
          value={formData.address.country}
          isSelect={true}
          handleInput={handleInput.bind(this, "country")}
        />
        <CustomInput
          parentClass="form-group col-6"
          label="Description"
          value={formData.description}
          handleInput={handleInput.bind(this, "description")}
        />
      </div>
      <ToastAlert
        showToast={!!formatError}
        parentClass={"col-12 pb-3 pl-0"}
        childClass={""}
        toastText={formatError}
        onCloseHandler={() => {
          setFormatError("");
        }}
      />

      {create && (
        <div className="user-create-btn">
          <button onClick={handleCreateVendor}>Create Vendor</button>
        </div>
      )}
    </>
  );
};

export default EditForm;
