import axios from 'axios';
import { customConfig } from '../../../../actions/action_constants';
import { authHeader } from '../../../../helpers/authHeader';
const API_URL_CARD_ACC = customConfig.API_URL_CARD_ACC;

export function fetchAllBlankcards(page=1,pageSize=10) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_ALL_BLANKCARDS_START' });
    axios
      .get(API_URL_CARD_ACC + `api/v1/cards/assigned?pageNum=${page}&pageSize=${pageSize}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_ALL_BLANKCARDS_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_ALL_BLANKCARDS_REJECTED', payload: err });
      });
  };
}

export function fetchAllBlankcardsCus(cid){
  return function(dispatch) {
    axios
      .get(API_URL_CARD_ACC + `api/v1/cards/assigned?customerId=${cid}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_ALL_BLANKCARDS_FULLFILLED_CUS', payload: res.data.content });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_ALL_BLANKCARDS_REJECTED_BYCID', payload: err });
      });
  };
}
