import React, {useState, useEffect} from "react";
import SubComponentHeader from "../../../../lib/Components/SubComponentHeader";
// import FormWarning from "../../../admin/shared/FormWarning";
import ActionModal from "../../../../lib/Components/ActionModal";
import {useDispatch} from "react-redux";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import CustomInput from "../../../../lib/Components/CustomInput";
import { StateList } from "../../../../services/states";

export default function CreateTerminal({
  onBackHandler,
  isCreateTerminal,
  isEditTerminal,
  oVendorReducer,
  onCreateTerminal,
  onEditTerminal,
  selectedTerminal,
}) {
  const dispatch = useDispatch();

  const [toast, setToast] = useState({class: "", text: ""});
  const [isCreatingTerminal, setIsCreatingTerminal] = useState(false);
  const [isEditingTerminal, setIsEditingTerminal] = useState(false);
  const [terminalValidationError, setTerminalValidationError] = useState(false);
  const [formData, setFormData] = useState({
    terminalName: "",
    terminalId: "",
    merchantId: "",
    dealerCode: "",
    state: "",
    address: "",
  });
  const [
    showTerminalCreationCompletion,
    setShowTerminalCreationCompletion,
  ] = useState(false);
  const [
    showTerminalEditCompletion,
    setShowTerminalEditCompletion,
  ] = useState(false);

  useEffect(() => {
    if (
      !!isCreatingTerminal &&
      oVendorReducer.createTerminalRequestStatus !== 1
    ) {
      setToast({
        class: "",
        text: oVendorReducer.createTerminalRequestMessage,
      });
      setTerminalValidationError(true);
      setIsCreatingTerminal(false);
    }
    if (
      !!isCreatingTerminal &&
      oVendorReducer.createTerminalRequestStatus === 1
    ) {
      resetFormData();
      setIsCreatingTerminal(false);
      setShowTerminalCreationCompletion(true);
    }
    dispatch({
      type: "CREATE_TERMINAL_REQUEST",
      response: {
        status: 0,
        message: "",
      },
    });
  }, [ oVendorReducer.createTerminalRequestStatus ]);

  useEffect(() => {
      if (
        !!isEditingTerminal &&
        oVendorReducer.updateTerminalRequestStatus !== 1
      ) {
        setToast({
          class: "",
          text: oVendorReducer.updateTerminalRequestMessage,
        });
        setTerminalValidationError(true);
        setIsEditingTerminal(false);
      }
      if (
        !!isEditingTerminal &&
        oVendorReducer.updateTerminalRequestStatus === 1
      ) {
        resetFormData();
        setIsEditingTerminal(false);
        setShowTerminalEditCompletion(true);
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
      dispatch({
        type: "UPDATE_TERMINAL_REQUEST",
        response: {
          status: 0,
          message: "",
        },
      });
    }, [oVendorReducer.updateTerminalRequestStatus]);

  useEffect(() => {
    if (isEditTerminal) {
      const { name, terminalId, merchantId, dealerCode, state, address } = selectedTerminal;
      setFormData({
        terminalName: name,
        terminalId,
        merchantId,
        dealerCode,
        state,
        address
      });
    }
  }, [isEditTerminal])

  const resetFormData = () => {
    setFormData({
      terminalId: "",
      terminalName: "",
      merchantId: "",
      dealerCode: "",
      state: "",
      address: ""
    });
  };

  const cancelForm =() => {
     resetFormData();
     onBackHandler();
  }

  const stateOption = StateList && StateList.map((value, key) => {
    return (<option value={value} key={`${key}_${value}`}>
      {value}
    </option>);
  });

  const invalidTerminalForm = () => {
    const { terminalName, terminalId, merchantId, dealerCode, state, address } = formData;
    if (!terminalName) {
      return "Enter a valid terminal name";
    } else if (
      merchantId.length < 15 ||
      /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(merchantId)
    ) {
      return "Merchant ID must be a minimum of 15 characters with no special character";
    } else if (
      terminalId.length < 8 ||
      /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(terminalId)
    ) {
      return "Terminal ID must be a minimum of 8 characters with no special character";
    } else if(dealerCode.length < 5 || dealerCode.length > 8) {
      return "Dealer Code must be minimum of 5 - 8 numeric characters long"
    } else if(!state) {
      return "Select a Valid state"
    } 
    else if(!address) {
      return "Enter a valid address"
    }
    else {
      return "";
    }
  };

  const submitTerminalForm = (event) => {
    event.preventDefault();
    const error = invalidTerminalForm();
    if (!!error) {
      setTerminalValidationError(true);
      setToast({
        class: "",
        text: error,
      });
      return;
    }

    isCreateTerminal && onCreateTerminal(formData, (bool) => {
      setIsCreatingTerminal(bool);
    });

    isEditTerminal && onEditTerminal(formData, (bool) => {
      setIsEditingTerminal(bool);
    });
  };

  const handleInput = (attr, event) => {
    setFormData({...formData, [attr]: event.target.value});
  };

  return (
    <>
      {(isCreateTerminal || isEditTerminal) && (
        <>
          <SubComponentHeader
            heading={isCreateTerminal ? "Create Terminal" : "Edit Terminal"}
            onBackHandler={onBackHandler}
          />

          <div className="row mt-2" style={{width: "100%"}}>
            <div className="offset-1 col-10 p-3">
              <div className="col-10 offset-1">
                <div
                  className="row bg-rfid-details pb-5 pt-5 form bg-white ml-1 mr-1"
                  style={{
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)",
                    borderRadius: 4,
                  }}
                >
                  <div className="row ml-2 mr-2" style={{width: "100%"}}>
                    <CustomInput
                      parentClass="col-6 mt-2 mb-3"
                      label="Terminal Name"
                      value={formData.terminalName}
                      handleInput={handleInput.bind(this, "terminalName")}
                    />

                    <CustomInput
                      parentClass="col-6 mt-2 mb-3"
                      label="Terminal ID"
                      value={formData.terminalId}
                      maxLength={8}
                      disabled={isEditTerminal}
                      handleInput={handleInput.bind(this, "terminalId")}
                    />

                    <CustomInput
                      parentClass="col-6 mt-2 mb-3"
                      label="Merchant ID"
                      value={formData.merchantId}
                      disabled={isEditTerminal}
                      maxLength={15}
                      handleInput={handleInput.bind(this, "merchantId")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-2 mb-3"
                      label="Dealer Code"
                      value={formData.dealerCode}
                      type="number"
                      maxLength={8}
                      handleInput={handleInput.bind(this, "dealerCode")}
                    />

                    <CustomInput
                      presetData="Select State"
                      data={stateOption}
                      parentClass="col-6 mt-2 mb-3"
                      label="State"
                      value={formData.state}
                      isSelect={true}
                      handleInput={handleInput.bind(this, "state")}
                    />

                    <CustomInput
                      parentClass="col-6 mt-2 mb-3"
                      label="Address"
                      value={formData.address}
                      handleInput={handleInput.bind(this, "address")}
                    />
                  </div>

                  <ToastAlert
                    showToast={!!terminalValidationError}
                    parentClass={"col-12 pb-3"}
                    childClass={toast.class}
                    toastText={toast.text}
                    onCloseHandler={() => {
                      setTerminalValidationError(false);
                    }}
                  />
                </div>

                <div className="buttons mt-3 text-right">
                  <div
                    className="mr-4"
                    style={{
                      backgroundColor: "#5F738C",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={cancelForm}
                  >
                    Cancel
                  </div>
                  <div
                    style={{
                      backgroundColor: "#00425F",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={
                      !isCreatingTerminal || !isEditingTerminal
                        ? submitTerminalForm
                        : undefined
                    }
                  >
                    {isCreateTerminal ? "Create terminal" : "Save Changes"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <ActionModal
        isShow={
          isCreatingTerminal ||
          showTerminalCreationCompletion ||
          isEditingTerminal ||
          showTerminalEditCompletion
        }
        isCompleted={!isCreatingTerminal && !isEditingTerminal}
        actionTitle={
          isCreateTerminal ? "Creating Terminal" : "Editing Terminal"
        }
        actionText={`Hold on a sec while we ${
          isCreateTerminal ? "generate" : "edit"
        } your terminal...`}
        isCompletedTitle="You are all set!"
        isCompletedText="Your new terminal has been successfully created"
        updateCompletedStatus={() => {}}
      >
        {showTerminalCreationCompletion ? (
          <div className="text-center mb-5">
            {isCreateTerminal && (
              <div
                className="dark-blue-btn d-inline-block mr-3"
                style={{float: "none", backgroundColor: "#5F738C"}}
                onClick={() => {
                  resetFormData();
                  setShowTerminalCreationCompletion(false);
                  setTerminalValidationError(false);
                }}
              >
                <span className="align-middle text-white">Create New</span>
              </div>
            )}
            <div
              className="dark-blue-btn d-inline-block ml-3"
              style={{float: "none"}}
              onClick={() => {
                onBackHandler();
                setShowTerminalCreationCompletion(false);
                setShowTerminalEditCompletion(false);
                setTerminalValidationError(false);
              }}
            >
              <span className="align-middle text-white">Back to terminals</span>
            </div>
          </div>
        ) : null}
      </ActionModal>
    </>
  );
}
