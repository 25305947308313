import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import { ReactComponent as MenuIcon } from "../../../assets/Svgs/menu_icon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/Svgs/download_icon.svg";
import { ReactComponent as ViewIcon } from "../../../assets/Svgs/view_icon.svg";
import { ReactComponent as EmptyTableIcon } from "../../../assets/Svgs/EmptyTableIcon.svg";
import { ReactComponent as SpinnerGap } from "../../../assets/Svgs/SpinnerGap.svg";
import { ReactComponent as InitiateContractIcon } from "../../../assets/Svgs/AddFile.svg";

import CustomToggle from "../../../lib/Components/CustomToggle";

// import Spinner from 'react-bootstrap/Spinner'

// import { loadAllCustomerVendors } from '../redux/action/VendorAction';
// import { getCustomerContracts } from '../../ownerModule/redux/action/OwnerContractsActions';
import { getInvoices } from "../../customerModule/redux/action/paymentsAction";
import { Dropdown } from "react-bootstrap";
import formatAmount from "../../../lib/Functions/formatAmount";
import ReceiptComponent from "./ReceiptComponent";
import { getUserProperties } from "../../../lib/Constants/UserTypes";
import RequeryComponent from "./RequeryComponent";
import { formatDate3 } from "../../../lib/Functions/formatDate";
import Pagination2 from "../../../lib/Components/Pagination2";
import PaymentsFilter from "./PaymentsFilter";
import PaymentStatus from "./paymentUtil";
// import PaymentsFilter from '.InvoiceFilter';

const PaymentsPage = () => {
  const [selectedtPaymentItem, setSelectedPaymentItem] = useState("");
  const [showRequeryModal, setShowRequery] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setPageSize] = useState(10);
  const { type, userLevel } = getUserProperties();
  const dispatch = useDispatch();
  const { invoice } = useSelector((s) => s.oPaymentsReducer);
  const {
    loading: invoiceLoading,
    success: invoiceLoaded,
    data: invoiceData,
  } = invoice;

  const paymentList = useMemo(() => {
    if (invoiceData && invoiceData.data) {
      return invoiceData.data || [];
    }
  }, [invoiceData]);
  useEffect(() => {
    dispatch(getInvoices({ page, size }));
  }, []);

  const handleSelectedPayment = (paymentItem) => {
    setShowReceiptModal(!showReceiptModal);
    if (paymentItem) {
      setSelectedPaymentItem(paymentItem);
      return;
    }
    setSelectedPaymentItem(null);
  };

  const handleRequery = (paymentItem) => {
    setShowRequery(!showRequeryModal);
    if (paymentItem) {
      setSelectedPaymentItem(paymentItem);
      return;
    }
    dispatch({ type: "ACCOUNT_RESET" });
    setSelectedPaymentItem(null);
  };

  const handleInitateContract = (e, item) => {
    e.preventDefault();
    localStorage.setItem("SAVE_CONTRACT_INFO", item.invoice.amount);
    window.location = "/vendor-admin/contracts";
  };

  const handlePaymentFetch = (page, size) => {
    setPage(page);
    dispatch(getInvoices({ page, size }));
  };

  const handleSizeUpdate = (e) => {
    setPageSize(e);
  };

  const handleSearch = (filter) => {
    dispatch(getInvoices({ page, size, ...filter }));
  };

  return (
    <div className="container-2">
      <div className="payment-box row mr-0 ml-0">
        {invoiceLoading && (
          <div className="col-3 pl-0">
            <Skeleton avatar={false} paragraph={{ rows: 3 }} />
          </div>
        )}
        {!invoiceLoading && (
          <div className="col-3 pl-0">
            <div className="box-upsection">
              <div>
                <h2 className="payments-header">Payments</h2>
                <p className="payments-sub">
                  Make payments to your vendors directly on the platform and
                  monitor the status of your payments
                </p>
              </div>
              {/* <span className='payment-label'>Last updated 8, May 2023</span> */}
            </div>
          </div>
        )}
        {invoiceLoading && (
          <div className="col-3 pl-0">
            <Skeleton avatar={false} paragraph={{ rows: 3 }} />
          </div>
        )}
        {!invoiceLoading && (
          <div className="col-3 pt-0 pb-0">
            <div
              style={{
                border: "1px solid #C8D2DF",
              }}
              className="account-box"
            >
              <div>
                <span className="payment-stat-dot"></span>{" "}
                <span className="payment-stat-text">
                  Total Successful Payments
                </span>
              </div>
              {paymentList && (
                <h2 className="payment-stat-fig">
                  {paymentList.totalSuccessAmount
                    ? formatAmount(paymentList.totalSuccessAmount * 100)
                    : ""}
                </h2>
              )}
            </div>
          </div>
        )}
        {invoiceLoading && (
          <div className="col-3 pl-0">
            <Skeleton avatar={false} paragraph={{ rows: 3 }} />
          </div>
        )}
        {!invoiceLoading && (
          <div className="col-3 pt-0 pb-0">
            <div
              style={{
                border: "1px solid #C8D2DF",
              }}
              className="account-box"
            >
              <div>
                <span className="payment-stat-dot failed"></span>{" "}
                <span className="payment-stat-text">Total Failed Payments</span>
              </div>
              {paymentList && (
                <h2 className="payment-stat-fig">
                  {paymentList.totalFailedAmount
                    ? formatAmount(paymentList.totalFailedAmount * 100)
                    : ""}
                </h2>
              )}
            </div>
          </div>
        )}
        {invoiceLoading && (
          <div className="col-3 pl-0">
            <Skeleton avatar={false} paragraph={{ rows: 3 }} />
          </div>
        )}
        {!invoiceLoading && (
          <div className="col-3 pt-0 pb-0">
            <div
              style={{
                border: "1px solid #C8D2DF",
              }}
              className="account-box"
            >
              <div>
                <span className="payment-stat-dot pend"></span>{" "}
                <span className="payment-stat-text">
                  Total Pending Payments
                </span>
              </div>
              {paymentList && (
                <h2 className="payment-stat-fig">
                  {paymentList.totalPendingAmount
                    ? formatAmount(paymentList.totalPendingAmount * 100)
                    : ""}
                </h2>
              )}
            </div>
          </div>
        )}
      </div>
      <PaymentsFilter onUpdateSearchTerms={handleSearch} />
      {/* <div className='d-flex justify-content-between align-items-center mt-5 mb-5'>
            <h2 className='payments-header'>All Payments</h2>
        </div> */}
      {invoiceLoading && <Skeleton avatar={false} paragraph={{ rows: 8 }} />}
      {!invoiceLoading && invoiceLoaded && paymentList?.content ? (
        <div className="table-responsive payment-table-container">
          <table className="table table-borderless">
            <thead className="chart-table-head">
              <tr>
                {(type === "CUSTOMER_USER" || type === "OWNER_USER") && (
                  <th scope="col">Vendor name</th>
                )}
                {(type === "VENDOR_USER" || type === "OWNER_USER") && (
                  <th scope="col">Customer name</th>
                )}
                <th scope="col">Amount</th>
                <th scope="col">Payment Type</th>
                <th scope="col">Payment ref</th>
                <th scope="col">Payment Date</th>
                <th scope="col">Payment Status</th>
                <th scope="col">Bank Name</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {paymentList?.content?.map((item, index) => {
                return (
                  <tr key={`in-${index}`}>
                    {(type === "CUSTOMER_USER" || type === "OWNER_USER") && (
                      <td>{item.vendorName ? item.vendorName : ""}</td>
                    )}
                    {(type === "VENDOR_USER" || type === "OWNER_USER") && (
                      <td>{item.customerName ? item.customerName : ""}</td>
                    )}
                    <td>
                      {item.invoice.amount
                        ? formatAmount(item.invoice.amount * 100)
                        : ""}
                    </td>
                    <td>{item.paymentType ? item.paymentType : ""}</td>
                    <td>
                      {item.invoice.paymentReference
                        ? item.invoice.paymentReference
                        : ""}
                    </td>
                    <td>
                      {item.invoice.transactionDate
                        ? formatDate3(item.invoice.transactionDate)
                        : "N/A"}
                    </td>
                    <td>
                      <PaymentStatus status={item.invoice.status} />
                    </td>
                    <td>{item.bankName}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                          <MenuIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleSelectedPayment(item)}
                          >
                            <>
                              <ViewIcon /> View
                            </>
                          </Dropdown.Item>
                          {/* <Dropdown.Divider />
                                            <Dropdown.Item href="#/action-3"><><DownloadIcon /> Download</></Dropdown.Item> */}
                          {(type === "CUSTOMER_USER" ||
                            type === "OWNER_USER") &&
                            (item.invoice.status === "FAILED" ||
                              item.invoice.status === "PENDING") && (
                              <>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  onClick={() => handleRequery(item)}
                                >
                                  <>
                                    <SpinnerGap /> Requery
                                  </>
                                </Dropdown.Item>
                              </>
                            )}
                          {type === "VENDOR_USER" && userLevel === "ADMIN" && (
                            <>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                href="/vendor-admin/contracts/main"
                                onClick={(e) => handleInitateContract(e, item)}
                              >
                                <>
                                  <InitiateContractIcon /> Initiate Contract
                                </>
                              </Dropdown.Item>
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        !invoiceLoading && (
          <div className="empty-table-container">
            <EmptyTableIcon />
            <h3 className="empty-table-caption">No Payments Made</h3>
            <span className="text-center empty-table-text">
              You have not made any payment yet. <br /> Make transactions to get
              started!
            </span>
          </div>
        )
      )}
      {paymentList?.content && (
        <div className="mt-5 mb-2">
          <Pagination2
            total={invoiceData?.count}
            current={page}
            pageSize={size}
            onChange={handlePaymentFetch}
            onSizeChange={handleSizeUpdate}
          />
        </div>
      )}
      {showRequeryModal && (
        <RequeryComponent
          toggleModal={() => handleRequery(null)}
          showModal={showRequeryModal}
          transactionDetail={{
            ...selectedtPaymentItem,
            ...selectedtPaymentItem.invoice,
            ...selectedtPaymentItem.data,
          }}
        />
      )}
      {showReceiptModal && (
        <ReceiptComponent
          paymentDetail={selectedtPaymentItem}
          toggleModal={handleSelectedPayment}
          showReceipt={showReceiptModal}
        />
      )}
    </div>
  );
};
export default PaymentsPage;
