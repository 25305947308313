import React, {useState, useEffect} from "react";
import "../../../../assets/Styles/Dashboard.css";
import {
  ExportSVG,
  CreateSVG,
  SchemeSvg,
  SchemeIconSvg,
} from "../../../../assets/Svgs/Svgs";
import {CSVLink} from "react-csv";
import Pagination from "../../../../lib/Components/Pagination";
import formatAmount from "../../../../lib/Functions/formatAmount";
import ActionModal from "../../../../lib/Components/ActionModal";
import CardDetails from "./CardDetails";
import {getUserProperties, USER} from "../../../../lib/Constants/UserTypes";
import CreateScheme from "./CreateScheme";
import {useSelector, useDispatch} from "react-redux";
import {
  fetchAllCustomerContracts,
  createNewScheme,
} from "../../../../actions/CustomerActions/CardMgtActions/CardAction";
import {history} from "../../../../helpers/history";
import {Link} from "react-router-dom";

const SchemeList = (props) => {
  const {
    onHideStatusBar,
    isStatusBarVisible,
    customerCards,
    cardQuantity,
    customerNumber,
    pageSize,
  } = props;
  const {activeCards, inactiveCards} = cardQuantity;
  const [pageNo, setPageNo] = useState(1);
  const [showNewSchemeModal, setShowNewSchemeModal] = useState(false);
  const [showActionModal, setshowActionModal] = useState(false);
  const [showCardDetails, setshowCardDetails] = useState(false);
  const [selectedCard, setselectedCard] = useState({});// eslint-disable-line no-unused-vars
  const [isCreatingScheme, setIsCreatingScheme] = useState(false);
  const [showCardList, setShowCardList] = useState(false);// eslint-disable-line no-unused-vars
  const [isErrorEncountered, setIsErrorEncountered] = useState(false);

  const start = (pageNo - 1) * pageSize;
  const end = start + pageSize;
  const mySchemes = customerCards.allSchemesByCus;
  const customerContracts = useSelector(
    (state) => state.customerCards.customerContracts
  );
  const schemeList =
    mySchemes && mySchemes.slice(start, end).map((value, index) => {
      return (
        <Link
          to="/customer-admin/cards/main"
          key={index}
          onClick={() => {
            onHideStatusBar(true);
            setShowCardList(true);
            history.push("/customer-admin/scheme/main");
            localStorage.setItem("selectedScheme", JSON.stringify(value));
          }}
        >
          <div className="col-lg-6 d-inline-block">
            <div className="scheme-wrapper left">
              <span className="scheme-icon">
                <SchemeIconSvg />
              </span>
              <div className="scheme-details">
                <span className="scheme-name">Scheme Name: {value.title}</span>
                <span className="scheme-code">
                  Customer Code: {customerNumber}
                </span>
                <div className="scheme-footer row">
                  <div className="contract-no col-lg-6 pl-0">
                    Cards: {activeCards + inactiveCards}
                  </div>
                  <div className="scheme-limit col-lg-6 pl-0">
                    Limit: ₦ {formatAmount(value.limit * 100)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    });

  const [state, setState] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    const customerData = getUserProperties();
    setState({
      cus_id: customerData.customer,
      customerName: customerData.name,
      customerId: customerData.customerNumber,
      page: 1,
      size: 20,
    });
  }, []);

  useEffect(() => {
    if (state.cus_id) {
      dispatch(fetchAllCustomerContracts(state.cus_id, 1, 200));
    }
  }, [state]);

  return (
    <>
      {showCardDetails && (
        <CardDetails
          {...props}
          backFunction={() => {
            setshowCardDetails(false);
            onHideStatusBar(false);
          }}
          selectedCard={selectedCard}
        />
      )}

      {showNewSchemeModal && (
        <CreateScheme
          onHide={() => {
            setShowNewSchemeModal(false);
          }}
          onShowActionModal={(status) => {
            setshowActionModal(status);
          }}
          isCreatingScheme={isCreatingScheme}
          isErrorEncountered={isErrorEncountered}
          customerContracts={customerContracts}
          onErrorHandler={(status) => setIsErrorEncountered(status)}
          onCreateSchemeHandler={(formObj) => {
            const formData = {
              title: formObj.title,
              cycle: formObj.cycle,
              limit: parseInt(formObj.limit, 10),
              contractId: formObj.selectedContractId,
              contractLimit: formObj.limit,
              customerId: state.customerId,
              customerName: state.customerName,
            };
            setIsCreatingScheme(true);
            dispatch(
              createNewScheme(
                formData,
                (status) => {
                  setIsCreatingScheme(status);
                },
                (isCreated, error) => {
                  if (isCreated) {
                    setshowActionModal(true);
                  } else {
                    setIsErrorEncountered(error?.description);
                  }
                }
              )
            );
          }}
        />
      )}

      {showActionModal ? (
        <ActionModal
          isCompleted={true}
          actionTitle={"Assigning card..."}
          isShow={showActionModal}
          actionText=""
          titleStyle={{color: "#000000"}}
          updateCompletedStatus={(status) => {}}
          isCompletedTitle="Thank you! You are all set!"
          isCompletedText="The scheme has been successfully created"
          onShowActionModal={(status) => setshowActionModal(status)}
        >
          <>
            <div className="row modal-action-buttons">
              <div className="col-6">
                <button
                  className="back"
                  onClick={() => setshowActionModal(false)}
                >
                  Back
                </button>
              </div>
              <div className="col-6">
                <button
                  className="create"
                  onClick={() => {
                    setShowNewSchemeModal(true);
                    setshowActionModal(false);
                  }}
                >
                  Create new scheme
                </button>
              </div>
            </div>
            <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
          </>
        </ActionModal>
      ) : null}

      {isStatusBarVisible && (
        <div className="disbursed-cards-container row">
          <div
            className="menu-link"
            style={{marginBottom: 0, paddingBottom: 4}}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid",
              }}
            >
              Schemes
            </span>
          </div>
          <div className="action-buttons" style={{bottom: "inherit"}}>
            {mySchemes.length > 0 && (
              <div className="export-btn d-inline-block">
                <CSVLink
                  data={mySchemes}
                  filename={"RFID Cards.csv"}
                  className=""
                  target="_blank"
                >
                  <span className="text-white">Export CSV</span> <ExportSVG />
                </CSVLink>
              </div>
            )}
            {getUserProperties().userLevel !== USER &&
              customerCards.fetched &&
              schemeList.length < 1 && (
                <button
                  className="add-btn"
                  onClick={() => {
                    setShowNewSchemeModal(true);
                  }}
                >
                  <i>
                    <CreateSVG />
                  </i>
                  Create new scheme
                </button>
              )}
          </div>
          {/* schemelist */}
          {schemeList.length > 0 ? (
            <div className="scheme-list-container">{schemeList}</div>
          ) : (
            <div className="no-scheme-list-container">
              <p className="no-scheme-title">
                You do not have any registered scheme
              </p>
              <p className="create-scheme-text">
                Start creating schemes to get started
              </p>
              <div className="text-center">
                <SchemeSvg />
                <p className="no-scheme-text">
                  Sorry, you have not registered any scheme.
                </p>
                {getUserProperties().userLevel !== USER &&
                  customerCards.fetched === true && (
                    <button
                      className="add-btn"
                      onClick={() => {
                        setShowNewSchemeModal(true);
                      }}
                    >
                      <i>
                        <CreateSVG />
                      </i>
                      Create new scheme
                    </button>
                  )}
              </div>
            </div>
          )}

          <Pagination
            count={mySchemes.length}
            pageSize={pageSize}
            fetchMethod={(pageNo) => {
              setPageNo(pageNo);
              // onFetchRfidTags(usedPageNo, pageNo)
            }}
          />
        </div>
      )}
    </>
  );
};

export default SchemeList;
