import { ALL_VENDOR_PRODUCTS, ALL_VENDOR_PRODUCTS_LOADING } from '../../actions/VendorActions/ProductActions/ProductConstants';

const initialState = {
  allVendorProduct: [],
  vendorProductsLoading: 0,
  selectedProduct: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_VENDOR_PRODUCTS:
      return Object.assign({}, state, {
        allVendorProduct: action.products,
      });
    case ALL_VENDOR_PRODUCTS_LOADING:
      return Object.assign({}, state, {
        vendorProductsLoading: action.status,
      });
    case 'FETCH_PRODUCT_FULFILLED':
      return Object.assign({}, state, {
        selectedProduct: action.payload,
      });
    default:
      return state;
  }
};
