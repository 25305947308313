import React, {useEffect} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../assets/Styles/Dashboard.css";
import StatusBar from "./StatusBar";
import Analytics from "./Analytics";
import {getAllUsers} from "../redux/action/customerUserActions";
import {
  CUSTOMER_USER,
  getUserProperties,
} from "../../../lib/Constants/UserTypes";
import {
  fetchAllTrailByCustomer,
} from "../../customerModule/redux/action/CustomerTrailActions";

import TransactionReport from "../Reports/Transactions/TransactionList";
import moment from "moment";
import {getTransactionsByCustomerForCDasboard} from "../../vendorModule/redux/actions/CustomerActions";
import {loadAllCustomerVendors} from "../redux/action/VendorAction";
import {
  fetchHighestTransactingUser,
  fetchThreeMonthsTransactionStats,
  fetchTransactionAmountByFrequency,
} from "../../ownerModule/redux/action/OwnerTrailActions";

function Dashboard() {
  const dispatch = useDispatch();
  const props = useSelector((state) => state);
  const startDate = moment(
    new Date(moment().subtract(365, "days").format("LLLL"))
  ).format("YYYY-MM-DD");
  const endDate = moment(
    new Date(moment().subtract(0, "days").format("LLLL"))
  ).format("YYYY-MM-DD");
  const { customer = 0, customerNumber = '' } = getUserProperties() || {};

  useEffect(() => {
    batch(() => {
      dispatch(getAllUsers(CUSTOMER_USER));
      dispatch(fetchAllTrailByCustomer(customer, 1, 10000000));
      dispatch(loadAllCustomerVendors(customer));
      dispatch(
        getTransactionsByCustomerForCDasboard(
          customerNumber,
          1,
          10,
          startDate,
          endDate,
          1,
          {vendorNum: null, /* customerNum: null, */ location: null}
        )
      );
    });
    dispatch(fetchHighestTransactingUser('DRIVER', 'AMOUNT'));
    dispatch(fetchThreeMonthsTransactionStats('DRIVER', 'AMOUNT'));
    dispatch(fetchTransactionAmountByFrequency('DRIVER', '', customerNumber));
  }, []);

  return (
    <div className="dashboard-container">
      <StatusBar {...props} />
      <Analytics {...props} currentDate={endDate} />
      <TransactionReport {...props} title="Last 10 Transactions" />
    </div>
  );
}

export default Dashboard;
