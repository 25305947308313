import React, {/* useEffect, */ useState} from "react";
import { useDispatch } from "react-redux";
import "../Admin.css";
import MenuBar from "../../../lib/Components/MenuBar";
import toSentenceCase from "../../../lib/Functions/toSentenceCase";
import {
  getUser,
  getUserLevel,
  getUserProperties,
} from "../../../lib/Constants/UserTypes";
import Header from "./header-ag";
import {logoutUser} from "../../Auth/Actions/auth_actions";
// const logoImg = require("../../../assets/sfn/sflogo.png");

export default ({ onSearchBtnClick }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({// eslint-disable-line no-unused-vars
    menuShow: false,
    user: getUserProperties(),
  });

  const logout = () => dispatch(logoutUser());

  return (
    <>
      {Object.keys(state.user).length > 0 && (
        <>
        <>
          {/* <nav
            className="navbar navbar-expand-lg navbar-light shadow-sm bg-white no-spaces"
            style={{zIndex: "999"}}
          >
            <a
              className="navbar-brand d-block"
              style={{overflow: "hidden"}}
              href="/"
            >
              <img
                src={logoImg}
                className="mx-md-0 mt-2 float-right d-block d-sm-block d-md-none"
                width="40%"
                alt="Logo"
              />
              <img
                src={logoImg}
                className="mx-md-0 d-none d-lg-block"
                width="40%"
                alt="Logo"
              />
            </a>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav mr-auto" />
              <p
                className="qt-dark-blue"
                style={{marginRight: "2%", marginBottom: 0}}
              >
                Hi,{" "}
                {toSentenceCase(
                  state.user.firstName) +
                    " " +
                    toSentenceCase(state.user.lastName)
                }{" "}
                ({getUser(getUserProperties().type)} {getUserLevel()})
              </p>

              <span className="navbar-text" style={{marginBottom: 0}}> 
                {/* <div className="notif" /> */}
                {/* <div className="person">
                  <div style={{width: 200, top: 40, bottom: "auto"}}>
                    <ul>
                      <li>
                        {" "}
                        <a
                          href={
                            "/" +
                            getUser(state.user.type).toLowerCase() +
                            "-admin/change-password/main"
                          }
                        >
                          Change Password
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a href="/login">Logout</a>
                      </li> */}
                      {/* <li>
                      {" "}
                      <a href="/reset-password">Change Password</a>
                    </li> */}
                    {/* </ul>
                  </div>
                </div>
              </span>
            </div>
          </nav> */}
        </>

        <Header 
            firstName={toSentenceCase(state.user.firstName)}
            userType={getUser(state.user.type)} 
            userLevel={getUserLevel()}
            logout={logout}
            modules={state.user.modules}
        />

        <MenuBar onSearchBtnClick={onSearchBtnClick} />
        </>
      )}
    </>
  );
};
