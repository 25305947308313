/* eslint-disable no-unused-vars */
import axios from "axios";
import { authHeader } from "../../../../helpers/authHeader";
import { vendorService } from "../../../../services/authService";
import { customConfig } from "../../../../actions/action_constants";
const API_URL = customConfig.API_URL;

export function loadAllCustomerVendors() {
  return function(dispatch) {
    axios
      .get(`${API_URL}/vendors/customers?vid=2`, { headers: authHeader() })
      .then(response => {
        // dispatch({
        //   type: GET_VENDORS,
        //   vendors: response.data
        // });
      })
      .catch(error => {});
  };
}

export function loadAllCustomerVendors_id(vid, page, size) {
  const config = {
    headers: {
      "Content-Type": "application/json"
    },
    data: {}
  };
  // 'Access-Control-Allow-Origin': 'http://104.248.149.29:8588',
  // 'content-type': 'application/x-www-form-urlencoded',
  return function(dispatch) {
    dispatch({ type: "FETCH_VENDOR_CUSTOMERS_START" });
    axios
      .get(API_URL + `api/vendors/customers?vid=${vid}&page=${page}&size=${size}`, {
        headers: authHeader()
      })
      .then(res => {
        dispatch({
          type: "FETCH_VENDOR_CUSTOMERS_FULLFILLED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({ type: "FETCH_VENDOR_CUSTOMERS_REJECTED", payload: err });
      });
  };
}

export function getAllVendors(page, size, q = '') {
  return dispatch => {
    dispatch(request());

    vendorService
      .getAllVendors(page, size, q)
      .then(
        payload => dispatch(success(payload)),
        error => dispatch(failure(error.toString() || "Error occurred!"))
      );
  };

  function request() {
    return { type: "FETCH_All_VENDORS_START" };
  }
  function success(payload) {
    return { type: "FETCH_All_VENDORS_FULLFILLED", payload };
  }
  function failure(error) {
    return { type: "FETCH_All_VENDORS_REJECTED", error };
  }
}

export function fetchAllVendors() {
  // 'Access-Control-Allow-Origin': 'http://104.248.149.29:8588',
  // 'content-type': 'application/x-www-form-urlencoded',
  return function(dispatch) {
    dispatch({ type: "FETCH_All_VENDORS_START" });
    axios
      .get(API_URL + "api/vendors/all?page=1&size=10000000", {
        headers: authHeader()
      })
      .then(res => {
        dispatch({ type: "FETCH_All_VENDORS_FULLFILLED", payload: res });
      })
      .catch(err => {
        dispatch({ type: "FETCH_All_VENDORS_REJECTED", payload: err });
      });
  };
}

export function updateRequestLocationModal(data) {
  return {
    type: "UPDATE_LOCATION_REQUEST_PARAMS",
    response: { status: data, message: "" }
  };
}

export function fetchAllTerminals(page, size) {
  return function(dispatch) {
    axios
      .get(API_URL + `api/terminals/all?page=${page}&size=${size}`, {
        headers: authHeader()
      })
      .then(res => {
        dispatch({ type: "FETCH_All_TERMINALS_FULLFILLED", payload: res.data });
      })
      .catch(err => {
        dispatch({ type: "FETCH_All_TERMINALS_REJECTED", payload: err });
      });
  };
}

export function fetchAllTerminalsByVendor(id, page, size) {
  return function(dispatch) {
    axios
      .get(
        API_URL + `api/terminals/vendor?id=${id}&page=${page}&size=${size}`,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: "FETCH_All_TERMINALS_VENDOR_ID_FULLFILLED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: "FETCH_All_TERMINALS__VENDOR_ID_REJECTED",
          payload: err
        });
      });
  };
}

export function fetchAllTerminalsByLocation(id) {
  // 'Access-Control-Allow-Origin': 'http://104.248.149.29:8588',
  // 'content-type': 'application/x-www-form-urlencoded',
  return function(dispatch) {
    axios
      .get(
        API_URL + "api/terminals/locations?id=" + id + "&page=1&size=10000000",
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({ type: "FETCH_All_TERMINALS_FULLFILLED", payload: res.data });
      })
      .catch(err => {
        dispatch({ type: "FETCH_All_TERMINALS_REJECTED", payload: err });
      });
  };
}

export function fetchAllLocations() {
  // 'Access-Control-Allow-Origin': 'http://104.248.149.29:8588',
  // 'content-type': 'application/x-www-form-urlencoded',
  return function(dispatch) {
    dispatch({ type: "FETCH_All_LOCATIONS_START" });
    axios
      .get(API_URL + "api/locations/all?page=1&size=10000000", {
        headers: authHeader()
      })
      .then(res => {
        dispatch({ type: "FETCH_All_LOCATIONS_FULLFILLED", payload: res.data });
      })
      .catch(err => {
        dispatch({ type: "FETCH_All_LOCATIONS_REJECTED", payload: err });
      });
  };
}

export function fetchAllPOSUsers() {
  // 'Access-Control-Allow-Origin': 'http://104.248.149.29:8588',
  // 'content-type': 'application/x-www-form-urlencoded',
  return function(dispatch) {
    dispatch({ type: "FETCH_All_TERMINALUSERS_START" });
    axios
      .get(API_URL + "api/users/terminalusers?page=1&size=10000000", {
        headers: authHeader()
      })
      .then(res => {
        dispatch({
          type: "FETCH_All_TERMINALUSERS_FULLFILLED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({ type: "FETCH_All_TERMINALUSERS_REJECTED", payload: err });
      });
  };
}

export function toggleVendorStatus(vid) {
  return function(dispatch) {
    dispatch({ type: "TOGGLE_VENDOR_STATUS_START" });
    axios
      .put(
        API_URL + "api/vendors/toggle?id=" + vid,
        {},
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: "TOGGLE_VENDOR_STATUS_FULLFILLED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({ type: "TOGGLE_VENDOR_STATUS_REJECTED", payload: err });
      });
  };
}

export function fetchAllLocationsByVendor(ven_id) {
  const config = {
    headers: {
      "Content-Type": "application/json"
    },
    data: {}
  };
  return function(dispatch) {
    axios
      .get(
        API_URL +
          "api/locations/vendors?id=" +
          ven_id +
          "&page=1&size=10000000",
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: "FETCH_LOCATIONS_BYVENDOR_FULLFILLED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({ type: "FETCH_LOCATIONS_BYVENDOR_REJECTED", payload: err });
      });
  };
}

export function fetchOneVendor(id) {
  return function(dispatch) {
    dispatch({ type: "FETCH_ONE_VENDOR_START" });
    axios
      .get(API_URL + "api/vendors?id=" + id, { headers: authHeader() })
      .then(res => {
        dispatch({ type: "FETCH_ONE_VENDOR_FULLFILLED", payload: res.data });
      })
      .catch(err => {
        dispatch({ type: "FETCH_ONE_VENDOR_REJECTED", payload: err.data });
      });
  };
}

export function fetchOneVendorCustomerContract(vid, cid) {
  return function(dispatch) {
    dispatch({ type: "FETCH_ONE_VENDOR_START" });
    axios
      .get(API_URL + `api/contracts/vcc?cid=${cid}&vid=${vid}`, {
        headers: authHeader()
      })
      .then(res => {
        dispatch({
          type: "FETCH_ONE_VENDOR_CONTRACT_FULLFILLED",
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: "FETCH_ONE_VENDOR_CONTRACT_REJECTED",
          payload: err.data
        });
      });
  };
}

export function updateCreateVendor(status) {
  return {
    type: "UPDATE_CREATE_VENDOR",
    response: { status, message: "" }
  };
}

export function submitVendorForm(data) {
  return function(dispatch) {
    dispatch({type:"CREATE_VENDOR_START"});
    axios
      .post(API_URL + "api/vendors", data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({
            type: "OCREATE_VENDOR_REQUEST",
            response: {
              data: res.data.data,
              status: 1,
              message: res.data.status.description
            }
          });
        } else {
          dispatch({
            type: "OCREATE_VENDOR_FAILED",
            response: { status: 2, message: res.data.status.description }
          });
        }
      })
      .catch(err => {
        dispatch({
          type: "OCREATE_VENDOR_FAILED",
          response: {
            status: 2,
            message:
              err.response.data.status.description ||
              err.response.data.status.errors ||
              err.response.data.status.message ||
              "An error occured."
          }
        });
      });
  };
}

export function getAllProductsByVendor(ven_id) {
  // 'Access-Control-Allow-Origin': 'http://104.248.149.29:8588',
  // 'content-type': 'application/x-www-form-urlencoded',
  return function(dispatch) {
    axios
      .get(
        API_URL + "api/products/vendors?id=" + ven_id + "&page=1&size=10000000",
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({ type: "FETCH_PRODUCTS_BYVENDOR_FULLFILLED", payload: res });
      })
      .catch(err => {
        dispatch({ type: "FETCH_PRODUCTS_BYVENDOR_REJECTED", payload: err });
      });
  };
  // {
  // type: "FETCH_USER_FULLFILLED",
  // payload: {
  //     name: "Will",
  //     age: 34
  // }
}

export function submitProductForm(id, data) {
  return function(dispatch) {
    dispatch({type:'START_CREATE_PRODUCT_REQUEST'})
    axios
      .post(API_URL + "api/products/vendors?id=" + id, data, {
        headers: authHeader()
      })
      .then(res => {
        // dispatch({ type: 'ADD_PRODUCT_TOVENDOR_FULLFILLED', payload: res });
        if (res.data.status.code !== 0) {
          dispatch({
            type: "CREATE_PRODUCT_REQUEST",
            response: { status: 2, message: res.data.status.description }
          });
        } else {
          dispatch({
            type: "CREATE_PRODUCT_REQUEST",
            response: { status: 1, message: res.data.status.description }
          });
        }
        // window.location.reload();
      })
      .catch(err => {
        dispatch({ type: "CREATE_PRODUCT_REQUEST_FAILED",response:{status:2,message:err.response.data.status.description} });
      });
  };
}

export function submitAddProductForm(pid,cid,rate) {
  return function(dispatch) {
    dispatch({type:'START_ADD_PRODUCT_TO_VENDOR_REQUEST'})
    axios
      .put(
        `${API_URL}api/contracts/addproduct?pid=${pid}&cid=${cid}&rate=${rate}`, '',
        { headers: authHeader() })
      .then(res => {
          dispatch({
            type: "ADD_PRODUCT_TO_VENDOR_REQUEST",
            response: { status: 1, message: res.data.status.description }
          });
        
      })
      .catch(err => {
        dispatch({
          type: "ADD_PRODUCT_TO_VENDOR_REQUEST_FAILED",
          response: {
            status: 2,
            message:err && err.response && err.response.data &&err.response.data.status && err.response.data.status.description
          }
        });
        // dispatch({ type: 'ADD_PRODUCT_TOVENDOR_REJECTED', payload: err });
      });
  };
}

export function submitTerminalForm(id, data) {
  return function(dispatch) {
    dispatch({type:'START_CREATE_TERMINAL_REQUEST'})
    axios
      .post(API_URL + "api/terminals/create?vid=" + id, data, {
        headers: authHeader()
      })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({
            type: "CREATE_TERMINAL_REQUEST",
            response: { status: 1, message: res.data.status.description }
          });
        } else {
          dispatch({
            type: "CREATE_TERMINAL_REQUEST",
            response: {
              status: 2,
              message: res.data.status.description || "An error occured"
            }
          });
        }
      })
      .catch(err => {
        const error = err.response.data;
        dispatch({
          type: "CREATE_TERMINAL_REQUEST",
          response: {
            status: 2,
            message: error
              ? error.status.description
                ? error.status.description
                : error.status.error
              : "Created Successfully."
          }
        });
      });
  };
}
export function singleLocationCreateForm(id, data) {
  return function(dispatch) {
    axios
      .post(API_URL + "api/locations?vid=" + id, data, {
        headers: authHeader()
      })
      .then(res => {
        if (res.data.status.code !== 0) {
          dispatch({
            type: "CREATE_LOCATION_REQUEST",
            response: { status: 2, message: res.data.status.description }
          });
        } else {
          dispatch({
            type: "CREATE_LOCATION_REQUEST",
            response: { status: 1, message: res.data.status.description }
          });
        }
      })
      .catch(err => {
        const error = err.response.data;
        
        dispatch({
          type: "CREATE_TERMINAL_REQUEST",
          response: {
            status: 2,
            message: error
              ? error.status.description
                ? error.status.message || error.status.description
                : error.status.error
              : "An error occured"
          }
        });
      });
  };
}

export function singlePOSUserCreateForm(data) {
  return function(dispatch) {
    dispatch({type:'START_CREATE_POS_USERS_REQUEST'})
    axios
      .post(API_URL + "api/users/registerterminaluser", data, {
        headers: authHeader()
      })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({
            type: "CREATE_POS_USERS_REQUEST",
            response: { status: 1, message: res.data.status.description }
          });
        } else {
          dispatch({
            type: "CREATE_POS_USERS_REQUEST",
            response: { status: 2, message: res.data.status.description }
          });
        }
      })
      .catch(err => {
         const error = err.response.data;
        dispatch({
          type: "CREATE_POS_USERS_REQUEST",
          response: {
            status: 2,
            message: err
              ? err.response.data.status.message
                ? err.response.data.status.message ||
                  err.response.data.status.description
                : err.response.data.status.error
              : "An error occured"
          }
        });
      });
  };
}

export function submitLocationForm(id, data) {
  return function(dispatch) {
    dispatch({ type: "ADD_PRODUCT_TOVENDOR_START" });
    axios
      .post(API_URL + "api/terminals/locations?id=" + id, data, {
        headers: authHeader()
      })
      .then(res => {
        dispatch({ type: "ADD_PRODUCT_TOVENDOR_FULLFILLED", payload: res });
        window.location.reload();
      })
      .catch(err => {
        dispatch({ type: "ADD_PRODUCT_TOVENDOR_REJECTED", payload: err });
      });
  };
}

export function resetVendorCreation() {
  return (dispatch) => {
    dispatch({type: 'RESET_ADD_VENDOR_USER'})
  }
}

export function resetVendorUpdate() {
  return dispatch => {
    dispatch({type: 'RESET_VENDOR_UPDATE'});
  }
}
