import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import "../../../../src/assets/Styles/Dashboard.css";
import {
  getCustomerContracts
} from "../../ownerModule/redux/action/OwnerContractsActions";
import {
  loadAllCustomerVendors
} from "../redux/action/OwnerVendorActions";
import ContractsStatusBar from "./StatusBar";
import VendorList from "./VendorList";

function Vendors () {
  const dispatch = useDispatch();
  const props = useSelector(state => state)
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);

  useEffect(() => {
    batch(() => {
      dispatch(loadAllCustomerVendors(
        props.authentication.user.customerNumber?.replace('CUS', '') ??
        props.authentication.user.number?.replace('USER', '')
      ));
      dispatch(getCustomerContracts(props.authentication.user.customer, 1, 10));
    });
  }, []);

  return (
    <div className="dashboard-container">
      {isStatusBarVisible && <ContractsStatusBar {...props} />}

      <VendorList
        {...props}
        onHideStatusBar={status => {
          setIsStatusBarVisible(!status);
        }}
        backFunction = {() => {
          setIsStatusBarVisible(true)
        }}
      />
    </div>
  );
}

export default Vendors;
