import { combineReducers } from 'redux';
import customerUser from '../modules/ownerModule/redux/reducer/customerUserReducer'; 
import customerVoucher from './customerVoucherReducer';
import customerTrail from './customerTrailReducer';
import customerSettle from './customerSettleReducer';
import customerTransact from './customerTransactReducer';
import customerVendor from './CustomerReducers/vendorReducer';
import customerCards from './CustomerReducers/customerCardReducer';
import vendorSettlementReports from './vendorSettlementReportsReducer';
import vendorTrail from './vendorTrailReducer';
import vendorUsers from './vendorUserReducer';
import ownerUsers from './ownerUserReducer';
import userReducer from '../../src/modules/ownerModule/Accounts/Users/userReducer';
import oCustomerReducer from './OwnerReducers/oCustomerReducer';
import oVendorReducer from '../modules/ownerModule/redux/reducer/oVendorReducer';
import vendorTerminal from './VendorReducers/TerminalReducer';
import vendorCustomer from './VendorReducers/CustomerReducer';
import vendorProducts from './VendorReducers/ProductReducer';
import oTrailReducer from '../modules/ownerModule/redux/reducer/oTrailReducer';
import cTrailReducer from '../modules/customerModule/redux/reducer/cTrailReducer';
import oProductReducer from './OwnerReducers/oProductReducer';
import oContractReducer from '../modules/ownerModule/redux/reducer/oContractReducer';
import oCardReducer from '../modules/ownerModule/redux/reducer/oCardReducer';
import voucherReducer from './CustomerReducers/voucherReducer';
import authentication from './authenticationReducer';
import rxPagination from './paginationReducer';
import makerCheckerRequests from "./maker-checker-request-reducer";
import rfidReducer from '../modules/ownerModule/redux/reducer/RfidReducer'
import oPaymentsReducer from './paymentsReducer';

export default combineReducers({
  customerUser,
  customerVoucher,
  customerTrail,
  customerSettle,
  customerTransact,
  customerVendor,
  customerCards,
  vendorSettlementReports,
  vendorTrail,
  vendorUsers,
  vendorTerminal,
  vendorCustomer,
  vendorProducts,
  ownerUsers,
  oCustomerReducer,
  oVendorReducer,
  oProductReducer,
  oContractReducer,
  oTrailReducer,
  cTrailReducer,
  oCardReducer,
  voucherReducer,
  authentication,
  rxPagination,
  makerCheckerRequests,
  rfidReducer,
  userReducer,
  oPaymentsReducer,
});
