import React, {useState, useEffect} from "react";
import SubComponentHeader from "../../../../lib/Components/SubComponentHeader";
import {useDispatch} from "react-redux";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import CustomInput from "../../../../lib/Components/CustomInput";

export default function StationCreateProduct({
  isEditProduct,
  onBackHandler,
  onEditProduct,
  oVendorReducer,
  selectedProduct,
}) {
  const [formData, setFormData] = useState({
    name: "",
    unit: "",
    rate: "",
    other: "",
    description: "",
  });

  // const [isCreatingProduct, setisCreatingProduct] = useState(false);
  const [isEditingProduct, setisEditingProduct] = useState(false);
  const [productValidationError, setproductValidationError] = useState(false);
  const [toast, setToast] = useState({class: "", text: ""});
  const dispatch = useDispatch();

  // const handleInput = (attr, event) => {
  //   setFormData({...formData, [attr]: event.target.value});
  // };

  const isproductValidationError = () => {
    const { stationPrice } = formData;
    if (!stationPrice || Math.sign(stationPrice) === -1) {
      return true;
    } else {
      return false;
    }
  };

  const resetFormData = () => {
    setFormData({
      name: "",
      unit: "",
      rate: "",
      other: "",
      description: "",
    });
  };

  const createProduct = (event) => {
    setproductValidationError(false);
    event.preventDefault();
    if (!!isproductValidationError()) {
      setproductValidationError(true);
      setToast({
        class: "",
        text: "All compulsory fields must be correctly filled to continue!",
      });
      return;
    }
    const { stationPrice } = formData;
      setisEditingProduct(true);
      onEditProduct(selectedProduct.id, stationPrice );
  };

  useEffect(() => {
    const {name, rate, unit, stationRate} = selectedProduct;
    setFormData({
      name,
      rate,
      unit,
      stationPrice : stationRate,
    });
  }, [isEditProduct]);

  useEffect(() => {
    if (!!isEditingProduct && oVendorReducer.editProductRequestStatus !== 1) {
      setToast({
        class: "",
        text:
          oVendorReducer.editProductRequestMessage ||
          "An unknown error has occurred!",
      });
      setproductValidationError(true);
      setisEditingProduct(false);
    }
    if (!!isEditingProduct && oVendorReducer.editProductRequestStatus === 1) {
      setToast({
        class: "text-white bg-success",
        text: oVendorReducer.editProductRequestMessage,
      });
      setproductValidationError(true);
      setisEditingProduct(false);
      
      setReload();
    }
    dispatch({
      type: "EDIT_PRODUCT_REQUEST",
      response: {status: 0, message: ""},
    });
  }, [oVendorReducer.editProductRequestStatus]);

  const setReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  return (
    <>
      {isEditProduct && (
        <>
          <SubComponentHeader
            heading={selectedProduct.stationPrice ? "Edit Product Price" : "Add Product Price"}
            onBackHandler={() => {
              setproductValidationError(false);
              onBackHandler();
            }}
          />

          <div className="row mt-2" style={{width: "100%"}}>
            <div className="offset-1 col-10 p-3">
              <div className="col-10 offset-1">
                <div
                  className="row bg-rfid-detailsm form bg-white ml-1 mr-1 pl-4 pr-4"
                  style={{
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)",
                    borderRadius: 4,
                  }}
                >
                  <div className="row">
                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Product Name"
                      isCompulsory={true}
                      value={formData.name}
                      readOnly={true}
                    />
                   
                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Unit Measurement"
                      isCompulsory={true}
                      value={formData.unit}
                      readOnly={true}
                      />

                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Rate"
                      type="number"
                      isCompulsory={true}
                      value={formData.rate}
                      readOnly={true}
                    />

                    <CustomInput
                      parentClass="col-6 mt-4 pb-3"
                      label="Station Price"
                      type="number"
                      placeHolder='Station Price'
                      isCompulsory={true}
                      value={formData.stationPrice}
                      handleInput={(e) => {
                        setFormData({
                          ...formData,
                          stationPrice: parseFloat(e.target.value),
                        });
                      }}
                    />

                    <div className="col-12">
                      <span style={{color: "#4B4B4B"}}>
                        All fields marked asterisk (
                        <span className="asterisk">* </span>) are compulsory
                      </span>
                    </div>

                    <ToastAlert
                      showToast={!!productValidationError}
                      parentClass={"col-12 mt-4 pb-3"}
                      childClass={toast.class}
                      toastText={toast.text}
                      onCloseHandler={() => {
                        setproductValidationError(false);
                      }}
                    />
                  </div>
                </div>

                <div className="buttons mt-3 text-right">
                  <div
                    style={{
                      backgroundColor: "#00425F",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={ !isEditingProduct && isEditProduct
                        ? createProduct: undefined }
                  >
                    {isEditingProduct ? (
                      <img
                        src="/images/ActivityIndicator.gif" alt="activity-indicator"
                        style={{height: 20, width: 20}}
                      />
                    )  : (
                      "Save Changes"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </>
      )}
    </>
  );
}
