import React, {useState} from "react";
import { Button, Select } from 'antd';
import { SearchOutline } from '@ant-design/icons';
import "../../../../assets/Styles/Dashboard.css";
import {
  CreateSVG,
  ExportSVG,
  FilterSVG,
  CloseSVG,
  ActiveMarkSVG,
  DisabledMark,
  TerminalSvg2,
} from "../../../../assets/Svgs/Svgs";
import {CSVLink} from "react-csv";
import toSentenceCase from "../../../../lib/Functions/toSentenceCase";
import Pagination from "../../../../lib/Components/Pagination";
import CreateTerminal from "./CreateTerminal";
import Modal from "react-bootstrap/Modal";
import { StateList } from "../../../../services/states";
import { getUserLevel } from "../../../../lib/Constants/UserTypes";
import BulkTerminalUpload from "../../../shared/BulkTerminal";
// import BulkTerminalUpload from "./BulkTerminal";

const TerminalList = (props) => {
  const [isCreateTerminal, setIsCreateTerminal] = useState(false);
  const [isEditTerminal, setIsEditTerminal] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState({});
  const [isSelectedTerminal, setIsSelectedTerminal] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [showBulkModal, setShowBulkModal] = useState(false);
  const {oVendorReducer, onFetchTerminals, onHideStatusBar} = props;
 
  const allTerminals = oVendorReducer.allTerminalsByVenId;
  let totalCount = allTerminals.count || 0;
  let terminalData = allTerminals ? allTerminals.data : [];
  const terminalList = terminalData && terminalData.map((value, i) => {
      const {name, terminalId, dealerCode, merchantId, state, status} = value;
      return (
        <tr key={i}>
          <td className="table-description font-weight-bold">{name}</td>
          <td className="table-description font-weight-bold">{terminalId}</td>
          <td className="table-description font-weight-bold">{dealerCode}</td>
          <td className="table-description">{merchantId}</td>
          <td className="table-description">{state}</td>
          <td className={"table-status"}>
            <div
              className={`status-btn ${
                status === "ACTIVE" ? "success" : "error"
              }`}
            >
              {toSentenceCase(status)}
            </div>
          </td>
          <td
            className="table-action"
            onClick={() => {
              setSelectedTerminal(value);
              setIsSelectedTerminal(true);
            }}
          >
            View
          </td>
        </tr>
      );
    });

  const pageSize = 10;

  return (
    <div className="disbursed-cards-container row">
      {!isCreateTerminal && !isEditTerminal && (
        <>
         <div className="col-12 m-0 p-0">
          <div
            className="rfid-menu-link"
            style={{marginBottom: 0, marginRight: 20}}
          >
            <span style={{color: "#000000"}} className="active">
              All Terminals
            </span>
          </div>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div style={{ display: 'inline-block'}} className="mb-2">
          <span className="filter-tags-desc">Filters:</span>
          <Select
            style={{ width: 250 }}
            defaultValue={selectedState}
            className="mr-3"
            placeholder="Choose State"
            allowClear 
            showSearch
            onChange={value => setSelectedState(value)}>
            <Select.Option value="">Select State</Select.Option>
            {StateList.map((value, key) => (
              <Select.Option value={value} key={key}>{value}</Select.Option>
            ))}
          </Select>
          
          <Button  onClick={
             ()=>onFetchTerminals(1, pageSize, selectedState)
           } 
          type="primary" 
          icon={<SearchOutline />}
          style={{
            //  marginRight: 300,
            // float: 'left'
          }}>
            Search
          </Button>
          </div>
           <div style={{ display: 'inline-block'}}>
            <button
              style={{ position: 'relative', border: 'none'}}
              className="create-new-tags-btn mr-2"
              onClick={() => {
                onHideStatusBar(true);
                setIsCreateTerminal(true);
              }}
            >
              <span className="align-middle">
                <CreateSVG /> Create terminal
              </span>
            </button>
            <button onClick={()=>setShowBulkModal(true)} style={{ position:'relative', border: 'none'}}  className="create-new-tags-btn mr-2">
             <CreateSVG /> Create terminal(s)
            </button>
            {
              <button  style={{ position: 'relative', display: 'inline-block', border: 'none', right: 'unset'}}
                  className="terminals-export-btn">
                <CSVLink
                  data={terminalData || []}
                  filename={"Terminals.csv"}
                  className=""
                  target="_blank"
                >
                  <span className="text-white">
                    Export CSV <ExportSVG />
                  </span>
                </CSVLink>
              </button>
            }{" "}
            </div>
            </div>
          </div>

          <table className="table table-hover">
            <thead>
              <tr>
                <td>Name</td>
                <td>Terminal ID</td>
                <td>Dealer Code</td>
                <td>Merchant ID</td>
                <td>State</td>
                <td>
                  Status <FilterSVG />
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {terminalList || (
                <tr>
                  <td colSpan={7} className="text-center">
                    No Terminal available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          
          <Pagination
            count={totalCount}
            pageSize={pageSize}
            fetchMethod={(pageNo, pageSize) => {
              onFetchTerminals(pageNo, pageSize, selectedState);
            }}
          />
        </>
      )}

      <Modal
        show={isSelectedTerminal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="transaction-modal"
      >
        <Modal.Body style={{backgroundColor: "#E8EFF9"}}>
          <div className="pl-4 pr-2 pt-5 pb-5">
            <div style={{display: "flex"}}>
              <TerminalSvg2 />
              <h6
                className="mt-2 ml-2 pb-3 text-left font-weight-bold quickteller-black"
                style={{
                  borderBottomColor: "#5F738C",
                  borderBottomWidth: 0.5,
                  borderBottomStyle: "solid",
                  textAlign: "left",
                  flex: 1,
                }}
              >
                Terminal Information
              </h6>
              <span
                style={{
                  cursor: "pointer",
                  position: "relative",
                  bottom: 5,
                  width: 60,
                  textAlign: "center",
                }}
                onClick={() => {
                  setIsSelectedTerminal(false);
                }}
              >
                <CloseSVG />
              </span>
            </div>
            {
              <>
                <div className="col-12 text-left mb-3 mt-4">
                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="offset-1 col-4 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block user-info-title">
                        Terminal Name
                      </span>
                      <span className="d-block user-info-details mt-2">
                        {selectedTerminal.name}
                      </span>
                    </div>
                    <div className="col-4 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block user-info-title">
                        Terminal ID
                      </span>
                      <span className="d-block user-info-details mt-2">
                        {selectedTerminal.terminalId}
                      </span>
                    </div>
                    <div className="col-3 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block user-info-title">
                        Merchant ID
                      </span>
                      <span className="d-block user-info-details mt-2">
                        {selectedTerminal.merchantId}
                      </span>
                    </div>
                  </div>

                  <div
                    className="row mt-3"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      boxSizing: "border-box",
                    }}
                  >
                    
                    <div className="offset-1 col-4 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block user-info-title">
                        Dealer Code
                      </span>
                      <span className="d-block user-info-details mt-2">
                        {selectedTerminal.dealerCode}
                      </span>
                    </div>
                    <div className="col-4 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block user-info-title">
                        State
                      </span>
                      <span className="d-block user-info-details mt-2">
                        {selectedTerminal.state}
                      </span>
                    </div>
                    <div className="col-2 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block user-info-title">Status</span>
                      <span className="d-block user-info-details mt-2">
                        {selectedTerminal.status === "ACTIVE"
                          ? "Active "
                          : `Disabled `}
                        {selectedTerminal.status === "ACTIVE" ? (
                          <ActiveMarkSVG />
                        ) : (
                          <DisabledMark />
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    className="row mt-3"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      boxSizing: "border-box",
                    }}
                  >
                    
                    <div className="offset-1 col-8 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block user-info-title">
                        Address
                      </span>
                      <span className="d-block user-info-details mt-2">
                        {selectedTerminal.address}
                      </span>
                    </div>
                    
                    <div className="col-3 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block user-info-title">
                        Station Manager
                      </span>
                      <span className="d-block user-info-details mt-2">
                        {selectedTerminal.managerName || "----"}
                      </span>
                    </div>
                  </div>

                  <div className="row pl-4 font-weight-bold mt-4 contract-modal-value">
                    <div className="col-4">
                      <div
                        style={{
                          backgroundColor: "#00425F",
                          color: "#fff",
                          display: "inline-block",
                          borderRadius: 4,
                          padding: "5px 30px",
                          cursor: "pointer",
                          marginTop: 30,
                        }}
                        onClick={() => {
                          onHideStatusBar(true);
                          setIsEditTerminal(true);
                          setIsCreateTerminal(false);
                          setIsSelectedTerminal(false);
                        }}
                      >
                        Edit Terminal
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </Modal.Body>
      </Modal>

      <CreateTerminal
        {...props}
        isCreateTerminal={isCreateTerminal}
        isEditTerminal={isEditTerminal}
        selectedTerminal={selectedTerminal}
        onBackHandler={() => {
          setIsCreateTerminal(false);
          onHideStatusBar(false);
          setIsEditTerminal(false);
          setIsSelectedTerminal(false);
          setSelectedTerminal({});
        }}
      />
      <BulkTerminalUpload showBulkModal={showBulkModal} closeBulkUploadModal={()=>setShowBulkModal(false)} uploadComplete={()=>{
        setShowBulkModal(false)
        onFetchTerminals(1, pageSize, selectedState);
      }} />
    </div>
  );
};

export default TerminalList;
