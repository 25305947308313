import React, {Fragment, useState, useEffect, useMemo} from "react";
import {Modal, ModalBody} from "reactstrap";
import {CloseSVG} from "../../../../assets/Svgs/Svgs";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import formatAmount from "../../../../lib/Functions/formatAmount";
import {useSelector, useDispatch} from "react-redux";
import {getAllUsers} from "../../redux/action/customerUserActions";
import { getUserProperties } from "../../../../lib/Constants/UserTypes";
import moment from 'moment';
import { calculateProductVolume, fetchState } from "../../redux/action/VendorAction";

export default ({
  onHide, onAddCard, selectedScheme, 
  selectedSchemeContracts, isCardAssignError, onTopUpCard,
   hideError, isTopUp, selectedCard, isAssignCard, reAssignCard, cards=[]
}) => {
  const [state, setState] = useState(selectedCard? {...selectedCard, selectedUserId: Number(selectedCard.userId.replace("USER", ""))} : {
    pan: "",
    expiryDate: "",
    accountNumber: "",
    userId: "",
    name: "",
    limit: "",
    selectedUserId: "",
  });
  const [ rawDate, setRawDate ] = useState(selectedCard? selectedCard.expiryDate: '');
  const [assignCardErrorMessage, setAssignCardErrorMessage] = useState("");
  const [customers, setCustomers] = useState([]);

  const [contractsWithPurchasableQty, setContractsWithPurchasableQty] = useState([]);
  const [computeError, setComputeError] = useState('');
  const [stateId, setStateId]= useState('');
  const { id, customer } = getUserProperties();

  const users = useSelector((state) => state.customerUser.users);
  const { fetchingPriceVolume, statePriceVolumes, states  } = useSelector(state => state.oVendorReducer);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(fetchState({ page: 1, pageSize : 40}));
  },[])

  function onHandleInput(field, e) {
    const isOk = (field === 'limit') ? computePurchasableQty(e.target.value) : true;

    if (isOk) {
      setState({...state, [field]: e.target.value});
    }
  }

  const computePurchasableQty = (value) => {
    setComputeError('');

    if (value && Number.isNaN(parseFloat(value))) {
      setComputeError('Only numbers!');
      return false;
    }

    setContractsWithPurchasableQty(
      transformProductContractRates(contractsWithPurchasableQty, value)
    );

    return true;
  }

  const transformProductContractRates = (contracts, limit) => {
    return contracts.map(pcr => [
      ...pcr.map(p => {
        const appliedProductPrice = (p.rate || p.product.rate);
        return {
          ...p, 
          appliedProductPrice,
          purchasableQty: (
            (limit && (appliedProductPrice > 0)) ? (parseFloat(limit) / appliedProductPrice) : 0
          ),
        }
      })
    ]);
  };

  useEffect(() => {
    if (selectedSchemeContracts.length !== 0) {
      const contractsWithPQs = selectedSchemeContracts.map(ssc => {//PCRates = product-contract-rates
        if (ssc.contract.productContractRates?.length === 0) {
          if (ssc.products !== 0) {
            ssc.products.map(p => ssc.contract.productContractRates.push({ product: p }));
          }
        }

        return [...ssc.contract.productContractRates];
      });

      setContractsWithPurchasableQty(transformProductContractRates(contractsWithPQs, state.limit));
    }
  }, [selectedSchemeContracts]);

  useEffect(() => {
    dispatch(getAllUsers(getUserProperties().type));
  }, []);

  const unAssignedDrivers = useMemo(()=>{
    return (users.length && cards.length >0)?
     users.filter(x => x.status === "ACTIVE" && !cards.find(y => y.userId === x.number))
     : [];
  },[users])

  useEffect(() => {
  
    let customerUsers = []
    if(isAssignCard) {
      customerUsers = unAssignedDrivers.length
      ? unAssignedDrivers.map((e) => {// eslint-disable-line
            return (
              <option value={e.id} key={e.id}>
                {e.firstName} {e.lastName}
              </option>
            );
        })
      : [];
      
    } else {
      customerUsers = users.length
      ? users.map((e) => {// eslint-disable-line
          if (e.status === "ACTIVE") {
            return (
              <option value={e.id} key={e.id}>
                {e.firstName} {e.lastName}
              </option>
            );
          }
        })
      : [];
    }
    setCustomers(customerUsers);
    setState({...state, accountNumber: selectedScheme.accountNumber});
  }, [users]);

  const {pan, expiryDate, limit, userId, selectedUserId} = state;

  function isValidateError() {
    let errorMsg = "";
    if (pan.length < 19) {
      errorMsg = "Card pan must not be less than 19 digits";
    } else if (isNaN(pan)) {
      errorMsg = "Card pan must be a number";
    } else if (new Date().getTime() > new Date(rawDate).getTime()) {
      errorMsg = "Date must be in the future";
    } else if (!expiryDate) {
      errorMsg = "Enter an expiry date";
    } else if (limit <= 0) {
      errorMsg =
        "Scheme balance is zero, new card cannot be added" 
    } else if(limit > selectedScheme.unassignedLimit){
      errorMsg = "Limit must be lesser than "+ selectedScheme.unassignedLimit;
    }
    else if (!selectedUserId) {
      errorMsg = "Please select a card holder";
    }
    setAssignCardErrorMessage(errorMsg);
    return errorMsg;
  }

  function isValidateReassign() {
    let errorMsg = "";
    if (pan.length < 19) {
      errorMsg = "Card pan must not be less than 19 digits";
    }  else if (!selectedUserId) {
      errorMsg = "Please select a driver";
    } else if(selectedCard.userId === state.userId){
      errorMsg = "Please reassign to a different driver"
    }
    setAssignCardErrorMessage(errorMsg);
    return errorMsg;
  }

  function isValidateToup() {
    let errorMsg = "";
    if (pan.length < 19) {
      errorMsg = "Card pan must not be less than 19 digits";
    } else if (limit <= 0) {
      errorMsg =
        "Scheme balance is zero, new card cannot be added" 
    } else if(limit > selectedScheme.unassignedLimit){
      errorMsg = "Limit must be lesser than "+ selectedScheme.unassignedLimit;
    } else if (new Date().getTime() > new Date(rawDate).getTime()) {
      errorMsg = "Date must be in the future";
    } else if (!expiryDate) {
      errorMsg = "Enter an expiry date";
    }

    setAssignCardErrorMessage(errorMsg);
    return errorMsg;
  }

  const handleVolumePreview =() =>{
    setAssignCardErrorMessage("")
    if(!state?.limit){
      setAssignCardErrorMessage("Enter a value for limit");
      return;
    }
    if(!stateId){
      setAssignCardErrorMessage("Select a State");
      return;
    }
    let object = {
       amount: state?.limit,
       stateId,
       cid: customer
    }
    dispatch(calculateProductVolume(object));
  }

  const purchasableQtyTable = statePriceVolumes?.data?.map((pcr, i) => (
    <tr key={`contract-${i}`}>
       <td className="p-2 font-weight-bold">{pcr.productName?.toUpperCase()}</td>
            <td className="p-2 font-weight-bold">
              {parseFloat(pcr.productPrice).toFixed(2)}
              {` / ${pcr.unit}`}
            </td>
            <td className="p-2 text-right">
              {parseFloat(pcr.quantity).toFixed(2)}
              {` / ${pcr.unit}`}s
            </td>
    </tr>
  ));

  return (
    <Modal
      isOpen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      className="user-modal"
    >
      <ModalBody>
        <div className="card-management-row ml-3 mr-3">
          <div
            className="mb-4"
            style={{
              textAlign: "left",
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10,
              borderBottom: "0.5px solid #5F738C",
            }}
          >
            <h6 className="mt-5 qt-black font-weight-bold text-left d-inline">
              {isTopUp && "Topup Card"} 
              {isAssignCard && "Reassign Card"}
              {!isTopUp && !isAssignCard &&  "Assign Card"}
            </h6>
            <span
              style={{float: "right", cursor: "pointer"}}
              onClick={() => {
                onHide();
                hideError('');
              }}
            >
              <CloseSVG />
            </span>
          </div>

          {isAssignCard &&
          <Fragment>
          <div className="row">
            <div className="form-group col-6">
              <label>Card Pan</label>
              <input
                type="text"
                value={pan}
                maxLength={19}
                onChange={onHandleInput.bind(this, "pan")}
                className="form-control"
              />
            </div>
            <div className="form-group col-6">
              <label>Select Holder</label>
              <select
                className="form-control my-md-3x"
                value={state.selectedUserId}
                onChange={(event) => {
                  const selectedUser = users.filter((user) => {
                    return user.id === parseInt(event.target.value, 10);
                  })[0];
                  setState({
                    ...state,
                    selectedUserId: event.target.value,
                    name: selectedUser.name,
                    userId: selectedUser.number,
                  });
                }}
                name="scheme"
              >
                <option>Select holder</option>
                {customers}
              </select>
            </div>
          </div>
          </Fragment> }
       
      {!isAssignCard && <Fragment>
          <div className="row">
            <div className="form-group col-6">
              <label>Card Pan</label>
              <input
                type="text"
                value={pan}
                maxLength={19}
                onChange={onHandleInput.bind(this, "pan")}
                className="form-control"
              />
            </div>
            <div className="form-group col-6">
              <label>Card Expiry Date</label>
              <input
                type="date"
                value={rawDate}
                className="form-control"
                onChange={(event) => {
                  setState({
                    ...state,
                    expiryDate: moment(event.target.value).format("MMYY"),
                  });
                  setRawDate(event.target.value);
                }}
              />
            </div>
          </div>      
          <div className="row">
            <div className="form-group col-6">
              <label>Scheme</label>
              <input
                type="text"
                value={selectedScheme.title}
                contentEditable={false}
                className="form-control font-weight-bold"
                onChange={() => {}}
                disabled
              />
            </div>
            {isTopUp &&<div className="form-group col-6">
              <label>{`Available balance on ${isTopUp? "card" :"scheme"}`}</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text font-weight-bold">&#8358;</span>
                </div>
                <input
                  type="text"
                  style={{color: "#000", fontWeight: "bolder", fontSize: 20}}
                  disabled
                  value={formatAmount(
                    selectedScheme.unassignedLimit * 100
                  )}
                  onChange={onHandleInput.bind(this, "availableBalance")}
                  className="form-control mt-0"
                />
              </div>
            </div>}
           {!isTopUp &&<div className="form-group col-6">
              <label>Select Holder</label>
              <select
                className="form-control my-md-3x"
                value={state.selectedUserId}
                onChange={(event) => {
                  const selectedUser = users.filter((user) => {
                    return user.id === parseInt(event.target.value, 10);
                  })[0];
                  setState({
                    ...state,
                    selectedUserId: event.target.value,
                    name: selectedUser.name,
                    userId: selectedUser.number,
                  });
                }}
                name="scheme"
              >
                <option>Select holder</option>
                {customers}
              </select>
            </div>}
          </div>
          <div className="row">
            <div className="form-group col-4">
              <label>
                {isTopUp? "Topup" : "Limit"} 
                {computeError && 
                  <span 
                    className="text-warning ml-4" 
                    style={{
                      fontWeight: 'normal', 
                      fontSize: '12px'
                    }}>
                    {computeError}
                  </span>
                }
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text font-weight-bold">&#8358;</span>
                </div>
                <input
                  type="text"
                  value={limit}
                  className="form-control mt-0"
                  onChange={onHandleInput.bind(this, "limit")}
                />
              </div>
            </div>
            <div className="form-group col-4">
              <label>Select State</label>
              <select
                className="form-control my-md-3x"
                value={stateId}
                onChange={(e)=>setStateId(e.target.value)}
                name="scheme"
              >
                <option>Select State</option>
                {states?.data?.map((item, index)=>{
                                return(<option key={index} value={item.id}>{item.name}</option>)
                              })}
              </select>
              </div>
            {!isTopUp && <div className="form-group col-4">
              <label>{`Available balance on ${isTopUp? "card" :"scheme"}`}</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text font-weight-bold">&#8358;</span>
                </div>
                <input
                  type="text"
                  style={{color: "#000", fontWeight: "bolder", fontSize: 20}}
                  disabled
                  value={formatAmount(
                    selectedScheme.unassignedLimit * 100
                  )}
                  onChange={onHandleInput.bind(this, "availableBalance")}
                  className="form-control mt-0"
                />
              </div>
            </div>}
            <div className="col-8">
              <button disabled={fetchingPriceVolume} onClick={handleVolumePreview}>{fetchingPriceVolume? 'loading...':'Preview prices'}</button>
            <div className="d-block pt-2" style={{color: '#00425F'}}>
                <span className="mr-2 font-weight-bold" style={{fontSize: '13px'}}>
                  Purchases:
                </span>
                <table className="table mb-0">
                  <thead>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Purchasable Quantity</th>
                  </thead>
                  <tbody>{purchasableQtyTable?? <td colSpan={3}><h2>No data to display</h2></td>}</tbody>
                </table>
              </div>
            </div>
          </div>
          </Fragment>}
          {(!!assignCardErrorMessage || !!isCardAssignError) && (
            <div className="row mt-2 mb-4">
              <ToastAlert
                parentClass="col-12"
                toastText={assignCardErrorMessage || isCardAssignError}
                showToast={!!assignCardErrorMessage || !!isCardAssignError}
                onCloseHandler={() => {
                  !!assignCardErrorMessage && setAssignCardErrorMessage("");
                  !!isCardAssignError && hideError("");
                }}
              />
            </div>
          )}
          <div className="row mt-3 mb-4">
            {(!isTopUp && !isAssignCard) && <div className="assign-btn col-12">
              <button
                onClick={() => {
                  if (!isValidateError()) {
                    onAddCard(state);
                  }
                }}
                className="ml-0"
              >
                Assign
              </button>
            </div>}
            {isTopUp && <div className="assign-btn col-12">
              <button
                onClick={() => {
                  if (!isValidateToup()) {
                    onTopUpCard({pan: state.pan, expiryDate: state.expiryDate, limit: state.limit});
                  }
                }}
                className="ml-0"
              >
                Top up card
              </button>
            </div>}
            {isAssignCard && <div className="assign-btn col-12">
              <button
                onClick={()=>{
                  if(!isValidateReassign()){ reAssignCard({ pan: state.pan, name: state.name, userId: state.userId })}
                }}
                className="ml-0"
              >
                Reassign card
              </button>
            </div>}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
