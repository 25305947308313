import React, { useEffect, useState } from "react";
import { Table, Divider, Button, Select } from 'antd';
import Modal from "react-bootstrap/Modal";
import {
  BackSVG,
  ActiveMarkSVG,
  AvatarSVG,
  UserTransactionSVG,
  ProductSvg,
  AccountSvg,
} from "../../../assets/Svgs/Svgs";
import formatAmount from "../../../lib/Functions/formatAmount";
import {formatDate3} from "../../../lib/Functions/formatDate"
import { batch, useDispatch } from "react-redux";
import { fetchWalletBalanceInVendorContract } from "../redux/action/OwnerVendorActions";
import { customerSignVendorsContract, removeCustomerVendorContract } from "../redux/action/VendorAction";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { loadingGIF } from "../../admin/shared/assets";
import { getContract } from "../../ownerModule/redux/action/OwnerContractsActions";

const columns = [
  {
    title: 'Product Name',
    dataIndex: 'product.name',
    key: 'id',
    render: (text, record) => (
      <span>
        <strong>{record.product.name}</strong>
      </span>
    ),
  },
  {
    title: 'Contract Product Rate',
    dataIndex: 'rate',
    key: 'idc',
    render: (text, record) => (
      <span>
        {/* Show default if  Contract Product Rate is not available */}
        <strong>{record?.rate ? record.rate : record.product.rate} / litre </strong>
      </span>
    ),
  },
];

const ContractDetails = (props) => {
  const dispatch = useDispatch();
  const { selectedVendor, authentication, oVendorReducer, customerCards, selectedContract, oContractReducer } = props;
  const [openModal, setOpenModal] = useState(false);
  const [selectedScheme, setSelectedScheme] = useState("")
  const [isRedirect, setIsRedirect] = useState(false);
  const [errMessage, setErrMessage] = useState('');// eslint-disable-line no-unused-vars
  const mySchemes = customerCards.allSchemesByCus || [];
  const {
    id,
    number,
    image,
    description,
    products = [],
    locations = []
  } = selectedVendor || {};
  const { selectedContract: fetchedContract } = oContractReducer;
  const { id : contractId } = selectedContract;

  const contractData = fetchedContract ? fetchedContract : {};
  const sum = contractData ? contractData.bound * 100 : 0;
  const contractBalance = contractData ? (contractData.balance || 0) : 0;

  const walletBalance = oVendorReducer.walletBalanceInContract ? oVendorReducer.walletBalanceInContract.data : {};
  const availableBalance = walletBalance ? walletBalance.availableBalance : 0;

  useEffect(() => {
    batch(() => {
      if (id) {
        // dispatch(fetchOneVendorCustomerContract(id, authentication.user.customer));
        dispatch(getContract(contractId))
        dispatch(fetchWalletBalanceInVendorContract(id))
      }
    });
  }, [id])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{ // reload data if contract got activated.
    if(oVendorReducer?.isContractActivated) {
      dispatch(getContract(contractId))
      dispatch(fetchWalletBalanceInVendorContract(id))
    }
  },[oVendorReducer?.isContractActivated]);

  useState(() => {
    dispatch(removeCustomerVendorContract());
  }, [])

  const handleActivateContract =()=>{
    if(mySchemes.length > 0) {
      setOpenModal(true);
      return;
    } else {
      dispatch(customerSignVendorsContract(contractId))
    }
  }

  useEffect(()=>{
    if(contractData && contractData.contractStatus === "ACTIVE" && mySchemes.length <= 0) {
        setOpenModal(true);
        setIsRedirect(true);
    }
  }, [contractData, mySchemes.length])

  const handleActivatedContractWithScheme =()=> {
    if(!selectedScheme) {
      setErrMessage('Kindly select a Scheme to proceed');
      return;
    }
    dispatch(customerSignVendorsContract(contractId, selectedScheme))
    setOpenModal(false);
  }

  const formatString = (str) => {
    let pr = str.toLowerCase();
    return pr.charAt(0).toUpperCase() + pr.slice(1);
  }

  return (
    <>
     <Modal show={openModal} onHide={()=>{setOpenModal(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>{!isRedirect? 'Select a Scheme to proceed' : 'Create a Scheme' }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isRedirect ? <Fragment>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: 50
          }}>
          <Select 
            defaultValue={selectedScheme}
            className="mr-3"
            placeholder="Select Scheme"
            style={{
              minWidth: 200
            }}
            // showSearch
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={value => setSelectedScheme(value)}>
            <Select.Option value=""></Select.Option>
            {(mySchemes || []).map((v, i) => (
              <Select.Option value={v.accountNumber} key={i}>{v.title}</Select.Option>
            ))}
          </Select>
          </div>
           <Button style={{
            marginTop: 10,
            float: 'right'
          }} type="primary" onClick={handleActivatedContractWithScheme}>
            Continue
          </Button>
          </Fragment>:
            <Link to={'/customer-admin/scheme/main'}> Click to Create Scheme</Link>
          }
          
          </Modal.Body>
      </Modal>
      <div className="underlined-tab" /* style={{marginRight: 30}} */>
        <span className="tab-title">Contract Details</span>
        <div className="action-buttons">
          <button className="export-btn" style={{marginRight: 0}} onClick={() => {
              props.backFunction()
          }}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className="customer-details" style={{ width: "100%" }}>
        <div className="row pl-3" style={{marginRight: "-30px"}}>
        <div className="col-12">
          <>
            <div className="row user-info">
              <div className="row user-info-title">
                <div className="col-6">
                  <div className="row">
                    <div className="col-5 text-center mt-4">
                      {!!image ? (
                        <img
                          alt="Profile"
                          src={image}
                          style={{ width: 100}}
                        />
                      ) : (
                        <AvatarSVG style={{ marginRight: 0 }} />
                      )}
                      <div className="credit-details mt-4">
                        <div
                          className="credit-title mb-2"
                          style={{ position: "relative" }}
                        >
                          AVAILABLE BALANCE
                        </div>
                        <div
                          className="amount"
                          style={{
                            position: "relative",
                            width: 130,
                            margin: "0 auto",
                          }}
                        >
                          { oVendorReducer.fetchingOvc ? <img src={loadingGIF} alt="" /> : <>₦ {formatAmount(contractBalance * 100)}</> }
                        </div>
                        <div
                          className="credit-title mb-2 mt-2"
                          style={{ position: "relative" }}
                        >
                          WALLET BALANCE
                        </div>
                        <div
                          className="amount"
                          style={{
                            position: "relative",
                            width: 130,
                            margin: "0 auto",
                          }}
                        >
                          { oVendorReducer.fetchingWbic ? <img src={loadingGIF} alt="" /> : <>₦ {formatAmount(availableBalance)}</> }
                        </div>
                      </div>
                    </div>
                    <div className="col-7 pt-4">
                      <div style={{float: "left", marginTop: 10}}><AccountSvg height={25} width={25} /></div>
                      <div className="d-inline-block">
                      <div className=" user-info-title">
                        <div>Vendor ID</div>
                      </div>
                      <div className=" user-info-details">
                        <div>{number}</div>
                      </div>
                      </div>
                      <div className="mt-5 customer-stats">
                        <div className="col-10 stats">
                          <div className="row">
                            <div className="col-6">PRODUCTS</div>
                            <div className="col-6">LOCATION</div>
                          </div>
                          <div className="row numbers">
                            <div className="col-6">{products?.length}</div>
                            <div className="col-6">{locations?.length}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="vendor-overview-border"></div>
                  <div className="user-info-title mt-4">
                    <div className="vendor-overview-title mb-2">Vendor Overview</div>
                    <div className="vendor-overview-body">
                      {description || "Not available"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row user-overview" style={{padding: '20px 80px'}}>
              <span className="overview-title" style={{marginBottom: 20}}>Contract Overview</span>
              <div className="inner pr-5 pb-5" style={{ paddingLeft: "200px", borderRadius: 6 }}>
                <div className="mb-3"><UserTransactionSVG /> <span style={{color: "#000", fontSize: 18, fontWeight: 900}}>Product: {fetchedContract ? fetchedContract.name : ""}</span></div>
                {fetchedContract ? <><div className="row user-address-title">
                  <div className="col-3">Contract Limit</div>
                  <div className="col-3">Frequency</div>
                  <div className="col-3">Vendor Signature</div>
                  <div className="col-3">Customer Signature</div>
                </div>
                <div className="row user-address-details mt-2">
                  <div className="col-3">₦ {formatAmount(sum || 0)}</div>
                  <div className="col-3">
                    {(fetchedContract.contractCycleType === "DAYS") && 
                      (fetchedContract.contractCycleTypeDays || "")}
                    {fetchedContract.contractCycleType || "0"}
                  </div>
                  <div className="col-3">{fetchedContract.vendorSignature || "Nil"}</div>
                  <div className="col-3">{fetchedContract.customerSignature || "Nil"}</div>
                </div>
                
                <div className="row user-address-title mt-4">
                  <div className="col-3">Start Date</div>
                  <div className="col-3">Auto-Renew?</div>
                  <div className="col-3">Status</div>
                </div>
                <div className="row user-address-details mt-2">
                  <div className="col-3">{formatDate3(fetchedContract.contractStartDate)}</div> 
                  <div className="col-3">{fetchedContract.autoRenew ? "Yes" : "No"}</div> 
                  <div className="col-3">
                    {fetchedContract.contractStatus === "ACTIVE" ? "Active " :  (
                      (fetchedContract.contractStatus === "EXPIRED" || fetchedContract.contractStatus === "TERMINATED") ?
                       formatString(fetchedContract.contractStatus) :
                      <div onClick={handleActivateContract} style={{cursor: "pointer", color: '#0172CB'}}>
                        {oVendorReducer.isSigningContract ? <>Activating <img
                        alt="Profile"
                        src="/images/ActivityIndicator.gif"
                        style={{ height: 20, width: 20, marginTop: 0 }}
                      /></> : 'Activate Contract'}
                      </div>
                    )}{fetchedContract.contractStatus === "ACTIVE" && (<ActiveMarkSVG />)}
                  </div>
                </div>
                </>: <div className="ml-5">Unavailable</div>}

                <Divider />
                  <div className="mb-3">
                    <span
                      style={{
                        color: "#000",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Product Rates
                    </span>
                  </div>
                  {fetchedContract ? (
                    <>
                      <div className="row user-address-title">
                        <Table
                          style={{ width: "100%" }}
                          columns={columns}
                          dataSource={
                            fetchedContract.productContractRates
                          }
                          size="small"
                        />
                      </div>
                    </>
                  ) : (
                    <div className="ml-5">Unavailable</div>
                  )}
              </div>     
            </div>          
            <div className="row user-info">
              <div className="row user-info-title mt-4 mb-4">
                <div className="col-2 text-right">
                    <ProductSvg />
                </div>
                <div className="col-10">
                  <div className="product-title">Products</div>
                  <div className="row">
                      {
                          products && products.map((item, i) => {
                            return <div key={i} className="col-6 mb-4">
                            <div className="user-info-title mt-2">
                              <div className="product-item mt-3">
                                {item.name}
                              </div>
                              <div className="product-amount mt-2">
                                ₦ {formatAmount(item.rate * 100)} <span>Per liter</span>
                              </div>
                            </div>
                            {i % 2 === 0 && <div style={{width: 1, height: 60, background: "rgba(95, 115, 140, 0.47)", position: "absolute", right: 120, top: 30}}></div>}
                          </div>
                          })
                      }
                    {products?.length === 0 && <span className="mt-3 ml-3">No product available</span>}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        </div>
      </div>
    </>
  );
};

export default ContractDetails;
