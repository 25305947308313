import React, { useState, useEffect } from "react";
import "../../../assets/Styles/Dashboard.css";
import { FilterSVG, ExportSVG } from "../../../assets/Svgs/Svgs";
import { CSVLink } from "react-csv";
import Pagination from "../../../lib/Components/Pagination";
import VendorDetails from "./VendorDetails";
import LocationList from "./LocationList";

const VendorList = (props) => {
  const { customerVendor, onHideStatusBar } = props;
  const data = customerVendor.allCustomerVendors ? customerVendor.allCustomerVendors.data : [];
  const count = data ? data.length : 0;
  // const [showVendorProfile, setShowVendorProfile] = useState(false);// eslint-disable-line no-unused-vars
  const [selectedVendor, setSelectedVendor] = useState({});
  const pageSize = 10;
  const [vendorArr, setVendorArr] = useState([])
  const [isLocationList, setIsLocationList] = useState(false);
  const [isShowVendorDetails, setIsShowVendorDetails] = useState(false);

  useEffect(() => {
    vendorList(1);
  }, [customerVendor.allCustomerVendors.data])

  function vendorList(pageNo = 1) {
    const vendorArray = data
      ? data.slice((pageNo - 1) * pageSize, (pageNo * pageSize))
      : [];

    setVendorArr(vendorArray.map((value) => {
      const { id, name, number, rcNumber, contact, status } = value;
      let condition = "", classIdentifier = "";

      if (status === "ACTIVE") {
        classIdentifier = "success";
        condition = "Active";
      } else {
        classIdentifier = "error";
        condition = "Inactive";
      }
 
      return (
        <tr key={id}>
          <td className="table-description font-weight-bold">{name}</td>
          <td className="table-description font-weight-bold">
            {number || "Unavailable"}
          </td>
          <td className="table-description font-weight-bold">
            {rcNumber || "Unavailable"}
            {/* amount in naira already */}
          </td>
          <td className="table-description">{contact.email}</td>
          <td className={`table-status`}>
            <div className={`status-btn ${classIdentifier}`}>{condition}</div>
          </td>
          <td
            className="table-action"
            onClick={() => {
              // setShowVendorProfile(true);
              setSelectedVendor(value);
              onHideStatusBar(true);
              setIsShowVendorDetails(true);
            }}
          >
            View
          </td>
        </tr>
      );
    }));
  }

  const showLocation =() => {
    setIsShowVendorDetails(false);
    setIsLocationList(true);
  }

  return (
    <div className="disbursed-cards-container row">
      {!isShowVendorDetails && !isLocationList && (
        <>
          <div
            className="menu-link"
            style={{ marginBottom: 0, paddingBottom: 4 }}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid",
              }}
            >
              All Vendors
            </span>
          </div>
          {!!data && (
            <CSVLink
              data={data}
              filename={"Vendors.csv"}
              className=""
              target="_blank"
            >
              <div
                className="contract-export-btn"
                style={{ float: "right", right: 0 }}
              >
                <span className="text-white">
                  Export CSV <ExportSVG />
                </span>
              </div>
            </CSVLink>
          )}
          <table className="table table-hover">
            <thead>
              <tr>
                <td>Name</td>
                <td>Vendor ID</td>
                <td>RC Number</td>
                <td>Email</td>
                <td>Status <FilterSVG /></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {!!count && vendorArr}
              {!count && (
                <tr>
                  <td colSpan={6} className="text-center">
                    No vendor to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            count={count}
            pageSize={pageSize}
            fetchMethod={(pageNo, pageSize) => {
              vendorList(pageNo);
            }}
          />
        </>
      )}
      {isShowVendorDetails && (
        <VendorDetails {...props} selectedVendor={selectedVendor} backFunction = {() => {
          setSelectedVendor({})
          setIsShowVendorDetails(false)
          props.onHideStatusBar(false);
        }}
        showLocation={showLocation} />
      )}
      {isLocationList && <LocationList 
                             {...props}
                             selectedVendor={selectedVendor}
                             backFunction = {()=>{ 
                               setIsShowVendorDetails(true)
                               setIsLocationList(false);
                             }} /> }
    </div>
  );
};

export default VendorList;
