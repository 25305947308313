import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import "../../assets/Styles/Dashboard.css";
import {
  UserCompleteMarkSVG,
} from "../../assets/Svgs/Svgs";

import {Modal, ModalBody} from "reactstrap";
import ToastAlert from "../../lib/Components/ToastAlert";
import {
  resetPassword,
  // logout,
} from "../../actions/OwnerActions/OwnerUserMgtActions/OwnerUserActions";
import { logoutUser } from "./Actions/auth_actions";
import {getUserProperties} from "../../lib/Constants/UserTypes";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    isChangingPassword: false,
    isPasswordChanged: false,
    errorMessage: "",
  });
  const [passwordObj, setPasswordObj] = useState({
    userEmail: "",
  });
  useEffect(() => {
    setPasswordObj({...passwordObj, userEmail: getUserProperties().userEmail});
  }, []);
  return (
    <>
      <div className="change-password-container">
        <div className="row">
          <div className="col-6 pr-5">
            <p className="change-password-text">Change Password</p>
            <div className="input-container">
              <label className="input-label">Old Password</label>
              <input
                name="user-password"
                style={{color: "#000"}}
                value={passwordObj.oldPassword}
                onChange={(e) => {
                  setPasswordObj({...passwordObj, oldPassword: e.target.value});
                }}
                type={"password"}
                className="account-input"
                placeholder="Enter Old Password"
              />
            </div>
            <div className="input-container">
              <label className="input-label">New Password</label>
              <input
                name="user-password"
                style={{color: "#000"}}
                value={passwordObj.newPassword}
                onChange={(e) => {
                  setPasswordObj({...passwordObj, newPassword: e.target.value});
                }}
                type={"password"}
                className="account-input"
              />
            </div>
            <div className="input-container">
              <label className="input-label">Confirm New Password</label>
              <input
                name="user-password"
                style={{color: "#000"}}
                value={passwordObj.confirmNewPassword}
                onChange={(e) => {
                  setPasswordObj({
                    ...passwordObj,
                    confirmNewPassword: e.target.value,
                  });
                }}
                type={"password"}
                className="account-input"
              />
            </div>
            {!!state.errorMessage && (
              <div className="mt-3">
                <ToastAlert
                  showToast={!!state.errorMessage}
                  parentClass={"col-12 pb-3 pl-0 pr-0 text-left"}
                  childClass={""}
                  toastText={state.errorMessage}
                  onCloseHandler={() => {
                    setState({...state, errorMessage: ""});
                  }}
                />
              </div>
            )}
            <div className="mt-3">
              <div className="col-6 text-center d-inline-block">
                {/* <span>Canel</span> */}
              </div>
              <div
                className="col-6 change-password-button d-inline-block"
                style={{cursor: "pointer"}}
                onClick={() => {
                  let errorMessage = "";
                  if (!passwordObj.newPassword) {
                    errorMessage = "Enter a new password!";
                  } else if (
                    passwordObj.oldPassword === passwordObj.newPassword
                  ) {
                    errorMessage =
                      "New passsword must be different from the old one";
                  } else if (
                    passwordObj.newPassword !== passwordObj.confirmNewPassword
                  ) {
                    errorMessage = "Passwords does not match!";
                  } else if (
                    !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*.()]).{8,}/.test(
                      passwordObj.newPassword
                    )
                  ) {
                    errorMessage =
                      "You have to meet all of the following requirements: Minimum 8 characters, at least one special character, at least one number, Cannot be the same as a previous password, must have at least one Uppercase letter and at least one lowercase letter";
                  } else {
                    setState({
                      ...state,
                      isChangingPassword: true,
                      errorMessage: "",
                    });
                    return dispatch(
                      resetPassword(
                        passwordObj,
                        (status) => {
                          setState({...state, isChangingPassword: status});
                        },
                        (status, errorMessage) => {
                          if (status) {
                            setState({...state, isPasswordChanged: true});
                            dispatch(logoutUser());
                          } else {
                            setState({
                              ...state,
                              isPasswordChanged: status,
                              errorMessage,
                            });
                          }
                        }
                      )
                    );
                  }
                  setState({...state, errorMessage});
                }}
              >
                {state.isChangingPassword
                  ? "Changing password..."
                  : "Change Password"}
              </div>
            </div>
          </div>

          <div className="col-6 pr-5">
            <div className="password-requirements-wrapper mb-3">
              <p className="requirement-title">Password requirements</p>
              <p className="requirement-body">
                To create a new password, you have to meet all of the following
                requirements:
              </p>
              <p className="requirement-body">
                * Minimum 8 character <br />
                * At least one special character <br />
                * At least one number <br />* Can’t be the same as a previous
                password
              </p>
            </div>
            <div className="password-requirements-wrapper">
              <p className="requirement-body">
                To protect the information in your Smart Fuel account from
                unauthorised access: Do not share your username and password
                with anyone
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={state.isPasswordChanged}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="user-modal"
      >
        <ModalBody>
          <>
            <div className="text-center mt-5 mb-4">
              <span
                className="d-block font-weight-bold"
                style={{
                  fontSize: "20px",
                  color: "#4B4B4B",
                  margin: "1rem",
                }}
              >
                Thank you! You are all set!
              </span>
              <UserCompleteMarkSVG />
              <span
                className="d-block quickteller-blue font-weight-bold"
                style={{
                  fontSize: "1rem",
                  color: "#00425F",
                  margin: "1.25rem",
                }}
              >
                Your password has been successfully changed
              </span>
            </div>
            <div className="row modal-action-buttons">
              <div className="col-6 offset-3 text-center">
                <button
                  className="back"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
            <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
          </>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChangePassword;
