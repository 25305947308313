import React, { useState, useEffect, useRef } from "react";
import "../../../assets/Styles/Dashboard.css";
import { CloseSVGShade } from "../../../assets/Svgs/Svgs";
// import {CSVLink} from "react-csv";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import { SkeletonTheme } from "react-loading-skeleton";
import Pagination from "../../../lib/Components/Pagination";
import { getUserProperties } from "../../../lib/Constants/UserTypes";
import { permissions } from "../../../lib/Constants/Permissions";
import ToastAlert from "../../../lib/Components/ToastAlert";
import { submitSmsForm } from "../redux/action/users.actions";
import { Spin } from "antd";
import "../Accounts/Users/Styles/Users.css";
import AutohideExample from "../../../helpers/SuccessToast";
import PendingRequest from "../../vendorModule/Contracts/Pendingrequest";

const SmsFeeList = (props) => {
  const {
    oTrailReducer,
    title,
    downloading,
    onDownloadTransactions,
    onFetchTransactions,
    vendorNum,
    location: locationFltr,
    dateRange,
    status: statusFltr,
    customerNumber,
  } = props;

  const { cSmsFee, fetching, error } = oTrailReducer;
  const {
    isCreatingUser,
    userCreationError,
    message,
    userCreationErrorMessage,
    isSetSms,
  } = useSelector((state) => state?.customerUser);

  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [show, setShow] = useState(false);
  const [smsMessage, setSmsMessage] = useState("");

  const componentRef = useRef();
  const pageSize = 10;

  // console.log("test", isCreatingUser, userCreationError, message);

  const dispatch = useDispatch();

  const formObj = {
    amount: "",
    CustomerNo: "",
    CustomerName: "",
  };

  const [formData, setFormData] = useState({ ...formObj });
  const [formatError, setFormatError] = useState("");

  useEffect(() => {
    onFetchTransactions(1, pageSize);
  }, [vendorNum, locationFltr, dateRange, statusFltr]); // eslint-disable-line react-hooks/exhaustive-deps

  const { permissions: authUserPermissions = [] } = getUserProperties() || {};
  // const csvData = (cSmsFee || []).map((item) => ({...item, amount: item.amount / 100}));

  const validationRules = () => {
    return {
      amount: (val) =>
        val.length < 1 ? `Amount must be no less than 1 digit!` : "",
    };
  };

  const isValid = () => {
    let response = true;
    let formatError = "";

    const amountErr = validationRules().amount(formData.amount);
    if (!!amountErr) {
      formatError = amountErr;
      response = false;
    }

    setFormatError(formatError);
    return response;
  };

  const handleInput = (e) => {
    if (e.target.name === "amount") {
      let reg = new RegExp(/^\d*\.?\d*$/);

      if (reg.test(e.target.value)) {
        setFormData({
          ...formData,
          amount: e.target.value,
        });
      }

      return;
    }
  };

  const handleSetSms = () => {
    if (!isValid()) return;
    const payload = {
      customerId: formData?.CustomerNo,
      charge: Number.isInteger(formData?.amount)
        ? formData?.amount.toString() + ".0"
        : formData?.amount,
    };
    dispatch(submitSmsForm(payload));

    // setShowTransactionModal(false);
  };

  useEffect(() => {
    if (message && !isCreatingUser) {
      setShow(true);
      setSmsMessage(message);
      setShowTransactionModal(false);
      onFetchTransactions(1, pageSize);

      setFormData({
        ...formData,
        amount: "",
      });
    }
    if (userCreationErrorMessage) {
      setShow(true);
      setSmsMessage(userCreationErrorMessage);
      // setShowTransactionModal(false);
      setFormData({
        ...formData,
        amount: "",
      });
    }

    // setSmsMessage("");
  }, [message, isCreatingUser, userCreationErrorMessage]);
  const smsList = [];
  cSmsFee &&
    cSmsFee?.data?.forEach((value, index) => {
      const { name, smsFee, number } = value;

      smsList.push(
        <tr key={index}>
          <td className="table-description">{name}</td>
          <td className="table-pin">{smsFee ? smsFee : "N/A"}</td>

          <td
            className="table-action"
            onClick={() => {
              setSelectedTransaction(value);
              setShowTransactionModal(true);
              setFormData({
                ...formData,
                CustomerNo: number,
                CustomerName: name,
              });
            }}
          >
            Set Fee
          </td>
        </tr>
      );
    });

  return (
    <SkeletonTheme color="#fff" highlightColor="#f4f4f4">
      {/* {smsMessage && ( */}
      <AutohideExample
        show={show}
        setShow={setShow}
        message={smsMessage}
        isCreatingUser={isSetSms}
      />
      {/* )} */}

      <div className="disbursed-cards-container row">
        <div
          className="menu-link"
          style={{ marginBottom: 0, paddingBottom: 1 }}
        >
          <span
            style={{
              color: "#000000",
              borderBottomColor: "#00425F",
              borderBottomWidth: 3,
              borderBottomStyle: "solid",
            }}
          >
            {!!title ? title : "Sms Fee"}
          </span>
        </div>
        <PendingRequest approvalType="SmsFee" />
        <table className="table table-hover">
          <thead>
            <tr>
              <td>Customer Name</td>
              <td>SMS Fee</td>
              <td></td>
            </tr>
          </thead>
          {fetching && (
            <tbody>
              <tr>
                <td
                  className="text-center"
                  colSpan={
                    authUserPermissions.indexOf(permissions.VIEW_TRANSACTION) >
                    -1
                      ? 8
                      : 7
                  }
                >
                  <Spin
                    wrapperClassName="spinner-wrapper"
                    spinning={fetching}
                    tip="Loading..."
                  ></Spin>{" "}
                </td>
              </tr>
            </tbody>
          )}
          {!fetching && (
            <tbody>
              {/* {!!smsList && smsList} */}
              {smsList && smsList.length > 0 ? (
                smsList
              ) : (
                <>
                  <tr>
                    <td
                      colSpan={
                        authUserPermissions.indexOf(
                          permissions.VIEW_TRANSACTION
                        ) > -1
                          ? 8
                          : 7
                      }
                      className="text-center"
                    >
                      No Customer Fee List to show
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          )}
        </table>

        <Pagination
          count={cSmsFee?.count}
          pageSize={pageSize}
          fetchMethod={(pageNo, pageSize) => {
            onFetchTransactions(pageNo, pageSize);
          }}
        />
      </div>
      <Modal
        show={showTransactionModal}
        size="sm"
        aria-labelledby=""
        centered
        dialogClassName="transaction-modal"
      >
        <Modal.Header className="modalStyle">
          <div className="header-text ">Set Fee</div>
          <div
            className="svg-close-icon"
            onClick={() => {
              setShowTransactionModal(false);
            }}
          >
            <CloseSVGShade />
          </div>
        </Modal.Header>
        <Modal.Body ref={componentRef}>
          <div className="  modal-wrapper">
            <div className="">
              <div className="form-group">
                <label>Customer Name</label>
                <input
                  type="text"
                  value={formData.CustomerName}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group ">
                <label>Amount</label>
                <input
                  type="text"
                  name="amount"
                  value={formData?.amount}
                  onChange={handleInput}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-red">
          <div className="save-sms-btn">
            <button
              className="svg-btns cancel-btns"
              onClick={() => {
                setShowTransactionModal(false);
              }}
            >
              {" "}
              Cancel
            </button>
            <button
              className="svg-btns save-btns text-center"
              onClick={handleSetSms}
            >
              {isCreatingUser ? (
                <img
                  src="/images/ActivityIndicator.gif"
                  alt=""
                  style={{ height: 20, width: 20 }}
                />
              ) : (
                "Save"
              )}
              {/* Save */}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastAlert
        showToast={!!formatError}
        parentClass={"col-12 pb-3 pl-0"}
        childClass={""}
        toastText={formatError}
        onCloseHandler={() => {
          setFormatError("");
        }}
      />
      {/* <ToastContainer /> */}
    </SkeletonTheme>
  );
};

export default SmsFeeList;
