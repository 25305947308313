const voucherReducer = (
  state = {
    allVouchers: [],
    customerVouchers: [],
    customerVouchersByStatus: [],
    vouchersByStatus: [],
    vouchersBySerial: [],
    newVoucher: {},
    fetching: false,
    fetched: false,
    error: null,
    selectedVoucher: {},
  },
  action,
) => {
  switch (action.type) {
    case 'FETCH_All_VOUCHERS_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_All_VOUCHERS_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, allVouchers: action.payload };
    }
    case 'FETCH_All_VOUCHERS_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.error };
    }

    case 'FETCH_All_CUSTOMER_VOUCHERS_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_All_CUSTOMER_VOUCHERS_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, customerVouchers: action.payload };
    }
    case 'FETCH_All_CUSTOMER_VOUCHERS_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.error };
    }

    case 'FETCH_All_CUSTOMER_VOUCHERS_BYSTATUS_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_All_CUSTOMER_VOUCHERS_BYSTATUS_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, customerVouchersByStatus: action.payload };
    }
    case 'FETCH_All_CUSTOMER_VOUCHERS_BYSTATUS_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.error };
    }

    case 'CREATE_VOUCHERS_START': {
      return { ...state, fetching: true };
    }

    case 'CREATE_VOUCHERS_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, newVoucher: action.payload, allVouchers: [...state.allVouchers, action.payload] };
    }

    case 'CREATE_VOUCHERS_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.error };
    }

    case 'FETCH_All_VOUCHERS_BYSTATUS_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_All_VOUCHERS_BYSTATUS_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, vouchersByStatus: action.payload };
    }
    case 'FETCH_All_VOUCHERS_BYSTATUS_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.error };
    }

    case 'FETCH_All_VOUCHERS_BYSERIAL_START': {
      return { ...state, fetching: true };
    }

    case 'FETCH_All_VOUCHERS_BYSERIAL_FULLFILLED': {
      return { ...state, fetching: false, fetched: true, vouchersBySerial: action.payload };
    }
    case 'FETCH_All_VOUCHERS_BYSERIAL_REJECTED': {
      return { ...state, fetching: false, fetched: true, error: action.error };
    }
  }
  return state;
};

export default voucherReducer;
