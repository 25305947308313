import React, {useEffect} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import StatusBar from '../StatusBar';
import { fetchAllTerminals, fetchAllPOSUsers, fetchAllVendors, singlePOSUserCreateForm } from "../../redux/action/OwnerVendorActions";
import PosUsersList from "./PosUsersList";
import { useState } from "react";

function PosUsers () {
  const dispatch = useDispatch();
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);
  useEffect(()=> {
    batch(()=>{
      dispatch(fetchAllPOSUsers());
      dispatch(fetchAllTerminals(1, 10));
      dispatch(fetchAllVendors());
    })
    
  }, [])

  const createPos = async (data, callback) => {
    const posUser = data;
      let body = {
        firstName: posUser.firstName,
        lastName: posUser.lastName,
        userCode: posUser.usersCode,
        userId: posUser.usersId,
        userEmail: posUser.email,
        phone: posUser.phone,
        vendor: posUser.vendor,
        address: {
          street: posUser.street,
          city: posUser.city,
          state: posUser.state,
          country: posUser.country,
        }
      }
     await dispatch(singlePOSUserCreateForm(body));
    callback(true);
  }

  const props = useSelector(state => state);
  
  return (
    <div className="dashboard-container">
      <StatusBar {...props} isVisible={isStatusBarVisible} />
      <PosUsersList {...props} onFetchTerminals={(pageNo, size) => {
        dispatch(fetchAllTerminals(pageNo, size));
      }}
      onHideStatusBar={status => {
        setIsStatusBarVisible(!status);
      }}
      onCreatePos = {createPos}
       />
    </div>
  )
}

export default PosUsers;