import React, { Component } from 'react';
import '../Admin.css';


class Notfound extends Component {
  render() {
    return (
      <div className="Notfound p-5 mx-5 notfound" >
      
      {/* <h5 className="px-5 mx-2">
      Page Not Found !!!
      </h5> */}
      </div>
    );
  }
}

export default Notfound;
