import React, { useState } from "react";
import "../../../assets/Styles/Dashboard.css";
import {
  FilterSVG,
  ExportSVG,
  VendorSVG,
  CustomerIconSVG,
  ContractIconSVG,
  AGOIconSVG,
  CreateSVG,
  CloseSVG
} from "../../../assets/Svgs/Svgs";
import { CSVLink } from "react-csv";
import Modal from "react-bootstrap/Modal";
import { ACTIVE, EXPIRED, TERMINATED } from "../../../lib/Constants/ContractStates";
import {
  shortMonthNames,
  shortDayNames
} from "../../../lib/Functions/DateNames";
import formatAmount from "../../../lib/Functions/formatAmount";
import toSentenceCase, {retrieveNameFromSignature } from "../../../lib/Functions/toSentenceCase";
import { formatDate3 } from "../../../lib/Functions/formatDate";
import Pagination from "../../../lib/Components/Pagination";
import CreateContract from "./CreateContract";

const NewContractList = props => {
  const {
    oContractReducer,
    onFetchContracts
  } = props;
  const { data, count } = oContractReducer.allContracts;
  const [showContractDetails, setShowContractDetails] = useState(false);
  const [selectedContract, setSelectedContract] = useState({});
  const [isCreateContract, setIsCreateContract] = useState(false);
  const pageSize = 10;

  const TrailList = data && data.map(value => {
    const {
      id,
      name,
      bound,
      contractStartDate,
      contractStatus,
      contractCycleType
    } = value;

    let condition = "", classIdentifier = "";
    if (contractStatus === ACTIVE) {
      classIdentifier = "success";
      condition = "Active";
    } else if (contractStatus === EXPIRED) {
      classIdentifier = "error";
      condition = "Expired";
    } else if (contractStatus === TERMINATED) {
      classIdentifier = "error";
      condition = "Terminated";
    } else {
      classIdentifier = "warning";
      condition = "Inactive";
    }
    const d = new Date(contractStartDate);

    return (
      <tr key={id}>
        <td className="table-description font-weight-bold">{name}</td>
        <td className="table-description font-weight-bold">
          ₦ {formatAmount(bound * 100)}
          {/* amount in naira already */}
        </td>
        <td className="table-description">
          {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
          {shortMonthNames[d.getMonth()]} {d.getFullYear()}
        </td>
        <td className={`table-status`}>
          <div className={`status-btn ${classIdentifier}`}>{condition}</div>
        </td>
        <td className={`table-description`}>{contractCycleType || "Unavailable"}</td>
        <td
          className="table-action"
          onClick={() => {
            setShowContractDetails(true);
            setSelectedContract(value);
          }}
        >
          View
        </td>
      </tr>
    );
  });

  return (
    <div className="disbursed-cards-container row">
      {!isCreateContract && (
        <>
          <div
            className="menu-link"
            style={{ marginBottom: 0, paddingBottom: 4 }}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid"
              }}
            >
              All Contracts
            </span>
          </div>
          <div
            className="create-new-tags-btn"
            onClick={() => {
              props.onHideStatusBar(true);
              setIsCreateContract(true);
            }}
          >
            <span className="align-middle">
              <CreateSVG /> Create New Contracts
            </span>
          </div>

          {!!data && (
            <CSVLink
              data={data}
              filename={"Contracts.csv"}
              className=""
              target="_blank"
            >
              <div className="contract-export-btn" style={{ float: "right" }}>
                <span className="text-white">
                  Export CSV <ExportSVG />
                </span>
              </div>
            </CSVLink>
          )}

          <table className="table table-hover">
            <thead>
              <tr>
                <td>Name</td>
                <td>Bound</td>
                <td>Start Date <FilterSVG /></td>
                <td>Status <FilterSVG /></td>
                <td>Frequency <FilterSVG /></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {!!count && TrailList}
              {!count && (
                <tr>
                  <td colSpan={6} className="text-center">
                    No contract to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            count={count}
            pageSize={pageSize}
            fetchMethod={(pageNo, pageSize) => {
              onFetchContracts(pageNo, pageSize);
            }}
          />

          <Modal
            show={showContractDetails}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="rfid-modal"
          >
            <Modal.Body>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: 10,
                  paddingLeft: 10
                }}
              >
                <h6 className="mt-5 qt-black font-weight-bold text-left d-inline">
                  Contract Details
                </h6>
                <span
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => {
                    setShowContractDetails(false);
                  }}
                >
                  <CloseSVG />
                </span>
              </div>
              <>
                <div className="row">
                  <div className="col-3 mt-2 p-3">
                    <div
                      className="row bg-rfid-details ml-2 mr-1 rounded"
                      style={{ height: "60%" }}
                    >
                      <div className="pl-4 mt-3 d-table">
                        <div className="col-1 p-0 d-inline-block d-table-cell align-middle">
                          <VendorSVG />
                        </div>
                        <div className="col-11 ml-0">
                          <div style={{
                            overflowWrap : 'normal'
                          }} className="contract-modal-heading d-inline-block">
                            Vendor
                          </div>
                          <div className="font-weight-bold mt-1 contract-modal-value">
                          {retrieveNameFromSignature(selectedContract.vendorSignature) || "Unavailable"}
                          </div>
                        </div>
                      </div>
                      <div className="pl-4 mt-3 mb-3 d-table">
                        <div className="col-1 p-0 d-table-cell align-middle">
                          <CustomerIconSVG />
                        </div>
                        <div className="col-11 pl-3">
                          <div className="contract-modal-heading d-inline-block">
                            Customer
                          </div>
                          <div className="font-weight-bold mt-1 contract-modal-value">
                         {retrieveNameFromSignature(selectedContract.customerSignature) || "Unavailable"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row bg-rfid-details ml-2 pb-5 mr-1 text-center font-weight-bold mt-3 rounded"
                      style={{
                        height: "calc(40% - 16px)",
                        background: "#D4E1F4"
                      }}
                    >
                      <div className="d-table pl-4" style={{ height: "100%" }}>
                        <div className=" p-0 d-table-cell align-middle">
                          <ContractIconSVG />
                        </div>
                        <div className=" pl-3 d-table-cell align-middle compartment">
                          <div className="contract-modal-heading text-left">
                            Contract Limit
                          </div>
                          <div className="font-weight-bold count mt-1 text-left">
                            ₦ {formatAmount(selectedContract.bound * 100)}
                            {/* amount in naira already */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-9 mt-2 p-3">
                    <div className="row bg-rfid-details mr-2 p-5 rounded">
                      <div className="col-1">
                        <AGOIconSVG />
                      </div>
                      <div className="col-11">
                        <span
                          className="title d-inline-block mb-4 pb-2"
                          style={{ borderBottom: "1px solid #c4c4c4" }}
                        >
                          {selectedContract.name}
                        </span>
                        <div className="row" style={{ width: "100%" }}>
                          <div className="row ml-0 contract-modal-heading">
                            <div className="col-3">Contract Cycle</div>
                            <div className="col-3">Contract Type</div>
                            <div className="col-3">Status</div>
                            <div className="col-3">Start Date</div>
                          </div>
                          <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                            <div className="col-3">
                              {toSentenceCase(
                                selectedContract.contractCycleType ||
                                  "Unavailable"
                              )}
                            </div>
                            <div className="col-3">
                            {selectedContract.autoRenew ? "Renewable" : "One-Off"}
                            </div>
                            <div className="col-3">
                              {toSentenceCase(
                                selectedContract.contractStatus || "Unavailable"
                              )}
                            </div>
                            <div className="col-3">
                              {formatDate3(
                                selectedContract.contractStartDate ||
                                  "Unavailable"
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="row ml-0 mt-4"
                          style={{ width: "100%" }}
                        >
                          <div className="row contract-modal-heading">
                            <div className="col-3">Vendor Signature</div>
                            <div className="col-3">Customer Signature</div>
                            <div className="col-3">Frequency</div>
                            <div className="col-3">Auto-Renew?</div>
                          </div>
                          <div className="row font-weight-bold mt-2  contract-modal-value">
                            <div
                              className="col-3"
                              style={{ overflowWrap: "break-word" }}
                            >
                              {selectedContract.vendorSignature || "Nil"}
                            </div>
                            <div className="col-3">
                              {selectedContract.customerSignature ||
                                "Unavailable"}
                            </div>
                            <div className="col-3">
                              {/* {selectedContract.frequency || "Unavailable"} */}
                              {(selectedContract.contractCycleType === "DAYS") && 
                                (selectedContract.contractCycleTypeDays || '')}
                              {selectedContract.contractCycleType || "Unavailable"}
                            </div>
                            <div className="col-3">
                              {selectedContract.autoRenew ? "Yes" : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </Modal.Body>
          </Modal>
        </>
      )}

      <CreateContract
        {...props}
        isCreateContract={isCreateContract}
        onBackHandler={() => {
          setIsCreateContract(false);
          props.onHideStatusBar(false);
        }}
      />
    </div>
  );
};

export default NewContractList;
