import React, {/* useState, */ useEffect/* , useRef */} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../assets/Styles/Style.css";
import BgLoader from "../../helpers/backgroundLoader";
import {loginUser as login} from "./Actions/auth_actions";// "../../actions/OwnerActions/OwnerUserMgtActions/OwnerUserActions";
// import Header from "../../lib/Components/Header";
// import {
//   EyeOpenSVG,
//   EyeClosedSVG,
//   CompletedMarkSVG,
// } from "../../assets/Svgs/Svgs";
import {userService} from "../../services/authService";
// import {history} from "../../helpers/history";
// import {forgotPassword} from "../../actions/action_constants";

function Login() {
  const dispatch = useDispatch();
  /* const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState(undefined);
  const [isResetTokenSent, setisResetTokenSent] = useState(false);
  const [showPasswordReset, setshowPasswordReset] = useState(false);
  const [passwordResetSuccess, setpasswordResetSuccess] = useState(false); */
  const props = useSelector((state) => state);
  const {authentication} = props;
  // const submitBtnRef = useRef();

  useEffect(() => {
    /* if (!!authentication.error) {
      setIsError(true);
      setErrorText(authentication.error);
      setIsLoggingIn(false);
    } */
    dispatch(login(authentication.error))
  }, [authentication.error]);

  useEffect(() => {
    userService.logout();
  }, []);

  /* const validateUser = () => {
    let error = false;
    if (userId === "") {
      error = true;
    }
    if (password === "" || !password) {
      error = true;
    }
    return error;
  };

  const dismissError = () => {
    setIsError(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validateUser()) {
      setIsError(true);
      setErrorText("Invalid Email/Phone or Password!");
      return;
    }
    setIsLoggingIn(true);
    dispatch(login(userId, password));
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    name === "user-id" && setUserId(value);
    name === "user-password" && setPassword(value);
  }; */

  // const [infoHeader, setInfoHeader] = useState("");

  // setTimeout(() => {
  //     setInfoHeader("Payments, loyalty, forecourt management and monitoring");
  // }, 2000);

  return (
    <>
    <BgLoader message="Redirecting..." />
    <>
    {/* <div className="container">
      <Header />
      <div className="body">
        {
          <div className="body-container"> */}
            {/* <div className="registration-info">
                        <span className="registration-text">New to Smartfuel ?</span>
                        <span className="registration-btn">Sign Up</span>
                    </div> */}

            {/* <div className="login-info">
              <div className="info-div">
                <div className="info-header">
                  {"Payments, loyalty, forecourt management and monitoring"}
                </div>

                <div className="info-body">
                  Your all in one platform for seamless purchase of fuel.
                </div>
              </div>
            </div>
            <div className="login-banner"></div> */}
            {/* <div className="login-panel"> */}
              {/* {window.location.pathname === "/login" && (
                <>
                  <span className="login-title">Login to Dashboard</span>
                  <span className="login-instruction">Enter credentials</span>
                  <div className="input-container">
                    <label className="input-label">
                      Email Address / Username
                    </label>
                    <input
                      type="text"
                      style={{color: "#000"}}
                      disabled={isLoggingIn}
                      name="user-id"
                      value={userId}
                      onChange={handleInputChange}
                      className="account-input"
                    />
                  </div>
                  <div className="input-container">
                    <label className="input-label">Password</label>
                    <input
                      name="user-password"
                      onKeyDown={(e) =>
                        e.keyCode === 13 ? submitBtnRef.current.focus() : null
                      }
                      style={{color: "#000"}}
                      disabled={isLoggingIn}
                      onChange={handleInputChange}
                      value={password}
                      type={isShowPassword ? "text" : "password"}
                      className="account-input"
                    />
                    <span
                      onClick={() => setIsShowPassword(!isShowPassword)}
                      style={{
                        position: "absolute",
                        right: 45,
                        marginTop: 7,
                        cursor: "pointer",
                      }}
                    >
                      {isShowPassword ? <EyeOpenSVG /> : <EyeClosedSVG />}
                    </span>
                  </div>

                  <div className="button-container" style={{marginTop: 33}}>
                    <button
                      type="button"
                      onClick={handleLogin}
                      ref={submitBtnRef}
                      disabled={isLoggingIn}
                      className="account-btn text-center"
                      value="Login"
                    >
                      {isLoggingIn ? (
                        <img
                          src="/images/ActivityIndicator.gif" alt="activity-indicator"
                          style={{height: 20, width: 20}}
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>

                  <a
                    className="forgot-password-text"
                    style={{cursor: "pointer"}}
                    href={forgotPassword}
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                  >
                    Forgot Password ?
                  </a>
                </>
              )}
              {window.location.pathname === "/forgot-password" && (
                <>
                  {!passwordResetSuccess && (
                    <span className="login-title">Forgot Password</span>
                  )}
                  {!isResetTokenSent && (
                    <>
                      <span className="login-instruction">
                        Enter account email below and we will send you a reset
                        token
                      </span>
                      <div className="input-container mt-5">
                        <label className="input-label">Email Address</label>
                        <input
                          type="text"
                          style={{color: "#000"}}
                          disabled={isLoggingIn}
                          name="user-id"
                          value={userId}
                          onChange={handleInputChange}
                          className="account-input"
                        />
                      </div>
                      <div className="button-container" style={{marginTop: 33}}>
                        <button
                          type="button"
                          onClick={() => {
                            setisResetTokenSent(true);
                          }}
                          ref={submitBtnRef}
                          disabled={isLoggingIn}
                          className="account-btn text-center"
                          value="Login"
                        >
                          {isLoggingIn ? (
                            <img
                              src="/images/ActivityIndicator.gif" alt="activity-indicator"
                              style={{height: 20, width: 20}}
                            />
                          ) : (
                            "Send token"
                          )}
                        </button>
                      </div>
                    </>
                  )}
                  {isResetTokenSent && !showPasswordReset && (
                    <>
                      <span className="login-instruction">
                        Enter the reset token sent to {userId}
                      </span>
                      <div className="input-container mt-4">
                        <label className="input-label">Reset Token</label>
                        <input
                          type="text"
                          style={{color: "#000"}}
                          disabled={isLoggingIn}
                          name="user-id"
                          value={userId}
                          onChange={handleInputChange}
                          className="account-input"
                        />
                      </div>
                      <div className="button-container" style={{marginTop: 33}}>
                        <button
                          type="button"
                          onClick={() => {
                            setshowPasswordReset(true);
                          }}
                          ref={submitBtnRef}
                          disabled={isLoggingIn}
                          className="account-btn text-center"
                          value="Login"
                        >
                          {isLoggingIn ? (
                            <img
                              src="/images/ActivityIndicator.gif" alt="activity-indicator"
                              style={{height: 20, width: 20}}
                            />
                          ) : (
                            "Proceed"
                          )}
                        </button>
                      </div>
                      <span
                        className="forgot-password-text"
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          fontWeight: "bold",
                        }}
                        onClick={() => {}}
                      >
                        Resend Token
                      </span>
                    </>
                  )}

                  {isResetTokenSent &&
                    showPasswordReset &&
                    !passwordResetSuccess && (
                      <>
                        <span className="login-instruction">
                          Enter your new pass and verify
                        </span>
                        <div className="input-container mt-4">
                          <label className="input-label">New Password</label>
                          <input
                            type="password"
                            style={{color: "#000"}}
                            disabled={isLoggingIn}
                            name="password"
                            value={userId}
                            onChange={handleInputChange}
                            className="account-input"
                          />
                        </div>
                        <div className="input-container mt-4">
                          <label className="input-label">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            style={{color: "#000"}}
                            disabled={isLoggingIn}
                            name="cPassword"
                            value={userId}
                            onChange={handleInputChange}
                            className="account-input"
                          />
                        </div>
                        <div
                          className="button-container"
                          style={{marginTop: 33}}
                        >
                          <button
                            type="button"
                            onClick={() => {
                              setpasswordResetSuccess(true);
                            }}
                            ref={submitBtnRef}
                            disabled={isLoggingIn}
                            className="account-btn text-center"
                          >
                            {isLoggingIn ? (
                              <img
                                src="/images/ActivityIndicator.gif" alt="activity-indicator"
                                style={{height: 20, width: 20}}
                              />
                            ) : (
                              "Verify"
                            )}
                          </button>
                        </div>
                      </>
                    )}

                  {isResetTokenSent &&
                    showPasswordReset &&
                    passwordResetSuccess && (
                      <>
                        <div className="text-center">
                          <CompletedMarkSVG width={300} />
                        </div>
                        <h6 className="text-center mb-4">
                          Your password has been reset
                        </h6>
                        <div className="button-container">
                          <button
                            type="button"
                            onClick={() => {
                              history.push("/login");
                              window.location.reload();
                            }}
                            ref={submitBtnRef}
                            disabled={isLoggingIn}
                            className="account-btn text-center"
                          >
                            {isLoggingIn ? (
                              <img
                                src="/images/ActivityIndicator.gif" alt="activity-indicator"
                                style={{height: 20, width: 20}}
                              />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                      </>
                    )}
                </>
              )}

              {isError && (
                <div className="error-container">
                  <span className="error-close" onClick={dismissError}>
                    x
                  </span>
                  <span className="error-text">{errorText}</span>
                </div>
              )} */}

              {/* <div className="panel-footer-container text-center">
                            <span className="panel-footer-text">Need help ? </span><span className="panel-footer-link">Contact Us</span>
                        </div> */}
            {/* </div> */}
          {/* </div>
        } */}
        {/* <div className="guest-footer">
                    <span className="footer-link">Support</span>
                    <span className="footer-link">Solutions</span>
                </div> */}
      {/* </div>
    </div> */}
    </>
    </>
  );
}

export default Login;
