const toSentenceCase = (word) => {
    let wordArray = word?.trim().split( " " ) || [];
    const sentenceArray = wordArray.map(value => {
        return value[0]?.toUpperCase() + value?.substring(1)?.toLowerCase();
    });
    return sentenceArray.join(" ");
}

export const retrieveNameFromSignature = signature => {
    let words = signature?.trim().split(" ") || [];
    const sentenceArray = words.map((value, index) => {
                if(index < (words.length -1))
                return value[0].toUpperCase() + value.substring(1).toLowerCase();
        });
    return sentenceArray.join(" ");
}

export default toSentenceCase;