import React, { useMemo, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as GreenCheckIcon } from '../../../../assets/Svgs/GreenCheck.svg'
import ReactToPrint from "react-to-print";
import formatAmount from '../../../../lib/Functions/formatAmount';
import { formatDate3 } from '../../../../lib/Functions/formatDate';

const InvoiceComponent = ({ toggleModal, invoiceDetails, showInvoice }) => {
    const invoiceRef = useRef();
     const contractInfo = useMemo(()=>{
        if(invoiceDetails?.data && typeof invoiceDetails?.data === 'string'){
            return JSON.parse(invoiceDetails.data);
        }
    },[invoiceDetails])

    return (<Modal style={{
    }} scrollable={true} backdropClassName="payment-modal-backdrop"  className="payment-modal" show={showInvoice}>
        <Modal.Header closeButton style={{
            background: '#EBF8FE',
            paddingRight: 24,
            border: 'none',
        }} onHide={toggleModal}>
        </Modal.Header>
        <Modal.Body style={{
            padding: 24,
            background: '#EBF8FE',
            maxHeight: '60vh', overflowY: 'auto'
        }}>
            <div className='invoice-modal-detail'>
                {invoiceDetails && <div ref={invoiceRef}>
                    <div className='d-flex flex-column justify-content-center align-items-center p-3'>
                        <GreenCheckIcon />
                        <span className='payment-modal-h mb-1'>Invoice Summary</span>
                        {invoiceDetails && <h3>N{formatAmount(invoiceDetails.invoice.amount * 100)}</h3>}
                    </div>
                    <div className='invoice-divider'></div>
                    <div className='invoice-details w-100 pb-4'>
                        <div className='d-flex justify-content-between w-100'>
                            <span className='payments-sub dark'>Invoiced Amount</span>
                            <span className='invoice-detail-value'>N{formatAmount((invoiceDetails.invoice.amount || invoiceDetails.invoice.invoice) * 100)}</span>
                        </div>
                        <div className='d-flex justify-content-between w-100'>
                            <span className='payments-sub dark'>Customer Name</span>
                            <span className='invoice-detail-value'>{invoiceDetails.customerName}</span>
                        </div>
                        <div className='d-flex justify-content-between w-100'>
                            <span className='payments-sub dark'>Vendor Name</span>
                            <span className='invoice-detail-value'>{invoiceDetails.vendorName}</span>
                        </div>
                        <div className='d-flex justify-content-between w-100'>
                            <span className='payments-sub dark'>Date Generated</span>
                            <span className='invoice-detail-value'>{formatDate3(invoiceDetails.createdAt)}</span>
                        </div>
                        <div className='d-flex justify-content-between w-100'>
                            <span className='payments-sub dark'>Account Number</span>
                            <span className='invoice-detail-value'>{invoiceDetails.accountNumber}</span>
                        </div>
                        <div className='d-flex justify-content-between w-100'>
                            <span className='payments-sub dark'>Bank</span>
                            <span className='invoice-detail-value'>{invoiceDetails.bankName}</span>
                        </div>
                        <div className='d-flex justify-content-between w-100'>
                            <span className='payments-sub dark'>Invoice ID</span>
                            <span className='invoice-detail-value'>{invoiceDetails?.invoice?.number}</span>
                        </div>
                        {(invoiceDetails.paymentType === "POSTPAID") && <>
                        <div className='d-flex justify-content-between w-100'>
                                <span className='payments-sub dark'>Contract ID</span>
                                <span className='invoice-detail-value'>{contractInfo?.contractNumber}</span>
                            </div>
                        <div className='d-flex justify-content-between w-100'>
                            <span className='payments-sub dark'>Contract Duration</span>
                            <span className='invoice-detail-value'>{formatDate3(contractInfo?.contractDuration)}</span>
                        </div>
                            <div className='d-flex justify-content-between w-100'>
                                <span className='payments-sub dark'>Contract Type</span>
                                <span className='invoice-detail-value'>{invoiceDetails.paymentType}</span>
                            </div></>}
                    </div>
                </div>}
                <div className='p-3'>
                    <ReactToPrint 
                      trigger={()=>(
                        <button className='payment-button invoice-detail-button mb-3'>
                        Download PDF
                    </button>
                      )}
                      content={()=>invoiceRef.current}
                    />
                    
                    <button onClick={toggleModal} style={{
                        border: '1px solid #C8D2DF',
                        background: 'white',
                    }} className='w-100 invoice-cancel-button invoice-detail-button'>
                        Cancel
                    </button>
                </div>
            </div>
        </Modal.Body>
    </Modal>);
}

export default InvoiceComponent;