import React, {useState} from "react";
import {Modal, ModalBody} from "reactstrap";
import {CloseSVG} from "../../../../assets/Svgs/Svgs";
import ToastAlert from "../../../../lib/Components/ToastAlert";
export default ({
  onHide,
  customerContracts,
  onCreateSchemeHandler,
  isCreatingScheme,
  isErrorEncountered,
  onErrorHandler,
}) => {
  const [state, setState] = useState({
    cycle: "",
    cardHolder: "",
    limit: "",
    availableBalance: "",
    assignCardErrorMessage: "",
    selectedContractId: "",
    title: "",
    schemeErrorMessage: "",
  });
  function onHandleInput(field, e) {
    setState({...state, [field]: e.target.value});
  }
  const {
    title,
    cycle,
    limit,
    description,
    schemeErrorMessage,
    selectedContractId,
  } = state;
  function isValidateError() {
    let errorMsg = "";
    // if (cardPan.length < 19) {
    //   errorMsg = "Card pan must not be less than 19 digits";
    // } else if (isNaN(cardPan)) {
    //   errorMsg = "Card pan must be a number";
    // } else if (title.length < 3) {
    //   errorMsg = "Title must be up to 3 characters in length";
    // } else if (limit <= 0) {
    //   errorMsg = "Limit must be greater than 0 naira";
    // } else if (!cardHolder) {
    //   errorMsg = "Please select a card holder";
    // }
    if (!selectedContractId) {
      errorMsg = "Select a contract to continue";
    } else if (title.length < 3) {
      errorMsg = "Title must be up to 3 characters in length";
    } else if (limit <= 0) {
      errorMsg = "Limit must be greater than 0 naira";
    }
    setState({...state, schemeErrorMessage: errorMsg});
    return errorMsg;
  }
  let contractList = [];
  if (customerContracts?.length) {
    contractList = customerContracts.map((contract, index) => {
      return (
        <option value={contract.contractNumber} key={index}>
          {contract.name}
        </option>
      );
    });
  }
  return (
    <Modal
      isOpen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      className="user-modal"
    >
      <ModalBody>
        <div className="card-management-row ml-3 mr-3">
          <div
            className="mb-4"
            style={{
              textAlign: "left",
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10,
              borderBottom: "0.5px solid #5F738C",
            }}
          >
            <h6 className="mt-5 qt-black font-weight-bold text-left d-inline">
              Create Scheme
            </h6>
            <span style={{float: "right", cursor: "pointer"}} onClick={onHide}>
              <CloseSVG />
            </span>
          </div>
          <div className="row">
            <div className="form-group col-6">
              <label>Select Contract</label>
              <select
                className="form-control"
                onChange={(event) => {
                  const selectedContract = customerContracts.filter(
                    (contract) => contract.contractNumber === event.target.value
                  )[0];
                  setState({
                    ...state,
                    cycle: selectedContract.contractCycleType,
                    limit: selectedContract.bound,
                    selectedContractId: event.target.value,
                  });
                }}
                value={state.selectedContractId}
              >
                <option>Select a Contract...</option>
                {contractList}
              </select>
            </div>
            <div className="form-group col-6">
              <label>Title</label>
              <input
                type="text"
                value={title}
                className="form-control"
                onChange={onHandleInput.bind(this, "title")}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-6">
              <label>Cycle</label>
              <input
                type="text"
                value={cycle}
                className="form-control"
                contentEditable={false}
                onChange={() => {}}
              />
            </div>
            <div className="form-group col-6">
              <label>Limit</label>
              <input
                type="text"
                value={limit}
                className="form-control"
                contentEditable={false}
                onChange={() => {}}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12">
              <label>Description</label>
              <textarea
                type="text"
                value={description}
                className="form-control"
                onChange={onHandleInput.bind(this, "description")}
              ></textarea>
            </div>
          </div>
          {(!!isErrorEncountered || !!schemeErrorMessage) && (
            <div className="row mt-2 mb-4">
              <ToastAlert
                parentClass="col-12"
                toastText={isErrorEncountered || schemeErrorMessage}
                showToast={!!isErrorEncountered || !!schemeErrorMessage}
                onCloseHandler={() => {
                  if (!!schemeErrorMessage) {
                    setState({...state, schemeErrorMessage: ""});
                  } else {
                    onErrorHandler(false);
                  }
                }}
              />
            </div>
          )}
          <div className="row mt-3 mb-4">
            <div className="assign-btn col-12">
              <button
                onClick={
                  !isCreatingScheme
                    ? () => {
                        if (!isValidateError()) {
                          //no error
                          // onHide();
                          onCreateSchemeHandler(state);
                        }
                      }
                    : () => {}
                }
              >
                {isCreatingScheme ? "Creating Scheme..." : "Create Scheme"}
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
