import { userService } from "../../../services/authService";
import {history} from "../../../helpers/history";

// export function loginUser(userId, password) {
export function loginUser(reason, redirectTo = null) {
    return dispatch => {
        if (!reason) dispatch(request({ userId: "app-user" }));

        userService.loginX(reason, redirectTo).then(
            user => {
                dispatch(success(user));
                if (user) {
                    switch (user.data.type) {
                      case 'SUPER_ADMIN':
                      case 'OWNER_USER':
                        history.push(
                            user.data.isNew
                                ? "/owner-admin/change-password/"
                                : '/owner-admin/dashboard');
                        window.location.reload();
                        break;

                      case 'VENDOR_ADMIN':
                      case 'VENDOR_USER':
                      case 'VENDOR_SUPER_ADMIN':
                      case 'LOGISTIC':
                      case 'PROCUREMENT':
                        history.push(
                            user.data.isNew
                                ? "/vendor-admin/change-password/"
                                : '/vendor-admin/dashboard'); 
                        window.location.reload();
                        break;

                      case 'CUSTOMER_ADMIN':
                      case 'CUSTOMER_USER':
                        history.push(
                            user.data.isNew
                                ? "/customer-admin/change-password/"
                                : '/customer-admin/dashboard');
                        window.location.reload();
                        break;

                      case 'FLIT':
                      case 'FLEET':
                        dispatch(accessDenied('/delivery'));
                        break;

                      default:
                        history.push('/login');
                        break;
                    }
                }
            }).catch(
                error => {
                    // console.log(`error------${error}`)
                    dispatch(failure(error));
                    // dispatch(alertError(error.toString()));
                },
            );
    };

    function request(user) {
        return { type: 'USERS_LOGIN_REQUEST', user };
    }

    function success(user) {
        return { type: 'USERS_LOGIN_SUCCESS', user };
    }

    function failure(error) {
        return { type: 'USERS_LOGIN_FAILURE', error };
    }

    // function alertSuccess(message) {
    //   return { type: 'ALERT_SUCCESS', message };
    // }

    // function alertError(message) {
    //     return { type: 'alert-danger', message };
    // }

    // function alertClear() {
    //   return { type: 'ALERT_CLEAR' };
    // }
}

function accessDenied(redirectTo = null) {
    return dispatch => dispatch(loginUser('ACCESSDENIED', redirectTo))
}

export function logoutUser() {
    return dispatch => {
        userService.logout();
        dispatch(loginUser('LEAVING'));
    }
}