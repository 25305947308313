import React, {useEffect} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import StatusBar from '../StatusBar';
import TerminalList from "./TerminalList";
import { fetchAllTerminals,
         fetchAllPOSUsers,
         fetchAllVendors,
         submitTerminalForm,
         submitTerminalUpdateForm } from "../../redux/action/OwnerVendorActions";
import { useState } from "react";

function Terminals () {
  const dispatch = useDispatch();
  useEffect(()=> {
    batch(()=>{
      dispatch(fetchAllPOSUsers());
      dispatch(fetchAllTerminals(1, 10));
      dispatch(fetchAllVendors());
    })
    
  }, [])
  const props = useSelector(state => state);
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);
  useEffect(()=> {
    const {oVendorReducer: { updateTerminalRequestStatus, updateTerminalRequestMessage } } = props;
   if(updateTerminalRequestStatus === 1) {
     dispatch(fetchAllTerminals(1, 10));
   }
 }, [props])

  const createTerminal = async (data, callback) => {
    let body = {
      merchantId: data.merchantId,
      terminalId: data.terminalId,
      dealerCode: data.dealerCode,
      name: data.terminalName,
      state: data.state,
      address: data.address,
    };
    await dispatch(submitTerminalForm(data.vendor,body));
    callback(true);
  }

  const editTerminal = async (data, callback) => {
    let body = {
      merchantId: data.merchantId,
      terminalId: data.terminalId,
      dealerCode: data.dealerCode,
      name: data.terminalName,
      state: data.state,
      address: data.address
    };
    await dispatch(submitTerminalUpdateForm(body));
    callback(true);
  }
  
  return (
    <div className="dashboard-container">
      {isStatusBarVisible && <StatusBar {...props} isVisible={true} />}

      <TerminalList {...props} onFetchTerminals={(pageNo, size, state, vid) => {
        dispatch(fetchAllTerminals(pageNo, size, state, vid));
      }} onHideStatusBar={status => {
        setIsStatusBarVisible(!status);
      }}
      onCreateTerminal={createTerminal}
      onEditTerminal={editTerminal}
      />
    </div>
  )
}

export default Terminals;