import React from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import {StatusIndicatorSVG} from "../../../assets/Svgs/Svgs";
import {shortMonthNames} from "../../../lib/Functions/DateNames";
import formatAmount from "../../../lib/Functions/formatAmount";

const ReportStatusBar = (props) => {
  const {oTrailReducer, type} = props;
  const { oSettlements: { totalAmount = 0, totalFee = 0, totalCount = 0, totalVolume= 0 } } = oTrailReducer;

  const d = new Date();
  const {
    totalSuccessfulTransactionValue: successfulTransactionAmount = 0,
    totalFailedTransactionValue: failedTransactionAmount = 0,
    totalSuccessCount: successfulTransactionCount = 0,
    totalFailureCount: failedTransactionCount = 0,
    totalSuccessVolume: successfulTransactionVolume = 0.00,
    totalFailureVolume: failedTransactionVolume = 0.00,
  } = oTrailReducer.oTransactions;

  return (
    <div className="status-container row">
      <div className="compartment compartment-one col col-2">
        <span className="title d-block mb-3 mt-2 mr-3">Reporting</span>
        <span className="sub-title" style={{color: "#C77510"}}>
          Summary of your transactions updated daily.
        </span>
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
      </div>

      <div className="compartment report-compartment-two col-3">
        <div className="p-4">
          <span className="sub-title mb-2 text-dark font-weight-bold">
            Total value of successful transactions
          </span>
          <span className="count">₦ {type === 'settlement'? formatAmount(totalAmount * 100) :formatAmount(successfulTransactionAmount * 100) || 0}</span>
          <span className="sub-title text-body font-weight-bold d-inline">
            Number of successful transactions
          </span>
          <span className="report-white-bg-btn d-inline ml-4">
            {type === 'settlement'? totalCount: successfulTransactionCount}
          </span>
        </div>
      </div>

      {!(type === 'settlement') && <div className="compartment report-compartment-two-orange col-3">
        <div className="p-4">
          <span className="sub-title mb-2 text-dark font-weight-bold">
            Total value of unsuccessful transactions
          </span>
          <span className="count">₦ {formatAmount(failedTransactionAmount)}</span>
          <span className="sub-title text-body font-weight-bold d-inline">
            Number of unsuccessful transactions
          </span>
          <span className="report-white-bg-btn-orange d-inline ml-4">{failedTransactionCount}</span>
        </div>
      </div>}

      {(type === 'settlement') && <div className="compartment report-compartment-two-orange col-3">
        <div className="p-4">
          <span className="sub-title mb-2 text-dark font-weight-bold">
            Total value of transaction fee
          </span>
          <span className="count">₦ {formatAmount(totalFee * 100)}</span>
          <span className="sub-title text-body font-weight-bold d-inline">
            Number of Transaction charges
          </span>
          <span className="report-white-bg-btn-orange d-inline ml-4">{totalCount}</span>
        </div>
      </div>}

      <div className="compartment report-compartment-two col-4">
        <div className="pr-4 pl-4 pt-4 pb-3">
          <span className="sub-title d-block mb-3 text-dark font-weight-bold">
            Total Transaction Volume (Litres)
          </span>
          <div className="row">
            <div className="col-6" style={{boxShadow: "none"}}>
              <span className="f-13">
                <StatusIndicatorSVG color={"rgba(17, 176, 26, 0.7)"} />{" "}
                Successful
              </span>
              <span
                className="report-white-bg-btn mt-2"
                style={{paddingLeft: 8, paddingRight: 8, minWidth: 30}}
              >
                {type === 'settlement'? formatAmount(totalVolume * 100): formatAmount(successfulTransactionVolume * 100)}
              </span>
            </div>
            {!(type === 'settlement') &&<div className="col-6">
              <span className="f-13">
                <StatusIndicatorSVG color={"#EE312A"} /> Unsuccessful
              </span>
              <span
                className="report-white-bg-btn mt-2"
                style={{paddingRight: 8, paddingLeft: 8, minWidth: 3}}
              >
                {formatAmount(failedTransactionVolume * 100)}
              </span>
            </div> }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportStatusBar;
