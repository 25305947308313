import React from "react";
import {useState} from "react";
import "../../assets/Styles/Dashboard.css";

const Pagination = ({count, pageSize, fetchMethod}) => {
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / pageSize);

  let pageArray = [];
  var i;
  for (i = pageNo; i < pageNo + 5; i++) {
    if (i <= totalPages) {
      pageArray.push(i);
    }
  }

  const pages = pageArray.map((value, key) => (
    <div
      key={key}
      className={`d-inline m-2 page-counter`}
      style={{
        cursor: "pointer",
        backgroundColor: currentPage === value ? "#00425F" : "transparent",
        color: currentPage === value && "#ffffff",
      }}
      onClick={() => {
        setCurrentPage(value);
        fetchMethod(value, pageSize);
      }}
    >
      {value}
    </div>
  ));

  const lastPage = (
    <div
      className={`d-inline m-2 page-counter`}
      style={{cursor: "pointer"}}
      onClick={() => {
        setCurrentPage(totalPages);
        setPageNo(totalPages - ((totalPages % 5) || 5) + 1);
        fetchMethod(totalPages, pageSize);
      }}
    >
      Last
    </div>
  );

  const prevPages =
    pageArray[0] > 1 ? (
      <div
        className={`d-inline m-2 page-counter`}
        style={{cursor: "pointer"}}
        onClick={() => {
          const i = pageArray[0] - 5 < 1 ? 0 : pageArray[0] - 5;
          setPageNo(i);
          setCurrentPage(i);
          fetchMethod(i, pageSize);
        }}
      >
        {" "}
        {"<"}{" "}
      </div>
    ) : (
      ""
    );

  const nextPages =
    pageArray[4] + 1 <= totalPages ? (
      <div
        className={`d-inline m-2 page-counter`}
        style={{cursor: "pointer"}}
        onClick={() => {
          const current = pageArray[4] + 1;
          setPageNo(current);
          setCurrentPage(current);
          fetchMethod(current, pageSize);
        }}
      >
        {" > "}
      </div>
    ) : (
      ""
    );

  return (
    <div className="pagination-container text-right">
      {prevPages}
      {pages}
      {nextPages}
      {!!count && lastPage}
    </div>
  );
};

export default Pagination;
