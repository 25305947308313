import React, { useState, useEffect } from "react";
// import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { /* Ellipse, */ CloseSVG/* , SearchSvg */ } from "../../../../assets/Svgs/Svgs";
/* import {
  updateCustomerToVendorList,
  resetUpdateCustomerToVendorList
} from "../../../vendorModule/redux/actions/CustomerActions"; */
import { useDispatch } from "react-redux";
// import formatAmount from "../../../../lib/Functions/formatAmount";
// import { submitUserForm } from "../../../../actions/OwnerActions/OwnerCustomerMgtActions/OwnerCustomerActions";
import {
  submitAddProductForm,
  resetAddProd
} from "../../redux/action/OwnerVendorActions";
import AutoCloseModal from "../../../../lib/Functions/AutoCloseModal";

const AddProduct = ({
  onCloseHandler,
  vendorId,
  oVendorReducer,
  contracts,
  products
}) => {
  const dispatch = useDispatch();
  const [formatError, setFormatError] = useState("");
  const [errorType, setErrorType] = useState("error");
  const [name, setName] = useState("");
  const [rate, setRate] = useState("");
  const [product, setProduct] = useState("");

  const contractOptions =
    contracts &&
    contracts.map((val, i) => {
      return (
        <option key={i} value={val.id}>
          {val.name}
        </option>
      );
    });

  const productOptions =
    products &&
    products.map((val, i) => {
      return (
        <option key={i} value={val.id}>
          {val.name}
        </option>
      );
    });

  useEffect(() => {
    if (oVendorReducer.addProdToVenRequestStatus === 1) {
      setFormatError(oVendorReducer.addProdToVenRequestMessage);
      setErrorType("success");
      AutoCloseModal(resetAddProd, true, dispatch);
    } else if (oVendorReducer.addProdToVenRequestStatus === 2) {
      setErrorType("error");
      setFormatError(oVendorReducer.addProdToVenRequestMessage);
    }
  }, [oVendorReducer.addProdToVenRequestStatus]);

  useEffect(() => {
    dispatch(resetAddProd());
  }, []);

  const handleInput = (target, e) => {
    if (target === "rate") {
      setRate(e.target.value);
    }
    if (target === "name") {
      setName(e.target.value);
    }
    if (target === "product") {
      setProduct(e.target.value);
    }
  };

  const validateInputs = () => {
    let isError = false;
    if (name.length === "") {
      setFormatError("Select a contract");
      isError = true;
    } else if (rate === "" || isNaN(rate) || rate < 0) {
      setFormatError("Enter a valid rate to continue!");
      isError = true;
    } else if (product === "") {
      setFormatError("Select a product");
      isError = true;
    }
    return isError;
  };

  const submitForm = () => {
    if (validateInputs()) return;
    dispatch(submitAddProductForm(product, name, rate));
  };

  return (
    <>
      <div className="card-management-row">
        <div
          className="mb-3"
          style={{
            textAlign: "left",
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 10,
            borderBottom: "0.5px solid #5F738C"
          }}
        >
          <h6 className="mt-5 qt-black font-weight-bold text-left d-inline">
            Add product to a contract
          </h6>
          <span
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              onCloseHandler();
              dispatch(resetAddProd());
            }}
          >
            <CloseSVG />
          </span>
        </div>
      </div>
      <div className="col-8 offset-2 mb-5">
        <div className="password">
          <label>Name</label>

          <select
            value={name}
            className="form-control"
            onChange={e => {
              handleInput("name", e);
            }}
          >
            <option>Select contract</option>
            {contractOptions}
          </select>
        </div>
        <div className="password mt-3">
          <label>Product</label>
          
          <select
              value={product}
              className="form-control"
              onChange={e => {
                handleInput("product", e)
              }}
            >
              <option>Select product</option>
              {productOptions}
            </select>
        </div>
        <div>
          <div className="password mt-3">
            <label>Rate</label>
            <div className="form-group">
              <input
                value={rate}
                className="form-control"
                onChange={e => {
                  handleInput("rate", e);
                }}
                placeholder="Enter your rate"
              />
            </div>
          </div>
          <div>
            {!!formatError && (
              <div className="col-12 pb-3 pl-0 pr-0">
                <div className={`${errorType}-container ${errorType}-text `}>
                  <span
                    className="error-close"
                    onClick={() => setFormatError("")}
                  >
                    x
                  </span>
                  <span className={``}>{formatError}</span>
                </div>
              </div>
            )}
          </div>
          <div
            className="create-btn text-right mb-5"
            style={{ position: "relative", bottom: 0, flight: "right" }}
          >
            <button
              onClick={() => {
                submitForm();
              }}
              style={{ padding: "0.5rem 2rem 0.5rem 2rem", marginLeft: 0 }}
            >
              {oVendorReducer.startAddProductToVendorRequest ? (
                <img
                  src="/images/ActivityIndicator.gif" alt="activity-indicator"
                  style={{ height: 20, width: 20, marginTop: 0 }}
                />
              ) : (
                "Add Product"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
