import {
  ADD_VENDOR,
  GET_SUBSCRIBED_VENDORS,
  SELECTED_VENDOR,
  SELECTED_VENDOR_LOCATIONS,
} from '../../modules/customerModule/redux/action/VendorMgtConstants';
// import { fetchAllCardsByScheme } from '../../actions/CustomerActions/CardMgtActions/CardAction';

const initialState = {
  allVendors: [],
  oneVendor: {},
  selectedVendorLocations: [],
  fetching:false,
  fetched:true,
  allCustomerVendors:[],
  customerAgreedVendors:[],
  allPosUser: [],

};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_VENDOR:
      return Object.assign({}, state, 
      //   {
      //   customerVendors: action.vendors,
      // }
      );
    case GET_SUBSCRIBED_VENDORS:
      return Object.assign({}, state, 
      //   {
      //   customerVendors: action.vendors,
      // }
      );
    case SELECTED_VENDOR:
      return Object.assign({}, state, 
      //   {
      //   selectedVendor: action.vendor,
      // }
      );
    case SELECTED_VENDOR_LOCATIONS:
      return Object.assign({}, state, 
      //   {
      //   selectedVendorLocations: action.locations,
      // }
      );
      case 'GET_CUSTOMER_VENDORS_START': {
        return { ...state, fetching: true };
      }
      case 'GET_CUSTOMER_VENDORS_REJECTED': {
        return { ...state, fetching: false, error: action.payload };
      }
      case 'GET_CUSTOMER_VENDORS_FULFILLED': {
        return { ...state, fetching: false, fetched: true, allCustomerVendors: action.payload };
      }

      case 'GET_SELECTED_VENDORS_START': {
        return { ...state, fetching: true };
      }
      case 'GET_SELECTED_VENDORS_REJECTED': {
        return { ...state, fetching: false, error: action.payload };
      }
      case 'GET_SELECTED_VENDORS_FULFILLED': {
        return { ...state, fetching: false, fetched: true, oneVendor: action.payload };
      }


      case 'FETCH_All_CUSTOMER_AGREED_VENDORS_START': {
        return { ...state, fetching: true };
      }
      case 'FETCH_All_CUSTOMER_AGREED_VENDORS_REJECTED': {
        return { ...state, fetching: false, error: action.payload };
      }
      case 'FETCH_All_CUSTOMER_AGREED_VENDORS_FULLFILLED': {
        return { ...state, fetching: false, fetched: true, customerAgreedVendors: action.payload };
      }


      
      case 'FETCH_All_VENDORS_START': {
        return { ...state, fetching: true };
      }
      case 'FETCH_All_VENDORS_FULLFILLED': {
        return { ...state, fetching: false, error: action.payload };
      }
      case 'FETCH_All_VENDORS_REJECTED': {
        return { ...state, fetching: false, fetched: true, allVendors: action.payload };
      }

    // default:fetchAllCardsByScheme
    default: 
    return state;
  }
};
