// this file handles all maker-checker approval/rejection actions
import axios from 'axios';
import { customConfig } from './action_constants';
import { authHeader } from '../helpers/authHeader';
const API_URL = customConfig.API_URL;

export function fetchPendingRequests() {
  return dispatch => {
    dispatch({ type: 'FETCH_PENDING_REQUESTS_START'});

    axios
      .get(`${API_URL}api/request/pending`, { headers: authHeader() })
      .then(
        res => {
          const isError = res.status !== 200;
          dispatch({
            type: `FETCH_PENDING_REQUESTS_${isError ? 'REJECTED' : 'FULFILLED'}`, 
            payload: { data: (isError ? [] : res.data.data), message: '' }
          });
        },
        err => {
          const message = (err.response && err.response.data.description) || 'Unable to fetch pending-requests';
          dispatch({ type: 'FETCH_PENDING_REQUESTS_REJECTED', payload: { data: [], message } })
        }
      )
  };
}

export function rejectRequest(requestId) {
  return dispatch => {
    dispatch({ type: 'REQUEST_DECISION_SUBMIT_START'});

    axios
      .put(`${API_URL}api/request/reject?requestId=${requestId}`, {}, { headers: authHeader() })
      .then(
        res => {
          console.log(`results: ${JSON.stringify(res.data)}`)
          const isError = res.status !== 200 || res.data.status.code !== 0;
          dispatch({
            type: `REQUEST_DECISION_SUBMIT_${isError ? 'REJECTED' : 'FULFILLED'}`, 
            payload: { message: res.data.status.description || '' }
          });
        },
        err => {
          const message = (err.response && err.response.data.status.description) || 'Something went wrong';
          dispatch({ type: 'REQUEST_DECISION_SUBMIT_REJECTED', payload: { message } })
        }
      );
  };
}

export function approveProductRateChange(requestId) {
  return dispatch => {
    dispatch({ type: 'REQUEST_DECISION_SUBMIT_START'});

    axios
      .put(`${API_URL}api/contract/update/product/approve?requestId=${requestId}`, {}, { headers: authHeader() })
      .then(
        res => {
          const isError = res.status !== 200 || res.data.status.code !== 0;
          dispatch({
            type: `REQUEST_DECISION_SUBMIT_${isError ? 'REJECTED' : 'FULFILLED'}`, 
            payload: { message: res.data.status.description || '' }
          });
        },
        err => {
          const message = (err.response && err.response.data.description) || 'Error occurred while registering the approval';
          dispatch({ type: 'REQUEST_DECISION_SUBMIT_REJECTED', payload: { message } })
        }
      );
  };
}

export function approveContractLimitChange(requestId) {
  return dispatch => {
    dispatch({ type: 'REQUEST_DECISION_SUBMIT_START'});

    axios
      .put(`${API_URL}api/contract/update/limit/approve?requestId=${requestId}`, {}, { headers: authHeader() })
      .then(
        res => {
          const isError = res.status !== 200 || res.data.status.code !== 0;
          dispatch({
            type: `REQUEST_DECISION_SUBMIT_${isError ? 'REJECTED' : 'FULFILLED'}`, 
            payload: { message: res.data.status.description || '' }
          });
        },
        err => {
          const message = (err.response && err.response.data.description) || 'Error occurred while registering the approval';
          dispatch({ type: 'REQUEST_DECISION_SUBMIT_REJECTED', payload: { message } })
        }
      );
  };
}

export function approveAddContractRequest(requestId) {
  return dispatch => {
    dispatch({ type: 'REQUEST_DECISION_SUBMIT_START'});

    axios
      .put(`${API_URL}api/add/contract/approve?requestId=${requestId}`, {}, { headers: authHeader() })
      .then(
        res => {
          const isError = res.status !== 200 || res.data.status.code !== 0;
          dispatch({
            type: `REQUEST_DECISION_SUBMIT_${isError ? 'REJECTED' : 'FULFILLED'}`, 
            payload: { message: res.data.status.description || '' }
          });
        },
        err => {
          const message = (err.response && err.response.data.description) || 'Error occurred while registering the approval';
          dispatch({ type: 'REQUEST_DECISION_SUBMIT_REJECTED', payload: { message } })
        }
      );
  };
}

export function approveAddContractProductRateRequest(requestId) {
  return dispatch => {
    dispatch({ type: 'REQUEST_DECISION_SUBMIT_START'});

    axios
      .put(`${API_URL}api/add/contract/approve?requestId=${requestId}`, {}, { headers: authHeader() })
      .then(
        res => {
          const isError = res.status !== 200 || res.data.status.code !== 0;
          dispatch({
            type: `REQUEST_DECISION_SUBMIT_${isError ? 'REJECTED' : 'FULFILLED'}`, 
            payload: { message: res.data.status.description || '' }
          });
        },
        err => {
          const message = (err.response && err.response.data.description) || 'Error occurred while registering the approval';
          dispatch({ type: 'REQUEST_DECISION_SUBMIT_REJECTED', payload: { message } })
        }
      );
  };
}

export function approveProductRequest(requestId) {
  return dispatch => {
    dispatch({ type: 'REQUEST_DECISION_SUBMIT_START'});

    axios
      .put(`${API_URL}api/add/product/approve?requestId=${requestId}`, {}, { headers: authHeader() })
      .then(
        res => {
          const isError = res.status !== 200 || res.data.status.code !== 0;
          dispatch({
            type: `REQUEST_DECISION_SUBMIT_${isError ? 'REJECTED' : 'FULFILLED'}`, 
            payload: { message: res.data.status.description || '' }
          });
        },
        err => {
          const message = (err.response && err.response.data.description) || 'Error occurred while registering the approval';
          dispatch({ type: 'REQUEST_DECISION_SUBMIT_REJECTED', payload: { message } })
        }
      );
  };
}

export function approveRequest(requestId){
  return function(dispatch){
    dispatch({ type: 'REQUEST_DECISION_SUBMIT_START'})
    axios.put(`${API_URL}api/request/approve?requestId=${requestId}`,{}, { headers: authHeader() }).
    then(res => {
      const isError = res.status !== 200 || res.data.status.code !== 0;
      dispatch({
        type: `REQUEST_DECISION_SUBMIT_${isError ? 'REJECTED' : 'FULFILLED'}`, 
        payload: { message: res.data.status.description || '' }
      });
    })
    .catch(err => {
      const message = (err.response && err.response.data.status.description) || 'Error occurred while registering the approval';
          dispatch({ type: 'REQUEST_DECISION_SUBMIT_REJECTED', payload: { message } })
    });
  }
}
