import React from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import {shortMonthNames} from "../../../lib/Functions/DateNames";

const StatusBar = (props) => {
  const d = new Date();
  const {oVendorReducer} = props;
  const { vendorProduct } = oVendorReducer;
  let data = vendorProduct.data || [];
  let pms = 0,
    ago = 0,
    dpk = 0,
    others = 0,
    total = vendorProduct.count;
  data && data.forEach((dataArr) => {
    switch (dataArr.name) {
      case "AGO":
        ago++;
        break;
      case "PMS":
        pms++;
        break;
      case "DPK":
        dpk++;
        break;
      default:
        others++;
    }
  });

  return (
    <div className="status-container row">
      <div className="compartment compartment-one col col-2">
        <span className="title d-block mt-3 mb-3 mr-3">Products</span>
        <span className="sub-title">Summary of all Vendor products</span>
        <br />
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
      </div>

      <div className="compartment compartment-two col-10">
        <div className="row token-status-box">
          <div
            className="col col-12"
            style={{
              position: "relative",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 18,
            }}
          >
            <div className="row mb-3">
              <span className="title">Product</span>
            </div>
            <div className="row status-sub-title text-center">
              <div className="col-3">
                TOTAL
                <div
                  className="short-borders"
                  style={{right: 0, top: -10}}
                ></div>
              </div>
              <div className="col-2">
                PMS
                <div
                  className="short-borders"
                  style={{right: 0, top: -10}}
                ></div>
              </div>
              <div className="col-2">
                DPK
                <div
                  className="short-borders"
                  style={{right: 0, top: -10}}
                ></div>
              </div>
              <div className="col-2">
                AGO
                <div
                  className="short-borders"
                  style={{right: 0, top: -10}}
                ></div>
              </div>
              <div className="col-3">OTHERS</div>
            </div>
            <div className="row status-values text-center">
              <div className="col-3">{total || "0"}</div>
              <div className="col-2">{pms || "0"}</div>
              <div className="col-2">{dpk || "0"}</div>
              <div className="col-2">{ago || "0"}</div>
              <div className="col-3">{others || "0"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusBar;
