import React, {useEffect, useState} from "react";
import "../../../assets/Styles/Dashboard.css";
import { useDispatch } from "react-redux";
import {ExportSVG, CreateSVG} from "../../../assets/Svgs/Svgs";
import {CSVLink} from "react-csv";
import { Popover } from "antd";
import {shortMonthNames, shortDayNames} from "../../../lib/Functions/DateNames";
import formatAmount from "../../../lib/Functions/formatAmount";
import toSentenceCase from "../../../lib/Functions/toSentenceCase";
import Pagination from "../../../lib/Components/Pagination";
import ToastAlert from "../../../lib/Components/ToastAlert";
import CreateProduct from "./CreateProduct";
import ProductDetails from "./ProductDetails";
import AddProduct from "./AddProduct";

const ProductList = (props) => {
  const {
    oVendorReducer, 
    onFetchProducts, 
    makerCheckerRequests, 
    currentUserId, 
    isAdmin,
    onFetchPendingRequest,
    onApproveProductRequest,
    onRejectRequest,
    pageSize,
  } = props;
  const {data=[], count} = oVendorReducer.vendorProduct || {};
  const [showProductDetails, setshowProductDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isCreateProduct, setIsCreateProduct] = useState(false);
  const [isAddProduct, setIsAddProduct] = useState(false);
  const [isEditProduct, setIsEditProduct] = useState(false);

  const [decisionSubmitResponse, setDecisionSubmitResponse] = useState({});
  const dispatch = useDispatch();
  
  useEffect(() => {
    setDecisionSubmitResponse(makerCheckerRequests.decisionSubmitResponse);
    onFetchPendingRequest();
  }, [makerCheckerRequests.decisionSubmitResponse]);

  const ProductLists = data && data.map((value) => {
      const {id, name, number, rate, createdAt} = value;
      const d = new Date(createdAt);

      return (
        <tr key={id}>
          <td className="table-description font-weight-bold">{name}</td>
          <td className="table-description font-weight-bold">{number}</td>
          <td className="table-description font-weight-bold">
            ₦ {formatAmount(rate * 100)}
            {/* amount in naira already */}
          </td>
          <td className="table-description">
            {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
            {shortMonthNames[d.getMonth()]} {d.getFullYear()}
          </td>
          <td
            className="table-action"
            onClick={() => {
              setshowProductDetails(true);
              setSelectedProduct(value);
              props.onHideStatusBar(true);
            }}
          >
            View
          </td>
        </tr>
      );
    });

  const pendingRequestsToBeTreated = (makerCheckerRequests.pendingRequests && makerCheckerRequests.pendingRequests
    .filter(pr => (pr.requestedby !== currentUserId && ['ADD_PRODUCT', 'UPDATE_PRODUCT'].includes(pr.requestType)))) || [];
  
  return (
    <div className="disbursed-cards-container row">
      {!isCreateProduct && !isEditProduct && !showProductDetails && !isAddProduct && (
        <>
          <div
            className="menu-link"
            style={{marginBottom: 0, paddingBottom: 4}}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid",
              }}
            >
              All Products
            </span>
          </div>
          <div
            className="create-new-tags-btn"
            onClick={() => {
              props.onHideStatusBar(true);
              setIsCreateProduct(true);
            }}
          >
            <span className="align-middle">
              <CreateSVG /> Create new product
            </span>
          </div>
          <div
            className="contract-export-btn"
            style={{right: 190, float: "right", backgroundColor: "#7F91A8"}}
            onClick={() => {
              props.onHideStatusBar(true);
              setIsAddProduct(true);
            }}
          >
            <span className="align-middle">Add product to contract</span>
          </div>

          {!!data && (
            <CSVLink
              data={data}
              filename={"Products.csv"}
              className=""
              target="_blank"
            >
              <div
                className="contract-export-btn"
                style={{float: "right", right: 370}}
              >
                <span className="text-white">
                  Export CSV <ExportSVG />
                </span>
              </div>
            </CSVLink>
          )}

          {!makerCheckerRequests.isSubmittingDecision && (<div className="row"><div className="col-12">
            <ToastAlert
              showToast={!!decisionSubmitResponse.message}
              toastText={decisionSubmitResponse.message || ''}
              parentClass={`mt-2 d-block clear-both bg-${decisionSubmitResponse.isError ? 'warning' : 'success'}`}
              childClass='text-white'
              onCloseHandler={() => dispatch({ type: 'REQUEST_DECISION_SUBMIT_RESET' })}
            />
          </div></div>)}

          {isAdmin && (pendingRequestsToBeTreated.length !== 0) && (<table className='table table-hover table-sm mb-3'>
            <thead><tr><td className="p-1 bg-secondary text-warning" colSpan="3">Pending Requests</td></tr></thead>
            <tbody>
              {pendingRequestsToBeTreated.map((r, key) => {
                const requestDataObj = JSON.parse(r.requestData);

                return (<tr key={key}>
                  <td className="p-1">
                    <span className="font-weight-bold">{r.requestType.replaceAll('_', '-')}</span> &mdash;&nbsp; 
                    {r.requestInfo}&nbsp; 
                    [<span className="font-weight-bold">{requestDataObj.name}</span>]
                  </td>
                  <td className="pb-0 pt-1 text-right">
                    <Popover 
                      content={
                        <div>
                          {Object.keys(requestDataObj).map((k, i) => {
                            if (['id', 'number', 'createdat', 'updatedat', 'created_at', 'updated_at'].includes(k) || !requestDataObj[k]) return null;

                            return <p key={i} className="pt-0 pb-0">
                              <span className="font-weight-bold mr-2">{toSentenceCase(k)}:</span>{requestDataObj[k]}
                            </p>;
                          })}
                        </div>
                      }
                      title="Details" 
                      trigger="click">
                      <i 
                        className="material-icons text-primary" 
                        title="Details" style={{cursor: 'pointer'}}>info</i>
                      {/* {JSON.stringify(JSON.parse(r.requestData), null, 2)} */}
                    </Popover>
                  </td>
                  <td className="pb-0 pt-1 pr-1 pl-1 text-right">
                    <div className="btn-group btn-group-sm" role="group">
                      <button type="button" className="btn pb-0" style={{background:'#00425f'}}
                        onClick={() => onApproveProductRequest(r.id)}>
                        <i className="material-icons text-white" style={{fontSize: '18px'}}>done</i>
                      </button>
                      <button type="button" title="Decline" className="btn btn-danger pb-0" 
                        onClick={() => onRejectRequest(r.id)}>
                        <i className="material-icons text-white" style={{fontSize: '18px'}}>close</i>
                      </button>
                    </div>
                  </td>
                </tr>)
              })}
            </tbody>
          </table>)}

          <table className="table table-hover">
            <thead>
              <tr>
                <td>Name</td>
                <td>Product ID</td>
                <td>Price</td>
                <td>Date Created</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {!!data.length && ProductLists}
              {!data.length && (
                <tr>
                  <td colSpan={7} className="text-center">
                    No product to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            count={count}
            pageSize={pageSize}
            fetchMethod={(pageNo, pageSize) => {
              onFetchProducts(pageNo, pageSize);
            }}
          />
        </>
      )}

      {showProductDetails && (
        <ProductDetails
          {...props}
          selectedProduct={selectedProduct}
          editProduct={() => {
            setshowProductDetails(false);
            setIsEditProduct(true);
            setIsCreateProduct(false);
          }}
          backFunction={() => {
            setSelectedProduct({});
            setshowProductDetails(false);
            props.onHideStatusBar(false);
          }}
        />
      )}

      <CreateProduct
        {...props}
        isCreateProduct={isCreateProduct}
        selectedProduct={selectedProduct}
        isEditProduct={isEditProduct}
        onBackHandler={() => {
          setIsCreateProduct(false);
          setIsEditProduct(false);
          setshowProductDetails(false);
          props.onHideStatusBar(false);
        }}
      />

      {isAddProduct && <AddProduct
        {...props}
        selectedProduct={selectedProduct}
        onCloseHandler={() => {
          setIsCreateProduct(false);
          setIsEditProduct(false);
          setIsAddProduct(false);
          setshowProductDetails(false);
          props.onHideStatusBar(false);
        }}
        contracts={oVendorReducer.oneVendor?.data?.contracts}
        products={data}
      />}
    </div>
  );
};

export default ProductList;
