import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {/* Ellipse, */ CloseSVG/* , SearchSvg */} from "../../../../assets/Svgs/Svgs";
import {
  updateCustomerToVendorList,
  resetUpdateCustomerToVendorList,
} from "../../../vendorModule/redux/actions/CustomerActions";
import AutoCloseModal from "../../../../lib/Functions/AutoCloseModal";

const AddCustomer = ({
  onCloseHandler,
  vendorId,
  vendorCustomer,
}) => {
  const dispatch = useDispatch();
  
  const allCustomers = useSelector(({vendorCustomer}) => vendorCustomer.allCustomers);

  useEffect(() => {
    if (vendorCustomer.addCustomerToVendorStatus === 1) {
      // setShowCreateSuccessModal(true);
      setFormatError(vendorCustomer.addCustomerToVendorMessage);
      setErrorType("success");
      AutoCloseModal(resetUpdateCustomerToVendorList, true, dispatch);
    } else if (vendorCustomer.addCustomerToVendorFailedStatus === 2) {
      setFormatError(vendorCustomer.addCustomerToVendorFailedMessage);
      setErrorType("error");
    } else {
      setFormatError("");
    }
  }, [
    vendorCustomer.addCustomerToVendorStatus,
    vendorCustomer.addCustomerToVendorFailedStatus,
  ]);

  useEffect(() => {
    dispatch(resetUpdateCustomerToVendorList());
  }, []);

  const customersOptions = allCustomers.map((val, i) => {
    return (
      <option key={i} value={val.id}>
        {val.name}
      </option>
    );
  });

  const [formatError, setFormatError] = useState("");
  const [errorType, setErrorType] = useState("error");
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  return (
    <>
      <div className="card-management-row">
        <div
          className="mb-3"
          style={{
            textAlign: "left",
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 10,
            borderBottom: "0.5px solid #5F738C",
          }}
        >
          <h6 className="mt-5 qt-black font-weight-bold text-left d-inline">
            Add a customer to vendor
          </h6>
          <span
            style={{float: "right", cursor: "pointer"}}
            onClick={onCloseHandler}
          >
            <CloseSVG />
          </span>
        </div>
      </div>
      <div className="col-8 offset-2 mb-5">
        <div className="password">
          <label>Choose a customer from the drop down</label>
          <div className="form-group">
            <select
              value={selectedCustomerId}
              className="form-control"
              onChange={(e) => {
                setSelectedCustomerId(e.target.value);
              }}
            >
              <option>Search a customer here...</option>
              {customersOptions}
            </select>
            {/* <i className="input-icon" style={{    right: "0.5rem", position: "absolute", top: "1.55rem"}}>
                <SearchSvg color="#000" />
              </i> */}
          </div>
          {!!formatError && (
            <div className="col-12 pb-3 pl-0 pr-0">
              <div className={`${errorType}-container ${errorType}-text `}>
                <span
                  className="error-close"
                  onClick={() => setFormatError("")}
                >
                  x
                </span>
                <span className={``}>{formatError}</span>
              </div>
            </div>
          )}
        </div>
        <div
          className="create-btn text-right mb-5"
          style={{position: "relative", bottom: 0, flight: "right"}}
        >
          <button
            onClick={() => {
              if (selectedCustomerId === "") {
                setFormatError("Select a customer to continue!");
                return;
              }
              dispatch(
                updateCustomerToVendorList(vendorId, selectedCustomerId)
              );
            }}
            style={{padding: "0.5rem 2rem 0.5rem 2rem", marginLeft: 0}}
          >
            {vendorCustomer.isUpdatingCustomerToVendor ? (
              <img
                src="/images/ActivityIndicator.gif" alt="activity-indicator"
                style={{height: 20, width: 20, marginTop: 0}}
              />
            ) : (
              "Add Customer"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
