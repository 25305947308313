import React, {useEffect, useState} from "react";
import { Popconfirm } from "antd";
import {
  BackSVG,
  ActiveMarkSVG,
  UserTransactionSVG,
  AccountSvg,
  DisabledMark,
} from "../../../assets/Svgs/Svgs";
import {useDispatch} from "react-redux";
import ToastAlert from "../../../lib/Components/ToastAlert";
import { ACTIVE, EXPIRED, TERMINATED } from "../../../lib/Constants/ContractStates";
import formatAmount from "../../../lib/Functions/formatAmount";
import toSentenceCase from "../../../lib/Functions/toSentenceCase";
import { isInThePast } from "../../../helpers/misc";

const ContractDetails = (props) => {
  const {
    selectedContract, onToggleContract, isTogglingContract, isAdmin,
    onRequestProductRateChange, isRequestingCPPriceChange, changeProductPriceResponse,
    onRequestContractLimitChange, isRequestingCLChange, changeContractLimitResponse,
    onEditContractClick, makerCheckerRequests, currentUserId,
    onApproveProductRateChange, onApproveContractLimitChange, onRejectRequest, 
    isSubmittingDecision, decisionSubmitResponse,
  } = props;

  const {
    bound, contractCycleType, contractCycleTypeDays, autoRenew, contractStatus, id: contractId,
    contractStopDate
  } = selectedContract;

  const [selectedProduct, setSelectedProduct] = useState({});
  const [newProductRate, setNewProductRate] = useState(0.00)
  const [isPREmode, setIsPREmode] = useState(false);// PRE = product-rate-edit

  const [newContractLimit, setNewContractLimit] = useState(0)
  const [isCLEmode, setIsCLEmode] = useState(false);// CLE = contract-limit-edit
  const dispatch = useDispatch();

  const isActive = (contractStatus === ACTIVE);
  const isTerminatedOrExpired = contractStatus === TERMINATED || contractStatus === EXPIRED;
  const isSupposedTobeExpired = !isActive && !isTerminatedOrExpired && isInThePast(contractStopDate);

  const spinnerLayout = (<span className="spinner-border spinner-border-sm text-light" role="status"><span className="sr-only">Loading...</span></span>);

  useEffect(() => {
    setIsPREmode(changeProductPriceResponse?.isError);
  }, [changeProductPriceResponse]);

  useEffect(() => {
    setIsCLEmode(changeContractLimitResponse?.isError);
  }, [changeContractLimitResponse]);

  const pendingRequestsToBeTreated = (makerCheckerRequests.pendingRequests && makerCheckerRequests.pendingRequests
    .filter(pr => {
      const parsedRequestData = JSON.parse(pr.requestData);
      return (pr.requestedby !== currentUserId &&
        (
          selectedContract.contractReferenceId === parsedRequestData?.contract?.contractReferenceId ||
          selectedContract.contractReferenceId === parsedRequestData?.contractReferenceId ||
          selectedContract.contractNumber === parsedRequestData?.contract?.contractNumber || // UPDATE_CONTRACT_PRODUCT_RATE
          selectedContract.contractNumber === parsedRequestData?.contractNumber // UPDATE_CONTRACT_LIMIT
        ) &&
        ['UPDATE_CONTRACT_PRODUCT_RATE', 'UPDATE_CONTRACT_LIMIT'].includes(pr.requestType))
    })) || [];

  const updateContractLimitRequests = [], updateContractProductRateRequests = [];
  pendingRequestsToBeTreated.forEach((pr, index) => {
    const oPR = {...pr, requestData: JSON.parse(pr.requestData)}
    if (pr.requestType === 'UPDATE_CONTRACT_PRODUCT_RATE') updateContractProductRateRequests.push(oPR)
    else updateContractLimitRequests.push(oPR);
  });

  let productContractRates = 
    <><tbody>{selectedContract.productContractRates.length !== 0
      ? selectedContract.productContractRates.map(
          //(item) => `${item.product.name}: ₦${formatAmount(item.product.rate * 100)}`
          (item) => {
            const isSelected = (item.product.id === selectedProduct.id);

            return <tr key={item.id}>
              <td className="p-2 font-weight-bold">{item.product.name}</td>
              <td className="p-2 text-success">
                {formatAmount((item.discount * 100) || 0)}%
              </td>
              <td className={`${(isPREmode && isSelected) ? 'pt-0 pr-0 pb-0 pl-2' : 'p-2'} text-danger`}>
                {isPREmode && isSelected 
                ? <div className="input-group input-group-sm">
                  <input type="text" className="form-control mt-0" defaultValue={item.discount} onChange={(e) => {
                    setNewProductRate(e.target.value)
                  }} disabled={isRequestingCPPriceChange} />
                  <div className="input-group-append">
                    <button className="btn btn-danger btn-sm" style={{paddingBottom: 0}} onClick={() => {
                      onRequestProductRateChange(contractId, selectedProduct.id, newProductRate)
                    }} disabled={isRequestingCPPriceChange}>
                      {isRequestingCPPriceChange 
                        ? spinnerLayout 
                        : <i className="material-icons" style={{fontSize: '18px'}}>done</i>
                      }
                    </button>
                    <button className="btn btn-sm" style={{background:'#00425f', paddingBottom: 0}} onClick={() => {
                      setIsPREmode(prevState => !prevState)
                    }} disabled={isRequestingCPPriceChange}>
                      <i className="material-icons text-white" style={{fontSize: '18px'}}>close</i>
                    </button>
                  </div>
                </div> 
                : <>{formatAmount(((isSelected && newProductRate) || item.discount) * 100)}%{' '}
                  {isAdmin && (updateContractProductRateRequests.length === 0) ? (!isRequestingCPPriceChange && 
                  <i className="material-icons float-right" title={`Edit ${item.product.name} Rate`} 
                    style={{ cursor: 'pointer', fontSize: '14px', color:'#00425f' }} onClick={() => {
                    setSelectedProduct(item.product);
                    setIsPREmode(true);
                  }}>edit</i>) : ''}</>
                }
              </td>
            </tr>
          })
      : <tr><td className="p-2">---</td></tr>
    }</tbody></>;

  return (
    <>
      <div className="underlined-tab" /* style={{marginRight: 30}} */>
        <span className="tab-title">Contract Details</span>
        <div className="action-buttons">
          <button
            className="export-btn"
            style={{marginRight: 0}}
            onClick={() => {
              props.backFunction();
            }}
          >
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className=" customer-details" style={{width: "100%"}}>
        <div className="row pl-3" style={{marginRight: "-30px"}}>
        <div className="col-12">
          <>
            <div className="row user-info">
              <div className="row user-info-title">
                <div className="col-6">
                  <div className="row">
                    <div className="col-5 text-center mt-4">
                      <AccountSvg height={62} width={62} />
                    </div>
                    <div className="col-7 pt-4">
                      <div className="d-inline-block">
                        <div className=" user-info-title font-weight-bold">
                          <div>Vendor</div>
                        </div>
                        <div className=" user-info-details mt-2">
                          <div
                            style={{
                              color: "#000000",
                              fontSize: 28,
                              fontWeight: 600,
                            }}
                          >
                            {selectedContract.vendorSignature?.split(" ")[0] ||
                              "Unavailable"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="short-borders"></div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-3 text-center mt-4"></div>
                    <div className="col-9 pt-4">
                      <div className="d-inline-block">
                        <div className=" user-info-title font-weight-bold">
                          <div>Customer</div>
                        </div>
                        <div className=" user-info-details mt-2">
                          <div
                            style={{
                              color: "#000000",
                              fontSize: 28,
                              fontWeight: 600,
                            }}
                          >
                            {selectedContract.customerSignature?.split(
                              " "
                            )[0] || "Unavailable"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row user-overview mt-3"
              style={{padding: "20px 80px"}}
            >
              <span className="overview-title" style={{marginBottom: 20}}>
                Contract Overview
              </span>
              <div
                className="inner pr-5 pb-5"
                style={{paddingLeft: "50px", borderRadius: 6}}
              >
                <div className="row mb-3 ml-0 mr-0">
                  <div className="col-12">
                    <UserTransactionSVG />{" "}
                    <span style={{color: "#000", fontSize: 18, fontWeight: 900}} >
                      {selectedContract.name}
                    </span>
                    {isAdmin && <i className="material-icons clear-both text-danger float-right" title={`Edit Contract`} 
                      style={{ cursor: 'pointer', fontSize: '18px' }} 
                      onClick={onEditContractClick}>edit</i>}
                  </div>
                </div>
                <>
                  <div className="row user-address-title bold ml-0 mr-0">
                    <div className="col-4">Contract Limit</div>
                    <div className="col-2">Contract Cycle</div>
                    <div className="col-2">Auto-Renew?</div>
                    <div className="col-4">Vendor Signature</div>
                  </div>
                  <div className="row user-address-details mt-2 ml-0 mr-0">
                    <div className="col-4">
                      {isCLEmode 
                      ? <div className="input-group input-group-sm">
                        <input type="text" className="form-control mt-0" defaultValue={newContractLimit} onChange={(e) => {
                          setNewContractLimit(e.target.value)
                        }} disabled={isRequestingCLChange} />
                        <div className="input-group-append">
                          <button className="btn btn-danger btn-sm pb-0" style={{paddingBottom: 0}} onClick={() => {
                            onRequestContractLimitChange(contractId, newContractLimit);
                          }} disabled={isRequestingCLChange}>
                            {isRequestingCLChange 
                              ? spinnerLayout 
                              : <i className="material-icons" style={{fontSize: '18px'}}>done</i>
                            }
                          </button>
                          <button className="btn btn-sm pb-0" style={{background:'#00425f', paddingBottom: 0}} onClick={() => {
                            setIsCLEmode(prevState => !prevState)
                          }} disabled={isRequestingCLChange}>
                            <i className="material-icons text-white" style={{fontSize: '18px'}}>close</i>
                          </button>
                        </div>
                      </div> 
                      : <>₦ {formatAmount(bound * 100 || 0)}{' '}
                          {isAdmin && (updateContractLimitRequests.length === 0) && !isRequestingCLChange && 
                          <i className="material-icons float-right" title={`Edit Contract Limit`} 
                            style={{ cursor: 'pointer', fontSize: '14px', color:'#00425f' }} onClick={() => {
                            setNewContractLimit(bound);
                            setIsCLEmode(true);
                          }}>edit</i>}

                          <div className="mt-0">
                            {(changeContractLimitResponse?.message) && 
                              <small style={{fontSize: '11px'}} className={
                                `text-${changeContractLimitResponse.isError ? 'danger': 'success'}`
                              }>
                                {changeContractLimitResponse.message}
                              </small>}
                          </div>
                        </>
                      }

                      {isAdmin && (<table className='table table-hover table-sm mb-0 mt-2'>
                      <thead><tr><td className="p-0 pl-1 pr-1 bg-secondary text-warning">Pending Requests</td></tr></thead>
                      <tbody>
                        {updateContractLimitRequests.map((r, key) => (
                          <tr key={key}>
                            <td className="p-1">
                              <div className="input-group input-group-sm">
                                <input type="text" className="form-control mt-0" readOnly 
                                  value={r.requestData.bound} 
                                  aria-describedby={`button-addon-${key}`} 
                                />
                                <div className="input-group-append" id={`button-addon-${key}`}>
                                  <button 
                                    className="btn pb-0" 
                                    style={{background:'#00425f'}} 
                                    type="button" title="Approve" 
                                    onClick={() => onApproveContractLimitChange(r.id)}>
                                    <i className="material-icons text-white" style={{fontSize: '18px'}}>done</i>
                                  </button>
                                  <button 
                                    className="btn btn-danger pb-0" 
                                    type="button" title="Decline" 
                                    onClick={() => onRejectRequest(r.id)}>
                                    <i className="material-icons text-white" style={{fontSize: '18px'}}>close</i>
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      </table>)}
                    </div>
                    <div className="col-2">
                      {(contractCycleType === "DAYS") && (contractCycleTypeDays || "")}&nbsp;
                      {contractCycleType || "0"}
                    </div>
                    <div className="col-2">{autoRenew ? "Yes" : "No"}</div>
                    <div className="col-4">
                      {selectedContract.vendorSignature || "Nil"}
                    </div>
                  </div>

                  <div className="row user-address-title bold mt-4 ml-0 mr-0">
                    <div className="col-4">Product Discounts</div>
                    <div className="col-4">Status</div>
                    <div className="col-4">Customer Signature</div>
                  </div>
                  <div className="row user-address-details mt-2 ml-0 mr-0">
                    <div className="col-4">
                      <div className="p-1">
                        {(changeProductPriceResponse?.message) && 
                          <small style={{fontSize: '11px'}} className={`text-${changeProductPriceResponse.isError ? 'danger': 'success'}`}>
                            {changeProductPriceResponse.message}
                          </small>}
                      </div>
                      
                      <table className='table table-hover table-sm mb-2'>
                        {productContractRates}
                      </table>
                      
                      {isAdmin && (<table className='table table-hover table-sm mb-0'>
                      <thead><tr><td className="p-0 pl-1 pr-1 bg-secondary text-warning">Pending Requests</td></tr></thead>
                      <tbody>
                        {updateContractProductRateRequests.map((r, key) => (
                          <tr key={key}>
                            <td className="p-1">
                              <div className="input-group input-group-sm">
                                <input type="text" className="form-control mt-0" readOnly 
                                  value={r.requestData.productRate} 
                                  aria-describedby={`button-addon-${key}`} 
                                />
                                <div className="input-group-append" id={`button-addon-${key}`}>
                                  <button 
                                    className="btn pb-0" 
                                    style={{background:'#00425f'}} 
                                    type="button" title="Approve"
                                    onClick={() => onApproveProductRateChange(r.id)}>
                                    <i className="material-icons text-white" style={{fontSize: '18px'}}>done</i>
                                  </button>
                                  <button 
                                    className="btn btn-danger pb-0" 
                                    type="button" title="Decline"
                                    onClick={() => onRejectRequest(r.id)}>
                                    <i className="material-icons text-white" style={{fontSize: '18px'}}>close</i>
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      </table>)}
                    </div>
                    <div className="col-2">
                      {`${toSentenceCase(contractStatus)} `}
                      {isActive ? (<ActiveMarkSVG />) : (<DisabledMark />)}
                    </div>
                    <div className="col-2">
                      {!isTerminatedOrExpired && 
                      <Popconfirm title={`Are you sure about this?`} okText="Yes" cancelText="No"
                        onConfirm={() => onToggleContract(contractId, !(isActive || isSupposedTobeExpired))}>
                        <button 
                          className={`btn btn-sm btn-${isActive || isSupposedTobeExpired ? 'danger': 'success'}`} 
                          disabled={isTogglingContract || (pendingRequestsToBeTreated.length !== 0)}>
                          {(isTogglingContract ? spinnerLayout : (isActive || isSupposedTobeExpired ? 'Terminate': 'Enable'))}
                        </button>
                      </Popconfirm>}
                    </div>
                    <div className="col-4">
                      {selectedContract.customerSignature || "Nil"}
                    </div>
                  </div>
                  
                  <div className="row user-address-title bold mt-4 ml-0 mr-0">
                   <div className="col-4">Contract Available Balance</div>
                  </div>
                  <div className="row user-address-details mt-2 ml-0 mr-0">
                    <div className="col-4">{selectedContract.balance?`₦ ${formatAmount(selectedContract.balance * 100)}`: 'N/A'}`</div>
                  </div>
                  {!isSubmittingDecision && (
                    <ToastAlert
                      showToast={!!decisionSubmitResponse.message}
                      toastText={decisionSubmitResponse.message || ''}
                      parentClass={`mt-2 d-block clear-both bg-${decisionSubmitResponse.isError ? 'warning' : 'success'}`}
                      childClass='text-white'
                      onCloseHandler={() => dispatch({ type: 'REQUEST_DECISION_SUBMIT_RESET' })}
                    />
                  )}
                </>
              </div>
            </div>
          </>
        </div>
        </div>
      </div>
    </>
  );
};

export default ContractDetails;
