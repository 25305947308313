const ownerUserReducer = (
  state = {
    users: [],
    ownerUsers: [],
    allSysUsers: [],
    fetching: false,
    fetched: false,
    error: null,
    bulkCreateModal: false,
    userCreateModal: false,
    bulkUploadModal: false,
    bulkUploadResultModal: false,
    userEditModal: false,
    userDeleteModal: false,
    isCreatingUser: false,
    fetchingStatus: 0,
    userCreationErrorMessage: "",
  },
  action,
) => {
  switch (action.type) { 
    case 'FETCH_USERS': {
      return { ...state, fetching: true };
    }

    case 'TOGGLE_MODAL': {
      return { ...state, userCreateModal: !state.userCreateModal };
    }

    case 'FETCH_USERS_REJECTED': {
      return { ...state, fetching: false, error: action.payload };
    }

    case 'FETCH_USERS_FULFILLED': {
      return { ...state, fetching: false, fetched: true, users: action.response.data };
    }

    case 'ADD_OWNER_USER_START': {
      return {...state, isCreatingUser: true, fetchingStatus: 0}
    }

    case 'ADD_OWNER_USER_SUCCESS': {
      return { 
        ...state,
        isCreatingUser: false,
        // ownerUsers: [...state.ownerUsers, action.payload],
        fetchingStatus: action.response.fetchingStatus
      };
    }

    case 'ADD_OWNER_USER_FAILED': {
      return {
        ...state,
        isCreatingUser: false,
        fetchingStatus: action.response.fetchingStatus,
        userCreationErrorMessage: action.response.message,
      }
    }

    case 'RESET_ADD_OWNER_USER': {
      return {
        ...state,
        isCreatingUser: false,
        fetchingStatus: 0,
        userCreationErrorMessage: ""
      }
    }

    case 'START_USERS_STATUS': {
      return {...state, isUpdatingUser: true, editingStatus: 0}
    }

    case 'UPDATE_USERS_STATUS_FULFILLED': {
      const { response } = action;
      return {
        ...state,
        editingStatus: response.editingStatus,
        isUpdatingUser: false
      }
    }

    case 'UPDATE_USERS_STATUS_FAILED': {
      return {...state, isUpdatingUser: false, editingStatus: action.response.editingStatus}
    }

    case 'RESET_USER_UPDATE': {
      return {
        ...state,
        fetchingStatus: 0
      }
    }

    case 'UPDATE_USERS': {
      const { id } = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex(user => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers,
      };
    }

    case 'DELETE_USERS': {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }

    case 'FETCH_SYS_USERS_START': {
      return { ...state, fetching: true, fetched: true };
    }

    case 'FETCH_SYS_USERS_FULFILLED': {
      return { ...state, fetching: false, fetched: true, allSysUsers: action.payload };
    }

    case 'FETCH_SYS_USERS_REJECTED': {
      return { ...state, fetching: false, error: action };
    }

    case 'RESET_PASSWORD_START': {
      return { ...state, fetching: true, fetched: true };
    }

    case 'RESET_PASSWORD_REJECTED': {
      return { ...state, fetching: false, error: action};
    }

    default:
      return state;
  }
};

export default ownerUserReducer;
