import React from 'react';
import { SmallEllipse, XButton } from '../../../../assets/Svgs/Svgs';
import './PermissionBadge.css';

const PermissionBadge = ({edit, permission, onDeleteHandler}) => {
    return (
    <div className={`permission-badge ${edit && "no-right-padding"}`}>
        <SmallEllipse />
        {permission}
        {edit && <i className="x-button" onClick={onDeleteHandler.bind(this, permission)}><XButton /></i>}
    </div>
    );
}

export default PermissionBadge;
