import React, { useState, useEffect } from "react";
import SubComponentHeader from "../../../../lib/Components/SubComponentHeader";
import {
  CheckedBoxSVG,
  UnCheckedBoxSVG,
} from "../../../../assets/Svgs/Svgs";
import { useDispatch } from "react-redux";
import {
  setTagCreationError,
  TagCreationAction,
  setTagCreationStatusAction
} from "../actions/actions";
import { createRfidTags } from "../actions/methods";
import ActionModal from "../../../../lib/Components/ActionModal";

export default function CreateRfid({
  rfidReducer,
  onBackHandler,
  showTagsCreation,
  showTagsCreationHandler,
  setTabHandler,
  onHideStatusBar
}) {
  const [isInputError, setIsInputError] = useState(false);
  const [inputErrorText, setInputErrorText] = useState("");
  const [ noOfTags, setNoOfTags ] = useState("");
  const [ showToast, setShowToast ] = useState(false);
  const [tagIdentifier, settagIdentifier] = useState('501')
  const [showTagsCreationCompletion, setShowTagsCreationCompletion] = useState(
    false
  );

  const dispatch = useDispatch();
  const { isCreatingTags, isTagsCreated } = rfidReducer;
  useEffect(() => {
    if (rfidReducer.tagErrorCode === 2) {
      dispatch(setTagCreationError(0));
      setShowToast(true);
    }
  }, [rfidReducer.tagErrorCode]);

  useEffect(() => {
    setShowTagsCreationCompletion(isTagsCreated);
  }, [isTagsCreated]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    name === "no-of-tags" && setNoOfTags(value);
    setIsInputError(false);
  };

  const ErrorToast = () => {
    return (
      !!showToast && (
        <div className="">
          <div className={`error-container error-text`}>
            <span className="error-close" onClick={() => setShowToast(false)}>
              x
            </span>
            <span className={`error-text`}>{"No network available!"}</span>
          </div>
        </div>
      )
    );
  };

  const validateTagCreationInputs = () => {
    let isError = false;
    if (noOfTags < 1) {
      isError = true;
    }
    return isError;
  };

  const createTags = e => {
    e.preventDefault();
    if (validateTagCreationInputs()) {
      setIsInputError(true);
      setInputErrorText("Number of tags must be greater than 0");
      return;
    }
    dispatch(TagCreationAction(true));
    dispatch(createRfidTags(tagIdentifier, noOfTags));
  };

  return (
    <>
      {showTagsCreation && (
        <>
          <SubComponentHeader
            onBackHandler={onBackHandler}
            heading="Create new tags"
          />

          <div className="row" style={{width: "100%"}}>
            <div className="offset-1 col-10 mt-5 p-3">
              <div className="col-10 offset-1">
                <div className="row text-center">
                  <div
                    className="col-4"
                    style={{cursor: "pointer"}}
                    onClick={() => settagIdentifier('501')}
                  >
                    <div
                      className={`bg-rfid-details row p-3 pt-5 pb-5 ml-1 mr-1 ${
                        tagIdentifier === '501' && "active"
                      }`}
                    >
                      <div className="col-3 my-auto">
                        <span>
                          {tagIdentifier === '501' ? (
                            <CheckedBoxSVG />
                          ) : (
                            <UnCheckedBoxSVG />
                          )}
                        </span>
                      </div>
                      <div className="col-9 text-left">
                        <span className="rfid-details-title">
                          Individual Customer
                        </span>
                        <br />
                        <span className="rfid-details-instruction">
                          Click here for individual customers RFID Tags.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-4"
                    style={{cursor: "pointer"}}
                    onClick={() => settagIdentifier('502')}
                  >
                    <div
                      className={`bg-rfid-details row p-3 pt-5 pb-5 ml-1 mr-1 ${
                        tagIdentifier === "502" && "active"
                      }`}
                    >
                      <div className="col-3 my-auto">
                        <span>
                          {tagIdentifier === "502" ? (
                            <CheckedBoxSVG />
                          ) : (
                            <UnCheckedBoxSVG />
                          )}
                        </span>
                      </div>
                      <div className="col-9 text-left">
                        <span className="rfid-details-title">
                          Corporate Customer
                        </span>
                        <br />
                        <span className="rfid-details-instruction">
                          Generate tags for corporate entities here.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="bg-rfid-details row p-3 pt-5 pb-5 ml-1 mr-1">
                      <div className="col-3 my-auto">
                        <span>
                          <UnCheckedBoxSVG />
                        </span>
                      </div>
                      <div className="col-9 text-left">
                        <span className="rfid-details-title">Custom Tags</span>
                        <br />
                        <span className="rfid-details-instruction">
                          Generate custom tags with a unique identifier prefix.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{width: "100%"}}>
            <div className="offset-1 col-10 p-3">
              <div className="col-10 offset-1">
                <div className="row bg-rfid-details ml-1 mr-1">
                  <div className="col-6 offset-3 pt-5 pb-5">
                    <div className="form mt-3 mb-3">
                      <label>Account Identifier Prefix</label>
                      <input className="disabled" value={tagIdentifier} disabled />

                      <label className="mt-5">
                        Enter number of tags you want to generate{" "}
                        <span style={{color: "red"}}>*</span>
                      </label>
                      <input
                        className=""
                        name="no-of-tags"
                        value={noOfTags}
                        onChange={handleInputChange}
                      />
                      {isInputError && (
                        <span
                          className="text-danger"
                          style={{marginTop: 10, fontSize: 12}}
                        >
                          {inputErrorText}
                        </span>
                      )}

                      <ErrorToast />

                      <input
                        type="button"
                        onClick={createTags}
                        className="create-tag-btn mt-5"
                        value="Generate Tags"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ActionModal
            isShow={isCreatingTags || showTagsCreationCompletion}
            isCompleted={showTagsCreationCompletion}
            actionTitle="Creating Tags"
            actionText="Hold on a sec. while we generate your tags..."
            isCompletedText="Your RFID Tags have been successfully generated"
          >
            {showTagsCreationCompletion ? (
              <>
                <div
                  className="col-8 offset-2 bg-white mb-3"
                  style={{
                    borderRadius: 8,
                    borderColor: "#5F738C",
                    borderWidth: 0.5,
                    borderStyle: "solid",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-6 pl-3 pr-3 pt-3 pb-3">
                      Account Type
                    </div>
                    <div
                      className="col-6 pl-3 pr-3 pt-3 pb-3"
                      style={{fontWeight: "bold", color: "#00425F"}}
                    >
                      Individual
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      ontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-6 pl-3 pr-3 pt-3 pb-3">
                      Account Identifier Prefix
                    </div>
                    <div
                      className="col-6 pl-3 pr-3 pt-3 pb-3"
                      style={{fontWeight: "bold", color: "#00425F"}}
                    >
                      {tagIdentifier}
                    </div>
                  </div>
                  <div className="row" style={{fontSize: 14, color: "#4B4B4B"}}>
                    <div className="col-6 pl-3 pr-3 pt-3 pb-3">
                      Number of RFID Tags generated
                    </div>
                    <div
                      className="col-6 pl-3 pr-3 pt-3 pb-3"
                      style={{fontWeight: "bold", color: "#00425F"}}
                    >
                      {noOfTags}
                    </div>
                  </div>
                </div>
                <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5">
                  {/* <div className="transparent-btn" onClick={() => {}}>
                          <span className="align-middle">
                            Export Batch <DownloadSVG />
                          </span>
                        </div> */}
                  <div className="create-another">
                    <span
                      className="align-middle ml-5 quickteller-blue"
                      onClick={() => {
                        setNoOfTags("");
                        dispatch(setTagCreationStatusAction(false));
                      }}
                    >
                      Create New
                    </span>
                  </div>
                  <div
                    className="dark-blue-btn"
                    onClick={() => {
                      setTabHandler(2);
                      setNoOfTags("");
                      setShowTagsCreationCompletion(false);
                      dispatch(setTagCreationStatusAction(false));
                      showTagsCreationHandler(false);
                      onHideStatusBar(false);
                    }}
                  >
                    <span className="align-middle text-white">
                      View Generated Tags
                    </span>
                  </div>
                </div>
              </>
            ) : null}
          </ActionModal>
        </>
      )}
    </>
  );
}
