import React, { useEffect } from "react";
import {useDispatch, useSelector } from "react-redux";
import "../../../../../assets/Styles/Dashboard.css"
import { useState } from "react";
import {
  singlePOSUserEditForm,
  singlePOSUserCreateForm,
} from "../../../../ownerModule/redux/action/OwnerVendorActions";
import { getUserProperties } from "../../../../../lib/Constants/UserTypes";
import { fetchTerminalDetail, getAllPosUsers } from "../../../../customerModule/redux/action/VendorAction";
import StationStatusBar from "./StationStatusBar";
import StationPosUsersList from "./StationPosUsersList";

function StationPosUsers () {
  const dispatch = useDispatch();
  const [ isStatusBarVisible, setIsStatusBarVisible ] = useState(true);

  const createPos = async (data, callback) => {
    const posUser = data;
    let dt = data.terminalIdMerchantId.split(":");
      let body = { 
        firstName: posUser.firstName,
        lastName: posUser.lastName,
        userCode: posUser.usersCode,
        userId: posUser.usersId,
        userEmail: posUser.email,
        phone: posUser.phone,
        vendor: posUser.vendor,
        merchantId: dt[1],
        terminalId: dt[0],
        address: {
          street: posUser.street,
          city: posUser.city,
          state: posUser.state,
          country: posUser.country,
        }
      }
     await dispatch(singlePOSUserCreateForm(body));
    callback(true);
  }

  const editPos = async (data, callback) => {
    const posUser = data;
    let dt = data.terminalIdMerchantId.split(":");
      let body = {
        firstName: posUser.firstName,
        lastName: posUser.lastName,
        userCode: posUser.usersCode,
        userId: posUser.usersId,
        userEmail: posUser.email,
        phone: posUser.phone,
        vendor: posUser.vendor,
        merchantId: dt[1],
        terminalId: dt[0],
        address: {
          street: posUser.street,
          city: posUser.city,
          state: posUser.state,
          country: posUser.country,
        }
      }
     await dispatch(singlePOSUserEditForm(body));
    callback(true);
  }

  const props = useSelector(state => state);
  const { oVendorReducer } = props;

  useEffect(()=>{
    if(getUserProperties().userLevel === 'STATION_MANAGER' && !oVendorReducer.terminalDetail.data) {
      dispatch(fetchTerminalDetail())
    }
  }, [])
  
  return (
    <div className="dashboard-container">
      <StationStatusBar {...props} isVisible={isStatusBarVisible} />
      <StationPosUsersList {...props} onFetchPosUsers={(pageNo, size) => {
        dispatch(getAllPosUsers(getUserProperties().id), pageNo, size);
      }}
      onHideStatusBar={status => {
        setIsStatusBarVisible(!status);
      }}
        onCreatePos={createPos}
        onEditPos={editPos}
       />
    </div>
  )
}

export default StationPosUsers;