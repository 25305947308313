import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
import { batch, useDispatch, useSelector } from 'react-redux';
import { GetSettlementAccount, addSettlementAccount, createInvoice, getBanks } from '../../../customerModule/redux/action/paymentsAction';
import { Alert } from 'antd';
import { ReactComponent as GreenCheckIcon } from '../../../../assets/Svgs/GreenCheck.svg'
import { customConfig } from '../../../../actions/action_constants';
import { SkeletonTheme } from 'react-loading-skeleton';

// import { toggleUserCreateModal } from '../../../ownerModule/redux/action/customerUserActions';
// import moment from 'moment';
// import { formatDate3 } from '../../../../lib/Functions/formatDate';

const SettlementAccount = (props) => {
    const { vendor, toggleModal, contractList, showModal, accountDetails } = props;
    const { invoice: { creating, created, data, createData },
        banks: { loading: banksLoading, success: banksLoaded, data: banksData },
        settlement: {error, creating: settlementCreating, success: settlementCreated, data: settlementData } } = useSelector(state => state.oPaymentsReducer);

    const [bankCode, setBankCode] = useState("");
    const [acctNumber, setAcctNumber] = useState("");
    const [alias, setAlias] = useState(vendor?.name);
    const [err, setErrorMessage] = useState("");

    const dispatch = useDispatch();
console.log("hello",settlementData)
    useEffect(() => {
        batch(()=>{
            dispatch(getBanks());
            dispatch(GetSettlementAccount(vendor.id))
        })
    }, [])

    useEffect(() => {
        if (created) {
            toggleModal();
        }
    }, [created])


    const handleSubmit = (e) => {
        e.preventDefault();
        let settlementObj = {
            "accountNumber": acctNumber,  //valid bank account
            "accountType": "20",    //must always be 20
            "alias": alias, //vendor account name and must be unique
            "bankInstitutionCode": bankCode,   //code from supported banks api 
            "merchantCode": customConfig.merchantCode,   //Smartfuel merchantCode
            "vendorNumber": vendor.number //valid vendorNumber and unique
        }
        dispatch(addSettlementAccount(settlementObj));
    }

    const handleSelectedBank = (e) => {
        setBankCode(e.target.value);
    }

    const handleAcctNumber = (e) => {
        const { value } = e.target;
        if (/^-?\d*$/.test(value)) {
            setAcctNumber(value);
        }
    }

    const handleCloseModal = ()=>{
        dispatch({ type: 'SETTLEMENT_RESET'})
        toggleModal()
    }

    const handleAlias = (e)=>{
        setAlias(e.target.value);
    }

    return (
        <SkeletonTheme color="#fff" highlightColor="#f4f4f4">
            <Modal style={{
                width: 720,
                background: 'white',
            }} scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal invoice-modal" show={showModal}>
                <Modal.Body style={{
                    padding: 0
                }}>
                    <div className='payment-modal-container'>
                        <h5 className='payment-modal-h'>Generate Settlement Form</h5>
                        <Tabs tabclassname="invoice-tab" defaultActiveKey="SETTLEMENT"
                            id="uncontrolled-tab-example"
                            activeKey="SETTLEMENT"
                        >
                            <Tab eventKey="SETTLEMENT" title="SETTLEMENT">
                                <div className='form-container mr-4 ml-4'>
                                   {settlementCreated && settlementData && settlementData?.data?.length > 0 ? <div className='d-flex flex-column justify-content-center align-items-center p-3'>
                                        <GreenCheckIcon />
                                        <span className='payment-modal-h mb-1'>Alias: {settlementData?.data?.alias || settlementData?.data?.accountDto?.alias }</span>
                                        <span className='payment-modal-h mb-1'>Settlement ID :{settlementData?.data?.accountDto?.id || settlementData?.data?.id}</span>
                                        <button type='button' className='invoice-form-button' onClick={handleCloseModal}>Cancel</button>

                                    </div>:
                                   <form className='incoice-form' onSubmit={handleSubmit}>
                                        {/* <Row>
                                            <Col>
                                                <Form.Label className='invoice-label mb-0'>Description*</Form.Label> 
                                                <Form.Control required className='mb-4 invoice-input' as={"textarea"} placeholder="" />
                                            </Col>
                                            <Col>
                                                <Form.Label className='invoice-label mb-0'>Vendor Name*</Form.Label>
                                                <Form.Control defaultValue={vendorInfo?.vendorName} className='mb-4 invoice-input' disabled readOnly as={'input'} placeholder="" />
                                            </Col>
                                        </Row> */}
                                        <Row>
                                            <Col>
                                                <Form.Label className='invoice-label mb-0'>Bank Name*</Form.Label>
                                                <Form.Control onChange={handleSelectedBank} required value={bankCode} className='mb-4 invoice-input' defaultValue="Choose..." as='select' type='number' placeholder="" >
                                                    <option value="">{banksLoading ? 'Loading Banks...' : 'Select bank...'}</option>
                                                    {banksLoaded && banksData?.data.map((bank, index) => {
                                                        return (<option value={bank.code} key={index}>{bank.name}</option>)
                                                    })}
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Label required className='invoice-label mb-0'>Account Number*</Form.Label>
                                                <Form.Control required maxLength={10} minLength={10} onChange={handleAcctNumber} value={acctNumber} as={'input'} type='text' className='mb-4 invoice-input' placeholder="" />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Label className='invoice-label mb-1'>Account alias*</Form.Label>
                                                <Form.Control required onChange={handleAlias} value={alias} className='mb-4 invoice-input' as={'input'} type='text' placeholder="" />
                                            </Col>
                                        </Row>

                                        {error && <Alert className='mb-1' closable message={settlementData} onClose={() => setErrorMessage('')} type='error' />}

                                        <div className='button-border'><button disabled={settlementCreating} type='button' onClick={handleCloseModal} className='invoice-cancel-button'>
                                            Cancel
                                        </button>
                                            <button disabled={settlementCreating} type='submit' className='invoice-form-button'>{settlementCreating ? 'Creating...' : 'Create'}</button>
                                        </div>
                                    </form>}
                                </div>
                            </Tab>

                        </Tabs>
                    </div>
                </Modal.Body>
            </Modal>
        </SkeletonTheme>)
}

export default SettlementAccount;