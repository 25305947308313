import React from "react";
// import { Modal } from "reactstrap";
import {
  CloseSVG,
  UserTransactionSVG,
  ActiveMarkSVG,
  DisabledMark
} from "../../../../assets/Svgs/Svgs";
import formatAmount from "../../../../lib/Functions/formatAmount";
import { formatDate3 } from "../../../../lib/Functions/formatDate";

const ContractManagement = ({ onCloseHandler, contracts }) => {
  const contractList =
    contracts && contracts.map((val, i) => {
      const {
        customerSignature,
        name,
        vendorSignature,
        bound,
        contractType,
        contractStartDate,
        gracePeriod,
        contractStatus
      } = val;

      return (
        <div key={i} className="row bg-rfid-details mr-2 ml-2 p-2 mb-3 rounded">
          <div className="col-3 mt-2 p-3">
            <div className="row ml-2 mr-1 ">
              <div className="pl-2 pr-2 mt-3 d-table">
                <div className="col-1 p-0 d-inline-block d-table-cell align-middle">
                  <UserTransactionSVG />
                </div>
                <div className="col-11 ml-0">
                  <div className="font-weight-bold mt-1 contract-modal-value">
                    {name}
                  </div>
                  <div className="contract-modal-heading d-inline-block">
                    {vendorSignature ||
                      "Nil"}
                  </div>
                </div>
              </div>
              <div className="dashboard-short-borders" style={{top: 23}}></div>
            </div>
          </div>
          <div className="col-9 mt-2 p-3">
            <div className="col-12">
              <div className="row" style={{ width: "100%" }}>
                <div className="row ml-0 contract-modal-heading">
                  <div className="col-3">Limit</div>
                  <div className="col-3">Type</div>
                  <div className="col-3">Start Date</div>
                  <div className="col-3">Grace Period</div>
                </div>
                <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                  <div className="col-3">₦ {formatAmount(bound) || "0.00"}</div>
                  <div className="col-3">{contractType || "Unavailable"}</div>
                  <div className="col-3">
                    {(contractStartDate && formatDate3(contractStartDate)) || "Unavailable"}
                  </div>
                  <div className="col-3">{(gracePeriod && formatDate3(gracePeriod)) || "Unavailable"}</div>
                </div>
              </div>
              <div className="row ml-0 mt-4" style={{ width: "100%" }}>
                <div className="row contract-modal-heading">
                  <div className="col-3">Customer Signature</div>
                  <div className="col-3">Status</div>
                </div>
                <div className="row font-weight-bold mt-2  contract-modal-value">
                  <div className="col-3" style={{ overflowWrap: "break-word" }}>
                    {customerSignature || "Unavailable"}
                  </div>
                  <div className="col-3">
                    {contractStatus === "ACTIVE" ? "Active " : "Inactive "}
                    {contractStatus === "ACTIVE" ? (
                      <ActiveMarkSVG />
                    ) : (
                      <DisabledMark />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <>
      <div
        className="mb-3"
        style={{
          textAlign: "left",
          paddingRight: 10,
          paddingLeft: 10,
          paddingBottom: 10,
          borderBottom: "0.5px solid #5F738C"
        }}
      >
        <h6 className="mt-5 qt-black font-weight-bold text-left d-inline">
          Contract Details
        </h6>
        <span
          style={{ float: "right", cursor: "pointer" }}
          onClick={onCloseHandler}
        >
          <CloseSVG />
        </span>
      </div>
      <>{contractList.length > 0? contractList : <div className="mt-4 mb-4 text-center">No contract to view</div>}</>
    </>
  );
};

export default ContractManagement;
