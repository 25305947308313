import React, { useState, useEffect, useMemo } from "react";
import FormWarning from "../../../admin/shared/FormWarning";
import PermissionBadge from "../../subcomponents/PermissionBadge/PermissionBadge";
import { SearchSvg } from "../../../../assets/Svgs/Svgs";
import { countries } from "../../../../lib/Dropdowns/countries";
import { useDispatch, batch } from "react-redux";
import { stationManagerPermission, vendorPermissions } from "../../../admin/shared/Permission";
import Checkbox from "./Checkbox";
import validator from "validator";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import { SUPER_ADMIN, ADMIN, STATION_MANAGER } from "../../../../lib/Constants/UserTypes";
import { submitUserForm, updateUserForm } from "../../../customerModule/redux/action/users.actions";

const EditForm = ({
  create,
  authentication,
  profilePix,
  userCreationError,
  userCreationErrorHandler,
  ownerUsers,
  resetForm,
  userToBeEdited,
  saveEditForm,
  onSaveEditFormHandler,
  oVendorReducer
}) => {
  const condition = userToBeEdited && userToBeEdited.address;
  const formObj = {
    firstName: (userToBeEdited && userToBeEdited.firstName) || "",
    lastName: (userToBeEdited && userToBeEdited.lastName) || "",
    userEmail: (userToBeEdited && userToBeEdited.userEmail) || "",
    secret: (userToBeEdited && userToBeEdited.secret) || "",
    bound: (userToBeEdited && userToBeEdited.bound) || "",
    type: (userToBeEdited && userToBeEdited.type) || "",
    userLevel: (userToBeEdited && userToBeEdited.userLevel) || "",
    owner: null,
    image: (userToBeEdited && userToBeEdited.image) || "",
    contact: {
      email: (userToBeEdited && userToBeEdited.userEmail) || "",
      phoneNumber: (userToBeEdited && userToBeEdited.contact.phoneNumber) || "",
    },
    terminalId: (userToBeEdited && userToBeEdited.terminalId) || "",
    address: {
      street: (condition && userToBeEdited.address.street) || "",
      city: (condition && userToBeEdited.address.city) || "",
      state: (condition && userToBeEdited.address.state) || "",
      country: (condition && userToBeEdited.address.country) || "",
    },
    permissions:
      (userToBeEdited && userToBeEdited.permissions.map((val) => val.name)) ||
      [],
    selectedPermission: (userToBeEdited && userToBeEdited.permissions.map((val) => val.name)) ||
    [],
  };

  // const {oVendorReducer} = props; 

  const allTerminals = oVendorReducer.allTerminalsByVenId;
  let terminalData = allTerminals ? allTerminals.data : [];
  const terminalList = (terminalData && userToBeEdited)? terminalData.map((value, i) => {
           const {name, id} = value;
           return (
             <option value={id} key={i}>
               {name}
             </option>
           );
         }) : (terminalData && !userToBeEdited) ? terminalData.filter(x => !x.managerName).map((value, i) => {
        const {name, id} = value;
        return (
          <option value={id} key={i}>
            {name}
          </option>
        );
      }): [];
  
  const {vendor, type, id} = authentication.user;
  formObj.vendor = vendor;
  formObj.type = type;
  formObj.owner = id;

  const [formData, setFormData] = useState({...formObj});
  const [formatError, setFormatError] = useState("");
  const [showPermissionList, setShowPersmissionList] = useState(false);
  
  useEffect(() => {
    setFormData({...formData, image: profilePix.base64 || ""});
  }, [profilePix]);

  useEffect(() => {
    if (userCreationError) {
      setFormatError(ownerUsers.userCreationErrorMessage);
      userCreationErrorHandler(false);
    }
  }, [userCreationError]);

  useEffect(() => {
    batch(() => {
      dispatch({type: "ADD_CUSTOMER_USER_RESET"});
    });
  }, []);

  useEffect(() => {
    formObj.image = "";
    if (resetForm) setFormData(formObj);
  }, [resetForm]);

  useEffect(() => {
    if (saveEditForm) {
      handleCreateUser();
      onSaveEditFormHandler(false);
    }
  }, [saveEditForm]);

  const isUserManager = useMemo(()=>{
    return formData.userLevel === STATION_MANAGER ? true : false;
  }, [formData.userLevel])
  
  const availablePermissions = vendorPermissions.map((val, i) => {
    return (
      <div className="permission-dropdown-item" key={i}>
        <span>
          <Checkbox
            val={val}
            name={i}
            selectedPermissions={formData.selectedPermission}
            onSelectPermission={({target}) => {
              if (target.checked) {
                if (!formData.selectedPermission.includes(val)) {
                  let arr = [...formData.selectedPermission];
                  arr.push(val);
                  setFormData({...formData, selectedPermission: arr});
                }
              } else if (!target.checked) {
                setFormData({
                  ...formData,
                  selectedPermission: formData.selectedPermission.filter((permission) => {
                    return permission !== val;
                  }),
                });
              }
            }}
          />{" "}
          {val}
        </span>
      </div>
    );
  });

  const stationPermissions = stationManagerPermission.map((val, i) => {
    return (
      <div className="permission-dropdown-item" key={i}>
        <span>
          <Checkbox
            val={val}
            name={i}
            selectedPermissions={formData.permissions}
          />{" "}
          {val}
        </span>
      </div>
    );
  });

  const getSelectedPermissions = () => {
    if(isUserManager){
      return  formData.permissions.map((permission, j) => { 
        return(<PermissionBadge
          edit={false}
          key={`${j}-${permission}`}
          permission={permission}
          onDeleteHandler={() =>{}}
        />)
      })
    }
    else
    return vendorPermissions.map((val, i) =>
      formData.selectedPermission.map((permission, j) => {// eslint-disable-line
        if (val === permission) {
          return (
            <PermissionBadge
              edit={true}
              key={i}
              permission={val}
              onDeleteHandler={(permission) =>
                setFormData({
                  ...formData,
                  permissions: formData.selectedPermission.filter(
                    (val) => val !== permission
                  ),
                })
              }
            />
          );
        }
      })
    );
  };


  const dispatch = useDispatch();

  const validationRules = () => {
    return {
      firstName: (val) =>
        val.length < 3 ? `First name must be longer than 2 letters!` : "",
      lastName: (field) =>
        field.length < 3 ? `Last name must be longer than 2 letters!` : "",
      secret: (val) =>
        val.length < 6 ? `Password length  must be longer than 5!` : "",
      userEmail: (val) =>
        !validator.isEmail(val) ? "Valid email address is required!" : "",
      phoneNumber: (val) =>
        !validator.isMobilePhone(val) ? "Valid phone number is required!" : "",
      image: (val) =>
        val.type !== "image/jpeg" || val.type !== "image/png"
          ? "Invalid image format!"
          : "",
      userLevel: (val) => (!val.length ? "User level is required!" : ""),
      terminalId: (val) => (isUserManager && !val.length ? "Terminal ID is required" : ""),
      street: (val) =>
        val.length < 3 ? "A valid street name is required!" : "",
      city: (val) => (val.length < 3 ? "A valid city name is required!" : ""),
      state: (val) => (val.length < 3 ? "A valid state name is required!" : ""),
      country: (val) =>
        val.length < 3 ? "A valid country name is required!" : "",
      permissionLevel: (val) =>
        (!val.length && !isUserManager) ? "Permission level is required!" : "",
      editingSelf: (val) =>
        val === SUPER_ADMIN
          ? "Permission denied! You can only edit the user you created."
          : "",
      upgradeValid: () => userToBeEdited? ((userToBeEdited.userLevel === ADMIN && isUserManager) || (userToBeEdited.userLevel === STATION_MANAGER && !isUserManager)
                            ? " Oops!! You cannot perform this action" : '')
                            : "",
    };
  };

  const isValid = () => {
    let response = true;
    let formatError = "";
    const editingSelfErr = validationRules().editingSelf(
      userToBeEdited ? userToBeEdited.userLevel : ""
    );
    const firstnameErr = validationRules().firstName(formData.firstName);
    const lastnameErr = validationRules().lastName(formData.lastName);
    const emailErr = validationRules().userEmail(formData.userEmail);
    const phoneErr = validationRules().phoneNumber(
      formData.contact.phoneNumber
    );
    const userLevelErr = validationRules().userLevel(formData.userLevel);
    const terminalErr = validationRules().terminalId(formData.terminalId);
    const permissionLevelErr = validationRules().permissionLevel(
      formData.permissions
    );
    const streetErr = validationRules().street(formData.address.street);
    const cityErr = validationRules().city(formData.address.city);
    const stateErr = validationRules().state(formData.address.state);
    const countryErr = validationRules().country(formData.address.country);
    const upgradeValidErr = validationRules().upgradeValid();

    if (!create && !!editingSelfErr) {
      formatError = editingSelfErr;
      response = false;
    } else if (!!firstnameErr) {
      formatError = firstnameErr;
      response = false;
    } else if (lastnameErr) {
      formatError = lastnameErr;
      response = false;
    } else if (emailErr) {
      formatError = emailErr;
      response = false;
    } else if (phoneErr) {
      formatError = phoneErr;
      response = false;
    } else if (userLevelErr) {
      formatError = userLevelErr;
      response = false;
    } else if (permissionLevelErr) {
      formatError = permissionLevelErr;
      response = false;
    } else if(terminalErr) {
      formatError = terminalErr;
      response = false;
    } else if (streetErr) {
      formatError = streetErr;
      response = false;
    } else if (cityErr) {
      formatError = cityErr;
      response = false;
    } else if (stateErr) {
      formatError = stateErr;
      response = false;
    } else if (countryErr) {
      formatError = countryErr;
      response = false;
    } else if(upgradeValidErr) {
      formatError = upgradeValidErr;
      response = false;
    }

    setFormatError(formatError);
    return response;
  };

  const handleInput = (target, e) => {
    if (target === "userEmail") {
      setFormData({
        ...formData,
        userEmail: e.target.value,
        secret: e.target.value,
        contact: {...formData.contact, email: e.target.value},
      });
      return;
    }
    if (target === "phone") {
      setFormData({
        ...formData,
        contact: {...formData.contact, phoneNumber: e.target.value},
      });
      return;
    }
    if (target === "userLevel") {
        setFormData({
          ...formData,
          terminalId: e.target.value !== STATION_MANAGER ?'': formData.terminalId,
          permissions: e.target.value === STATION_MANAGER ? stationManagerPermission : formData.selectedPermission,
          userLevel: e.target.value,
        });
      return;
    }
    if (
      target === "street" ||
      target === "city" ||
      target === "state" ||
      target === "country"
    ) {
      setFormData({
        ...formData,
        address: {...formData.address, [target]: e.target.value},
      });
      return;
    }

    setFormData({...formData, [target]: e.target.value});
  };

  const handleCreateUser = () => {
    setFormData({
      ...formData,
      permissions: isUserManager? formData.permissions : formData.selectedPermission,
      name: formData.firstName + " " + formData.lastName,
      owner: 2,
    });
    if (!isValid()) return;
    !!create
      ? dispatch(submitUserForm(formData))
      : dispatch(updateUserForm(formData, userToBeEdited.id));
  };

  return (
    <>
      <div className="row">
        <div className="form-group col-3">
          <label>First Name</label>
          <input
            type="text"
            value={formData.firstName}
            onChange={handleInput.bind(this, "firstName")}
            className="form-control"
          />
        </div>
        <div className="form-group col-3">
          <label>Last Name</label>
          <input
            type="text"
            value={formData.lastName}
            onChange={handleInput.bind(this, "lastName")}
            className="form-control"
          />
        </div>
        <div className="form-group col-3">
          <label>Phone Number</label>
          <input
            type="phone"
            value={formData.contact.phoneNumber}
            onChange={handleInput.bind(this, "phone")}
            className="form-control"
          />
        </div>
        <div className="form-group col-3">
          <label>User Level</label>
          <select
            className="form-control"
            value={formData.userLevel}
            onChange={handleInput.bind(this, "userLevel")}
          >
            <option value={""}>Select User Level</option>
            <option value={"ADMIN"}>Admin</option>
            <option value={"USER"}>User</option>
            <option value={"STATION_MANAGER"}>Station Manager</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label>Email</label>
          <input
            type="email"
            value={formData.userEmail}
            onChange={
              (create && handleInput.bind(this, "userEmail")) || undefined
            }
            className="form-control"
            disabled={!create}
          />
        </div>

        <div className="form-group col-3">
          <label>Street</label>
          <input
            type="text"
            value={formData.address.street}
            onChange={handleInput.bind(this, "street")}
            className="form-control"
          />
        </div>

        <div className="form-group col-3">
          <label>City</label>
          <input
            type="text"
            value={formData.address.city}
            onChange={handleInput.bind(this, "city")}
            className="form-control"
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-3">
          <label>State</label>
          <input
            type="text"
            value={formData.address.state}
            onChange={handleInput.bind(this, "state")}
            className="form-control"
          />
        </div>

        <div className="form-group col-3">
          <label>Country</label>
          <select
            className="form-control"
            value={formData.address.country}
            onChange={handleInput.bind(this, "country")}
          >
            <option value={""}>Select Country</option>
            {countries}
          </select>
        </div>

        {isUserManager &&
          <div className="form-group col-3">
          <label>Terminal</label>
          <select
            className="form-control"
            value={formData.terminalId}
            onChange={handleInput.bind(this, "terminalId")}
          >
            <option value={""}>Select Terminal</option>
            {terminalList}
          </select>
        </div>
        }

      </div>

      <div className="row edit-permissions permissions">
        <span className="overview-title">
          Permissions
          <FormWarning
            inline={true}
            message=" Permission determines the rights of a users"
          />
        </span>
        <div style={{width: "100%"}}>
          {!showPermissionList && (
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search a permission"
                onFocus={() => {
                  setShowPersmissionList(true);
                }}
                className="menu-input"
              />
              <i className="input-icon">
                <SearchSvg />
              </i>
            </div>
          )}
          {showPermissionList && (
            <div className="permission-dropdown">
              {
                <div className="permission-dropdown-title">
                  Search a permission{" "}
                  <span
                    style={{float: "right", cursor: "pointer"}}
                    onClick={setShowPersmissionList.bind(this, false)}
                  >
                    Done
                  </span>
                </div>
              }
              {isUserManager ? stationPermissions: availablePermissions}
            </div>
          )}
        </div>
        {!showPermissionList && getSelectedPermissions()}
      </div>
      <ToastAlert
        showToast={!!formatError}
        parentClass={"col-12 pb-3 pl-0"}
        childClass={""}
        toastText={formatError}
        onCloseHandler={() => {
          setFormatError("");
        }}
      />
      {create && (
        <div className="create-btn ml-0 pl-0">
          <button onClick={handleCreateUser} className="ml-0">Create User</button>
        </div>
      )}
    </>
  );
};

export default EditForm;
