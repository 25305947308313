import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import "../../../../src/assets/Styles/Dashboard.css";
import {
  fetchAllVendors,
  loadAllCustomerVendors_id
} from "../redux/action/OwnerVendorActions";
import ContractsStatusBar from "./StatusBar";
import {
  viewAllContracts,
  createContract
} from "../redux/action/OwnerContractsActions";
import ContractList from "./ContractList";

function Contracts () {
  const dispatch = useDispatch();
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);
  useEffect(() => {
    batch(() => {
      dispatch(viewAllContracts(1, 10));
      dispatch(fetchAllVendors());
    });
  }, []);
  const props = useSelector(state => state);
  return (
    <div className="dashboard-container">
      {isStatusBarVisible && <ContractsStatusBar {...props} />}

      <ContractList
        {...props}
        onHideStatusBar={status => {
          setIsStatusBarVisible(!status);
        }}
        onFetchContracts={(pageNo, pageSize) => {
          dispatch(viewAllContracts(pageNo, pageSize));
        }}
        onFetchVendorCustomers={vendorId =>
          dispatch(loadAllCustomerVendors_id(vendorId, 1, 100000))
        }
        onCreateContract={data => dispatch(createContract(data))}
      />
    </div>
  );
}

export default Contracts;
