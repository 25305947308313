import React, { useEffect } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import moment from "moment";
import { getUserProperties } from "../../../lib/Constants/UserTypes";
import { fetchTerminalDetail, getAllPosUsers } from "../../customerModule/redux/action/VendorAction";
import { fetchOneVendor } from "../../ownerModule/redux/action/OwnerVendorActions";
import TransactionList from "../Reports/Transactions/TransactionList";
import StationStatusBar from "./StationStatusBar";
import { getTransactionsByvendor } from "../../ownerModule/redux/action/OwnerTrailActions";

export const SDashboard = () => {
    const dispatch = useDispatch();
    const props = useSelector((state) => state);
    const { oVendorReducer } = props;
    const startDate = moment(
        new Date(moment().subtract(365, "days").format("LLLL"))
      ).format("YYYY-MM-DD");
      const endDate = moment(
        new Date(moment().subtract(0, "days").format("LLLL"))
      ).format("YYYY-MM-DD");
    useEffect(() => {
        batch(()=>{
            dispatch(fetchOneVendor())
            dispatch(getAllPosUsers(getUserProperties().id))
            dispatch(fetchTerminalDetail());
        })
    }, [])

    useEffect(()=> {
        if(oVendorReducer.terminalDetail.data) {
            let terminal = oVendorReducer.terminalDetail.data;
            dispatch(getTransactionsByvendor(getUserProperties().vendorNumber,startDate, endDate, 1, 10, 2, { terminalId: terminal.terminalId}))
        }
    }, [oVendorReducer.terminalDetail])
    return (<div className="dashboard-container">
        <StationStatusBar {...props} />
       <TransactionList {...props} title="Last 10 Transactions" />
    </div>)
}
export default SDashboard;