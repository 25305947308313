import React from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import { shortMonthNames } from "../../../lib/Functions/DateNames";
import formatAmount from "../../../lib/Functions/formatAmount";

const ContractsStatusBar = (props) => {
  const d = new Date();
  const { customerVendor, oContractReducer } = props;
  const { allCustomerVendors } = customerVendor;
  const { customerContracts = [] } = oContractReducer;

  let data = allCustomerVendors.data ? allCustomerVendors.data : [];
  let active = 0, inactive = 0, total = data.length;

  const totalBound = data.length !== 0 && customerContracts && customerContracts
    .filter(c => c.contractStatus === 'ACTIVE')
    .reduce((totalValue, amount) => totalValue + amount.bound, 0);

  data.forEach((dataArr) => {
    if (dataArr.status === "ACTIVE") {
      active++;
    } else {
      inactive++;
    }
  });

  return (
    <div className="status-container row">
      <div className="compartment compartment-one col col-2">
        <span className="title d-block mt-3 mb-3 mr-3">Contracts / Vendors</span>
        <span className="sub-title">
          Summary of all vendors / contracts active and suspended{" "}
        </span>
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
      </div>

      <div className="compartment report-compartment-two col-3">
        <div
          className="pl-3 pr-3 pt-1 pb-1"
          style={{display: "table", height: "100%", width: "100%"}}
        >
          <div
            style={{
              border: "none",
              boxShadow: "none",
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            <span className="sub-title mb-3 text-dark font-weight-bold">
              Total Value of Contracts
            </span>
            <span className="count mt-2 font-weight-bold">
              ₦ {formatAmount(totalBound * 100 || 0)}
            </span>
            {/* amount in naira already */}
          </div>
        </div>
      </div>
      <div className="compartment compartment-two col-7">
        <div className="row status-box">
          <div
            className="col col-12"
            style={{
              position: "relative",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 18,
              // width: "25%",
            }}
          >
            <div className="row ">
              <div className="col-4 text-center">
                <span className="title">Vendor</span>
              </div>
            </div>
            <div className="row status-sub-title text-center">
              <div className="col-4 pl-0">TOTAL</div>
              <div className="col-4 pl-0">ACTIVE</div>
              <div className="col-4 pl-0">SUSPENDED</div>
            </div>
            <div className="row status-values text-center">
              <div className="col-4 pl-0">{total || "0"}<div className="short-borders" style={{right: 0, top: -45, position: 'absolute'}}></div></div>
              
              <div className="col-4 pl-0">{active || "0"}<div className="short-borders" style={{right: 0, top: -45, position: 'absolute'}}></div></div>
              {/* <div className="short-borders" style={{left: 470}}></div> */}
              <div className="col-4 pl-0">{inactive || "0"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractsStatusBar;
