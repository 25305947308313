import React, { useEffect, useState } from "react";
import "../../../assets/Styles/Dashboard.css";
import {
  FilterSVG,
  ExportSVG,
  CreateSVG,
} from "../../../assets/Svgs/Svgs";
import { Spin } from "antd";
import { CSVLink } from "react-csv";
import {
  shortMonthNames,
  shortDayNames
} from "../../../lib/Functions/DateNames";
import formatAmount from "../../../lib/Functions/formatAmount";
import Pagination from "../../../lib/Components/Pagination";
import { ACTIVE, EXPIRED, TERMINATED } from "../../../lib/Constants/ContractStates";
import CreateContract from "./CreateContract";
import ContractDetails from "./ContractDetails";
import PendingRequest from "./Pendingrequest";

const NewContractList = props => {
  const {
    makerCheckerRequests,
    pageSize,
    oContractReducer,
    onFetchContracts,
  } = props;
  const { data, count, isLoading } = oContractReducer.allVendorContracts;
  const [showContractDetails, setShowContractDetails] = useState(false);
  const [selectedContract, setSelectedContract] = useState({});
  const [isCreateContract, setIsCreateContract] = useState(false);
  const [isUpdateContract, setIsUpdateContract] = useState(false);
  const [changeProductPriceResponse, setChangeProductPriceResponse] = useState({});
  const [decisionSubmitResponse, setDecisionSubmitResponse] = useState({});

  const TrailList = data && data.map(value => {
      const {
        id,
        name,
        bound,
        contractStartDate,
        contractStatus,
        contractCycleType
      } = value;

      let condition = "", classIdentifier = "";
      if (contractStatus === ACTIVE) {
        classIdentifier = "success";
        condition = "Active";
      } else if (contractStatus === EXPIRED) {
        classIdentifier = "error";
        condition = "Expired";
      } else if (contractStatus === TERMINATED) {
        classIdentifier = "error";
        condition = "Terminated";
      } else {
        classIdentifier = "warning";
        condition = "Inactive";
      }
      const d = new Date(contractStartDate);

      return (
        <tr key={id}>
          <td className="table-description font-weight-bold">{name}</td>
          <td className="table-description font-weight-bold">
            ₦ {formatAmount(bound * 100)}
            {/* amount in naira already */}
          </td>
          <td className="table-description">
            {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
            {shortMonthNames[d.getMonth()]} {d.getFullYear()}
          </td>
          <td className={`table-status`}>
            <div className={`status-btn ${classIdentifier}`}>{condition}</div>
          </td>
          <td className={`table-description`}>
            {contractCycleType || "Unavailable"}
          </td>
          <td
            className="table-action"
            onClick={() => {
              setShowContractDetails(true);
              setSelectedContract(value);
              props.onHideStatusBar(true);
            }}
          >
            View
          </td>
        </tr>
      );
    });

  useEffect(() => {
    setSelectedContract(oContractReducer.selectedContract);
  }, [oContractReducer.selectedContract])

  useEffect(() => {
    setChangeProductPriceResponse(oContractReducer.changeProductPriceResponse);
  }, [oContractReducer.changeProductPriceResponse]);

  useEffect(() => {
    setDecisionSubmitResponse(makerCheckerRequests.decisionSubmitResponse);
  }, [makerCheckerRequests.decisionSubmitResponse]);

  useEffect(()=>{
    let data = localStorage.getItem('SAVE_CONTRACT_INFO');
    if(data){
      handleCreateContract()
    }
  },[])

  const handleCreateContract =() =>{
      props.onHideStatusBar(true);
      setIsCreateContract(true);
      setIsUpdateContract(false);
  }

  return (
    <div className="disbursed-cards-container row">
      {!isCreateContract && !isUpdateContract && !showContractDetails && (
        <>
          <div
            className="menu-link"
            style={{marginBottom: 0, paddingBottom: 4}}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid",
              }}
            >
              All Contracts
            </span>
          </div>
          <div
            className="create-new-tags-btn"
            onClick={handleCreateContract}
          >
            <span className="align-middle">
              <CreateSVG /> Create New Contracts
            </span>
          </div>

          {!!data && (
            <CSVLink
              data={data}
              filename={"Contracts.csv"}
              className=""
              target="_blank"
            >
              <div className="contract-export-btn" style={{float: "right"}}>
                <span className="text-white">
                  Export CSV <ExportSVG />
                </span>
              </div>
            </CSVLink>
          )}

        <PendingRequest approvalType='Contract' />
       <Spin spinning={isLoading} 
         wrapperClassName='spinner-wrapper'
        tip={'Loading contracts...'}
       >
          <table className="table table-hover">
            <thead>
              <tr>
                <td>Name</td>
                <td>Limit</td>
                <td>
                  Start Date <FilterSVG />
                </td>
                <td>
                  Status <FilterSVG />
                </td>
                <td>
                  Contract Cycle <FilterSVG />
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {!!count && TrailList}
              {!count && (
                <tr>
                  <td colSpan={7} className="text-center">
                    No contract to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Spin>
          <Pagination
            count={count}
            pageSize={pageSize}
            fetchMethod={(pageNo, pageSize) => {
              onFetchContracts(pageNo, pageSize);
            }}
          />
        </>
      )}

      {showContractDetails && (
        <ContractDetails
          {...props}
          selectedContract={selectedContract}
          isTogglingContract={oContractReducer.isTogglingContract}
          isRequestingCPPriceChange={oContractReducer.isRequestingCPPriceChange}
          changeProductPriceResponse={changeProductPriceResponse}
          isRequestingCLChange={oContractReducer.isRequestingCLChange}
          changeContractLimitResponse={oContractReducer.changeContractLimitResponse}
          isSubmittingDecision={makerCheckerRequests.isSubmittingDecision}
          decisionSubmitResponse={decisionSubmitResponse}
          onEditContractClick={() => {
            setIsUpdateContract(true);
            setShowContractDetails(false);
          }}
          backFunction={() => {
            setSelectedContract({});
            setShowContractDetails(false);
            props.onHideStatusBar(false);
          }}
        />
      )}

      <CreateContract
        {...props}
        isCreateContract={isCreateContract}
        isUpdateContract={isUpdateContract}
        selectedContract={selectedContract}
        onBackHandler={() => {
          !isCreateContract && setShowContractDetails(true);
          setIsUpdateContract(false);
          setIsCreateContract(false);
          props.onHideStatusBar(isUpdateContract);
        }}
      />
    </div>
  );
};

export default NewContractList;
