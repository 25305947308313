import React from "react";
import "../../../../src/assets/Styles/Dashboard.css";
import { shortMonthNames } from "../../../lib/Functions/DateNames";
import formatAmount from "../../../lib/Functions/formatAmount";

const ContractsStatusBar = (props) => {
  const d = new Date();
  const {oContractReducer} = props;
  const { allVendorContracts } = oContractReducer
    let data = allVendorContracts !== null ? allVendorContracts.data : [];
    let active =0, pending =0, inactive = 0, ended = 0, expired=0, total = allVendorContracts.count;// eslint-disable-line no-unused-vars
    let totalBound = data?.filter(c => c.contractStatus !== "TERMINATED").reduce((total, amount) => total + amount.bound, 0);

    data && data.forEach(dataArr=>{
      switch(dataArr.contractStatus){
        case 'ACTIVE':
        active++
        break;
        case 'PENDING':
        pending++
        break;
        case 'INACTIVE':
        inactive++
        break;
        case 'ENDED':
        case 'TERMINATED':
        ended++
        break;
        case 'EXPIRED':
        expired++
        break;

        default:
      }
    })


  return (
    <div className="status-container row">
      <div className="compartment compartment-one col col-2">
        <span className="title d-block mt-3 mb-3 mr-3">Contracts</span>
        <span className="sub-title">
          Summary of Vendor's active and suspended contracts
        </span>
        <span className="count-time font-weight-bold">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
      </div>

      <div className="compartment compartment-two col-10">
        <div className="row token-status-box">
          <div
            className="col col-12"
            style={{
              position: "relative",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 16,
              paddingBottom: 18,
            }}
          >
            <div className="row mb-3">
              <span className="title">Contracts</span>
            </div>
            <div className="row status-sub-title text-center">
              <div className="col-2">
                TOTAL{" "}
                <div
                  className="short-borders"
                  style={{right: 0, top: -10}}
                ></div>
              </div>
              <div className="col-2">
                ACTIVE{" "}
                <div
                  className="short-borders"
                  style={{right: 0, top: -10}}
                ></div>
              </div>
              <div className="col-2">
                INACTIVE{" "}
                <div
                  className="short-borders"
                  style={{right: 0, top: -10}}
                ></div>
              </div>
              <div className="col-2">
                EXPIRED{" "}
                <div
                  className="short-borders"
                  style={{right: 0, top: -10}}
                ></div>
              </div>
              <div className="col-1">
                ENDED{" "}
                <div
                  className="short-borders"
                  style={{right: 0, top: -10}}
                ></div>
              </div>
              <div className="col-3">TOTAL VALUE</div>
            </div>
            <div className="row status-values text-center">
              <div className="col-2">{total || "0"}</div>
              <div className="col-2">{active || "0"}</div>
              <div className="col-2">{inactive || "0"}</div>
              <div className="col-2">{expired || "0"}</div>
              <div className="col-1">{ended || "0"}</div>
              <div className="col-3">
                ₦{formatAmount((totalBound || 0) * 100)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractsStatusBar;