import React, { useMemo, useState } from "react";
import "../../../../assets/Styles/Dashboard.css";
import {
  CreateSVG,
  ExportSVG,
  FilterSVG,
  BackSVG,
  TerminalSvg2,
  MarkSVG,
  MissSVG
} from "../../../../assets/Svgs/Svgs";
import { CSVLink } from "react-csv";
import toSentenceCase from "../../../../lib/Functions/toSentenceCase";
import Pagination from "../../../../lib/Components/Pagination";
import CreatePosUser from "./CreatePosUser";

const PosUsersList = props => {
  const [isCreatePos, setIsCreatePos] = useState(false);

  const [isPosUserSelected, setIsPosUserSelected] = useState(false);
  const [selectedPosUser, setSelectedPosUser] = useState({});
  const { oVendorReducer, onHideStatusBar } = props;
  const allPosUsers = oVendorReducer.allPosUsers;
  let totalCount = allPosUsers.count;
  let posData = allPosUsers ? allPosUsers.data : [];
  const [pageNo, setPageNo] = useState(1);
  const pageSize = 10;
  const start = (pageNo - 1) * pageSize;
  const end = start + pageSize;

  const posList =
    posData &&
    posData.slice(start, end).map((value, i) => {
      const { firstName, lastName, id, status, phone, userEmail, terminalName } = value;
      return (
        <tr key={i}>
          <td className="table-description font-weight-bold">
            {id.userId}
          </td>
          <td className="table-description font-weight-bold">
            {id.usercode}
          </td>
          <td className="table-description font-weight-bold">
            {firstName + " " + lastName}
          </td>
          <td className="table-description">{phone}</td>
          <td className="table-description">{userEmail}</td>
          <td className="table-description">{terminalName || "----"}</td>
          <td className={"table-status"}>
            <div
              className={`status-btn ${
                status === "ACTIVE" ? "success" : "error"
              }`}
            >
              {toSentenceCase(status)}
            </div>
          </td>
          <td
            className="table-action"
            onClick={() => {
              setIsPosUserSelected(true);
              onHideStatusBar(true);
              setSelectedPosUser(value);
            }}
          >
            View
          </td>
        </tr>
      );
    });
  
  const dataToExport = useMemo(() => {
    return posData && posData.map(({ id, firstName, lastName, phone, address, userLevel, status,
      image, userEmail, userType, createdAt, updatedAt, terminalId, terminalName }) => {
      return {
        userId: id.userId, userCode: id.usercode, firstName, lastName, phone : `"${phone}"`,
        address: `${address.street}, ${address.city}, ${address.state}, ${address.country}`,
        userLevel, status, image, userEmail, userType, createdAt, updatedAt, terminalId, terminalName
      }
    })
  }, [posData])
  const csvHeader = [
    { label: "User ID", key: "userId" },
    { label: "User Code", key: "userCode" },
    { label: "Firstname", key: "firstName" },
    { label: "Lastname", key: "lastName" },
    { label: "Phone number", key: "phone"},
    { label: "Address", key: "address"},
    { label: "User's Level", key: "userLevel"},
    { label: "Status", key: "status"},
    { label: "Email", key: "userEmail"},
    { label: "User type", key: "userType"},
    { label: "Terminal ID", key: "terminalId"},
    { label: "Terminal Name", key: "terminalName"},
    { label: "Created At", key: "createdAt"},
    { label: "Updated At", key: "updatedAt"},
  ]
  return (
    <div className="disbursed-cards-container row">
      {!isCreatePos && !isPosUserSelected && (
        <>
          <div
            className="rfid-menu-link"
            style={{ marginBottom: 0, marginRight: 20 }}
          >
            <span style={{ color: "#000000" }} className="active">
              All POS Users
            </span>
          </div>
          {
            <>
              <div
                className="create-new-tags-btn"
                onClick={() => {
                  onHideStatusBar(true);
                  setIsCreatePos(true);
                }}
              >
                <span className="align-middle">
                  <CreateSVG /> Create new POS user
                </span>
              </div>
              {
                <div className="terminals-export-btn">
                  <CSVLink
                    data={dataToExport || []}
                    headers={csvHeader}
                    filename={"POS Users.csv"}
                    className=""
                    target="_blank"
                  >
                    <span className="text-white">
                      Export CSV <ExportSVG />
                    </span>
                  </CSVLink>
                </div>
              }{" "}
            </>
          }
          {
            <table className="table table-hover">
              <thead>
                <tr>
                  <td>User ID</td>
                  <td>User Code</td>
                  <td>Name</td>
                  <td>Phone</td>
                  <td>Email</td>
                  <td>Terminal Name</td>
                  <td>
                    Status <FilterSVG />
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {posList || (
                  <tr>
                    <td colSpan={7} className="text-center">
                      No POS User available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          }

          {
            <Pagination
              count={totalCount}
              pageSize={pageSize}
              fetchMethod={(pageNo, pageSize) => {
                setPageNo(pageNo);
              }}
            />
          }
        </>
      )}

      <CreatePosUser
      {...props}
        isCreatePos={isCreatePos}
        onBackHandler={() => {
          setIsCreatePos(false);
          onHideStatusBar(false);
        }}
      />

      {isPosUserSelected && (
        <>
          <div
            onClick={() => {}}
            className="rfid-menu-link"
            style={{
              fontFamily: "Source Sans Pro",
              marginTop: 0,
              marginBottom: 0,
              marginRight: 0,
              paddingBottom: 6,
              width: "100%",
              borderWidth: 0,
              borderBottomWidth: 1,
              borderStyle: "solid",
              borderBottomColor: "#5F738C"
            }}
          >
            <span style={{ color: "#000000", fontSize: 25 }} className="active">
              POS user details
            </span>
          </div>
          <div
            className="terminal-back-btn"
            onClick={() => {
              setSelectedPosUser({});
              setIsPosUserSelected(false);
              onHideStatusBar(false);
            }}
          >
            <span>
              <BackSVG /> Back
            </span>
          </div>

          <div className="row mt-2" style={{ width: "100%" }}>
            <div className="col-10 offset-1 mt-2 p-3">
              <div className="row bg-rfid-details mr-2 p-5 rounded">
                <div className="col-1">
                  <TerminalSvg2 />
                </div>
                <div className="col-11">
                  <span
                    className="title d-inline-block mb-4 pb-2"
                    style={{ borderBottom: "1px solid #c4c4c4" }}
                  >
                    USER ID: {selectedPosUser.id.usercode}-
                    {selectedPosUser.id.userId}
                  </span>
                  <div className="row" style={{ width: "100%" }}>
                    <div className="row ml-0 contract-modal-heading">
                      <div className="col-3">Name</div>
                      <div className="col-3">Phone Number</div>
                      <div className="col-3">Email</div>
                      <div className="col-3">Status</div>
                    </div>
                    <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                      <div className="col-3">
                        {toSentenceCase(
                          `${selectedPosUser.firstName} ${selectedPosUser.lastName}` ||
                            "Unavailable"
                        )}
                      </div>
                      <div className="col-3">
                        {toSentenceCase(selectedPosUser.phone || "Unavailable")}
                      </div>
                      <div className="col-3">
                        {toSentenceCase(
                          selectedPosUser.userEmail || "Unavailable"
                        )}
                      </div>
                      <div className="col-3">
                        {toSentenceCase(selectedPosUser.status)}{" "}
                        {selectedPosUser.status === "ACTIVE" ? (
                          <MarkSVG size={14} />
                        ) : (
                          <MissSVG size={14} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row bg-rfid-details mr-2 mt-3 p-5 rounded">
                <div className="col-1"></div>
                <div className="col-11">
                  <div className="row mb-5" style={{ width: "100%" }}>
                    <div className="row ml-0 contract-modal-heading">
                      <div className="col-3">Street</div>
                      <div className="col-3">City</div>
                      <div className="col-3">State</div>
                      <div className="col-3">Country</div>
                    </div>
                    <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                      <div className="col-3">
                        {toSentenceCase(
                          `${selectedPosUser.address.street}` || "Unavailable"
                        )}
                      </div>
                      <div className="col-3">
                        {toSentenceCase(
                          selectedPosUser.address.city || "Unavailable"
                        )}
                      </div>
                      <div className="col-3">
                        {toSentenceCase(
                          selectedPosUser.address.state || "Unavailable"
                        )}
                      </div>
                      <div className="col-3">
                        {toSentenceCase(selectedPosUser.address.country) ||
                          "Unavailable"}
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ width: "100%" }}>
                    <div className="row ml-0 contract-modal-heading">
                      <div className="col-3">Terminal Name</div>
                    </div>
                    <div className="row ml-0 font-weight-bold mt-2 contract-modal-value">
                      <div className="col-3">
                        {toSentenceCase(
                          `${selectedPosUser.terminalName || "----"}`
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PosUsersList;
