import { customConfig } from '../actions/action_constants';
import { authHeader } from '../helpers/authHeader';
import createGuest from "cross-domain-storage/guest";
import CryptoJS from 'crypto-js';

export const userService = {
  loginX,
  login,
  logout,
};

export const vendorService = {
  getAllVendors,
  createVendor,
  getTransactionsByVendor,
  getTransactionsByTerminal,
  getTransactionsByLocation,
  getTransactionsByProduct,
};

export const customerService = {
  getVendorsThatAgreeToSignWithCustomer,
  getCustomersContractWithAVendor,
  getAllCustomerContracts,
  getSchemeById,
  getSchemeBySchemeMgrId,
  getTransactionsByCustomer,
  getTransactionsById,
};

export const cardService = {
  getCardsByCustomer,
  getCardsByScheme,
  getCardsByNumber,
};

export const settlementService = {
  getSettlementsByVendor,
  getSettlementsByCustomer,
  getSettlementInvoice,
  getCustomerSettlementStatus,
};

export const voucherService = {
  getAllVouchers,
  getAllCustomerVouchers,
  getAllCustomerVouchersByStatus,
  createVoucher,
  getAllVouchersByStatus,
  getVoucherBySerial,
};

async function loginX(reason, redirectTo = null) {
  logout();// clear all previously stored data

  if (reason === 'ACCESSDENIED') {
    window.location.href = `${customConfig.FRONT_SSO_URL}${redirectTo || '?d=isw'}`;
  } else if (reason === 'NODATAFROMSTORE') {
    window.location.href = `${customConfig.FRONT_SSO_URL}/signin?r=sf-contract`;
  } else if (reason === 'LEAVING') {
    window.location.href = `${customConfig.FRONT_SSO_URL}/logout`;
  } else {
    return new Promise((resolve, reject) => {
      createGuest(customConfig.FRONT_SSO_URL).get("USER", (error, value) => {
        let errorCode;
        if (value) {
          const bytes = CryptoJS.AES.decrypt(value.toString(), "smartware.spa");
          const response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

          delete response.decoded;// delete passport payload
          delete response.url;// remove irrelevant url parameter

          if (response && response.modules.includes("CONTRACT_MANAGEMENT")) {
            // login successful if there's a user in the response
            localStorage.setItem('user', JSON.stringify(response));
            localStorage.setItem('token', JSON.stringify(response.access_token));

            return resolve({ data: response })
          } else {
            errorCode = 'ACCESSDENIED'; //no access
          }
        } else {
          errorCode = 'NODATAFROMSTORE';
        }

        reject(errorCode)
      });
    });
  }
}

function login(userEmail, secret) {
  /* const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userEmail, secret }),
  };

  return fetch(`${customConfig.API_URL}api/auth/userlogin`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      localStorage.setItem('user', JSON.stringify(user.data));
      localStorage.setItem('token', JSON.stringify(user.status.message));

      return user;
      // if (user) {
      //   // store user details and basic auth credentials in local storage
      //   // to keep user logged in between page refreshes
      //   user.authdata = window.btoa(userEmail + ':' + secret);
      // }

      // return user;
    }); */
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

function getAllVendors(page, size, q = '') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL}api/vendors/all?page=${page}&size=${size}&q=${q}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getVendorsThatAgreeToSignWithCustomer(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL}api/customers/vendors?cid=${id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getCustomersContractWithAVendor(cid, vid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL}api/contracts/ccv?${cid}=2&vid=${vid}    `;
  return fetch(url, requestOptions).then(handleResponse);
}

function getAllCustomerContracts(cid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL}api/contracts/customers?cid=${cid}&page=1&size=50`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getTransactionsByVendor(vid, filterParams) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let params = "";
  let options = filterParams || {};
  const customerNum = options.customerNum || "";
  const location = options.location || "";
  const status = options.status || 2;
  const startDate = options.startDate || "";
  const endDate = options.endDate || "";

  if (customerNum.length) params+=`&customerNum=${customerNum}`;
  if (location.length) params+=`&location=${location}`;
  if (status) params+=`&status=${status}`;
  if (startDate) params+=`&startDate=${startDate}`;
  if (endDate) params+=`&endDate=${endDate}%2023:59:59`

  let url = `${customConfig.API_URL_CARD_AND_TRANS}api/v1/transactions?vendorNum=${vid}&pageNum=1&pageSize=100${params}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getTransactionsByTerminal(tid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_CARD}api/transaction/terminal?terminal=${tid}&page=1&size=100`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getTransactionsByProduct(pid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_CARD}api/transaction/product?product=${pid}&page=1&size=50`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getTransactionsByLocation(lid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_CARD}api/transaction/location?location=${lid}&page=1&size=100`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getTransactionsById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_CARD}api/transaction/find?find=${id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getTransactionsByCustomer(cid, pageNum, pageSize, startDate, endDate) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${
    customConfig.API_URL_CARD_AND_TRANS
  }api/v1/transactions?customerNum=${cid}&pageNum=${pageNum}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`;
  return fetch(url, requestOptions).then(handleResponse);
}

// cards Endpoints
function getCardsByCustomer(cid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_CARD}api/card/fetch/customer?customer=${cid}&page=1&size=100`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getCardsByScheme(sid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL}api/card/fetch/scheme?scheme=${sid}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getCardsByNumber(num) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_CARD}api/card/find/number?number=${num}`;
  return fetch(url, requestOptions).then(handleResponse);
}

// settlements endpoints
function getSettlementsByVendor(vid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_REPORT}api/settlement/vendor?vendor=${vid}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getSettlementsByCustomer(cid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_REPORT}api/settlement/customer?customer=${cid}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getSettlementInvoice(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_REPORT}api/settlement/invoice?invoice=${id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getCustomerSettlementStatus(cid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_REPORT}api/settlement/customer/status?status=${cid}&page=1&size=100`;
  return fetch(url, requestOptions).then(handleResponse);
}

// voucher Endpoints
function getAllVouchers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_VOUCHER}api/voucher/find/all`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getAllCustomerVouchers(cid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_VOUCHER}api/voucher/find/customer?customer=${cid}&page=0&size=100`;
  return fetch(url, requestOptions).then(handleResponse);
}

// api/voucher/bulk
// api/voucher/find:

function createVoucher(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(`${customConfig.API_URL_VOUCHER}api/voucher/single`, body, requestOptions).then(handleResponse);
}

function getAllCustomerVouchersByStatus(status) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_VOUCHER}api/voucher/find/customer/status?status=${status}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getAllVouchersByStatus(status) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_VOUCHER}api/voucher/find/all/status?status=${status}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getVoucherBySerial(serial) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL_VOUCHER}api/voucher/find/serial?serial=${serial}`;
  return fetch(url, requestOptions).then(handleResponse);
}

// PUT api/card/update/name:
// PUT api/card/suspend:
// PUT api/card/enable:
// POST api/card/apply/scheme:
// PUT api/settlement/status:

function getSchemeById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL}api/schemes?id=${id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getSchemeBySchemeMgrId(sid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${customConfig.API_URL}api/schemes/schememanager?sid=${sid}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function createVendor(userEmail, secret) {
  // const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ userEmail, secret })
  // };
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(`${customConfig.API_URL}api/auth/userlogin`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
      }

      return user;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        window.location.reload();
      }
      const error = data && (data.status.description || data.status.error || data.status.message || response.statusText);
      return Promise.reject(error);
    }
    return data;
  });
}

// function handleResponse(response) {
//   return response.text().then(text => {
//       const data = text && JSON.parse(text);
//       if (!response.ok) {
//           if (response.status === 401) {
//               // auto logout if 401 response returned from api
//               logout();
//               location.reload(true);
//           }

//           const error = (data && data.message) || response.statusText;
//           return Promise.reject(error);
//       }

//       return data;
//   });
// }
