import React from "react";
import "../../../assets/Styles/Dashboard.css";
import {TransactionSVG} from "../../../assets/Svgs/Svgs";
import {shortMonthNames} from "../../../lib/Functions/DateNames";
import {NavLink} from "react-router-dom";

const StationStatusBar = (props) => {
  const {vendorCustomer, oVendorReducer} = props;
  const { allPosUser } = oVendorReducer;
  const d = new Date();
  let terminalUserCount = allPosUser?.count || 0;
  // let vendorUserCount = customerUser.users?.length;
  return (
    <div className="status-container row">
      <div className="compartment compartment-one col col-5">
        <span className="title d-inline mr-3">Transaction Tracking</span>
        <TransactionSVG color={"#06CC3E"} />
        <span className="sub-title">
          Summary of your transactions updated daily.
        </span>
        <span className="count">{0}</span>
        <span className="count-description">{`Transactions from ${vendorCustomer.activeCustomers.length}  Customer(s)`}</span>
        <span className="count-time">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
        <NavLink to="/vendor-admin/station/reports">
          <div className="blue-bg-btn">View Report</div>
        </NavLink>
        <div className="dashboard-short-border-line"></div>
      </div>
      <div className="compartment compartment-two col col-4">
        <div className="row">
          <div className="text-center col-6">
            <p className="title">All POS Users</p>
            <span className="sub-title"></span>
            <span className="count">{terminalUserCount}</span>
          </div>

          {/* <div className="text-center col-6">
            <p className="title">User Actions</p>
            <span className="sub-title"></span>
            <span className="count">{oTrailReducer.vtrails.count || "0"}</span>
            <br />
            <NavLink to="/vendor-admin/trail">
              <div className="white-bg-btn">View Trail</div>
            </NavLink>
          </div> */}
        </div>

        <div className="dashboard-short-border-line"></div>
      </div>

      <div className="compartment text-center col-3">
        <div className="row">
          {/* <div className="text-center col-6">
            <p className="title">Customers</p>
            <span className="sub-title"></span>
            <span className="count">
              {vendorCustomer.activeCustomers.length}
            </span>
          </div> */}

          <div className="text-center col-6">
            <p className="title">Products</p>
            <span className="sub-title"></span>
            <span className="count">
              {oVendorReducer.oneVendor?.data?.products.length ?? 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationStatusBar;
