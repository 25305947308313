const oVendorReducer = (
  state = {
    terminalDetail: {},
    allVendors: [],
    allPosUsers: [],
    allTerminals: [],
    allTerminalsByVenId: [],
    allLocations: [],
    allVendorLocations: [],
    vendorCustomers: [],
    oneVendor: {},
    vendorProduct: [],
    isFetchedProduct: false,
    oneVendorContract: {},
    fetchingOvc: false,
    walletBalanceInContract: {},
    fetchingWbic: false,
    oneTerminal: {},
    fetching: false,
    fetched: false,
    error: null,
    bulkCreateModal: false,
    userCreateModal: false,
    bulkUploadModal: false,
    bulkUploadResultModal: false,
    userEditModal: false,
    userDeleteModal: false,
    createVendorRequestStatus: 0,
    createVendorRequestMessage: "",
    createLocationRequestStatus: 0,
    createLocationRequestMessage: "",
    createTerminalRequestStatus: 0,
    createTerminalRequestMessage: "",
    startUpdateTerminalRequest: false,
    updateTerminalRequestStatus: 0,
    updateTerminalRequestMessage: "",
    createPOSUserRequestStatus: 0,
    createPOSUserRequestMessage: "",
    createProductRequestStatus: 0,
    createProductRequestMessage: "",
    editProductRequestStatus: 0,
    editProductRequestMessage: "",
    addProdToVenRequestStatus: 0,
    addProdToVenRequestMessage: "",
    vendorStatus: "",
    isCreatingVendor: false,
    fetchingStatus: 0,
    vendorCreationErrorMessage: "",
    isUpdatingVendor: false,
    editingStatus: 0,
    editingAccountStatus: 0,
    startCreateProductRequest: false,
    isSigningContract: false,
    errSigningContract: null,
    statePrice: null,
    statePriceVolumes: null,
  },
  action
) => {
  switch (action.type) {
    case "FETCH_USERS": {
      return {...state, fetching: true};
    }

    case "RESET_ADD_OWNER_VENDOR": {
      return {
        ...state,
        isCreatingVendor: false,
        fetchingStatus: 0,
        vendorCreationErrorMessage: "",
      };
    }

    case "CREATE_VENDOR_START": {
      return {...state, isCreatingVendor: true, fetchingStatus: 0};
    }

    case "OCREATE_VENDOR_REQUEST":
      return {
        ...state,
        isCreatingVendor: false,
        fetchingStatus: action.response.status,
      };

    case "FETCH_All_LOCATIONS_FULLFILLED":
      return {...state, allLocations: action.payload};

    case "OCREATE_VENDOR_FAILED":
      return {
        ...state,
        isCreatingVendor: false,
        fetchingStatus: action.response.status,
        vendorCreationErrorMessage: action.response.message,
      };

    case "RESET_VENDOR_UPDATE": {
      return {
        ...state,
        fetchingStatus: 0,
        isUpdatingVendor: false,
      };
    }

    case "UPDATE_VENDOR_START": {
      return {...state, isUpdatingVendor: true, fetchingStatus: 0};
    }

    case "UPDATE_VENDOR_REQUEST": {
      const {response/* , id, customer */} = action;
      return {
        ...state,
        isUpdatingVendor: false,
        fetchingStatus: response.status,
        // createCustomerRequestMessage: action.response.message
      };
    }
    case "UPDATE_VENDOR_REJECTED": {
      return {
        ...state,
        isUpdatingVendor: false,
        fetchingStatus: action.response.status,
        vendorCreationErrorMessage: action.response.message,
      };
    }

    case "UPDATE_CREATE_VENDOR":
      return {
        ...state,
        createVendorRequestStatus: action.response.status,
        createVendorRequestMessage: action.response.message,
      };
    // return Object.assign({}, state, {
    //   createVendorRequestStatus: action.response.status,
    //   createVendorRequestMessage: action.response.message,
    // });
    case "CREATE_LOCATION_REQUEST":
      return Object.assign({}, state, {
        createLocationRequestMessage: action.response.message,
        createLocationRequestStatus: action.response.status,
      });
    case "UPDATE_LOCATION_REQUEST_PARAMS":
      return Object.assign({}, state, {
        createLocationRequestMessage: action.response.message,
        createLocationRequestStatus: action.response.status,
        createTerminalRequestMessage: action.response.message,
        createTerminalRequestStatus: action.response.status,
      });

    case "START_CREATE_TERMINAL_REQUEST":
      return {
        ...state,
        startCreateTerminalRequest: true,
      };

    case "CREATE_TERMINAL_REQUEST":
      return Object.assign({}, state, {
        startCreateTerminalRequest: false,
        createTerminalRequestMessage: action.response.message,
        createTerminalRequestStatus: action.response.status,
      });
    case "START_UPDATE_TERMINAL_REQUEST":
      return {
        ...state,
        startUpdateTerminalRequest: true,
      };

    case "UPDATE_TERMINAL_REQUEST":
      return Object.assign({}, state, {
        startUpdateTerminalRequest: false,
        updateTerminalRequestMessage: action.response.message,
        updateTerminalRequestStatus: action.response.status,
      });
    case "CREATE_POS_USERS_REQUEST":
      return Object.assign({}, state, {
        createPOSUserRequestMessage: action.response.message,
        createPOSUserRequestStatus: action.response.status,
      });
    case "EDIT_POS_USERS_REQUEST":
      return Object.assign({}, state, {
        editPOSUserRequestMessage: action.response.message,
        editPOSUserRequestStatus: action.response.status,
      });
    case "START_CREATE_PRODUCT_REQUEST":
      return {
        ...state,
        startCreateProductRequest: true,
      };

    case "CREATE_PRODUCT_REQUEST":
      return Object.assign({}, state, {
        createProductRequestMessage: action.response.message,
        startCreateProductRequest: false,
        createProductRequestStatus: action.response.status,
      });
    case "CREATE_PRODUCT_REQUEST_FAIL":
      return Object.assign({}, state, {
        createProductRequestMessage: action.response.message,
        createProductRequestStatus: action.response.status,
        startCreateProductRequest: false,
      });
    case "CREATE_PRODUCT_REQUEST_FAILED":
      return {
        ...state,
        createProductRequestMessage: action.response.message,
        createProductRequestStatus: action.response.status,
        startCreateProductRequest: false,
      };
    case "RESET_CREATE_PRODUCT":
      return {
        ...state,
        createProductRequestMessage: "",
        createProductRequestStatus: 0,
        startCreateProductRequest: false,
      };

    case "START_EDIT_PRODUCT_REQUEST":
      return {
        ...state,
        startEditProductRequest: true,
      };

    case "EDIT_PRODUCT_REQUEST":
      return Object.assign({}, state, {
        startEditProductRequest: false,
        editProductRequestMessage: action.response.message,
        editProductRequestStatus: action.response.status,
      });
    case "EDIT_PRODUCT_REQUEST_FAIL":
      return Object.assign({}, state, {
        startEditProductRequest: false,
        editProductRequestMessage: action.response.message,
        editProductRequestStatus: action.response.status,
      });

    case "START_ADD_PRODUCT_TO_VENDOR_REQUEST":
      return {
        ...state,
        startAddProductToVendorRequest: true,
        addProdToVenRequestStatus: 0,
      };
    case "ADD_PRODUCT_TO_VENDOR_REQUEST":
      return Object.assign({}, state, {
        addProdToVenRequestStatus: action.response.status,
        startAddProductToVendorRequest: false,
        addProdToVenRequestMessage: action.response.message,
      });
    case "ADD_PRODUCT_TO_VENDOR_REQUEST_FAILED":
      return Object.assign({}, state, {
        addProdToVenRequestStatus: action.response.status,
        startAddProductToVendorRequest: false,
        addProdToVenRequestMessage: action.response.message,
      });
    case "RESET_ADD_PRODUCT_T0_VENDOR":
      return {
        ...state,
        addProdToVenRequestStatus: 0,
        startAddProductToVendorRequest: false,
        addProdToVenRequestMessage: "",
      };

    case "TOGGLE_MODAL": {
      return {...state, userCreateModal: !state.userCreateModal};
    }
    case "FETCH_USERS_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_PRODUCTS_BYVENDOR_FULLFILLED": {
      return {
        ...state,
        vendorProduct: action.payload?.data || {},
        isFetchedProduct: true,
      };
    }
    case "FETCH_PRODUCTS_BYVENDOR_REJECTED": {
      return {
        ...state,
        isFetchedProduct: false,
      };
    }

    case "FETCH_All_VENDORS_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_All_VENDORS_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        allVendors: action.payload,
      };
    }

    case "FETCH_All_VENDORS_REJECTED": {
      return {
        ...state,
        fetching: false,
        error: action.payload,
        allVendors: [],
      };
    }

    case "FETCH_VENDOR_CUSTOMERS_START": {
      return {...state, fetching: true, fetched: true};
    }
    case "FETCH_VENDOR_CUSTOMERS_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        vendorCustomers: action.payload,
      };
    }

    case "FETCH_VENDOR_CUSTOMERS_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }
    case "FETCH_All_TERMINALS_REJECTED": {
      return {...state, allTerminals: [], fetching: false, error: action.payload};
    }
    case "FETCH_All_TERMINALS_VENDOR_ID_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        allTerminalsByVenId: action.payload,
      };
    }
    case "FETCH_All_TERMINALS__VENDOR_ID_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }
    case "FETCH_All_TERMINALS_START": {
      return {...state, fetching: true, fetched: false};
    }
    case "FETCH_All_TERMINALS_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        allTerminals: action.payload,
      };
    }

    case "FETCH_All_TERMINALUSERS_START": {
      return {...state, fetching: true, fetched: false};
    }
    case "FETCH_All_TERMINALUSERS_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        allPosUsers: action.payload,
      };
    }
    case "FETCH_All_TERMINALUSERS_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    }
    case "FETCH_LOCATIONS_BYVENDOR_START": {
      return {...state, fetching: true, fetched: false};
    }
    case "FETCH_LOCATIONS_BYVENDOR_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        allVendorLocations: action.payload,
      };
    }
    case "FETCH_LOCATIONS_BYVENDOR_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    }
    case "FETCH_All_LOCATIONS_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }
    case "FETCH_ONE_VENDOR_START": {
      return {...state, fetching: true, fetched: true, vendorStatus: ""};
    }
    case "FETCH_ONE_VENDOR_FULLFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        oneVendor: action.payload,
      };
    }
    case "FETCH_ONE_VENDOR_REJECTED": {
      return {...state, fetching: false, error: action.payload};
    }

    case "FETCH_ONE_VENDOR_CONTRACT_START": {
      return { ...state, fetchingOvc: true, oneVendorContract: {} }
    }
    case "FETCH_ONE_VENDOR_CONTRACT_FULLFILLED": {
      return {
        ...state,
        fetchingOvc: false,
        fetching: false,
        fetched: true,
        oneVendorContract: action.payload,
      };
    }
    case "FETCH_ONE_VENDOR_CONTRACT_REJECTED": {
      return {...state, fetchingOvc: false, fetching: false, error: action.payload};
    }

    case "FETCH_WALLET_BALANCE_IN_CONTRACT_START": {
      return { ...state, fetchingWbic: true, walletBalanceInContract: {} }
    }
    case "FETCH_WALLET_BALANCE_IN_CONTRACT_FULLFILLED": {
      return {
        ...state,
        fetchingWbic: false,
        fetching: false,
        fetched: true,
        walletBalanceInContract: action.payload,
      };
    }
    case "FETCH_WALLET_BALANCE_IN_CONTRACT_REJECTED": {
      return {...state, fetchingWbic: false, fetching: false, error: action.payload};
    }

    case "TOGGLE_VENDOR_STATUS_START": {
      return {...state, isTogglingStatus: true, editingAccountStatus: 0};
    }
    case "TOGGLE_VENDOR_STATUS_FULLFILLED": {
      const {/* payload, */ id, mode, editingAccountStatus} = action;
      const newVendors = [...state.allVendors.data];
      const vendorToUpdate = newVendors.findIndex((vendor) => vendor.id === id);
      newVendors[vendorToUpdate].status = mode;
      return {
        ...state,
        editingAccountStatus,
        isTogglingStatus: false,
        allVendors: {...state.allVendors, data: newVendors},
      };
      // return { ...state, fetching: false, fetched: true, vendorStatus: action.payload};
    }

    case "TOGGLE_VENDOR_STATUS_REJECTED": {
      return {
        ...state,
        isTogglingStatus: false,
        error: action.payload,
        editingAccountStatus: 2,
      };
    }

    case "RESET_STATUS_FETCH": {
      return {...state, editingAccountStatus: 0, isTogglingStatus: false};
    }

    case "ADD_VENDOR_USER": {
      return {
        ...state,
        userCreateModal: false,
        allVendors: [...state.allVendors, action.payload],
      };
    }

    case "UPDATE_USERS": {
      const {id} = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex((user) => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers,
      };
    }
    case "DELETE_USERS": {
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    }

    case "SIGN_VENDORCONTRACT_START": {
      return {
        ...state,
        isSigningContract: true,
        errSigningContract: null,
      };
    }

    case "SIGN_VENDORCONTRACT_FULFILLED": {
      const newVendorData = {...state.oneVendorContract.data};
      newVendorData.contractStatus = "ACTIVE";
      return {
        ...state,
        isSigningContract: false,
        isContractActivated: true,
        oneVendorContract: {...state.oneVendorContract, data: newVendorData},
      };
    }

    case "SIGN_VENDORCONTRACT_REJECTED": {
      return {
        ...state,
        isSigningContract: false,
        errSigningContract: action.payload,
      };
    }

    case "REMOVE_ONE_CONTRACT": {
      return {
        ...state,
        oneVendorContract: {},
      };
    }

    case 'GET_POSUSER_START': {
      return { ...state, fetching: true };
    }
    case 'GET_POSUSER_SUCCESS': {
      return { ...state, fetching: false, fetched: true, allPosUser: action.payload };
    }
    case 'GET_POSUSER_ERR': {
      return { ...state, fetching: false, error: action.payload };
    }

    case 'GET_TERMINAL_DETAILS': {
      return { ...state, fetching: true };
    }
    case 'GET_TERMINAL_DETAILS_SUCCESS': {
      return { ...state, fetching: false, fetched: true, terminalDetail: action.payload };
    }
    case 'GET_TERMINAL_DETAILS_ERR': {
      return { ...state, fetching: false, error: action.payload };
    }

    case 'FETCH_STATE_PRICE': {
      return {...state, fetching: true, statePrices: null };
    }
    case 'FETCH_STATE_PRICE_COMPLETED': {
      return {...state, fetching: false, success: true, statePrices: action.payload };
    }
    case 'FETCH_STATE_PRICE_ERROR': {
      return {...state, fetching: false, success: false, error: action.payload };
    }

    case 'FETCH_STATES' :{ return {...state, fetchingStates: true }; }
    case 'FETCH_STATES_COMPLETED' :{  return {...state, fetchingStates: false, success: true, states: action.payload }; }
    case 'FETCH_STATES_ERROR' :{ return {...state, fetchingStates: false, success: false, error: action.payload }; }

    case 'FETCH_STATEVOLUME' : { return {...state, fetchingPriceVolume: true}}
    case 'FETCH_STATEVOLUME_FULLFILLED' : { return {...state, fetchingPriceVolume: false, statePriceVolumes: action.payload }}
    case 'ERROR': { return {...state, fetchingPriceVolume: false, statePriceVolumes: null, err: action.payload }}

    case 'CREATE_STATE_PRICE': { return {...state, creating: true}}
    case 'CREATE_STATE_PRICE_COMPLETED': { return {...state, creating: false, success: true, statePrice: action.payload}}
    case 'CREATE_STATE_PRICE_ERROR': { return {...state, creating: false, success: true, statePrice: action.payload}}
    case 'RESET_STATE_PRICE': { return {...state, creating: false, success: false, statePrice: null, error: null}} 

    case 'UPLOAD_STATE_PRICE': { return {...state, uploading: true}}
    case 'UPLOAD_STATE_PRICE_COMPLETED': { return {...state, uploading: false, success: true, stateUpload: action.payload}}
    case 'UPLOAD_STATE_PRICE_ERROR': { return {...state, uploading: false, success: false, error: action.payload}}
    case 'RESET_UPLOAD_PRICE': { return {...state, uploading: false, success: false, stateUpload: null, error: null}} 

    default:
      return state;
  }
};

export default oVendorReducer;
