import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfigureVendorFee, EditVendorFee, getVendorFee } from "../../../../actions/OwnerActions/OwnerVendorMgtActions/OwnerVendorMgtConstants";
import { Ellipse } from "../../../../assets/Svgs/Svgs";
import { LoadingSVG, UserCompleteMarkSVG, } from "../../../../assets/Svgs/Svgs";

const VendorSettings = ({ vendorId, onEditingDone }) => {
  const { feeConfig = {}, feeProcessing, feeLoading, feeStatus, } = useSelector(state => state.ownerUsers);
  const dispatch = useDispatch();
  const [chargeType, setChargeType] = useState(feeConfig.chargeType ? feeConfig.chargeType : '');
  const [charge, setCharge] = useState(feeConfig.charge ? feeConfig.charge : '');
  const [errMessage, setErrMessage] = useState("");
  const [isCapped, setIsCapped] = useState();
  const [cap, setCap] = useState();

  useEffect(() => {
    dispatch(getVendorFee(vendorId));
  }, [vendorId])

  useEffect(() => {
    setChargeType(feeConfig.chargeType ? feeConfig.chargeType : '');
    setCharge(feeConfig.charge ? feeConfig.charge : '');
    setIsCapped(feeConfig.capped? feeConfig.capped: false);
    setCap(feeConfig.cap? feeConfig.cap : '');
  }, [feeConfig.chargeType, feeConfig.charge, feeConfig.cap, feeConfig.isCapped])

  const handleFormSubmit = (e, type) => {
    e.preventDefault();
    if (!chargeType) {
      return setErrMessage('Select a Transaction fee type');
    }
    if (!charge) {
      return setErrMessage('Select a Charge')
    }
    if (chargeType === 'PERCENT' && charge > 100) {
      return setErrMessage("Charge can't be greater than 100%");
    }
    
    if(isCapped && !cap){
      return setErrMessage("Enter Capped amount");
    }

    let data = {
      vendorNumber: vendorId,
      charge,
      chargeType,
      capped: isCapped,
      cap
    }
    if (type !== 'edit') {
      dispatch(ConfigureVendorFee(data));
    }

    if (type === 'edit') {
      dispatch(EditVendorFee(data))
    }
  }

  useEffect(() => {
    if (errMessage) {
      setErrMessage("");
    }

    if(chargeType === "FLATRATE"){
      setIsCapped(false);
    }
  }, [charge, chargeType])
  return (
    <form onSubmit={handleFormSubmit}>
      {!feeProcessing && !feeLoading && feeStatus !== 1 && <>
        <div className="card-management-row">
          <div className="title"> Transaction Fee type</div>
          <div className="radios">
            <div
              onClick={() => setChargeType('PERCENT')}
              className={`choice-btn ${chargeType === "PERCENT" ? 'enable-btn' : 'inactive-btn'} first`}>
              Percentage Fee <Ellipse color={chargeType === "PERCENT" ? "#11B01A" : ''} />
            </div>
            <div onClick={() => setChargeType('FLATRATE')}
              className={`choice-btn ${chargeType === "FLATRATE" ? 'enable-btn' : 'inactive-btn'} second`}>
              Flat fee <Ellipse color={chargeType === "FLATRATE" ? "#11B01A" : ''} />
            </div>
          </div>
        </div>
        {chargeType === "PERCENT" &&
          <div className="card-management-row">
            <div className="title">Do you want to Cap it?</div>
            <div className="radios">
              <div
                onClick={() => setIsCapped(true)}
                className={`choice-btn ${isCapped ? 'enable-btn' : 'inactive-btn'} first`}>
                Cap fee <Ellipse color={isCapped ? "#11B01A" : ''} />
              </div>
              <div onClick={() => setIsCapped(false)}
                className={`choice-btn ${!isCapped ? 'enable-btn' : 'inactive-btn'} second`}>
                Don't cap fee <Ellipse color={!isCapped ? "#11B01A" : ''} />
              </div>
            </div>
          </div>}
        {isCapped && <div className="password">
          <div className="title">Cap - Fee</div>
          <div className="form-group">
            <input value={cap} onChange={(e) => setCap(e.target.value)} type="number" className="form-control" />
          </div>
        </div>}
        <div className="password">
          <div className="title">Fee</div>
          <div className="form-group">
            <input value={charge} onChange={(e) => setCharge(e.target.value)} type="number" className="form-control" />
          </div>
        </div>
        {errMessage && <div className="error-container">
          <div className="error-text">{errMessage}</div>
        </div>}

        {!feeConfig.chargeType ? <button style={{ width: '40%', float: 'left' }} type='submit' className="confirm-btn">Confirm changes</button> :
          <button style={{ width: '40%', float: 'left' }} onClick={(e) => handleFormSubmit(e, 'edit')} type='button' className="confirm-btn">Save</button>}
      </>}
      {(feeProcessing || feeLoading) && <>
        <>
          <div className="text-center mt-5 header">
            Configuring vendor fee....
          </div>
          <div className="text-center mt-5 mb-5">
            <LoadingSVG />
          </div>
        </>
      </>}
      {feeStatus === 1 && (
        <>
          <div className="text-center mt-5 mb-4">
            <span
              className="d-block font-weight-bold"
              style={{
                fontSize: "20px",
                color: "#4B4B4B",
                margin: "1rem"
              }}
            >
              Thank you! You are all set!
            </span>
            <UserCompleteMarkSVG />
            <span
              className="d-block quickteller-blue font-weight-bold"
              style={{
                fontSize: "1rem",
                color: "#00425F",
                margin: "1.25rem"
              }}
            >
              Awaiting Approval
            </span>
          </div>
          <div className="row modal-action-buttons">
            <div className="col-12 text-center">
              <button
                className="back"
                onClick={() => {
                  onEditingDone();
                  dispatch({ type: 'VENDOR_CONFIG_RESET' })
                }}
                style={{ width: 120 }}
              >
                Back
              </button>
            </div>
          </div>
          <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
        </>
      )}
      {/* <div className="password">
        <div className="title">Admin Password</div>
        <div className="form-group">
          <input type="password" className="form-control" />
        </div>
      </div> */}

    </form>
  );
};

export default VendorSettings;