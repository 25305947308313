import React, { useState, useEffect } from "react";
import SubComponentHeader from "../../../lib/Components/SubComponentHeader";
import FormWarning from "../../admin/shared/FormWarning";
import { useDispatch, useSelector } from "react-redux";
import ToastAlert from "../../../lib/Components/ToastAlert";
import CustomInput from "../../../lib/Components/CustomInput";
import { Spin, Icon } from "antd";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export default function CreateContract({
  isCreateContract,
  onBackHandler,
  oVendorReducer,
  onFetchVendorCustomers,
  onCreateContract,
  oContractReducer,
}) {
  const [formData, setFormData] = useState({
    name: "",
    customer: "",
    vendor: "",
    bound: "",
    contractStartDate: "",
    frequency: 1,
    contractCycleType: "DAILY",
    contractCycleTypeDays: 0,
    autoRenew: false,
    description: "",
  });

  const [isCreatingContract, setIsCreatingContract] = useState(false);
  const [contractValidationError, setContractValidationError] = useState(false);
  const [toast, setToast] = useState({ class: "", text: "" });
  const [autoRenewValue, setAutoRenewValue] = useState("")
  const dispatch = useDispatch();
  // const _vendorCustomers = useSelector(
  //   ({ oVendorReducer }) => oVendorReducer.vendorCustomers
  // );
  const loading = useSelector(({ oVendorReducer }) => oVendorReducer.fetching);

  const ven = oVendorReducer.allVendors;
  const venRow = ven.data ? ven.data : ven;
  const vendorData = venRow.data ? venRow.data : venRow;
  const vendorCustomers = oVendorReducer.vendorCustomers;

  const vendorOption =
    vendorData &&
    vendorData.map((value, key) => {
      return (
        <option value={value.id} key={key}>
          {value.name}
        </option>
      );
    });

  const customers = vendorCustomers && vendorCustomers.data;
  const customerOption = customers
    ? customers.map((value, key) => {
      return (
        <option value={value.id} key={key}>
          {value.name}
        </option>
      );
    })
    : null;

  useEffect(() => {
    if (formData.vendor !== "") {
      const customerId =
        vendorData &&
        vendorData.find(
          (vendor) => vendor.id === parseInt(formData.vendor, 10)
        );
      onFetchVendorCustomers(customerId.id, 1, 1000000);
    }
  }, [formData.vendor]);

  const handleInput = (attr, event) => {
    // check if "event.detail" is available.
    // "event.detail" is from number component
    if (event.detail && event.target == null) {
      setFormData({ ...formData, [attr]: event.detail.value });
    } else {
      setFormData({ ...formData, [attr]: event.target.value });
    }
  };

  const isContractValidationError = () => {
    const {
      name,
      customer,
      vendor,
      bound,
      contractStartDate,
      contractCycleType,
      contractCycleTypeDays,
      description,
    } = formData;
    if (
      !name ||
      !vendor ||
      !customer ||
      !bound ||
      !contractStartDate ||
      (contractCycleType === "DAYS" && !contractCycleTypeDays) ||
      !description ||
      (Math.sign(bound) === -1) || !autoRenewValue
    ) {
      return true;
    } else {
      return false;
    }
  };

  const resetFormData = () => {
    setFormData({
      name: "",
      customer: "",
      vendor: "",
      bound: "",
      contractStartDate: "",
      frequency: 1,
      contractCycleType: "DAILY",
      contractCycleTypeDays: 0,
      autoRenew: null,
      description: "",
    });
    setAutoRenewValue("");
  };

  const createContract = (event) => {
    setContractValidationError(false);
    event.preventDefault();
    if (!!isContractValidationError()) {
      setContractValidationError(true);
      setToast({
        class: "",
        text: "All fields must be correctly filled to continue!",
      });
      return;
    }

    setIsCreatingContract(true);
    onCreateContract(formData);
  };

  useEffect(() => {
    if (!!isCreatingContract && oContractReducer.contractRequestStatus !== 1) {
      setToast({
        class: "",
        text:
          oContractReducer.contractRequestMessage ||
          "An unknown error has occurred!",
      });
      setContractValidationError(true);
      setIsCreatingContract(false);
    }
    if (!!isCreatingContract && oContractReducer.contractRequestStatus === 1) {
      resetFormData();
      setToast({
        class: "text-white bg-success",
        text:
          oContractReducer.contractRequestMessage ||
          "An unknown error has occured!",
      });
      setContractValidationError(true);
      setIsCreatingContract(false);
    }
    dispatch({
      type: "CREATE_CONTRACT_REQUEST",
      response: { status: 0, message: "" },
    });
  }, [oContractReducer.contractRequestStatus]);

  const handleAutoRenew =(e)=> {
    let renewStatus = false;
    if(e.target.value === 'auto-renew') {
      renewStatus = true;
    }

    if(e.target.value === 'one-off') {
      renewStatus = false;
    }
    setAutoRenewValue(e.target.value);
    setFormData({ ...formData, autoRenew: renewStatus })
  }

  const cancelForm =() => {
    resetFormData();
    onBackHandler();
  }

  return (
    <>
      {isCreateContract && (
        <>
          <SubComponentHeader
            heading="Create new contract"
            onBackHandler={cancelForm}
          />

          <div className="row mt-2" style={{ width: "100%" }}>
            <div className="offset-1 col-10 p-3">
              <div className="col-10 offset-1">
                <div className="row bg-rfid-detailsm form bg-white ml-1 mr-1 pl-4 pr-4">
                  <div className="row">
                    <div className="row mt-4 mr-2">
                      <FormWarning
                        inline={true}
                        message="Please note that all fields are compulsory"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <CustomInput
                      parentClass="col-4 mt-4 pb-3"
                      label="Contract Name"
                      value={formData.name}
                      handleInput={handleInput.bind(this, "name")}
                    />

                    <div className="col-4 mt-2 pb-3">
                      <label className="mt-3">Frequency</label>
                      <select
                        className="contract-select pl-2 mt-1"
                        name="contract-type"
                        value={formData.contractCycleType}
                        onChange={handleInput.bind(this, "contractCycleType")}
                      >
                        <option value="DAILY">Daily</option>
                        <option value="WEEKLY">Weekly</option>
                        <option value="MONTHLY">Monthly</option>
                        <option value="DAYS">Days</option>
                      </select>
                    </div>
                    <CustomInput
                      parentClass="col-4 mt-4 pb-3"
                      inputClass="pl-2"
                      label="Select Vendor"
                      value={formData.vendor}
                      data={vendorOption}
                      presetData="Select Vendor..."
                      isSelect={true}
                      handleInput={handleInput.bind(this, "vendor")}
                    />

                   {formData.contractCycleType === "DAYS" && (
                          <CustomInput
                            parentClass="col-4 mt-3"
                            label="No. Of Days"
                            type="number"
                            value={formData.contractCycleTypeDays}
                            handleInput={handleInput.bind(
                              this,
                              "contractCycleTypeDays"
                            )}
                          />
                        )}

                    {loading ? (
                      <div className="col-4 mt-3 pb-3">
                        <Spin
                          indicator={antIcon}
                          tip="Loading customers options..."
                        />
                      </div>
                    ) : (
                      <CustomInput
                        parentClass="col-4 mt-3 pb-3"
                        inputClass="pl-2"
                        label="Select Customer"
                        value={formData.customer}
                        data={customerOption}
                        presetData={
                          customerOption === null
                            ? "No customer available"
                            : "Select Customer..."
                        }
                        isSelect={true}
                        disabled={customerOption === null}
                        handleInput={handleInput.bind(this, "customer")}
                      />
                    )}

                    <CustomInput
                      parentClass="col-4 mt-3 pb-3"
                      label="Limit"
                      isNumber={true}
                      value={formData.bound}
                      handleInput={handleInput.bind(this, "bound")}
                    />

                    <CustomInput
                      parentClass="col-4 mt-3 pb-3"
                      label="Contract Start Date"
                      type="date"
                      value={formData.contractStartDate}
                      onBlur={() => {
                        const formattedDate = formData.contractStartDate
                          .split("/")
                          .join("-");
                        setFormData({
                          ...formData,
                          contractStartDate: formattedDate,
                        });
                      }}
                      handleInput={handleInput.bind(this, "contractStartDate")}
                    />
                    
                    <div className="col-6 mt-1 pl-4 pb-3 pt-2">
                      <div className="row">
                        <div style={{
                          paddingRight: 10,
                          width: '100%',
                          height: 25
                        }}>
                        <span style={{ float: "left",
                            marginLeft: 18
                        }}>
                          Contract Type
                        </span>
                        <span style={{
                          height: 5,
                          bottom: 10
                        }} className="asterisk">*</span>
                        </div>
                        <div className="col-sm-6 pl-4">
                          <div className="form-group">
                            <div className="form-check pl-0">
                            <label style={{  display: "inline-block", marginTop: '10%' }}>Auto-Renew</label>
                              <input className="form-check-input" type="radio" name='auto-renew' value="auto-renew" id="auto-renew-check"
                                style={{ paddingLeft: 0, width: '70%' }} checked={autoRenewValue === 'auto-renew'}
                                onChange={e => handleAutoRenew(e)} />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <div className="form-check pl-0">
                            <label style={{  display: "inline-block", marginTop: '10%' }}>One-off</label>
                              <input className="form-check-input" type="radio" name='auto-renew' value="one-off" id="one-off-check"
                                style={{ paddingLeft: 0, width: '70%' }} checked={autoRenewValue === 'one-off'}
                                onChange={e => handleAutoRenew(e)} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CustomInput
                      parentClass="col-6 mt-3 pb-3"
                      label="Description"
                      value={formData.description}
                      handleInput={handleInput.bind(this, "description")}
                    />

                    <ToastAlert
                      showToast={!!contractValidationError}
                      parentClass={"col-12 mt-4 mb-2 pb-3"}
                      childClass={toast.class}
                      toastText={toast.text}
                      onCloseHandler={() => {
                        setContractValidationError(false);
                      }}
                    />
                  </div>
                </div>

                <div className="buttons mt-3 text-right">
                  <div
                    className="mr-4"
                    style={{
                      backgroundColor: "#5F738C",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={cancelForm}
                  >
                    Cancel
                  </div>
                  <div
                    style={{
                      backgroundColor: "#00425F",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                    }}
                    onClick={!isCreatingContract ? createContract : undefined}
                  >
                    {isCreatingContract ? (
                      <img
                        alt="activity-indicator"
                        src="/images/ActivityIndicator.gif"
                        style={{ height: 20, width: 20 }}
                      />
                    ) : (
                      "Create Contract"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
