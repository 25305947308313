import React, {useEffect} from "react";
import "../../../assets/Styles/Dashboard.css";
import {TransactionSVG} from "../../../assets/Svgs/Svgs";
import {shortMonthNames} from "../../../lib/Functions/DateNames";
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchAllBlankcards} from "../redux/action/OwnerCardActions";
import {getUserProperties} from "../../../lib/Constants/UserTypes";

const StatusBar = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllBlankcards());
  }, []);
  const myCards = [];

  let activeCards = 0,
    inactiveCards = 0,
    customerNumber = getUserProperties().customerNumber;

  const {customerUser, oTrailReducer, customerVendor, oCardReducer} = props;
  const cards = oCardReducer.allBlankCards || [];
  cards.content &&
    cards.content.forEach((val) => {
      if (val.userId && val.customerId === customerNumber) {
        myCards.push(val);
        activeCards++;
      } else if (!val.userId && val.customerId === customerNumber)
        inactiveCards++;
    });
  const d = new Date();
  const vendorCount = customerVendor.allCustomerVendors.data
    ? customerVendor.allCustomerVendors.data.length
    : "0";
  return (
    <div className="status-container row">
      <div className="compartment compartment-one col col-5">
        <span className="title d-inline mr-3">Transaction Tracking</span>
        <TransactionSVG color={"#06CC3E"} />
        <span className="sub-title">
          Summary of your transactions updated daily.
        </span>
        <span className="count">{0}</span>
        <span className="count-description">{`Transactions from ${vendorCount}  Vendor(s)`}</span>
        <span className="count-time">{`Last updated ${d.getDate()}, ${
          shortMonthNames[d.getMonth()]
        } ${d.getFullYear()} `}</span>
        <NavLink to="/customer-admin/reports">
          <div className="blue-bg-btn">View Report</div>
        </NavLink>
        <div className="dashboard-short-border-line"></div>
      </div>
      <div className="compartment compartment-two col col-4">
        <div className="row">
          <div className="text-center col-6">
            <p className="title">All Users</p>
            <span className="sub-title"></span>
            <span className="count">{customerUser.users.length}</span>
          </div>

          <div className="text-center col-6">
            <p className="title">User Actions</p>
            <span className="sub-title"></span>
            <span className="count">{oTrailReducer.ctrails.count || "0"}</span>
            <br />
            <NavLink to="/customer-admin/trail">
              <div className="white-bg-btn">View Trail</div>
            </NavLink>
          </div>
        </div>

        <div className="dashboard-short-border-line"></div>
      </div>

      <div className="compartment text-center col-3">
        <div className="row">
          <div className="text-center col-6">
            <p className="title">Cards</p>
            <span className="sub-title"></span>
            <span className="count">{activeCards + inactiveCards}</span>
          </div>

          <div className="text-center col-6">
            <p className="title">Vendors</p>
            <span className="sub-title"></span>
            <span className="count">{vendorCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusBar;
