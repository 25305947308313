import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import ReportStatusBar from '../StatusBar';
import SettlementList from "./SettlementList";
import moment from "moment";
import { downloadSettlementReport, fetchAllTransactions, getAllSettlements } from "../../../ownerModule/redux/action/OwnerTrailActions";
import { getUserProperties } from "../../../../lib/Constants/UserTypes";
import fileDownload from "js-file-download";
import { DatePicker, Select } from "antd";
import { loadAllVendorCustomer } from "../../redux/actions/CustomerActions";

function Settlements () {
  const dispatch = useDispatch();
  const startDate = moment(new Date(moment().subtract(365, 'days').format('LLLL')));
  const endDate = moment(new Date(moment().subtract(0, 'days').format('LLLL')));
  const [customerNum , setCustomerNum] = useState("");
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const { vendorNumber = '', vendor = 0 } = getUserProperties() || {};
  const props = useSelector(state => state);
  const { activeCustomers } = props.vendorCustomer;

  useEffect(()=> {
    batch(()=>{
      dispatch(loadAllVendorCustomer(vendor, 1, 10000000));
      dispatch(fetchAllTransactions(startDate, endDate, 1, 20, 2, {vendorNum: null, customerNum: null, location: null}));
      dispatch(getAllSettlements(1, 10, dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'), vendorNumber, customerNum ));
    })
  }, [])

  useEffect(() => {
    if (props.oTrailReducer.oTransactionsBlob) {
      fileDownload(props.oTrailReducer.oTransactionsBlob, 'Settlement-Report.xlsx');
      dispatch({ type: 'DOWNLOAD_ALL_TRANSACTION_RESET' });
    }
  }, [props.oTrailReducer.oTransactionsBlob]);

  const handleDownload =() =>{
    dispatch(downloadSettlementReport(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'), vendorNumber, customerNum))
  }

  const handleCalendarChange = ([startDate0 = startDate, endDate0 = endDate]) => {
    setDateRange([moment(startDate0), moment(endDate0)]);
  };

  const handleSearch =()=>{
    dispatch(getAllSettlements(1, 10, dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'), vendorNumber, customerNum ));
  }

  return (
    <div className="dashboard-container">
      <ReportStatusBar {...props} isVisible={true} type='settlement'/>
      <div className="row">
        <div className="col-md-5 text-right pr-0 mt-md-1 mt-2">
        <Select 
            defaultValue={customerNum} 
            className="mr-3"
            style={{ minWidth : '130px'}}
            placeholder="Choose Customer"
            // allowClear 
            // showSearch
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={value => setCustomerNum(value)}>
            <Select.Option value="">All Customers</Select.Option>
            {(activeCustomers || []).filter(c => c.status === "ACTIVE").map((c, i) => (
              <Select.Option value={c.number} key={i}>{c.name}</Select.Option>
            ))}
          </Select>
        </div>
       
        <div className="col-md-4 text-right pr-0 mt-md-1 mt-2">
          <DatePicker.RangePicker
            defaultValue={dateRange}
            onCalendarChange={handleCalendarChange}
            format={"DD-MMMM-YYYY"}
            allowClear={false}
          />
        </div>
        <div className="col-md text-right pr-0 mt-md-1 mt-2">
          <button className="btn btn-primary" onClick={handleSearch}>Search</button>
        </div>
      </div>
      <SettlementList {...props} onDownloadTransactions={handleDownload} 
        onPageChange={(pageNo, pageSize) => {
          dispatch(getAllSettlements(
            pageNo,
            pageSize,
            dateRange[0].format('YYYY-MM-DD'), 
            dateRange[1].format('YYYY-MM-DD'), 
            vendorNumber, customerNum,
          ))
        }} />
    </div>
  )
}

export default Settlements;