import * as pa from './../actions/paginationAction';
const initialState = {
    pageNumber: 1,
    pageSize: 10,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case pa.PAGINATE_INIT:
            return initialState;

        case pa.PAGINATE_NEXT:
            return {
                ...state,
                pageNumber: action.payload,
            }

        case pa.PAGINATION_SIZE_CHANGE:
            return {
                ...state,
                pageSize: action.payload,
            }
        
        default:
            return state;
    }
}
