import React, {useState} from 'react';
import { Modal, Form, Dropdown } from 'react-bootstrap';
import { Skeleton, Alert, Input } from "antd";
import BulkUserFile from '../../../../assets/upload_files/bulk-customer-user.xlsx';
import { ReactComponent as GreenCheckIcon } from '../../../../assets/Svgs/GreenCheck.svg'
import { useCreateMultipleUser } from '../../redux/action/VendorAction';
import { getUserProperties } from '../../../../lib/Constants/UserTypes';

const BulkUserUpload = ({ showBulkModal , closeBulkUploadModal, uploadComplete}) => {
    const user = getUserProperties();

    const { postData, isLoading, error, data } = useCreateMultipleUser(user?.customer);
    const [bulkFile, setBulkFile ] = useState();
    const [fileError, setFileError] = useState();
    console.log(user);
    const handleUploadFile = (e) => {
        e.preventDefault();
        if (bulkFile) {
            if (bulkFile.size > 4 * 1024 * 1024) {
                setFileError('File size exceeds 4MB.');
                return;
            }
            postData(bulkFile)
            // dispatch(uploadBulkStatePrice(bulkFile));
        } else {
            setFileError('Please select a file.');
            return;
        }
    };
  
   const handleFileChange = (e) =>{
    setFileError('');
     if(e.target.files[0]){
        setBulkFile(e.target.files[0])
     }
   }

    return (<Modal style={{
    }} scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal" show={showBulkModal}>
        <Modal.Header closeButton style={{
        }} onHide={closeBulkUploadModal}>
            <Modal.Title>
                Add multiple users
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
        }}>
            <div className='invoice-modal-detail'>
                {data && <div className='d-flex flex-column justify-content-center align-items-center p-3'>
                    <GreenCheckIcon />
                    <span className='payment-modal-h mb-3'>Users Uploaded Successfuly</span>
                    <span className='center-text mb-3'>A report of the upload will be made availabile to you shortly</span>
                    <button onClick={closeBulkUploadModal} className='payment-button invoice-detail-button mb-3'>
                        Done
                    </button>
                </div>}
                {!data && <form onSubmit={handleUploadFile}>
                    {/* <Form.Label className='invoice-label mb-1'>Select State</Form.Label> */}
                    <a style={{ display: 'inline-block' }} className='mb-3' href={BulkUserFile} download="User-bulk-upload-file" target='_blank' rel="noopener noreferrer">Click here to download sample upload file</a>

                    <Form.Label className='invoice-label mb-1'>Upload file</Form.Label>
                    <input required type="file" className='mb-3' style={{ width: '100%', height: 40, padding: 10 }} onChange={handleFileChange} />

                    {(!!error || !!fileError) && <Alert className='mb-1' closable={true} message={error || fileError} onClose={() => setFileError('')} type='error' />}

                    <div className='button-border'><button type='button' disabled={isLoading} onClick={closeBulkUploadModal} className='invoice-cancel-button'>
                        Cancel
                    </button>
                        <button disabled={isLoading} type='submit' className='invoice-form-button'>{isLoading ? 'uploading...' : 'Upload'}</button>
                    </div>
                </form>}

            </div>
        </Modal.Body>
    </Modal>)
}
export default BulkUserUpload