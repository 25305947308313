import axios from 'axios';
import { customConfig } from '../../../../actions/action_constants';
import { authHeader } from '../../../../helpers/authHeader';
const API_URL = customConfig.API_URL;

export function fetchUser() {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/users/all?page=1&size=50000', { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_USERS_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_USER_REJECTED', payload: err });
      });
  };
}

export function toggleUserCreateModal(id) {
  return {
    type: 'TOGGLE_MODAL',
    payload: id,
  };
}

export function selectedUserObject(user) {
  return {
    type: 'SELECTED_USER',
    user,
  };
}
export function resetUserRequestParams(fetchingStatus, message) {
  return {
    type: 'RESET_USER_REQ_PARAMS',
    response: { fetchingStatus, message },
  };
}


export function getAllUsers(user_type, q = '') {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/users/category?page=1&size=10000000&type=' + user_type + '&q=' + q, {
        headers: authHeader(),
      })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'FETCH_USERS_FULFILLED', response: { data: res.data.data, message: res.data.status.description, fetchingStatus: 1 } });
        } else if (res.status.code !== 0) {
          dispatch({ type: 'FETCH_USERS_FULFILLED', response: { data: [], message: res.data.status.description, fetchingStatus: 2 } });
        } else {
          dispatch({ type: 'FETCH_USERS_FULFILLED', response: { data: [], message: res.data.status.description, fetchingStatus: 1 } });
        }
      })
      .catch(err => {
        dispatch({ type: 'FETCH_USERS_REJECTED', response: { data: [], message: err.message, fetchingStatus: 2 } });
      });
  };
}
export function getAllSystemUsers() {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/users/all?page=1&size=500000000', {
        headers: authHeader(),
      })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'FETCH_SYS_USERS_FULFILLED', response: { data: res.data, message: res.status.description, fetchingStatus: 1 } });
        } else if (res.status.code !== 0) {
          dispatch({ type: 'FETCH_SYS_USERS_FULFILLED', response: { data: [], message: res.data.status.description, fetchingStatus: 2 } });
        } else {
          dispatch({ type: 'FETCH_SYS_USERS_FULFILLED', response: { data: [], message: res.data.status.description, fetchingStatus: 1 } });
        }
      })
      .catch(err => {
        dispatch({ type: 'FETCH_SYS_USERS_REJECTED', response: { data: [], message: err.message, fetchingStatus: 2 } });
      });
  };
}

export function toggleUserStatus(userId, mode) {
  return function(dispatch) {
    dispatch({type:'START_USERS_STATUS'})
    axios
      .put(
        API_URL + 'api/users/toggle?id=' + userId,
        {},
        {
          headers: authHeader(),
        },
      )
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'UPDATE_USERS_STATUS_FULFILLED', response: { data: res.data.data, message: res.data.status.description, fetchingStatus: 1 } });
        } else if (res.status.code !== 0) {
          dispatch({ type: 'UPDATE_USERS_STATUS_FULFILLED', response: { message: res.data.status.description, fetchingStatus: 2 } });
        }
        // if (res.data.status.httpcode === 400) {
        //   dispatch({ type: 'UPDATE_USERS_STATUS_FULFILLED', response: { message: res.data.status.description, fetchingStatus: 2 } });
        // }
      })
      .catch(err => {
        dispatch({ type: 'UPDATE_USERS_STATUS_FAILED', response: { message: err.response.data.status.description, fetchingStatus: 2 } });
      });
  };
}
export function getAllPermissions(userType) {
  return function(dispatch) {
    axios
      .get(API_URL + `api/users/permissions/all?type=${userType}`, { headers: authHeader() })
      .then(res => {
        if (res.data) {
          dispatch({ type: 'FETCH_PERMISSIONS_FULFILLED', payload: res.data.data });
        }
      })
      .catch(err => {
        dispatch({ type: 'FETCH_PERMISSIONS_FAILED', payload: err });
      });
  };
}

export function submitUserForm(data) {
  return function(dispatch) {
    dispatch({ type: 'ADD_USER_START' });
    axios
      .post(API_URL + 'api/users', data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'ADD_USER', response: { data: res.data.data, message: res.data.status.description, fetchingStatus: 1 } });
        } else {
          dispatch({ type: 'ADD_REJECTED', response: { message: res.data.status.description, fetchingStatus: 2 } });
        }
      })
      .catch(err => {
        const msg = 
          err.response && 
          err.response.data && 
          err.response.data.status &&
          err.response.data.status.description;
        
        dispatch({
          type: 'ADD_REJECTED',
          response: {
            message: msg || 'User not added successfully',
            fetchingStatus: 2,
          },
        });
      });
  };
}


export function updateUserForm(data, id) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_USER_START' });
    axios
      .put(API_URL + 'api/users?id=' + id, data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'UPDATE_USER', response: { data: res.data.data, message: res.data.status.description, fetchingStatus: 1 } });
        } else {
          dispatch({ type: 'UPDATE_REJECTED', response: { message: res.data.status.description, fetchingStatus: 2 } });
        }
      })
      .catch(err => {
        const msg = 
          err.response && 
          err.response.data && 
          err.response.data.status &&
          err.response.data.status.description;
        dispatch({ 
          type: 'UPDATE_REJECTED',
          response: { message: msg || 'User not Updated', fetchingStatus: 2 },
        });
      });
  };
}
