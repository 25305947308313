import React, { useState,useEffect } from 'react';
import { Modal, Form, Dropdown } from 'react-bootstrap';
import { Alert, Input } from "antd";
import BulkVoucherFile from '../../assets/upload_files/bulk-voucher.xlsx';
import { ReactComponent as GreenCheckIcon } from '../../assets/Svgs/GreenCheck.svg';
import { getUserProperties } from '../../lib/Constants/UserTypes';
import { useCreateMultipleVoucher } from '../customerModule/redux/action/VendorAction';
import {useDispatch, useSelector} from "react-redux";

import fileDownload from "js-file-download";
import { downloadSampleByCustomer } from '../customerModule/redux/action/CustomerActions';

const BulkVoucherUpload = ({ showBulkModal, closeBulkUploadModal, uploadComplete }) => {
    const dispatch = useDispatch();
    const props = useSelector((state) => state);
    const { customerNumber, number } = getUserProperties();
    const { postData, isLoading, error, data } = useCreateMultipleVoucher();
    const [bulkFile, setBulkFile] = useState();
    const [fileError, setFileError] = useState();
    const handleUploadFile = (e) => {
        e.preventDefault();
   
        if (bulkFile) {
            if (bulkFile.size > 4 * 1024 * 1024) {
                setFileError('File size exceeds 4MB.');
                return;
            }
            postData(bulkFile, customerNumber, number)
            // dispatch(uploadBulkStatePrice(bulkFile));
        } else {
            setFileError('Please select a file.');
            return;
        }
    };

    useEffect(() => {
        if (props.oTrailReducer.cDashoardTransactionsBlob) {
          fileDownload(props.oTrailReducer.cDashoardTransactionsBlob, 'Driver-Report.xlsx');
          dispatch({ type: 'DOWNLOAD_ALL_CUSTOMERS_TRANSACTIONS_RESET' });
        }
      },[props.oTrailReducer.cDashoardTransactionsBlob]);

    const downloadSample = () => {
        dispatch(downloadSampleByCustomer(JSON.parse(localStorage.getItem('user')).customer))
    }

    console.log("props", JSON.parse(localStorage.getItem('user')))

    const handleFileChange = (e) => {
        setFileError('');
        if (e.target.files[0]) {
            setBulkFile(e.target.files[0])
        }
    }

    return (<Modal style={{
    }} scrollable={true} backdropClassName="payment-modal-backdrop" className="payment-modal" show={showBulkModal}>
        <Modal.Header closeButton style={{
        }} onHide={closeBulkUploadModal}>
            <Modal.Title>
                Create multiple vouchers
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
        }}>
            <div className='invoice-modal-detail'>
                {data && <div className='d-flex flex-column justify-content-center align-items-center p-3'>
                    <GreenCheckIcon />
                    <span className='payment-modal-h mb-3'>Bulk voucher uploaded Successfuly</span>
                    <span className='center-text mb-3'>A report of the upload will be made availabile to you shortly</span>
                    <button onClick={uploadComplete} className='payment-button invoice-detail-button mb-3'>
                        Done
                    </button>
                </div>}
                {!data && <form onSubmit={handleUploadFile}>
                    {/* <Form.Label className='invoice-label mb-1'>Select State</Form.Label> */}
                    {/* <a style={{ display: 'inline-block' }} className='mb-3' href={BulkVoucherFile} download="bulk-voucher-upload-file" target='_blank' rel="noopener noreferrer">Click here to download sample upload file</a> */}
                    <div style={{ display: 'inline-block', color:"blue", cursor:"pointer" }} className='mb-3' onClick={downloadSample}  target='_blank' rel="noopener noreferrer">Click here to download sample upload file</div>
                    <Form.Label className='invoice-label mb-1'>Upload file</Form.Label>
                    <input required type="file" className='mb-3' style={{ width: '100%', height: 40, padding: 10 }} onChange={handleFileChange} />

                    {(!!error || !!fileError) && <Alert className='mb-1' closable={true} message={error || fileError} onClose={() => setFileError('')} type='error' />}

                    <div className='button-border'><button type='button' disabled={isLoading} onClick={closeBulkUploadModal} className='invoice-cancel-button'>
                        Cancel
                    </button>
                        <button disabled={isLoading} type='submit' className='invoice-form-button'>{isLoading ? 'uploading...' : 'Upload'}</button>
                    </div>
                </form>}

            </div>
        </Modal.Body>
    </Modal>)
}
export default BulkVoucherUpload;