import React, {useEffect} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../assets/Styles/Dashboard.css";
import StatusBar from "./StatusBar";
import Analytics from "./Analytics";
// import {getAllUsers} from "../redux/action/customerUserActions";
import {
  getUserProperties, VENDOR_USER,
} from "../../../lib/Constants/UserTypes";

// import TransactionReport from "../Reports/Transactions/TransactionList";
import moment from "moment";
import {
  fetchAllTrailByVendor, 
  fetchHighestTransactingUser, 
  fetchThreeMonthsTransactionStats, 
  fetchTransactionAmountByFrequency, 
  getTransactionsByvendor
} from "../../ownerModule/redux/action/OwnerTrailActions";
import { getAllUsers } from "../../customerModule/redux/action/customerUserActions";
import TransactionList from "../Reports/Transactions/TransactionList";
import { Redirect } from "react-router";
// import {loadAllCustomerVendors} from "../redux/action/VendorAction";

function Dashboard() {
  const dispatch = useDispatch();
  const props = useSelector((state) => state);
  const startDate = moment(
    new Date(moment().subtract(365, "days").format("LLLL"))
  ).format("YYYY-MM-DD");
  const endDate = moment(
    new Date(moment().subtract(0, "days").format("LLLL"))
  ).format("YYYY-MM-DD");
  const { vendor = 0, vendorNumber = '' } = getUserProperties() || {};

  useEffect(() => {
    batch(() => {
      dispatch(getAllUsers(VENDOR_USER));
      dispatch(fetchAllTrailByVendor(vendor, 1, 10000000));
      dispatch(
        getTransactionsByvendor(vendorNumber, startDate, endDate, 1, 10, 2, {
          customerNum: null,
          location: null,
        }, () => {})
      );
      dispatch(fetchHighestTransactingUser('CUSTOMER', 'AMOUNT'));
      dispatch(fetchThreeMonthsTransactionStats('CUSTOMER', 'AMOUNT'));
      dispatch(fetchTransactionAmountByFrequency('CUSTOMER', vendorNumber));
    });
  }, []);

  return (
    <div className="dashboard-container">
      {(getUserProperties().userLevel === "STATION_MANAGER")&&
        <Redirect to='/vendor-admin/station/dashboard' />
      }
      <StatusBar {...props} />
      <Analytics {...props} currentDate={endDate} />
      <TransactionList {...props} title="Last 10 Transactions" />
    </div>
  );
}

export default Dashboard;
