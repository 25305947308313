import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import "../../../../assets/Styles/Dashboard.css";
import {
  FilterSVG,
  ExportSVG,
  // CompletedMarkSVG,
  // LoadingSVG,
  // DownloadSVG,
  MarkSVG,
  MissSVG,
  CloseSVG,
} from "../../../../assets/Svgs/Svgs";
import Modal from "react-bootstrap/Modal";
import {
  longMonthNames,
  shortMonthNames,
  shortDayNames,
} from "../../../../lib/Functions/DateNames";
// import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import ReactToPrint from "react-to-print";
import Pagination from "../../../../lib/Components/Pagination";
import formatAmount from "../../../../lib/Functions/formatAmount";

const TransactionList = (props) => {
  const {oTrailReducer, title, downloading, onDownloadTransactions, onFetchTransactions} = props;
  const {content, count} = oTrailReducer.oTransactions;

  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [showTransactionModal, setShowTransactionModal] = useState(false);

  const componentRef = useRef();
  const pageSize = 10;
  const {
    cardName,
    cardNumber,
    location,
    time,
    amount,
    vendor,
    reference,
    status,
    description,
    volume,
    odometer,
    truckNumber,
    tripNumber,
    driverName,
    dealerCode,
    dealerRep,
    otherInformation,
  } = selectedTransaction;

  const d = new Date(time);
  const dayIndex = ((d.getDay() - 1) < 0 ? (7 - Math.abs(d.getDay() - 1)) : (d.getDay() - 1));
  const transactionDate = `${shortDayNames[dayIndex]}, ${
    longMonthNames[d.getMonth()]
  } ${d.getDate()}, ${d.getFullYear()}`;

  // const [pageNo, setPageNo] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);
  // const csvData = (content || []).map((item) => ({...item, amount: item.amount / 100}));

  const TransactionList = content && content.map((value) => {
      const {
        id,
        location,
        cardNumber,
        customer,
        product,
        amount: transactionAmount,
        time,
        volume,
        // odometer,
        // truckNumber,
        // tripNumber,
        // driverName,
        // dealerCode,
        // dealerRep,
      } = value;
      const d = new Date(time);
      const date = d.getDate();
      let suffix = (date >= 4 && date <= 20) || (date >= 24 && date <= 30) ? "th" : ["st", "nd", "rd"][date % 10 - 1];
      const dayIndex = ((d.getDay() - 1) < 0 ? (7 - Math.abs(d.getDay() - 1)) : (d.getDay() - 1));

      let classIdentifier = "", condition = "";
      if (value.status === "Successful" || value.status === "SUCCESS") {
        classIdentifier = "success";
        condition = "Successful";
      } else {
        classIdentifier = "error";
        condition = "Failed";
      }

      return (
        <tr key={id}>
          <td className="table-description">
            Purchase of {product} from {location}
            <small className="d-block text-primary font-weight-bold">
              {shortDayNames[dayIndex]}, {d.getDate()}{suffix}{" "}
              {shortMonthNames[d.getMonth()]}, {d.getFullYear()}
            </small>
          </td>
          <td className="table-pin">{customer}</td>
          <td className="table-description font-weight-bold">{cardNumber}</td>
          <td className="table-description">
            ₦ {formatAmount(transactionAmount)}
          </td>
          <td className="table-description">{product}</td>
          <td className="table-description">{volume || "0.00"}</td>
          <td className="table-status">
            <div className={`status-btn ${classIdentifier}`}>{condition}</div>
          </td>
          {/* <td className="table-description"> */}
          {/* </td> */}
          <td
            className="table-action"
            onClick={() => {
              setSelectedTransaction(value);
              setShowTransactionModal(true);
            }}
          >
            View
          </td>
        </tr>
      );
    });

  return (
    <>
      <div className="disbursed-cards-container row">
        <div className="menu-link" style={{marginBottom: 0, paddingBottom: 4}}>
          <span
            style={{
              color: "#000000",
              borderBottomColor: "#00425F",
              borderBottomWidth: 3,
              borderBottomStyle: "solid",
            }}
          >
            {!!title ? title : "Transaction Report"}
          </span>
        </div>

        {!!content && typeof onDownloadTransactions === 'function' && (<>
          {/* <CSVLink
            data={csvData}
            filename={"Transaction Report.csv"}
            className=""
            target="_blank"
          > */}
            <div
              className="disbursed-cards-export-btn text-white"
              style={{float: "right"}}
            >
              {downloading ? 'Loading...': <Link
                to="#"
                onClick={() => {
                  onDownloadTransactions()
                }}
                className="text-white"
              >Export XLS <ExportSVG /></Link>}
            </div>
          {/* </CSVLink> */}
        </>)}

        <table className="table table-hover">
          <thead>
            <tr>
              <td>Description / Date</td>
              <td>
                Customer <FilterSVG />
              </td>
              <td>Card Pan</td>
              <td>Amount</td>
              <td>Product</td>
              <td>Volume / Qty</td>
              <td>
                Status <FilterSVG />
              </td>
              {/* <td> */}
                {/* Date <FilterSVG /> */}
              {/* </td> */}
              <td></td>
            </tr>
          </thead>
          <tbody>
            {/* {!!TransactionList && TransactionList} */}
            {TransactionList || (
              <tr>
                <td colSpan={8} className="text-center">
                  No Transaction to show
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {!title && (
          <Pagination
            count={count}
            pageSize={pageSize}
            fetchMethod={(pageNo, pageSize) => {
              onFetchTransactions(pageNo, pageSize);
            }}
          />
        )}
      </div>

      <Modal
        show={showTransactionModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="transaction-modal"
      >
        <Modal.Body ref={componentRef}>
          <div className="pl-5 pr-5 pt-5 pb-5 text-center">
            <div
              style={{
                borderBottomColor: "#5F738C",
                borderBottomWidth: 0.5,
                borderBottomStyle: "solid",
                textAlign: "left",
              }}
            >
              <h6 className="mt-5 text-left d-inline">Transaction Details</h6>
              <span
                style={{
                  float: "right",
                  cursor: "pointer",
                  position: "relative",
                  bottom: 5,
                }}
                onClick={() => {
                  setShowTransactionModal(false);
                }}
              >
                <CloseSVG />
              </span>
            </div>
            <div className="text-center mt-4">
              {status === "Successful" || status === "SUCCESS" ? <MarkSVG /> : <MissSVG />}
            </div>
            <div className="text-center mt-3 mb-1 font-weight-bold quickteller-black">
              Your transaction was{" "}
              {status === "Successful" || status === "SUCCESS" ? "successful" : "unsuccessful"}
            </div>
            <span className="quickteller-black f-14">
              {location} Purchase for {cardName} {cardNumber}{" "}
            </span>
            {
              <>
                <div className="col-12 text-left bg-white mb-3 mt-4">
                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Description
                      </span>
                      <span className="d-block qt-gray mt-1">
                        SmartFuel Purchase for {cardName}
                      </span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Date
                      </span>
                      <span className="d-block qt-gray mt-1">
                        {transactionDate}
                      </span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Amount
                      </span>
                      <span className="d-block qt-gray mt-1">
                        ₦ {formatAmount(amount)}
                      </span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Card Pan
                      </span>
                      <span className="d-block qt-gray mt-1">{cardNumber}</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Volume / Quantity Dispensed
                      </span>
                      <span className="d-block qt-gray mt-1">{volume || "0.00"} Litres</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Truck Number
                      </span>
                      <span className="d-block qt-gray mt-1">{truckNumber}&nbsp;</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Driver Name
                      </span>
                      <span className="d-block qt-gray mt-1">{driverName}&nbsp;</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Trip Number
                      </span>
                      <span className="d-block qt-gray mt-1">{tripNumber}&nbsp;</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Dealer Rep
                      </span>
                      <span className="d-block qt-gray mt-1">{dealerRep}&nbsp;</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Dealer Code
                      </span>
                      <span className="d-block qt-gray mt-1">{dealerCode}&nbsp;</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Customer Name
                      </span>
                      <span className="d-block qt-gray mt-1">{cardName}</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Location
                      </span>
                      <span className="d-block qt-gray mt-1">{location}</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Vendor
                      </span>
                      <span className="d-block qt-gray mt-1">{vendor}</span>
                    </div>
                    <div className="col-2 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Other Info.
                      </span>
                      <span className="d-block qt-gray mt-1">{otherInformation || "---"}&nbsp;</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Odometer No.
                      </span>
                      <span className="d-block qt-gray mt-1">{odometer}&nbsp;</span>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Response
                      </span>
                      <span className="d-block qt-gray mt-1">{description}</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Request Reference
                      </span>
                      <span className="d-block qt-gray mt-1">{reference}</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col d-print-none pl-0 pr-0 pt-3 pb-3 text-center">
                      <span className="d-block font-weight-bold qt-dark-blue text-white print-btn">
                        <ReactToPrint
                          trigger={() => (
                            <a href="#print" className="text-white">
                              Print receipt
                            </a>
                          )}
                          content={() => componentRef.current}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionList;
