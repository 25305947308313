import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import { logoutUser } from "../modules/Auth/Actions/auth_actions";
// import { loggedInUser } from "./permissionHelpers";

class Timeout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warning: false,
      timeoutTime: 10,
      idleMinsTime: 2,
    };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];
  }

  UNSAFE_componentWillMount() {
    localStorage.removeItem('sf_exp');
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/login') {
      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }
    }
  }

  doClearTimeOut = () => {
    this.setState({ warning: false, timeoutTime: 10 });

    if (this.warnTimeout) {
      clearTimeout(this.warnTimeout);
    }

    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }

    if (this.countdownInterval) {
      clearTimeout(this.countdownInterval);
    }

  };

  setWarningTimeOut = () => {
    const { idleMinsTime } = this.state;
    this.warnTimeout = setTimeout(this.warn, (idleMinsTime * 60 * 1000));
  };

  resetTimeout = () => {
    this.doClearTimeOut();
    this.setWarningTimeOut();
  };

  warn = () => {
    const { timeoutTime } = this.state;
    this.countdownInterval = setInterval(() => {
      this.setState({ timeoutTime: (timeoutTime - 1) });
    }, 1000);

    this.setState({ warning: true }, () => {
      this.logoutTimeout = setTimeout(() => {
        this.logout();
      }, (timeoutTime - 0) * 1000);
    })
  };

  logout = () => {
    // localStorage.removeItem("user");
    // localStorage.removeItem("token");
    localStorage.setItem('sf_exp', 1);
    // this.props.history.push('/login');
    this.props.dispatch(logoutUser())
  };

  destroy = () => {
    this.doClearTimeOut();
    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  };

  componentWillUnmount() {
    this.destroy();
  }

  render() {
    const style = {
      background: "#e23535d9",
      display: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      color: "white",
      zIndex: 1000,
      padding: "5px",
      textAlign: "center"
    }
    const { warning, timeoutTime } = this.state;
    const isLoggedIn = localStorage.getItem('token');
    const sessionExpired = localStorage.getItem('sf_exp');
    
    if (warning && isLoggedIn) {
      return (
        <div style={style}>
          You will be timed out in {timeoutTime} seconds
        </div>
      )
    }

    if (sessionExpired) {
      return (
        <div style={style}>
          You were timed out! Please log in again
        </div>
      )
    }

    return null;
  }
}
export default withRouter(connect()(Timeout));
