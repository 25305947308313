export let ownerPermissions = [
  "VIEW_AUDIT",
  // "ADD_PERMISSSION",
  "VIEW_CARDS",
  // "CREATE_SCHEME",
  "VIEW_PRODUCTS",
  "VIEW_SCHEME",
  "VIEW_SCHEMES",
  // "VIEW_TRANSACTION",
  "VIEW_VENDOR",
  // "CREATE_USER",
  "VIEW_PRODUCT",
  "VIEW_CONTRACTS",
  // "VIEW_USER",
  // "VIEW_SETTLEMENT",
  "VIEW_CONTRACT",
  "VIEW_TERMINALS"
  // "EDIT_USER"
];

export let customerPermissions = [
  "VIEW_AUDIT",
  // 'ADD_PERMISSSION',
  "VIEW_CARDS",
  // 'CREATE_SCHEME',
  "VIEW_PRODUCT",
  "VIEW_SCHEME",
  // 'VIEW_SCHEMES',
  'VIEW_TRANSACTION',
  "VIEW_VENDOR",
  'CREATE_USER',
  // 'VIEW_PRODUCTS',
  // 'VIEW_CONTRACTS',
  "VIEW_CONTRACT",
  "VIEW_USER"
  // 'VIEW_SETTLEMENT',
  // 'VIEW_CONTRACT',
  // 'EDIT_USER',
];

export let vendorPermissions = [
  "VIEW_AUDIT",
  "VIEW_CARDS",
  "VIEW_PRODUCTS",
  "VIEW_SCHEME",
  // "VIEW_SCHEMES",
  "VIEW_TRANSACTION",
  "VIEW_VENDOR",
  "VIEW_PRODUCT",
  "VIEW_CONTRACTS",
  "VIEW_USER",
  // "VIEW_SETTLEMENT",
  "VIEW_CONTRACT",
  "VIEW_TERMINAL",
  // "CREATE_SCHEME",
  "CREATE_CUSTOMER",
  "CREATE_USER",
  "CREATE_TERMINAL",
  "CREATE_AGENT",
  "CREATE_LOCATION",
  "EDIT_USER",
  "EDIT_TERMINAL",
  "EDIT_LOCATION",
];

export let stationManagerPermission =  [
  "VIEW_POS_USER",
  "CREATE_POS_USER",
  "EDIT_POS_USER",
  "ADD_STATION_PRICE",
  "VIEW_TRANSACTION" ]