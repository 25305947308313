const initialState = {
  contractRequestMessage: '',
  contractRequestStatus: 0,
  allContracts: [],
  customerContracts: [],
  selectedContract: {},
  allVendorContracts: [],
  loadingContractStatus: 0,
  loadingContractMessage: '',
  //updateProductPriceMessage: {isError: false, message: ''},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'START_CONTRACT_REQUEST':{
      return {...state,creatingContract:true}
    }
    case 'CREATE_CONTRACT_REQUEST':
      return Object.assign({}, state, {
        creatingContract:false,
        contractRequestMessage: action.response.message,
        contractRequestStatus: action.response.status,
      });
    case 'FETCH_All_CONTRACTS_FULFILLED':
      return Object.assign({}, state, {
        creatingContract:false,
        allContracts: action.payload,
      });
    case 'FETCH_VENDOR_CONTRACTS_STARTED': 
    return Object.assign({}, state, {
      allVendorContracts: {...action.payload, isLoading : true }
    });
    case 'FETCH_VENDOR_CONTRACTS_FULFILLED':
      return Object.assign({}, state, {
        allVendorContracts: {...action.payload, isLoading : false }
      });
    case 'FETCH_VENDOR_CONTRACTS_REJECTED' : 
    return Object.assign({}, state, {
      allVendorContracts: { isLoading : false }
    });
    case 'FETCH_CONTRACT_FULFILLED':
      return Object.assign({}, state, {
        selectedContract: action.response.data,
        loadingContractStatus: action.response.status,
        loadingContractMessage: action.response.message,
      });
    case 'UPDATE_CONTRACT_REQUEST_PARAMS':
      return Object.assign({}, state, {
        contractRequestStatus: action.response.status,
        contractRequestMessage: action.response.message,
      });
    case 'FETCH_All_CUSTOMER_CONTRACTS_FULFILLED':
      return Object.assign({}, state, {
        customerContracts: action.response.data,
        loadingContractStatus: action.response.loadingStatus,
        loadingContractMessage: action.response.message,
      });

    case 'TOGGLE_CONTRACT_START':
      return {...state, isTogglingContract: true};

    case 'TOGGLE_CONTRACT_FULFILLED':
      return {...state, isTogglingContract: false, 
        selectedContract: action.payload.data,
      };

    case 'TOGGLE_CONTRACT_REJECTED':
      return {...state, isTogglingContract: false };

    case "CHANGE_CONTRACT_PRODUCT_PRICE_REQUEST_START": {
      return {...state, isRequestingCPPriceChange: true};
    }

    case "CHANGE_CONTRACT_PRODUCT_PRICE_REQUEST_FULLFILLED": {
      return {
        ...state,
        isRequestingCPPriceChange: false,
        changeProductPriceResponse: {isError: false, message: action.payload.message},
      };
    }

    case "CHANGE_CONTRACT_PRODUCT_PRICE_REQUEST_REJECTED": {
      return {
        ...state, 
        isRequestingCPPriceChange: false, 
        changeProductPriceResponse: {isError: true, message: action.payload.message},
      };
    }

    case "CHANGE_CONTRACT_PRODUCT_PRICE_REQUEST_RESET": {
      return { ...state, changeProductPriceResponse: {} }
    }

    case "CHANGE_CONTRACT_LIMIT_REQUEST_START": {
      return {...state, isRequestingCLChange: true};
    }

    case "CHANGE_CONTRACT_LIMIT_REQUEST_FULLFILLED": {
      return {
        ...state,
        isRequestingCLChange: false,
        changeContractLimitResponse: {isError: false, message: action.payload.message},
      };
    }

    case "CHANGE_CONTRACT_LIMIT_REQUEST_REJECTED": {
      return {
        ...state, 
        isRequestingCLChange: false, 
        changeContractLimitResponse: {isError: true, message: action.payload.message},
      };
    }

    case "CHANGE_CONTRACT_LIMIT_REQUEST_RESET": {
      return { ...state, changeContractLimitResponse: {} }
    }

    default:
      return state;
  }
};
