import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import StatusBar from "../StatusBar";
import {
  getUserProperties,
} from "../../../../lib/Constants/UserTypes";
import {fetchAllBlankcards} from "../../redux/action/OwnerCardActions";
import SchemeList from "./SchemeList";
import {
  getAllVouchersByCustomer,
  getSchemesByCus,
} from "../../../../actions/CustomerActions/CardMgtActions/CardAction";

function Cards() {
  const dispatch = useDispatch();

  const [showStatusBar, setshowStatusBar] = useState(true)
  const userProperties = getUserProperties();
  const customerNumber = userProperties.customerNumber; //change to userProperties.customerNumber;
  const pageSize = 10;

  useEffect(() => {
    batch(() => {
      dispatch(getSchemesByCus(customerNumber));
      dispatch(fetchAllBlankcards());
      dispatch(getAllVouchersByCustomer(customerNumber, 1, pageSize));
    });
  }, []);

  const props = useSelector((state) => state);
  const {oCardReducer} = props;
  const cards = oCardReducer.allBlankCards || [];
  const myCards = [];

  let activeCards = 0, inactiveCards = 0;
  
  cards.content && cards.content.forEach((val) => {
    if (val.userId && val.customerId === customerNumber) {
      myCards.push(val);
      activeCards++;
    } else if (!val.userId && val.customerId === customerNumber) inactiveCards++;
  });

  return (
    <div className="dashboard-container">
      <StatusBar
        {...props}
        isVisible={showStatusBar}
        cardQuantity={{activeCards, inactiveCards}}
      />

      <SchemeList
        {...props}
        cardQuantity={{activeCards, inactiveCards}}
        cards={cards.content}
        isStatusBarVisible={showStatusBar}
        myCards={myCards}
        onHideStatusBar={(status) => setshowStatusBar(!status)}
        customerNumber = {customerNumber}
        pageSize={pageSize}
      />
    </div>
  );
}

export default Cards;
