import React/* , { useState } */ from "react";
// import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Ellipse } from "../../../../assets/Svgs/Svgs";

const CustomerSettings = props => {
  return (
    <>
      <div className="card-management-row">
        <div className="title"> Card Management</div>
        <div className="radios">
          <div className="enable-btn first">
            Enable <Ellipse color="#11B01A" />
          </div>
          <div className="inactive-btn second">
            Disable <Ellipse />
          </div>
        </div>
      </div>
      <div className="voucher-management-row">
        <div className="title"> Voucher Management</div>
        <div className="radios">
          <div className="inactive-btn first">
            Enable <Ellipse />
          </div>
          <div className="disable-btn second">
            Disable <Ellipse color="#EE312A" />
          </div>
        </div>
      </div>
      <div className="password">
        <div className="title">Admin Password</div>
        <div className="form-group">
          <input type="password" className="form-control" />
        </div>
      </div>
      <button className="confirm-btn">Confirm changes</button>
    </>
  );
};

export default CustomerSettings;
