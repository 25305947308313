const customerReducer = (
  state = {
    // users: [
    //   { id: '8376321', first_name: 'Akpan', last_name: 'Enoghobong', type: 'Customer', description: 'Head, Acquisition', date_created: '12/11/2019' },
    //   { id: '8392821', first_name: 'Tony', last_name: 'Ogan', type: 'Scheme Mgr', description: 'Head, Control', date_created: '12/11/2019' },
    //   { id: '11176321', first_name: 'Bolaji', last_name: 'Bummi', type: 'Customer', description: 'Head, Acquisition', date_created: '12/11/2019' },
    //   { id: '83763098721', first_name: 'Chisom', last_name: 'Emeagwa', type: 'Scheme Mgr', description: 'Head, Operations', date_created: '12/11/2019' },
    // ],
    users: [],
    selectedUser: {},
    fetching: false,
    fetched: false,
    error: null,
    bulkCreateModal: false,
    userCreateModal: false,
    bulkUploadModal: false,
    bulkUploadResultModal: false,
    userEditModal: false,
    userDeleteModal: false,
    fetchingUsersMessage: "",
    fetchingUserStatus: 0,
    updateUserStatus: 0,
    updateStatusMessage: "",
    userRequestStatus: 0,
    userRequestMessage: "",
    availablePermissions: [],
    isCreatingUser: false,
    fetchingStatus: 0,
    editingStatus: 0,
    isUpdatingUser: false,
    userCreationErrorMessage: "",
    message: "",
    isSetSms: false,
  },
  action
) => {
  switch (action.type) {
    case "FETCH_USERS": {
      return { ...state, fetchingUserStatus: 0 };
    }

    case "TOGGLE_MODAL": {
      return { ...state, userCreateModal: !state.userCreateModal };
    }

    case "FETCH_PERMISSIONS_FULFILLED": {
      return { ...state, availablePermissions: action.payload };
    }

    case "FETCH_USERS_REJECTED": {
      return {
        ...state,
        fetchingUsersMessage: action.response.message,
        users: action.response.data,
        fetchingUserStatus: action.response.fetchingStatus,
      };
    }

    case "FETCH_USERS_FULFILLED": {
      return {
        ...state,
        fetchingUsersMessage: action.response.message,
        users: action.response.data,
        fetchingUserStatus: action.response.fetchingStatus,
      };
    }

    case "START_CUSERS_STATUS": {
      return {
        ...state,
        toggleStatusStart: true,
        editingStatus: 0,
        isUpdatingUser: true,
      };
    }

    case "UPDATE_CUSERS_STATUS_FULFILLED": {
      const { response, id, mode } = action;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex((user) => user.id === id);
      newUsers[userToUpdate].status = mode;
      return {
        ...state,
        editingStatus: response.editingStatus,
        isUpdatingUser: false,
        users: newUsers,
      };
    }

    case "UPDATE_CUSERS_STATUS_FAILED": {
      return {
        ...state,
        isUpdatingUser: false,
        editingStatus: action.response.editingStatus,
      };
    }

    case "RESET_CUSER_UPDATE": {
      return {
        ...state,
        editingStatus: 0,
        isUpdatingUser: false,
      };
    }

    case "SELECTED_USER": {
      return { ...state, selectedUser: action.user };
    }

    case "ADD_USER_START": {
      return {
        ...state,
        settingUser: true,
      };
    }

    case "ADD_USER": {
      return {
        ...state,
        settingUser: false,
        users: [action.response.data, ...state.users],
        selectedUser: action.response.data,
        userRequestStatus: action.response.fetchingStatus,
        userRequestMessage: action.response.message,
      };
    }

    case "ADD_REJECTED": {
      return {
        ...state,
        settingUser: false,
        userRequestStatus: action.response.fetchingStatus,
        userRequestMessage: action.response.message,
      };
    }

    case "ADD_CUSTOMER_USER_START": {
      return {
        ...state,
        isCreatingUser: true,
        fetchingStatus: 0,
        userCreationError: false,
      };
    }

    case "ADD_CUSTOMER_USER_SUCCESS": {
      return {
        ...state,
        isCreatingUser: false,
        isSetSms: true,
        fetchingStatus: action.response.fetchingStatus,
        userCreationError: false,
        message: action.response.message,
      };
    }

    case "ADD_CUSTOMER_USER_FAILED": {
      return {
        ...state,
        isCreatingUser: false,
        fetchingStatus: action.response.fetchingStatus,
        userCreationErrorMessage: action.response.userCreationErrorMessage,
        userCreationError: true,
        isSetSms: false,
      };
    }

    case "ADD_CUSTOMER_USER_RESET": {
      return {
        ...state,
        isCreatingUser: false,
        fetchingStatus: 0,
        userCreationErrorMessage: "",
        userCreationError: false,
      };
    }

    case "UPDATE_USER_START": {
      return {
        ...state,
        settingUser: true,
      };
    }

    case "UPDATE_USER": {
      const index = state.users.findIndex((user, index) => {
        return user.id === action.response.data.id;
      });
      state.users.splice(index, 1, action.response.data);
      return {
        ...state,
        settingUser: false,
        users: state.users,
        selectedUser: action.response.data,
        userRequestStatus: action.response.fetchingStatus,
        userRequestMessage: action.response.message,
      };
    }

    case "UPDATE_REJECTED": {
      return {
        ...state,
        settingUser: false,
        userRequestStatus: action.response.fetchingStatus,
        userRequestMessage: action.response.message,
      };
    }

    case "RESET_USER_REQ_PARAMS": {
      return {
        ...state,
        userRequestStatus: action.response.fetchingStatus,
        userRequestMessage: action.response.message,
      };
    }

    case "UPDATE_USERS": {
      const { id } = action.payload;
      const newUsers = [...state.users];
      const userToUpdate = newUsers.findIndex((user) => user.id === id);
      newUsers[userToUpdate] = action.payload;

      return {
        ...state,
        users: newUsers,
      };
    }

    case "DELETE_USERS": {
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    }

    default:
      return state;
  }
};

export default customerReducer;
