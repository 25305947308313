import React/* , { useState, useEffect } */ from "react";
import "../../../../assets/Styles/Dashboard.css";
import { FilterSVG, ExportSVG/* , CompletedMarkSVG, LoadingSVG, DownloadSVG, MarkSVG, MissSVG */ } from "../../../../assets/Svgs/Svgs";
import { CSVLink } from "react-csv";
// import Modal from "react-bootstrap/Modal";
// import { longMonthNames, shortMonthNames, shortDayNames } from "../../../../lib/Functions/DateNames";



const SettlementList = (props) => {
  const content = [];
  // const [pageNo, setPageNo] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);
 

  return (
    <>
    <div className="disbursed-cards-container row">
      <div className="menu-link" style={{marginBottom: 0, paddingBottom: 4}}>
        <span style={{color: "#000000", borderBottomColor: "#00425F", borderBottomWidth: 3, borderBottomStyle: "solid"}}>Settlement Report</span>
      </div>  
      {content.length > 0 && <CSVLink data={content} filename={'Transaction Report.csv'} className="" target="_blank">
        <div className="disbursed-cards-export-btn" style={{float: "right"}}>
          <span className="text-white">Export CSV <ExportSVG /></span>
        </div> 
      </CSVLink>}
      <table className="table table-hover">
          <thead>
            <tr>
              <td>Customer ID</td>
              <td>Customer Name</td>
              <td>Card Content</td>
              <td>Vendor ID</td>
              <td>Total</td>
              <td>Date <FilterSVG /></td>
              <td>Status <FilterSVG /></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr><td colSpan={7} className="text-center">No Settlement to show</td></tr>
          </tbody>
      </table>
      {/* {totalPages >= 1 && <div className="pagination-container row">
        <div className={`col-2 offset-3 prev text-center ${pageNo > 1 ? "visible": "invisible"}`} onClick={() => {
          setPageNo(pageNo - 1);
        }}>
          Prev
        </div>
        <div className="col-2 text-center">
          <span className="align-middle">{`Page ${pageNo} of ${totalPages}`}</span>
        </div>
        <div className={`col-2 next text-center ${pageNo === totalPages ? "invisible": "visible"}`} onClick={() => {
          setPageNo(pageNo + 1);
        }}>
          Next
        </div>
      </div>} */}
    </div>

    {/* <Modal
          show={showTransactionModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="transaction-modal"
        >
          <Modal.Body>
            <div className="pl-5 pr-5 pt-5 pb-5 text-center">
            <div style={{borderBottomColor: "#5F738C", borderBottomWidth: 0.5, borderBottomStyle: "solid", textAlign: "left"}}><h6 className="mt-5 text-left d-inline">Transaction Details</h6>
            <span style={{float: "right", cursor: "pointer"}} onClick={() => {setShowTransactionModal(false)}}> X </span>
            </div>
            <div className="text-center mt-4">
              {status === "Successful" ? <MarkSVG />: <MissSVG />}
            </div>
            <div className="text-center mt-3 mb-1 font-weight-bold quickteller-black">
              Your transaction was {status === "Successful" ? "successful": "unsuccessful"}
            </div>
            <span className="quickteller-black f-14">{location} Purchase for {cardName} {cardNumber} </span>
            {<>
              <div className="col-12 text-left bg-white mb-3 mt-4">
                <div className="row" style={{fontSize: 14, color: "#4B4B4B", border: "none", borderWidth: 0, borderBottomColor: "#5F738C", borderBottomWidth: 0.5, borderStyle: "solid", boxSizing: "border-box"}}>
                  <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                    <span className="d-block font-weight-bold qt-dark-blue">Description</span>
                    <span className="d-block qt-gray mt-3">Smart Fuel Purchase for {cardName}</span>
                  </div>
                  <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                  <span className="d-block font-weight-bold qt-dark-blue">Date</span>
                    <span className="d-block qt-gray mt-3">{transactionDate}</span>
                  </div>
                </div>



                <div className="row" style={{fontSize: 14, color: "#4B4B4B", border: "none", borderWidth: 0, borderBottomColor: "#5F738C", borderBottomWidth: 0.5, borderStyle: "solid", boxSizing: "border-box"}}>
                  <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                    <span className="d-block font-weight-bold qt-dark-blue">Amount</span>
                    <span className="d-block qt-gray mt-3">{amount}</span>
                  </div>
                  <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                  <span className="d-block font-weight-bold qt-dark-blue">Card Pan</span>
            <span className="d-block qt-gray mt-3">{cardNumber}</span>
                  </div>
                </div>



                <div className="row" style={{fontSize: 14, color: "#4B4B4B", border: "none", borderWidth: 0, borderBottomColor: "#5F738C", borderBottomWidth: 0.5, borderStyle: "solid", boxSizing: "border-box"}}>
                  <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                    <span className="d-block font-weight-bold qt-dark-blue">Customer Name</span>
                    <span className="d-block qt-gray mt-3">{cardName}</span>
                  </div>
                  <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                  <span className="d-block font-weight-bold qt-dark-blue">Location</span>
                    <span className="d-block qt-gray mt-3">{location}</span>
                  </div>
                </div>

                <div className="row" style={{fontSize: 14, color: "#4B4B4B", border: "none", borderWidth: 0, borderBottomColor: "#5F738C", borderBottomWidth: 0.5, borderStyle: "solid", boxSizing: "border-box"}}>
                  <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                    <span className="d-block font-weight-bold qt-dark-blue">Vendor</span>
                    <span className="d-block qt-gray mt-3">{vendor}</span>
                  </div>
                  <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                  <span className="d-block font-weight-bold qt-dark-blue">Request Reference</span>
                    <span className="d-block qt-gray mt-3">{reference}</span>
                  </div>
                </div>



              </div>
            </>}
            </div>
          </Modal.Body>
        </Modal> */}

    </>
  )
};

export default SettlementList;