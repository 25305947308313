import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../../../../assets/Styles/Dashboard.css";
import {
  FilterSVG,
  ExportSVG,
  MarkSVG,
  MissSVG,
  CloseSVG,
} from "../../../../assets/Svgs/Svgs";
// import {CSVLink} from "react-csv";
import Modal from "react-bootstrap/Modal";
import {
  longMonthNames,
  shortMonthNames,
  shortDayNames,
} from "../../../../lib/Functions/DateNames";
import { SkeletonTheme } from "react-loading-skeleton";
import ReactToPrint from "react-to-print";
import Pagination from "../../../../lib/Components/Pagination";
import formatAmount from "../../../../lib/Functions/formatAmount";
import { getUserProperties } from "../../../../lib/Constants/UserTypes";
import { permissions } from "../../../../lib/Constants/Permissions";
import { Spin } from "antd";
import "../../Accounts/Users/Styles/Users.css";

const SettlementContractList = (props) => {
  const {
    oTrailReducer,
    title,
    downloading,
    onDownloadTransactions,
    onFetchTransactions,
    vendorNum,
    location: locationFltr,
    dateRange,
    status: statusFltr,
    customerNumber,
  } = props;

  console.log("give sth", props);

  const { fetching, sContractreport } = oTrailReducer;
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [showTransactionModal, setShowTransactionModal] = useState(false);

  // console.log("This is what i look like", sContractreport);
  const componentRef = useRef();
  const pageSize = 10;
  const {
    cardName,
    cardNumber,
    location,
    time,
    amount,
    vendor,
    reference,
    status,
    vendorNumber,
    volume,
    odometer,
    truckNumber,
    tripNumber,
    driverName,
    dealerCode,
    dealerRep,
    otherInformation,
    product,
  } = selectedTransaction;

  useEffect(() => {
    !title && onFetchTransactions(1, pageSize);
  }, [vendorNum, locationFltr, dateRange, statusFltr]); // eslint-disable-line react-hooks/exhaustive-deps

  const d = new Date(time);
  const dayIndex =
    d.getDay() - 1 < 0 ? 7 - Math.abs(d.getDay() - 1) : d.getDay() - 1;
  const transactionDate = `${shortDayNames[dayIndex]}, ${
    longMonthNames[d.getMonth()]
  } ${d.getDate()}, ${d.getFullYear()}`;

  const { permissions: authUserPermissions = [] } = getUserProperties() || {};
  // const csvData = (sContractreport || []).map((item) => ({...item, amount: item.amount / 100}));

  const contractList = [];
  sContractreport &&
    sContractreport.forEach((value, index) => {
      const {
        contractName,
        vendorName,
        customerName,
        totalAmountUsed,
        totalNoOfTransactions,
        productPurchaseValue,
        totalProductUsedValue,
        totalUser,
        transactionAmount,
      } = value;

      let classIdentifier = "",
        condition = "";

      const d = new Date(time);
      const date = d.getDate();
      let suffix =
        (date >= 4 && date <= 20) || (date >= 24 && date <= 30)
          ? "th"
          : ["st", "nd", "rd"][(date % 10) - 1];
      const dayIndex =
        d.getDay() - 1 < 0 ? 7 - Math.abs(d.getDay() - 1) : d.getDay() - 1;

      if (customerNumber === props.authentication.user.customerNumber) {
        if (value.status === "Successful" || value.status === "SUCCESS") {
          classIdentifier = "success";
          condition = "Successful";
        } else {
          classIdentifier = "error";
          condition = "Failed";
        }

        contractList.push(
          <tr key={index}>
            <td className="table-description">{contractName}</td>
            <td className="table-pin">{vendorName}</td>
            <td className="table-description">{customerName}</td>
            <td className="table-description">
              ₦ {formatAmount(transactionAmount)}
            </td>
            <td className="table-description">{totalAmountUsed}</td>
            <td className="table-description">{totalNoOfTransactions}</td>
            <td className="table-description">{productPurchaseValue}</td>
            <td className="table-description">{totalProductUsedValue}</td>
            <td className="table-description">{totalUser}</td>

            {/* {authUserPermissions.indexOf(permissions.VIEW_TRANSACTION) > -1 && (
              <td
                className="table-action"
                onClick={() => {
                  setSelectedTransaction(value);
                  setShowTransactionModal(true);
                }}
              >
                View
              </td>
            )} */}
          </tr>
        );
      }
    });

  return (
    <>
      <SkeletonTheme color="#fff" highlightColor="#f4f4f4">
        <div className="disbursed-cards-container row">
          <div
            className="menu-link"
            style={{ marginBottom: 0, paddingBottom: 4 }}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid",
              }}
            >
              {!!title ? title : "Settlement Transactions"}
            </span>
          </div>

          {!!sContractreport &&
            sContractreport.length > 0 &&
            typeof onDownloadTransactions === "function" && (
              <div
                className="disbursed-cards-export-btn"
                style={{ float: "right" }}
              >
                {downloading ? (
                  "Loading..."
                ) : (
                  <Link
                    to="#"
                    onClick={() => {
                      onDownloadTransactions();
                    }}
                    className="text-white"
                  >
                    Export XLS <ExportSVG />
                  </Link>
                )}
              </div>
              // {</CSVLink>}
            )}

          <table className="table table-hover">
            <thead>
              <tr>
                <td>Contract Name</td>
                <td>Vendor Name</td>
                <td>Customer Name</td>
                <td>Total Contract amount</td>
                <td>Total amount used</td>
                <td>Total numbers of transactions</td>
                <td>Total product purchased</td>
                <td>Total product used</td>
                <td>Total Users</td>
              </tr>
            </thead>
            {!fetching && (
              <tbody>
                {contractList && contractList.length > 0 ? (
                  contractList
                ) : (
                  <tr>
                    <td
                      colSpan={
                        authUserPermissions.indexOf(
                          permissions.VIEW_TRANSACTION
                        ) > -1
                          ? 8
                          : 7
                      }
                      className="text-center"
                    >
                      No Settlement Contract List to show
                    </td>
                  </tr>
                )}
              </tbody>
            )}

            {fetching && (
              <tbody>
                <tr>
                  <td
                    className="text-center"
                    colSpan={
                      authUserPermissions.indexOf(
                        permissions.VIEW_TRANSACTION
                      ) > -1
                        ? 8
                        : 7
                    }
                  >
                    <Spin
                      wrapperClassName="spinner-wrapper"
                      spinning={fetching}
                      tip="Loading..."
                    ></Spin>{" "}
                  </td>
                </tr>
              </tbody>
            )}
          </table>

          {!title && (
            <Pagination
              count={sContractreport.length}
              pageSize={pageSize}
              fetchMethod={(pageNo, pageSize) => {
                onFetchTransactions(pageNo, pageSize);
              }}
            />
          )}
        </div>

        <Modal
          show={showTransactionModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="transaction-modal"
        >
          <Modal.Body ref={componentRef}>
            <div className="pl-5 pr-5 pt-5 pb-5 text-center">
              <div
                style={{
                  borderBottomColor: "#5F738C",
                  borderBottomWidth: 0.5,
                  borderBottomStyle: "solid",
                  textAlign: "left",
                }}
              >
                <h6 className="mt-5 text-left d-inline">
                  Settlement Contract Report Details
                </h6>
                <span
                  style={{
                    float: "right",
                    cursor: "pointer",
                    position: "relative",
                    bottom: 5,
                  }}
                  onClick={() => {
                    setShowTransactionModal(false);
                  }}
                >
                  <CloseSVG />
                </span>
              </div>
              <div className="text-center mt-4">
                {status === "Successful" || status === "SUCCESS" ? (
                  <MarkSVG />
                ) : (
                  <MissSVG />
                )}
              </div>
              <div className="text-center mt-3 mb-1 font-weight-bold quickteller-black">
                Your transaction was{" "}
                {status === "Successful" || status === "SUCCESS"
                  ? "successful"
                  : "unsuccessful"}
              </div>
              <span className="quickteller-black f-14">
                {location} Purchase for {cardName} {product}{" "}
              </span>
              {
                <>
                  <div className="col-12 text-left bg-white mb-3 mt-4">
                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        borderWidth: 0,
                        borderBottomColor: "#5F738C",
                        borderBottomWidth: 0.5,
                        borderStyle: "solid",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Description
                        </span>
                        <span className="d-block qt-gray mt-1">
                          Smart Fuel Purchase for {product}
                        </span>
                      </div>
                      <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Date
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {transactionDate}
                        </span>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        borderWidth: 0,
                        borderBottomColor: "#5F738C",
                        borderBottomWidth: 0.5,
                        borderStyle: "solid",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Amount
                        </span>
                        <span className="d-block qt-gray mt-1">
                          ₦ {formatAmount(amount)}
                        </span>
                      </div>
                      <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Card Pan
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {cardNumber}
                        </span>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        borderWidth: 0,
                        borderBottomColor: "#5F738C",
                        borderBottomWidth: 0.5,
                        borderStyle: "solid",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Volume / Quantity Dispensed
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {volume || "0.00"} Litres
                        </span>
                      </div>
                      <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Truck Number
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {truckNumber}&nbsp;
                        </span>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        borderWidth: 0,
                        borderBottomColor: "#5F738C",
                        borderBottomWidth: 0.5,
                        borderStyle: "solid",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Driver Name
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {driverName}&nbsp;
                        </span>
                      </div>
                      <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Trip Number
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {tripNumber}&nbsp;
                        </span>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        borderWidth: 0,
                        borderBottomColor: "#5F738C",
                        borderBottomWidth: 0.5,
                        borderStyle: "solid",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Dealer Rep
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {dealerRep}&nbsp;
                        </span>
                      </div>
                      <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Dealer Code
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {dealerCode}&nbsp;
                        </span>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        borderWidth: 0,
                        borderBottomColor: "#5F738C",
                        borderBottomWidth: 0.5,
                        borderStyle: "solid",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Other Info.
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {otherInformation || "---"}&nbsp;
                        </span>
                      </div>
                      <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Odometer No.
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {odometer}&nbsp;
                        </span>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        borderWidth: 0,
                        borderBottomColor: "#5F738C",
                        borderBottomWidth: 0.5,
                        borderStyle: "solid",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Customer Name
                        </span>
                        <span className="d-block qt-gray mt-1">{cardName}</span>
                      </div>
                      <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Location
                        </span>
                        <span className="d-block qt-gray mt-1">{location}</span>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        borderWidth: 0,
                        borderBottomColor: "#5F738C",
                        borderBottomWidth: 0.5,
                        borderStyle: "solid",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col-7 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Vendor
                        </span>
                        <span className="d-block qt-gray mt-1">{vendor}</span>
                      </div>
                      <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Vendor ID
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {vendorNumber}
                        </span>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        borderWidth: 0,
                        borderBottomColor: "#5F738C",
                        borderBottomWidth: 0.5,
                        borderStyle: "solid",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col-5 pl-0 pr-0 pt-2 pb-2">
                        <span className="d-block font-weight-bold qt-dark-blue">
                          Payment Reference
                        </span>
                        <span className="d-block qt-gray mt-1">
                          {reference}
                        </span>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        fontSize: 14,
                        color: "#4B4B4B",
                        border: "none",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="col d-print-none pl-0 pr-0 pt-3 pb-3 text-center">
                        <span className="d-block font-weight-bold qt-dark-blue text-white print-btn">
                          <ReactToPrint
                            trigger={() => (
                              <a href="#print" className="text-white">
                                Print receipt
                              </a>
                            )}
                            sContractreport={() => componentRef.current}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </Modal.Body>
        </Modal>
      </SkeletonTheme>
    </>
  );
};

export default SettlementContractList;
