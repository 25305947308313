import React/* , { useEffect } */ from "react";
import Modal from "react-bootstrap/Modal";
import { LoadingSVG, CompletedMarkSVG } from "../../assets/Svgs/Svgs";
import PropTypes from "prop-types";

export default function ActionModal({
  isCompleted,
  isShow,
  actionText = '',
  actionTitle,
  isCompletedTitle,
  isCompletedText,
  children,
  titleStyle,
  // updateCompletedStatus,
}) {
  // useEffect(() => {
  //   if (!isCompleted && isShow) {
  //     setTimeout(() => {
  //       updateCompletedStatus(true);
  //     }, 2000);
  //   }
  // }, [isCompleted])
  return (
    <Modal
      show={isShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="rfid-modal"
      data-testid = 'action-modal'
    >
      <Modal.Body>
        {!isCompleted && (
          <>
            <h5 className={`text-center mt-5 mb-5 quickteller-blue font-weight-bold`} style={titleStyle}>
              {actionTitle}
            </h5>
            <div className="text-center mt-5">
              <LoadingSVG />
            </div>
            <h5 className="quickteller-blue text-center mt-5 mb-5 font-weight-bold">
              {actionText}
            </h5>
          </>
        )}
        {isCompleted && (
          <>
            <div className="text-center mt-5 mb-4">
              {!!isCompletedTitle && <span
                className="d-block font-weight-bold mb-4"
                style={{ fontSize: 16, color: "#4B4B4B" }}
              >
                {isCompletedTitle}
              </span>}
              <CompletedMarkSVG />
              <span
                className="d-block"
                style={{ fontSize: 16, color: "#4B4B4B" }}
              >
                {isCompletedText}
              </span>
            </div>
          </>
        )}
        {children}
      </Modal.Body>
    </Modal>
  );
}

ActionModal.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
  isShow: PropTypes.bool.isRequired,
  actionText: PropTypes.string.isRequired,
  actionTitle: PropTypes.string.isRequired,
  isCompletedTitle: PropTypes.string,
  isCompletedText: PropTypes.string.isRequired,
  children: PropTypes.element
};
