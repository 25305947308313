import React from 'react';
import { SmallEllipse, XButton } from '../../../../assets/Svgs/Svgs';
import './PermissionBadge.css';

const PermissionBadge = ({edit, permission, module, onDeleteHandler}) => {
    return (
    <div className={`permission-badge ${edit && "no-right-padding"}`}>
        <SmallEllipse />
        {permission || module}
        {edit && <i className="x-button" onClick={onDeleteHandler.bind(this, permission || module)}>
            {module 
                ? <i className="material-icons" style={{ color: '#D0021B', fontSize: '18px'}}>delete</i> 
                : <XButton />}
        </i>}
    </div>
    );
}

export default PermissionBadge;
