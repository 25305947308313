import React, { useState } from "react";
import "../../../../assets/Styles/Dashboard.css";
import { CreateSVG, ExportSVG, FilterSVG } from "../../../../assets/Svgs/Svgs";
import { StateList } from '../../../../services/states';
import { CSVLink } from "react-csv";
import { Button, Select } from 'antd';
import toSentenceCase from "../../../../lib/Functions/toSentenceCase";
import Pagination from "../../../../lib/Components/Pagination";
import CreateTerminal from "./CreateTerminal";
import { TerminalDetails } from "./TerminalDetails";
import BulkTerminalUpload from "../../../shared/BulkTerminal";

const TerminalList = props => {
  const [isCreateTerminal, setIsCreateTerminal] = useState(false);
  const [isTerminalDetails, setIsTerminalDetails] = useState(false);
  const [isTerminalEdit, setIsTerminalEdit] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const [selectedVendor, setVendorSelected] = useState("");
  const [showTerminalModal, setShowTerminalModal] = useState(false);
  const {
    oVendorReducer,
    onFetchTerminals,
    onHideStatusBar,
  } = props;

  const allTerminals = oVendorReducer.allTerminals;
  let totalCount = allTerminals.count;
  let terminalData = allTerminals ? allTerminals.data : [];

  const ven = oVendorReducer.allVendors;
  const venRow = ven.data ? ven.data : ven;
  const vendorData = venRow.data ? venRow.data : venRow;

  const terminalList = terminalData && terminalData.map((value, i) => {
    const { name, terminalId, dealerCode, merchantId, status, state } = value;
    return (
      <tr key={i}>
        <td className="table-description font-weight-bold">{name}</td>
        <td className="table-description font-weight-bold">{terminalId}</td>
        <td className="table-description font-weight-bold">{dealerCode}</td>
        <td className="table-description">{merchantId}</td>
        <td className="table-description">{toSentenceCase(state)}</td>
        <td className={"table-status"}>
          <div
            className={`status-btn ${status === "ACTIVE" ? "success" : "error"
              }`}
          >
            {toSentenceCase(status)}
          </div>
        </td>
        <td
          className="table-action"
          onClick={() => {
            setIsTerminalDetails(true);
            onHideStatusBar(true);
            setIsCreateTerminal(false);
            setSelectedTerminal(value);
          }}
        >
          View
        </td>
      </tr>
    );
  });

  const pageSize = 10;

  const handleBackToTable = () => {
    setIsTerminalDetails(false);
    onHideStatusBar(false);
    setIsCreateTerminal(false);
    setSelectedTerminal({});
  }

  const showEditTerminal = () => {
    setIsTerminalEdit(true);
    onHideStatusBar(true);
    setIsCreateTerminal(false);
    setIsTerminalDetails(false);
  }

  const handleTerminalFormBack = (instruction) => {
    if (instruction === 'back') {
      setIsTerminalDetails(false);
      setIsCreateTerminal(false);
      setIsTerminalEdit(false);
      onHideStatusBar(false);
      return;
    }
    if (isCreateTerminal) {
      setIsCreateTerminal(false);
      onHideStatusBar(false);
    } else if (isTerminalEdit) {
      setIsTerminalDetails(true);
      setIsTerminalEdit(false);
      setIsCreateTerminal(false);
      onHideStatusBar(false);
    }
  }

  return (
    <div className="disbursed-cards-container row">
      {!isCreateTerminal && !isTerminalDetails && !isTerminalEdit && (
        <>
          <div className="col-12 m-0 p-0">
            <div
              className="rfid-menu-link"
              style={{ marginBottom: 0, marginRight: 20 }}
            >
              <span style={{ color: "#000000" }} className="active">
                All Terminals
              </span>
            </div>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <div style={{ display: 'inline-block' }} className="mb-2">
                <span className="filter-tags-desc">Filters:</span>
                <Select
                  style={{ width: 150 }}
                  defaultValue={selectedState}
                  className="mr-3"
                  placeholder="Choose State"
                  allowClear
                  showSearch
                  onChange={value => setSelectedState(value)}>
                  <Select.Option value="">Select State</Select.Option>
                  {StateList.map((value, key) => (
                    <Select.Option value={value} key={key}>{value}</Select.Option>
                  ))}
                </Select>
                <Select
                  style={{ width: 150 }}
                  defaultValue={selectedVendor}
                  className="mr-3"
                  placeholder="Choose Vendor"
                  allowClear
                  showSearch
                  onChange={value => setVendorSelected(value)}>
                  <Select.Option value="">Select Vendor</Select.Option>
                  {vendorData.map((value, key) => (
                    <Select.Option value={value.id} key={`${key}_${value.id}`}>{value.name}</Select.Option>
                  ))}
                </Select>
                <Button onClick={
                  () => onFetchTerminals(1, pageSize, selectedState, selectedVendor)
                } type="primary"
                >
                  Search
                </Button>
              </div>
              <div style={{ display: 'inline-block' }}>
                <button
                  style={{ position: 'relative', border: 'none' }}
                  className="create-new-tags-btn mr-2"
                  onClick={() => {
                    onHideStatusBar(true);
                    setIsCreateTerminal(true);
                  }}
                >
                  <span className="align-middle">
                    <CreateSVG /> Create terminal
                  </span>
                </button>
                <button onClick={() => setShowTerminalModal(true)} style={{ position: 'relative', border: 'none' }} className="create-new-tags-btn mr-2">
                  <CreateSVG /> Create terminal(s)
                </button>
                {
                  <button style={{ position: 'relative', display: 'inline-block', border: 'none', right: 'unset' }}
                    className="terminals-export-btn">
                    <CSVLink
                      data={terminalData || []}
                      filename={"Terminals.csv"}
                      className=""
                      target="_blank"
                    >
                      <span className="text-white">
                        Export CSV <ExportSVG />
                      </span>
                    </CSVLink>
                  </button>
                }{" "}
              </div>
            </div>
          </div>
          {
            <table className="table table-hover">
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Terminal ID</td>
                  <td>Dealer Code</td>
                  <td>Merchant ID</td>
                  <td>State</td>
                  <td>
                    Status <FilterSVG />
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {terminalList || (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No Terminal available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          }

          {
            <Pagination
              count={totalCount}
              pageSize={pageSize}
              fetchMethod={(pageNo, pageSize) => {
                onFetchTerminals(pageNo, pageSize, selectedState, selectedVendor);
              }}
            />
          }
        </>
      )}
      {isTerminalDetails && (<TerminalDetails
        selectedTerminal={selectedTerminal}
        handleBack={handleBackToTable}
        showTerminalEdit={showEditTerminal} />)}

      {(isCreateTerminal || isTerminalEdit) && (<CreateTerminal
        {...props}
        isCreateTerminal={isCreateTerminal}
        terminalToEdit={selectedTerminal}
        isTerminalEdit={isTerminalEdit}
        onBackHandler={handleTerminalFormBack}
      />)}
      <BulkTerminalUpload showBulkModal={showTerminalModal} closeBulkUploadModal={() => setShowTerminalModal(false)} uploadComplete={() => {
        setShowTerminalModal(false)
        onFetchTerminals(1, pageSize, selectedState);
      }} />
    </div>
  );
};

export default TerminalList;
