import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  BackSVG,
  ActiveMarkSVG,
  // UploadSVG,
  AvatarSVG,
  DisabledMark,
  UserCompleteMarkSVG,
  LoadingSVG
} from "../../../../assets/Svgs/Svgs";
import PermissionBadge from "../../subcomponents/PermissionBadge/PermissionBadge";
import {
  shortMonthNames,
  shortDayNames,
} from "../../../../lib/Functions/DateNames";
import EditForm from "./EditUser";
import { useDispatch } from "react-redux";
import { toggleUserStatus } from "./users.actions";
import { 
  assignUserModule, fetchUserModules, removeUserModule, resetUserUpdate
} from "../../../../actions/OwnerActions/OwnerUserMgtActions/OwnerUserActions";
// import FileBase64 from "react-file-base64";

const AccountDetails = props => {
  // const [showSettings, setShowSettings] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showEditModuleForm, setShowEditModuleForm] = useState(false);
  const { user, modules: availableModules, ownerUsers: { assignedModules, isEditModuleMode } } = props;
  const { permissions } = user;
  const d = new Date(user.createdAt);
  const dispatch = useDispatch();
  // const toggleSettings = () => setShowSettings(!showSettings);

  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [showCreateSuccessModal, setShowCreateSuccessModal] = useState(false);
  const [profilePix, setProfilePix] = useState(user.image || "");// eslint-disable-line no-unused-vars
  const [userCreationError, setUserCreationError] = useState(false);
  // const [resetForm, setResetForm] = useState(false);
  const [saveEditForm, setSaveEditForm] = useState(false);

  /* const toggleModal = () => {
    setIsCreatingUser(false);
    setShowCreateSuccessModal(false);
  }; */

  useEffect(() => {
    setIsCreatingUser(props.ownerUsers.isCreatingUser);
  }, [props.ownerUsers.isCreatingUser]);

  useEffect(() => {
    dispatch(fetchUserModules(user.userEmail));
  }, [props.ownerUsers.isEditModuleMode, user.userEmail]);

  useEffect(() => {
    if (props.ownerUsers.fetchingStatus === 1) {
      setShowCreateSuccessModal(true);
    } else if (props.ownerUsers.fetchingStatus === 2) {
      setUserCreationError(true);
    }
  }, [props.ownerUsers.fetchingStatus]);

  useEffect(() => {
    setIsCreatingUser(false);
    setShowCreateSuccessModal(false);
    dispatch(resetUserUpdate());
  }, []);

  return (
    <>
      <>
        <Modal
          isOpen={isCreatingUser || showCreateSuccessModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="user-modal"
        >
          <ModalBody>
            {isCreatingUser && (
              <>
                <div className="text-center mt-5 header">Editing user....</div>
                <div className="text-center mt-5 mb-5">
                  <LoadingSVG />
                </div>
              </>
            )}

            {showCreateSuccessModal && (
              <>
                <div className="text-center mt-5 mb-4">
                  <span
                    className="d-block font-weight-bold"
                    style={{
                      fontSize: "20px",
                      color: "#4B4B4B",
                      margin: "1rem"
                    }}
                  >
                    Thank you! You are all set!
                  </span>
                  <UserCompleteMarkSVG />
                  <span
                    className="d-block quickteller-blue font-weight-bold"
                    style={{
                      fontSize: "1rem",
                      color: "#00425F",
                      margin: "1.25rem"
                    }}
                  >
                    Your new user profile has been successfully edited
                  </span>
                </div>
                <div className="row modal-action-buttons">
                  <div className="col-12 text-center">
                    <button
                      className="back"
                      onClick={() => {
                        props.onEditingDone();
                      }}
                      style={{ width: 120 }}
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
              </>
            )}
          </ModalBody>
        </Modal>
      </>
      
      <div className="underlined-tab">
        <span className="tab-title">Account details</span>
        <div className="action-buttons">
          <button className="export-btn" onClick={props.backFunction}>
            <BackSVG />
            Back
          </button>
        </div>
      </div>

      <div className="row account-details">
        <div className="col-3 profile-picture-and-actions">
          <div className="avatar">
            {!!profilePix ? (
              <img
                alt="Profile"
                src={profilePix.base64 || profilePix}
                style={{ width: 100, height: 100, borderRadius: 50 }}
              />
            ) : (
              <AvatarSVG />
            )}
          </div>
          <>{/* {showEditForm && (
            <>
              <div className="upload">
                <i>
                  <UploadSVG />
                </i>{" "}
                <FileBase64
                  multiple={false}
                  onDone={file => {
                    if (file.type === "image/jpeg" || file.type === "image/png")
                      setProfilePix(file);
                  }}
                />
              </div>
              <button
                className="button remove-btn"
                onClick={() => setProfilePix("")}
              >
                Remove
              </button>
            </>
          )} */}
          {/* <button className="button remove-btn">Remove</button> */}</>

          {showEditForm && (
            <button
              className="button save-btn"
              onClick={() => setSaveEditForm(true)}
            >
              Save changes
            </button>
          )}

          {!showEditForm && (<>
            <button
              className="button edit-profile-btn"
              onClick={() => setShowEditForm(true)}
            >
              Edit Profile
            </button>

            {(!(user.userLevel === 'SUPER_ADMIN' && user.type === 'OWNER_USER')) && <button
                className={`button ${
                user.status === "ACTIVE" ? "suspend-btn" : "activate-btn"
            }`}
            onClick={() => {
                dispatch(
                toggleUserStatus(
                    user.id,
                    user.status === "ACTIVE" ? "DISABLED" : "ACTIVE"
                )
                );
            }}
            >
            {props.ownerUsers.isUpdatingUser ? (
                <img
                src="/images/ActivityIndicator.gif" alt="activity-indicator"
                style={{ height: 20, width: 20 }}
                />
            ) : (
                `${
                user.status !== "ACTIVE" ? "Activate" : "Suspend"
                } Account`
            )}
            </button>}
          </>)}
        </div>

        {!showEditForm && (
          <>
            <div className="col-9">
              <div className="row user-info">
                <div className="row user-info-title">
                  <div className="col-2">First Name</div>
                  <div className="col-2">Last Name</div>
                  <div className="col-4">Email</div>
                  <div className="col-2">User Level</div>
                  <div className="col-2">User Type</div>
                </div>
                <div className="row user-info-details">
                  <div className="col-2" title={user.firstName}>
                    {user.firstName}
                  </div>
                  <div className="col-2" title={user.lastName}>
                    {user.lastName}
                  </div>
                  <div className="col-4" title={user.userEmail} style={{overflowWrap: "break-word"}}>
                    {user.userEmail}
                  </div>
                  <div className="col-2" title={user.userLevel}>
                    {user.userLevel.split("_").join(" ")[0].toUpperCase()}{user.userLevel.split("_").join(" ").slice(1).toLowerCase()}
                  </div>
                  <div className="col-2" title={user.type}>
                    {user.type.split("_").join(" ")[0].toUpperCase()}{user.type.split("_").join(" ").slice(1).toLowerCase()}
                  </div>
                </div>
              </div>
              <div className="row user-extras">
                <div className="inner">
                  <div className="row user-extras-title">
                    <div className="col-4">Date Created</div>
                    <div className="col-4">Phone</div>
                    <div className="col-4">Status</div>
                  </div>
                  <div className="row user-extras-detail">
                    <div className="col-4">
                      {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
                      {shortMonthNames[d.getMonth()]} {d.getFullYear()}
                    </div>
                    <div className="col-4">
                      {user.contact.phoneNumber || ""}
                    </div>
                    {user.status.toUpperCase() === "ACTIVE" ? (
                      <div className="col-4">
                        Active <ActiveMarkSVG />
                      </div>
                    ) : (
                      <div className="col-4">
                        Disabled <DisabledMark />
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="action-btn-column">  */}
                {/* </div> */}
              </div>
              <div className="row user-address">
                <div className="row user-address-title">
                  <div className="col-2">Street</div>
                  <div className="col-2">City</div>
                  <div className="col-3">State</div>
                  <div className="col-2">Country</div>
                </div>
                <div className="row user-address-details">
                  <div className="col-2">
                    {(user.address && user.address.street) || "Unavailable"}
                  </div>
                  <div className="col-2">
                    {(user.address && user.address.city) || "Unavailable"}
                  </div>
                  <div className="col-3">
                    {(user.address && user.address.state) || "Unavailable"}
                  </div>
                  <div className="col-2">
                    {(user.address && user.address.country) || "Unavailable"}
                  </div>
                </div>
              </div>
              <div className="row user-overview">
                <span className="overview-title">Permissions</span>
                {(!permissions || !permissions.length) && "No permission assigned to user"}
                {permissions && permissions.map((permission, index) => (
                    <PermissionBadge key={index} permission={permission.name} />
                ))}
              </div>

              <div className="row user-overview">
                <span className="overview-title">Modules</span>
                {(!assignedModules || !assignedModules.length) && "No module assigned to user"}
                {assignedModules && assignedModules.map((modulex) => (
                    <PermissionBadge key={modulex.id} module={modulex.name.replace('_', ' ')} edit={showEditModuleForm} onDeleteHandler={() => {
                        dispatch(removeUserModule(user.id, modulex));
                    }} />
                ))}{' '}

                <span style={{position: "absolute", top: 0, right: 0}}>
                    <a href="#edit" onClick={() => setShowEditModuleForm(!showEditModuleForm)} title={`${showEditModuleForm ? 'Close': 'Edit'}`}>
                        <i className="material-icons" style={{color: "#00425F"}}>{showEditModuleForm ? 'close' : 'edit'}</i>
                    </a>
                </span>
              </div>

              {showEditModuleForm && 
                <div className="row user-overview">
                    <ul style={{display: 'block', padding: 0, margin: 0}}>
                        {availableModules && 
                            availableModules.filter(m => !assignedModules.map(m => m.name).includes(m.name)).map((modulex) => (
                            <li key={modulex.id}>
                                <label htmlFor={`check-${modulex.id}`} style={{cursor: "pointer"}}>
                                    <input type="checkbox" key={modulex.id} id={`check-${modulex.id}`} value={modulex.id} onClick={(e) => {
                                        if (e.target.checked) {
                                            dispatch(assignUserModule(user.id, modulex));
                                        }
                                    }} disabled={isEditModuleMode} />&nbsp;{modulex.name.replace('_', ' ')}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
              }{' '}
            </div>
          </>
        )}

        {showEditForm && (
          <div className="col-8 edit-form">
            <EditForm
              {...props}
              create={false}
              saveEditForm={saveEditForm}
              onSaveEditFormHandler={status => setSaveEditForm(status)}
              userToBeEdited={user}
              resetForm={false}
              profilePix={{ base64: profilePix.base64 || user.image }}
              userCreationError={userCreationError}
              userCreationErrorHandler={status => setUserCreationError(status)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AccountDetails;
