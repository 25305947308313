import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import {
  getUserProperties,
} from "../../../../lib/Constants/UserTypes";
import { fetchAllBlankcards } from "../../redux/action/OwnerCardActions";
import VoucherList from "./VoucherList";
import {
  getAllVouchersByCustomer,
  getAllVouchersByCustomerForStats,
  getSchemesByCus,
} from "../../../../actions/CustomerActions/CardMgtActions/CardAction";
import StatusBar from "../StatusBar";

function Vouchers() {
  const dispatch = useDispatch();
  const [showStatusBar, setshowStatusBar] = useState(true);

  const userProperties = getUserProperties();
  const customerNumber = userProperties.customerNumber;
  const userNumber = userProperties?.number;
  const pageSize = 10;

  useEffect(() => {
    batch(() => {
      dispatch(getSchemesByCus(customerNumber));
      dispatch(fetchAllBlankcards());
      dispatch(getAllVouchersByCustomer(customerNumber, 1, pageSize));
      dispatch(getAllVouchersByCustomerForStats(customerNumber));
    });
  }, []);

  const props = useSelector((state) => state);
  const { oCardReducer, customerCards } = props;
  const cards = oCardReducer.allBlankCards || [];
  const myCards = [];

  const myVouchers = customerCards.allGeneratedVouchers?.data ?? [];
  const myVouchersCount = customerCards.allGeneratedVouchers?.count ?? 0;
  const myVouchersListing = customerCards.allGeneratedVouchersList?.data ?? [];

  let activeCards = 0, inactiveCards = 0, unUsedVouchers = 0, usedVouchers = 0;

  cards.content && cards.content.forEach((val) => {
    if (val.userId && val.customerId === customerNumber) {
      myCards.push(val);
      activeCards++;
    } else if (!val.userId && val.customerId === customerNumber) inactiveCards++;
  });

  myVouchersListing.length && myVouchersListing.forEach(v => {
    if (v.status === 'UNUSED') unUsedVouchers++;
    else usedVouchers++;
  });

  return (
    <div className="dashboard-container">
      <StatusBar
        {...props}
        isVisible={showStatusBar}
        cardQuantity={{ activeCards, inactiveCards }}
        myVouchersStats={{ unUsedVouchers, usedVouchers }}
      />

      <VoucherList
        {...props}
        cardQuantity={{ activeCards, inactiveCards }}
        cards={cards.content}
        myVouchers={myVouchers}
        myVouchersCount={myVouchersCount}
        isStatusBarVisible={showStatusBar}
        onHideStatusBar={(status) => setshowStatusBar(!status)}
        customerNumber={customerNumber}
        userNumber={userNumber}
        pageSize={pageSize}
        onFetchPage={(pageNo) => {
          dispatch(getAllVouchersByCustomer(customerNumber, pageNo, pageSize))
        }}
      />
    </div>
  );
}

export default Vouchers;
