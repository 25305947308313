import React, {useState} from "react";
import "../../../../assets/Styles/Dashboard.css";
import {
  FilterSVG,
  // ExportSVG,
  CloseSVG,
  MarkSVG,
  MissSVG,
} from "../../../../assets/Svgs/Svgs";
import {CSVLink} from "react-csv";
import {
  shortMonthNames,
  shortDayNames,
  longMonthNames,
} from "../../../../lib/Functions/DateNames";
import {useRef} from "react";
import ReactToPrint from "react-to-print";
import Pagination from "../../../../lib/Components/Pagination";
import formatAmount from "../../../../lib/Functions/formatAmount";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const TransactionReport = (props) => {
  const {onFetchTransactions} = props;
  const vendorCustomer = useSelector((stateProps) => stateProps.vendorCustomer);
  const {content, count} = vendorCustomer.getAllTransactionbyVendorId;

  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const componentRef = useRef();
  const {
    cardName,
    cardNumber,
    location,
    time,
    amount,
    vendor,
    reference,
    status,
    vendorNumber,
  } = selectedTransaction;
  const d = new Date(time);
  const transactionDate = `${shortDayNames[d.getDay()]}, ${
    longMonthNames[d.getMonth()]
  } ${d.getDate()}, ${d.getFullYear()}`;
  const TransactionList = content?.map((value) => {
    const {id, location, cardNumber, vendor, amount} = value;
    let classIdentifier = "",
      condition = "";
    if (value.status === "Successful") {
      classIdentifier = "success";
      condition = "Successful";
    } else {
      classIdentifier = "error";
      condition = "Failed";
    }
    return (
      <tr key={id}>
        <td className="table-description">Purchased of fuel from {location}</td>
        <td className="table-description font-weight-bold">{cardNumber}</td>
        <td className="table-pin">{vendor}</td>
        <td className="table-description">₦ {formatAmount(amount)}</td>
        <td className="table-description">
          {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
          {shortMonthNames[d.getMonth()]} {d.getFullYear()}
        </td>
        <td className="table-status">
          <div className={`status-btn ${classIdentifier}`}>{condition}</div>
        </td>
        <td
          className="table-action"
          onClick={() => {
            setSelectedTransaction(value);
            setShowTransactionModal(true);
          }}
        >
          View
        </td>
      </tr>
    );
  });
  return (
    <>
      <div className="disbursed-cards-container row">
        <div className="menu-link" style={{marginBottom: 0, paddingBottom: 4}}>
          <span
            style={{
              color: "#000000",
              borderBottomColor: "#00425F",
              borderBottomWidth: 3,
              borderBottomStyle: "solid",
            }}
          >
            Recent Transactions
          </span>
        </div>
        {!!content && (
          <CSVLink
            data={content}
            filename={"Transaction Report.csv"}
            className=""
            target="_blank"
          >
            <div style={{float: "right"}}>
              <Link to="" className="more-transaction-link table-action">
                More Transactions
              </Link>
            </div>
          </CSVLink>
        )}
        <table className="table table-hover">
          <thead>
            <tr>
              <td>Description</td>
              <td>Card Pan</td>
              <td>
                Vendor <FilterSVG />
              </td>
              <td>Amount</td>
              <td>
                Date <FilterSVG />
              </td>
              <td>
                Status <FilterSVG />
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {/* {!!TransactionList && TransactionList} */}
            {(TransactionList && TransactionList.length > 0) || (
              <tr>
                <td colSpan={7} className="text-center">
                  No Transaction to show
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          count={count}
          pageSize={30}
          fetchMethod={(pageNo, pageSize) => {
            onFetchTransactions(pageNo, pageSize);
          }}
        />
      </div>
      <Modal
        show={showTransactionModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="transaction-modal"
      >
        <Modal.Body ref={componentRef}>
          <div className="pl-5 pr-5 pt-5 pb-5 text-center">
            <div
              style={{
                borderBottomColor: "#5F738C",
                borderBottomWidth: 0.5,
                borderBottomStyle: "solid",
                textAlign: "left",
              }}
            >
              <h6 className="mt-5 text-left d-inline">Transaction Details</h6>
              <span
                style={{
                  float: "right",
                  cursor: "pointer",
                  position: "relative",
                  bottom: 5,
                }}
                onClick={() => {
                  setShowTransactionModal(false);
                }}
              >
                <CloseSVG />
              </span>
            </div>
            <div className="text-center mt-4">
              {status === "Successful" ? <MarkSVG /> : <MissSVG />}
            </div>
            <div className="text-center mt-3 mb-1 font-weight-bold quickteller-black">
              Your transaction was{" "}
              {status === "Successful" ? "successful" : "unsuccessful"}
            </div>
            <span className="quickteller-black f-14">
              {location} Purchase for {cardName} {cardNumber}{" "}
            </span>
            {
              <>
                <div className="col-12 text-left bg-white mb-3 mt-4">
                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Description
                      </span>
                      <span className="d-block qt-gray mt-3">
                        Smart Fuel Purchase for {cardName}
                      </span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Date
                      </span>
                      <span className="d-block qt-gray mt-3">
                        {transactionDate}
                      </span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Amount
                      </span>
                      <span className="d-block qt-gray mt-3">
                        ₦ {formatAmount(amount * 100)}
                      </span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Card Pan
                      </span>
                      <span className="d-block qt-gray mt-3">{cardNumber}</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Customer Name
                      </span>
                      <span className="d-block qt-gray mt-3">{cardName}</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Location
                      </span>
                      <span className="d-block qt-gray mt-3">{location}</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-7 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Vendor
                      </span>
                      <span className="d-block qt-gray mt-3">{vendor}</span>
                    </div>
                    <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Vendor ID
                      </span>
                      <span className="d-block qt-gray mt-3">
                        {vendorNumber}
                      </span>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      borderWidth: 0,
                      borderBottomColor: "#5F738C",
                      borderBottomWidth: 0.5,
                      borderStyle: "solid",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col-5 pl-0 pr-0 pt-3 pb-3">
                      <span className="d-block font-weight-bold qt-dark-blue">
                        Payment Reference
                      </span>
                      <span className="d-block qt-gray mt-3">{reference}</span>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      fontSize: 14,
                      color: "#4B4B4B",
                      border: "none",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col pl-0 pr-0 pt-3 pb-3 text-center">
                      <span className="d-block font-weight-bold qt-dark-blue text-white print-btn">
                        <ReactToPrint
                          trigger={() => (
                            <a href="#print" className="text-white">
                              Print receipt
                            </a>
                          )}
                          content={() => componentRef.current}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionReport;
