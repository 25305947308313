import React, { useState/* , useEffect */ } from "react";
import "../../../../assets/Styles/Dashboard.css";
import { FilterSVG, ExportSVG } from "../../../../assets/Svgs/Svgs";
import { CSVLink } from "react-csv";
import Pagination from "../../../../lib/Components/Pagination";
import formatAmount from "../../../../lib/Functions/formatAmount";



const CardList = (props) => {
  const {oCardReducer} = props;
  const {content, count} = oCardReducer.allBlankCards;
  const [pageNo, setPageNo] = useState(1);
  const pageSize = 10;
  const start = (pageNo - 1) * pageSize;
  const end = start + pageSize;
  const cardsList = content && content.slice(start, end).map((value) => {
    const {id, pan, name, accountNumber, limit, cycle} = value;
    return (
      <tr key={id}>
        <td className="table-description">{pan}</td>
        <td className="table-description font-weight-bold">{name}</td>
        <td className="table-pin">{accountNumber}</td>
        <td className="table-description">₦ {formatAmount(limit * 100)}</td>
        {/* amount in naira already */}
        <td className="table-description">{`${cycle[0].toUpperCase()}${cycle.substring(1).toLowerCase()}`}</td>
        <td className="table-status"><div className="status-btn success">Active</div></td>
      </tr>
    )
  });
  return (

    <div className="disbursed-cards-container row">
      <div className="menu-link" style={{marginBottom: 0, paddingBottom: 4}}>
        <span style={{color: "#000000", borderBottomColor: "#057ece", borderBottomWidth: 3, borderBottomStyle: "solid"}}>Disbursed Cards</span>
      </div>  
      {count > 0 && <CSVLink data={!!content && content} filename={'RFID Cards.csv'} className="" target="_blank">
        <div className="disbursed-cards-export-btn" style={{float: "right"}}>
          <span className="text-white">Export CSV <ExportSVG /></span>
        </div> 
      </CSVLink>}
      <table className="table table-hover">
          <thead>
            <tr>
              <td>Card Pan</td>
              <td>Name</td>
              <td>Account Number <FilterSVG /></td>
              <td>Limit</td>
              <td>Frequency <FilterSVG /></td>
              <td>Status <FilterSVG /></td>
              {/* <td></td> */}
            </tr>
          </thead>
          <tbody>
            {cardsList || <tr><td colSpan={6} className="text-center">No Cards to show</td></tr>}
          </tbody>
      </table>
      
      <Pagination count={count} pageSize={pageSize} fetchMethod={(pageNo) => {
        setPageNo(pageNo);
                // onFetchRfidTags(usedPageNo, pageNo)
              }} />
    </div>
  )
};

export default CardList;