import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, batch} from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import ReportStatusBar from '../StatusBar';
import { downloadAllTransactions, fetchAllTransactions } from "../../redux/action/OwnerTrailActions";
import { fetchAllCustomers } from '../../Accounts/Customers/customers.actions';
import { getAllVendors, fetchAllLocations } from '../../Accounts/Vendors/vendors.actions';
import TransactionList from "./TransactionList";
import fileDownload from "js-file-download";
import { DatePicker, Radio, Select } from "antd";
import moment from "moment";

function Transactions () {
  const dispatch = useDispatch();

  const startDate = moment(new Date(moment().subtract(365, 'days').format('LLLL')));
  const endDate = moment(new Date(moment().subtract(0, 'days').format('LLLL')));

  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [vendorNum, setVendorNum] = useState("");
  const [customerNum, setCustomerNum] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState(5);

  const props = useSelector(state => state);
  const { allVendors, allLocations } = props.oVendorReducer;
  const { allCustomers } = props.oCustomerReducer;

  useEffect(() => {
    if (props.oTrailReducer.oTransactionsBlob) {
      fileDownload(props.oTrailReducer.oTransactionsBlob, 'Transaction-Report.xlsx');
      dispatch({ type: 'DOWNLOAD_ALL_TRANSACTION_RESET' });
    }
  }, [props.oTrailReducer.oTransactionsBlob]);

  useEffect(()=> {
    batch(()=>{
      dispatch(fetchAllTransactions(
        dateRange[0].format('YYYY-MM-DD'), 
        dateRange[1].format('YYYY-MM-DD'), 
        1, 
        10, 
        status, 
        {vendorNum, customerNum, location}
      ));
    })
  }, [dateRange, status, vendorNum, customerNum, location]);
  
  useEffect(() => {
    batch(() => {
      dispatch(getAllVendors(1, 10000000));
      dispatch(fetchAllCustomers(1, 10000000));
      dispatch(fetchAllLocations())
    });
  }, [])

  const handleCalendarChange = ([startDate0 = startDate, endDate0 = endDate]) => {
    setDateRange([moment(startDate0), moment(endDate0)]);
  };

  return (
    <div className="dashboard-container">
      <ReportStatusBar {...props} isVisible={true} />
      
      <div className="row">
        <div className="col-md-5 text-right pr-0 mt-md-1 mt-2">
          {/* <span className="filter-tags-desc">Filters:</span> */}
          <Select 
            defaultValue={vendorNum} style={{ minWidth: 135 }} 
            className="mr-3"
            placeholder="Choose Vendor"
            // allowClear 
            // showSearch
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={value => setVendorNum(value)}>
            <Select.Option value="">All Vendors</Select.Option>
            {(allVendors.data || []).filter(v => v.name).map((v, i) => (
              <Select.Option value={v.number} key={i}>{v.name}</Select.Option>
            ))}
          </Select>
          <Select 
            defaultValue={customerNum} 
            className="mr-3"
            placeholder="Choose Customer"
            // allowClear 
            // showSearch
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={value => setCustomerNum(value)}>
            <Select.Option value="">All Customers</Select.Option>
            {(allCustomers.data || []).map((c, i) => (
              <Select.Option value={c.number} key={i}>{c.name}</Select.Option>
            ))}
          </Select>
          <Select 
            defaultValue={location}
            placeholder="Choose Location"
            // allowClear 
            // showSearch
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={value => setLocation(value)}>
            <Select.Option value="">All Locations</Select.Option>
            {(allLocations.data || []).map((l, i) => (
              <Select.Option value={l.number} key={i}>{l.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="col-md-3 text-right pr-0 mt-md-1 mt-2">
          <Radio.Group defaultValue={status} buttonStyle="solid" onChange={(e) => setStatus(e.target.value)}>
            <Radio.Button value={5}>ALL</Radio.Button>
            <Radio.Button value={1}>SUCCESSFUL</Radio.Button>
            <Radio.Button value={0}>FAILED</Radio.Button>
          </Radio.Group>
        </div>
        <div className="col-md-4 text-right pr-0 mt-md-1 mt-2">
          <DatePicker.RangePicker
            defaultValue={dateRange}
            onCalendarChange={handleCalendarChange}
            format={"DD-MMMM-YYYY"}
            allowClear={false}
          />
        </div>
      </div>

      <TransactionList 
        {...props}
        downloading={props.oTrailReducer.downloading}
        onDownloadTransactions={() => {
          dispatch(downloadAllTransactions(
            dateRange[0].format('YYYY-MM-DD'), 
            dateRange[1].format('YYYY-MM-DD'), 
            status, 
            {vendorNum, customerNum, location}
          ))
        }}
        onFetchTransactions={(pageNo, pageSize) => {
          dispatch(fetchAllTransactions(
            dateRange[0].format('YYYY-MM-DD'), 
            dateRange[1].format('YYYY-MM-DD'), 
            pageNo, 
            pageSize, 
            status, 
            {vendorNum, customerNum, location}
          ))
        }}
      />
    </div>
  )
}

export default Transactions;