import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { CloseSVG } from "../../../../assets/Svgs/Svgs";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import formatAmount from "../../../../lib/Functions/formatAmount";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../redux/action/customerUserActions";
import { getUserProperties } from "../../../../lib/Constants/UserTypes";
import {
  calculateProductVolume,
  fetchState,
} from "../../redux/action/VendorAction";

export default ({
  onHide,
  onAddVoucher,
  selectedScheme,
  selectedSchemeContracts,
  isVoucherAssignError,
  hideError,
}) => {
  const [state, setState] = useState({
    beneficiaryNumber: "",
    name: "",
    amount: "",
  });
  const [assignVoucherErrorMessage, setAssignVoucherErrorMessage] =
    useState("");
  const [customers, setCustomers] = useState([]);

  const [contractsWithPurchasableQty, setContractsWithPurchasableQty] =
    useState([]);
  const [computeError, setComputeError] = useState("");
  const [stateId, setStateId] = useState("");
  const { fetchingPriceVolume, statePriceVolumes, states } = useSelector(
    (state) => state.oVendorReducer
  );
  const users = useSelector((state) => state.customerUser.users);
  const dispatch = useDispatch();
  const { type, id, customer } = getUserProperties();
  useEffect(() => {
    dispatch(fetchState({ page: 1, pageSize: 40 }));
  }, []);

  function onHandleInput(field, e) {
    const isOk =
      field === "amount" ? computePurchasableQty(e.target.value) : true;

    if (isOk) {
      setState({ ...state, [field]: e.target.value });
    }
  }

  const computePurchasableQty = (value) => {
    setComputeError("");

    if (value && Number.isNaN(parseFloat(value))) {
      setComputeError("Only numbers!");
      return false;
    }

    setContractsWithPurchasableQty(
      transformProductContractRates(contractsWithPurchasableQty, value)
    );

    return true;
  };

  const transformProductContractRates = (contracts, amount) => {
    return contracts.map((pcr) => [
      ...pcr.map((p) => {
        const appliedProductPrice = p.rate || p.product.rate;
        return {
          ...p,
          appliedProductPrice,
          purchasableQty:
            amount && appliedProductPrice > 0
              ? parseFloat(amount) / appliedProductPrice
              : 0,
        };
      }),
    ]);
  };

  useEffect(() => {
    if (selectedSchemeContracts.length !== 0) {
      const contractsWithPQs = selectedSchemeContracts.map((ssc) => {
        //PCRates = product-contract-rates
        if (ssc.contract.productContractRates?.length === 0) {
          if (ssc.products !== 0) {
            ssc.products.map((p) =>
              ssc.contract.productContractRates.push({ product: p })
            );
          }
        }

        return [...ssc.contract.productContractRates];
      });

      setContractsWithPurchasableQty(
        transformProductContractRates(contractsWithPQs, state.amount)
      );
    }
  }, [selectedSchemeContracts]);

  useEffect(() => {
    dispatch(getAllUsers(type));
  }, []);

  useEffect(() => {
    let customerUsers = users.length
      ? users.map((e) => {
          // eslint-disable-line
          if (e.status === "ACTIVE") {
            return (
              <option value={e.userNumber} key={e.id}>
                {e.firstName} {e.lastName}
              </option>
            );
          }
        })
      : [];
    setCustomers(customerUsers);
    setState({ ...state, contractNumber: selectedScheme.contractId });
  }, [users]);

  const { beneficiaryNumber, amount } = state;

  function isValidateError() {
    let errorMsg = "";
    if (amount <= 0 || amount > selectedScheme.unassignedLimit) {
      errorMsg =
        "Amount / Limit must be greater than 0 naira but less than " +
        selectedScheme.unassignedLimit;
    } else if (!beneficiaryNumber) {
      errorMsg = "Please select a driver";
    }
    setAssignVoucherErrorMessage(errorMsg);
    return errorMsg;
  }

  const handleVolumePreview = () => {
    setAssignVoucherErrorMessage("");
    if (!state?.amount) {
      setAssignVoucherErrorMessage("Enter a value for limit");
      return;
    }
    if (!stateId) {
      setAssignVoucherErrorMessage("Select a State");
      return;
    }
    let object = {
      amount: state?.amount,
      stateId,
      cid: customer,
    };
    dispatch(calculateProductVolume(object));
  };

  const purchasableQtyTable = statePriceVolumes?.data?.map((pcr, i) => (
    <tr key={`contract-${i}`}>
      <td className="p-2 font-weight-bold">{pcr.productName?.toUpperCase()}</td>
      <td className="p-2 font-weight-bold">
        {parseFloat(pcr.productPrice).toFixed(2)}
        {` / ${pcr.unit}`}
      </td>
      <td className="p-2 text-right">
        {parseFloat(pcr.quantity).toFixed(2)}
        {` / ${pcr.unit}`}s
      </td>
    </tr>
  ));

  return (
    <Modal
      isOpen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      className="user-modal"
    >
      <ModalBody>
        <div className="card-management-row ml-3 mr-3">
          <div
            className="mb-4"
            style={{
              textAlign: "left",
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 10,
              borderBottom: "0.5px solid #5F738C",
            }}
          >
            <h6 className="mt-5 qt-black font-weight-bold text-left d-inline">
              Generate / Assign Voucher
            </h6>
            <span
              style={{ float: "right", cursor: "pointer" }}
              onClick={() => {
                onHide();
                hideError("");
              }}
            >
              <CloseSVG />
            </span>
          </div>
          <div className="row">
            <div className="form-group col-12">
              <label>Select Driver</label>
              <select
                className="form-control my-md-3x"
                value={state?.beneficiaryNumber}
                onChange={(event) => {
                  const selectedUser = users?.filter((user) => {
                    return user?.userNumber === event.target.value;
                  })[0];

                  setState({
                    ...state,
                    name: selectedUser?.name,
                    beneficiaryNumber: event.target.value,
                  });
                }}
                name="driver"
              >
                <option>Select Driver</option>
                {customers}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-4">
              <label>
                Amount / Limit
                {computeError && (
                  <span
                    className="text-warning ml-4"
                    style={{
                      fontWeight: "normal",
                      fontSize: "12px",
                    }}
                  >
                    {computeError}
                  </span>
                )}
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text font-weight-bold">
                    &#8358;
                  </span>
                </div>
                <input
                  type="text"
                  value={amount}
                  className="form-control mt-0"
                  onChange={onHandleInput.bind(this, "amount")}
                />
              </div>
            </div>
            <div className="form-group col-4">
              <label>Select State</label>
              <select
                className="form-control my-md-3x"
                value={stateId}
                onChange={(e) => setStateId(e.target.value)}
                name="scheme"
              >
                <option>Select State</option>
                {states?.data?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group col-4">
              <label>Available balance on scheme</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text font-weight-bold">
                    &#8358;
                  </span>
                </div>
                <input
                  type="text"
                  style={{ color: "#000", fontWeight: "bolder", fontSize: 20 }}
                  disabled
                  value={formatAmount(selectedScheme.unassignedLimit * 100)}
                  onChange={onHandleInput.bind(this, "availableBalance")}
                  className="form-control mt-0"
                />
              </div>
            </div>
            <div className="col-8">
              <button
                disabled={fetchingPriceVolume}
                onClick={handleVolumePreview}
              >
                {fetchingPriceVolume ? "loading..." : "Preview prices"}
              </button>
              <div className="d-block pt-2" style={{ color: "#00425F" }}>
                <span
                  className="mr-2 font-weight-bold"
                  style={{ fontSize: "13px" }}
                >
                  Purchases:
                </span>
                <table className="table mb-0">
                  <thead>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Purchasable Quantity</th>
                  </thead>
                  <tbody>
                    {purchasableQtyTable ?? (
                      <td colSpan={3}>
                        <h2>No data to display</h2>
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {(!!assignVoucherErrorMessage || !!isVoucherAssignError) && (
            <div className="row mt-2 mb-4">
              <ToastAlert
                parentClass="col-12"
                toastText={assignVoucherErrorMessage || isVoucherAssignError}
                showToast={
                  !!assignVoucherErrorMessage || !!isVoucherAssignError
                }
                onCloseHandler={() => {
                  !!assignVoucherErrorMessage &&
                    setAssignVoucherErrorMessage("");
                  !!isVoucherAssignError && hideError("");
                }}
              />
            </div>
          )}
          <div className="row mt-3 mb-4">
            <div className="assign-btn col-12">
              <button
                onClick={() => {
                  if (!isValidateError()) {
                    onAddVoucher(state);
                  }
                }}
                className="ml-0"
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
