import React, { useEffect, useState } from "react";

export default function Checkbox ({val, selectedPermissions, name, onSelectPermission}) {
    const [permit, setPermit] = useState(selectedPermissions.includes(val));
    useEffect(() => {
        if(selectedPermissions.includes(val)){
            setPermit(true);
        }
        else {
            setPermit(false)
        }
    })
    return <input type="checkbox" checked={permit} name={name} onChange={onSelectPermission} />
}