import axios from 'axios';
import { customConfig } from '../../action_constants';
import { authHeader } from '../../../helpers/authHeader';
const API_URL = customConfig.API_URL;

export function fetchAllCustomers(page, pageSize) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_All_CUSTOMERS_START' });
    axios
      .get(API_URL + `api/customers/all?page=${page}&size=${pageSize}`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_All_CUSTOMERS_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_All_CUSTOMERS_REJECTED', payload: err });
      });
  };
}
export function fetchOneCustomer(id) {
  return function(dispatch) {
    axios
      .get(API_URL + 'api/customers?id=' + id, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'FETCH_ONE_CUSTOMER_FULLFILLED', payload: res.data });
      })
      .catch(err => {
        dispatch({ type: 'FETCH_ONE_CUSTOMER_REJECTED', payload: err });
      });
  };
}

export function toggleCustomerStatus(cid, mode) { 
  return function(dispatch) {
    dispatch({ type: 'TOGG_CUSTOMER_STATUS_START' });
    axios
      .put(API_URL + 'api/customers/toggle?id=' + cid, {}, { headers: authHeader() })
      .then(res => {
        dispatch({ type: 'TOGG_CUSTOMER_STATUS_FULLFILLED', id: cid, mode, response: { data: res.data.data, message: res.data.status.description, editingStatus: 1 } });
      })
      .catch(err => {
        dispatch({ type: 'TOGG_CUSTOMER_STATUS_REJECTED', response: { message: err.response.data.status.description, editingStatus: 2 } });
      });
  };
}

export function toggleVendorCustomerStatus(cid, mode) {
  return function (dispatch) {
    dispatch({type: "TOG_CUSTOMER_STATUS_START"});
    axios
      .put(
        API_URL + "api/customers/toggle?id=" + cid,
        {},
        {headers: authHeader()}
      )
      .then((res) => {
        dispatch({
          type: "TOG_CUSTOMER_STATUS_FULLFILLED",
          id: cid,
          mode,
          response: {
            data: res.data.data,
            message: res.data.status.description,
            editingStatus: 1,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "TOG_CUSTOMER_STATUS_REJECTED",
          response: {
            message: err.response.data.status.description,
            editingStatus: 2,
          },
        });
      });
  };
}

export function setSelectedCustomer(customer) {
  return {
    type: 'SET_SELECTED_CUSTOMER',
    customer,
  };
}
export function submitCustomerForm(data) {
  return function(dispatch) {
     dispatch({ type: 'ADD_CUSTOMERS_START' });
    axios
      .post(API_URL + 'api/customers', data, { headers: authHeader() })
      .then(res => {
        if (res.data.status.code === 0) {
          dispatch({ type: 'OCREATE_CUSTOMER_REQUEST', response: { status: 1, message: res.data.status.description } });
        } else {
          dispatch({ type: 'OCREATE_CUSTOMER_REJECTED', response: { status: 2, message: res.data.status.description } });
        }
       dispatch({type:'ADD_CUSTOMER_FINISED'});
      })
      .catch(err => {
        dispatch({
          type: 'OCREATE_CUSTOMER_REJECTED',
          // response: { status: 2, message: err.response.data.status.description || err.response.data.status.message || err.response.data.status.errors },
          response: {
            status: 2,
            message: err.response?.data?.status?.description || err.response?.data?.status?.message || err.response?.data?.status?.errors || 'Error occured',
          },
        });
      });
      dispatch({type:'ADD_CUSTOMER_FINISED'});
  };
}


export function resetCustomerCreation() {
  return (dispatch) => {
    dispatch({type: 'RESET_ADD_OWNER_CUSTOMER'})
  }
}

export function resetCustomerUpdate() {
  return dispatch => {
    dispatch({type: 'RESET_CUSTOMER_UPDATE'});
  }
}

export function submitCustomerEdit(data, id) {
  return function(dispatch) {
    dispatch({ type: 'ADD_CUSTOMERS_START' });
    axios
      .put(API_URL + 'api/customers?id=' + id, data, { headers: authHeader() })
      .then(res => {
        // dispatch({ type: 'ADD_CUSTOMERS_FULLFILLED', payload: res });
        if (res.data.status.code === 0) {
          dispatch({ type: 'UPDATE_CUSTOMER_REQUEST', response: { data: res.data.data, status: 1, message: res.data.status.description}, customer: data, id });
        } else {
          dispatch({ type: 'UPDATE_CUSTOMERS_REJECTED', response: { data: [], status: 2, message: res.data.status.description } });
        }
      })
      .catch(err => {
        // dispatch({ type: 'UPDATE_CUSTOMER_REQUEST', response: { data: [], status: 2, message: err.response.data.status.description } });
        dispatch({ type: 'UPDATE_CUSTOMERS_REJECTED', response: { status: 2, message: 'An rror occured!' } });
      });
      dispatch({type:'ADD_CUSTOMER_FINISED'});
  };
}

export function resetModalParams(fetchingStatus) {
  return {
    type: 'RESET_CUSTOMER_CREATE_REQ_PARAMS',
    response: { fetchingStatus, message: '' },
  };
}

export function toggleUserCreateModal(id) {
  return {
    type: 'TOGGLE_MODAL',
    payload: id,
  };
}

export function submitUserForm(body) {
  return {
    type: 'ADD_USER',
    payload: body,
  };
}
