import React, {useEffect, useState} from "react";
import {
  BackSVG,
  ActiveMarkSVG,
  DisabledMark,
  CardSvg,
  CardTypeSvg,
  RfidAssignSvg,
} from "../../../../assets/Svgs/Svgs";
import { loadingGIF } from "../../../admin/shared/assets";
import formatAmount from "../../../../lib/Functions/formatAmount";
import {batch, useDispatch} from "react-redux";
import {
  fetchOneCard,
  assignTag,
  getCardRfid,
  fetchOneCardBalance,
  fetchOneCardStatistics,
  reAssignCard,
  topupCard,
  fetchAllCardsByScheme,
} from "../../../../actions/CustomerActions/CardMgtActions/CardAction";
import { getUserProperties, USER } from "../../../../lib/Constants/UserTypes";
import ToastAlert from "../../../../lib/Components/ToastAlert";
import moment from "moment";
import AddCard from "./AddCard";
import ActionModal from "../../../../lib/Components/ActionModal";


export default ({
  backFunction, selectedCard, onToggleSchemeCard, isTogglingSchemeCard,
  toggleSchemeCardResponse, customerCards, selectedScheme, selectedSchemeContracts, myCards
}) => {

  const {
    name,
    userId,
    pan,
    limit,
    cycle,
    //customerId,
    accountName,
    accountNumber,
    expiryDate,
    isBlocked,
  } = selectedCard;

  const dispatch = useDispatch();
  const [ rfid, setRfid ] = useState("Loading...");

  useEffect(() => {
  batch(() => {
    dispatch(fetchOneCard(pan));
    dispatch(fetchOneCardBalance(pan));
    dispatch(getCardRfid({pan}, (rfidNo) => {
      if (typeof rfidNo === 'string') {
        setRfid(rfidNo);
      }
      else if (rfidNo.length === 0) {
        setRfid("Nil");
        setcardAssignStage("not-started");
      } else {
        setRfid(rfidNo.map(item => item.rfid + '\n'));
        setcardAssignStage("completed");
      }
    }));
    dispatch(fetchOneCardStatistics(pan));
    // dispatch(fetchOneCardTransactions(7));
  });
  }, [pan]);// eslint-disable-line react-hooks/exhaustive-deps


  const [cardAssignStage, setcardAssignStage] = useState("completed");
  const [rfidTagNo, setrfidTagNo] = useState("");
  const [assignTagError, setassignTagError] = useState("");
  const [isAssigningTag, setIsAssigningTag] = useState(false);
  const [isToggleSchemeCardMode, setIsToggleSchemeCardMode] = useState(false);
  const [isTopupCard, setIsTopupCard] = useState(false);
  const [isAssignCard, setIsAssignCard] = useState(false);
  const [isCardAssignError, setIsCardAssignError] = useState("");
  const [showActionModal, setshowActionModal] = useState(false);
  const [isActionCompleted, setIsActionCompleted] = useState(false);
 

  const {
    oneCardStats: {
      data: { totalCount = 0, totalVolume = 0, totalAmount = 0 } = {} 
    } = {},
    fetchingStats,
    oneCardBalance: {
      goodsNrTransLimit: cardBalanceNr = 0
    } = {},
    fetchingBal,
    errorBal
  } = customerCards || {};

  const spinnerLayout = (<span className="spinner-border spinner-border-sm text-light" role="status">
    <span className="sr-only">Loading...</span>
  </span>);

  useEffect(() => {
    setIsToggleSchemeCardMode(isTogglingSchemeCard);
  }, [isTogglingSchemeCard]);

   const handleTopupModal = () =>{
    setIsTopupCard(!isTopupCard);
   }

   const handleCardAssignModal =() =>{
    setIsAssignCard(!isAssignCard);
   }

   const handleCardTopup = (data) =>{
     setIsTopupCard(!isTopupCard);
     setIsActionCompleted(false);
     setshowActionModal(true);
     dispatch(topupCard(data, (status)=>setshowActionModal(status),
     (status, error) =>{
      if (!status) {
        setIsCardAssignError(error);
        setIsTopupCard(true);
        return;
      }
      else {
        setIsActionCompleted(true);
        setshowActionModal(true);
        setIsTopupCard(false);
      }
     } 
     ))
   }

   const handleReassign = (data) =>{
    setIsAssignCard(!isAssignCard);
    setIsActionCompleted(false);
    setshowActionModal(true);
     dispatch(reAssignCard(data, (status)=>setshowActionModal(status),
      (status, error)=>{
        if (!status) {
          setIsCardAssignError(error);
          setIsAssignCard(true);
          return;
        }
        else {
          setIsActionCompleted(true);
          setshowActionModal(true);
          setIsAssignCard(false);
          dispatch(fetchAllCardsByScheme(selectedScheme.customerId, selectedScheme.accountNumber));
        }
     }))
   }
  return (
    <>
      <div className="underlined-tab">
        <span className="tab-title">Card Details</span>
        <div className="action-buttons">
          <button className="export-btn" onClick={()=> {  
           backFunction() } }>
            <BackSVG />
            Back
          </button>
        </div>
      </div>
      <div className="row account-details">
        <div className="col-3 profile-picture-and-actions mt-4">
          <div>
            <CardSvg />
          </div>
          <div className="mt-3">
            <CardTypeSvg />
          </div>

          {(getUserProperties().userLevel !== USER) && (<>
            <button
              className={`button mt-4 ${!isBlocked ? "suspend-btn" : "activate-btn"}`} 
              onClick={() => {
                setIsToggleSchemeCardMode(true);
                onToggleSchemeCard(pan, (expiryDate || moment().add(365, 'days').format('MMYY')), accountNumber, !isBlocked);
              }}
              style={{marginBottom: '10px'}}
              disabled={isToggleSchemeCardMode}
            >
              {isToggleSchemeCardMode ? spinnerLayout : (`${!isBlocked ? 'Suspend' : 'Activate'} Card`)}
            </button>

            <button onClick={handleCardAssignModal} className="button suspend-btn" style={{ backgroundColor: 'rgb(69, 132, 219)' }}>
                Reassign Card
            </button>

            {!isToggleSchemeCardMode && (
              <ToastAlert
                showToast={!!toggleSchemeCardResponse.message}
                toastText={toggleSchemeCardResponse.message || ''}
                parentClass={`mt-2 d-block clear-both bg-${toggleSchemeCardResponse.isError ? 'warning' : 'success'}`}
                childClass='text-white'
                onCloseHandler={() => dispatch({ type: 'TOGGLE_SCHEME_CARD_STATUS_RESET' })}
              />
            )}
          </>)}
        </div>

        <>
          <div className="col-9">
            <div className="row user-info">
              <div className="row user-info-title">
                <div className="col-3">Name</div>
                <div className="col-3">Users ID</div>
                <div className="col-3">Card Pan</div>
                <div className="col-3">RFID</div>
              </div>
              <div className="row user-info-details mt-3">
                <div className="col-3">{name}</div>
                <div className="col-3">{userId.replace("USER", "")}</div>
                <div className="col-3">{pan}</div>
                <div className="col-3">{rfid}</div>
              </div>
            </div>
            <div className="row user-extras">
              <div className="row user-extras-title">
                <div className="col-3">Date Created</div>
                <div className="col-3">Status</div>
                <div className="col-3">Limit</div>
                <div className="col-3">Frequency</div>
              </div>
              <div className="row user-extras-detail mt-3">
                <div className="col-3">{"Unavailable"}</div>
                <div className="col-3">
                  {!isBlocked ? `Active ` : "Suspended"}{" "}
                  {!isBlocked ? <ActiveMarkSVG /> : <DisabledMark />}
                </div>
                <div className="col-3">₦ {formatAmount(limit * 100)}</div>
                <div className="col-3">
                  {cycle[0]}
                  {cycle.split("").slice(1).join("").toLowerCase()}
                </div>
              </div>
            </div>
            <div className="row user-address">
              <div className="row user-address-title">
                <div className="col-3">Account</div>
                <div className="col-3">Scheme</div>
              </div>
              <div className="row user-address-details mt-3">
                <div className="col-3">{accountNumber}</div>
                <div className="col-3">{accountName}</div>
              </div>
            </div>
            <div className="row user-address mt-3">
              <div className="row col-12">
                <span className="overview-title" style={{marginBottom: 20}}>
                  Account Overview
                </span>
              </div>
              <div
                className="row col-12 ml-1"
                style={{backgroundColor: "#F1F4FA"}}
              >
                <div className="col-3 text-center mt-4">
                  <div className="credit-details">
                    <div
                      className="credit-title mb-2"
                      style={{position: "relative"}}
                    >
                      AVAILABLE BALANCE
                    </div>
                    <div
                      className="amount"
                      style={{
                        position: "relative",
                        width: 130,
                        margin: "10px auto",
                      }}
                    >
                      {fetchingBal ? <img src={loadingGIF} alt="" /> : <>₦ {formatAmount((cardBalanceNr || 0) * 100)}</>}
                    </div>
                    {errorBal && <small className="error">{errorBal}</small>}
                  </div>
                </div>
                <div className="col-9 pt-4">
                  <div className="customer-stats row">
                    <div className="col-10 stats">
                      <div className="row">
                        <div className="col-4">LITRES USED</div>
                        <div className="col-4">TRANSACTION</div>
                        <div className="col-4">VALUE</div>
                      </div>
                      <div className="row numbers">
                        <div className="col-4">
                          {fetchingStats ? <img src={loadingGIF} alt="" /> : formatAmount(totalVolume * 100)}
                        </div>
                        <div className="col-4">
                          {fetchingStats ? <img src={loadingGIF} alt="" /> : totalCount}
                        </div>
                        <div className="col-4">
                          {fetchingStats ? <img src={loadingGIF} alt="" /> : formatAmount(totalAmount)}
                        </div>
                      </div>
                    </div>
                    <div className="col-2 pt-4">
                      <button className="ant-btn add-btn" onClick={handleTopupModal}>
                        Top up
                      </button>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
            {cardAssignStage === "not-started" && (
              <div className="row mt-4">
                <button
                  className="add-btn"
                  onClick={() => {
                    setcardAssignStage("show-input");
                  }}
                >
                  Assign tag to card
                </button>
              </div>
            )}
            {cardAssignStage === "show-input" && (
              <>
                <div className="row mt-4 user-address-details">
                  <span>Assign tag to card</span>
                </div>
                <div className="row d-block mt-4">
                  <label>RFID Tag</label>
                  <input
                    type="text"
                    value={rfidTagNo}
                    placeholder="Enter Tag Number"
                    className="form-control"
                    style={{maxWidth: 400}}
                    onChange={(e) => {
                      setrfidTagNo(e.target.value);
                    }}
                    maxLength={19}
                  />
                </div>
                {!!assignTagError && (
                  <div className="row">
                    <ToastAlert
                      showToast={!!assignTagError}
                      toastText={assignTagError}
                      parentClass="mt-2 d-block clear-both"
                      onCloseHandler={() => setassignTagError("")}
                    />
                  </div>
                )}
                <div className="row mt-4">
                  <button
                    className="add-btn"
                    onClick={() => {
                      setassignTagError("");
                      if (rfidTagNo.length < 16)
                        return setassignTagError(
                          "RFID tag must be at least 16 digits long"
                        );
                      else if (isNaN(rfidTagNo))
                        return setassignTagError(
                          "RFID tag must be made up of numeric digits"
                        );
                      else {
                        setIsAssigningTag(true);
                        dispatch(
                          assignTag({pan, rfid: rfidTagNo}, (status, error) => {
                            if (status) {
                              setcardAssignStage("card-assigned");
                            } else {
                              setassignTagError(error);
                            }
                            setIsAssigningTag(false);
                          })
                        );
                      }
                    }}
                  >
                    {isAssigningTag ? "Assigning tag..." : "Confirm"}
                  </button>
                </div>
              </>
            )}
            {cardAssignStage === "card-assigned" && (
              <>
                <div className="row mt-4">
                  <RfidAssignSvg />
                </div>
                <div className="row mt-4">
                  <button
                    className="add-btn"
                    onClick={() => {
                      setcardAssignStage("completed");
                    }}
                  >
                    Refresh Page
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      </div>
      { (isAssignCard || isTopupCard)  && (
        <AddCard
          onHide={() => {
            setIsTopupCard(false);
            setIsAssignCard(false)
          }}
          selectedScheme={selectedScheme}
          selectedSchemeContracts={selectedSchemeContracts}
          isCardAssignError={isCardAssignError}
          hideError={(value) => {
             setIsCardAssignError(value);
          }}
          reAssignCard={(card)=>{ handleReassign(card)}}
          onAddCard={null}
          onTopUpCard={(card)=>{ handleCardTopup(card)}}
          isTopUp={isTopupCard}
          isAssignCard={isAssignCard}
          selectedCard={selectedCard}
          cards={myCards}
        />
      )}
       {showActionModal &&
        <ActionModal
          isCompleted={isActionCompleted}
          actionTitle={"Card operation in progress..."}
          isShow={showActionModal}
          actionText=""
          titleStyle={{color: "#000000"}}
          updateCompletedStatus={(status) => setIsActionCompleted(status)}
          isCompletedTitle="Thank you! You are all set!"
          isCompletedText={ "Task completed successfully" }
          onShowActionModal={(status) => setshowActionModal(status)}
        >
          {isActionCompleted ? (
            <>
              <div className="row modal-action-buttons">
                <div className="col-12 text-center">
                  <button
                    className="back"
                    onClick={() => setshowActionModal(false)}
                  >
                    Back
                  </button>
                </div>
              </div>
              {/* <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div> */}
            </>
          ) : null}
        </ActionModal>}
    </>
  );
};
