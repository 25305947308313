import React, { useState, useEffect } from "react";
import {
  FilterSVG,
  ExportSVG,
  CreateSVG
} from "../../../../../src/assets/Svgs/Svgs";
// import {
  // shortMonthNames,
  // shortDayNames,
//   longMonthNames
// } from "../../../../lib/Functions/DateNames";
import "./Vendors.css";
import { useDispatch } from "react-redux";
import AccountDetails from "./AccountDetails";
import CreateVendor from "./CreateVendor";
import { getAllVendors } from "./vendors.actions";
import { CSVLink } from "react-csv";
import Pagination from "../../../../lib/Components/Pagination";
import {
  fetchOneVendor
} from "../../redux/action/OwnerVendorActions";
import { history } from "../../../../helpers/history";

const displayVendors = (vendors, callback) => {
  return vendors.map((value, index) => {
    const { name, number, rcNumber,/* createdAt, */ status, contact } = value;
    // const d = new Date(createdAt);

    return (
      <tr key={index}>
        <td className="font-weight-bold">{name}</td>
        <td>{number}</td>
        <td className="vendor-number">{rcNumber}</td>
        <td>{contact.email}</td>
        {/* <td>{shortDayNames[d.getDay()-1]} {d.getDate()}, {shortMonthNames[d.getMonth()]} {d.getFullYear()}</td> */}
        {status.toUpperCase() === "ACTIVE" ? (
          <td className="table-status">
            <div className="status-btn success">{"Active"}</div>
          </td>
        ) : (
          <td className="table-status">
            <div className="status-btn error">{"Suspended"}</div>
          </td>
        )}
        <td className="table-action" onClick={() => callback(value)}>
          View
        </td>
      </tr>
    );
  });
};

const VendorsList = props => {
  const { oVendorReducer, setVendor, unsetVendor, onHideStatusBar } = props;
  const { allVendors } = oVendorReducer;
  const dispatch = useDispatch();
  const [selectedVendor, setSelectedVendor] = useState({});
  const [showVendorDetails, setShowVendorDetails] = useState(false);
  const [showCreateVendor, setShowCreateVendor] = useState(false);
  // const { createdAt } = selectedVendor;
  const [loader, setLoader] = useState(true);
  // const d = new Date(createdAt);
  /* const vendorCreatedDate = `${d.getDate()}, ${
    longMonthNames[d.getMonth()]
  } ${d.getFullYear()}`; */
  const pageSize = 10;
  useEffect(() => {
    if (!!setVendor) {
      dispatch(fetchOneVendor(setVendor));
    }
  }, []);

  const { oneVendor } = oVendorReducer;
  useEffect(() => {
    if(oneVendor && oneVendor.data){
      setLoader(false);
    }
   
    if (oneVendor && oneVendor.data && !!setVendor) {
      setShowVendorDetails(true);
      setSelectedVendor(oneVendor.data);
      onHideStatusBar(true);
      setLoader(false);
    }
  }, [oVendorReducer.oneVendor.data]);

  return (
    <>
    {loader && setVendor && <div className="text-center mt-5 mb-5 pt-5 pb-5" style={{fontSize: 25}}>Loading...</div>}
      {!showVendorDetails && !showCreateVendor && !setVendor && (
        <div className="charts-container row">
          <div className="table-menu-link">
            <span>Vendors</span>
            <div className="action-buttons">
              {!!allVendors && (
                <div className="export-btn d-inline-block">
                  <CSVLink
                    data={(allVendors.data) || []}
                    filename={"List of Vendors.csv"}
                    className=" text-white"
                    target="_blank"
                  >
                    Export CSV{" "}
                    <i>
                      <ExportSVG />
                    </i>
                  </CSVLink>
                </div>
              )}
              <button
                className="add-btn"
                onClick={() => {
                  onHideStatusBar(true);
                  setShowCreateVendor(true);
                }}
              >
                <i>
                  <CreateSVG />
                </i>
                Add new vendor
              </button>
            </div>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <td className="table-title">Name</td>
                <td className="table-title">Vendor ID</td>
                <td className="table-title">RC Number</td>
                <td className="table-title">Email</td>
                <td className="table-title">
                  Status
                  <i className="filter-bars">
                    <FilterSVG />
                  </i>
                </td>
                <td className="table-title"></td>
              </tr>
            </thead>
            <tbody>
              {allVendors.count > 0 &&
                displayVendors(allVendors.data, selectedVendor => {
                  setSelectedVendor(selectedVendor);
                  onHideStatusBar(true);
                  history.push(
                    "/owner-admin/vendors/vendor-details/" + selectedVendor.id
                  );
                  setShowVendorDetails(true);
                })}
              {allVendors.count < 0 ||
                (!allVendors.count && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      {allVendors.description ||
                        "No Vendors Available at the moment"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Pagination
            count={allVendors.count}
            pageSize={pageSize}
            fetchMethod={(page, pageSize) => {
              dispatch(getAllVendors(page, pageSize));
            }}
          />
        </div>
      )}

      {showVendorDetails && selectedVendor && (
        <>
        <AccountDetails
          {...props}
          selectedVendor={selectedVendor}
          backFunction={() => {
            setShowVendorDetails(false);
            onHideStatusBar(false);
            unsetVendor(null);
          }}
          onQuitEditing={() => {
            setShowVendorDetails(true);
            onHideStatusBar(true);
          }}
          onEditingDone={() => {
            window.location.reload();
          }}
        /></>
      )}
      {showCreateVendor && (
        <CreateVendor
          {...props}
          backFunction={() => {
            setShowCreateVendor(false);
            onHideStatusBar(false);
          }}
          onCreatingDone={() => {
            window.location.reload();
          }}
        />
      )}
    </>
  );
};

export default VendorsList;
