import React from 'react';
import {WarningIcon} from '../../../assets/Svgs/Svgs';

const FormWarning = ({inline, message}) => {
    return (
    <div className={`form-warning ${inline && "inline"}`}><i><WarningIcon /></i>{message}</div>
    );
}

export default FormWarning;
