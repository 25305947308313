import React, {useState, useEffect} from "react";
import "../../../../assets/Styles/Dashboard.css";
import {FilterSVG, ExportSVG, CreateSVG, AccountSvg} from "../../../../assets/Svgs/Svgs";
import {CSVLink} from "react-csv";
import Pagination from "../../../../lib/Components/Pagination";
import formatAmount from "../../../../lib/Functions/formatAmount";
import AddCard from "./AddCard";
import AddVoucher from "./AddVoucher";
import ActionModal from "../../../../lib/Components/ActionModal";
import CardDetails from "./CardDetails";
import {
  getUserProperties,
  // ADMIN,
  USER,
} from "../../../../lib/Constants/UserTypes";
// import Cards from "../../../ownerModule/Tokens/Card/Cards";
import {useDispatch} from "react-redux";
import {
  assignCard, assignVoucher, fundCustomerWallet
} from "../../../../actions/CustomerActions/CardMgtActions/CardAction";
import FundWallet from "./FundWallet";

const CardList = (props) => {
  const {
    myCards, onHideStatusBar, isStatusBarVisible, selectedScheme,
    toggleSchemeCardResponse, selectedSchemeContracts, userNumber,
    customerNumber,
  } = props;
  const dispatch = useDispatch();

  const [pageNo, setPageNo] = useState(1);
  const [showAssignCard, setShowAssignCard] = useState(false);
  const [showActionModal, setshowActionModal] = useState(false);
  const [showCardDetails, setshowCardDetails] = useState(false);
  const [selectedCard, setselectedCard] = useState({});
  const [isCardAssignError, setIsCardAssignError] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const [isVoucherOps, setIsVoucherOperation] = useState(false);
  const [showAssignVoucher, setShowAssignVoucher] = useState(false);
  const [isVoucherAssignError, setIsVoucherAssignError] = useState("");

  const [isWalletOps, setIsWalletOps] = useState(false);
  const [showFundWallet, setShowFundWallet] = useState(false);
  const [isFundWalletError, setIsFundWalletError] = useState("");

  useEffect(() => {
    if (toggleSchemeCardResponse && (Object.entries(toggleSchemeCardResponse).length !== 0) && !toggleSchemeCardResponse.isError) {
      setselectedCard({...selectedCard, isBlocked: !selectedCard.isBlocked})
    }
  }, [toggleSchemeCardResponse]);//eslint-disable-line

  const pageSize = 10;
  const start = (pageNo - 1) * pageSize;
  const end = start + pageSize;

  const cardsList = myCards && myCards.slice(start, end).map((value) => {
    const {id, pan, name, accountNumber, limit, cycle, isBlocked} = value;

    return (
      <tr key={id}>
        <td className="table-description">{pan}</td>
        <td className="table-description font-weight-bold">{name}</td>
        <td className="table-pin">{accountNumber}</td>
        <td className="table-description">{`${cycle[0].toUpperCase()}${cycle
          .substring(1)
          .toLowerCase()}`}</td>
        <td className="table-description">₦ {formatAmount(limit * 100)}</td>
        {/* amount in naira already */}
        <td className="table-status">
          <div className={`status-btn ${isBlocked ? 'error' : 'success'}`}>
            {`${isBlocked ? 'Inactive' : 'Active'}`}
          </div>
        </td>
        <td
          className="table-action"
          onClick={() => {
            setshowCardDetails(true);
            onHideStatusBar(true);
            setselectedCard(value);
            dispatch({ type: 'TOGGLE_SCHEME_CARD_STATUS_RESET'});//reset the response
          }}
        >
          View
        </td>
      </tr>
    );
  });

  return (
    <>
      {showCardDetails && (
        <CardDetails
          {...props}
          backFunction={() => {
            setshowCardDetails(false);
            onHideStatusBar(false);
          }}
          selectedCard={selectedCard}
        />
      )}

      {showAssignCard && (
        <AddCard
          onHide={() => {
            setShowAssignCard(false);
          }}
          selectedScheme={selectedScheme}
          selectedSchemeContracts={selectedSchemeContracts}
          isCardAssignError={isCardAssignError}
          hideError={(value) => {
            setIsCardAssignError(value);
          }}
          onAddCard={(card) => {
            setShowAssignCard(false);
            setIsActionCompleted(false);
            setshowActionModal(true);
            dispatch(
              assignCard(
                card,
                (status) => {
                  setshowActionModal(status);
                },
                (status, error) => {
                  if (!status) {
                    setIsCardAssignError(error);
                    setShowAssignCard(true);
                    return;
                  }
                  else {
                    setIsActionCompleted(true);
                    setshowActionModal(true);
                    setShowAssignCard(false);
                  }
                }
              )
            );
          }}
        />
      )}

      {showAssignVoucher && (
        <AddVoucher
          onHide={() => {
            setShowAssignVoucher(false);
          }}
          selectedScheme={selectedScheme}
          selectedSchemeContracts={selectedSchemeContracts}
          isVoucherAssignError={isVoucherAssignError}
          hideError={(value) => {
            setIsVoucherAssignError(value);
          }}
          onAddVoucher={(voucher) => {
            setShowAssignVoucher(false);
            setIsActionCompleted(false);
            setshowActionModal(true);
            dispatch(
              assignVoucher(
                userNumber,
                { ...voucher, customerNumber },
                (status) => {
                  setshowActionModal(status);
                },
                (status, error) => {
                  if (!status) {
                    setIsVoucherAssignError(error);
                    setShowAssignVoucher(true);
                    return;
                  }
                  else {
                    setIsActionCompleted(true);
                    setshowActionModal(true);
                    setShowAssignVoucher(false);
                  }
                }
              )
            );
          }}
        />
      )}

      {showFundWallet && (
        <FundWallet
          onHide={() => {
            setShowFundWallet(false);
          }}
          isFundWalletError={isFundWalletError}
          hideError={(value) => {
            setIsFundWalletError(value);
          }}
          onFundWallet={(vendorId, customerId, amount) => {
            setShowFundWallet(false);
            setIsActionCompleted(false);
            setshowActionModal(true);
            dispatch(
              fundCustomerWallet(
                vendorId,
                customerId,
                amount,
                (status) => {
                  setshowActionModal(status);
                },
                (status, error) => {
                  if (!status) {
                    setIsFundWalletError(error);
                    setShowFundWallet(true);
                    return;
                  }
                  else {
                    setIsActionCompleted(true);
                    setshowActionModal(true);
                    setShowFundWallet(false);
                  }
                }
              )
            );
          }}
        />
      )}

      {showActionModal ? (
        <ActionModal
          isCompleted={isActionCompleted}
          actionTitle={isVoucherOps ? 'Generating / Assigning Voucher' : (isWalletOps ? "Funding Wallet..." : "Assigning Card...")}
          isShow={showActionModal}
          actionText=""
          titleStyle={{color: "#000000"}}
          updateCompletedStatus={(status) => setIsActionCompleted(status)}
          isCompletedTitle="Thank you! You are all set!"
          isCompletedText={
            isVoucherOps ? "The voucher has been successfully generated" : (isWalletOps ? "The wallet-funding operation was successful" : "The card has been successfully assigned to the user")
          }
          onShowActionModal={(status) => setshowActionModal(status)}
        >
          {isActionCompleted ? (
            <>
              <div className="row modal-action-buttons">
                <div className="col-6">
                  <button
                    className="back"
                    onClick={() => setshowActionModal(false)}
                  >
                    Back
                  </button>
                </div>
                {!isWalletOps && <div className="col-6">
                  <button
                    className="create"
                    onClick={() => {
                      isVoucherOps ? setShowAssignVoucher(true) : setShowAssignCard(true);
                      setIsActionCompleted(false);
                      setshowActionModal(false);
                    }}
                  >
                    { isVoucherOps ? 'Generate new voucher' : 'Assign new card' }
                  </button>
                </div>}
              </div>
              <div className="col-8 offset-2 pl-0 pr-0 mt-4 mb-5"></div>
            </>
          ) : null}
        </ActionModal>
      ) : null}

      {isStatusBarVisible && (
        <div className="disbursed-cards-container row">
          <div
            className="menu-link"
            style={{marginBottom: 0, paddingBottom: 4}}
          >
            <span
              style={{
                color: "#000000",
                borderBottomColor: "#00425F",
                borderBottomWidth: 3,
                borderBottomStyle: "solid",
              }}
            >
              Disbursed Cards
            </span>
          </div>

          <div className="action-buttons" style={{bottom: "inherit"}}>
            {myCards.length > 0 && (
              <div className="export-btn d-inline-block">
                <CSVLink
                  data={myCards}
                  filename={"RFID Cards.csv"}
                  className=""
                  target="_blank"
                >
                  <span className="text-white">Export CSV</span> <ExportSVG />
                </CSVLink>
              </div>
            )}
            {getUserProperties().userLevel !== USER && (<>
              <button
                className="add-btn mr-2"
                style={{backgroundColor: "#E5250F"}}
                onClick={() => {
                  setShowFundWallet(true);
                  setIsWalletOps(true);
                }}
              >
                <i>
                  <AccountSvg />
                </i>
                Fund Wallet
              </button>
              <button
                className="add-btn mr-2"
                style={{backgroundColor: "#4584db"}}
                onClick={() => {
                  setShowAssignVoucher(true);
                  setIsVoucherOperation(true);
                }}
              >
                <i>
                  <CreateSVG />
                </i>
                Generate Voucher
              </button>
              <button
                className="add-btn"
                onClick={() => {
                  setShowAssignCard(true);
                  setIsVoucherOperation(false);
                }}
              >
                <i>
                  <CreateSVG />
                </i>
                Add Cards
              </button>
            </>)}
          </div>

          <table className="table table-hover">
            <thead>
              <tr>
                <td>Card Pan</td>
                <td>Name</td>
                <td>
                  Account Number <FilterSVG />
                </td>
                <td>Cycle</td>
                <td>Limit</td>
                <td>
                  Status <FilterSVG />
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {cardsList.length > 0 ? (
                cardsList
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    No Cards to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            count={myCards.length}
            pageSize={pageSize}
            fetchMethod={(pageNo) => {
              setPageNo(pageNo);
              // onFetchRfidTags(usedPageNo, pageNo)
            }}
          />
        </div>
      )}
    </>
  );
};

export default CardList;
