import React/* , {useState} */ from "react";
import {
  BackSVG,
  UserTransactionSVG,
} from "../../../assets/Svgs/Svgs";
import formatAmount from "../../../lib/Functions/formatAmount";
import { shortMonthNames, shortDayNames } from "../../../lib/Functions/DateNames";
import { getUserProperties, STATION_MANAGER } from "../../../lib/Constants/UserTypes";
import { StatePriceComponent } from "../../shared/StatePrice/StatePrice";

const ProductDetails = (props) => {
  const {selectedProduct} = props;
  const {
    name,
    number,
    createdAt,
    rate,
    unit,
    stationRate,
  } = selectedProduct;
  const { userLevel } = getUserProperties();

  const d = new Date(createdAt);

  return (
    <>
      <div className="underlined-tab" style={{marginRight: 30}}>
        <span className="tab-title">Product Details</span>
        <div className="action-buttons">
          <button
            className="export-btn"
            style={{marginRight: 0}}
            onClick={() => {
              props.backFunction();
            }}
          >
            <BackSVG />
            Back
          </button>
        </div>
      </div>

      <div className=" customer-details mb-1" style={{width: "100%"}}>
        <div className="col-12">
          <>
            <div
              className="row user-overview mt-3"
              style={{
                // padding: "20px 80px",
                marginLeft: "6rem",
                marginRight: "6rem",
                width: "auto",
              }}
            >
              <div
                className="pr-5 pb-1"
                style={{paddingLeft: "100px", borderRadius: 6, width: "100%"}}
              >
                <div className="mb-4 mt-4">
                  <UserTransactionSVG />{" "}
                  <span style={{color: "#000", fontSize: 18, fontWeight: 900}}>
                    {name}
                  </span>
                </div>
                <>
                  <div className="row user-address-title">
                    <div className="col-4">Product Name</div>
                    <div className="col-4">Product ID</div>
                    <div className="col-4">Date Created</div>
                  </div>
                  <div className="row user-address-details mt-2">
                    <div className="col-4">{name}</div>
                    <div className="col-4">{number || "0"}</div>
                    <div className="col-4">
                      {shortDayNames[d.getDay() - 1]} {d.getDate()},{" "}
                      {shortMonthNames[d.getMonth()]} {d.getFullYear()}
                    </div>
                  </div>

                  <div className="row user-address-title mt-4">
                    <div className="col-4">Product Rate</div>
                    <div className="col-4">Contract Product Unit</div>
                    {userLevel === STATION_MANAGER && <div className="col-4">Station Price</div>}
                  </div>
                  <div className="row user-address-details mt-2">
                    <div className="col-4">₦ {formatAmount(rate * 100)}</div>
                    <div className="col-4">{unit}</div>
                    {userLevel === STATION_MANAGER && <div className="col-4">{stationRate? `₦ ${formatAmount(stationRate * 100)}`: 'none'}</div> }
                  </div>
                  {userLevel === STATION_MANAGER ? 
                  <div
                    style={{
                      backgroundColor: "#00425F",
                      color: "#fff",
                      display: "inline-block",
                      borderRadius: 4,
                      padding: "5px 30px",
                      cursor: "pointer",
                      marginTop: 30,
                    }}
                    onClick={props.editProduct}
                  >
                    { (stationRate && stationRate > 0)? 'Edit Station Price' : 'Add Station Price' }
                  </div> :
                   <div
                   style={{
                     backgroundColor: "#00425F",
                     color: "#fff",
                     display: "inline-block",
                     borderRadius: 4,
                     padding: "5px 30px",
                     cursor: "pointer",
                     marginTop: 30,
                   }}
                   onClick={props.editProduct}
                 >
                   Edit Product
                 </div>}
                </>
              </div>
            </div>
          </>
        </div>
      </div>
      <StatePriceComponent product={name} />
    </>
  );
};

export default ProductDetails;
