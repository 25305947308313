import React, { useEffect, useState } from 'react';
import { Pagination, Select } from 'antd';
const Pagination2 = ({ current, pageSize, onChange, onSizeChange, total }) => {
   const [_pageSize, setPageSize] = useState(pageSize);
   useEffect(()=>{
      setPageSize(pageSize);
   },[pageSize])
   const showTotal = (total) => {
      return `Showing ${current} of ${Math.ceil(total / _pageSize)}`
   }
   
   return (<div className='d-flex justify-content-between custom-pagination'>
      <Pagination current={current}
         size='small'
         showLessItems={false}
         total={total}
         defaultCurrent={1}
         pageSize={_pageSize}
         onChange={onChange}
      />
      <section style={{
         width: 'max-content'
      }}>
         <Select defaultValue={10} value={pageSize} style={{
            width: 54,
            backgroundColor: '#F3F5F6',
            borderRadius: 4,
         }} onChange={onSizeChange}>
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={15}>15</Select.Option>
         </Select>
         <label style={{
            display: 'inline-block',
            color: '#00425F',
            padding: 5,
            fontSize: 12,
         }}>{showTotal(total)}</label>
      </section>
   </div>)
}

export default Pagination2;