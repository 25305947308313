import React, { useEffect } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import "../../../../assets/Styles/Dashboard.css";
import StatusBar from "../StatusBar";
import { getAllUsers } from "../../redux/action/customerUserActions";
import { OWNER_USER } from "../../../../lib/Constants/UserTypes";
import { fetchAllTrail } from "../../redux/action/OwnerTrailActions";
import { fetchAllVendors } from "../../redux/action/OwnerVendorActions";
import { fetchAllBlankcards } from "../../redux/action/OwnerCardActions";
import CardList from "./CardList";
import { fetchTags } from "../actions/methods";

function Cards() {
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(getAllUsers(OWNER_USER));
      dispatch(fetchAllTrail(1, 10000000));
      dispatch(fetchAllVendors(1, 1000));
      dispatch(fetchAllBlankcards());
      dispatch(fetchTags(1, 1));
    });
  }, []);
  const props = useSelector(state => state);

  return (
    <div className="dashboard-container">
      <StatusBar {...props} isVisible={true} />
      <CardList {...props} />
    </div>
  );
}

export default Cards;
